import { Button, Popconfirm, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GenericDocumentDto } from "../../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateEntryItemsOrderDto } from "../../../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDto";
import { CreateOrUpdateEntryItemsOrderDetailDto } from "../../../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDetailDto";
import { db } from "../../../../indexDB/clientSideDatabase";
import { ColDef } from "ag-grid-community";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import {
  RightOutlined,
  FilterOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import {
  getEntryItemsOrdersColumnsAsync,
  getEntryItemsOrdersWithFilters,
} from "../../../../redux/slices/entryItemsOrdersSlice";
import { FilterData } from "../../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../../customHooks/useFilter";
import { exportDataToCsv } from "../../../../models/helper/exportDataToCsv";
import { renderFilterInputs } from "../../../../helperMethods/renderFilterInputs";
import FilterModal from "../../../CustomComponents/FilterModal";
import { formatDate, formatDateWithTime } from "../../../../helperMethods/DateFormat";
import { GetEntryItemsOrderDto } from "../../../../models/clientDashboard/EntriesModule/Orders/GetEntryItemsOrderDto";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import WithPermission from "../../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import { usePermission } from "../../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;
export default function AutomaticEntryItemsOrders() {
  const automaticEntryItemsOrders = useSelector(
    (state: RootState) => state.entryItemsOrder.entryItemsOrders
  );
  const t = useAppTranslation("ClientDashboard.AutomaticEntryItemsOrders");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef<GetEntryItemsOrderDto>[]>([
    {
      field: "entryItemsOrderId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.entryItemsOrderId}</Text>;
      },
    },

    { field: "subjectDescription", headerName: t("tableHeaders.subjectName"), suppressMenu: true, width: 250 },
    { field: "deliveryDeadline", headerName: t("tableHeaders.deliveryDeadline"), suppressMenu: true },
    { field: "documentNo", headerName: t("tableHeaders.documentNo"), suppressMenu: true },
    { field: "stability", headerName: t("tableHeaders.stability"), suppressMenu: true },
    { field: "comment", headerName: t("tableHeaders.comment"), suppressMenu: true },
    { field: "startDate", headerName: t("tableHeaders.startDate"), valueFormatter: formatDate, suppressMenu: true },
    { field: "endDate", headerName: t("tableHeaders.endDate"), valueFormatter: formatDate, suppressMenu: true },
    { field: "hasReturn", headerName: t("tableHeaders.hasReturn"), suppressMenu: true },
    { field: "exchangeRate", headerName: t("tableHeaders.exchangeRate"), suppressMenu: true, width: 120 },


    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.AutomaticOrderCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateEntryItemsOrders"),
                  `entryItemsOrders/automatic/update/${params?.data?.entryItemsOrdersId}`,
                  undefined
                );
                navigate(
                  `/entryItemsOrders/automatic/update/${params?.data?.entryItemsOrdersId}/${params.data.branchId}`
                );
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 90,
      suppressMenu: true,
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["deliveryDeadline"]);
    params.columnApi.autoSizeColumns(["documentNo"]);
    params.columnApi.autoSizeColumns(["startDate"]);
    params.columnApi.autoSizeColumns(["hasReturn"]);
    params.columnApi.autoSizeColumns(["exchangeRate"]);
    params.columnApi.autoSizeColumns(["endDate"]);
    params.columnApi.autoSizeColumns(["stability"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);
  const getEntryItemsOrders = async () => {
    const result = await dispatch(getEntryItemsOrdersWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    getEntryItemsOrders();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.LocalPurchasesCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(t("tabs.updateEntryItemsOrders"), `entryItemsOrders/automatic/update/${event?.data?.entryItemsOrderId}/${event.data.branchId}`, undefined)
      navigate(`/entryItemsOrders/automatic/update/${event?.data?.entryItemsOrderId}/${event.data.branchId}`)
    };
  };
  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;
    const result = await dispatch(getEntryItemsOrdersWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getEntryItemsOrdersColumnsAsync());
    if (
      result.type === "EntryItemsOrders/getEntryItemsOrdersColumnsAsync/fulfilled"
    ) {
      return result;
    } else {
      return null;
    }
  };
  const handleClearFilters = async () => {
    const result = await dispatch(getEntryItemsOrdersWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewEntryItemsOrders = async () => {
    navigate("/entryItemsOrders/automatic/register");
    const existingTab = await db.tabs.get("entryItemsOrders/automatic/register");
    if (!existingTab) {
      db.addTab(
        t("tabs.createEntryItemsOrders"),
        "entryItemsOrders/automatic/register",
        undefined
      );
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "entryItemsId",
      "branch",
      "supplier",
      "documentType",
      "documentNo",
      "customDocNo",
      "validated",
      "date",
      "entryDate",
      "invoiceDate",
      "lastUpdatedDate",
      "supplierInvoiceNo",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.automaticEntryItemsOrders")}
        createRecord={handleCreateNewEntryItemsOrders}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.AutomaticOrderCreate}
        exportAccessEnum={MenuOptionEnum.AutomaticOrderExport}
      />
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState &&
          filterState?.filterInputs?.map((input) => (
            <div
              key={input.name}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Text>
                {input.isClassifier
                  ? input.name
                  : t(`FilterColumns.${input.name}`)}
              </Text>
              {renderFilterInputs({ input: input, filterState: filterState })}
            </div>
          ))}
      </div>
      {filterState && filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button onClick={filterState?.handleClearFilters}>
            {t("clearFilters")}
          </Button>
          <Button type="primary" onClick={filterState?.handleFilterSubmit}>
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={automaticEntryItemsOrders}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.AutomaticEntryItemsOrders"
      />
    </>
  );
}
