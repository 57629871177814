import {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { getServersAsync } from "../../../redux/slices/serversSlice";
import { ColDef } from "ag-grid-community";
import { Button, Spin, Tooltip, Typography } from "antd";
import { EditFilled } from "@ant-design/icons";
import { formatDateTimeAsDDMMYYYY } from "../../../helperMethods/formatDate";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { useNavigate } from "react-router-dom";
import { MenuAdminOptionEnum } from "../../../enums/MenuAdminOptionEnum";
import WithPermission from "../../Authorization/WithPermission";

const { Title, Text, Link } = Typography;

const ServerListComponent = () => {
  const servers = useSelector((state: RootState) => state.servers.servers);
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const t = useAppTranslation("AdminDashboard.ServerList");
  const handleCreateButtonClick = () => {
    console.log("Create button clicked");
  };
  interface Props {
    title: string;
    createButtonText: string;
    handleCreateButtonClick: () => void;
    handleSubmitForm: () => void;
  }
  useEffect(() => {
    dispatch(getServersAsync());
  }, []);

  const columnDefs: ColDef[] = [
    { headerName: t("columnHeaders.serverId"), field: "serverID" },
    { headerName: t("columnHeaders.serverName"), field: "serverName" },
    { headerName: t("columnHeaders.domain"), field: "domain" },
    { headerName: t("columnHeaders.databaseName"), field: "dbName" },
    { headerName: t("columnHeaders.userName"), field: "userName" },
    {
      headerName: t("columnHeaders.isActive"),
      field: "isActive",
    },
    {
      headerName: t("columnHeaders.dateCreated"),
      field: "dateCreated",
      cellRenderer: (params: any) => {
        return (
          <Text type="secondary">
            {formatDateTimeAsDDMMYYYY(params.data.dateCreated)}
          </Text>
        );
      },
    },
    {
      field: "edit",
      headerName: "#",
      width: 30,
      cellRenderer: (params: any) => (
        <WithPermission
        menuOptionId={MenuAdminOptionEnum.AdminServerCreate}
        children={
        <Tooltip placement="left" title={t("columnHeaders.edit")}>
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => console.log(params.data)}
            style={{
              color: "#007FFF",
            }}
          ></Button>
        </Tooltip>
        }
        />
      ),
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    flex: 1,
  };

  return (
    <>
      <Spin tip="Loading..." spinning={loadScreen}>
        <ListHeaderComponent
          title={t("titles.createServer")}
          createRecord={() => {
            navigate("/dashboard/servers/register");
          }}
          menuItemsDisabled={false}
          createTitle={t("create")}
          createAccessEnum={MenuAdminOptionEnum.AdminServerCreate}
        />

        <div className="ag-theme-balham" style={{ height: 550, marginTop: 15 }}>
          <AgGridTableComponent
            defaultColDef={defaultColDef}
            isLoading={loadScreen}
            rowData={servers}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={20}
          />
        </div>
      </Spin>
    </>
  );
};

export default ServerListComponent;
