import { Col, Form, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { useNavigate, useParams } from "react-router-dom";
import useAppTranslation from "../../customHooks/useAppTranslation";
import { useEffect, useRef } from "react";
import { PaymentMethods } from "../../models/clientDashboard/PaymentMethods/PaymentMethods";
import { setLoading } from "../../redux/slices/loadingSlice";
import apiService from "../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../indexDB/clientSideDatabase";
import {
  getPaymentMethodsDataById,
  getPaymentMethodsSuccess,
} from "../../redux/slices/paymentMethodsSlice";
import useFormData from "../../customHooks/useFormData";
import { Formik } from "formik";
import FormHeaderOptions from "../CustomComponents/FormHeaderOptions";
import FormInput from "../CustomComponents/FormInput";
import HandleFormDataForTabSaving from "../../helperMethods/handleFormDataForTabSaving";
import FormSwitch from "../CustomComponents/FormSwitch";
import FormSelect from "../CustomComponents/FormSelect";
import { getPaymentMethodTypesAsync } from "../../redux/slices/paymentMethodTypesSlice";
import { getFiscalPayMethodTypesAsync } from "../../redux/slices/fiscalPayMethodSlice";
import { MenuOptionEnum } from "../../enums/MenuOptionEnum";

const { Title, Text } = Typography;

const UpdatePaymentMethods = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const { id } = useParams();
  const t = useAppTranslation("ClientDashboard.UpdatePaymentMethods");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<any>(null);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  const fiscalPayMethodTypes = useSelector(
    (state: RootState) => state.fiscalPayMethodType.fiscalPayMethodTypes
  );

  const paymentMethodsTypes = useSelector(
    (state: RootState) => state.paymentMethodType.paymentMethodTypes
  );

  useEffect(() => {
    dispatch(getPaymentMethodTypesAsync());
    dispatch(getFiscalPayMethodTypesAsync());
  }, []);

  const onFinish = async (values: PaymentMethods) => {
    dispatch(setLoading(true));

    await apiService
      .put("/api/PaymentMethod", values)
      .then((response) => {
        toast.success(t("paymentMethodUpdatedSuccessfully"));
        db.deleteTab(`paymentMethod/update/${id}`);
        dispatch(getPaymentMethodsSuccess([]));
        navigate("/paymentMethods");
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const { initialValues } = useFormData<PaymentMethods>(
    `paymentMethods/update/${id}`,
    {} as PaymentMethods,
    undefined,
    {
      fetchData: async () => {
        const paymentMethodsAction = await dispatch(
          getPaymentMethodsDataById(parseInt(id ?? "0"))
        );
        const paymentMethods = paymentMethodsAction.payload as PaymentMethods;
        return paymentMethods;
      },
    }
  );
  const handleSubmitValidationForm = async (
    setTouched: ({}: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = (await validateForm()) || {};
    setTouched({
      description: true,
      shortDescription: true,
      displayedInPos: true,
      sortingOrder: true,
      paymentWithBonus: true,
      directPayment: true,
      comission: true,
      fiscalPayMethodType: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };

  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({} as PaymentMethods)}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, handleSubmit, submitForm, setTouched, validateForm }) => (
          <>
            <FormHeaderOptions
              title={t("formTitle")}
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("updatePaymentMethodsButton")}
              submitButtonIsDisabled={isLoading}
              createAccessEnum={MenuOptionEnum.PaymentMethodsCreate}
            />
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              style={{ marginTop: 20 }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <FormInput
                    name="shortDescription"
                    label={t("shortDescriptionInputLabel")}
                    type="text"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: t("shortDescriptionPlaceholder"),
                      },
                    ]}
                    value={values.shortDescription}
                  />
                  <FormInput
                    name="description"
                    label={t("descriptionInputLabel")}
                    type="text"
                    rules={[
                      {
                        required: true,
                        message: t("descriptionPlaceholder"),
                      },
                    ]}
                    value={values.description}
                  />
                  <FormInput
                    name="comission"
                    type="number"
                    label={t("comissionInputLabel")}
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: t("comissionPlaceholder"),
                      },
                      {
                        validator: (_: any, value: number) => {
                          if (value <= 0) {
                            return Promise.reject(t("comissionInvalidMessage"));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    value={values.comission}
                  />
                  <FormInput
                    name="sortingOrder"
                    type="number"
                    label={t("sortingOrderLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("sortingOrderPlaceholder"),
                      },
                      {
                        validator: (_: any, value: number) => {
                          if (value <= 0) {
                            return Promise.reject(
                              t("sortingOrderInvalidMessage")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    value={values.sortingOrder}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <FormSelect
                    name="fiscalPayMethodType"
                    label={t("fiscalPayMethodTypeLabel")}
                    required={true}
                    value={values.fiscalPayMethodType}
                    options={
                      fiscalPayMethodTypes?.map((item) => {
                        return {
                          key: item.fiscalPayMethodTypeId,
                          label: item.description,
                          value: item.fiscalPayMethodTypeId,
                        };
                      }) ?? []
                    }
                  />
                  <FormSelect
                    name="typeId"
                    label={t("typeId")}
                    required={true}
                    value={values.typeId}
                    options={
                      paymentMethodsTypes?.map((item) => {
                        return {
                          key: item.paymentMethodTypeId,
                          label: item.description,
                          value: item.paymentMethodTypeId,
                        };
                      }) ?? []
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "16px",
                    }}
                  >
                    <FormSwitch
                      name="displayedInPos"
                      label={t("displayedInPosLabel")}
                      rules={[
                        {
                          required: true,
                          message: t("displayedInPosPlaceholder"),
                        },
                      ]}
                      value={values.displayedInPos}
                    />
                    <FormSwitch
                      name="paymentWithBonus"
                      label={t("paymentWithBonusLabel")}
                      rules={[
                        {
                          required: true,
                          message: t("paymentWithBonusPlaceholder"),
                        },
                      ]}
                      value={values.paymentWithBonus}
                    />
                    <FormSwitch
                      name="directPayment"
                      label={t("directPaymentLabel")}
                      rules={[
                        {
                          required: true,
                          message: t("directPaymentPlaceholder"),
                        },
                      ]}
                      value={values.directPayment}
                    />
                  </div>
                </Col>
              </Row>
              <HandleFormDataForTabSaving
                tabPath={`paymentMethods/update/${id}`}
              />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
};

export default UpdatePaymentMethods;
