import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DashboardSubscriptionsState } from "../../models/states/DashboardSubscriptionsState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { GetSubscriptionsDto } from "../../models/dashboard/Subscriptions/GetSubscriptionsDto";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { toast } from "react-toastify";
import GetSubscriptionPlansDto from "../../models/dashboard/Subscriptions/GetSubscriptionPlansDtp";
import GetDataWithPagination from "../../models/GetDataWithPagination";
import { SubscriptionPayment } from "../../models/dashboard/Payments/SubscriptionPayment";

const initialState: DashboardSubscriptionsState = {
  subscriptions: [],
  error: null,
  subscriptionPlans: [],
  paymentSubscription: [],
};

const dashboardSubscriptionsSlice = createSlice({
  name: "dashboardSubscriptions",
  initialState,
  reducers: {
    getDashboardSubscriptionsStart(state: DashboardSubscriptionsState) {
      setLoading(true);
      state.error = null;
    },
    getDashboardSubscriptionsSuccess(
      state: DashboardSubscriptionsState,
      action: PayloadAction<GetSubscriptionsDto[]>
    ) {
      setLoading(false);
      state.subscriptions = action.payload;
    },
    getDashboardSubscriptionsFailure(
      state: DashboardSubscriptionsState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
    getSubscriptionPlansSuccess(
      state: DashboardSubscriptionsState,
      action: PayloadAction<GetSubscriptionPlansDto[]>
    ) {
      state.subscriptionPlans = action.payload;
    },
    getSubscriptionPaymentsSuccess(
      state: DashboardSubscriptionsState,
      action: PayloadAction<SubscriptionPayment[]>
    ) {
      state.paymentSubscription = action.payload;
    },
  },
});

export const getDashboardSubscriptionsAsync = createAsyncThunk(
  "dashboard/Subscriptions",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { subscriptions } = (
      getState() as { dashboardSubscriptions: DashboardSubscriptionsState }
    ).dashboardSubscriptions;

    // if (subscriptions.length > 0) {
    //   return subscriptions;
    // }

    try {
      dispatch(setLoadingState(true));
      dispatch(getDashboardSubscriptionsStart());
      const response = await apiService.get<
        ApiResponse<GetDataWithPagination<GetSubscriptionsDto>>
      >("api/dashboard/Subscriptions");
      toast.success("Abonimet u shkarkuan me sukses!");
      dispatch(getDashboardSubscriptionsSuccess(response.data.Data.list));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getDashboardSubscriptionsFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getSubscriptionPlansAsync = createAsyncThunk(
  "api/dashboard/Subscriptions/plans",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { subscriptionPlans } = (
      getState() as { dashboardSubscriptions: DashboardSubscriptionsState }
    ).dashboardSubscriptions;

    if (subscriptionPlans.length > 0) {
      return subscriptionPlans;
    }

    try {
      const response = await apiService.get<
        ApiResponse<GetSubscriptionPlansDto[]>
      >("api/dashboard/Subscriptions/plans");

      dispatch(getSubscriptionPlansSuccess(response.data.Data));

      return response.data.Data;
    } catch (error) {
      toast.error("Failed to fetch subscription plans");
      // dispatch(getSubscriptionPlansFailure());
      return rejectWithValue(error);
    }
  }
);

export const getSubscriptionPaymentsAsync = createAsyncThunk(
  "api/dashboard/Subscriptions/payments",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { paymentSubscription } = (
      getState() as { dashboardSubscriptions: DashboardSubscriptionsState }
    ).dashboardSubscriptions;

    if (paymentSubscription.length > 0) {
      return paymentSubscription;
    }

    try {
      const response = await apiService.get<
        ApiResponse<SubscriptionPayment[]>
      >("api/dashboard/SubscriptionPayments");

      dispatch(getSubscriptionPaymentsSuccess(response.data.Data));

      return response.data.Data;
    } catch (error) {
      toast.error("Failed to fetch subscription payments");
      // dispatch(getSubscriptionPlansFailure());
      return rejectWithValue(error);
    }
  }
);

export const {
  getDashboardSubscriptionsStart,
  getDashboardSubscriptionsSuccess,
  getDashboardSubscriptionsFailure,
  getSubscriptionPlansSuccess,
  getSubscriptionPaymentsSuccess
} = dashboardSubscriptionsSlice.actions;

export default dashboardSubscriptionsSlice.reducer;
