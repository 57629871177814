import { RefObject } from "react";
import { CreateOrUpdateInternalSaleDetailDto } from "../../../../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDetailDto";
import { ColDef } from "ag-grid-community";
import { suppressEnter } from "../../../../../helperMethods/suppressEnter";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Classifier } from "../../../../../models/clientDashboard/Classifier/Classifier";
import FormSelectWithSearchWithoutFormik from "../../../../CustomComponents/FormSelectWithSearchWithoutFormik";
import { ClassifierDetail } from "../../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";



export const getInternalSalesDetailColumns = (
    deleteInternalSaleDetail: (index: number) => void,
    t: (text: string) => string,
    searchItemInputRef: RefObject<any>,
    mode: string,
    classifiersForInternalSale: Classifier[],
    fetchClassifiers: (searchInput: string, classifierId: number) => any,
) => {
    const getMaxInternalSaleDetailId = (rowData: CreateOrUpdateInternalSaleDetailDto[]) => {
        return Math?.max(0, ...rowData?.map((item) => item.internalSaleDetailId ?? 0));
    };
    const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const parseDate = (dateStr: string) => {
        const [day, month, year] = dateStr.split('-');
        return `${year}-${month}-${day}`;
    };

    const columnDefs: ColDef<CreateOrUpdateInternalSaleDetailDto>[] = [
        {
            headerName: t("tableHeaders.internalSaleDetailId"),
            field: "internalSaleDetailId",
            editable: false,
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            sortable: false,
            filter: false,
            width: 80,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
            valueSetter: (params) => {
                const newValue = parseInt(params.newValue, 10);

                const allData: CreateOrUpdateInternalSaleDetailDto[] = [];
                params.api.forEachNode((node: any) => allData.push(node.data));

                const isDuplicate = allData.some(
                    (row) => row.internalSaleDetailId === newValue && row !== params.data
                );

                if (isNaN(newValue) || newValue <= 0 || isDuplicate) {
                    params.data.internalSaleDetailId = params.oldValue;
                    return false;
                }

                if (mode === 'update' && params.data.internalSaleDetailId !== 0 && params.data.internalSaleDetailId !== undefined && params.data.internalSaleDetailId !== null) {
                    params.data.rowAction = 'U';
                }
                params.data.internalSaleDetailId = newValue;
                return true;
            },
            valueGetter: (params: any) => {
                if (params.data.no === undefined || params.data.no === null) {

                    const allData: CreateOrUpdateInternalSaleDetailDto[] = [];
                    params.api.forEachNode((node: any) => allData.push(node.data));
                    const maxNo = getMaxInternalSaleDetailId(allData);
                    params.data.no = maxNo + 1;
                }
                return params.data.no;
            },
        },
        {
            hide: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.itemId"),
            field: "itemId",
            editable: false,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.itemDescription"),
            field: "itemDescription",
            editable: false,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.quantity"),
            wrapHeaderText: true,
            filter: false,
            field: "quantity",
            editable: true,
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            singleClickEdit: true,
            suppressMenu: true,
            valueSetter: (params: any) => {
                const value = params.newValue;
                if (parseInt(value) <= 0) {
                    params.data.quantity = 1;
                } else {
                    params.data.quantity = value;
                }
                if (mode === 'update' && params.data.saleOrderDetailId !== 0 && params.data.saleOrderDetailId !== undefined && params.data.saleOrderDetailId !== null) {
                    params.data.rowAction = 'U';
                }
                params.data.package = (Number(value) / params?.data?.itemPackaging)?.toFixed(4);
                return true;
            },
            sortable: false,
            suppressMovable: true,
        },
        {
            headerName: t("tableHeaders.itemCost"),
            field: "itemCost",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.averageCost"),
            field: "averageCost",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.comment"),
            field: "comment",
            sortable: false,
            editable: true,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.vAT"),
            field: "vAT",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            editable: false,
            filter: false,
            singleClickEdit: true,
            valueSetter: (params) => {
                const vat = parseFloat(params.newValue);
                if (
                    mode === "update" &&
                    params.data.internalSaleDetailId !== 0 &&
                    params.data.internalSaleDetailId !== undefined &&
                    params.data.internalSaleDetailId !== null
                ) {
                    params.data.rowAction = "U";
                }
                return true;
            },
            maxWidth: 80,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.saleId"),
            wrapHeaderText: true,
            field: "saleId",

            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.salesPrice"),
            field: "salesPrice",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            wrapHeaderText: true,
            filter: false,
            singleClickEdit: true,
            maxWidth: 120,
            suppressMenu: true
        }];
    classifiersForInternalSale.forEach((classifier, index) => {
        columnDefs.push({
            headerName: classifier.clientDescription,
            field: classifier.description?.toLowerCase(),
            cellRenderer: (params: any) => {
                const defaultValueOfClassifier = params.data[classifier.description?.toLowerCase() ?? -1]
                return (
                    <FormSelectWithSearchWithoutFormik
                        name=""
                        label=""
                        style={{ width: "100%" }}
                        value={defaultValueOfClassifier}
                        className="import-select-with-search"
                        fetchOptions={(searchText: string) => fetchClassifiers(searchText, classifier.classifierId ?? "")}
                        placeHolder={t("placeHolder")}
                        onChange={(option) => {
                            const fieldName = classifier?.description?.toLowerCase();
                            params.data[fieldName] = option.value;

                            const value = +option.value;


                            switch (fieldName) {
                                case 'k70':
                                    params.data.k70 = value;
                                    break;
                                case 'k71':
                                    params.data.k71 = value;
                                    break;
                                case 'k72':
                                    params.data.k72 = value;
                                    break;
                                case 'k73':
                                    params.data.k73 = value;
                                    break;
                                case 'k74':
                                    params.data.k74 = value;
                                    break;
                                default:
                                    break;
                            }
                            if (mode === 'update' && params.data.internalSaleDetailId !== 0 && params.data.internalSaleDetailId !== undefined && params.data.internalSaleDetailId !== null) {
                                params.data.rowAction = 'U';
                            }
                            params.api.refreshCells({ rowNodes: [params.node], force: true });
                            params.api.applyTransaction({ update: [params.data] });
                        }}
                        fetchInitialOption={(value) => {
                            return {
                                key: params.data[classifier?.description?.toLowerCase()] ?? "",
                                label: params?.data[`${classifier?.description?.toLowerCase()}Description`] ?? "",
                                value: params?.data[classifier?.description?.toLowerCase()] ?? ""
                            }
                        }}
                    />
                );
            },
            valueSetter: (params) => {
                const newValue = parseInt(params.newValue, 10);

                const allData: CreateOrUpdateInternalSaleDetailDto[] = [];
                params.api.forEachNode((node: any) => allData.push(node.data));

                const isDuplicate = allData.some(
                    (row) => row.internalSaleDetailId === newValue && row !== params.data
                );

                if (mode === 'update' && params.data.internalSaleDetailId !== 0 && params.data.internalSaleDetailId !== undefined && params.data.internalSaleDetailId !== null) {
                    params.data.rowAction = 'U';
                }
                params.data.internalSaleDetailId = newValue;
                return true;
            },
            editable: false,
            sortable: false,
            filter: false,
            maxWidth: 140,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
        });

    })
    columnDefs.push(
        {
            headerName: t("tableHeaders.options"),
            suppressMenu: true,
            maxWidth: 100,
            cellRenderer: (params: any) => {
                return (
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteInternalSaleDetail(params.rowIndex)}
                        style={{ color: "#007FFF" }}
                    ></Button>
                );
            },
        })

    return columnDefs;
};