import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import FormInput from "../../../CustomComponents/FormInput";
import { RootState } from "../../../../redux";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import { Entity } from "../../../../models/clientDashboard/Entity/Entity";
import { fetchClassifiers } from "../../../../redux/slices/classifierSlice";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";

interface Props {
  entity: Entity;
  setFieldValue: (name: string, value: any) => void;
}
export default function EntityClassifiersComponent({ entity, setFieldValue }: Props) {
  const t = useAppTranslation("ClientDashboard.CreateEntity.EntityClassifiersComponent");
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const filteredClassifiers = classifiers.filter(
    (classifier) =>
      classifier.description !== "K1" &&
      classifier.description !== "K2" &&
      classifier.description !== "K3" &&
      classifier.description !== "K4" &&
      classifier.status === true
  );

  function getNestedValue(obj: any, path: any) {
    return path.split('.').reduce((acc: any, part: any) => acc && acc[part], obj);
  };

  const getClassifierValueByNameForSelect = (name: string, entity: any) => {
    const categoryValue = entity[name];
    if (typeof categoryValue === 'number') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
    } else {
      return undefined;
    }
  };

  const getClassifierValueByNameForInput = (name: string, entity: any) => {
    const categoryValue = entity[name];
    if (typeof categoryValue === 'number' || typeof categoryValue === 'string') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue?.classifierDetailDescription || undefined;
    } else {
      return undefined;
    }
  };

  const groupSize = Math.ceil(filteredClassifiers.length / 3);
  const groups = [
    filteredClassifiers.slice(0, groupSize),
    filteredClassifiers.slice(groupSize, groupSize * 2),
    filteredClassifiers.slice(groupSize * 2)
  ];

  const getClassifierDescriptionValue = (name: string, entity: any) => {
    const descriptionValue = getNestedValue(entity, name);
    switch (name) {
      case "k25Description":
        return entity?.k25Description;
      case "k26Description":
        return entity?.k26Description;
      case "k27Description":
        return entity?.k27Description;
      case "k28Description":
        return entity?.k28Description;
      case "k29Description":
        return entity?.k29Description;
      case "k30Description":
        return entity?.k30Description;
      case "k31Description":
        return entity?.k31Description;
      case "k32Description":
        return entity?.k32Description;
      case "k33Description":
        return entity?.k33Description;
      case "k34Description":
        return entity?.k34Description;
      case "k35Description":
        return entity?.k35Description;
      case "k36Description":
        return entity?.k36Description;
      case "k37Description":
        return entity?.k37Description;
      case "k38Description":
        return entity?.k38Description;
      case "k39Description":
        return entity?.k39Description;
      case "k40Description":
        return entity?.k40Description;
      case "k41Description":
        return entity?.k41Description;
      case "k42Description":
        return entity?.k42Description;
      case "k43Description":
        return entity?.k43Description;
      case "k44Description":
        return entity?.k44Description;
      case "k55":
        return entity?.k55Description;
      case "k56":
        return entity?.k56Description;
      case "k57":
        return entity?.k57Description;
      case "k58":
        return entity?.k58Description;
      case "k59":
        return entity?.k59Description;
      default:
        return "";
    }
  };

  const renderGroup = (group: Classifier[]) => {
    const inputComponents = group
      .filter(
        (classifier) =>
          classifier.description !== "K1" &&
          classifier.description !== "K2" &&
          classifier.description !== "K3" &&
          classifier.description !== "K4" &&
          classifier.status === true
      )
      .map((classifier) => {
        switch (classifier.classifierTypeDescription.toLowerCase()) {
          case "text":
            const textValue = getClassifierValueByNameForInput(`${classifier.description.toLowerCase()}Description`, entity);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                value={textValue}
              />
            );
          case "select":
            const value = getClassifierValueByNameForSelect(classifier?.description?.toLowerCase(), entity)
            const label = getClassifierDescriptionValue(`${classifier?.description?.toLowerCase()}Description`, entity);
            return (
              <FormSelectWithSearch
                key={classifier.description}
                name={classifier?.description?.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                required={classifier.mandatory}
                value={entity[classifier?.description?.toLowerCase()]}
                disabled={entity.validated}
                fetchOptions={(searchText: string) => fetchClassifiers(searchText, classifier.classifierId ?? "")}
                fetchInitialOption={() => {
                  return {
                    key: entity[classifier?.description?.toLowerCase()],
                    label: label,
                    value: entity[classifier?.description?.toLowerCase()],
                  };
                }}
                placeHolder={t("placeHolder")}
                onChange={(option) => {
                  setFieldValue(classifier?.description?.toLowerCase(), option.value);
                }}
              />
            );
          case "number":
          case "decimal number":
            const numberValue = getClassifierValueByNameForInput(`${classifier.description.toLowerCase()}Description`, entity);

            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                type="number"
                value={numberValue}
              />
            );
          case "email":
            const emailValue = getClassifierValueByNameForInput(`${classifier.description.toLowerCase()}Description`, entity);

            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                type="email"
                value={emailValue}
              />
            );
          default:
            return null;
        }
      });

    return inputComponents;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={8}>{renderGroup(groups[0])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[1])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[2])}</Col>
    </Row>
  );
}
