import { Select, Form, Divider, Space, Input, Button, InputRef } from "antd";
import { ErrorMessage, useField } from "formik";
import classes from "./CustomInputStyle.module.css";
import { forwardRef, ReactNode, useRef, useState } from "react";
import FloatLabel from "./FloatLabel";
import { SelectOption } from "../../models/SelectOption";

interface Props {
  name: string;
  label: string;
  rules?: any[];
  options: SelectOption[] | undefined;
  className?: string;
  style?: any;
  disabled?: boolean;
  value?: any;
  required?: boolean;
  handleCustomChange?: (value: any) => void;
  hasAddOption?: boolean;
  dropdownContent?: () => ReactNode;
  multiple?: boolean;
  onChange?: (value: any) => void;
  showSearch?:boolean;
}

const FormSelect = forwardRef<any, Props>(({
  name,
  label,
  rules,
  options,
  className = "",
  style = {},
  disabled = false,
  value,
  required,
  handleCustomChange,
  hasAddOption = false,
  dropdownContent = () => undefined,
  multiple = false,
  showSearch = true,
}: Props, ref) => {
  const [field, meta, helpers] = useField(name);
  const hasError = meta.touched && meta.error;

  const handleChange = (value: any) => {
    helpers.setValue(value);
  };
  // const addItem = () => {
  //   if (addNewOption) {
  //     addNewOption({
  //       inputValue: searchName,
  //       selectName: name,
  //       selectValue: field.value
  //     });
  //   }
  // };

  return (
    <Form.Item
      required={required}
      // label={label}
      // validateStatus={hasError ? "error" : ""}
      // help={hasError ? meta.error : ""}
      className={className}
      style={style}
      rules={rules}
    >
      <>
        <FloatLabel label={label} value={value} required={required}>
          <Select
          ref={ref}
            {...field}
            value={value ?? field.value}
            onChange={(selectedValue) => {
              if (handleCustomChange) {
                handleCustomChange(selectedValue);
              } else {
                handleChange(selectedValue);
              }
            }}
            mode={multiple ? "multiple" : undefined}
            showSearch={showSearch}
            // placeholder={label}
            options={options}
            dropdownRender={(menu) => {
              if (!hasAddOption) {
                return <>{menu}</>;
              }
              return (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    {dropdownContent()}
                  </Space>
                </>
              );
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            // filterSort={(optionA, optionB) =>
            //   (optionA?.label ?? "")
            //     .toLowerCase()
            //     .localeCompare((optionB?.label ?? "").toLowerCase())
            // }
            disabled={disabled}
          />
        </FloatLabel>
        <ErrorMessage
          name={name}
          component="div"
          className={classes.errorMessage}
        />
      </>
    </Form.Item>
  );
});

export default FormSelect;
