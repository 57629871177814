import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ClientLayoutComponent from "../ClientLayoutComponent";
import ClientDashboard from "../../ClientDashboard/ClientDashboard";
import CreateBrand from "../../ClientDashboard/Brands/CreateBrand";
import Brands from "../../ClientDashboard/Brands";
import UpdateBrand from "../../ClientDashboard/Brands/UpdateBrand";
import Customs from "../../ClientDashboard/Customs";
import UpdateCustom from "../../ClientDashboard/Customs/UpdateCustom";
import CreateCustom from "../../ClientDashboard/Customs/CreateCustom";
import Items from "../../ClientDashboard/Items";
import CreateItem from "../../ClientDashboard/Items/CreateItem";
import Classifiers from "../../ClientDashboard/Classifiers";
import ClassifierMainComponent from "../../ClientDashboard/Classifiers/ClassifierDetails/ClassifierMainComponent";
import Entities from "../../ClientDashboard/Entities";
import CreateEntity from "../../ClientDashboard/Entities/CreateEntity";
import UpdateItem from "../../ClientDashboard/Items/UpdateItem";
import UpdateEntity from "../../ClientDashboard/Entities/UpdateEntity";
import ImportArticles from "../../ClientDashboard/Items/ExcelImport/ImportArticles";
import ImportTemplates from "../../ClientDashboard/ImportTempates/ImportTemplates";
import LocalPurchases from "../../ClientDashboard/LocalPurchases";
import CreateLocalPurchase from "../../ClientDashboard/LocalPurchases/CreateLocalPurchase";
import InvoiceWithRemarksReasons from "../../ClientDashboard/InvoiceWithRemarksReasons";
import GoodsReturnReasons from "../../ClientDashboard/GoodsReturnReasons";
import Currencies from "../../ClientDashboard/Currencies";
import CreateCurrency from "../../ClientDashboard/Currencies/CreateCurrency";
import UpdateCurrency from "../../ClientDashboard/Currencies/UpdateCurrency";
import Branches from "../../ClientDashboard/Branches";
import CreateBranch from "../../ClientDashboard/Branches/CreateBranch";
import UpdateBranch from "../../ClientDashboard/Branches/UpdateBranch";
import Users from "../../ClientDashboard/Users";
import CreateUser from "../../ClientDashboard/Users/CreateUser";
import UpdateUser from "../../ClientDashboard/Users/UpdateUser";
import ImportPurchases from "../../ClientDashboard/ImportPurchases";
import CreateImportPurchase from "../../ClientDashboard/ImportPurchases/CreateImportPurchase";
import Price from "../../ClientDashboard/ItemPrices/Prices";
import CreatePrice from "../../ClientDashboard/ItemPrices/Prices/CreatePrice";
import CashRegisters from "../../ClientDashboard/Registrations/CashRegisters";
import CreateCashRegister from "../../ClientDashboard/Registrations/CashRegisters/CreateCashRegister";
import UpdateCashRegister from "../../ClientDashboard/Registrations/UpdateCashRegister";
import DocumentPeriods from "../../ClientDashboard/Registrations/DocumentPeriods";
import Roles from "../../ClientDashboard/Registrations/Roles";
import CreateRole from "../../ClientDashboard/Registrations/Roles/CreateRole";
import GoodsTransfer from "../../ClientDashboard/InternalMovements/GoodsTransfers";
import CreateGoodsTransfer from "../../ClientDashboard/InternalMovements/GoodsTransfers/CreateGoodTransferComponent/CreateGoodsTransfer";
import Agents from "../../ClientDashboard/Users/Agents";
import CreateAgents from "../../ClientDashboard/Users/Agents/CreateAgents";
import UpdateAgents from "../../ClientDashboard/Users/Agents/UpdateAgents";
import Sales from "../../ClientDashboard/Sales/SalesInvoice/Index";
import CreateSale from "../../ClientDashboard/Sales/SalesInvoice/CreateSale";
import PaymentMethods from "../../PaymentMethods";
import CreatePaymentMethods from "../../PaymentMethods/CreatePaymentMethods";
import UpdatePaymentMethods from "../../PaymentMethods/UpdatePaymentMethods";
import UpdateRole from "../../ClientDashboard/Registrations/Roles/UpdateRole";
import Configurations from "../../Configurations";
import EntryItemsOrders from "../../ClientDashboard/EntryItems/EntryItemsOrders";
import CreateEntryItemsOrders from "../../ClientDashboard/EntryItems/EntryItemsOrders/CreateEntryItemsOrders";
import AutomaticEntryItemsOrders from "../../ClientDashboard/EntryItems/AutomaticEntryItemsOrder";
import SaleOrder from "../../ClientDashboard/EntryItems/SaleOrder/Index";
import CreateSaleOrder from "../../ClientDashboard/EntryItems/SaleOrder/CreateSaleOrder";
import InternalSale from "../../ClientDashboard/EntryItems/InternalSales/Index";
import CreateInternalSale from "../../ClientDashboard/EntryItems/InternalSales/CreateInternalSale";
import ProductSearchColumns from "../../ClientDashboard/ProductSearchColumns";
import SupplierAgreements from "../../ClientDashboard/SupplierAgreements";
import CreateSupplierAgreements from "../../ClientDashboard/SupplierAgreements/CreateSupplierAgreements";
import AccountingConfigurations from "../../ClientDashboard/AccountingConfigurations";
import FinancialAccounts from "../../ClientDashboard/FinancialAccounts";
import ProtectedComponent from "../../Authorization/ProtectedComponent";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import { Menu } from "antd";
import MenuItem from "antd/es/menu/MenuItem";
import FinInvoiceRegistrations from "../../ClientDashboard/FinInvoiceRegistrations";
import CreateFinInvoiceRegistration from "../../ClientDashboard/FinInvoiceRegistrations/CreateFinInvoiceRegistration";












const ClientDashboardLayoutRoutes: React.FC = () => (
  <ClientLayoutComponent>
    <Routes>
      <Route path="" element={
        <ProtectedComponent component={<ClientDashboard />} menuOptionId={MenuOptionEnum.DashboardAccess} />} />
      {/* <Route path="/taxes" element={<Taxes/>}/>
      <Route path="/tax/register" element={<CreateTax/>}/>
      <Route path="/tax/update/:id" element={<UpdateTax/>}/> 
       <Route path="/entityTypes" element={<EntityTypes/>}/>
      <Route path="/entityType/register" element={<CreateEntityType/>}/>
      <Route path="/entityType/update/:id" element={<UpdateEntityType/>}/> 
       <Route path="/countries" element={<Countries/>}/>
      <Route path="/country/register" element={<CreateCountry/>}/>
      <Route path="/country/update/:id" element={<UpdateCountry/>}/>
      <Route path="/cities" element={<Cities/>}/>
      <Route path="/city/register" element={<CreateCity/>}/>
      <Route path="/city/update/:id" element={<UpdateCity/>}/>
      <Route path="/unitMeasures" element={<UnitMeasures/>}/>
      <Route path="/unitMeasure/register" element={<CreateUnitMeasure/>}/>
      <Route path="/unitMeasure/update/:id" element={<UpdateUnitMeasure/>}/>
      <Route path="/itemTypes" element={<ItemTypes/>}/>
      <Route path="/itemType/register" element={<CreateItemType/>}/>
      <Route path="/itemType/update/:id" element={<UpdateItemType/>}/> */}
      {/* <Route path="/itemStatuses" element={<ItemStatuses/>}/>
      <Route path="/itemStatus/register" element={<CreateItemStatus/>}/>
      <Route path="/itemStatus/update/:id" element={<UpdateItemStatus/>}/> */}

      <Route path="/brands" element={
        <ProtectedComponent component={<Brands />} menuOptionId={MenuOptionEnum.BrandsAccess} />} />
      <Route path="/brand/register" element={
        <ProtectedComponent component={<CreateBrand />} menuOptionId={MenuOptionEnum.BrandsCreate} />} />
      <Route path="/brand/update/:id" element={
        <ProtectedComponent component={<UpdateBrand />} menuOptionId={MenuOptionEnum.BrandsCreate} />} />
      <Route path="/entities" element={
        <ProtectedComponent component={<Entities />} menuOptionId={MenuOptionEnum.EntitiesAccess} />} />
      <Route path="/entity/register" element={
        <ProtectedComponent component={<CreateEntity />} menuOptionId={MenuOptionEnum.EntitiesCreate} />} />
      <Route path="/entity/update/:id" element={
        <ProtectedComponent component={<UpdateEntity />} menuOptionId={MenuOptionEnum.EntitiesCreate} />} />
      <Route path="/customs" element={
        <ProtectedComponent component={<Customs />} menuOptionId={MenuOptionEnum.CustomsAccess} />} />
      <Route path="/custom/register" element={
        <ProtectedComponent component={<CreateCustom />} menuOptionId={MenuOptionEnum.CustomsCreate} />} />
      <Route path="/custom/update/:id" element={
        <ProtectedComponent component={<UpdateCustom />} menuOptionId={MenuOptionEnum.CustomsCreate} />} />
      <Route path="/items" element={
        <ProtectedComponent component={<Items />} menuOptionId={MenuOptionEnum.ItemsAccess} />} />
      <Route path="/item/register" element={
        <ProtectedComponent component={<CreateItem />} menuOptionId={MenuOptionEnum.ItemsCreate} />} />
      <Route path="/item/update/:id" element={
        <ProtectedComponent component={<UpdateItem />} menuOptionId={MenuOptionEnum.ItemsCreate} />} />
      <Route path="/classifiers" element={
        <ProtectedComponent component={<Classifiers />} menuOptionId={MenuOptionEnum.ClassifiersAccess} />} />
      <Route path="/classifier/update/:id" element={
        <ProtectedComponent component={<ClassifierMainComponent />} menuOptionId={MenuOptionEnum.ClassifiersCreate} />} />
      <Route path="/items/import" element={
        <ProtectedComponent component={<ImportArticles />} menuOptionId={MenuOptionEnum.ItemsImport} />} />
      <Route path="import-templates" element={
        <ProtectedComponent component={<ImportTemplates />} menuOptionId={MenuOptionEnum.ItemsImport} />} />
      <Route path="/localPurchases" element={
        <ProtectedComponent component={<LocalPurchases />} menuOptionId={MenuOptionEnum.LocalPurchasesAccess} />} />
      <Route path="/localPurchase/:mode/:id?/:branchId?" element={
        <ProtectedComponent component={<CreateLocalPurchase />} menuOptionId={MenuOptionEnum.LocalPurchasesCreate} />} />
      <Route path="/invoiceWithRemarksReasons" element={
        <ProtectedComponent component={<InvoiceWithRemarksReasons />} menuOptionId={MenuOptionEnum.InvoiceWithRemarksReasonsAccess} />} />
      <Route path="/goodsReturnReasons" element={
        <ProtectedComponent component={<GoodsReturnReasons />} menuOptionId={MenuOptionEnum.GoodsReturnReasonsAccess} />} />
      <Route path="/currencies" element={
        <ProtectedComponent component={<Currencies />} menuOptionId={MenuOptionEnum.CurrenciesAccess} />} />
      <Route path="/currency/register" element={
        <ProtectedComponent component={<CreateCurrency />} menuOptionId={MenuOptionEnum.CurrenciesCreate} />} />
      <Route path="/currency/update/:id" element={
        <ProtectedComponent component={<UpdateCurrency />} menuOptionId={MenuOptionEnum.CurrenciesCreate} />} />
      <Route path="/branches" element={
        <ProtectedComponent component={<Branches />} menuOptionId={MenuOptionEnum.BranchesAccess} />} />
      <Route path="/branch/register" element={
        <ProtectedComponent component={<CreateBranch />} menuOptionId={MenuOptionEnum.BranchesCreate} />} />
      <Route path="/branch/update/:id" element={
        <ProtectedComponent component={<UpdateBranch />} menuOptionId={MenuOptionEnum.BranchesCreate} />} />
      <Route path="/users" element={
        <ProtectedComponent component={<Users />} menuOptionId={MenuOptionEnum.UsersAccess} />} />
      <Route path="/user/register" element={
        <ProtectedComponent component={<CreateUser />} menuOptionId={MenuOptionEnum.UsersCreate} />} />
      <Route path="/user/update/:id" element={
        <ProtectedComponent component={<UpdateUser />} menuOptionId={MenuOptionEnum.UsersCreate} />} />
      <Route path="/importPurchases" element={
        <ProtectedComponent component={<ImportPurchases />} menuOptionId={MenuOptionEnum.ImportPurchasesAccess} />} />
      <Route path="/importPurchase/:mode/:id?/:branchId?" element={
        <ProtectedComponent component={<CreateImportPurchase />} menuOptionId={MenuOptionEnum.ImportPurchasesCreate} />} />
      <Route path="/prices" element={
        <ProtectedComponent component={<Price />} menuOptionId={MenuOptionEnum.PricesAccess} />} />
      <Route path="/price/:mode/:id?/:branchId?" element={
        <ProtectedComponent component={<CreatePrice />} menuOptionId={MenuOptionEnum.PricesCreate} />} />
      <Route path="/cashRegisters" element={
        <ProtectedComponent component={<CashRegisters />} menuOptionId={MenuOptionEnum.CashRegisterAccess} />} />
      <Route path="/cashRegister/register" element={
        <ProtectedComponent component={<CreateCashRegister />} menuOptionId={MenuOptionEnum.CashRegisterCreate} />} />
      <Route path="/cashRegister/update/:id" element={
        <ProtectedComponent component={<UpdateCashRegister />} menuOptionId={MenuOptionEnum.CashRegisterCreate} />} />
      <Route path="/documentPeriods" element={
        <ProtectedComponent component={<DocumentPeriods />} menuOptionId={MenuOptionEnum.DocumentPeriodAccess} />} />
      <Route path="/roles" element={
        <ProtectedComponent component={<Roles />} menuOptionId={MenuOptionEnum.RoleAccess} />} />
      <Route path="/role/register" element={
        <ProtectedComponent component={<CreateRole />} menuOptionId={MenuOptionEnum.RoleCreate} />} />
      <Route path="/goodsTransfer" element={
        <ProtectedComponent component={<GoodsTransfer />} menuOptionId={MenuOptionEnum.GoodsTransferAccess} />} />
      <Route path="/goodsTransfer/:mode/:id?" element={
        <ProtectedComponent component={<CreateGoodsTransfer />} menuOptionId={MenuOptionEnum.GoodsTransferCreate} />} />
      <Route path="/agents" element={
        <ProtectedComponent component={<Agents />} menuOptionId={MenuOptionEnum.AgentAccess} />} />
      <Route path="/agents/register" element={
        <ProtectedComponent component={<CreateAgents />} menuOptionId={MenuOptionEnum.AgentCreate} />} />
      <Route path="/agents/update/:id" element={
        <ProtectedComponent component={<UpdateAgents />} menuOptionId={MenuOptionEnum.AgentCreate} />} />
      <Route path="/sales" element={
        <ProtectedComponent component={<Sales />} menuOptionId={MenuOptionEnum.SaleInvoiceAccess} />} />
      <Route path="/sales/:mode/:id?/:branchId?" element={
        <ProtectedComponent component={<CreateSale />} menuOptionId={MenuOptionEnum.SaleInvoiceCreate} />} />
      <Route path="/paymentMethods" element={
        <ProtectedComponent component={<PaymentMethods />} menuOptionId={MenuOptionEnum.PaymentMethodsAccess} />} />
      <Route path="/paymentMethod/register" element={
        <ProtectedComponent component={<CreatePaymentMethods />} menuOptionId={MenuOptionEnum.PaymentMethodsCreate} />} />
      <Route path="/paymentMethod/update/:id" element={
        <ProtectedComponent component={<UpdatePaymentMethods />} menuOptionId={MenuOptionEnum.PaymentMethodsCreate} />} />
      <Route path="/configurations" element={
        <ProtectedComponent component={<Configurations />} menuOptionId={MenuOptionEnum.ConfigurationsModuleAccess} />} />
      |
      <Route path="/role/update/:id" element={
        <ProtectedComponent component={<UpdateRole />} menuOptionId={MenuOptionEnum.RoleCreate} />} />
      <Route path="/entryItemsOrders" element={
        <ProtectedComponent component={<EntryItemsOrders />} menuOptionId={MenuOptionEnum.EntryOrderAccess} />} />
      <Route path="/entryItemsOrders/:type/:mode/:id?/:branchId?" element={
        <ProtectedComponent component={<CreateEntryItemsOrders />} menuOptionId={MenuOptionEnum.EntryOrderCreate} />} />
      <Route path="/automaticEntryItemsOrders" element={
        <ProtectedComponent component={<AutomaticEntryItemsOrders />} menuOptionId={MenuOptionEnum.AutomaticOrderAccess} />} />
      <Route path="/saleOrder" element={
        <ProtectedComponent component={<SaleOrder />} menuOptionId={MenuOptionEnum.SaleOrderAccess} />} />
      <Route path="/saleOrder/:mode/:id?/:branchId?" element={
        <ProtectedComponent component={<CreateSaleOrder />} menuOptionId={MenuOptionEnum.SaleOrderCreate} />} />
      <Route path="/internalSale" element={
        <ProtectedComponent component={<InternalSale />} menuOptionId={MenuOptionEnum.InternalSaleAccess} />} />
      <Route path="/internalSale/:mode/:id?/:branchId?" element={
        <ProtectedComponent component={<CreateInternalSale />} menuOptionId={MenuOptionEnum.InternalSaleCreate} />} />
      <Route path="/searchProductsColumns" element={
        <ProtectedComponent component={<ProductSearchColumns />} menuOptionId={MenuOptionEnum.ProductColumnsAccess} />} />
      <Route path="/supplierAgreements" element={
        <ProtectedComponent component={<SupplierAgreements />} menuOptionId={MenuOptionEnum.SupplierAgreementsAccess} />} />
      <Route path="/supplierAgreements/:mode/:id?" element={
        <ProtectedComponent component={<CreateSupplierAgreements />} menuOptionId={MenuOptionEnum.SupplierAgreementsCreate} />} />
      <Route path="/accountingConfiguration" element={
        <ProtectedComponent component={<AccountingConfigurations />} menuOptionId={MenuOptionEnum.AccountingConfigurationAccess} />} />
      <Route path="/financialAccounts" element={
        <ProtectedComponent component={<FinancialAccounts />} menuOptionId={MenuOptionEnum.FinancialAccountsAccess} />} />
      <Route path="/finInvoiceRegistrations" element={
        <ProtectedComponent component={<FinInvoiceRegistrations />} menuOptionId={MenuOptionEnum.EntryOrderAccess} />} />
      <Route path="/finInvoiceRegistration/:mode/:id?/:branchId?" element={
        <ProtectedComponent component={<CreateFinInvoiceRegistration />} menuOptionId={MenuOptionEnum.EntryOrderCreate} />} />
    </Routes>
  </ClientLayoutComponent>
);

export default ClientDashboardLayoutRoutes;


