export const rolesForAdmin = () => {
  return [
    {
      label: "System Administrator",
      value: 1,
    },
    {
      label: "Accountant ",
      value: 2,
    },
    {
      label: "Owner",
      value: 3,
    },
    {
      label: "CompanyUser ",
      value: 4,
    },
  ];
};

export const rolesForAccountant = [
  {
    label: "Owner",
    value: 3,
  },
  {
    label: "CompanyUser ",
    value: 4,
  },
];

export const rolesForOwner = [
  {
    label: "CompanyUser ",
    value: 4,
  },
];


export const adminRoles = [
  { key: 1, description: 'systemAdministrator', value: 1 },
  { key: 2, description: 'accountant', value: 2 },
  { key: 3, description: 'owner', value: 3 },
  // { key: 4, description: 'companyUser', value: 4 },
];