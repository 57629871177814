import { Input, Form } from "antd";
import { ErrorMessage, useField } from "formik";
import classes from "./CustomInputStyle.module.css";
const { TextArea } = Input;

interface Props {
  name: string;
  label: string;
  rules: any[];
  placeholder: string;
  rows?: number;
  className?: string;
  style?: any;
  value?: string;
  required?: boolean;
}

export default function FormTextArea({
  name,
  label,
  rules,
  placeholder,
  rows = 3,
  className = "",
  style = {},
  value,
  required,
}: Props) {
  const [field, meta, helpers] = useField(name);
  const hasError = meta.touched && meta.error;

  return (
    <Form.Item
      required={required}
      name={name}
      label={label}
      rules={rules}
      className={className}
      style={style}
    >
      <>
        <TextArea
          {...field}
          rows={rows}
          placeholder={placeholder}
          value={value ?? field.value}
        />
        <ErrorMessage
          name={name}
          component="div"
          className={classes.errorMessage}
        />
      </>
    </Form.Item>
  );
}
