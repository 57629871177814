import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { Button, Typography, Tooltip, Popconfirm } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { renderFilterInputs } from "../../../helperMethods/renderFilterInputs";
import { getEntityFilterColumnsAsync } from "../../../redux/slices/entitySlice";
import { getEntitiesWithFilters } from "../../../redux/slices/entitySlice";
import {
  deleteEntityAsync,
  getEntitiesAsync,
} from "../../../redux/slices/entitySlice";
import AgGridTableWithPagination from "../../CustomComponents/AgGridTableWithPagination";
import { db } from "../../../indexDB/clientSideDatabase";
import useFilters from "../../../customHooks/useFilter";
import apiService from "../../../extensions/api";
import FilterModal from "../../CustomComponents/FilterModal";
import { toast } from "react-toastify";
import {
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import "../../../assets/generalStyles/GridThemeStyles.css"
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import { usePermission } from "../../../customHooks/usePermission";
import WithPermission from "../../Authorization/WithPermission";


const { Title, Text, Link } = Typography;

const Entities = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const entities = useSelector((state) => state.entity.entities);
  const t = useAppTranslation("ClientDashboard.Entities");
  const dispatch = useDispatch();
  const [menuEntitiesDisabled, setMenuEntitiesDisabled] = useState(false);

  const deleteEntity = async (entityID) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      dispatch(deleteEntityAsync(entityID));
    }
  };

  const handleClearFilters = async () => {
    const result = dispatch(getEntitiesWithFilters([]));
    const data = result.payload;

    if (gridApi) {
      gridApi.setRowData(data);
    }
  };

  const handleFilterSubmit = async (filters) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;
    const result = dispatch(getEntitiesWithFilters(filteredFilters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const handleExportEntities = async () => {
    const entityIds = entities.map((entity) => entity.entityId);

    setMenuEntitiesDisabled(true);

    try {
      const response = await apiService.post(
        "/api/ExcelTemplates/export-entities",
        entityIds
      );
      let base64Data = response.data.Data;

      if (typeof base64Data === "string") {
        base64Data = base64Data.replace(/"/g, "");
      }

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "entities.xlsx";
      downloadLink.click();
    } catch (error) {
      toast.error("Error downloading file");
    } finally {
      setMenuEntitiesDisabled(false);
    }
  };

  const handleCreateNewEntity = () => {
    navigate("/entity/register");
    db.addTab(t("tabs.createEntity"), "entity/register", undefined);
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getEntityFilterColumnsAsync());
    return result;
  };
  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "entityId",
      headerName: "ID",
      width: 80,
      cellRenderer: (params) => {
        return <Text className="ag-grid-id-column" >{params?.data?.entityId}</Text>;
      },
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      suppressMenu: true,
      width: 250
    },
    {
      field: "tradeName",
      headerName: t("tableHeaders.tradeName"),
      suppressMenu: true,
    },
    {
      field: "entityTypeDescription",
      headerName: t("tableHeaders.entityTypeDescription"),
      suppressMenu: true,
    },
    {
      field: "isSupplier",
      headerName: t("tableHeaders.isSupplier"),
      suppressMenu: true,
    },
    {
      field: "isCustomer",
      headerName: t("tableHeaders.isCustomer"),
      suppressMenu: true,
    },
    {
      field: "businessNumber",
      headerName: t("tableHeaders.businessNumber"),
      suppressMenu: true,
    },
    {
      field: "vatNumber",
      headerName: t("tableHeaders.vATNumber"),
      suppressMenu: true,
    },
    {
      field: "address",
      headerName: t("tableHeaders.address"),
      suppressMenu: true,
      width: 250
    },
    { field: "email", headerName: t("tableHeaders.email"), suppressMenu: true, width: 250 },
    { field: "phone", headerName: t("tableHeaders.phone"), suppressMenu: true },
    {
      field: "contactPerson",
      headerName: t("tableHeaders.contactPerson"),
      suppressMenu: true,
      width: 140,
    },
    {
      field: "status",
      headerName: t("tableHeaders.status"),
      suppressMenu: true,
      width: 100,
    },
    {
      field: "comment",
      headerName: t("tableHeaders.comment"),
      suppressMenu: true,
      width: 180,
    },
    {
      field: "licenseNumbers",
      headerName: t("tableHeaders.licenseNumbers"),
      suppressMenu: true,
      width: 100,
    },
    {
      field: "details",
      suppressMenu: true,
      headerName: t("tableHeaders.details"),
      cellRenderer: (params) => (
        <WithPermission
        menuOptionId={MenuOptionEnum.EntitiesCreate}
        children={
        <Button
          type="text"
          icon={<RightOutlined />}
          onClick={() => navigate(`/entity/update/${params.data.entityId}`)}
          style={{
            color: "#007FFF",
          }}
        ></Button>
      }
      />
      ),
      width: 90,
    },
  ]);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params) => {
    params.columnApi.autoSizeColumns(["tradeName"]);
    params.columnApi.autoSizeColumns(["entityTypeDescription"]);
    params.columnApi.autoSizeColumns(["isSupplier"]);
    params.columnApi.autoSizeColumns(["isCustomer"]);
    params.columnApi.autoSizeColumns(["businessNumber"]);
    params.columnApi.autoSizeColumns(["vatNumber"]);
    params.columnApi.autoSizeColumns(["phone"]);
    params.columnApi.autoSizeColumns(["contactPerson"]);
    params.columnApi.autoSizeColumns(["status"]);
    params.columnApi.autoSizeColumns(["comment"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });

  useEffect(() => {
    // dispatch(getEntitiesAsync());
    dispatch(getEntitiesWithFilters([]));
  }, [dispatch]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.EntitiesCreate)


  const handleRowDoubleClick = (event) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateEntity"),
        `entity/update/${event.data.entityId}`,
        undefined
      );
      navigate(`/entity/update/${event.data.entityId}`);
    }
  };

  const [perPage, setPerPage] = useState(20);
  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          const page = Math.floor(params.startRow / perPage) + 1;
          dispatch(getEntitiesAsync({ pageNumber: page, pageSize: perPage }))
            .then((data) => {
              params.successCallback(
                data.payload.list,
                data.payload.totalCount
              );
            })
            .catch((err) => {
              console.log(err);
              params.successCallback([], 0);
            });
        },
      };
      gridApi.updateGridOptions({ datasource: dataSource });
    }
  }, [gridApi, dispatch, perPage]);

  return (

    <>
      <ListHeaderComponent
        title={t("titles.entities")}
        createRecord={handleCreateNewEntity}
        menuItemsDisabled={menuEntitiesDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={handleExportEntities}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.EntitiesCreate}
        exportAccessEnum={MenuOptionEnum.EntitiesExport}
      />

      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState?.filterInputs?.map((input) => (
          <div
            key={input.name}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Text>
              {input.isClassifier
                ? input.name
                : t(`FilterColumns.${input.name}`)}
            </Text>
            {renderFilterInputs({ input: input, filterState: filterState })}
          </div>
        ))}
      </div>
      {filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button
            onClick={filterState.handleClearFilters}
            disabled={filterState.clearFilterButtonDisabled}
          >
            {t("clearFilters")}
          </Button>
          <Button
            type="primary"
            onClick={filterState.handleFilterSubmit}
            disabled={
              filterState.filterButtonDisabled || !filterState.inputsChanged
            }
          >
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={entities}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.Entities"
      />
    </>
  );
};

export default Entities;
