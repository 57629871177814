import { useCallback, useEffect, useState } from "react";
import { debounce } from "../../helperMethods/debounce";
import { Form, Select } from "antd";
import { ErrorMessage } from "formik";
import FloatLabel from "./FloatLabel";
import { SelectOption } from "../../models/SelectOption";

interface Option {
  key?: string;
  value: string | number;
  label: string;
}

interface Props {
  name: string;
  value: any;
  onChange: (option: SelectOption) => void;
  label: string;
  fetchOptions: (searchText: string) => Promise<SelectOption[]>;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  style?: any;
  rules?: any[];
  placeHolder?: string;
  fetchInitialOption?: (value: string | number) => SelectOption | null;
}

export default function FormSelectWithSearchWithoutFormik({
  name,
  value,
  onChange,
  label,
  fetchOptions,
  required,
  disabled,
  style,
  rules,
  className,
  placeHolder,
  fetchInitialOption,
}: Props) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [initialFetched, setInitialFetched] = useState<boolean>(false);

  useEffect(() => {
    const fetchInitial = async () => {
      if (value && fetchInitialOption) {
        setLoading(true);
        try {
          const initialOption = fetchInitialOption(value);
          if (initialOption) {
            setOptions([initialOption]);
          }
        } catch (error) {
          console.error("Error fetching initial option:", error);
        } finally {
          setLoading(false);
          setInitialFetched(true);
        }
      }
    };

    fetchInitial();
  }, []);

  const debouncedFetchOptions = useCallback(
    debounce(async (searchText: string) => {
      if (searchText) {
        setLoading(true);
        try {
          const fetchedOptions = await fetchOptions(searchText);
          setOptions(fetchedOptions);
        } catch (error) {
          console.error("Error fetching options:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setOptions([]);
      }
    }, 1000),
    [fetchOptions]
  );
  return (
    <Form.Item
      required={required}
      // label={label}
      className={className}
      style={style}
      rules={rules}
    >
        <Select
          showSearch
          // placeholder={placeHolder ?? "Start typing to search"}
          value={value}
          onChange={(value, option) => {
            const selectedOption = option as SelectOption;
            onChange(selectedOption);
          }}
          disabled={disabled}
          onSearch={debouncedFetchOptions}
          filterOption={false}
          notFoundContent={loading ? "Loading..." : null}
          style={{ width: "100%" }}
          options={options}
        />
    </Form.Item>
  );
}
