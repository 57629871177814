import { Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import WithPermission from "../../Authorization/WithPermission";
import { MenuAdminOptionEnum } from "../../../enums/MenuAdminOptionEnum";
import { db } from "../../../indexDB/clientSideDatabase";
import {
    RightOutlined,
  } from "@ant-design/icons";
import { getAdminUsersAsync } from "../../../redux/slices/userSlice";
import { usePermission } from "../../../customHooks/usePermission";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";


const { Title, Text, Link } = Typography;

const AdminUser = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const adminUsers = useSelector((state: RootState) => state.users.adminUsers);
  const t = useAppTranslation("AdminDashboard.AdminUser");
  const dispatch = useDispatch<AppDispatch>();
  //const [menuCurrenciesDisabled, setMenuCurrenciesDisabled] = useState(false);

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "usersID",
      headerName: "ID",
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.usersID}</Text>;
      },
      suppressMenu: true
    },
    { field: "firstName", headerName: t("tableHeaders.firstName"), suppressMenu: true },
    { field: "lastName", headerName: t("tableHeaders.lastName"), suppressMenu: true },
    { field: "username", headerName: t("tableHeaders.userName"), suppressMenu: true },
    { field: "email", headerName: t("tableHeaders.email"), suppressMenu: true },
    { field: "status", headerName: t("tableHeaders.status"), suppressMenu: true },
    { field: "adminRoleDescription", headerName: t("tableHeaders.AdminRoleDescription"), suppressMenu: true },
    {
      headerName: t("tableHeaders.options"), suppressMenu: true, width: 100,
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuAdminOptionEnum.AdminUsersCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateAdminUser"),
                  `update/${params?.data?.usersID}`,
                  undefined
                );
                navigate(`update/${params?.data?.usersID}`);
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
    },
  ]);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['firstName']);
    params.columnApi.autoSizeColumns(['lastName']);
    params.columnApi.autoSizeColumns(['userName']);
    params.columnApi.autoSizeColumns(['email']);
    params.columnApi.autoSizeColumns(['status']);
    params.columnApi.autoSizeColumns(['AdminRoleDescription']);

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getAdminUser = async () => {
    const result = await dispatch(getAdminUsersAsync());
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  useEffect(() => {
    getAdminUser();
  }, [dispatch, gridApi]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuAdminOptionEnum.AdminUsersCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      navigate(`update/${event.data.usersID}`);
    }
  };

  const handleCreateNewAdminUser = () => {
    navigate("/dashboard/adminUsers/register");
  };

  return (
    <>
      <ListHeaderComponent
        title={t("titles.adminUser")}
        createRecord={handleCreateNewAdminUser}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuAdminOptionEnum.AdminUsersCreate}
      />
      <AgGridTableComponent
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={adminUsers}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
    </>
  );
};

export default AdminUser;
