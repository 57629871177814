import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FiscalPayMethodTypeState } from "../../models/clientDashboard/PaymentMethods/FiscalPayMethodTypeState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { FiscalPayMethodTypeDto } from "../../models/clientDashboard/PaymentMethods/FiscalPayMethodTypeDto";
import { RootState } from "..";
import { ApiResponse } from "../../models/ApiResponse";
import apiService from "../../extensions/api";

const initialState: FiscalPayMethodTypeState = {
  fiscalPayMethodTypes: [],
  error: null,
};

const FiscalPayMethodTypesSlice = createSlice({
  name: "fiscalPayMethodTypes",
  initialState,
  reducers: {
    getFiscalPayMethodTypesStart(state: FiscalPayMethodTypeState) {
      setLoading(true);
      state.error = null;
    },
    getFiscalPayMethodTypesSuccess(
      state: FiscalPayMethodTypeState,
      action: PayloadAction<FiscalPayMethodTypeDto[]>
    ) {
      setLoading(false);
      state.fiscalPayMethodTypes = action.payload;
    },
    getFiscalPayMethodTypesFailure(
      state: FiscalPayMethodTypeState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});

export const getFiscalPayMethodTypesAsync = createAsyncThunk(
  "FiscalPayMethodTypes/getFiscalPayMethodTypes",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const fiscalPayMethodTypes = state.paymentMethod.paymentMethods;

    if (fiscalPayMethodTypes.length > 0) {
      return fiscalPayMethodTypes;
    }

    try {
      dispatch(getFiscalPayMethodTypesStart());
      const response = await apiService.get<
        ApiResponse<FiscalPayMethodTypeDto[]>
      >("/api/PaymentMethod/fiscalPayMethodTypes");
      dispatch(getFiscalPayMethodTypesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getFiscalPayMethodTypesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  getFiscalPayMethodTypesStart,
  getFiscalPayMethodTypesSuccess,
  getFiscalPayMethodTypesFailure,
} = FiscalPayMethodTypesSlice.actions;

export default FiscalPayMethodTypesSlice.reducer;
