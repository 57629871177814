import { Col, Form, Input, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../../models/clientDashboard/Users/User";
import { setLoading } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../indexDB/clientSideDatabase";
import { getUserDataById, getUsersSuccess } from "../../../redux/slices/userSlice";
import useFormData from "../../../customHooks/useFormData";
import { Formik, FormikErrors } from "formik";
import FormInput from "../../CustomComponents/FormInput";
import FormSwitch from "../../CustomComponents/FormSwitch";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { useEffect, useRef } from "react";
import { UpdateUserDto } from "../../../models/clientDashboard/Users/UpdateUserDto";
import { getBranchesAsync } from "../../../redux/slices/branchSlice";
import FormSelect from "../../CustomComponents/FormSelect";
import * as Yup from "yup";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { BranchUsers } from "../../../models/clientDashboard/BranchUsers/BranchUsers";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";



const { Title, Text } = Typography;

const UpdateUser = () => {
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const { id } = useParams();
    const formikRef = useRef<any>(null);
    const branches = useSelector((state: RootState) => state.branch.branches);
    const t = useAppTranslation("ClientDashboard.UpdateUser");


    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const { initialValues } = useFormData<UpdateUserDto>(
        `user/update/${id}`,
        { status: true } as UpdateUserDto,
        undefined,
        {
            fetchData: async () => {
                const userAction = await dispatch(getUserDataById(parseInt(id ?? "0")));
                if (userAction.type === 'Users/getUserDataById/fulfilled') {
                    const userData = userAction.payload as any;
                    const branches:number[] = userData.branches.map((branch:BranchUsers)=> branch.branchId)
                    const newUserData:UpdateUserDto = {
                        ...userData,
                        branches:branches
                    }
                    return newUserData
                } else {
                    toast.error('user not found')
                    return { status: true } as UpdateUserDto
                }
            }
        }
    )
    useEffect(() => {
        dispatch(getBranchesAsync());
    }, [dispatch, id])

    const onFinish = async (values: UpdateUserDto) => {
        dispatch(setLoading(true));

        values.passCodeDigit = values.passCodeDigit?.toString();

        await apiService
            .put("/api/client/User/update", values)
            .then((response) => {
                toast.success(t("userUpdatedSuccessfully"));
                db.deleteTab(`user/update/${id}`);
                dispatch(getUsersSuccess([]))
                navigate("/useres");
            })
            .catch(() => { })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t("firstNameRequired")),
        lastName: Yup.string().required(t("lastNameRequired")),
        username: Yup.string().required(t("usernameRequired")),
        email: Yup.string().required(t("emailRequired")),
        // newPassword: Yup.string(),
        // confirmPassword: Yup.string()
        // .test('passwords-match', 'Passwords must match', function(value) {
        //     const { newPassword } = this.parent;
        //     if (!newPassword || !value) return true;
        //     return newPassword === value;
        // }),
        branches: Yup.array()
            .of(Yup.string().required())
            .min(1, "At least one branch must be selected.")
            .required(t("branchRequired"))
    });
    

    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (
            values?: any
        ) => any
    ) => {
        const errors = await validateForm()||{};
        setTouched({
            usersID: true,
            firstName: true,
            lastName: true,
            username: true,
            // confirmPassword:true,
            // newPassword:true,
            email: true,
            fiscalCode: true,
            status: true,
            branches:true
        });

        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    }



    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={initialValues ?? {} as UpdateUserDto}
                onSubmit={onFinish}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
            >
                {({ values,
                    handleSubmit,
                    submitForm,
                    validateForm, setTouched }) => (
                    <>
                        <FormHeaderOptions
                            title={t("updateFormTitle")}
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("updateUserButton")}
                            submitButtonIsDisabled={isLoading}
                            createAccessEnum={MenuOptionEnum.UsersCreate}
                        />
                        <Form
                            layout="vertical"
                            onFinish={handleSubmit}
                            style={{ marginTop: 20 }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12}>
                                    <FormInput
                                        name="firstName"
                                        label={t("firstNameInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("firstNameInputPlaceholder"),
                                            },

                                        ]}
                                        required={true}
                                        value={values.firstName}
                                    />
                                    <FormInput
                                        name="lastName"
                                        label={t("lastNameInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("lastNameInputPlaceholder"),
                                            },
                                        ]}
                                        required={true}
                                        value={values.lastName}
                                    />
                                    <FormInput
                                        name="username"
                                        label={t("userNameInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("userNameInputPlaceholder"),
                                            },
                                        ]}
                                        required={true}
                                        value={values.username}
                                    />
                                    <FormInput
                                        name="fiscalCode"
                                        label={t("fiscalCodeInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("fiscalCodeInputPlaceholder"),
                                            },

                                        ]}
                                        value={values.fiscalCode}
                                    />
                                </Col>
                                <Col xs={24} sm={12}>
                                    <FormInput
                                        name="email"
                                        label={t("emailInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("emailInputPlaceholder"),
                                            },

                                        ]}
                                        required={true}
                                        value={values.email}
                                    />
                                    {/* <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={8}>
                                            <Form.Item
                                                name="password"
                                                label={t("oldPasswordInputLabel")}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: t("oldPasswordInputPlaceholder"),
                                                    },

                                                ]}
                                            >
                                                <Input.Password
                                                    prefix={<LockOutlined />}
                                                    placeholder={t("oldPasswordInputLabel")}
                                                    value={values.password}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            <Form.Item
                                                name="newPassword"
                                                label={t("newPasswordInputLabel")}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: t("newPasswordInputPlaceholder"),
                                                    },

                                                ]}
                                            >
                                                <Input.Password
                                                    placeholder={t("newPasswordInputLabel")}
                                                    prefix={<LockOutlined />}
                                                    value={values.newPassword}
                                                    onChange={(e) => formikRef.current.setFieldValue('newPassword', e.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            <Form.Item
                                                name="confirmPassword"
                                                label={t("confirmPasswordnputLabel")}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: t("confirmPasswordInputPlaceholder"),
                                                    },

                                                ]}
                                                >
                                                    <Input.Password
                                                    placeholder={t("confirmPasswordnputLabel")}
                                                    prefix={<LockOutlined />}
                                                value={values.confirmPassword}
                                                onChange={(e) => formikRef.current.setFieldValue('confirmPassword', e.target.value)}
                                            />
                                            </Form.Item>
                                        </Col>
                                    </Row> */}
                                    <FormSelect
                                        name="branches"
                                        label={t("branchesInputLabel")}
                                        rules={[
                                            {
                                                message: t("branchesInputPlaceholder"),
                                            },
                                        ]}
                                        required={true}
                                        value={values.branches}
                                        options={
                                            branches?.map((branch) => {
                                                return {
                                                    key: branch.branchId,
                                                    label: branch.description,
                                                    value: branch.branchId,
                                                };
                                            }) ?? []
                                        }
                                        multiple={true}
                                    />
                                    <FormInput
                                        name="passCodeDigit"
                                        type="number"
                                        label={t("passCodeDigitInputLabel")}
                                        maxLength={6}
                                        rules={[
                                            {
                                                message: t("passCodeDigitInputPlaceholder"),
                                            },

                                        ]}
                                        value={values.passCodeDigit?.toString()}

                                    />
                                    <FormSwitch
                                        name="status"
                                        label={t("statusInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("statusInputPlaceholder"),
                                            },

                                        ]}
                                        value={values.status}
                                    />
                                </Col>
                            </Row>
                            <HandleFormDataForTabSaving tabPath={`user/update/${id}`} />

                        </Form>
                    </>
                )}
            </Formik>
        </Spin>
    );
};


export default UpdateUser;