import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuState } from "../../models/clientDashboard/Menu/MenuState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { MenuDto } from "../../models/clientDashboard/Menu/MenuDto";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";

const initialState: MenuState = {
    menus: [],
    error: null,
};

const MenuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        getMenusStart(state: MenuState) {
            setLoading(true);
            state.error = null;
        },
        getMenusSuccess(
            state: MenuState,
            action: PayloadAction<MenuDto[]>
        ) {
            setLoading(false);
            state.menus= action.payload;
        },
        getMenusFailure(
            state: MenuState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        }
    }
});

export const getMenusAsync = createAsyncThunk(
    "Menus/getMenus",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        

        try {
            dispatch(getMenusStart());
            const response = await apiService.get<ApiResponse<MenuDto[]>>(
                "api/Menus"
            );
            // toast.success("Brands were successfully retrieved!");
            dispatch(getMenusSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getMenusFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getMenuDataById = createAsyncThunk(
    "Menus/getMenuDataById",
    async (menuId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<MenuDto>>(
                `/api/Menus?menuId=${menuId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getMenusStart,
    getMenusSuccess,
    getMenusFailure
} = MenuSlice.actions;

export default MenuSlice.reducer;