export enum MenuOptionEnum {
    NoAccess = -1,
    DashboardAccess = 0,
    RegistrationModuleAccess = 1,
    ItemsAccess,
    ItemsCreate,
    ItemsDelete,
    BrandsAccess,
    BrandsCreate,
    CustomsAccess,
    CustomsCreate,
    EntitiesAccess,
    EntitiesCreate,
    ItemsExport,
    ItemsImport,
    ClassifiersAccess,
    ClassifiersCreate,
    CurrenciesAccess,
    CurrenciesCreate,
    BranchesAccess,
    BranchesCreate,
    UsersAccess,
    UsersCreate,
    PurchasesModuleAccess,
    LocalPurchasesAccess,
    LocalPurchasesCreate,
    LocalPurchasesExport,
    ImportPurchasesAccess,
    ImportPurchasesCreate,
    ImportPurchasesExport,
    PricesModuleAccess,
    PricesAccess,
    PricesCreate,
    EntryOrderAccess,
    EntryOrderCreate,
    EntryOrderExport,
    EntryOrderValidate,
    AutomaticOrderAccess,
    AutomaticOrderCreate,
    AutomaticOrderExport,
    AutomaticOrderValidate,
    InternalMovementModuleAccess,
    GoodsTransferAccess,
    GoodsTransferCreate,
    GoodsTransferExport,
    GoodsTransferValidate,
    UsersModuleAccess,
    AgentAccess,
    AgentCreate,
    SalesModuleAccess,
    SaleInvoiceAccess,
    SaleInvoiceExport,
    SaleInvoiceCreate,
    SaleInvoiceValidate,
    SaleInvoiceCorrection,
    PaymentMethodsAccess,
    PaymentMethodsCreate,
    SaleOrderAccess,
    SaleOrderExport,
    SaleOrderCreate,
    SaleOrderValidate,
    InternalSaleAccess,
    InternalSaleExport,
    InternalSaleCreate,
    InternalSaleValidate,
    ConfigurationsModuleAccess,
    SystemConfigurationsAccess,
    ProductColumnsAccess,
    InvoiceWithRemarksReasonsAccess,
    InvoiceWithRemarksReasonsDelete,
    GoodsReturnReasonsAccess,
    GoodsReturnReasonsDelete,
    CashRegisterAccess,
    CashRegisterCreate,
    DocumentPeriodAccess,
    RoleAccess,
    RoleCreate,
    SupplierAgreementsAccess,
    SupplierAgreementsCreate,
    AccountingConfigurationAccess,
    FinancialAccountsAccess,
    FinancialModuleAccess,
    FinancialAccountsCreate,
    AccountingConfigurationCreate,
    EntitiesExport,
    InvoiceWithRemarksReasonsCreate,
    GoodsReturnReasonsCreate,
    DocumentPeriodCreate,
    PricesExport,
    PricesValidate,
    PricesInvalidate,
    LocalPurchasesValidate,
    LocalPurchasesInvalidate,
    LocalPurchasesStorno,
    ImportPurchasesValidate,
    ImportPurchasesInvalidate,
    ImportPurchasesStorno,
    EntryOrderInvalidate,
    AutomaticOrderInvalidate,
    SupplierAgreementsExport,
    SaleInvoiceInvalidate,
    SaleOrderInvalidate,
    InternalSaleInvalidate,
    LocalPurchasesPrint,
    ImportPurchasesPrint,
    GoodsTransferPrint,
    SaleInvoicePrint
}