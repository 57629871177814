import { Col, Form, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { useEffect, useMemo, useRef, useState } from "react";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate, useParams } from "react-router-dom";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { Formik, FormikErrors } from "formik";
import FormInput from "../../CustomComponents/FormInput";
import { CurrenciesDto } from "../../../models/clientDashboard/Currencies/CurrenciesDto";
import FormSwitch from "../../CustomComponents/FormSwitch";
import useFormData from "../../../customHooks/useFormData";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import { db } from "../../../indexDB/clientSideDatabase";
import {
  getCurrenciesSuccess,
  getEFiscalCurrencies,
} from "../../../redux/slices/currencySlice";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import * as Yup from "yup";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import FormSelect from "../../CustomComponents/FormSelect";
import { EFiscalCurrenciesDto } from "../../../models/clientDashboard/Currencies/EfiscalCurrenciesDto";
import { current } from "@reduxjs/toolkit";
import Currencies from ".";

const { Title, Text } = Typography;

const CreateCurrency = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const formikRef = useRef<any>(null);
  const { initialValues } = useFormData<CurrenciesDto>("currency/register", {
    status: true,
  } as CurrenciesDto);

  const t = useAppTranslation("ClientDashboard.CreateCurrency");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const [fiscalType, setFiscalTypes] = useState<EFiscalCurrenciesDto[]>([]);
  // const fiscalType = useSelector(
  //     (rootState: RootState) => rootState.fiscalTypes.fiscalType
  //   );
  const onFinish = async (values: CurrenciesDto) => {
    values.exchangeRate = parseFloat(values.exchangeRate.toFixed(4));
    dispatch(setLoadingState(true));

    await apiService
      .post("/api/Currencies", values)
      .then((response) => {
        toast.success(t("currencyCreatedSuccessfully"));
        db.deleteTab("currency/register");
        dispatch(getCurrenciesSuccess([]));
        navigate("/currencies");
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const validationSchema = Yup.object({
    currencyCode: Yup.string().required(t("currencyCodeRequired")),
    currencyName: Yup.string().required(t("currencyNameRequired")),
    exchangeRate: Yup.number()
      .required(t("exchangeRateRequired"))
      .min(0, t("exchangeRatePositive")),
  });
    const getEFiscalTypeOptions= async () => {
      const response = await dispatch(getEFiscalCurrencies());
      if (
        response.type ===
       "EFiscalCurrencies/getEFiscalCurrencies/fulfilled"
      ) {
          setFiscalTypes(response.payload as EFiscalCurrenciesDto[]);
      }
    };
    useEffect(() => {
      getEFiscalTypeOptions();
    }
  )
  const handleSubmitValidationForm = async (
    setTouched: ({}: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = (await validateForm()) || {};
    setTouched({
      currencyCode: true,
      currencyName: true,
      currencySymbol: true,
      exchangeRate: true,
      fiscalType: true,
      status: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };
  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({ status: true } as CurrenciesDto)}
        onSubmit={onFinish}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, submitForm, setTouched, validateForm }) => (
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            style={{ marginTop: 20 }}
          >
            <FormHeaderOptions
              title={t("formTitle")}
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createCurrencyButton")}
              submitButtonIsDisabled={isLoading}
              createAccessEnum={MenuOptionEnum.CurrenciesCreate}
            />

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <FormInput
                  name="currencyCode"
                  label={t("currencyCodeInputLabel")}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: t("currencyCodeInputPlaceholder"),
                    },
                  ]}
                  value={values.currencyCode}
                />
                <FormInput
                  name="currencyName"
                  label={t("currencyNameInputLabel")}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: t("currencyNameInputPlaceholder"),
                    },
                  ]}
                  value={values.currencyName}
                />
                <FormInput
                  name="currencySymbol"
                  label={t("currencySymbolInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("currencySymbolInputPlaceholder"),
                    },
                  ]}
                  value={values.currencySymbol}
                />
              </Col>
              <Col xs={24} sm={12}>
                <FormInput
                  name="exchangeRate"
                  type="number"
                  label={t("exchangeRateInputLabel")}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: t("exchangeRateInputPlaceholder"),
                    },
                  ]}
                  value={values.exchangeRate}
                />
                <FormSelect
                  name="fiscalType"
                  label={t("fiscalTypeInputLabel")}
                  required={true}
                  value={values.fiscalType}
                  options={
                    fiscalType?.map((fiscalType: any) => {
                      return {
                        key: fiscalType.eFiscalCurrencyId,
                        label: fiscalType.currencyName,
                        value: fiscalType.eFiscalCurrencyId,
                      };
                    }) ?? []
                  }
                />
                <FormSwitch
                  className="switch-for-currency"
                  name="status"
                  label={t("statusInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("statusInputPlaceholder"),
                    },
                  ]}
                  value={values.status}
                />
              </Col>
            </Row>
            <HandleFormDataForTabSaving tabPath="currency/register" />
          </Form>
        )}
      </Formik>
    </Spin>
  );
};

export default CreateCurrency;
