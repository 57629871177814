export default function FrameIcon() {
  return (
    <>
      <svg
        width="15px"
        className="icon-for-sidebar"
        height="15px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 1C6.44775 1 6 1.44775 6 2V6H2C1.44775 6 1 6.44775 1 7C1 7.55225 1.44775 8 2 8H6V16H2C1.44775 16 1 16.4478 1 17C1 17.5522 1.44775 18 2 18H6V22C6 22.5522 6.44775 23 7 23C7.55225 23 8 22.5522 8 22V18H16V22C16 22.5522 16.4478 23 17 23C17.5522 23 18 22.5522 18 22V18H22C22.5522 18 23 17.5522 23 17C23 16.4478 22.5522 16 22 16H18V8H22C22.5522 8 23 7.55225 23 7C23 6.44775 22.5522 6 22 6H18V2C18 1.44775 17.5522 1 17 1C16.4478 1 16 1.44775 16 2V6H8V2C8 1.44775 7.55225 1 7 1ZM8 8V16H16V8H8Z"
            fill="#5E5E5E"
          ></path>{" "}
        </g>
      </svg>
    </>
  );
}
