
import React from "react";
import { Select, Switch, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { changeTheme, setLanguage } from "../../redux/slices/appearanceSlice";
import { toast } from "react-toastify";

const { Option } = Select;

const DashboardSettingsComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: RootState) => state.appearance.theme);
  const language = useSelector((state: RootState) => state.appearance.language);

  const handleLanguageChange = (value: string) => {
    dispatch(setLanguage(value));
    toast.success("Language changed successfully");
  };

  const handleThemeChange = (checked: boolean) => {
    const selectedTheme = checked ? "dark" : "light";
    dispatch(changeTheme(selectedTheme)); 
    toast.success("Theme changed successfully");
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Language">
        <Select value={language} onChange={handleLanguageChange}>
          <Option value="al">Shqip</Option>
          <Option value="en">English</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Dark mode">
        <Switch checked={theme === "dark"} onChange={handleThemeChange} />
      </Form.Item>
    </Form>
  );
};

export default DashboardSettingsComponent;
