export default function TrashIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="url(#paint0_linear_292_7062)" />
      <path
        d="M27.6539 20L27.1923 32M20.8077 32L20.3462 20M33.6369 15.7208C34.0928 15.7896 34.5472 15.8633 35 15.9417M33.6369 15.7208L32.2131 34.2301C32.0928 35.7931 30.7895 37 29.2219 37H18.7781C17.2105 37 15.9072 35.7931 15.7869 34.2301L14.3631 15.7208M33.6369 15.7208C32.1083 15.4899 30.562 15.3131 29 15.1924M13 15.9417C13.4528 15.8633 13.9072 15.7896 14.3631 15.7208M14.3631 15.7208C15.8917 15.4899 17.438 15.3131 19 15.1924M29 15.1924V13.9709C29 12.3984 27.7858 11.0856 26.2141 11.0354C25.479 11.0118 24.7409 11 24 11C23.2591 11 22.521 11.0118 21.7859 11.0354C20.2142 11.0856 19 12.3984 19 13.9709V15.1924M29 15.1924C27.3501 15.0649 25.6826 15 24 15C22.3174 15 20.6499 15.0649 19 15.1924"
        stroke="#EC5962"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_292_7062"
          x1="24"
          y1="0"
          x2="24"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEE6E5" />
          <stop offset="1" stopColor="#FBD0D0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
