export default function printBase64PDF(base64String: any) {

    const cleanBase64String = base64String.replace(/[^A-Za-z0-9+/=]/g, '');

    try {
        const byteCharacters = atob(cleanBase64String);

        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);

        const newTab = window.open(blobURL);
        if (newTab) {
            // newTab.onload = () => {
            //     newTab.print();
            // };
            newTab.onbeforeunload = () => {
                URL.revokeObjectURL(blobURL);
            };
        } else {
            alert('Please allow popups for this website');
        }
    } catch (error) {
        console.error("Error decoding base64 string:", error);
    }
}
