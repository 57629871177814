import React, { useEffect } from "react";
import { Form, Button, Row, Col, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { getCompaniesBasicInfoAsync } from "../../../redux/slices/dashboardCompanySlice";
import { GetCompaniesBasicInfo } from "../../../models/dashboard/Company/GetCompaniesBasicInfo";
import { toast } from "react-toastify";
import apiService from "../../../extensions/api";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import FormSelect from "../../CustomComponents/FormSelect";
import FormInput from "../../CustomComponents/FormInput";

const { Title } = Typography;

const CreateSubscriptionPayment: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);

  const clients = useSelector((state: RootState) =>
    state.dashboardCompany.companiesBasicInfo?.map(
      (item: GetCompaniesBasicInfo) => {
        return {
          label: `${item.vatNumber} - ${item.companyName}`,
          value: item.companyID,
        };
      }
    )
  );

  useEffect(() => {
    dispatch(getCompaniesBasicInfoAsync());
  }, [dispatch]);

  const onFinish = async (values: any) => {
    console.log(values);

    const decimalRegex = /^\d+(\.\d+)?$/;

    if (!decimalRegex.test(values.amount)) {
      toast.error("Vlera nuk eshte numer!");
      return;
    }

    await apiService
      .post("/api/dashboard/subscriptionpayments", {
        paymentAmount: values.amount,
        subscriptionPaymentMethodId: 2,
        companyId: values.clientID,
        currencyId: 1,
      })
      .then(() => {
        toast.success("Pagesa u regjistrua me sukses");
        navigate("/dashboard/subscription-payments");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Gabim gjatë regjistrimit të pagesës!"); 
      });
  };

  return (
    <>
      <Spin tip="Loading..." spinning={loadScreen}>
        <Title level={3}>Regjistro pagesë për abonim.</Title>
        <Formik
          initialValues={{ clientID: undefined, amount: "" }} 
          onSubmit={onFinish}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormSelect
                    name="clientID"
                    label="Klienti"
                    options={clients}
                    value={values.clientID}
                    onChange={(value) => setFieldValue('clientID', value)} 
                    required
                    rules={[
                      {
                        required: true,
                        message: "Te lutem zgjedh klientin",
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    label="Shuma e paguar"
                    name="amount"
                    value={values.amount}
                    onChange={handleChange}
                    required
                    rules={[
                      { required: true, message: "Ju lutem sheno shumen e paguar" },
                    ]}
                  />
                </Col>
              </Row>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Spin>
    </>
  );
};

export default CreateSubscriptionPayment;
