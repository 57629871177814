import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FinancialAccountState } from "../../models/clientDashboard/FinancialAccounts/FinancialAccountsState";
import { getFinancialAccountsAsync } from "./accountingConfigurationSlice";
import { setLoading, setLoadingState } from "./loadingSlice";
import { CreateOrUpdateFinancialAccountsDto } from "../../models/clientDashboard/FinancialAccounts/CreateOrUpdateFinancialAccountsDto";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { FinancialAccount } from "../../models/clientDashboard/FinancialAccounts/FinancialAccounts";
import { FinancialAccountClassDto } from "../../models/clientDashboard/FinancialAccounts/FinancialAccountClassDto";

const initialState: FinancialAccountState = {
  financialAccounts: [],
  financialAccountsWithChildrens: [],
  financialAccountsClass: [],
  error: null,
};

const financialAccountsSlice = createSlice({
  name: "financialAccounts",
  initialState,
  reducers: {
    getFinancialAccountsStart(state: FinancialAccountState) {
      setLoading(true);
      state.error = null;
    },
    getFinancialAccountsSuccess(
      state: FinancialAccountState,
      action: PayloadAction<CreateOrUpdateFinancialAccountsDto[]>
    ) {
      setLoading(false);
      state.financialAccounts = action.payload;
    },
    getFinancialAccountClassesSuccess(
      state: FinancialAccountState,
      action: PayloadAction<FinancialAccountClassDto[]>
    ) {
      setLoading(false);
      state.financialAccountsClass = action.payload;
    },
    getFinancialAccountsFailure(
      state: FinancialAccountState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
    getFinancialAccountsWithChildrenSuccess(
        state: FinancialAccountState,
        action: PayloadAction<CreateOrUpdateFinancialAccountsDto[]>
      ) {
        setLoading(false);
        state.financialAccountsWithChildrens = action.payload;
      },
  },
});

export const getFinancialAccountAsync = createAsyncThunk(
  "financialAccounts/getFinancialAccounts",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getFinancialAccountsStart());
      const response = await apiService.get<
        ApiResponse<CreateOrUpdateFinancialAccountsDto[]>
      >("api/FinancialAccounts");
      dispatch(getFinancialAccountsSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getFinancialAccountsFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getFinAccountClassesAync = createAsyncThunk(
  "finAccountClasses/getFinAccountClasses",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getFinancialAccountsStart());
      const response = await apiService.get<
        ApiResponse<FinancialAccountClassDto[]>
      >("api/FinancialAccounts/GetFinAccountClasses");
      dispatch(getFinancialAccountClassesSuccess(response.data.Data));
      // return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getFinancialAccountsFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getFinancialAccountsWithChildrenAsync = createAsyncThunk(
    "financialAccounts/GetFinancialAccountsWithChilds",
    async (_, { dispatch, getState, rejectWithValue }) => {
      try {
        dispatch(getFinancialAccountsStart());
        const response = await apiService.get<
          ApiResponse<FinancialAccount[]>
        >("api/FinancialAccounts/GetFinancialAccountsWithChilds");
        dispatch(getFinancialAccountsWithChildrenSuccess(response.data.Data));
        return response.data.Data;
      } catch (error) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        dispatch(getFinancialAccountsFailure(errorMessage));
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  );
  

export const {
  getFinancialAccountsStart,
  getFinancialAccountsSuccess,
  getFinancialAccountsFailure,
  getFinancialAccountsWithChildrenSuccess,
  getFinancialAccountClassesSuccess
} = financialAccountsSlice.actions;

export default financialAccountsSlice.reducer;
