import { Row, Col, Form, Select, Button, Input } from "antd";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { AppDispatch, RootState } from "../../../../redux";
import FormInput from "../../../CustomComponents/FormInput";
import FormSelect from "../../../CustomComponents/FormSelect";
import FormTextArea from "../../../CustomComponents/FormTextArea";
import { Entity } from "../../../../models/clientDashboard/Entity/Entity";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import { useEffect, useState } from "react";
import { City } from "../../../../models/clientDashboard/City/City";
import { useField } from "formik";
import { GetCitiesDto } from "../../../../models/clientDashboard/City/GetCitiesDto";
import { toast } from "react-toastify";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import apiService from "../../../../extensions/api";
import { useNavigate } from "react-router-dom";
import { getCitiesAsync, setCitiesBasicInfo } from "../../../../redux/slices/citySlice";
import { PlusOutlined } from "@ant-design/icons";

interface Props {
  entity: Entity;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  selectedCountry:number;
  setSelectedCountry:(countryId:number)=>void;
}

const { Title, Text } = Typography;

const MainFormCreateEntityComponent = ({ entity,setFieldValue,selectedCountry,setSelectedCountry }: Props) => {
  const cities = useSelector(
    (rootState: RootState) => rootState.city.citiesBasicInfo
  );
  const countries = useSelector(
    (rootState: RootState) => rootState.country.countries
  );
  const entityTypes = useSelector(
    (rootState: RootState) => rootState.entityType.entityTypes
  );
  const t = useAppTranslation("ClientDashboard.CreateEntity.EntityForm");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [addCityName, setAddCityName] = useState<string>("");

  useEffect(()=>{
    dispatch(getCitiesAsync())
  },[selectedCountry]);
  
  const addNewCityOption = async () => {
    if(selectedCountry === 0){
      toast.error("Please select a country first")
      return;
    }
    if(addCityName === ""){
      toast.error("Please enter a city name")
      return;
    }
    const newCity:City ={
      description:addCityName,
      countryId:selectedCountry,
      cityId:0
    } 
    dispatch(setLoadingState(true));
    
    await apiService
        .post('/api/entity/createCity', newCity)
        .then((response) => {
          dispatch(setCitiesBasicInfo([]))
            dispatch(getCitiesAsync())
        })
        .catch(() => {})
        .finally(() => {
          setAddCityName("");
            dispatch(setLoadingState(false));
        });
  }
  const handleAddCityNameChange = (e: any) => {
    setAddCityName(e.target.value);
  }

  return (
    <>
      
      {/* <Text italic>{t("formSubtitle")}</Text> */}

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8}>
          <FormInput
            name="description"
            label={t("descriptionInputLabel")}
            required={true}
            value={entity.description}
          />
          <FormSelect
            name="entityTypeId"
            label={t("entityTypeIdInputLabel")}
            required={true}
            value={entity.entityTypeId}
            options={
              entityTypes?.map((entityType) => {
                return {
                  key: entityType.entityTypeId,
                  label: entityType.description,
                  value: entityType.entityTypeId,
                };
              }) ?? []
            }
          />
          <FormInput
            name="businessNumber"
            label={t("businessNumberInputLabel")}
            required={true}
            value={entity.businessNumber}
          />
          <FormInput
            name="vatNumber"
            label={t("vATNumberInputLabel")}
            value={entity.vatNumber}
          />
          <FormTextArea
            name="comment"
            label={t("commentInputLabel")}
            rules={[
              {
                message: t("commentInputPlaceholder"),
              },
            ]}
            value={entity.comment}
            placeholder={t("commentInputPlaceholder")}
            rows={5}
          />
        </Col>
        <Col xs={24} sm={8}>
          <FormInput
            name="tradeName"
            label={t("tradeNameInputLabel")}
            value={entity.tradeName}
          />

          <FormSelect
            name="countryId"
            label={t("countryIdInputLabel")}
            required={true}
            value={entity.countryId}
            options={
              countries?.map((country) => {
                return {
                  key: country.countryId,
                  label: country.description,
                  value: country.countryId,
                };
              }) ?? []
            }
            handleCustomChange={(value)=>{
              setFieldValue("countryId",value)
              setSelectedCountry(value)}}
          />

          <FormSelect
            name="cityId"
            label={t("cityIdInputLabel")}
            required={true}
            value={entity.cityId}
            options={
              cities
                ?.filter((city) => city.countryId === selectedCountry)
                .map((city) => {
                  return {
                    key: city.cityId,
                    label: city.cityDescription,
                    value: city.cityId,
                  };
                }) ?? []
            }
            hasAddOption={true}
            dropdownContent={()=>(
              <>
              <Input
              placeholder="Please enter item"
              value={addCityName}
              onChange={handleAddCityNameChange}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={addNewCityOption}
            >
              Add item
            </Button>
            </>
            )}
          />
          <FormInput
            name="address"
            label={t("addressInputLabel")}
           required={true}
            value={entity.address}
          />
          <FormSwitch
            value={entity.isSupplier}
            name="isSupplier"
            label={t("isSupplierInputLabel")}
          />
          <FormSwitch name="status" label={t("statusInputLabel")} />
        </Col>
        <Col xs={24} sm={8}>
          <FormInput
            name="email"
            label={t("emailInputLabel")}
            required={true}
            value={entity.email}
            type="email"
          />
          <FormInput
            name="website"
            label={t("websiteInputLabel")}
            rules={[
              {
                message: t("websiteInputPlaceholder"),
              },
            ]}
            value={entity.website}
          />
          <FormInput
            name="contactPerson"
            label={t("contactPersonInputLabel")}
            rules={[
              {
                message: t("contactPersonInputPlaceholder"),
              },
            ]}
            value={entity.contactPerson}
          />
          <FormInput
            name="licenseNumbers"
            label={t("licenseNumbersInputLabel")}
            rules={[
              {
                message: t("licenseNumbersInputPlaceholder"),
              },
            ]}
            value={entity.licenseNumbers}
          />
          <FormSwitch
            value={entity.isCustomer}
            name="isCustomer"
            label={t("isCustomerInputLabel")}
          />
        </Col>
      </Row>
    </>
  );
};

export default MainFormCreateEntityComponent;
