import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { CreateOrUpdateSaleOrderDetailDto } from "../../../../../models/clientDashboard/EntriesModule/SaleOrder/CreateOrUpdateSaleOrderDetailsDto";
import { CreateOrUpdateSaleOrderDto } from "../../../../../models/clientDashboard/EntriesModule/SaleOrder/CreateOrUpdateSaleOrderDto";
import { GenericDocumentDto } from "../../../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { AppDispatch, RootState } from "../../../../../redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import {
  getItemDetailsForLocalPurchase,
  getItemsBySearchValue,
} from "../../../../../redux/slices/itemSlice";
import { ItemDescriptionIdDto } from "../../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { CopiedDocumentDetailsTable } from "../../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { toast } from "react-toastify";
import { GetItemDetailsDto } from "../../../../../models/clientDashboard/Item/GetItemDetailsDto";
import { focusOnAgGridEditingCell } from "../../../../../helperMethods/focusOnAgGridEditingCell";
import { mapDataToInterface } from "../../../../../helperMethods/mapDataToInterface";
import { db } from "../../../../../indexDB/clientSideDatabase";
import { debounce } from "lodash";
import {
  getBranchesByEntityId,
  getEntitiesBySearch,
} from "../../../../../redux/slices/entitySlice";
import { Entity } from "../../../../../models/clientDashboard/Entity/Entity";
import { AutoComplete, Button, Col, Row } from "antd";
import FormSelect from "../../../../CustomComponents/FormSelect";
import FormSelectWithSearch from "../../../../CustomComponents/FormSelectWithSearch";
import FormInput from "../../../../CustomComponents/FormInput";
import FormSwitch from "../../../../CustomComponents/FormSwitch";
import Search from "antd/es/input/Search";
import AgGridTableComponent from "../../../../CustomComponents/AgGridTableComponent";
import { handleNextGridColumnClick } from "../../../../../helperMethods/handleNextGridColumnClick";
import { getSaleOrdersDetailColumns } from "../SaleOrderDetails/AgGridSaleOrderDetailColumns";
import { CreateAndUpdateEntityBranchDto } from "../../../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { SelectOption } from "../../../../../models/SelectOption";

interface Props {
  type: string;
  values: GenericDocumentDto<
    CreateOrUpdateSaleOrderDto,
    CreateOrUpdateSaleOrderDetailDto
  >;
  saleOrdersDetails: CreateOrUpdateSaleOrderDetailDto[];
  addSaleOrdersDetail: (
    saleOrdersDetail: CreateOrUpdateSaleOrderDetailDto
  ) => void;
  setSaleOrderDetails: (asleOrder: CreateOrUpdateSaleOrderDetailDto[]) => void;
  removeSaleOrdersDetail: (rowIndex: number) => void;
  mode: string;
  setFieldValue: (name: string, value: any) => void;
  supplierOptions: SelectOption[];
  supplierBranchOptions?: SelectOption[];
}
export default function MainFieldsComponent({
  values,
  saleOrdersDetails,
  addSaleOrdersDetail,
  removeSaleOrdersDetail,
  setSaleOrderDetails,
  mode,
  type,
  setFieldValue,
  supplierOptions,
  supplierBranchOptions,
}: Props) {
  const t = useAppTranslation("ClientDashboard.CreateSaleOrder");
  const tForDocumentType = useAppTranslation("ClientDashboard.DocumentTypes");
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const [searchValue, setSearchValue] = useState("");
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const searchInputRef = useRef<any | null>(null);
  const suppliers = useSelector((state: RootState) => state.entity.entities);
  const [entityBranches, setEntityBranches] = useState<
    CreateAndUpdateEntityBranchDto[]
  >([]);
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const dispatch = useDispatch<AppDispatch>();
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const documentTypes = useSelector(
    (state: RootState) => state.documentType.documentTypes
  );
  const saleOrdersDetailGridRef = useRef(null);
  const currencies = useSelector(
    (state: RootState) => state.currency.currencies
  );

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onSaleOrdersDetailGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["no"]);
    params.columnApi.autoSizeColumns(["itemId"]);
    params.columnApi.autoSizeColumns(["itemDescription"]);
    params.columnApi.autoSizeColumns(["quantity"]);
    params.columnApi.autoSizeColumns(["itemCost"]);
    params.columnApi.autoSizeColumns(["avarageCost"]);
    params.columnApi.autoSizeColumns(["salePrice"]);
    params.columnApi.autoSizeColumns(["discount"]);
    params.columnApi.autoSizeColumns(["vAT"]);
    params.columnApi.autoSizeColumns(["extraDiscount"]);
    params.columnApi.autoSizeColumns(["comment"]);
    params.columnApi.autoSizeColumns(["barcode"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getEntityBranches = async (entityId: number) => {
    if (entityId !== null || entityId !== undefined) {
      const result = await dispatch(getBranchesByEntityId(entityId));
      if (result.type === "Entities/getBranchesByEntityId/fulfilled") {
        setEntityBranches(result.payload as CreateAndUpdateEntityBranchDto[]);
      }
    }
  };

  const [columnDefs] = useState<ColDef<CreateOrUpdateSaleOrderDetailDto>[]>(
    getSaleOrdersDetailColumns(removeSaleOrdersDetail, t, searchInputRef, mode)
  );
  const handleItemSearch = async (value: any, supplierId: number) => {
    const itemsBasedOnValueAction = await dispatch(
      getItemsBySearchValue({ searchValue: value, supplierId: supplierId })
    );
    const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

    if (!items || items.length === 0) {
      setItemOptions([
        {
          label: t("noItemsFound"),
          value: "no-items",
          disabled: true,
        },
      ]);
    } else {
      setItemOptions([]);
      setItemOptions(
        items?.map((item) => ({
          key: item.itemId,
          label: item.description,
          value: item.itemId.toString(),
        }))
      );
    }
  };
  const [copiedData, setCopiedData] = useState<
    CopiedDocumentDetailsTable<any> | undefined
  >(undefined);

  const handleOnSelect = async (value: any) => {
    if (!value || isNaN(parseInt(value))) {
      toast.error("Please enter a valid search term");
      return;
    }
    const itemID = parseInt(value);
    const itemOnDatabase = await dispatch(
      getItemDetailsForLocalPurchase({ itemID, branchID: values.branchId ?? 0 })
    );
    if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/rejected"
    ) {
      toast.error("Item not found");
      return;
    } else if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/fulfilled"
    ) {
      const item = itemOnDatabase.payload as GetItemDetailsDto;

      if (item) {
        const isItemExist = saleOrdersDetails.some(
          (detail) => detail.itemId === item.itemId
        );
        if (isItemExist) {
          toast.success(t("Item"));
          return;
        }
        const newSaleOrdersDetail: CreateOrUpdateSaleOrderDetailDto = {
          saleOrderDetailId: 0,
          saleOrderId: "",
          itemId: item.itemId,
          itemDescription: item.description ?? "",
          no: 0,
          quantity: 0,
          itemCost: 0,
          avarageCost: item.averageCost,
          vAT: item.vat,
          salePrice: item.salesPrice,
          discount: item.discount ?? 0,
          extraDiscount: item.extraDiscount ?? 0,
          comment: "",
          entryItemDetailId: 0,
          reserveStock: true,
          quantityVerification: 0,
          barcode: "",
          rowAction: "A",
        };
        addSaleOrdersDetail(newSaleOrdersDetail);

        setTimeout(() => {
          focusOnAgGridEditingCell(saleOrdersDetailGridRef, "quantity");
        }, 0);
        setSearchValue("");
      } else {
        toast.error("Item not found");
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const pasteDetails = async () => {
    try {
      if (copiedData) {
        const targetType = {
          saleOrderId: "",
          itemId: 0,
          itemDescription: undefined,
          no: 0,
          quantity: 0,
          itemCost: 0,
          avarageCost: 0,
          vAT: 0,
          extraDiscount: 0,
          salePrice: 0,
          discount: 0,
          comment: undefined,
          entryItemDetailId: 0,
          reserveStock: false,
          quantityVerification: 0,
          barcode: undefined,
          rowAction: "A",
        } as CreateOrUpdateSaleOrderDetailDto;
        const details = mapDataToInterface(
          copiedData.list as any[],
          targetType
        );
        setSaleOrderDetails(details);
      } else {
        toast.error(t("toastMessages.no_document"));
      }
    } catch (error) {
      toast.error(t("toastMessages.something_wrong"));
    }
  };

  const getCopiedData = async () => {
    const data = await db.getGenericData();
    if (data) setCopiedData(data[0]);
    else setCopiedData(undefined);
  };

  useEffect(() => {
    getCopiedData();
  }, []);

  useEffect(() => {
    if (
      values?.header?.subjectId !== undefined &&
      values?.header?.subjectId !== 0
    ) {
      getEntityBranches(values?.header?.subjectId);
    }
  }, [values?.header?.subjectId]);

  const onChange = (data: any) => {
    setSearchValue(data);
  };

  const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

  const handleSearchChange = (value: string, supplierId: number) => {
    if (!value.trim()) {
      setSearchValue("");
      return;
    }
    setSearchValue(value);
    debouncedItemSearch(value, supplierId);
  };

  const fetchBuyers = async (searchText: string) => {
    const result = await dispatch(
      getEntitiesBySearch({ searchText: searchText, isBuyer: true })
    );
    if (result.payload !== "An error occurred") {
      const payload = result.payload as Entity[];
      const options = payload?.map((entity) => ({
        key: entity.entityId.toString(),
        label: entity.description,
        value: entity.entityId,
      }));
      return options;
    }
    return [];
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const memoizedRowData = useMemo(() => {
    return saleOrdersDetails.filter((detail) => detail.rowAction !== "D");
  }, [saleOrdersDetails]);

  const subjectBranchOptions = useMemo(() => {
    return entityBranches.map((branch) => ({
      key: branch.entityBranchId,
      label: branch.description,
      value: branch.entityBranchId,
    }));
  }, [entityBranches]);

  useEffect(() => {}, [supplierOptions]);

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xs={12} sm={8}>
          <FormSelect
            name="branchId"
            label={t("branchIdInputLabel")}
            required={true}
            value={values.branchId}
            handleCustomChange={(value) => {
              setFieldValue("branchId", value);
            }}
            options={
              userBranches?.map((userBarnch) => {
                return {
                  key: userBarnch.branchId,
                  label: userBarnch.description,
                  value: userBarnch.branchId,
                };
              }) ?? []
            }
            disabled={
              !user?.isMainBranch ||
              values.validated ||
              saleOrdersDetails.length > 0
            }
          />
          <FormSelect
            name="documentTypeId"
            label={t("documentTypeInputLabel")}
            style={{ width: "100%" }}
            value={values.documentTypeId}
            required={true}
            disabled={values.validated || saleOrdersDetails.length > 0}
            options={
              documentTypes.map((documentType) => {
                return {
                  key: documentType.documentId,
                  label: documentType.description,
                  value: documentType.documentId,
                };
              }) ?? []
            }
          />
          <FormSelectWithSearch
            name="header.subjectId"
            label={t("subjectIdInputLabel")}
            required={true}
            value={values?.header?.subjectId}
            disabled={values.validated || saleOrdersDetails.length > 0}
            fetchOptions={fetchBuyers}
            fetchInitialOption={() => {
              return {
                key: values?.header?.subjectId ?? "",
                label: values?.header?.subjectDescription ?? "",
                value: values?.header?.subjectId ?? 0,
              };
            }}
            placeHolder={t("placeHolder")}
            onChange={(option) => {
              setFieldValue("header.subjectId", option.value);
              setFieldValue("header.subjectDescription", option.label);
              setFieldValue("branchSubjectId", undefined);
              if (option) {
                getEntityBranches(Number(option.value));
              }
            }}
          />
          <FormSelect
            name="header.branchSubjectId"
            label={t("branchSubjectIdInputLabel")}
            value={values?.header?.branchSubjectId}
            options={subjectBranchOptions}
            showSearch={true}
            disabled={
              !values?.header?.subjectId ||
              values.validated ||
              saleOrdersDetails.length > 0
            }
          />
        </Col>
        <Col xs={12} sm={8}>
          <FormInput
            name="date"
            type="date"
            required={true}
            min={new Date().toISOString().split("T")[0]}
            label={t("dateInputLabel")}
            value={values?.date?.split("T")[0] || ""}
            disabled={values.validated}
          />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div style={{ flex: 1, marginRight: "10px" }}>
              <FormSelect
                name="header.currencyId"
                label={t("currencyInputLabel")}
                style={{ width: "100%" }}
                required={false}
                value={values?.header?.currencyId}
                disabled={values.validated}
                options={currencies.map((currency) => ({
                  label: `${currency.currencySymbol} ${currency.currencyName}`,
                  value: currency.currencyId,
                }))}
              />
            </div>
            <div style={{ width: "100px" }}>
              <FormInput
                name="header.exchangeRate"
                type="number"
                required={false}
                label={t("exchangeRateInputLabel")}
                style={{ width: "100%" }}
                value={values?.header?.exchangeRate}
                disabled={
                  values?.header?.currencyId === undefined || values.validated
                }
              />
            </div>
          </div>
          <FormInput
            name="comment"
            label={t("commentInputLabel")}
            required={false}
            value={values?.header?.comment}
            disabled={values.validated}
          />
        </Col>
        <Col xs={12} sm={8}>
          <FormInput
            name="genericDocumentIdAsString"
            label={t("referenceNoInputLabel")}
            required={false}
            value={values.genericDocumentIdAsString}
            disabled={true}
          />
          <FormInput
            name="documentNo"
            label={t("documentNoInputLabel")}
            required={false}
            value={values.documentNo}
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col
          className="custom-row"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <AutoComplete
            options={itemOptions}
            onSelect={handleOnSelect}
            onSearch={(value) => handleSearchChange(value, values.branchId)}
            onChange={onChange}
            value={searchValue}
            className="custom-search"
            style={{ width: 300 }}
            disabled={
              values.branchId === 0 ||
              values.branchId === null ||
              values.branchId === undefined ||
              values.validated ||
              values?.header?.subjectId === 0 ||
              values?.header?.subjectId === null ||
              values?.header?.subjectId === undefined ||
              values.documentTypeId === undefined ||
              values.documentTypeId === null
            }
          >
            <Search
              placeholder={t("searchPlaceholder")}
              allowClear
              ref={searchInputRef}
              size="large"
              onSearch={(value) => handleSearchChange(value, values.branchId)}
            />
          </AutoComplete>
          {copiedData && (
            <Button
              onClick={pasteDetails}
              disabled={
                saleOrdersDetails.length > 0 ||
                !values.branchId ||
                !values.documentTypeId ||
                !values?.header?.subjectId
              }
            >
              {t("paste")}
            </Button>
          )}
        </Col>
      </Row>
      <Row gutter={[12, 8]}>
        <Col xs={24} sm={24}>
          <AgGridTableComponent
            style={{ overflow: "auto" }}
            onGridReady={onSaleOrdersDetailGridReady}
            gridRef={saleOrdersDetailGridRef}
            isLoading={isLoading}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={memoizedRowData}
            stopEditingWhenCellsLoseFocus={true}
            suppressCellFocus={true}
            disabled={values.validated}
            onCellKeyDown={(event: CellKeyDownEvent) =>
              handleNextGridColumnClick(event, searchInputRef)
            }
          />
        </Col>
      </Row>
    </>
  );
}
