import { ValueFormatterParams } from "ag-grid-community";

export function formatDate(params: ValueFormatterParams) {
  if (params.value === null || params.value === undefined) {
    return null;
  }

  const date = new Date(params.value);
  if (!isNaN(date.getTime())) {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-GB', options).replace(/\//g, '.');
    return formattedDate;
  }

  return params.value;
}




export function formatDateWithTime(params: ValueFormatterParams) {
  const date = new Date(params.value);
  if (!isNaN(date.getTime())) {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    const formattedDateTime = date.toLocaleDateString('en-GB', options).replace(/\//g, '.');
    return formattedDateTime;
  }
  return params.value;
}
