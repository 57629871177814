import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { CreateOrUpdateSaleDto } from "../../../../../models/clientDashboard/Sales/SalesInvoice/CreateOrUpdateSalesDto";
import { CreateOrUpdateSaleDetailsDto } from "../../../../../models/clientDashboard/Sales/SalesInvoiceDetail/CreateOrUpdateSaleDetailsDto";
import { AppDispatch, RootState } from "../../../../../redux";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellKeyDownEvent, ColDef, GridApi } from "ag-grid-community";
import { ItemDescriptionIdDto } from "../../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { getItemDetailsForLocalPurchase, getItemsBySearchValue } from "../../../../../redux/slices/itemSlice";
import { toast } from "react-toastify";
import { GetItemDetailsDto } from "../../../../../models/clientDashboard/Item/GetItemDetailsDto";
import { focusOnAgGridEditingCell } from "../../../../../helperMethods/focusOnAgGridEditingCell";
import { debounce } from "lodash";
import { getBranchesByEntityId, getEntitiesBySearch } from "../../../../../redux/slices/entitySlice";
import { Entity } from "../../../../../models/clientDashboard/Entity/Entity";
import { AutoComplete, Button, Col, Row } from "antd";
import FormSelect from "../../../../CustomComponents/FormSelect";
import FormInput from "../../../../CustomComponents/FormInput";
import FormSelectWithSearch from "../../../../CustomComponents/FormSelectWithSearch";
import Search from "antd/es/input/Search";
import { handleNextGridColumnClick } from "../../../../../helperMethods/handleNextGridColumnClick";
import AgGridTableComponent from "../../../../CustomComponents/AgGridTableComponent";
import { getSaleDetailColumns } from "../SaleDetails/AgGridDetailColumns";
import { CreateAndUpdateEntityBranchDto } from "../../../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { getPaymentExecutionDetailColumns } from "../SaleDetails/AgGridDetailPaymentExecutionColumns";
import { CreatePaymentExecutionsDetailsDto } from "../../../../../models/clientDashboard/Sales/SalesInvoiceDetail/CreatePaymentExecutionsDetailsDto";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { DocumentTypeEnum } from "../../../../../enums/DocumentTypeEnum";
import { CopiedDocumentDetailsTable } from "../../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { db } from "../../../../../indexDB/clientSideDatabase";
import { mapDataToInterface } from "../../../../../helperMethods/mapDataToInterface";
import { SelectOption } from "../../../../../models/SelectOption";
import { getUserBranches } from "../../../../../redux/slices/userSlice";


interface Props {
    values: CreateOrUpdateSaleDto;
    saleDetails: CreateOrUpdateSaleDetailsDto[];
    addSaleDetail: (
        saleDetail: CreateOrUpdateSaleDetailsDto
    ) => void;
    addPaymentExecutions: (paymentExecution: CreatePaymentExecutionsDetailsDto) => void;
    paymentExecutionsDetails: CreatePaymentExecutionsDetailsDto[];
    removeSaleDetail: (rowIndex: number) => void;
    removePaymentExecutionDetail: (rowIndex: number) => void;
    mode: string;
    setFieldValue: (name: string, value: any) => void;
    setSaleDetails: (data: any) => void;
    supplierOptions: SelectOption[];
    supplierBranchOptions: SelectOption[];
}
export default function MainFieldsComponent({
    values,
    saleDetails,
    addSaleDetail,
    paymentExecutionsDetails,
    addPaymentExecutions,
    removePaymentExecutionDetail,
    removeSaleDetail,
    mode,
    setFieldValue,
    setSaleDetails,
    supplierOptions,
    supplierBranchOptions

}: Props) {
    const t = useAppTranslation("ClientDashboard.CreateSale");
    const branches = useSelector((state: RootState) => state.branch.branches);
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const [searchValue, setSearchValue] = useState("");
    const [itemOptions, setItemOptions] = useState<any[]>([]);
    const searchInputRef = useRef<any | null>(null);
    const paymentMethodRef = useRef<any | null>(null);
    const buyers = useSelector((state: RootState) => state.entity.entities);
    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const [totalWithVat, setTotalWithVat] = useState<number>(0);
    const [totalWoVat, setTotalWoVat] = useState<number>(0);
    const [totalVat, setTotalVat] = useState<number>(0);
    const userBranches = useSelector(
        (state: RootState) => state.user.loggedInUserBranches
    );
    const paymentMethods = useSelector((state: RootState) => state.paymentMethod.paymentMethods);
    const [entityBranches, setEntityBranches] = useState<CreateAndUpdateEntityBranchDto[]>([]);
    const saleDetailGridRef = useRef(null);
    const user = useSelector((state: RootState) => state.user.loggedInUser);
    const dispatch = useDispatch<AppDispatch>();
    const documentTypes = useSelector(
        (state: RootState) => state.documentType.documentTypes
    );
    const paymentExecutionsGridRef = useRef<any>(null);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null>(null);

    const [gridApi, setGridApi] = useState<any>(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [copiedData, setCopiedData] = useState<CopiedDocumentDetailsTable<any> | undefined>(undefined);


    const onSaleDetailGridReady = (params: any) => {
        params.api.autoSizeColumns(["no"]);
        params.api.autoSizeColumns(["itemId"]);
        params.api.autoSizeColumns(["salePrice"]);
        params.api.autoSizeColumns(["extraDiscount"]);
        params.api.autoSizeColumns(["itemLastCost"]);
        params.api.autoSizeColumns(["averageCost"]);
        params.api.autoSizeColumns(["vAT"]);
        params.api.autoSizeColumns(["regularPrice"]);
        params.api.autoSizeColumns(["wholesalePrice"]);
        params.api.autoSizeColumns(["barcode"]);
        params.api.autoSizeColumns(["comment"]);
        params.api.autoSizeColumns(["options"]);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };



    const getEntityBranches = async (entityId: number) => {
        const result = await dispatch(getBranchesByEntityId(entityId));
        if (result.type === "Entities/getBranchesByEntityId/fulfilled") {
            setEntityBranches(result.payload as CreateAndUpdateEntityBranchDto[])
        }
    }
    const columnDefs = useMemo(() => {
        return getSaleDetailColumns(removeSaleDetail, t, searchInputRef, mode, setSaleDetails, values.documentTypeId);
    }, [saleDetails]);

    const getCopiedData = async () => {
        const data = await db.getGenericData();
        if (data)
            setCopiedData(data[0])
        else
            setCopiedData(undefined);
    }
    useEffect(() => {
        getCopiedData();
        dispatch(getUserBranches());
    }, [dispatch]);

    useEffect(() => {
        if (values?.subjectId !== undefined && values?.subjectId !== 0 && values.subjectId !== null)  {
            getEntityBranches(values?.subjectId)
        }
    }, [values.subjectId])


    const columnPaymentExecutionsDefs = getPaymentExecutionDetailColumns(paymentExecutionsGridRef, totalWithVat, removePaymentExecutionDetail, t, mode)


    const handleItemSearch = async (value: any) => {
        const itemsBasedOnValueAction = await dispatch(
            getItemsBySearchValue({ searchValue: value })
        );
        const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

        if (!items || items.length === 0) {
            setItemOptions([
                {
                    label: t("noItemsFound"),
                    value: "no-items",
                    disabled: true,
                },
            ]);
        } else {
            setItemOptions([]);
            setItemOptions(
                items?.map((item) => ({
                    key: item.itemId,
                    label: item.description,
                    value: item.itemId.toString(),
                }))
            );
        }
    };

    const handleOnSelect = async (value: any) => {
        if (!value || isNaN(parseInt(value))) {
            toast.error("Please enter a valid search term");
            return;
        }
        const itemID = parseInt(value);
        const itemOnDatabase = await dispatch(
            getItemDetailsForLocalPurchase({ itemID, branchID: values.branchId })
        );
        if (
            itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/rejected"
        ) {
            toast.error("Item not found");
            return;
        } else if (
            itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/fulfilled"
        ) {
            const item = itemOnDatabase.payload as GetItemDetailsDto;


            if (item.salesPrice === 0) {
                toast.error(t("invalidSalesPrice"));
                return
            }

            if (item) {
                const isItemExist = saleDetails?.some(
                    (detail) => detail.itemId === item.itemId && detail.rowAction !== "D"
                );
                if (isItemExist) {
                    toast.success(t("Item"));
                    return;
                }
                const newSaleDetail: CreateOrUpdateSaleDetailsDto = {
                    saleDetailId: 0,
                    itemId: item.itemId,
                    quantity: 1,
                    salesPrice: item.salesPrice,
                    stock: item.stock,
                    itemDescription: item.description,
                    discount: item.discount,
                    extraDiscount: item.extraDiscount,
                    itemLastCost: 0,
                    averageCost: item.averageCost ?? 0,
                    vat: item.vat,
                    itemStatusPriceId: 0,
                    entryItemsDetailId: 0,
                    regularPrice: 0,
                    wholesalePrice: item.wholeSalePrice ?? 0,
                    barcode: "",
                    couponCode: "",
                    excisePerUnit: 0,
                    comment: "",
                    rowAction: "A",
                };

                if (values.documentTypeId === DocumentTypeEnum.goodsExport || values.documentTypeId === DocumentTypeEnum.saleWoVat) {

                    newSaleDetail.salesPrice = Number((item.salesPrice / (1.00 + item.vat / 100)).toFixed(2));
                    newSaleDetail.vat = 0;
                }
                addSaleDetail(newSaleDetail);
                setTimeout(() => {
                    focusOnAgGridEditingCell(saleDetailGridRef, "no");
                }, 0);
                setSearchValue("");
            } else {
                toast.error("Item not found");
            }
        } else {
            toast.error("Something went wrong");
        }
    };


    const pasteDetails = async () => {
        try {
            if (copiedData) {
                const targetType = {
                    itemId: 0,
                    quantity: 0,
                    no: 0,
                    salesPrice: 0,
                    itemDescription: "",
                    discount: 0,
                    extraDiscount: 0,
                    itemLastCost: 0,
                    averageCost: 0,
                    vat: 0,
                    stock: 0,
                    itemStatusPriceId: 0,
                    entryItemsDetailId: 0,
                    regularPrice: 0,
                    wholesalePrice: 0,
                    barcode: undefined,
                    couponCode: undefined,
                    excisePerUnit: 0,
                    comment: undefined,
                    rowAction: 'A'
                } as CreateOrUpdateSaleDetailsDto
                console.log(copiedData.list);
                const details = mapDataToInterface(copiedData.list as any[], targetType)
                setSaleDetails(details);
            } else {
                toast.error(t('toastMessages.no_document'));
            }
        } catch (error) {
            toast.error(t('toastMessages.something_wrong'));
        }
    }


    const onChange = (data: any) => {
        setSearchValue(data);
    };

    const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

    const handleSearchChange = (value: string) => {
        if (!value.trim()) {
            setSearchValue("");
            return;
        }
        setSearchValue(value);
        debouncedItemSearch(value);
    };

    const fetchBuyers = async (searchText: string) => {
        const result = await dispatch(getEntitiesBySearch({ searchText: searchText, isBuyer: true }));
        if (result.payload !== "An error occurred") {
            const payload = result.payload as Entity[]
            const options = payload.map((entity) => ({
                key: entity.entityId.toString(),
                label: entity.description,
                value: entity.entityId
            }))
            return options;
        }
        return [];
    }
    const selectPaymentMethod = (paymentMethodId: number) => {
        const isItemExist = paymentExecutionsDetails?.some(
            (detail) => detail.paymentMethodId === paymentMethodId && detail.rowAction !== "D"
        );
        if (isItemExist) {
            setSelectedPaymentMethod(null);
            return;
        }
        const paymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.paymentMethodId === paymentMethodId);
        const paymentExecutionsSum = paymentExecutionsGridRef?.current?.props?.rowData
            .filter((payment: any) => payment.rowAction !== 'D')
            .reduce(
                (sum: any, detail: any) => sum + (detail.amount || 0),
                0
            );
        if (paymentExecutionsSum >= totalWithVat) {
            toast.error(t("sumEqual"));
            setSelectedPaymentMethod(null);
            return;
        }

        const newPaymentMethodDetail: CreatePaymentExecutionsDetailsDto = {
            paymentMethodId: paymentMethod?.paymentMethodId ?? 0,
            amount: null,
            paid: 0,
            paymentMethodDescription: paymentMethod?.description,
            cashGiven: 0,
            currencyId: values.currencyId,
            exchangeRate: values.exchangeRate,
            voucherCode: "",
            voucherType: "",
            rowAction: "A"
        };
        addPaymentExecutions(newPaymentMethodDetail);
        setSelectedPaymentMethod(null);
        setTimeout(() => {
            focusOnAgGridEditingCell(paymentExecutionsGridRef, "amount");
        }, 0);
    }



    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };


    useMemo(() => {
        setTotalWithVat(
            saleDetails
                .filter(payment => payment.rowAction !== 'D')
                .reduce((sum, detail) => {
                    const discountedPrice = sum + detail.quantity * detail.salesPrice * (1.00 - detail.discount / 100.00) * (1.00 - detail.extraDiscount / 100.00);
                    return discountedPrice;
                }, 0.00));
    }, [saleDetails])

    useMemo(() => {
        setTotalWoVat(saleDetails
            .filter(payment => payment.rowAction !== 'D')
            .reduce((sum, detail) => {
                return sum + detail.quantity * (detail.salesPrice / (1.00 + detail.vat / 100.00)) * (1.00 - detail.discount / 100.00) * (1 - detail.extraDiscount / 100.00);
            }, 0.00))
    }, [saleDetails]);

    useMemo(() => setTotalVat(totalWithVat - totalWoVat), [totalWithVat, totalWoVat]);
    const memoizedRowData = useMemo(() => {
        return saleDetails.filter((detail) => detail.rowAction !== "D");
    }, [saleDetails]);

    const memoizedRowData2 = useMemo(() => {
        return paymentExecutionsDetails.filter((detail) => detail.rowAction !== "D");
    }, [paymentExecutionsDetails]);

    const subjectBranchOptions = useMemo(() => {
        return entityBranches?.map((branch) => (
            {
                key: branch.entityBranchId,
                label: branch.description,
                value: branch.entityBranchId,
            }
        )) ?? []
    }, [entityBranches]);

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col xs={12} sm={8}>
                    <FormSelect
                        name="branchId"
                        label={t("branchIdInputLabel")}
                        required={true}
                        value={values.branchId}
                        options={
                            userBranches?.map((userBarnch) => {
                                return {
                                    key: userBarnch.branchId,
                                    label: userBarnch.description,
                                    value: userBarnch.branchId,
                                };
                            }) ?? []
                        }
                        disabled={values.validated || saleDetails.length > 0}
                    />
                    <FormSelect
                        name="documentTypeId"
                        label={t("documentTypeInputLabel")}
                        style={{ width: "100%" }}
                        value={values.documentTypeId}
                        disabled={values.validated || saleDetails.length > 0}
                        required={true}
                        options={
                            documentTypes
                                .map((documentType) => ({
                                    key: documentType.documentId,
                                    label: documentType.description,
                                    value: documentType.documentId,
                                }))
                        }
                    />
                    <FormSelectWithSearch
                        name="subjectId"
                        label={t("subjectIdInputLabel")}
                        required={true}
                        value={values.subjectId}
                        disabled={values.validated || saleDetails.length > 0}
                        fetchOptions={fetchBuyers}
                        fetchInitialOption={() => {
                            return {
                                key: values?.subjectId ?? 0,
                                label: values?.subject ?? "",
                                value: values?.subjectId ?? 0
                            }
                        }}
                        placeHolder={t("placeHolder")}
                        onChange={(option) => {
                            setFieldValue("subjectId", option.value);
                            setFieldValue("subject", option.label)
                            setFieldValue("branchSubjectId", undefined)
                            if (option) {
                                getEntityBranches(Number(option.value));
                            }
                        }}
                    />
                    <FormSelect
                        name="branchSubjectId"
                        label={t("branchSubjectIdInputLabel")}
                        value={values.branchSubjectId}
                        options={
                            subjectBranchOptions
                                .map((subjectBranchOption) => ({
                                    key: subjectBranchOption.key,
                                    label: subjectBranchOption.label,
                                    value: subjectBranchOption.value
                                })) ?? []
                        }
                        showSearch={true}
                        disabled={!values.subjectId || values.validated || saleDetails.length > 0}
                    />
                </Col>
                <Col xs={12} sm={8}>
                    <FormInput
                        name="date"
                        type="date"
                        required={true}
                        label={t("dateInputLabel")}
                        value={values.date?.split("T")[0] || ""}
                        disabled={values.validated}
                    />

                    <FormInput
                        name="paymentDeadline"
                        type="number"
                        required={true}
                        label={t("paymentDeadlineInputLabel")}
                        value={values.paymentDeadline}
                        disabled={values.validated}
                    />
                    <FormInput
                        name="deliveryDeadline"
                        type="date"
                        required={false}
                        label={t("deliveryDeadlineInputLabel")}
                        value={values.deliveryDeadline?.split("T")[0] || ""}
                        disabled={values.validated}
                    />
                </Col>
                <Col xs={12} sm={8}>
                    <FormSelect
                        name="paymentMethod"
                        label={t("paymentMethodInputLabel")}
                        options={
                            paymentMethods?.map((payment) => {
                                return {
                                    key: payment.paymentMethodId,
                                    label: payment.description,
                                    value: payment.paymentMethodId,
                                };
                            }) ?? []
                        }
                        ref={paymentMethodRef}
                        value={selectedPaymentMethod || ''}
                        handleCustomChange={(value) => {
                            setSelectedPaymentMethod(value);
                            selectPaymentMethod(value)
                        }}
                        disabled={values.validated || saleDetails.length === 0}
                    />
                    <AgGridTableComponent
                        style={{ overflow: "auto", height: 150 }}
                        gridRef={paymentExecutionsGridRef}
                        disabled={values.validated}
                        isLoading={isLoading}
                        defaultColDef={defaultColDef}
                        columnDefs={columnPaymentExecutionsDefs}
                        rowData={memoizedRowData2}
                        suppressCellFocus={true}
                        stopEditingWhenCellsLoseFocus={true}
                        onCellKeyDown={(event: CellKeyDownEvent) =>
                            handleNextGridColumnClick(event, paymentMethodRef)
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col className="custom-row" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <AutoComplete
                        options={itemOptions}
                        className="custom-search"
                        onSelect={handleOnSelect}
                        onSearch={(value) => handleSearchChange(value)}
                        onChange={onChange}
                        value={searchValue}
                        style={{ width: 300 }}
                        disabled={values.validated || values.documentTypeId === 0 || values.documentTypeId === undefined || values.branchId === 0 || values.branchId === undefined}
                    >
                        <Search
                            placeholder={t('searchPlaceholder')}
                            allowClear
                            ref={searchInputRef}
                            size="large"
                            onSearch={(value) => handleSearchChange(value)}
                        />
                    </AutoComplete>
                    {copiedData && (
                        <Button onClick={pasteDetails} disabled={saleDetails.length > 0 || !values.branchId || !values.documentTypeId || !values.subjectId}>{t("paste")}</Button>
                    )}

                </Col>
                <Col xs={12} sm={16} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                        <div className="documentTotals">{t("text1")} {totalWithVat?.toFixed(2)}</div>
                        <div className="documentTotals">{t("text2")} {totalWoVat?.toFixed(2)}</div>
                        <div className="documentTotals">{t("text3")} {totalVat?.toFixed(2)}</div>
                    </div>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col xs={24} sm={24}>
                    <AgGridTableComponent
                        onGridReady={onSaleDetailGridReady}
                        style={{ overflow: "auto" }}
                        gridRef={saleDetailGridRef}
                        isLoading={isLoading}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowData={memoizedRowData}
                        suppressCellFocus={true}
                        disabled={values.validated}
                        stopEditingWhenCellsLoseFocus={true}
                        onCellKeyDown={(event: CellKeyDownEvent) =>
                            handleNextGridColumnClick(event, searchInputRef)
                        }
                    />
                </Col>
            </Row>
        </>
    );
}