import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});
export const setLoadingState = createAsyncThunk(
  "loading/setLoadingState",
  (state: boolean, { dispatch }) => {
    dispatch(setLoading(state));
  }
);

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
