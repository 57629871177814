export default function PriceIcon() {
  return (
    <svg
      width="20"
      height="20"
      className="icon-for-sidebar"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9006 0.0977528C19.7681 -0.0325843 19.5538 -0.0325843 19.4213 0.0977528L18.1647 1.33346H10.2722L0.350168 11.1707C0.124406 11.3927 0 11.6884 0 12.0024C0 12.3164 0.124406 12.6121 0.350168 12.8341L7.28777 19.6563C7.52065 19.8853 7.82607 20 8.13217 20C8.43962 20 8.74708 19.8843 8.98267 19.653L18.644 9.8957V1.8048L19.9006 0.5691C20.0331 0.438763 20.0331 0.22809 19.9006 0.0977528ZM17.966 9.62503L8.49996 19.185C8.29793 19.3836 7.96912 19.3836 7.76709 19.185L0.829488 12.3628C0.627455 12.1641 0.627455 11.8408 0.830505 11.6411L10.5549 2.00014H17.4867L15.8484 3.61119C15.5803 3.43652 15.2599 3.33351 14.9152 3.33351C13.9806 3.33351 13.2203 4.0812 13.2203 5.00023C13.2203 5.91926 13.9806 6.66694 14.9152 6.66694C15.8498 6.66694 16.6101 5.91926 16.6101 5.00023C16.6101 4.66122 16.5054 4.34621 16.3277 4.08254L17.966 2.47149V9.62503ZM15.9321 5.00023C15.9321 5.55158 15.4759 6.00026 14.9152 6.00026C14.3545 6.00026 13.8982 5.55158 13.8982 5.00023C13.8982 4.44888 14.3545 4.0002 14.9152 4.0002C15.0718 4.0002 15.2182 4.0382 15.3508 4.10054L14.6755 4.76456C14.543 4.89489 14.543 5.10557 14.6755 5.2359C14.7416 5.3009 14.8284 5.33357 14.9152 5.33357C15.002 5.33357 15.0887 5.3009 15.1549 5.2359L15.8301 4.57188C15.8938 4.70222 15.9321 4.84622 15.9321 5.00023Z"
        fill="#5E5E5E"
        stroke="#5E5E5E"
        strokeWidth="0.000533316"
      />
    </svg>
  );
}
