import { Modal } from "antd"
import { t } from "i18next"
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import FormSelect from "../../../../CustomComponents/FormSelect";
import { useState } from "react";
import { JsxElement } from "typescript";
import { GenerateItemOrderDetailsDto } from "../../../../../models/clientDashboard/EntriesModule/Orders/GenerateItemOrderDetailsDto";
import { Classifier } from "../../../../../models/clientDashboard/Classifier/Classifier";
import { getClassifierValueByNameForSelect } from "../../../../../helperMethods/getClassifierValueByNameForSelect";

interface Props {
    isModalVisible: boolean,
    handleOk: (data: GenerateItemOrderDetailsDto) => void;
    handleCancel: () => void;
    classifiersForBrand: Classifier[];

}

export default function GenerateOrderModal({ isModalVisible, handleOk, handleCancel, classifiersForBrand }: Props) {

    const t = useAppTranslation("ClientDashboard.CreateEntryItemsOrders");
    const brands = useSelector((state: RootState) => state.brand.brands);
    const [selectedBrands, setSelectedBrands] = useState<number | null>(null);
    const [category1, setCategory1] = useState<number | null>(null);
    const [category2, setCategory2] = useState<number | null>(null);
    const [category3, setCategory3] = useState<number | null>(null);
    const [category4, setCategory4] = useState<number | null>(null);
    const theme = useSelector(
        (rootState: RootState) => rootState.appearance.theme
    );
    const onSubmit = () => {
        const data: GenerateItemOrderDetailsDto = {
            numOfDays: 0,
            entityId: 0,
            stockRotation: 0,
            category1: category1,
            category2: category2,
            category3: category3,
            category4: category4,
            brandId: selectedBrands,
            branchId: 0,
            limit: 0
        }
        handleOk(data);
    }
    const getClassifierValue = (type: string) => {
        switch (type) {
            case "K1":
                return category1;
            case "K2":
                return category2;
            case "K3":
                return category3;
            case "K4":
                return category4;
            default:
                return null;
        }
    }
    const renderClassifiers = () => {
        const inputComponents = classifiersForBrand !== undefined ?
            classifiersForBrand?.filter((classifier) => classifier.status === true && (classifier.description === "K1"
                || classifier.description === "K2" || classifier.description === "K3" || classifier.description === "K4")).map((classifier) => {
                    const value = getClassifierValue(classifier.description);
                    return (<FormSelect
                        required={classifier.mandatory}
                        key={classifier.description}
                        name={classifier.description.toLowerCase()}
                        label={classifier.clientDescription || classifier.description}
                        options={classifier.classifierDetails?.map((detail) => {
                            return {
                                key: detail.classifierDetailId?.toString(),
                                label: detail.description,
                                value: detail.classifierDetailId ?? 0,
                            }
                        })}
                        handleCustomChange={(selectedValue) => {

                            if (classifier.description === "K1") {
                                setCategory1(selectedValue as number);
                            } else if (classifier.description === "K2") {
                                setCategory2(selectedValue as number);
                            } else if (classifier.description === "K3") {
                                setCategory3(selectedValue as number);
                            } else if (classifier.description === "K4") {
                                setCategory4(selectedValue as number);
                            }

                        }}

                        value={value}
                    />)
                }
                ) : []
        return inputComponents;
    }

    return (
        <Modal
            title={t("customInputsModalTitle")}
            visible={isModalVisible}
            className={`${theme === "dark" ? "darkMode" : "lightMode"}`}
            onOk={onSubmit}
            onCancel={handleCancel}
        >
            {brands && brands.length > 0 ? (
                <div>
                    {renderClassifiers ? renderClassifiers() : null}

                    <h4>{t("selectBrandLabel")}</h4>

                    <FormSelect
                        name="selectedBrands"
                        label={t("brands")}
                        style={{ width: '100%' }}
                        value={selectedBrands}
                        options={
                            brands?.map((brand) => {
                                return {
                                    key: brand.brandId,
                                    value: brand.brandId,
                                    label: brand.description
                                }
                            })
                        }
                        handleCustomChange={(value) => {
                            setSelectedBrands(value)
                        }}
                    />
                </div>
            ) : (
                <p>{t("noBrandsAvailable")}</p>
            )}
        </Modal>
    )
}
