import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { Button, Typography, Tooltip, Popconfirm } from "antd";

import {
  deleteItemAsync,
  getItemsAsync,
  getItemsWithFilters,
} from "../../../redux/slices/itemSlice";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import { getItemFilterColumnsAsync } from "../../../redux/slices/itemSlice";
import { formatDateAsDDMMYYYY } from "../../../helperMethods/formatDate";
import AgGridTableWithPagination from "../../CustomComponents/AgGridTableWithPagination";
import { db } from "../../../indexDB/clientSideDatabase";
import { PropertyDto } from "../../../models/clientDashboard/Filter/PropertyDto";
import useFilters from "../../../customHooks/useFilter";
import { renderFilterInputs } from "../../../helperMethods/renderFilterInputs";
import FilterModal from "../../CustomComponents/FilterModal";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import "./index.css";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import "../../../assets/generalStyles/GridThemeStyles.css"
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import { usePermission } from "../../../customHooks/usePermission";
import WithPermission from "../../Authorization/WithPermission";

const { Title, Text, Link } = Typography;
const Items = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const items = useSelector((state) => state.item.items);
  const t = useAppTranslation("ClientDashboard.Items");
  const dispatch = useDispatch();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);

  const deleteItem = async (itemID) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      dispatch(deleteItemAsync(itemID));
    }
  };
  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };
  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "itemId",
      headerName: "ID",
      suppressMenu: true,
      width: 80,
      cellRenderer: (params) => {
        return <Text className="ag-grid-id-column">{params?.data?.itemId}</Text>;
      },
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      suppressMenu: true,
    },
    {
      field: "shortDescription",
      headerName: t("tableHeaders.shortDescription"),
      suppressMenu: true,
    },
    {
      field: "unitName",
      headerName: t("tableHeaders.unitName"),
      suppressMenu: true,
    },
    {
      field: "brandName",
      headerName: t("tableHeaders.brandName"),
      suppressMenu: true,
    },
    // {
    //   field: "vATDescription",
    //   headerName: t("tableHeaders.vATDescription"),
    //   suppressMenu: true,
    // },
    // {
    //   field: "category1",
    //   suppressMenu: true,
    //   headerName: t("tableHeaders.category1Description"),
    // },
    // {
    //   field: "category2",
    //   suppressMenu: true,
    //   headerName: t("tableHeaders.category2Description"),
    // },
    // {
    //   field: "category3",
    //   suppressMenu: true,
    //   headerName: t("tableHeaders.category3Description"),
    // },
    // {
    //   field: "category4",
    //   suppressMenu: true,
    //   headerName: t("tableHeaders.category4Description"),
    // },
    // {
    //   field: "customDescription",
    //   suppressMenu: true,
    //   headerName: t("tableHeaders.customDescription"),
    // },
    {
      field: "primarySupplierName",
      suppressMenu: true,
      headerName: t("tableHeaders.primarySupplierName"),
      width: 250
    },
    // {
    //   field: "secondarySupplierName",
    //   suppressMenu: true,
    //   headerName: t("tableHeaders.secondarySupplierName"),
    // },
    // {
    //   field: "supplierCode",
    //   headerName: t("tableHeaders.supplierCode"),
    //   suppressMenu: true,
    // },
    // {
    //   field: "variationCode",
    //   headerName: t("tableHeaders.variationCode"),
    //   suppressMenu: true,
    // },
    {
      field: "statusName",
      headerName: t("tableHeaders.statusDescription"),
      suppressMenu: true,
      width: 120,
    },
    // {
    //   field: "comment",
    //   headerName: t("tableHeaders.comment"),
    //   suppressMenu: true,
    // },
    // {
    //   field: "ingredients",
    //   headerName: t("tableHeaders.ingredients"),
    //   suppressMenu: true,
    // },
    {
      field: "packaging",
      headerName: t("tableHeaders.packaging"),
      suppressMenu: true,
    },
    // {
    //   field: "excise",
    //   headerName: t("tableHeaders.excise"),
    //   suppressMenu: true,
    //   width: 80,
    // },
    {
      field: "entryDate",
      headerName: t("tableHeaders.entryDate"),
      suppressMenu: true,
      cellRenderer: (params) => {
        return <Text>{formatDateAsDDMMYYYY(params?.data?.entryDate)}</Text>;
      },
    },
    {
      field: "details",
      headerName: t("tableHeaders.details"),
      suppressMenu: true,
      width: 90,
      cellRenderer: (params) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.ItemsCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateItem"),
                  `item/update/${params?.data?.itemId}`,
                  undefined
                );
                navigate(`/item/update/${params?.data?.itemId}`);
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 100,
    },
  ]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params) => {
    params.columnApi.autoSizeColumns(["unitName"]);
    params.columnApi.autoSizeColumns(["brandName"]);
    params.columnApi.autoSizeColumns(["vATDescription"]);
    params.columnApi.autoSizeColumns(["customDescription"]);
    params.columnApi.autoSizeColumns(["statusName"]);
    params.columnApi.autoSizeColumns(["entryDate"]);
    params.columnApi.autoSizeColumns(["packaging"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const getItems = async () => {
    const result = await dispatch(getItemsWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {

    getItems();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.ItemsCreate)

  const handleRowDoubleClick = (event) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateItem"),
        `item/update/${event.data.itemId}`,
        undefined
      );
      navigate(`/item/update/${event.data.itemId}`);
    }
  };

  const handleFilterSubmit = async (filters) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;
    const result = await dispatch(getItemsWithFilters(filteredFilters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getItemFilterColumnsAsync());
    return result;
  };
  const handleClearFilters = async () => {
    const result = await dispatch(getItemsWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });

  const handleExportItems = async () => {
    const itemIds = items.map((item) => item.itemId);

    setMenuItemsDisabled(true);

    try {
      const response = await apiService.post(
        "/api/ExcelTemplates/export-items",
        itemIds
      );
      let base64Data = response.data.Data;

      if (typeof base64Data === "string") {
        base64Data = base64Data.replace(/"/g, "");
      }

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "items.xlsx";
      downloadLink.click();
    } catch (error) {
      toast.error("Error downloading file");
    } finally {
      setMenuItemsDisabled(false);
    }
  };

  const handleCreateNewItem = () => {
    navigate("/item/register");
    db.addTab(t("tabs.createItem"), "item/register", undefined);
  };

  const handleImportItems = () => {
    db.addTab(t("tabs.importItem"), "items/import", undefined);
    navigate("/items/import");
  };

  const handleDownloadExcelTemplate = async () => {
    try {
      setMenuItemsDisabled(true);

      let endpoint = "/api/ExcelTemplates/import-items-template";
      const response = await apiService.get(endpoint);
      let base64Data = response.data.Data;

      if (typeof base64Data === "string") {
        base64Data = base64Data.replace(/"/g, "");
      }

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "template.xlsx";
      downloadLink.click();
    } catch (error) {
      toast.error("Error downloading file");
    } finally {
      setMenuItemsDisabled(false);
    }
  };

  return (
    <>
      <ListHeaderComponent
        title={t("titles.items")}
        createRecord={handleCreateNewItem}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={handleExportItems}
        exportTitle={t("export")}
        importMethod={handleImportItems}
        importTitle={"Import nga excel"}
        downloadTemplate={handleDownloadExcelTemplate}
        downloadTemplateTitle={"Shkarko shabllonin"}
        createAccessEnum={MenuOptionEnum.ItemsCreate}
        exportAccessEnum={MenuOptionEnum.ItemsExport}
        importAccessEnum={MenuOptionEnum.ItemsImport}
      />

      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState?.filterInputs?.map((input) => (
          <div
            key={input.name}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Text>
              {input.isClassifier
                ? input.name
                : t(`FilterColumns.${input.name}`)}
            </Text>
            {renderFilterInputs({ input: input, filterState: filterState })}
          </div>
        ))}
      </div>
      {filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button
            onClick={filterState.handleClearFilters}
            disabled={filterState.clearFilterButtonDisabled}
          >
            {t("clearFilters")}
          </Button>
          <Button
            type="primary"
            onClick={filterState.handleFilterSubmit}
            disabled={
              filterState.filterButtonDisabled || !filterState.inputsChanged
            }
          >
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={items}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.Items"
      />
    </>
  );
};

export default Items;
