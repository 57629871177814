import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { Entity } from "../../../models/clientDashboard/Entity/Entity";
import { useCallback, useEffect, useState } from "react";
import { getEntityTypesAsync } from "../../../redux/slices/entityTypeSlice";
import { getCountriesAsync } from "../../../redux/slices/countrySlice";
import { getCitiesAsync } from "../../../redux/slices/citySlice";
import { Form, Spin, Tabs } from "antd";
import { Formik, FormikErrors } from "formik";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { getEntityDataById } from "../../../redux/slices/entitySlice";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import MainFormCreateEntityComponent from "./CreateEntityComponents/MainFormCreateEntityComponent";
import EntityClassifiersComponent from "./CreateEntityComponents/EntityClassifiersComponent";
import { getClassifiersByTypeAsync } from "../../../redux/slices/classifierSlice";
import { categories, entityBranchClassifiers } from "./EntityClassifiersMapped";
import { convertClassifierValueToObject } from "../../../helperMethods/convertClassifierValueToObject";
import { ItemsClassifierDto } from "../../../models/clientDashboard/Classifier/ItemClassifierDto";
import { useParams } from "react-router-dom";
import { EntityValidationSchema } from "./CreateEntityComponents/EntityValidationSchema";
import useFormData from "../../../customHooks/useFormData";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import { db } from "../../../indexDB/clientSideDatabase";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import EntityBranchesComponent from "./CreateEntityComponents/EntityBranchesComponent";
import { CreateAndUpdateEntityBranchDto } from "../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { Classifier } from "../../../models/clientDashboard/Classifier/Classifier";
import { SelectOption } from "../../../models/SelectOption";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";

export default function UpdateEntity() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.UpdateEntity");
  const [entityBranchesDetails, setEntityBranchesDetails] = useState<CreateAndUpdateEntityBranchDto[]>([])
  const classifiers = useSelector((state: RootState) => state.classifier.classifiers);
  const [classifiersForBranch, setClassifiersForBranch] = useState<Classifier[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<number>(0);
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
  const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
    setSupplierOptions(options);
  };

  const setAdditionalStates = useCallback(
    (data: Entity | null) => {
      if (data !== null) {
        if (data.entityBranches) {
          setEntityBranchesDetails(data.entityBranches);
        }
      }
    },
    []
  );

  const { initialValues, setInitialValues } = useFormData<Entity>(
    `entity/update/${id}`,
    {} as Entity,
    setAdditionalStates,
    {
      fetchData: async () => {

        if (id) {
          const response = await dispatch(
            getEntityDataById(Number(id))
          );
          const data = response.payload as Entity;
          if (
            response.type ===
            "Entities/getEntityDataById/fulfilled"
          ) {
            const entity = response.payload as Entity;


            entity.k25Description = entity?.k25?.classifierDetailDescription;
            entity.k25 = entity?.k25?.classifierDetailId;

            entity.k26Description = entity?.k26?.classifierDetailDescription;
            entity.k26 = entity?.k26?.classifierDetailId;

            entity.k27Description = entity?.k27?.classifierDetailDescription;
            entity.k27 = entity?.k27?.classifierDetailId;

            entity.k28Description = entity?.k28?.classifierDetailDescription;
            entity.k28 = entity?.k28?.classifierDetailId;

            entity.k29Description = entity?.k29?.classifierDetailDescription;
            entity.k29 = entity?.k29?.classifierDetailId;

            entity.k30Description = entity?.k30?.classifierDetailDescription;
            entity.k30 = entity?.k30?.classifierDetailId;

            entity.k31Description = entity?.k31?.classifierDetailDescription;
            entity.k31 = entity?.k31?.classifierDetailId;

            entity.k32Description = entity?.k32?.classifierDetailDescription;
            entity.k32 = entity?.k32?.classifierDetailId;

            entity.k33Description = entity?.k33?.classifierDetailDescription;
            entity.k33 = entity?.k33?.classifierDetailId;

            entity.k34Description = entity?.k34?.classifierDetailDescription;
            entity.k34 = entity?.k34?.classifierDetailId;

            entity.k35Description = entity?.k35?.classifierDetailDescription;
            entity.k35 = entity?.k35?.classifierDetailId;

            entity.k36Description = entity?.k36?.classifierDetailDescription;
            entity.k36 = entity?.k36?.classifierDetailId;

            entity.k37Description = entity?.k37?.classifierDetailDescription;
            entity.k37 = entity?.k37?.classifierDetailId;

            entity.k38Description = entity?.k38?.classifierDetailDescription;
            entity.k38 = entity?.k38?.classifierDetailId;

            entity.k39Description = entity?.k39?.classifierDetailDescription;
            entity.k39 = entity?.k39?.classifierDetailId;

            entity.k40Description = entity?.k40?.classifierDetailDescription;
            entity.k40 = entity?.k40?.classifierDetailId;

            entity.k41Description = entity?.k41?.classifierDetailDescription;
            entity.k41 = entity?.k41?.classifierDetailId;

            entity.k42Description = entity?.k42?.classifierDetailDescription;
            entity.k42 = entity?.k42?.classifierDetailId;

            entity.k43Description = entity?.k43?.classifierDetailDescription;
            entity.k43 = entity?.k43?.classifierDetailId;

            entity.k44Description = entity?.k44?.classifierDetailDescription;
            entity.k44 = entity?.k44?.classifierDetailId;

            setInitialValues(entity);
            setSelectedCountry(entity.countryId);
            if (entity.entityBranches)
              setEntityBranchesDetails(entity.entityBranches);
          }
          setSelectedCountry(data.countryId);
          return data;
        }
        return {} as Entity;
      }
    }
  );



  const convertCategoryValue = (
    values: Entity,
    categoryKey: keyof Entity,
    classifierDescription: string
  ) => {
    if (
      values[categoryKey] !== undefined &&
      values[categoryKey] !== null &&
      (typeof values[categoryKey] !== 'object' || Array.isArray(values[categoryKey]))
    ) {
      const classifier = classifiers.find((c) => c.description === classifierDescription);
      if (classifier) {
        const classifierId = classifier.classifierId;
        const detail = classifier.classifierDetails?.find((cd) => cd.classifierDetailId === values[categoryKey]);
        const categoryDescription = detail?.description ?? values[categoryKey] as string;
        const categoryDetailId = detail?.classifierDetailId;
        values[categoryKey] = convertClassifierValueToObject(classifierId, categoryDescription.toString(), categoryDetailId) as ItemsClassifierDto;
      }
    }
  };

  const onFinish = async (values: Entity) => {

    categories.forEach(({ key, description }) => {
      convertCategoryValue(values, key, description);
    });

    entityBranchesDetails.forEach(entityBranch=>{
      if(typeof entityBranch.k55 === "object" ){
        entityBranch.k55 =  entityBranch?.k55?.value;
      }
      else{
        entityBranch.k55 = entityBranch.k55;
      }
      if(typeof entityBranch.k56 === "object" ){
        entityBranch.k56 =  entityBranch?.k56?.value;
      }
      else{
        entityBranch.k56 = entityBranch.k56;
      }
      if(typeof entityBranch.k57 === "object" ){
        entityBranch.k57 =  entityBranch?.k57?.value;
      }
      else{
        entityBranch.k57 = entityBranch.k57;
      }
      if(typeof entityBranch.k58 === "object" ){
        entityBranch.k58 =  entityBranch?.k58?.value;
      }
      else{
        entityBranch.k58 = entityBranch.k58;
      }
      if(typeof entityBranch.k59 === "object" ){
        entityBranch.k59 =  entityBranch?.k59?.value;
      }
      else{
        entityBranch.k59 = entityBranch.k59;
      }
    })

    values.entityBranches = entityBranchesDetails;

    await apiService
      .post("/api/Entity/updateEntity", values)
      .then(async (response) => {
        toast.success(t("entityUpdatedSuccessfully"));
        await db.tabs.delete(`entity/update/${id}`);
        // navigate("/entities");
      })
      .catch(() => { })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };
  const validationSchema = EntityValidationSchema();

  const handleValidation = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      return {};
    } catch (err: any) {
      const validationErrors: { [key: string]: string } = {};
      err.inner.forEach((error: any) => {
        validationErrors[error.path] = error.message;
        toast.error(error.message);
      });
      return validationErrors;
    }
  };

  const getEntityBranchesDetail = (tempId: string) => {

    let foundEntityBranches = null;
    setEntityBranchesDetails((prevValues) => {
      const entityBranchesExists = prevValues.find((entityBranches) => entityBranches.tempId === tempId);
      if (entityBranchesExists) {
        foundEntityBranches = entityBranchesExists;
      }
      return prevValues;
    });
    return foundEntityBranches;
  };
  const addOrUpdateEntityBranchesDetail = (
    entityBranchesDetail: CreateAndUpdateEntityBranchDto
  ) => {
    const entityBranchExists = entityBranchesDetails.find(
      (entityBranch) => entityBranch.tempId === entityBranchesDetail.tempId
    );

    if (entityBranchExists) {
      const index = entityBranchesDetails.findIndex(
        (entityBranch) => entityBranch.tempId === entityBranchesDetail.tempId
      );
      setEntityBranchesDetails((prevEntityBranchesDetails) => {
        const newEntityBranchesDetails = [...prevEntityBranchesDetails];
        newEntityBranchesDetails[index] = entityBranchesDetail;
        return newEntityBranchesDetails;
      });
    } else {
      setEntityBranchesDetails((prevEntityBranchesDetails) => [
        ...prevEntityBranchesDetails,
        entityBranchesDetail,
      ]);
    }
  };
  const removeEntityBranchesDetail = (index: number) => {
    setEntityBranchesDetails((prevEntityBranchesDetails) => {
      const activeEntityBranchesDetails = prevEntityBranchesDetails.filter(
        (entityBranchesDetails) => entityBranchesDetails.rowAction !== "D"
      );
      const selectedEntityBranchesDetail = activeEntityBranchesDetails[index];
      if (
        selectedEntityBranchesDetail.entityId === 0 ||
        !selectedEntityBranchesDetail.entityId
      ) {
        prevEntityBranchesDetails.splice(index, 1);
      } else {
        selectedEntityBranchesDetail.rowAction = "D";
      }
      return [...prevEntityBranchesDetails];
    });
  };
  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (
      values?: any
    ) => Promise<FormikErrors<Entity>>
  ) => {
    const errors = await validateForm();
    setTouched({
      description: true,
      businessNumber: true,
      vatNumber: true,
      entityTypeId: true,
      countryId: true,
      cityId: true,
      email: true,
      k25: true,
      k26: true,
      k27: true,
      k28: true,
      k29: true,
      k30: true,
      k31: true,
      k32: true,
      k33: true,
      k34: true,
      k35: true,
      k36: true,
      k37: true,
      k38: true,
      k39: true,
      k40: true,
      k41: true,
      k42: true,
      k43: true,
      k44: true,
    });
    return errors;
  };
  useEffect(() => {
    dispatch(getEntityTypesAsync());
    dispatch(getCountriesAsync());
    dispatch(getClassifiersByTypeAsync("Entities"));
    dispatch(getCitiesAsync());
  }, [dispatch, id]);

  return (
    <Spin tip="Loading..." spinning={isLoading}>

      <Formik
        initialValues={initialValues ?? {} as Entity}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validate={handleValidation}
        validateOnChange={false}
      >
        {({ values, handleSubmit, submitForm, validateForm, setTouched, setFieldValue }) => (
          <Form onFinish={handleSubmit} layout="vertical">
            <FormHeaderOptions
              title={t("updateFormTitle")}
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("updateEntityButton")}
              submitButtonIsDisabled={isLoading}
              createAccessEnum={MenuOptionEnum.EntitiesCreate}
            />

            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: t("entity"),
                  key: "1",
                  children: <MainFormCreateEntityComponent entity={values} setFieldValue={setFieldValue} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />,
                },
                {
                  label: t("classifiers"),

                  key: "2",
                  children: (
                    <EntityClassifiersComponent
                      entity={values}
                      setFieldValue={setFieldValue}
                    />
                  ),
                },
                {
                  label: t("entityBranches"),

                  key: "3",
                  children: (
                    <EntityBranchesComponent
                      values={values}
                      entityBranchesDetails={entityBranchesDetails}
                      getEntityBranchesDetail={getEntityBranchesDetail}
                      setClassifiersForBranch={setClassifiersForBranch}
                      classifiersForBranch={classifiersForBranch}
                      addOrUpdateEntityBranchesDetail={addOrUpdateEntityBranchesDetail}
                      removeEntityBranchesDetail={removeEntityBranchesDetail}
                      mode={"update"}
                      setFieldValue={setFieldValue}
                    />
                  ),
                },

              ]}
            />
            <HandleFormDataForTabSaving tabPath={`entity/update/${id}`} additionalStates={{ entityBranches: entityBranchesDetails }} />
          </Form>
        )}
      </Formik>
    </Spin>
  );
}