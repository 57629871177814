export default function DownIcon() {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81815 0.432125C9.64241 0.256388 9.35749 0.256388 9.18175 0.432125L4.99995 4.61393L0.818148 0.432125C0.642412 0.256388 0.357488 0.256388 0.181752 0.432124C0.00601584 0.60786 0.00601584 0.892784 0.181752 1.06852L4.99995 5.88672L9.81815 1.06852C9.99389 0.892785 9.99389 0.60786 9.81815 0.432125Z"
        fill="#8E8E8E"
      />
    </svg>
  );
}
