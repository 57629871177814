import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/Tax/TaxState";
import { TaxState } from "../../models/clientDashboard/Tax/TaxState";
import { Tax } from "../../models/clientDashboard/Tax/Tax";
import { RootState } from "..";

const initialState: TaxState = {
    taxes: [],
    error: null,
};

const TaxSlice = createSlice({
    name: "tax",
    initialState,
    reducers: {
        getTaxesStart(state: TaxState) {
            setLoading(true);
            state.error = null;
        },
        getTaxesSuccess(
            state: TaxState,
            action: PayloadAction<Tax[]>
        ) {
            setLoading(false);
            state.taxes = action.payload;
        },
        getTaxesFailure(
            state: TaxState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        deleteTaxFromState(state: TaxState, action: PayloadAction<number>) {
            setLoading(false);
            const taxId = action.payload;
            state.taxes = state.taxes.filter(tax => tax.taxId !== taxId);
        },
    }
});

export const getTaxesAsync = createAsyncThunk(
    "Taxes/getTaxes",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const taxes = state.tax.taxes;

        if (taxes.length > 0) {
            return taxes;
        }

        try {
            dispatch(getTaxesStart());
            const response = await apiService.get<ApiResponse<Tax[]>>(
                "api/Taxes"
            );
            // toast.success("Taxes were successfully retrieved!");
            dispatch(getTaxesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getTaxesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getTaxDataById = createAsyncThunk(
    "Taxes/getTaxDataById",
    async (taxId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<Tax>>(
                `/api/Taxes/${taxId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getTaxesStart,
    getTaxesSuccess,
    getTaxesFailure,
    deleteTaxFromState
} = TaxSlice.actions;

export default TaxSlice.reducer;