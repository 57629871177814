import { Classifier } from "../models/clientDashboard/Classifier/Classifier";


const validateClassifier = (value: any, classifier: Classifier) => {
    const regex = classifier.regex ? new RegExp(classifier.regex) : null;
  
    if (!classifier.mandatory && (value === null || value === undefined)) {
      return true;
    }
  
    if (typeof value === 'object' && value !== null) {
      if (classifier.classifierTypeDescription.toLowerCase() === 'select') {
        if (typeof value.classifierDetailId === 'number') {
          return true;
        } else {
          return false;
        }
      } else {
        const detailDescription = value.classifierDetailDescription;
  
        if (typeof detailDescription === 'string') {
          if (classifier.classifierTypeDescription.toLowerCase() === 'number' || classifier.classifierTypeDescription.toLowerCase() === 'decimal number') {
            const numberValue = parseFloat(detailDescription);
            if (!isNaN(numberValue) && (!regex || regex.test(detailDescription))) {
              return true;
            } else {
              return false;
            }
          } else {
            if (!regex || regex.test(detailDescription)) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    } else {
      if (typeof value === 'number' || (typeof value === 'string' && (!regex || regex.test(value)))) {
        return true;
      } else {
        return false;
      }
    }
  };

  export default validateClassifier;