export default function ExportIcon() {
    return(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 1.6665H5.00004C4.55801 1.6665 4.13409 1.8421 3.82153 2.15466C3.50897 2.46722 3.33337 2.89114 3.33337 3.33317V16.6665C3.33337 17.1085 3.50897 17.5325 3.82153 17.845C4.13409 18.1576 4.55801 18.3332 5.00004 18.3332H15C15.4421 18.3332 15.866 18.1576 16.1786 17.845C16.4911 17.5325 16.6667 17.1085 16.6667 16.6665V5.83317L12.5 1.6665Z"
      stroke="#5E5E5E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6666 1.6665V4.99984C11.6666 5.44186 11.8422 5.86579 12.1548 6.17835C12.4673 6.49091 12.8913 6.6665 13.3333 6.6665H16.6666"
      stroke="#5E5E5E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15V10"
      stroke="#5E5E5E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 12.5L10 15L12.5 12.5"
      stroke="#5E5E5E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>);
}
