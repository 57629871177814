import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CreateOrUpdateEntryItemsOrderDetailDto } from "../../../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDetailDto";
import { GenericDocumentDto } from "../../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateEntryItemsOrderDto } from "../../../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDto";
import useFormData from "../../../../customHooks/useFormData";
import moment from "moment";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import apiService from "../../../../extensions/api";
import { db } from "../../../../indexDB/clientSideDatabase";
import {
    getEntryItemsOrdersDataById,
    invalidateEntryItemsOrdersAsync,
    validateEntryItemsOrdersAsync,
} from "../../../../redux/slices/entryItemsOrdersSlice";
import { getItemsWithFilters } from "../../../../redux/slices/itemSlice";
import { getClassifiersByTypeAsync, getClassifiersForSubjectBranchesAsync } from "../../../../redux/slices/classifierSlice";
import { getEntitiesByTypeAsync } from "../../../../redux/slices/entitySlice";
import { getDocumentTypesWithSignHAsync } from "../../../../redux/slices/documentTypeSlice";
import { getUserBranches } from "../../../../redux/slices/userSlice";
import { Form, Spin, Tabs } from "antd";
import { Formik } from "formik";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import * as Yup from "yup";
import MainFieldsComponent from "./CreateEntryItemsOrdersComponent/MainFieldsComponent";
import { v4 as uuidv4 } from "uuid";
// import OtherFieldsComponent from "./CreateEntryItemsOrdersComponent/OtherFieldsComponent";
import { getCurrenciesAsync } from "../../../../redux/slices/currencySlice";
import { CopiedDocumentDetailsTable } from "../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { getBrandsAsync } from "../../../../redux/slices/brandSlice";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import { SelectOption } from "../../../../models/SelectOption";
import { DocumentTypeEnum } from "../../../../enums/DocumentTypeEnum";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";

export default function CreateEntryItemsOrders() {
    const navigate = useNavigate();
    const { type, mode, id, branchId } = useParams<{
        type: string;
        mode: string;
        id?: string;
        branchId?: string;
    }>();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const dispatch = useDispatch<AppDispatch>();
    const t = useAppTranslation("ClientDashboard.CreateEntryItemsOrders");
    const formikRef = useRef<any>(null);
    const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
    const userBranches = useSelector(
        (state: RootState) => state.user.loggedInUserBranches
    );

    const [entryItemsOrdersDetails, setEntryItemsOrdersDetails] = useState<
        CreateOrUpdateEntryItemsOrderDetailDto[]
    >([]);
    const generatedIdForCorrection = uuidv4();
    const classifiers = useSelector(
        (state: RootState) => state.classifier.classifiers
    );
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const [isDocumentValidated, setIsDocumentValidated] = useState<
        boolean | null
    >(null);

    const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
        setSupplierOptions(options);
    };
    const user = useSelector((state: RootState) => state.user.loggedInUser);
    const setAdditionalStates = useCallback(
        (
            data: GenericDocumentDto<
                CreateOrUpdateEntryItemsOrderDto,
                CreateOrUpdateEntryItemsOrderDetailDto
            >
        ) => {
            if (data.details) {
                setEntryItemsOrdersDetails(data.details);
            }
        },
        []
    );

    const getData = async () => {
        if (id && branchId) {
            const response = await dispatch(
                getEntryItemsOrdersDataById({ entryItemsOrdersId: id, branchID: branchId })
            );
            const data = response.payload as GenericDocumentDto<
                CreateOrUpdateEntryItemsOrderDto,
                CreateOrUpdateEntryItemsOrderDetailDto>;
            if (
                response.type ===
                "EntryItemsOrders/getEntryItemsOrdersDataById/fulfilled"
            ) {
                const entryItemsOrders = response.payload as GenericDocumentDto<
                    CreateOrUpdateEntryItemsOrderDto,
                    CreateOrUpdateEntryItemsOrderDetailDto
                >;

                setInitialValues(entryItemsOrders);
                setSupplierOptions([
                    {
                        key: entryItemsOrders.header.subjectId,
                        label: entryItemsOrders.header.subjectDescription ?? "",
                        value: entryItemsOrders.header.subjectId ?? 0,
                    }
                ]);

                formikRef?.current?.setValues(entryItemsOrders);
                const updatedDetails = entryItemsOrders.details.map((detail) => ({
                    ...detail,
                }));
                setEntryItemsOrdersDetails(updatedDetails);
            }
            return data;
        }
        return {
            generatedId: mode !== "update" ? uuidv4() : "",
            header: { deliveryDeadline: 1, exchangeRate: 1, fulfillPackage: true, packageLimit: 50, stability: type === "automatic" ? 1 : undefined, salesAnalysis: type === "automatic" ? 7 : undefined },
            date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
            documentTypeId: type === "automatic" ? DocumentTypeEnum.automaticOrder : undefined,
            branchId:
                user?.isMainBranch === false
                    ? Number(user.branchId)
                    : userBranches.length === 1
                        ? userBranches[0].branchId
                        : undefined,
        } as GenericDocumentDto<
            CreateOrUpdateEntryItemsOrderDto,
            CreateOrUpdateEntryItemsOrderDetailDto>;
    }

    const { initialValues, setInitialValues } = useFormData<
        GenericDocumentDto<
            CreateOrUpdateEntryItemsOrderDto,
            CreateOrUpdateEntryItemsOrderDetailDto
        >
    >(
        mode === "update"
            ? `entryItemsOrders/${type === "normal" ? "normal" : "automatic"}/update/${id}/${branchId}`
            : `entryItemsOrders/${type === "normal" ? "normal" : "automatic"}/register`,
        {
            generatedId: mode !== "update" ? uuidv4() : "",
            header: { deliveryDeadline: 1, exchangeRate: 1, fulfillPackage: true, packageLimit: 50 },
            date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
            branchId:
                user?.isMainBranch === false
                    ? Number(user.branchId)
                    : userBranches.length === 1
                        ? userBranches[0].branchId
                        : undefined,
            documentTypeId: type === "automatic" ? DocumentTypeEnum.automaticOrder : undefined,
        } as GenericDocumentDto<
            CreateOrUpdateEntryItemsOrderDto,
            CreateOrUpdateEntryItemsOrderDetailDto
        >,
        setAdditionalStates,
        {
            fetchData: getData
        }
    );
    const removeEntryItemsOrdersDetail = (index: number) => {
        setEntryItemsOrdersDetails((prevEntryItemsOrdersDetails) => {
            const activeEntryItemsOrdersDetails = prevEntryItemsOrdersDetails.filter(
                (entryItemsOrdersDetails) => entryItemsOrdersDetails.rowAction !== "D"
            );
            const selectedEntryItemsOrdersDetail =
                activeEntryItemsOrdersDetails[index];
            if (
                selectedEntryItemsOrdersDetail.entryItemsOrderDetailId === 0 ||
                !selectedEntryItemsOrdersDetail.entryItemsOrderDetailId
            ) {
                prevEntryItemsOrdersDetails.splice(index, 1);
            } else {
                selectedEntryItemsOrdersDetail.rowAction = "D";
            }
            return [...prevEntryItemsOrdersDetails];
        });
    };
    const addEntryItemsOrdersDetail = (
        entryItemsOrdersDetail: CreateOrUpdateEntryItemsOrderDetailDto
    ) => {
        setEntryItemsOrdersDetails((prevEntryItemsOrdersDetails) => {
            return [...prevEntryItemsOrdersDetails, entryItemsOrdersDetail];
        });
    };

    const validationSchema = Yup.object({
        branchId: Yup.number().required(t("branchIsRequired")),
        documentTypeId: Yup.number().required(t("documentTypeIsRequired")),
        date: Yup.date().required(t("dateIsRequired")),
        header: Yup.object({
            subjectId: Yup.number()
                .required(t("subjectIsRequired")),
            deliveryDeadline: Yup.number()
                .required(t("deliveryDeadlineIsRequired")),
        }),
    });

    const onFinish = async (
        values: GenericDocumentDto<
            CreateOrUpdateEntryItemsOrderDto,
            CreateOrUpdateEntryItemsOrderDetailDto
        >
    ) => {
        dispatch(setLoadingState(true));
        if (entryItemsOrdersDetails.length === 0) {
            toast.error(t("atLeastOneDetailIsRequired"));
            dispatch(setLoadingState(false));
            return;
        }
        if (user?.isMainBranch && !values.branchId) {
            toast.error(t("branchIsRequired"));
            dispatch(setLoadingState(false));
            return;
        }
        if (type === "automatic") {

            if (!values.header.currencyId) {
                toast.error(t('currencyIsRequired'));
                dispatch(setLoadingState(false));
                return;
            };
            if (values.header.exchangeRate <= 0) {
                toast.error(t('exchaneRatezeroIsRequired'))
                dispatch(setLoadingState(false));
                return;
            }
            if (!values.header.exchangeRate || values.header.exchangeRate <= 0) {
                toast.error(t('exchaneRateIsRequired'))
                dispatch(setLoadingState(false));
                return;
            }
            if (!values.header.stability) {
                toast.error(t('stabilityIsRequired'));
                dispatch(setLoadingState(false));
                return;
            }
            if (!values.header.salesAnalysis) {
                toast.error(t('salesAnalysisIsRequired'));
                dispatch(setLoadingState(false));
                return;
            }
        }
        values.details = entryItemsOrdersDetails;


        if (mode === "update" && id) {
            const result = await apiService
                .post(`/api/EntryItemsOrder/update`, values)
                .then(async (response) => {
                    if (response.status === 200) {
                        toast.success(t("updatedSuccessfully"));
                        getData();
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        } else {
            await apiService
                .post("/api/EntryItemsOrder/create", values)
                .then(async (response) => {
                    toast.success(t("createdSuccessfully"));
                    formikRef.current.setFieldValue(
                        "genericDocumentId",
                        response.data.Data
                    );
                    formikRef.current.setFieldValue(
                        "genericDocumentIdAsString",
                        response.data.Data
                    );

                    const createdId = response.data.Data;

                    await db.updateTab(
                        `entryItemsOrders/${type === "normal" ? "normal" : "automatic"}/register`,
                        `entryItemsOrders/${type === "normal" ? "normal" : "automatic"}/update/${createdId}/${values.branchId}`,
                        type === "normal" ? t("tabs.updateEntryItemsOrders") : t("tabs.updateAutomaticEntryItemsOrders")
                    );

                    navigate(`/entryItemsOrders/${type === "normal" ? "normal" : "automatic"}/update/${createdId}/${values.branchId}`);
                })
                .catch(() => { })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        }
        dispatch(setLoadingState(false));
    };

    useEffect(() => {
        dispatch(getItemsWithFilters([]));
        dispatch(getClassifiersByTypeAsync("EntryItems"));
        dispatch(getEntitiesByTypeAsync(true));
        dispatch(getCurrenciesAsync());
        dispatch(getDocumentTypesWithSignHAsync(type === "normal" ? "PO" : "POA"));
        dispatch(getUserBranches());
        dispatch(getBrandsAsync());
        getClassifiersForModal();
    }, [dispatch, mode, id]);
    const [tab, setTab] = useState<any>(null);

    useEffect(() => {
        const updateIndexedDB = async () => {
            setTab(await db.tabs.get(`entryItemsOrders/update/${id}`));

            const tab = await db.tabs.get(
                mode === "update"
                    ? `entryItemsOrders/update/${id}`
                    : "entryItemsOrders/register"
            );
            if (tab) {
                await db.tabs.put({
                    ...tab,
                    data: {
                        ...tab.data,
                        details: entryItemsOrdersDetails,
                    },
                });
            }
        };

        updateIndexedDB();
    }, [entryItemsOrdersDetails]);
    const [classifiersForBrand, setClassifiersForBrand] = useState<Classifier[]>([]);

    const getClassifiersForModal = async () => {
        const result = await dispatch(getClassifiersForSubjectBranchesAsync("Products"));
        if (result.type === "Classifiers/getClassifiersForSubjectBranchesAsync/fulfilled") {
            const payload = result.payload as Classifier[];
            setClassifiersForBrand(payload);
        }
    }

    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm();
        setTouched({
            genericDocumentId: true,
            genericDocumentIdAsString: true,
            branchId: true,
            documentTypeId: true,
            date: true,
            documentNo: true,
            header: true,
            generatedId: true,
            registeredBy: true,
            validated: true,
            details: true,
            subjectId: true,
            deliveryDeadline: true,
            entrydate: true,
            entryItemId: true,
            comment: true,
            stability: true,
            salesAnalysis: true,
            startDate: true,
            endDate: true,
            orderOutsideRoute: true,
            emailSentToSupplier: true,
            hasReturn: true,
            currencyId: true,
            exchangeRate: true,
            canceled: true,
            fulfillPackage: true,
            packageLimit: true,
        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    };
    const handleEntryItemsOrdersValidation = async (
        entryItemsOrderId: string,
        branchId: string
    ) => {
        const result = await dispatch(
            validateEntryItemsOrdersAsync({
                entryItemsOrdersId: entryItemsOrderId,
                branchID: branchId ?? "",
            })
        );
        if (result.type === "EntryItemsOrders/validateEntryItemsOrders/fulfilled") {
            toast.success(t("validatedSuccessfully"));
            formikRef.current.setFieldValue("validated", true);
        } else {
            toast.error(t("ValidationFailed"));
        }
    };

    const handleEntryItemsOrdersInvalidation = async (
        entryItemsOrderId: string,
        branchId: number
    ) => {
        const result = await dispatch(
            invalidateEntryItemsOrdersAsync({
                entryItemsOrderId: entryItemsOrderId,
                branchID: branchId,
            })
        );
        if (
            result.type === "EntryItemsOrders/invalidateEntryItemsOrders/fulfilled"
        ) {
            toast.success(t("invalidatedSuccessfully"));
            formikRef.current.setFieldValue("validated", false);
        } else {
            toast.error(t("invalidationFailed"));
        }
    };

    const isInvalidateButtonDisabled = (
        values: GenericDocumentDto<
            CreateOrUpdateEntryItemsOrderDto,
            CreateOrUpdateEntryItemsOrderDetailDto
        >
    ) => {
        return isLoading || !values.validated;
    };
    const copyDetails = () => {
        const details: CopiedDocumentDetailsTable<CreateOrUpdateEntryItemsOrderDetailDto> = {
            id: "EntryItemsOrdersDetails",
            list: entryItemsOrdersDetails
        }
        db.upsertGenericData(details);
    }

    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={
                    initialValues ??
                    ({} as GenericDocumentDto<
                        CreateOrUpdateEntryItemsOrderDto,
                        CreateOrUpdateEntryItemsOrderDetailDto
                    >)
                }
                validationSchema={validationSchema}
                onSubmit={onFinish}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    handleSubmit,
                    submitForm,
                    validateForm,
                    setTouched,
                    setFieldValue,
                }) => (
                    <>
                        <FormHeaderOptions
                            title={
                                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
                            }
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("createButton")}
                            submitButtonIsDisabled={
                                isLoading ||
                                entryItemsOrdersDetails.filter(
                                    (detail) => detail.rowAction !== "D"
                                ).length === 0 ||
                                values.validated
                            }
                            createAccessEnum={type === "normal" ? MenuOptionEnum.EntryOrderCreate : MenuOptionEnum.AutomaticOrderCreate}
                            validateAccessEnum={type === "normal" ? MenuOptionEnum.EntryOrderValidate : MenuOptionEnum.AutomaticOrderValidate}
                            validateButtonIsDisabled={
                                isLoading || mode !== "update" || values.validated
                            }
                            validateButtonText={t("validateButton")}
                            handleDocumentValidation={async () => {
                                if (values.genericDocumentIdAsString) {
                                    await handleEntryItemsOrdersValidation(
                                        values.genericDocumentIdAsString,
                                        values?.branchId?.toString() ?? ""
                                    );
                                }
                            }}
                            invalidateAccessEnum={type === "normal" ? MenuOptionEnum.EntryOrderInvalidate : MenuOptionEnum.AutomaticOrderInvalidate}
                            invalidateButtonText={t("invalidateButton")}
                            invalidateButtonIsDisabled={isInvalidateButtonDisabled(values)}
                            handleDocumentInvalidation={async () => {
                                await handleEntryItemsOrdersInvalidation(
                                    values.genericDocumentIdAsString ?? "",
                                    values?.branchId ?? 0
                                );
                            }}
                            copyButtonIsDisabled={
                                isLoading || mode !== "update" || entryItemsOrdersDetails.length === 0
                            }
                            copyButtonText={t("CopyButton")}
                            handleCopyDetails={mode === "update" ? () => copyDetails() : undefined}
                        />

                        <Form onFinish={handleSubmit} layout="vertical">
                            <Tabs
                                defaultActiveKey="1"
                                items={[
                                    {
                                        label: type === "normal" ? t("entryItemsOrders") : t("entryItemsAutomaticsOrders"),
                                        key: "1",
                                        children: (
                                            <MainFieldsComponent
                                                supplierOptions={supplierOptions}
                                                setEntryItemsOrderDetails={setEntryItemsOrdersDetails}
                                                values={values}
                                                entryItemsOrdersDetails={entryItemsOrdersDetails}
                                                addEntryItemsOrdersDetail={addEntryItemsOrdersDetail}
                                                removeEntryItemsOrdersDetail={
                                                    removeEntryItemsOrdersDetail
                                                }
                                                type={type ?? ""}
                                                mode={mode ?? ""}
                                                setFieldValue={setFieldValue}
                                                setClassifiersForBrand={setClassifiersForBrand}
                                                classifiersForBrand={classifiersForBrand}
                                            />
                                        ),
                                    },
                                    // ...(type !== "automatic"
                                    //     ? [
                                    //         {
                                    //             label: t("other"),
                                    //             key: "2",
                                    //             children: (
                                    //                 <OtherFieldsComponent
                                    //                     type={type ?? ""}
                                    //                     values={values}
                                    //                     uploadedFiles={uploadedFiles}
                                    //                     setFieldValue={setFieldValue}
                                    //                 />
                                    //             ),
                                    //         }
                                    //     ]
                                    //     : []),
                                ]}
                            />

                            <HandleFormDataForTabSaving
                                tabPath={
                                    mode === "update"
                                        ? `entryItemsOrders/${type === "normal" ? "normal" : "automatic"}/update/${id}/${branchId}`
                                        : `entryItemsOrders/${type === "normal" ? "normal" : "automatic"}/register`
                                }
                                additionalStates={{
                                    details: entryItemsOrdersDetails,
                                }}
                            />
                        </Form>
                    </>
                )}
            </Formik>
        </Spin>
    );
}
