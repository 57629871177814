import {
  App,
  AutoComplete,
  AutoCompleteProps,
  Button,
  Col,
  Input,
  InputRef,
  RefSelectProps,
  Row,
  Select,
} from "antd";
import FormInput from "../../../CustomComponents/FormInput";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { CreateAndEditLocalPurchaseDto } from "../../../../models/clientDashboard/LocalPurchase/CreateAndEditLocalPurchaseDto";
import { CreateAndEditLocalPurchaseDetailDto } from "../../../../models/clientDashboard/LocalPurchaseDetail/CreateAndEditLocalPurchaseDetailDto";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stat } from "fs";
import { AppDispatch, RootState } from "../../../../redux";
import moment from "moment";
import { toast } from "react-toastify";
import { focusOnAgGridEditingCell } from "../../../../helperMethods/focusOnAgGridEditingCell";
import { DocumentTypeEnum } from "../../../../enums/DocumentTypeEnum";
import { translateDocumentType } from "../../../../helperMethods/translateDocumentType";
import FormSelect from "../../../CustomComponents/FormSelect";
import { getLocalPurchaseDetailColumns } from "../../LocalPurchaseDetails/AgGridLocalPurchaseDetailColumns";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { handleNextGridColumnClick } from "../../../../helperMethods/handleNextGridColumnClick";
import Search from "antd/es/input/Search";
import { debounce } from "../../../../helperMethods/debounce";
import {
  getItemDataById,
  getItemDetailsForLocalPurchase,
  getItemsBySearchValue,
} from "../../../../redux/slices/itemSlice";
import { ItemDescriptionIdDto } from "../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { ItemModel } from "../../../../models/clientDashboard/Item/ItemModel";
import { GetItemDetailsDto } from "../../../../models/clientDashboard/Item/GetItemDetailsDto";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import { PricesDetail } from "../../../../models/clientDashboard/ItemPricesDetail/PricesDetail/PricesDetail";
import { getPriceDetailColumns } from "../../ItemPricesDetails/PricesDetails/AgGridPricesDetailColumns";
import { getEntitiesBySearch } from "../../../../redux/slices/entitySlice";
import { Entity } from "../../../../models/clientDashboard/Entity/Entity";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";
import { BranchPricesDetail } from "../../../../models/clientDashboard/ItemPricesDetail/PricesDetail/BranchPricesDetail";
import { SelectOption } from "../../../../models/SelectOption";
import { CopiedDocumentDetailsTable } from "../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { mapDataToInterface } from "../../../../helperMethods/mapDataToInterface";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getDocumentTypesWithSignHAsync } from "../../../../redux/slices/documentTypeSlice";
import { ConfigurationEnum } from "../../../../enums/ConfigurationEnum";
import { GetAllConfigurationsDto } from "../../../../models/Configurations/GetAllConfigurationsDto";
import { t as tForDocumentTypes } from "i18next";

interface Props {
  values: CreateAndEditLocalPurchaseDto;
  localPurchaseDetails: CreateAndEditLocalPurchaseDetailDto[];
  itemPriceDetails: PricesDetail[];
  supplierOptions: SelectOption[];
  addLocalPurchaseDetail: (
    localPurchaseDetail: CreateAndEditLocalPurchaseDetailDto
  ) => void;
  addInitialOptionsOfSupplier: (objects: SelectOption[]) => void;
  removePurchaseDetail: (rowIndex: number) => void;
  deletePriceDetail: (itemId: number) => void;
  addPricesDetail: (pricesDetail: PricesDetail) => void;
  mode: string;
  setFieldValue: (name: string, value: any) => void;
  setLocalPurchaseDetails: (data: any) => void;
  setItemPriceDetails:(priceDetails:PricesDetail[])=>void
}
export default function MainFieldsComponent({
  values,
  localPurchaseDetails,
  itemPriceDetails,
  addLocalPurchaseDetail,
  deletePriceDetail,
  removePurchaseDetail,
  addInitialOptionsOfSupplier,
  supplierOptions,
  addPricesDetail,
  mode,
  setFieldValue,
  setLocalPurchaseDetails,
  setItemPriceDetails
}: Props) {
  const t = useAppTranslation("ClientDashboard.CreateLocalPurchase");
  const branches = useSelector((state: RootState) => state.branch.branches);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const [searchValue, setSearchValue] = useState("");
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const searchInputRef = useRef<any | null>(null);
  const [showItemPrices, setShowItemPrices] = useState<boolean>(false);
  const suppliers = useSelector((state: RootState) => state.entity.entities);
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const configurations = useSelector(
    (state: RootState) => state.configuration.configurationValuesFromToken
  );
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const dispatch = useDispatch<AppDispatch>();
  const documentTypes = useSelector(
    (state: RootState) => state.documentType.documentTypes
  );
  const [copiedData, setCopiedData] = useState<
    CopiedDocumentDetailsTable<any> | undefined
  >(undefined);
  const localPurchaseDetailGridRef = useRef(null);
  const priceItemDetailGridRef = useRef(null);
  const [totalWithVat, setTotalWithVat] = useState<number>(0);
  const [totalWoVat, setTotalWoVat] = useState<number>(0);
  const [totalVat, setTotalVat] = useState<number>(0);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onLocalPurchaseDetailGridReady = (params: any) => {
    params.api.autoSizeColumns(["no"]);
    params.api.autoSizeColumns(["itemId"]);
    params.api.autoSizeColumns(["unitDescription"]);
    params.api.autoSizeColumns(["stock"]);
    params.api.autoSizeColumns(["expirationDate"]);
    params.api.autoSizeColumns(["quantity"]);
    params.api.autoSizeColumns(["purchasePrice"]);
    params.api.autoSizeColumns(["discount"]);
    params.api.autoSizeColumns(["extraDiscount"]);
    params.api.autoSizeColumns(["vat"]);
    params.api.autoSizeColumns(["margin"]);
    params.api.autoSizeColumns(["salesPrice"]);
    params.api.autoSizeColumns(["options"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [gridPricesApi, setGridPricesApi] = useState<any>(null);
  const [gridPricesColumnApi, setGridPricesColumnApi] = useState(null);

  const onItemPriceDetailGridReady = (params: any) => {
    setGridPricesApi(params.api);
    setGridPricesColumnApi(params.columnApi);
  };
  const purchaseConfig = configurations.find(
    (config) =>
      config.configurationId ===
      ConfigurationEnum.Mundesovendosjenecmimeveteshitjestekthyrjaemallit
  );
  const columnDefs = useMemo(() => {
    return getLocalPurchaseDetailColumns(
      removePurchaseDetail,
      t,
      searchInputRef,
      mode,
      setLocalPurchaseDetails,
      purchaseConfig ?? ({} as GetAllConfigurationsDto),
      setItemPriceDetails
    );
  }, [localPurchaseDetails, purchaseConfig]);
  const branchesForPriceDetail = branches?.map((branch) => {
    return {
      branchId: branch.branchId,
      branchName: branch.description,
      branchPrice: 0,
      itemId: 0,
    };
  });

  const getCopiedData = async () => {
    const data = await db.getGenericData();
    if (data) setCopiedData(data[0]);
    else setCopiedData(undefined);
  };

  const memoizedColumnItemPriceDefs = useMemo(() => {
    return getPriceDetailColumns(
      deletePriceDetail,
      t,
      searchInputRef,
      mode ?? "",
      branchesForPriceDetail,
      true
    );
  }, [
    branches,
    deletePriceDetail,
    t,
    searchInputRef,
    mode,
    branchesForPriceDetail,
  ]);

  const handleItemSearch = async (value: any, supplierId: number) => {
    const itemsBasedOnValueAction = await dispatch(
      getItemsBySearchValue({ searchValue: value, supplierId: supplierId })
    );
    const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

    if (!items || items.length === 0) {
      setItemOptions([
        {
          label: t("noItemsFound"),
          value: "no-items",
          disabled: true,
        },
      ]);
    } else {
      setItemOptions([]);
      setItemOptions(
        items?.map((item) => ({
          key: item.itemId,
          label: item.description,
          value: item.itemId.toString(),
        }))
      );
    }
  };

  const handleOnSelect = async (value: any) => {
    if (!value || isNaN(parseInt(value))) {
      toast.error("Please enter a valid search term");
      return;
    }
    if (!values.branchId) {
      toast.error(t("selectBranchError"));
      return;
    }

    const itemID = parseInt(value);
    const itemOnDatabase = await dispatch(
      getItemDetailsForLocalPurchase({ itemID, branchID: values.branchId })
    );
    if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/rejected"
    ) {
      toast.error("Item not found");
      return;
    } else if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/fulfilled"
    ) {
      const item = itemOnDatabase.payload as GetItemDetailsDto;

      if (item) {
        const isItemExist = localPurchaseDetails.some(
          (detail) => detail.itemId === item.itemId
        );
        if (isItemExist) {
          toast.success(t("Item"));
          return;
        }

        const priceWithoutVat = item.salesPrice / (1 + item.vat / 100);
        const calculatedMargin = priceWithoutVat
          ? ((priceWithoutVat - item.finalCost) / priceWithoutVat) * 100
          : 0;


        const newPurchaseDetail: CreateAndEditLocalPurchaseDetailDto = {
          entryItemsDetailsId: 0,
          entryItemsId: values.entryItemsId,
          itemId: item.itemId,
          itemDescription: item.description,
          unitId: item.unitId,
          unitDescription: item.unit,
          stock: item.stock ?? 0,
          quantity: 1,
          purchasePrice: item.purchasePrice ?? 0,
          discount: item.discount ?? 0,
          extraDiscount: item.extraDiscount ?? 0,
          vat: item.vat ?? 0,
          barcode: "",
          serialNumber: "",
          expirationDate: "",
          margin: isNaN(calculatedMargin) ? 0 : calculatedMargin,
          salesPrice: item.salesPrice ?? 0,
          rowAction: "A",
          finalCost: item.finalCost,
        };

        addLocalPurchaseDetail(newPurchaseDetail);

        const branchPricesDetails: BranchPricesDetail[] = branches.map(
          (branch) => ({
            branchId: branch.branchId,
            branchName: branch.description,
            branchPrice: 0,
            itemId: item.itemId,
          })
        );

        const newPriceDetail: PricesDetail = {
          pricesDetailsId: 0,
          itemId: item.itemId,
          itemDescription: item.description,
          branchPriceDetails: branchPricesDetails,
          purchasePrice: item.purchasePrice,
          discount: item.discount,
          extraDiscount: item.extraDiscount,
          salesPrice: item.salesPrice,
          wholeSalePrice: item.wholeSalePrice ?? 0,
          rowAction: "A",
        };
        addPricesDetail(newPriceDetail);
        setTimeout(() => {
          focusOnAgGridEditingCell(localPurchaseDetailGridRef, "barcode");
        }, 0);
        setSearchValue("");
      } else {
        toast.error("Item not found");
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const onChange = (data: any) => {
    setSearchValue(data);
  };

  const pasteDetails = async () => {
    try {
      if (copiedData) {
        const targetType = {
          entryItemsId: "",
          itemId: 0,
          itemDescription: "",
          unitId: 0,
          unitDescription: "",
          stock: 0,
          quantity: 0,
          purchasePrice: 0,
          discount: 0,
          extraDiscount: 0,
          vat: 0,
          barcode: "",
          serialNumber: undefined,
          expirationDate: null,
          itemCost: 0,
          finalCost: 0,
          margin: 0,
          salesPrice: 0,
          no: 0,
          rowAction: "A",
        } as CreateAndEditLocalPurchaseDetailDto;
        console.log(copiedData.list);
        const details = mapDataToInterface(
          copiedData.list as any[],
          targetType
        );
        setLocalPurchaseDetails(details);
      } else {
        toast.error(t("toastMessages.no_document"));
      }
    } catch (error) {
      toast.error(t("toastMessages.something_wrong"));
    }
  };

  const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

  const handleSearchChange = (value: string, supplierId: number) => {
    if (!value.trim()) {
      setSearchValue("");
      return;
    }
    setSearchValue(value);
    debouncedItemSearch(value, supplierId);
  };

  const fetchSuppliers = async (searchText: string) => {
    const result = await dispatch(
      getEntitiesBySearch({ searchText: searchText, isSupplier: true })
    );

    if (result.payload !== "An error occurred") {
      const payload = result.payload as Entity[];
      const options = payload.map((entity) => ({
        key: entity?.entityId?.toString(),
        label: entity?.description,
        value: entity?.entityId?.toString(),
      }));
      addInitialOptionsOfSupplier(options);
      return options;
    }
    return [];
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  useEffect(() => {
    dispatch(getDocumentTypesWithSignHAsync("H"));
    getCopiedData();
  }, [dispatch]);

  useMemo(() => {
    setTotalWithVat(
      localPurchaseDetails
        .filter((payment) => payment.rowAction !== "D")
        .reduce((sum, detail) => {
          return (
            sum +
            detail.quantity *
            (detail.purchasePrice * (1.0 + detail.vat / 100.0)) *
            (1.0 - detail.discount / 100.0) *
            (1 - detail.extraDiscount / 100.0)
          );
        }, 0.0)
    );
  }, [localPurchaseDetails]);

  useMemo(() => {
    setTotalWoVat(
      localPurchaseDetails
        .filter((payment) => payment.rowAction !== "D")
        .reduce((sum, detail) => {
          return (
            sum +
            detail.quantity *
            detail.purchasePrice *
            (1.0 - detail.discount / 100.0) *
            (1 - detail.extraDiscount / 100.0)
          );
        }, 0.0)
    );
  }, [localPurchaseDetails]);

  useMemo(
    () => setTotalVat(totalWithVat - totalWoVat),
    [totalWithVat, totalWoVat]
  );

  const memoizedRowData = useMemo(() => {
    return localPurchaseDetails.filter((detail) => detail.rowAction !== "D");
  }, [localPurchaseDetails]);

  useEffect(() => { }, [supplierOptions]);

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xs={12} sm={8}>
          <FormSelect
            name="branchId"
            label={t("branchIdInputLabel")}
            required={true}
            value={values.branchId}
            options={
              userBranches?.map((userBarnch) => {
                return {
                  key: userBarnch.branchId?.toString(),
                  label: userBarnch.description,
                  value: userBarnch.branchId,
                };
              }) ?? []
            }
            disabled={
              !user?.isMainBranch ||
              values.validated ||
              localPurchaseDetails.length > 0
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          ></div>
          {/* <label className="aria-required">
            <span style={{ color: "red" }}>* </span>{t("documentTypeInputLabel")}
          </label> */}
          <div style={{ display: "flex", width: "100%", gap: "10px" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <div style={{ display: "flex", width: "100%", gap: "10px" }}>
              {/* <FormInput
                name="documentTypeId"
                style={{ width: "80px" }}
                label={""}
                required={true}
                value={values.documentTypeId}
                disabled={true}
              /> */}
              <FormSelect
                name="documentTypeId"
                label={t("documentTypeInputLabel")}
                style={{ width: "100%" }}
                required={true}
                value={values.documentTypeId}
                disabled={values.validated || localPurchaseDetails.length > 0}
                options={
                  documentTypes.map((documentType) => {
                    return {
                      key: documentType.documentId,
                      // label: documentType.description,
                      label: tForDocumentTypes(documentType.serverDescription),
                      value: documentType.documentId,
                    };
                  }) ?? []
                }
              />
            </div>
          </div>
          <FormSelectWithSearch
            name="supplierId"
            label={t("supplierIdInputLabel")}
            required={true}
            value={values.supplierId}
            disabled={values.validated || localPurchaseDetails.length > 0}
            fetchOptions={fetchSuppliers}
            fetchInitialOption={() => {
              return supplierOptions[0];
            }}
            placeHolder={t("placeHolder")}
            onChange={(option) => {
              setFieldValue("supplierId", option.value);
              setFieldValue("supplierDescription", option.label);
            }}
          />
          {purchaseConfig?.status === true && (
            <FormInput
              name="plannedDate"
              type="date"
              required={true}
              label={t("plannedDateInputLabel")}
              value={values.plannedDate?.split("T")[0] || ""}
              disabled={values.validated}
              min={new Date().toISOString().split("T")[0]}
            />
          )}
        </Col>
        <Col xs={12} sm={8}>
          <FormInput
            name="date"
            type="date"
            required={true}
            label={t("dateInputLabel")}
            value={values.date?.split("T")[0] || ""}
            disabled={values.validated}
          />
          <FormInput
            name="supplierInvoiceNo"
            required={false}
            label={t("supplierInvoiceNoInputLabel")}
            value={values.supplierInvoiceNo}
            disabled={values.validated}
          />
          <FormInput
            name="invoiceDate"
            type="date"
            required={false}
            label={t("invoiceDateInputLabel")}
            value={values.invoiceDate?.split("T")[0] || ""}
            disabled={values.validated}
          />
          {/* <FormInput
            name="plannedDate"
            type="date"
            className={style.localPurchaseInput}
            required={false}
            label={t("plannedDateInputLabel")}
            value={values.date?.split("T")[0] || ""}
            disabled={values.validated}
          /> */}
        </Col>
        <Col xs={12} sm={8}>
          <FormInput
            name="entryItemsId"
            label={t("referenceNoInputLabel")}
            required={false}
            value={values.entryItemsId}
            disabled={true}
          />
          <FormInput
            name="documentNo"
            label={t("documentNoInputLabel")}
            required={false}
            value={values.documentNo}
            disabled={true}
          />
          <FormInput
            name="comment"
            label={t("commentInputLabel")}
            required={false}
            value={values.comment}
            disabled={values.validated}
          />
        </Col>
      </Row>
      {/* <Row gutter={[8, 8]}>
        <Col xs={12} sm={8}>
          <FormSwitch
            name="showItemPrices"
            label={t("showItemPricesInputLabel")}
            value={showItemPrices}
            onChange={(checked) => setShowItemPrices(checked)}
          />
        </Col>
      </Row> */}
      <Row>
        <Col
          className="custom-row"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <AutoComplete
            options={itemOptions}
            onSelect={handleOnSelect}
            onSearch={(value) => handleSearchChange(value, values.supplierId)}
            onChange={onChange}
            value={searchValue}
            className="custom-search"
            style={{ width: 300 }}
            disabled={
              values.supplierId === 0 ||
              values.supplierId === null ||
              values.supplierId === undefined ||
              values.validated ||
              values.branchId === 0 ||
              values.branchId === null ||
              values.branchId === undefined ||
              values.documentTypeId === undefined ||
              values.documentTypeId === null
            }
          >
            <Search
              placeholder={t("searchPlaceholder")}
              allowClear
              ref={searchInputRef}
              size="large"
              onSearch={(value) => handleSearchChange(value, values.supplierId)}
            />
          </AutoComplete>
          {copiedData && (
            <Button
              onClick={pasteDetails}
              disabled={
                localPurchaseDetails.length > 0 ||
                !values.branchId ||
                !values.documentTypeId ||
                !values.supplierId
              }
            >
              {t("paste")}
            </Button>
          )}
        </Col>
        <Col
          xs={12}
          sm={16}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div className="documentTotals">
              {t("text1")} {totalWithVat?.toFixed(2)}
            </div>
            <div className="documentTotals">
              {t("text2")} {totalWoVat?.toFixed(2)}
            </div>
            <div className="documentTotals">
              {t("text3")} {totalVat?.toFixed(2)}
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[12, 8]}>
        <Col xs={24} sm={24}>
          {/* {showItemPrices === true ? (
            <AgGridTableComponent
              style={{ overflow: "auto" }}
              onGridReady={onItemPriceDetailGridReady}
              gridRef={priceItemDetailGridRef}
              isLoading={isLoading}
              defaultColDef={defaultColDef}
              columnDefs={memoizedColumnItemPriceDefs}
              rowData={itemPriceDetails.filter(
                (detail) => detail.rowAction !== "D"
              )}
              suppressCellFocus={true}
              disabled={values.validated}
              stopEditingWhenCellsLoseFocus={true}
              onCellKeyDown={(event: CellKeyDownEvent) =>
                handleNextGridColumnClick(event, searchInputRef)
              }
            />
          ) : ( */}
          <AgGridTableComponent
            style={{ overflow: "auto" }}
            onGridReady={onLocalPurchaseDetailGridReady}
            gridRef={localPurchaseDetailGridRef}
            isLoading={isLoading}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={memoizedRowData}
            suppressCellFocus={true}
            disabled={values.validated}
            stopEditingWhenCellsLoseFocus={true}
            onCellKeyDown={(event: CellKeyDownEvent) =>
              handleNextGridColumnClick(event, searchInputRef)
            }
          />
          {/* )} */}
        </Col>
      </Row>
    </>
  );
}
