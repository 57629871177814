import { useDispatch, useSelector } from "react-redux";
import { CreateAndEditImportPurchaseDto } from "../../../../models/clientDashboard/ImportPurchase/CreateAndEditImportPurchaseDto";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { Button, Col, Input, Row } from "antd";
import FormInput from "../../../CustomComponents/FormInput";
import FormSelect from "../../../CustomComponents/FormSelect";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import style from "../ImportPurchaseStyle.module.css";

interface Props {
  values: CreateAndEditImportPurchaseDto;
  setFieldValue: (field: string, value: any) => void;
  uploadedFiles: File[];
}

export default function OtherFieldsComponent({ values, uploadedFiles, setFieldValue }: Props) {
  const currencies = useSelector((state: RootState) => state.currency.currencies);
  //const goodsReturnReason = useSelector((state: RootState) => state.goodsReturnReason.goodsReturnReasons);
  const t = useAppTranslation(
    "ClientDashboard.CreateImportPurchase.OtherFieldsComponent"
  );
  const dispatch = useDispatch<AppDispatch>();


  return (
    <Row gutter={[8, 8]}>
      <Col xs={12} sm={8}>
        <FormInput
          name="fromDate"
          label={t("fromDateInputLabel")}
          required={false}
          value={(values.fromDate?.split("T")[0] || "")}
          type="date"
          max={values.toDate}
          disabled={values.validated}
        />
        <FormInput
          name="toDate"
          label={t("toDateInputLabel")}
          required={false}
          value={(values.toDate?.split("T")[0] || "")}
          type="date"
          min={values.fromDate}
          disabled={values.validated}
        />
        <FormSwitch
          name="verified"
          label={t("verifiedInputLabel")}
          value={values.verified}
          disabled={values.validated}
        />
      </Col>
      <Col xs={12} sm={8}>
        <FormInput
          name="paymentDeadline"
          type="number"
          className={style.importPurchaseInput}
          required={false}
          label={t("paymentDeadlineInputLabel")}
          value={values.paymentDeadline}
          disabled={values.validated}
        />
        <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          <FormSelect
            name="currencyId"
            label={t("currencyInputLabel")}
            style={{ width: "80%" }}
            required={false}
            value={values.currencyId}
            disabled={values.validated}
            options={currencies.map((currency) => (
              { label: `${currency.currencySymbol} ${currency.currencyName}`, value: currency.currencyId }))}
          />
          <FormInput
            name="exchangeRate"
            type="number"
            label={t("exchangeRateInputLabel")}
            style={{ width: "100px" }}
            value={values.exchangeRate}
            disabled={values.currencyId === undefined || values.validated}
          />

        </div>
      </Col>
      <Col xs={12} sm={8}>
        <FormInput
          name="uploadedFiles"
          label={t("uploadedFilesInputLabel")}
          required={false}
          value={values.uploadedFiles}
          type="file"
          multiple={true}
          disabled={values.validated}
        />
        <FormInput
          name="customDocNo"
          type="string"
          className={style.importPurchaseInput}
          required={false}
          label={t("customDocNolineInputLabel")}
          value={values.customDocNo}
          disabled={values.validated}
        />
      </Col>
    </Row>
  );
}
