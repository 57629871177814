import { Button, Col, Row } from "antd";
import FormSelect from "../../../CustomComponents/FormSelect";
import style from "../ImportPurchaseStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import FormInput from "../../../CustomComponents/FormInput";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import { CreateAndUpdateGoodsImportExpenseDto } from "../../../../models/clientDashboard/GoodsImportExpense/CreateAndUpdateGoodsImportExpenseDto";
import { useMemo, useRef, useState } from "react";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { ColDef } from "ag-grid-community";
import { getExpensesDetailColumns } from "../../ExpenseDetails/AgGridExpesesDetailsColumns";
import { CreateAndEditImportPurchaseDto } from "../../../../models/clientDashboard/ImportPurchase/CreateAndEditImportPurchaseDto";
import { toast } from "react-toastify";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";
import { getEntitiesBySearch } from "../../../../redux/slices/entitySlice";
import { Entity } from "../../../../models/clientDashboard/Entity/Entity";
import { SelectOption } from "../../../../models/SelectOption";


interface Props {
    values: CreateAndEditImportPurchaseDto;
    expensesDetails: CreateAndUpdateGoodsImportExpenseDto[];
    setExpenseDetails:(values:CreateAndUpdateGoodsImportExpenseDto[])=>void;
    getExpenseDetail: (tempId: string) => CreateAndUpdateGoodsImportExpenseDto | null;
    addExpensesDetail: (
        expensesDetail: CreateAndUpdateGoodsImportExpenseDto
    ) => void;
    // updateExpenseDetail:(expsenseDetail:CreateAndUpdateGoodsImportExpenseDto)=>void;
    removeExpensesDetail: (rowIndex: number) => void;
    mode: string;
    supplierOptions: SelectOption[];
    setFieldValue: (name: string, value: any) => void;
    setSupplierOptions:(values:SelectOption[])=>void;
}

export default function ExpensesFieldsComponent({ values, expensesDetails, setExpenseDetails,getExpenseDetail,setSupplierOptions, addExpensesDetail, removeExpensesDetail, mode, supplierOptions, setFieldValue }: Props) {

    const [goodsImportExpense, setGoodsImportExpense] = useState<CreateAndUpdateGoodsImportExpenseDto>({
        distributionMethod: true, exchangeRate: 1, fiscalized: false, fromDate: "", toDate: "", paymentDeadline: 0,
    } as CreateAndUpdateGoodsImportExpenseDto)
    const entity = useSelector((state: RootState) => state.entity.entities);
    const expensType = useSelector((state: RootState) => state.expensType.expensTypes);
    const currency = useSelector((state: RootState) => state.currency.currencies);
    const t = useAppTranslation("ClientDashboard.CreateImportPurchase");
    const taxes = useSelector((rootState: RootState) => rootState.tax.taxes);
    const expenseDetailGridRef = useRef(null);
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const dispatch = useDispatch<AppDispatch>();


    const updateExpensesDetail = (tempId: string) => {
        const expenseDetail = getExpenseDetail(tempId);
        if (expenseDetail) {
            setSupplierOptions([{
                key:expenseDetail.entityId,
                label:expenseDetail.entity,
                value:expenseDetail.entityId
            }])
            setGoodsImportExpense({ ...expenseDetail, entityId: expenseDetail?.entityId ?? 0 });
        }
    }

    const columnDefs = useMemo(
        () =>
            getExpensesDetailColumns(
                removeExpensesDetail,
                t,
                updateExpensesDetail,
                mode
            ),
        [removeExpensesDetail, t, updateExpensesDetail, mode,expensesDetails]
    );

    const [gridApi, setGridApi] = useState<any>(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const onExpenseDetailGridReady = (params: any) => {
        params.columnApi.autoSizeColumns(['no']);
        params.columnApi.autoSizeColumns(['invoiceNumber']);
        params.columnApi.autoSizeColumns(['comment']);
        params.columnApi.autoSizeColumns(['value']);
        params.columnApi.autoSizeColumns(['vat']);
        params.columnApi.autoSizeColumns(['expenseType']);
        params.columnApi.autoSizeColumns(['distributionMethod']);
        params.columnApi.autoSizeColumns(['exchangeRate']);
        params.columnApi.autoSizeColumns(['currencyName']);
        params.columnApi.autoSizeColumns(['purchasePrice']);
        params.columnApi.autoSizeColumns(['nivf']);
        params.columnApi.autoSizeColumns(['date']);
        params.columnApi.autoSizeColumns(['fiscalized']);
        params.columnApi.autoSizeColumns(['fromDate']);
        params.columnApi.autoSizeColumns(['toDate']);
        params.columnApi.autoSizeColumns(['paymentDeadline']);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };

    const handleAddRow = () => {
        if (goodsImportExpense.entityId === 0 || goodsImportExpense.entityId === undefined) {
            toast.error(t("entityRequired"));
            return;
        }
        if (goodsImportExpense.value === 0 || goodsImportExpense.value === undefined) {
            toast.error(t("valueRequired"));
            return;
        }
        if (goodsImportExpense.date === "" || goodsImportExpense.date === undefined) {
            toast.error(t("dateRequired"));
            return;
        }
        if (goodsImportExpense.expenseTypeId === 0 || goodsImportExpense.expenseTypeId === undefined) {
            toast.error(t("expenseTypeIdRequired"));
            return;
        }
        if (goodsImportExpense.paymentDeadline === undefined || goodsImportExpense.paymentDeadline < 0) {
            toast.error(t("paymentDeadlineRequired"));
            return;
        }
        if (goodsImportExpense.invoiceNumber === "" || goodsImportExpense.invoiceNumber === null || goodsImportExpense.invoiceNumber === undefined) {
            toast.error(t("InvoiceNoRequired"));
            return;
        }
        if (goodsImportExpense.fiscalized && goodsImportExpense.fromDate && goodsImportExpense.toDate) {
            const fromDate = new Date(goodsImportExpense.fromDate);
            const toDate = new Date(goodsImportExpense.toDate);
   
            if (fromDate > toDate) {
                toast.error(t("fromDateCannotBeLaterThanToDate"));
                return;
            }
        }
   
        const existingIndex = expensesDetails.findIndex(detail => detail.entityId === goodsImportExpense.entityId && detail.rowAction !== "D");
   
        if (existingIndex !== -1) {
            expensesDetails[existingIndex] = { ...goodsImportExpense, rowAction: 'U' }; 
            setExpenseDetails(expensesDetails);
            gridApi.refreshCells();
        } else {
            addExpensesDetail(goodsImportExpense);
        }

        setExpenseDetails(expensesDetails); 
        gridApi.setRowData(expensesDetails.filter(detail => detail.rowAction !== "D"));
   
        setGoodsImportExpense({
            distributionMethod: true,
            exchangeRate: 1,
            fiscalized: false,
            fromDate: "",
            toDate: "",
            paymentDeadline: 0,
        } as CreateAndUpdateGoodsImportExpenseDto);
    };

    

    const fetchEntity = async (searchText: string) => {
        const result = await dispatch(
            getEntitiesBySearch({ searchText: searchText, isSupplier: true })
        );
        if (result.payload !== "An error occurred") {
            const payload = result.payload as Entity[];
            const options = payload.map((entity) => ({
                key: entity.entityId.toString(),
                label: entity.description,
                value: entity.entityId,
            }));
            return options;
        }
        return [];
    };

    const memoizedRowData = useMemo(() => {
        return expensesDetails.filter((detail) => detail.rowAction !== "D");
    }, [expensesDetails]);

    const taxOptions = useMemo(() =>{
        return taxes?.map((tax)=>{
        return {key:tax.taxId,
            label:tax.description,
            value:tax.taxId}
        })
    },[taxes])
    return (
        <div>
            <Row gutter={[8, 8]}>
                <Col xs={12} sm={8}>
                    <FormSelectWithSearch
                        name="entityId"
                        label={t("entityIdInputLabel")}
                        required={true}
                        value={goodsImportExpense.entityId}
                        disabled={values.validated}
                        fetchOptions={fetchEntity}
                        fetchInitialOption={() => {
                            return supplierOptions[0];
                        }}
                        placeHolder={t("placeHolder")}
                        onChange={(option) => {
                            setFieldValue("entityId", option.value);
                            setFieldValue("description", option.label);
                          }}
                    />
                    <FormInput
                        name="comment"
                        type="text"
                        label={t("commentInputLabel")}
                        className={style.importPurchaseInput}
                        value={goodsImportExpense.comment}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setGoodsImportExpense((prevValues) => ({
                                ...prevValues,
                                comment: value.target.value
                            }));
                        }}
                        disabled={values.validated}
                    />
                    <FormInput
                        name="value"
                        type="number"
                        label={t("valueIputLabel")}
                        required={true}
                        className={style.importPurchaseInput}
                        value={goodsImportExpense.value}
                        onChange={(e: any) => {
                            const inputValue = e.target.value ? e.target.value : 0;
                            setGoodsImportExpense(prevValues => ({ ...prevValues, value: inputValue }));
                        }}

                        disabled={values.validated}
                    />
                    <FormSelect
                        name="vat"
                        label={t("vatIputLabel")}
                        rules={[
                            {
                                message: t("vatIputLabel"),
                            },
                        ]}
                        value={goodsImportExpense.vat}
                        options={taxOptions}
                        handleCustomChange={(value) => {
                            setGoodsImportExpense((prevValues) => ({
                                ...prevValues,
                                vat: value,
                            }));
                        }}
                    />
                    <FormInput
                        name="invoiceNo"
                        type="string"
                        label={t("invoiceNoIputLabel")}
                        required={true}
                        className={style.importPurchaseInput}
                        value={goodsImportExpense.invoiceNumber}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setGoodsImportExpense((prevValues) => ({
                                ...prevValues,
                                invoiceNumber: value.target.value,
                            }));
                        }}
                        disabled={values.validated}
                    />
                </Col>
                <Col xs={12} sm={8}>
                    <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
                        <FormSelect
                            name="currencyId"
                            className={style.importPurchaseInput}
                            label={t("currencyIdInputLabel")}
                            style={{ width: "80%" }}
                            value={goodsImportExpense.currencyId}
                            options={currency?.map((currency) => {
                                return {
                                    key: currency.currencyId,
                                    label: `${currency.currencySymbol} ${currency.currencyName}`,
                                    value: currency.currencyId
                                };
                            }) ?? []
                            }
                            handleCustomChange={(value) => {
                                setGoodsImportExpense((prevValues) => ({
                                    ...prevValues,
                                    currencyId: value,
                                    currencyName: currency.find((currency) => currency.currencyId === value)?.currencyName ?? ""
                                }))
                            }}
                            disabled={values.validated}
                        />
                        <FormInput
                            name="exchangeRate"
                            type="number"
                            label={t("exchangeRateIputLabel")}
                            style={{ width: "100px" }}
                            className={style.importPurchaseInput}
                            value={goodsImportExpense.exchangeRate}
                            onChange={(e) => {
                                setGoodsImportExpense((prevValues) => ({
                                    ...prevValues,
                                    exchangeRate: parseFloat(e.target.value) || 0,
                                }));
                            }}
                            disabled={!goodsImportExpense.currencyId || values.validated}
                        />
                    </div>
                    <FormInput
                        name="nivf"
                        type="string"
                        label={t("nivfIputLabel")}
                        className={style.importPurchaseInput}
                        value={goodsImportExpense.nivf}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setGoodsImportExpense((prevValues) => ({
                                ...prevValues,
                                nivf: value.target.value
                            }));
                        }}
                        disabled={values.validated}
                    />
                    <FormInput
                        name="date"
                        label={t("dateInputLabel")}
                        required={true}
                        value={(goodsImportExpense.date?.split("T")[0] || "")}
                        type="date"
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setGoodsImportExpense((prevValues) => ({
                                ...prevValues,
                                date: value.target.value
                            }));
                        }}
                        disabled={values.validated}
                    />
                    <FormSelect
                        name="expenseTypeId"
                        label={t("expenseTypeIdIputLabel")}
                        style={{ width: "100%" }}
                        required={true}
                        value={goodsImportExpense.expenseTypeId}
                        disabled={values.validated}
                        options={expensType?.map((expensType) => {
                            return {
                                key: expensType.goodsEntryExpenseTypesId,
                                label: expensType.description,
                                value: expensType.goodsEntryExpenseTypesId
                            };
                        }) ?? []
                        }
                        handleCustomChange={(value) => {
                            setGoodsImportExpense((prevValues) => ({
                                ...prevValues,
                                expenseTypeId: value,
                                expenseType: expensType.find((expensType) => expensType.goodsEntryExpenseTypesId === value)?.description ?? "",
                                vat: null
                            }))
                        }}
                    />
                    <FormSwitch
                        className="switch-for-purchase"
                        name="distributionMethod"
                        label={t("distributionMethodInputLabel")}
                        value={goodsImportExpense.distributionMethod}
                        onChange={(checked) => setGoodsImportExpense((prevState) => ({
                            ...prevState,
                            distributionMethod: checked,
                        }))}
                    />
                </Col>
                <Col xs={12} sm={8}>
                    <FormInput
                        name="paymentDeadline"
                        type="number"
                        label={t("paymentDeadlineIputLabel")}
                        required={true}
                        className={style.importPurchaseInput}
                        value={goodsImportExpense.paymentDeadline}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setGoodsImportExpense((prevValues) => ({
                                ...prevValues,
                                paymentDeadline: value.target.value ? parseFloat(value.target.value ?? "0") : 0,
                            }));
                        }}
                        disabled={values.validated}
                    />
                    <FormSwitch
                        className="switch-for-purchase"
                        name="fiscalized"
                        label={t("fiscalizedInputLabel")}
                        onChange={(checked) => setGoodsImportExpense((prevState) => ({
                            ...prevState,
                            fiscalized: checked,
                            fromDate: checked ? prevState.fromDate : "",
                            toDate: checked ? prevState.toDate : ""
                        }))}
                        value={goodsImportExpense.fiscalized}
                    />
                    {goodsImportExpense.fiscalized && (
                        <>
                            <FormInput
                                name="fromDate"
                                label={t("fromDateInputLabel")}
                                required={false}
                                value={(goodsImportExpense.fromDate?.split("T")[0] || "")}
                                type="date"
                                max={goodsImportExpense.toDate ?? undefined}
                                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                                    setGoodsImportExpense((prevValues) => ({
                                        ...prevValues,
                                        fromDate: value.target.value
                                    }));
                                }}
                                disabled={values.validated}
                            />

                            <FormInput
                                name="toDate"
                                label={t("toDateInputLabel")}
                                required={false}
                                value={(goodsImportExpense.toDate?.split("T")[0] || "")}
                                type="date"
                                min={goodsImportExpense.fromDate ?? undefined}
                                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                                    setGoodsImportExpense((prevValues) => ({
                                        ...prevValues,
                                        toDate: value.target.value
                                    }));
                                }}
                                disabled={values.validated}
                            />
                        </>
                    )}
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                    <Button type="primary" onClick={handleAddRow}>
                        {t("addExpenseButtonText")}
                    </Button>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col xs={24} sm={24}>
                    <AgGridTableComponent
                        style={{ overflow: "auto" }}
                        onGridReady={onExpenseDetailGridReady}
                        gridRef={expenseDetailGridRef}
                        isLoading={isLoading}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowData={memoizedRowData}
                        suppressCellFocus={true}
                        stopEditingWhenCellsLoseFocus={true}
                        disabled={values.validated}
                    />
                </Col>
            </Row>
        </div>
    );
}