import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GoodsReturnReasonState } from "../../models/clientDashboard/GoodsReturnReasons/GoodsReturnReasonState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { GoodsReturnReason } from "../../models/clientDashboard/GoodsReturnReasons/GoodsReturnReason";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";

const initialState: GoodsReturnReasonState = {
    goodsReturnReasons: [],
    error: null,
};

const GoodsReturnReasonSlice = createSlice({
    name: "goodsReturnReason",
    initialState,
    reducers: {
        getGoodsReturnReasonsStart(state: GoodsReturnReasonState) {
            setLoading(true);
            state.error = null;
        },
        getGoodsReturnReasonsSuccess(
            state: GoodsReturnReasonState,
            action: PayloadAction<GoodsReturnReason[]>
        ) {
            setLoading(false);
            state.goodsReturnReasons = action.payload;
        },
        getGoodsReturnReasonsFailure(
            state: GoodsReturnReasonState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
       
        deleteGoodsReturnReasonFromState(state: GoodsReturnReasonState, action: PayloadAction<number>) {
            setLoading(false);
            const goodsReturnReasonId = action.payload;
            state.goodsReturnReasons = state.goodsReturnReasons.filter(goodsReturnReason => goodsReturnReason.goodsReturnReasonId !== goodsReturnReasonId);
        },
        addNewGoodsReturnReasonToState(state: GoodsReturnReasonState, action: PayloadAction<GoodsReturnReason>) {
            state.goodsReturnReasons.push(action.payload);
        }
    }
});

export const getGoodsReturnReasonsAsync = createAsyncThunk(
    "GoodsReturnReasons/getGoodsReturnReasons",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const goodsReturnReasons = state.goodsReturnReason.goodsReturnReasons;

        if (goodsReturnReasons.length > 0) {
            return goodsReturnReasons;
        }

        try {
            dispatch(getGoodsReturnReasonsStart());
            const response = await apiService.get<ApiResponse<GoodsReturnReason[]>>(
                "api/GoodsReturnReasons"
            );
            
            dispatch(getGoodsReturnReasonsSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getGoodsReturnReasonsFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getGoodsReturnReasonDataById = createAsyncThunk(
    "GoodsReturnReasons/getGoodsReturnReasonDataById",
    async (goodsReturnReasonId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<GoodsReturnReason>>(
                `/api/GoodsReturnReasons/${goodsReturnReasonId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteGoodsReturnReasonAsync = createAsyncThunk(
    "GoodsReturnReasons/deleteGoodsReturnReason",
    async (goodsReturnReasonId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/GoodsReturnReasons?goodsReturnReasonId=${goodsReturnReasonId}`
            );
            dispatch(deleteGoodsReturnReasonFromState(goodsReturnReasonId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getGoodsReturnReasonsStart,
    getGoodsReturnReasonsSuccess,
    getGoodsReturnReasonsFailure,
    deleteGoodsReturnReasonFromState,
    addNewGoodsReturnReasonToState
} = GoodsReturnReasonSlice.actions;

export default GoodsReturnReasonSlice.reducer;