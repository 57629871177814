import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FinInvoiceRegistrationState } from "../../models/clientDashboard/FinInvoiceRegistrations/FinInvoiceRegistrationState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { CreateOrUpdateFinInvoiceRegistration } from "../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistration";
import { CreateOrUpdateFinInvoiceRegistrationAccounting } from "../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationAccounting";
import { ApiResponse } from "../../models/ApiResponse";
import { GenericDocumentDto } from "../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import { FinInvoiceRegistration } from "../../models/clientDashboard/FinInvoiceRegistrations/FinInvoiceRegistration";

const initialState: FinInvoiceRegistrationState = {
    finInvoiceRegistrations: [],
    error: null,
  };
  
  const finInvoiceRegistrationSlice = createSlice({
    name: "finInvoiceRegistrations",
    initialState,
    reducers: {
      getFinInvoiceRegistrationStart(state: FinInvoiceRegistrationState) {
        setLoading(true);
        state.error = null;
      },
      getFinInvoiceRegistrationSuccess(
        state: FinInvoiceRegistrationState,
        action: PayloadAction<FinInvoiceRegistration[]>
      ) {
        setLoading(false);
        state.finInvoiceRegistrations = action.payload;
      },
      getFinInvoiceRegistrationFailure(
        state: FinInvoiceRegistrationState,
        action: PayloadAction<string>
      ) {
        setLoading(false);
        state.error = action.payload;
      },
    },
  });
  
  export const getFinInvoiceRegistrationFilterColumnsAsync = createAsyncThunk(
    "FinInvoiceRegistrations/getFinInvoiceRegistrationFilterColumnsAsync",
    async (_, { dispatch, getState, rejectWithValue }) => {
      const state = getState() as RootState;
      try {
        const response = await apiService.get<
          ApiResponse<
            GenericDocumentDto<
              CreateOrUpdateFinInvoiceRegistration,
              CreateOrUpdateFinInvoiceRegistrationAccounting
            >[]
          >
        >("api/Filter/finInvoiceRegistration");
        return response.data.Data;
      } catch (error) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        dispatch(getFinInvoiceRegistrationFailure(errorMessage));
        return rejectWithValue(errorMessage);
      }
    }
  );
  export const getFinInvoiceRegistrationColumnsAsync = createAsyncThunk(
    "FinInvoiceRegistrations/getFinInvoiceRegistrationColumnsAsync",
    async (_, { dispatch, getState, rejectWithValue }) => {
      const state = getState() as RootState;
      try {
        const response = await apiService.get<
          ApiResponse<PropertyDto[]>>("api/Filter/finInvoiceRegistration");
        return response.data.Data;
      } catch (error) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        dispatch(getFinInvoiceRegistrationFailure(errorMessage));
        return rejectWithValue(errorMessage);
      }
    }
  );
  export const getFinInvoiceRegistrationDataById = createAsyncThunk(
    "FinInvoiceRegistrations/getFinInvoiceRegistrationDataById",
    async (
      data: { finInvoiceRegistrationId: string; branchID: string },
      { dispatch, rejectWithValue }
    ) => {
      try {
        dispatch(setLoadingState(true));
        const response = await apiService.get<
          ApiResponse<
            GenericDocumentDto<
              CreateOrUpdateFinInvoiceRegistration,
              CreateOrUpdateFinInvoiceRegistrationAccounting
            >
          >
        >(
          `/api/FinInvoiceRegistration/getFinInvoiceRegistrationById?finInvoiceRegistrationId=${data.finInvoiceRegistrationId}&branchId=${data.branchID}`
        );
        return response.data.Data;
      } catch (error: any) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  );

  export const validateFinInvoiceRegistrationAsync = createAsyncThunk(
    "FinInvoiceRegistrations/validateFinInvoiceRegistration",
    async (
      data: { finInvoiceRegistrationId: string; branchID: string },
      { dispatch, rejectWithValue }
    ) => {
      try {
        dispatch(setLoadingState(true));
        await apiService.put<ApiResponse<void>>(
          `/api/FinInvoiceRegistration/validate?finInvoiceRegistrationId=${data.finInvoiceRegistrationId}&branchId=${data.branchID}`
        );
      } catch (error: any) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  );
  export const deleteFinInvoiceRegistrationAsync = createAsyncThunk(
    "FinInvoiceRegistrations/deleteFinInvoiceRegistration",
    async (finInvoiceRegistrationId: number, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoadingState(true));
        await apiService.delete<ApiResponse<void>>(
          `/api/FinInvoiceRegistration/${finInvoiceRegistrationId}`
        );
      } catch (error: any) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  );
  export const getFinInvoiceRegistrationWithFilters = createAsyncThunk(
    "FinInvoiceRegistrations/getFinInvoiceRegistrationWithFilters",
    async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
      try {
        dispatch(setLoadingState(true));
        const response = await apiService.post<ApiResponse<FinInvoiceRegistration[]>>
          ("/api/FinInvoiceRegistration/getDataWithFilter", data);
        dispatch(getFinInvoiceRegistrationSuccess(response.data.Data));
        return response.data.Data;
      } catch (error: any) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        dispatch(setLoadingState(false));
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  );
  export const invalidateFinInvoiceRegistrationAsync = createAsyncThunk(
      "FinInvoiceRegistrations/invalidateFinInvoiceRegistration",
      async (data: { finInvoiceRegistrationId: string, branchID: number }, { dispatch, rejectWithValue }) => {
          try {
              dispatch(setLoadingState(true));
              await apiService.put<ApiResponse<void>>(
                  `/api/FinInvoiceRegistration/unvalidate?finInvoiceRegistrationId=${data.finInvoiceRegistrationId}&branchId=${data.branchID}`
              );
          } catch (error: any) {
              const errorMessage =
                  typeof error === "string" ? error : "An error occurred";
              return rejectWithValue(errorMessage);
          } finally {
              dispatch(setLoadingState(false));
          }
      }
  );

  export const {
    getFinInvoiceRegistrationStart,
    getFinInvoiceRegistrationSuccess,
    getFinInvoiceRegistrationFailure,
  } = finInvoiceRegistrationSlice.actions;
  
  export default finInvoiceRegistrationSlice.reducer;