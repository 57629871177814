import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntryItemsOrderState } from "../../models/states/EntryItemsOrderState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { GenericDocumentDto } from "../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateEntryItemsOrderDto } from "../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDto";
import { CreateOrUpdateEntryItemsOrderDetailDto } from "../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDetailDto";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import { GetEntryItemsOrderDto } from "../../models/clientDashboard/EntriesModule/Orders/GetEntryItemsOrderDto";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { GenerateItemOrderDetailsDto } from "../../models/clientDashboard/EntriesModule/Orders/GenerateItemOrderDetailsDto";
import { AutomaticOrderDetailDto } from "../../models/clientDashboard/EntriesModule/Orders/AutomaticOrderDetailDto";

const initialState: EntryItemsOrderState = {
  entryItemsOrders: [],
  error: null,
};

const entryItemsOrdersSlice = createSlice({
  name: "entryItemsOrders",
  initialState,
  reducers: {
    getEntryItemsOrdersStart(state: EntryItemsOrderState) {
      setLoading(true);
      state.error = null;
    },
    getEntryItemsOrdersSuccess(
      state: EntryItemsOrderState,
      action: PayloadAction<
       GetEntryItemsOrderDto[]>
      
    ) {
      setLoading(false);
      state.entryItemsOrders = action.payload;
    },
    getEntryItemsOrdersFailure(
      state: EntryItemsOrderState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});

export const getEntryItemsOrdersFilterColumnsAsync = createAsyncThunk(
  "EntryItemsOrders/getEntryItemsOrderFilterColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<
        ApiResponse<
          GenericDocumentDto<
            CreateOrUpdateEntryItemsOrderDto,
            CreateOrUpdateEntryItemsOrderDetailDto
          >[]
        >
      >("api/Filter/entryItemsOrders");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getEntryItemsOrdersFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);
export const getEntryItemsOrdersColumnsAsync = createAsyncThunk(
  "EntryItemsOrders/getEntryItemsOrdersColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<
        ApiResponse<PropertyDto[]>>("api/Filter/entry-items-order");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getEntryItemsOrdersFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);
export const getEntryItemsOrdersDataById = createAsyncThunk(
  "EntryItemsOrders/getEntryItemsOrdersDataById",
  async (
    data: { entryItemsOrdersId: string; branchID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<
        ApiResponse<
          GenericDocumentDto<
            CreateOrUpdateEntryItemsOrderDto,
            CreateOrUpdateEntryItemsOrderDetailDto
          >
        >
      >(
        `/api/EntryItemsOrder/getOrderById?entryItemsOrderId=${data.entryItemsOrdersId}&branchId=${data.branchID}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const validateEntryItemsOrdersAsync = createAsyncThunk(
  "EntryItemsOrders/validateEntryItemsOrders",
  async (
    data: { entryItemsOrdersId: string; branchID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/EntryItemsOrder/validate?entryItemsOrderId=${data.entryItemsOrdersId}&branchId=${data.branchID}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const deleteEntryItemsOrdersAsync = createAsyncThunk(
  "EntryItemsOrders/deleteEntryItemsOrder",
  async (entryItemsOrdersId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.delete<ApiResponse<void>>(
        `/api/EntryItemsOrders/${entryItemsOrdersId}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getEntryItemsOrdersWithFilters = createAsyncThunk(
  "EntryItemsOrders/getEntryItemsOrdersWithFilters",
  async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<GetEntryItemsOrderDto[]>>
        ("/api/EntryItemsOrder/getDataWithFilter", data);
      dispatch(getEntryItemsOrdersSuccess(response.data.Data));
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(setLoadingState(false));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const invalidateEntryItemsOrdersAsync = createAsyncThunk(
    "EntryItemsOrders/invalidateEntryItemsOrders",
    async (data: { entryItemsOrderId: string, branchID: number }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/EntryItemsOrder/unvalidate?entryItemsOrderId=${data.entryItemsOrderId}&branchId=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const postEntryItemsOrderDetailsAsync = createAsyncThunk(
  'EntryItemsOrders/postEntryItemsOrderDetails',
  async (data: GenerateItemOrderDetailsDto, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<AutomaticOrderDetailDto[]>>(
        '/api/EntryItemsOrder/getSalesData', data
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage = typeof error === 'string' ? error : 'An error occurred';
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const {
  getEntryItemsOrdersStart,
  getEntryItemsOrdersSuccess,
  getEntryItemsOrdersFailure,
} = entryItemsOrdersSlice.actions;

export default entryItemsOrdersSlice.reducer;
