import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InvoiceWithRemarksReasonState } from "../../models/clientDashboard/InvoiceWithRemarksReason/InvoiceWithRemarksReasonState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { InvoiceWithRemarksReason } from "../../models/clientDashboard/InvoiceWithRemarksReason/InvoiceWithRemarksReason";

const initialState: InvoiceWithRemarksReasonState = {
    invoiceWithRemarksReasons: [],
    error: null,
};

const InvoiceWithRemarksReasonSlice = createSlice({
    name: "invoiceWithRemarksReason",
    initialState,
    reducers: {
        getInvoiceWithRemarksReasonsStart(state: InvoiceWithRemarksReasonState) {
            setLoading(true);
            state.error = null;
        },
        getInvoiceWithRemarksReasonsSuccess(
            state: InvoiceWithRemarksReasonState,
            action: PayloadAction<InvoiceWithRemarksReason[]>
        ) {
            setLoading(false);
            state.invoiceWithRemarksReasons = action.payload;
        },
        getInvoiceWithRemarksReasonsFailure(
            state: InvoiceWithRemarksReasonState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
       
        deleteInvoiceWithRemarksReasonFromState(state: InvoiceWithRemarksReasonState, action: PayloadAction<number>) {
            setLoading(false);
            const invoiceWithRemarksReasonId = action.payload;
            state.invoiceWithRemarksReasons = state.invoiceWithRemarksReasons.filter(invoiceWithRemarksReason => invoiceWithRemarksReason.invoiceWithRemarksReasonId !== invoiceWithRemarksReasonId);
        },
        addNewInvoiceWithRemarksReasonToState(state: InvoiceWithRemarksReasonState, action: PayloadAction<InvoiceWithRemarksReason>) {
            state.invoiceWithRemarksReasons.push(action.payload);
        }
    }
});

export const getInvoiceWithRemarksReasonsAsync = createAsyncThunk(
    "InvoiceWithRemarksReasons/getInvoiceWithRemarksReasons",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        // const invoiceWithRemarksReasons = state.invoiceWithRemarksReason.invoiceWithRemarksReasons;

        // if (invoiceWithRemarksReasons.length > 0) {
        //     return invoiceWithRemarksReasons;
        // }

        try {
            dispatch(getInvoiceWithRemarksReasonsStart());
            const response = await apiService.get<ApiResponse<InvoiceWithRemarksReason[]>>(
                "api/InvoiceWithRemarksReasons"
            );
            // toast.success("Brands were successfully retrieved!");
            dispatch(getInvoiceWithRemarksReasonsSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getInvoiceWithRemarksReasonsFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getInvoiceWithRemarksReasonDataById = createAsyncThunk(
    "InvoiceWithRemarksReasons/getInvoiceWithRemarksReasonDataById",
    async (invoiceWithRemarksReasonId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<InvoiceWithRemarksReason>>(
                `/api/InvoiceWithRemarksReasons/${invoiceWithRemarksReasonId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteInvoiceWithRemarksReasonAsync = createAsyncThunk(
    "InvoiceWithRemarksReasons/deleteInvoiceWithRemarksReason",
    async (invoiceWithRemarksReasonId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/InvoiceWithRemarksReasons?invoiceWithRemarksReasonId=${invoiceWithRemarksReasonId}`
            );
            dispatch(deleteInvoiceWithRemarksReasonFromState(invoiceWithRemarksReasonId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getInvoiceWithRemarksReasonsStart,
    getInvoiceWithRemarksReasonsSuccess,
    getInvoiceWithRemarksReasonsFailure,
    deleteInvoiceWithRemarksReasonFromState,
    addNewInvoiceWithRemarksReasonToState
} = InvoiceWithRemarksReasonSlice.actions;

export default InvoiceWithRemarksReasonSlice.reducer;