import { Button, Col, Row, Spin, Typography } from "antd";
import { CreateOrUpdateFinInvoiceRegistration } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistration";
import { CreateOrUpdateFinInvoiceRegistrationAccounting } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationAccounting";
import { GenericDocumentDto } from "../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import FormInput from "../../CustomComponents/FormInput";
import { CreateOrUpdateFinInvoiceRegistrationVat } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationVat";
import { useMemo, useRef, useState } from "react";
import { CreateOrUpdateFinInvoiceRegistrationDelayedExpense } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationDelayedExpense";
import { RootState } from "../../../redux";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import { handleNextGridColumnClick } from "../../../helperMethods/handleNextGridColumnClick";
import { getFinInvoiceRegistrationVatColumns } from "./GridColumnDefs/AgGridFininvoiceRegistrationVatColumns";
import { getFinInvoiceRegistrationDelayedExpensesColumns } from "./GridColumnDefs/AgGridFinInvoiceRegistrationDelayedExpenseColumns";
import FormSelect from "../../CustomComponents/FormSelect";
import { GetFinancialAccountsDto } from "../../../models/clientDashboard/AccountinConfigurations/GetFinancialAccountsDto";
import { toast } from "react-toastify";
interface Props {
  values: GenericDocumentDto<
    CreateOrUpdateFinInvoiceRegistration,
    CreateOrUpdateFinInvoiceRegistrationAccounting
  >;
  setFieldValue: (name: string, value: any) => void;
  mode: string;
  t: (value: string) => string;
  finInvoiceRegistrationVats: CreateOrUpdateFinInvoiceRegistrationVat[];
  setFinInvoiceRegistrationDelayedExpenses: (
    value: CreateOrUpdateFinInvoiceRegistrationDelayedExpense[]
  ) => void;
  finInvoiceRegistrationDelayedExpenses: CreateOrUpdateFinInvoiceRegistrationDelayedExpense[];
  financialAccounts: GetFinancialAccountsDto[];
  balancInfo:{
          totalInvoiceAmount:number,
          remainingInvoiceAmount:number,
          debitAmount: number,
          creditAmount: number,
  }
}

const { Title, Text } = Typography;

export default function ExpensesAndVatsComponent({
  values,
  setFieldValue,
  mode,
  t,
  setFinInvoiceRegistrationDelayedExpenses,
  finInvoiceRegistrationVats,
  finInvoiceRegistrationDelayedExpenses,
  financialAccounts,
  balancInfo
}: Props) {

  const [
    finInvoiceRegistrationDelayedExpense,
    setFinInvoiceRegistrationDelayedExpense,
  ] = useState<CreateOrUpdateFinInvoiceRegistrationDelayedExpense>(
    {} as CreateOrUpdateFinInvoiceRegistrationDelayedExpense
  );

  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  const finInvoiceRegistrationDelayedExpenseGridRef = useRef(null);

  const handleDelayedExpenseAdd = async () => {
    if (!balancInfo.totalInvoiceAmount || !finInvoiceRegistrationDelayedExpense.numberOfInstallments || finInvoiceRegistrationDelayedExpense.numberOfInstallments <= 0) {
      toast.error("Invalid totalInvoiceAmount or numberOfInstallments");
      return;
    }
  
    const newDelayedExpenses = [];
    const installmentAmount = Math.floor((balancInfo.totalInvoiceAmount / finInvoiceRegistrationDelayedExpense.numberOfInstallments) * 100) / 100;
    let remainingAmount = balancInfo.totalInvoiceAmount;
  
    for (let i = 0; i < finInvoiceRegistrationDelayedExpense.numberOfInstallments; i++) {
      const currentInstallmentAmount = 
        i === finInvoiceRegistrationDelayedExpense.numberOfInstallments - 1 ? remainingAmount : installmentAmount;
      newDelayedExpenses.push({
        ...finInvoiceRegistrationDelayedExpense,
        amount: currentInstallmentAmount,
        installmentNumber: i + 1,
      });
      remainingAmount -= currentInstallmentAmount;
    }
  
    setFinInvoiceRegistrationDelayedExpenses(newDelayedExpenses);
  };

  const removeDelayedExpense = async (index: number) => {
    const newdelayedExpenses = [...finInvoiceRegistrationDelayedExpenses];
    newdelayedExpenses.splice(index, 1);
    setFinInvoiceRegistrationDelayedExpenses(newdelayedExpenses);
  };
  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };


  const [delayedExpenseColumnDefs] = useState<
    ColDef<CreateOrUpdateFinInvoiceRegistrationDelayedExpense>[]
  >(
    getFinInvoiceRegistrationDelayedExpensesColumns(
      removeDelayedExpense,
      t,
      mode
    )
  );

  const memoizedDelayedExpenseData = useMemo(() => {
    return finInvoiceRegistrationDelayedExpenses;
  }, [finInvoiceRegistrationDelayedExpenses]);

  return (
    <>
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12}>
        {/* Delayed expenses*/}
        <Text>{t("delayedExpenses")}</Text>
        <div>
          <FormSelect
            name="delayedExpense.branchId"
            label={t("branchIdInputLabel")}
            style={{ width: "100%" }}
            value={finInvoiceRegistrationDelayedExpense.branchId}
            required={true}
            disabled={values.validated}
            options={
              userBranches.map((branch) => {
                return {
                  key: branch.branchId,
                  label: branch.description,
                  value: branch.branchId,
                };
              }) ?? []
            }
            handleCustomChange={(value) => {
              setFinInvoiceRegistrationDelayedExpense((prevValues) => {
                return { ...prevValues, branchId: value,branchName:userBranches.find(x=>x.branchId===value)?.description };
              });
            }}
          />
          <FormSelect
            name="delayedExpense.accountId"
            label={t("accountIdInputLabel")}
            style={{ width: "100%" }}
            value={finInvoiceRegistrationDelayedExpense.accountId}
            required={true}
            disabled={values.validated}
            options={
              financialAccounts.map((account) => {
                return {
                  key: account.financialAccountId ?? 0,
                  label: `${account?.financialAccountId}-${account?.description}`,
                  value: account.financialAccountId ?? 0,
                };
              }) ?? []
            }
            handleCustomChange={(value) => {
              setFinInvoiceRegistrationDelayedExpense((prevValues) => {
                return {
                  ...prevValues,
                  accountId: value,
                  accountDescription: financialAccounts?.find(
                    (x) => x.financialAccountId === value
                  )?.description,
                };
              });
            }}
          />
          <FormInput
            name="delayedExpense.date"
            label={t("dateInputLabel")}
            type="date"
            required={true}
            value={
              finInvoiceRegistrationDelayedExpense.date
                ?.toISOString()
                .split("T")[0]
            }
            onChange={(e) => {
              const dateValue = new Date(e.target.value);
              setFinInvoiceRegistrationDelayedExpense((prevValues) => ({
                ...prevValues,
                date: dateValue,
              }));
            }}
          />
    
        </div>
        </Col>
        <Col xs={24} sm={12} style={{paddingTop:"22px"}}>
        <FormInput
            name="delayedExpense.numberOfInstallments"
            label={t("numberOfInstallmentsInputLabel")}
            required={true}
            value={finInvoiceRegistrationDelayedExpense.numberOfInstallments}
            onChange={(e) => {
              setFinInvoiceRegistrationDelayedExpense((prevValues) => {
                return { ...prevValues, numberOfInstallments: Number(e.target.value) };
              });
            }}
          />
          <FormSelect
            name="delayedExpense.dK"
            label={t("dkInputLabel")}
            style={{ width: "100%" }}
            value={finInvoiceRegistrationDelayedExpense.dK}
            required={true}
            options={[
              {
                key: "D",
                label: "D",
                value: "D",
              },
              {
                key: "K",
                label: "K",
                value: "K",
              },
            ]}
            handleCustomChange={(value) => {
              setFinInvoiceRegistrationDelayedExpense((prevValues) => {
                return { ...prevValues, dK: value };
              });
            }}
          />
          <Button onClick={handleDelayedExpenseAdd}>
            {t("addDelayedExpenses")}
          </Button>
        </Col>
        </Row>
         <Row gutter={[16, 16]}>
        <Col xs={24} sm={24}>
        <div>
          <Spin tip="Loading..." spinning={isLoading}>
            <div
              className="ag-theme-quartz custom-theme-grid"
              style={{ height: 450, marginTop: 15, position: "relative" }}
            >
              <AgGridReact
                defaultColDef={defaultColDef}
                rowData={memoizedDelayedExpenseData}
                ref={finInvoiceRegistrationDelayedExpenseGridRef}
                columnDefs={delayedExpenseColumnDefs}
                suppressRowHoverHighlight={true}
                suppressCellFocus={false}
                suppressRowClickSelection={true}
                rowSelection="single"
                paginationPageSizeSelector={[10, 20, 50, 100]}
              />
              {values.validated && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    zIndex: 1000,
                    cursor: "not-allowed",
                  }}
                />
              )}
            </div>
          </Spin>
        </div>
      </Col>
    </Row>
    </>
  );
}
