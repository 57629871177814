import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CashRegisterState } from "../../models/states/CashRegisterState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { CashRegister } from "../../models/clientDashboard/Registrations/CashRegister/CashRegister";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";



const initialState: CashRegisterState = {
   cashRegisters: [],
    error: null
};

const CashRegisterSlice = createSlice({
    name: "cashRegister",
    initialState,
    reducers: {
        getCashRegisterStart(state: CashRegisterState) {
            setLoading(true);
            state.error = null;
        },
        getCashRegisterSuccess(
            state: CashRegisterState,
            action: PayloadAction<CashRegister[]>
        ) {
            setLoading(false);
            state.cashRegisters = action.payload;
        },
        getCashRegisterFailure(
            state: CashRegisterState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewCashRegisterToState(state: CashRegisterState, action: PayloadAction<CashRegister>) {
            setLoading(false);
            state.cashRegisters.push(action.payload);
        },
        updateCashRegisterInState(state:CashRegisterState, action: PayloadAction<CashRegister>) {
            setLoading(false);
            const updatedCashRegister = action.payload;
            const index = state.cashRegisters.findIndex(CashRegister=> CashRegister. cashRegisterId === updatedCashRegister.cashRegisterId);
            if (index !== -1) {
                state.cashRegisters[index] = updatedCashRegister;
            }
        },
        deleteCashRegisterFromState(state:CashRegisterState, action: PayloadAction<number>) {
            setLoading(false);
            const cashRegisterId = action.payload;
            state.cashRegisters = state.cashRegisters.filter(cashRegister => cashRegister.cashRegisterId!== cashRegisterId);
        },
    }
});

export const getCashRegistersAsync = createAsyncThunk(
    "CashRegister/getCashRegister",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;

        try {
            let url  = `api/CashRegister`;
            
            const response = await apiService.get<ApiResponse<CashRegister[]>>(
                url
            );
            dispatch(getCashRegisterSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getCashRegisterFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
        }
    }
);


export const getCashRegisterDataById = createAsyncThunk(
    "CashRegister/getCashRegisterDataById",
    async (cashRegisterId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<CashRegister>>(
                `/api/CashRegister/byId?cashRegisterId=${cashRegisterId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteCashRegisterAsync = createAsyncThunk(
    "CashRegister/deleteCashRegister",
    async (cashRegisterId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/ImportPurchase/${cashRegisterId}`
            );
            dispatch(deleteCashRegisterFromState(cashRegisterId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getCashRegisterStart,
    getCashRegisterSuccess,
    getCashRegisterFailure,
    addNewCashRegisterToState,
    updateCashRegisterInState,
    deleteCashRegisterFromState
} = CashRegisterSlice.actions;

export default CashRegisterSlice.reducer;