import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { ItemModel } from "../../../../models/clientDashboard/Item/ItemModel";
import { ItemDescription } from "../../../../models/clientDashboard/ItemDescription/ItemDescription";
import { AppDispatch, RootState } from "../../../../redux";
import { useEffect, useRef, useState } from "react";
import { getLanguagesAsync } from "../../../../redux/slices/languageSlice";
import { AgGridReact } from "ag-grid-react";
import { Button, Col, Row, Select, Typography } from "antd";
import { setEditingCell } from "../../../../helperMethods/setEditingCell";
import { DeleteOutlined } from "@ant-design/icons";
import FormTextArea from "../../../CustomComponents/FormTextArea";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { CellKeyDownEvent } from "ag-grid-community";
import { handleNextGridColumnClick } from "../../../../helperMethods/handleNextGridColumnClick";


interface Props {
    item: ItemModel;
    addNewItemDescription: (itemDescription: ItemDescription) => void;
    removeItemDescription: (index: number) => void;
    updatedItemDescriptions: (itemDescriptions: ItemDescription[]) => void;
    itemDescriptions: ItemDescription[];
    formikRef: any;
    setFieldValue: (name: string, value: any) => void;
}
const { Title, Text } = Typography;

const OtherFormCreateItemComponent = ({
    item,
    addNewItemDescription,
    removeItemDescription,
    updatedItemDescriptions,
    itemDescriptions,
    formikRef,
    setFieldValue
}: Props) => {
    // const currencies = useSelector((state: RootState) => state.currency.currencies);

    const dispatch = useDispatch<AppDispatch>();
    const languages = useSelector((state: RootState) => state.language.languages);
    const itemTypes = useSelector((state: RootState) => state.itemType.itemTypes);
    const itemStatuses = useSelector(
        (rootState: RootState) => rootState.itemStatus.itemStatuses
    );
    const classifiers = useSelector(
        (state: RootState) => state.classifier.classifiers
    );
    const [customObjectToAdd, setCustomObjectToAdd] = useState<{
        description: string;
        value: number;
    }>({
        description: "",
        value: 0,
    });
    const formatDescription = (description: string): string => {
        const match = description.match(/^K(\d+)$/);
        if (match) {
            return `category${match[1]}`;
        }
        return description;
    };

    useEffect(() => {
        dispatch(getLanguagesAsync());
    }, []);

    const suppliers = useSelector(
        (rootState: RootState) => rootState.entity.entities
    );

    const t = useAppTranslation("ClientDashboard.CreateItem.OtherForCreateItemFieldsComponent");

    const getClassifierValueByName = (name: string, item: any) => {
        const categoryValue = item[name];
        if (typeof categoryValue === "number") {
            return categoryValue;
        } else if (categoryValue && typeof categoryValue === "object") {
            return categoryValue.classifierDetailId || undefined;
        } else {
            return undefined;
        }
    };
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const itemDescriptionGridRef = useRef<AgGridReact<any> | null>(null);

    const getFilteredLanguages = (currentIndex: number) => {
        return languages.filter((language) => {
            const itemDescriptionsFromGrid = itemDescriptionGridRef.current?.props
                ?.rowData as ItemDescription[];
            const isLanguageUsed = itemDescriptionsFromGrid?.some((desc, index) => {
                return desc.language === language.code && index !== currentIndex;
            });
            return !isLanguageUsed;
        });
    };
    const initialColDefs = [
        {
            field: "index",
            headerName: "No.",
            width: 80,
            supressMenu: false,
            cellRenderer: (params: any) => {
                const rowIndex = params.node.rowIndex + 1;
                return <Text type="secondary">{rowIndex}</Text>;
            },
        },
        {
            field: "description",
            headerName: "Description",
            editable: true,
        },
        {
            field: "language",
            headerName: "Language",
            editable: false,
        },
        {
            field: "options",
            headerName: "Options",
            cellRenderer: (params: any) => {
                return (
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => removeItemDescription(params.rowIndex)}
                        style={{ color: "#007FFF" }}
                    />
                );
            },
            width: 100,
        },
    ];

    useEffect(() => {
        const updatedColDefs = initialColDefs.map((colDef) => {
            if (colDef.field === "language") {
                return {
                    ...colDef,
                    cellRenderer: (params: any) => {
                        const currentIndex = params.node.rowIndex;
                        const filteredLanguages = getFilteredLanguages(currentIndex);
                        return (
                            <Select
                                style={{ width: "100%", height: "70%" }}
                                value={params.value}
                                onChange={(value) => {
                                    const updatedValues = [
                                        ...(itemDescriptionGridRef.current?.props.rowData || []),
                                    ];
                                    const itemDescriptionForEdit = updatedValues[currentIndex];
                                    if (itemDescriptionForEdit) {
                                        itemDescriptionForEdit.language = value;
                                    }
                                    updatedItemDescriptions(updatedValues);
                                }}
                                options={filteredLanguages.map((language) => ({
                                    key: language.code,
                                    label: language.description,
                                    value: language.code,
                                }))}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label.toLowerCase() ?? "").includes(
                                        input.toLowerCase()
                                    )
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare((optionB?.label ?? "").toLowerCase())
                                }
                            />
                        );
                    },
                };
            }
            return colDef;
        });
        setItemDescriptionColDefs(updatedColDefs);
    }, [languages]);

    const [itemDescriptionsColDefs, setItemDescriptionColDefs] = useState([
        {
            field: "index",
            headerName: "No.",
            width: 80,
            cellRenderer: (params: any) => {
                const rowIndex = params.node.rowIndex + 1;
                return <Text type="secondary">{rowIndex}</Text>;
            },
        },
        {
            field: "description",
            headerName: t("tableHeaders.description"),
            editable: true,
        },
        {
            field: "language",
            headerName: t("tableHeaders.language"),
            editable: false,
            cellRenderer: (params: any) => {
                const currentIndex = params.node.rowIndex;
                const filteredLanguages = getFilteredLanguages(currentIndex);
                return (
                    <Select
                        style={{ width: "100%", height: "70%" }}
                        value={params.value}
                        onChange={(value) => {
                            const updatedValues = [
                                ...(itemDescriptionGridRef.current?.props
                                    ?.rowData as ItemDescription[]),
                            ];
                            const itemDescriptionForEdit = updatedValues[currentIndex];
                            if (itemDescriptionForEdit) {
                                itemDescriptionForEdit.language = value;
                            }
                            updatedItemDescriptions(updatedValues);
                        }}
                        options={filteredLanguages.map((language) => ({
                            key: language.code,
                            label: language.description,
                            value: language.code,
                        }))}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                    />
                );
            },
        },
        {
            field: "options",
            headerName: t("tableHeaders.options"),
            cellRenderer: (params: any) => {
                return (
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => removeItemDescription(params.rowIndex)}
                        style={{ color: "#007FFF" }}
                    ></Button>
                );
            },
            width: 100,
        },
    ]);

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };
    const handleAddNewItemDescription = () => {
        addNewItemDescription({
            itemId: 0,
            description: "",
            language: "",
            isDeleted: false,
        });
        setTimeout(() => {
            setEditingCell(itemDescriptionGridRef, "description");
        }, 0);
    };
    const [itemDescriptionGridApi, setItemDescriptionGridApi] = useState(null);
    const [itemDescriptionGridColumnApi, setItemDescriptionGridColumnApi] =
        useState(null);

    const onItemDescriptionGridReady = (params: any) => {
        setItemDescriptionGridApi(params.api);
        setItemDescriptionGridColumnApi(params.columnApi);
    };





    return (
        <Row gutter={[8, 8]}>
            <Col xs={12} sm={12}>
                <Button color="blue" onClick={handleAddNewItemDescription}>
                    {t("addItemDescription")}
                </Button>

                <AgGridTableComponent
                    isLoading={isLoading}
                    className="item-grid"
                    gridRef={itemDescriptionGridRef ?? null}
                    onGridReady={onItemDescriptionGridReady}
                    defaultColDef={defaultColDef}
                    rowData={itemDescriptions}
                    columnDefs={itemDescriptionsColDefs}
                    pagination={true}
                    paginationPageSize={20}
                    suppressCellFocus={true}
                    stopEditingWhenCellsLoseFocus={true}
                    onCellKeyDown={(event: CellKeyDownEvent) => {
                        handleNextGridColumnClick(event)
                    }}
                />
                </Col>
            <Col xs={12} sm={12}>
            <FormTextArea
                    name="comment"
                    label={t("commentInputLabel")}
                    rules={[
                        {
                            message: t("commentInputPlaceholder"),
                        },
                    ]}
                    placeholder={t("commentInputPlaceholder")}
                    rows={5}
                    value={item.comment}
                />
                <FormTextArea
                    name="ingredients"
                    label={t("ingredientsInputLabel")}
                    rules={[
                        {
                            message: t("ingredientsInputPlaceholder"),
                        },
                    ]}
                    placeholder={t("ingredientsInputPlaceholder")}
                    rows={5}
                    value={item.ingredients}
                />
            </Col>
        </Row>
    );
}
export default OtherFormCreateItemComponent;