import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServerState } from "../../models/states/ServerState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { GetServersDto } from "../../models/dashboard/Servers/GetServersDto";
import { ApiResponse } from "../../models/ApiResponse";
import { toast } from "react-toastify";
import apiService from "../../extensions/api";

const initialState: ServerState = {
  servers: [],
  error: null,
};

const serversSlice = createSlice({
  name: "servers",
  initialState,
  reducers: {
    getServersStart(state: ServerState) {
      setLoading(true);
      state.error = null;
    },
    getServersSuccess(
      state: ServerState,
      action: PayloadAction<GetServersDto[]>
    ) {
      setLoading(false);
      state.servers = action.payload;
    },
    getServersFailure(state: ServerState, action: PayloadAction<string>) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});

export const getServersAsync = createAsyncThunk(
  "dashboard/servers",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { servers } = (getState() as { servers: ServerState }).servers;

    if (servers.length > 0) {
      return servers;
    }

    try {
      dispatch(getServersStart());
      const response = await apiService.get<ApiResponse<GetServersDto[]>>(
        "api/dashboard/servers"
      );
      toast.success("Serverat u shkarkuan me sukses!");
      dispatch(getServersSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getServersFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const { getServersStart, getServersSuccess, getServersFailure } =
  serversSlice.actions;

export default serversSlice.reducer;
