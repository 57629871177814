import { useSelector } from "react-redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { RootState } from "../../../../redux";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import * as Yup from "yup";
import { toast } from "react-toastify";


export function EntityValidationSchema(){
    const t = useAppTranslation("ClientDashboard.CreateEntity.ValidationMessages");
    const classifiers = useSelector((state:RootState)=>state.classifier.classifiers);
    const yupSchema: any = {};

    const validateClassifier = (value: any, classifier: Classifier, t: any) => {
        const regex = classifier.regex ? new RegExp(classifier.regex) : null;
      
        if (!classifier.mandatory && (value === null || value === undefined)) {
          return true;
        }
      
        if (typeof value === 'object' && value !== null) {
          if (classifier.classifierTypeDescription.toLowerCase() === 'select') {
            if (typeof value.classifierDetailId === 'number') {
              return true;
            } else {
              return false;
            }
          } else {
            const detailDescription = value.classifierDetailDescription;
      
            if (typeof detailDescription === 'string') {
              if (classifier.classifierTypeDescription.toLowerCase() === 'number' || classifier.classifierTypeDescription.toLowerCase() === 'decimal number') {
                const numberValue = parseFloat(detailDescription);
                if (!isNaN(numberValue) && (!regex || regex.test(detailDescription))) {
                  return true;
                } else {
                  return false;
                }
              } else {
                if (!regex || regex.test(detailDescription)) {
                  return true;
                } else {
                  return false;
                }
              }
            } else {
              return false;
            }
          }
        } else {
          if (typeof value === 'number' || (typeof value === 'string' && (!regex || regex.test(value)))) {
            return true;
          } else {
            return false;
          }
        }
      };

    classifiers.filter((classifier) => classifier.status === true).forEach(classifier => {
        const name = classifier.description.toLowerCase();
        switch (classifier.classifierTypeDescription.toLowerCase()) {
          case 'text':
            let textValidation = Yup.mixed().nullable().test({
              name: 'validateClassifier',
              message: t('invalidInput'),
              exclusive: true,
              params: { classifier, t },
              test: (value: any) => validateClassifier(value, classifier, t),
            });
            if (classifier.mandatory) textValidation = textValidation.required(t('classifierIsRequired'));
            yupSchema[name] = textValidation;
            break;
          case 'select':
            yupSchema[name] = classifier.mandatory
              ? Yup.mixed().required(t('classifierIsRequired')).test({
                name: 'validateClassifier',
                message: t('invalidInput'),
                exclusive: true,
                params: { classifier, t },
                test: (value: any) => validateClassifier(value, classifier, t),
              })
              : Yup.mixed().nullable().test({
                name: 'validateClassifier',
                message: t('invalidInput'),
                exclusive: true,
                params: { classifier, t },
                test: (value: any) => validateClassifier(value, classifier, t),
              });
            break;
          case 'number':
          case 'decimal number':
            let numberValidation = Yup.mixed().nullable().test({
              name: 'validateClassifier',
              message: t('invalidInput'),
              exclusive: true,
              params: { classifier, t },
              test: (value: any) => validateClassifier(value, classifier, t),
            });
            if (classifier.mandatory) numberValidation = numberValidation.required(t('classifierIsRequired'));
            yupSchema[name] = numberValidation;
            break;
          case 'email':
            let emailValidation = Yup.mixed().nullable().test({
              name: 'validateClassifier',
              message: t('invalidEmail'),
              exclusive: true,
              params: { classifier, t },
              test: (value: any) => validateClassifier(value, classifier, t),
            });
            if (classifier.mandatory) emailValidation = emailValidation.required(t('classifierIsRequired'));
            yupSchema[name] = emailValidation;
            break;
          default:
            yupSchema[name] = Yup.mixed().nullable().test({
              name: 'invalidInput',
              message: t('invalidClassifier'),
              exclusive: true,
              params: { classifier, t },
              test: (value: any) => validateClassifier(value, classifier, t),
            });
        }
      });

  yupSchema.description = Yup.string().required(t("descriptionIsRequired"));
  yupSchema.businessNumber = Yup.string().required(t("businessNumberIsRequired"));
  yupSchema.entityTypeId = Yup.number().required(t("entityTypeIdIsRequired"));
  yupSchema.countryId = Yup.number().required(t("countryIdIsRequired"));
  yupSchema.cityId = Yup.number().required(t("cityIdIsRequired"));
  yupSchema.address = Yup.string().required(t("addressIsRequired"));
  yupSchema.email = Yup.string().email().required(t("emailIsRequired"));

  // const validationSchema = Yup.object().shape(yupSchema);
  const validationSchema = Yup.object().shape(yupSchema);
  return validationSchema;

}