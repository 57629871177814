import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { Button, Tooltip, Typography } from "antd";
import { AgGridReact } from "ag-grid-react";
import {
  getCustomsAsync,
  getCustomsSuccess,
} from "../../../redux/slices/customSlice";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { AppDispatch, RootState } from "../../../redux";
import { Custom } from "../../../models/clientDashboard/Custom/Custom";
import { setEditingCell } from "../../../helperMethods/setEditingCell";
import _ from "lodash";
import { CreateCustomDto } from "../../../models/clientDashboard/Custom/CreateCustomDto";
import apiService from "../../../extensions/api";
import { CellKeyDownEvent } from "ag-grid-community";
import { PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { handleNextGridColumnClick } from "../../../helperMethods/handleNextGridColumnClick";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";


const { Title, Text, Link } = Typography;

const Customs = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  // const customs = useSelector((state: RootState) => state.custom.customs);
  const t = useAppTranslation("ClientDashboard.CustomsList");
  const gridRef = useRef<AgGridReact | null>(null);
  const [customs, setCustoms] = useState<Custom[]>([]);
  const [menuCustomsDisabled, setMenuCustomsDisabled] = useState(false);

  const getCustoms = async () => {
    const response = await dispatch(getCustomsAsync());
    if (response.type === "Customs/getCustoms/fulfilled") {
      setCustoms(response.payload as Custom[]);
    }
  };

  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const addOrUpdateCustomsOnCellChange = async (params: any, field: string) => {
    try {
      const value = params.newValue;
      const data = params.data;
      
  
      if (field === "description") {
        console.log("test2")
        if (value === null || value === undefined || value.trim() === "") {
          toast.error(t("undefined"));
          params.api.stopEditing(false); 
          return;
        }

        const existingDescriptions = gridRef?.current?.props?.rowData?.filter(custom => custom.customId !== 0)?.map(custom => custom.description.toLowerCase()) || [];
      if (existingDescriptions.includes(value.trim().toLowerCase())) {
        toast.error(t("uniqueDescription"));
        params.api.stopEditing(false);
        return;
      }
      if (data.value < 0) {
        toast.error(t("negativeValue"));
        params.api.stopEditing(false);
        return;
      }
      if (gridRef?.current?.props?.rowData?.filter(custom => custom.customId !== 0)?.some(custom => custom.value === value)) {
        toast.error(t("uniqueValue"));
        params.api.stopEditing(false);
        return;
      }
      }

      if (field === "value") {
        if (value < 0) {
          toast.error(t("negativeValue"));
          params.api.stopEditing(false);
          return;
        }
        
        if (gridRef?.current?.props?.rowData?.filter(custom => custom.customId !== 0)?.find(custom => custom.value === value)) {
          toast.error(t("uniqueValue"));
          params.api.stopEditing(false);
          return;
        }
        if (data.description === null || data.description === undefined || data.description.trim() === "") {
          toast.error(t("undefined"));
          params.api.stopEditing(false); 
          return;
        }
        const existingDescriptions = gridRef?.current?.props?.rowData?.filter(custom => custom.customId !== 0)?.map(custom => custom.description.toLowerCase()) || [];
      if (existingDescriptions.includes(data.description.trim().toLowerCase())) {
        toast.error(t("uniqueDescription"));
        params.api.stopEditing(false);
        return;
      }


      }

      params?.node?.setDataValue(field, value);
      params.api.stopEditing(true);

      
  
      if (data.customId === 0 && data.description.trim() !== "") {
        const newCustom: CreateCustomDto = {
          description: field === "description" ? value : data.description,
          value: field === "value" ? value : data.value,
        };
  
        apiService.post("/api/Customs", newCustom)
          .then(() => {
            dispatch(getCustomsSuccess([]));
            getCustoms();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (data.customId !== 0 && data.description.trim() !== "") {
        const updatedCustom: Custom = {
          customId: data.customId,
          description: field === "description" ? value : data.description,
          value: field === "value" ? value : data.value,
        };
  
        apiService.put("/api/Customs", updatedCustom)
          .then(() => {
            dispatch(getCustomsSuccess([]));
            getCustoms();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (e) {
      console.error(e);
    }
  };
  

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "customId",suppressMenu: true ,
      headerName: "ID",
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params.data.customId}</Text>;
      },
    },
    {
      field: "description",suppressMenu: true ,
      headerName: t("tableHeaders.description"),
      editable: true,
      onCellValueChanged: (params: any) => {
        addOrUpdateCustomsOnCellChange(params, "description");
      },
      // onCellValueChanged: (params: any) => {
      //   addOrUpdateCustomsOnCellChange(params,"description");
      // },
    },
    {
      field: "value",suppressMenu: true ,
      headerName: t("tableHeaders.value"),
      editable: true,
      onCellValueChanged: (params: any) => {
        addOrUpdateCustomsOnCellChange(params,"value");
      },
    },
    // {
    //   field: "options",
    //   headerName: t("tableHeaders.options"),
    //   cellRenderer: (params: any) => (
    //     <Button
    //       type="text"
    //       icon={<EditOutlined />}
    //       onClick={() =>{
    //         params.api.startEditingCell({
    //         rowIndex: params.rowIndex,
    //         colKey: "description",
    //       });}}
    //       style={{
    //         color: "#007FFF",
    //       }}
    //     ></Button>
    //   ),
    //   width: 100,
    // },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['value']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    getCustoms();
  }, []);

  const handleAddCustom = () => {
    if(customs.find((custom)=>custom.customId ===0)){
      return;
    }
    const newCustom = {
      customId: 0,
      description: "",
      value: 0,
    };
    setCustoms([...customs, newCustom]);
    setTimeout(() => {
      setEditingCell(gridRef, "description");
    }, 0);
  };
  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  const mutableObject = _.cloneDeep(customs);
  return ( 
    <>
    <ListHeaderComponent
   title={t("titles.customs")}
   createRecord={handleAddCustom}
   createTitle={t("create")}
   menuItemsDisabled={false}
   createAccessEnum={MenuOptionEnum.CustomsCreate}
 />
 
      <AgGridTableComponent
        gridRef={gridRef}
        isLoading={isLoading}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        rowData={mutableObject}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        suppressCellFocus={true}
        stopEditingWhenCellsLoseFocus={true}
        enableCellTextSelection={true}
        // onCellEditingStopped={(params: any) => {
        //   const data = params.data;
        //   if (
        //     data?.customId === 0 &&
        //     data?.description?.trim() === "" &&
        //     data?.value === null
        //   ) {
        //     setCustoms((prevValues) =>
        //       prevValues.filter((custom) => custom.customId !== 0)
        //     );
        //   } else if (data?.customId !== 0 && data?.description?.trim() === "") {
        //     setCustoms((prevValues) => {
        //       const customs = [...prevValues];
        //       const custom = customs.find(
        //         (custom) => custom.customId === data.customId
        //       );
        //       if (custom) {
        //         custom.description = data.description;
        //         custom.value = data.value;
        //       }
        //       return customs;
        //     });
        //   }
        // }}
        onCellKeyDown={(event: CellKeyDownEvent) =>
          handleNextGridColumnClick(event)
        }
      />
    </>
  );
};

export default Customs;
