import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import FormInput from "../../../CustomComponents/FormInput";
import { AppDispatch, RootState } from "../../../../redux";
import FormSelect from "../../../CustomComponents/FormSelect";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import { CreateItemDto } from "../../../../models/clientDashboard/Item/CreateItemDto";
import { ItemModel } from "../../../../models/clientDashboard/Item/ItemModel";
import { ClassifierDetail } from "../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import { fetchClassifiers, getClassifiersByTypeAsync } from "../../../../redux/slices/classifierSlice";
import apiService from "../../../../extensions/api";
import { toast } from "react-toastify";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";

interface Props {
  item: ItemModel;
  setFieldValue: (name: string, value: any) => void;
  mode:string;
}
export default function ItemClassifiersComponent({ item, setFieldValue,mode }: Props) {
  const t = useAppTranslation("ClientDashboard.CreateItem.ItemClassifiersComponent");
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const dispatch = useDispatch<AppDispatch>();
  const filteredClassifiers = classifiers.filter(
    (classifier) =>
      classifier.description !== "K1" &&
      classifier.description !== "K2" &&
      classifier.description !== "K3" &&
      classifier.description !== "K4" &&
      classifier.status === true
  );

  function getNestedValue(obj: any, path: any) {
    return path.split('.').reduce((acc: any, part: any) => acc && acc[part], obj);
  };

  const groupSize = Math.ceil(filteredClassifiers.length / 3);
  const groups = [
    filteredClassifiers.slice(0, groupSize),
    filteredClassifiers.slice(groupSize, groupSize * 2),
    filteredClassifiers.slice(groupSize * 2)
  ];

  const getClassifierValueByNameForSelect = (name: string, item: any) => {
    const categoryValue = item[name];
    if (typeof categoryValue === 'number') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue?.classifierDetailId || undefined;
    } else {
      return undefined;
    }
  };
  const getClassifierValueByNameForInput = (name: string, item: any) => {
    const categoryValue = item[name];
    if (typeof categoryValue === 'number' || typeof categoryValue === 'string') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue?.classifierDetailDescription || undefined;
    } else {
      return undefined;
    }
  };

  const getClassifierDescriptionValue = (name: string, item: any) => {
    const descriptionKeys = [
        "k5Description",
        "k6Description",
        "k7Description",
        "k8Description",
        "k9Description",
        "k10Description",
        "k11Description",
        "k12Description",
        "k13Description",
        "k14Description",
        "k15Description",
        "k16Description",
        "k17Description",
        "k18Description",
        "k19Description",
        "k20Description",
        "k21Description",
        "k22Description",
        "k23Description",
        "k24Description",
        "k25Description"
    ];

    if (descriptionKeys.includes(name)) {
        return item?.[name] || "";
    }
    if (item[name] && typeof item[name] === 'object') {
      return item[name]?.classifierDetailDescription || "";
    }
    return "";
};

  const renderGroup = (group: Classifier[]) => {
    const inputComponents = group
      .filter(
        (classifier) =>
          classifier.description !== "K1" &&
          classifier.description !== "K2" &&
          classifier.description !== "K3" &&
          classifier.description !== "K4" &&
          classifier.status === true
      )
      .map((classifier) => {
        switch (classifier.classifierTypeDescription.toLowerCase()) {
          case "text":
            const textValue = getClassifierValueByNameForInput(classifier.description.toLowerCase(), item);

            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage"),
                  },
                ]}
                value={textValue}
              />
            );
          case "select":
            const value = getClassifierValueByNameForSelect(classifier?.description?.toLowerCase(), item)
            const label = getClassifierDescriptionValue(`${classifier?.description?.toLowerCase()}Description`, item);
            return (
              <FormSelectWithSearch
                key={classifier.description}
                name={classifier?.description?.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                required={classifier.mandatory}
                value={value}
                disabled={item.validated}
                fetchOptions={(searchText: string) => fetchClassifiers(searchText, classifier?.classifierId ?? "")}
                fetchInitialOption={() => {
                  if(mode === "update"){
                    return {
                      key: item[classifier?.description?.toLowerCase()]?.classifierDetailId,
                    label: item[classifier?.description?.toLowerCase()]?.classifierDetailDescription,
                    value: item[classifier?.description?.toLowerCase()]?.classifierDetailId,
                    }
                  }
                  else{
                  return {
                    key: item[classifier?.description?.toLowerCase()],
                    label: label,
                    value: item[classifier?.description?.toLowerCase()],
                  };
                }
                }}
                placeHolder={t("placeHolder")}
                onChange={(option) => {
                  setFieldValue(classifier?.description?.toLowerCase(),option.value);
                  setFieldValue(`${classifier?.description?.toLowerCase()}Description`, option.label);
                }}
              />
            );
          case "number":
          case "decimal number":
            const numberValue = getClassifierValueByNameForInput(classifier.description.toLowerCase(), item);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage")
                  },
                  {
                    pattern: {
                      value: new RegExp(classifier.regex ?? ""),
                      message: t("numberPatternMessage"),
                    },
                  },
                ]}
                type="number"
                value={numberValue}
              />
            );
          case "email":
            const emailValue = getClassifierValueByNameForInput(classifier.description.toLowerCase(), item);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage"),
                  },
                  {
                    pattern: {
                      value: new RegExp(classifier.regex ?? ""),
                      message: t("emailPatternMessage"),
                    },
                  },
                ]}
                value={emailValue}
                type="email"
              />
            );
          default:
            return null;
        }
      });

    return inputComponents;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={8}>{renderGroup(groups[0])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[1])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[2])}</Col>
    </Row>
  );
}
