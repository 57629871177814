
import {
  RightOutlined,
  FilterOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { GetSaleOrderDto } from "../../../../models/clientDashboard/EntriesModule/SaleOrder/GetSaleOrderDto";
import { formatDate } from "../../../../helperMethods/DateFormat";
import { db } from "../../../../indexDB/clientSideDatabase";
import { FilterData } from "../../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../../customHooks/useFilter";
import { exportDataToCsv } from "../../../../models/helper/exportDataToCsv";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import { renderFilterInputs } from "../../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../../CustomComponents/FilterModal";
import { getSaleOrdersColumnsAsync, getSaleOrdersWithFilters } from "../../../../redux/slices/saleOrderSlice";
import WithPermission from "../../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import { usePermission } from "../../../../customHooks/usePermission";


const { Title, Text, Link } = Typography;
export default function SaleOrder() {
  const saleOrders = useSelector(
    (state: RootState) => state.saleOrder.saleOrders
  );
  const t = useAppTranslation("ClientDashboard.SaleOrder");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef<GetSaleOrderDto>[]>([
    {
      field: "saleOrderId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.saleOrderId}</Text>;
      },
    },

    { field: "registrationDate", headerName: t("tableHeaders.registrationDate"), suppressMenu: true, width: 210 },
    { field: "subjectDescription", headerName: t("tableHeaders.subjectName"), suppressMenu: true, width: 250 },
    { field: "branchSubjectId", headerName: t("tableHeaders.branchSubjectId"), suppressMenu: true },
    { field: "comment", headerName: t("tableHeaders.comment"), suppressMenu: true },
    { field: "validationDate", headerName: t("tableHeaders.validationDate"), valueFormatter: formatDate, suppressMenu: true },
    { field: "validated", headerName: t("tableHeaders.validated"), suppressMenu: true },
    { field: "saleId", headerName: t("tableHeaders.saleId"), suppressMenu: true },
    { field: "exchangeRate", headerName: t("tableHeaders.exchangeRate"), suppressMenu: true },
    { field: "currencyId", headerName: t("tableHeaders.currencyId"), suppressMenu: true },
    { field: "currencyName", headerName: t("tableHeaders.currencyName"), suppressMenu: true, maxWidth: 120 },
    { field: "validatedBy", headerName: t("tableHeaders.validatedBy"), suppressMenu: true },


    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.SaleOrderCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateSaleOrder"),
                  `saleOrder/update/${params?.data?.saleOrderId}`,
                  undefined
                );
                navigate(
                  `/saleOrder/update/${params?.data?.saleOrderId}/${params.data.branchId}`
                );
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 100,
      suppressMenu: true,
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["registrationDate"]);
    params.columnApi.autoSizeColumns(["subjectName"]);
    params.columnApi.autoSizeColumns(["branchSubjectId"]);
    params.columnApi.autoSizeColumns(["comment"]);
    params.columnApi.autoSizeColumns(["validationDate"]);
    params.columnApi.autoSizeColumns(["saleId"]);
    params.columnApi.autoSizeColumns(["exchangeRate"]);
    params.columnApi.autoSizeColumns(["currencyId"]);
    params.columnApi.autoSizeColumns(["currencyName"]);
    params.columnApi.autoSizeColumns(["validatedBy"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);
  const getSaleOrders = async () => {
    const result = await dispatch(getSaleOrdersWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    getSaleOrders();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.SaleOrderCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(t("tabs.updateSaleOrder"), `saleOrder/update/${event?.data?.saleOrderId}/${event.data.branchId}`, undefined)
      navigate(`/saleOrder/update/${event?.data?.saleOrderId}/${event.data.branchId}`)
    };
  };

  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;
    const result = await dispatch(getSaleOrdersWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getSaleOrdersColumnsAsync());
    if (
      result.type === "SaleOrder/getSaleOrderColumnsAsync/fulfilled"
    ) {
      return result;
    } else {
      return null;
    }
  };
  const handleClearFilters = async () => {
    const result = await dispatch(getSaleOrdersWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewSaleOrders = async () => {
    navigate("/saleOrder/register");
    const existingTab = await db.tabs.get("saleOrder/register");
    if (!existingTab) {
      db.addTab(
        t("tabs.createSaleOrder"),
        "saleOrder/register",
        undefined
      );
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "entryItemsId",
      "branch",
      "supplier",
      "documentType",
      "documentNo",
      "customDocNo",
      "validated",
      "date",
      "entryDate",
      "invoiceDate",
      "lastUpdatedDate",
      "supplierInvoiceNo",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.saleOrder")}
        createRecord={handleCreateNewSaleOrders}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.SaleOrderCreate}
        exportAccessEnum={MenuOptionEnum.SaleOrderExport}
      />
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState &&
          filterState?.filterInputs?.map((input) => (
            <div
              key={input.name}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Text>
                {input.isClassifier
                  ? input.name
                  : t(`FilterColumns.${input.name}`)}
              </Text>
              {renderFilterInputs({ input: input, filterState: filterState })}
            </div>
          ))}
      </div>
      {filterState && filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button onClick={filterState?.handleClearFilters}>
            {t("clearFilters")}
          </Button>
          <Button type="primary" onClick={filterState?.handleFilterSubmit}>
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={saleOrders}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.SaleOrder"
      />
    </>
  );
}
