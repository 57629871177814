import { CellKeyDownEvent } from "ag-grid-community";
import { InputRef } from "antd";
import { RefObject } from "react";

export function handleNextGridColumnClick(
  event: CellKeyDownEvent,
  searchItemInputRef?: RefObject<InputRef>
) {
  const keyboardEvent = event.event as KeyboardEvent;
  if (
    keyboardEvent.code === "Tab" ||
    keyboardEvent.code === "Enter" ||
    keyboardEvent.code === "NumpadEnter"
  ) {
    keyboardEvent.preventDefault();
    const currentCell = event.column;
    if (currentCell) {
      const currentRowIndex = event.rowIndex;
      const currentColumnId = currentCell.getColId();

      const columnIds = event.columnApi
        .getAllDisplayedColumns()
        .map((column) => column.getColId());



      const editableColumnIds = columnIds.filter((columnId) => {
        const column = event.columnApi.getColumn(columnId);
        return column && column?.getColDef()?.editable;
      });

      const isLastEditableColumn =
      editableColumnIds[editableColumnIds.length - 1] === currentColumnId;


      if (searchItemInputRef) {
        if (isLastEditableColumn && searchItemInputRef.current) {
          searchItemInputRef.current.focus();
          return;
        }
      }


      let nextRowIndex = currentRowIndex ?? 0;
      let nextColumnId = currentColumnId;

      let foundEditableCell = false;
      for (let i = 0; i < columnIds.length; i++) {
        const columnId = columnIds[i];
        const column = event.columnApi.getColumn(columnId);
        if (column && column?.getColDef()?.editable) {
          if (columnId === currentColumnId) {
            foundEditableCell = true;
          } else if (foundEditableCell) {
            nextColumnId = columnId;
            break;
          }
        }
      }

      if (nextColumnId === currentColumnId) {
        nextRowIndex++;
        nextColumnId =
          columnIds.find((columnId) => {
            const column = event.columnApi.getColumn(columnId);
            return column && column?.getColDef()?.editable;
          }) || currentColumnId;

        if (!nextColumnId || nextRowIndex >= event.api.getDisplayedRowCount()) {
          return;
        }
      }

      event.api.startEditingCell({
        rowIndex: nextRowIndex,
        colKey: nextColumnId,
      });

      event.api.ensureIndexVisible(nextRowIndex);
    }
  }
}