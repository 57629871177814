import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import { useEffect, useRef } from "react";
import useFormData from "../../../../customHooks/useFormData";
import { CreateAndEditAgentsDto } from "../../../../models/Users/agents/CreateAndEditAgentsDto";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import {
  getUserBranches,
  getUserBySearch,
} from "../../../../redux/slices/userSlice";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import apiService from "../../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getAgentsSuccess } from "../../../../redux/slices/agentsSlice";
import * as Yup from "yup";
import { Col, Form, Row, Spin } from "antd";
import { Formik } from "formik";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import FormSelect from "../../../CustomComponents/FormSelect";
import FormInput from "../../../CustomComponents/FormInput";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import { Agents } from "../../../../models/Users/agents/Agents";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";
import { User } from "../../../../models/clientDashboard/Users/User";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";

const CreateAgents = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const formikRef = useRef<any>(null);
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const user = useSelector((state: RootState) => state.user.loggedInUser);

  const t = useAppTranslation("ClientDashboard.Agents");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const fetchUsers = async (searchText: string) => {
    const result = await dispatch(getUserBySearch(searchText));
    if (result.payload !== "An error occurred") {
      const payload = result.payload as [];
      const options = payload.map((user: User) => ({
        key: user.usersID.toString(),
        label: user.firstName + " " + user.lastName,
        value: user.usersID,
      }));
      return options;
    }
    return [];
  };

  useEffect(() => {
    dispatch(getUserBranches());
  }, [dispatch]);
  const onFinish = async (values: CreateAndEditAgentsDto) => {
    dispatch(setLoadingState(true));

    await apiService
      .post("/api/client/Agents/create", values)
      .then((response) => {
        toast.success(t("agentsCreatedSuccessfully"));
        db.deleteTab("agents/register");
        dispatch(getAgentsSuccess([]));
        navigate("/agents");
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required(t("fullNameRequired")),
  });

  const handleSubmitValidationForm = async (
    setTouched: ({}: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = (await validateForm()) || {};
    setTouched({
      agentId: true,
      fullName: true,
      agentCode: true,
      status: true,
      email: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };

  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        // initialValues={initialValues ?? {
        //     status: true,
        //     branchId: user?.isMainBranch === false ? Number(user.branchId) : (userBranches.length ===1?userBranches[0].branchId:undefined),
        //  } as CreateAndEditAgentsDto}
        initialValues={
          {
            status: true,
          } as CreateAndEditAgentsDto
        }
        onSubmit={onFinish}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          handleSubmit,
          submitForm,
          setTouched,
          validateForm,
          setFieldValue,
        }) => (
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            style={{ marginTop: 20 }}
          >
            <FormHeaderOptions
              title={t("formTitle")}
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createAgentsButton")}
              submitButtonIsDisabled={isLoading}
              createAccessEnum={MenuOptionEnum.AgentCreate}
            />

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                {/* <FormSelect
                                    name="agentId"
                    
                                    label={t("agentIdInputLabel")}
                                    required={true}
                                    value={values.agentId}
                                    options={
                                        userBranches?.map((userBarnch) => {
                                            return {
                                                key: userBarnch.branchId,
                                                label: userBarnch.description,
                                                value: userBarnch.branchId,
                                            };
                                        }) ?? []
                                    }
                                    disabled={!user?.isMainBranch }
                                /> */}
                <FormInput
                  name="fullName"
                  type="string"
                  label={t("fullNameInputLabel")}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: t("fullNameInputPlaceholder"),
                    },
                  ]}
                  value={values.FullName}
                />
                <FormInput
                  name="agentCode"
                  label={t("agentCodeInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("agentCodeInputPlaceholder"),
                    },
                  ]}
                  value={values.AgentCode}
                />
                <FormSelectWithSearch
                  name="operatori"
                  label={t("operatoriInputLabel")}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: t("operatoriPlaceholder"),
                    },
                  ]}
                  value={values.userId}
                  fetchOptions={fetchUsers}
                  placeHolder={t("placeHolder")}
                  onChange={(option) => {
                    setFieldValue("userId", option.value);
                    setFieldValue("userDescription", option.label);

                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <FormInput
                  name="email"
                  label={t("emailInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("emailInputPlaceholder"),
                    },
                  ]}
                  value={values.email}
                />
                <FormSwitch
                  className="switch-for-agents"
                  name="status"
                  label={t("statusInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("statusInputPlaceholder"),
                    },
                  ]}
                  value={values.status}
                />
              </Col>
            </Row>
            <HandleFormDataForTabSaving tabPath="agents/register" />
          </Form>
        )}
      </Formik>
    </Spin>
  );
};

export default CreateAgents;
