import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Layout, Menu, Modal, Popconfirm, Space, Switch } from "antd";
import Logo from "../../assets/kubit-lite-logo.png";
import packageJson from "../../../package.json";
import {
  LeftOutlined,
  RightOutlined,
  LogoutOutlined,
  DownOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Tooltip, Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutAsync,
  setUserToNullAndRemoveToken,
} from "../../redux/slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeTheme,
  setDrawerOpen,
  setLanguage,
  setTheme,
} from "../../redux/slices/appearanceSlice";
import ClientNavigationMenu from "./ClientNavigationMenu";
import { db } from "../../indexDB/clientSideDatabase";
import "./ClientLayoutComponent.scss";
import { AppDispatch, RootState } from "../../redux";
import { tabTable } from "../../indexDB/databaseTables/tabTable";
import { liveQuery } from "dexie";
import useHorizontalScroll from "../../customHooks/useHorizontalScroll";
import { useTranslation } from "react-i18next";
import useAppTranslation from "../../customHooks/useAppTranslation";
import DeleteTabIcon from "../../assets/icons/DeleteTabIcon";
import { toast } from "react-toastify";
import TrashIcon from "../../assets/icons/TrashIcon";
import UserIcon from "../../assets/icons/UserIcon";
import DownIcon from "../../assets/icons/DownIcon";
import NewTrashIcon from "../../assets/icons/NewTrashIcon";
import CloseTabIcon from "../../assets/icons/CloseTabIcon";

const { Header, Content } = Layout;
const { Text, Title } = Typography;
interface Props {
  children: React.ReactNode;
}
const ClientLayoutComponent = ({ children }: Props) => {
  const drawerVisible = useSelector(
    (state: RootState) => state.appearance.drawerOpen
  );
  const [tabs, setTabs] = useState<tabTable[]>([]);
  const location = useLocation();
  const tabsRef = useHorizontalScroll();
  const activeTabRef = useRef<HTMLDivElement | null>(null);
  const { i18n } = useTranslation();
  const t = useAppTranslation("ClientLayoutComponent.Nav");
  const isDarkTheme = useSelector((state: RootState) => state.appearance.theme);
  const [selectedLang, setSelectedLang] = useState("en");

  useEffect(() => {
    const storedLanguage = localStorage.getItem("langCode") || "al";
    if (i18n.language !== storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [location, i18n]);

  const changeLanguage = (langCode: string) => {
    localStorage.setItem("langCode", langCode);
    i18n.changeLanguage(langCode);
  };

  const closeAllTabs = async () => {
    try {
      const allTabs = await db.tabs.toArray();

      await Promise.all(allTabs?.map((tab) => db.deleteTab(tab.tabPath)));

      navigate("/");
    } catch (error) {
      console.error("Error closing all tabs:", error);
    }
  };
  useEffect(() => {
    const allTabs = liveQuery(() => db.tabs.orderBy("createdAt").toArray());
    const subscription = allTabs.subscribe({
      next: (result) => {
        setTabs(result as tabTable[]);
      },
      error: (error) => console.error(error),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (activeTabRef.current && tabsRef.current) {
      const parent = tabsRef.current;
      const activeTab = activeTabRef.current;
      const activeTabRect = activeTab.getBoundingClientRect();
      const parentRect = parent.getBoundingClientRect();

      if (
        activeTabRect.left < parentRect.left ||
        activeTabRect.right > parentRect.right
      ) {
        parent.scrollLeft +=
          activeTabRect.left -
          parentRect.left -
          parentRect.width / 2 +
          activeTabRect.width / 2;
      }
    }
  }, [location.pathname, tabs]);

  const closeTab = async (tab: tabTable) => {
    const removedTabIndex = tabs.indexOf(tab);

    if (removedTabIndex !== -1) {
      if (location.pathname === `/${tab.tabPath}`) {
        const nextTabIndex =
          removedTabIndex < tabs.length - 1
            ? removedTabIndex + 1
            : removedTabIndex - 1;
        const nextTab = tabs[nextTabIndex];
        await db.tabs.put({ ...tab, shouldRemoveTab: true });
        await db.deleteTab(tab.tabPath);

        if (nextTab) {
          navigate(`/${nextTab.tabPath}`);
        } else {
          const prevTabIndex =
            removedTabIndex > 0 ? removedTabIndex - 1 : removedTabIndex + 1;
          const prevTab = tabs[prevTabIndex];

          if (prevTab) {
            navigate(`/${prevTab.tabPath}`);
          } else {
            navigate(`/`);
          }
        }
      } else {
        await db.deleteTab(tab.tabPath);
      }
    }
  };
  const handleRemoveTab = async (tab: tabTable) => {
    if (tab.isTouched === true) {
      Modal.confirm({
        className: "custom-tab-confirm",
        title: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TrashIcon />
            {t("confirmRemoveTabTitle")}
          </div>
        ),
        onOk: async () => {
          closeTab(tab);
        },
        onCancel: () => {},
        okText: t("yesT"),
        cancelText: t("noT"),
      });
    } else {
      closeTab(tab);
    }
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    dispatch(setDrawerOpen(!drawerVisible));
  };
  const logout = () => {
    Modal.confirm({
      title: t("logout"),
      onOk() {
        dispatch(logoutAsync());
        db.clearAllTables();
        navigate("/login");
      },
    });
  };

  const handleThemeChange = (checked: boolean) => {
    dispatch(changeTheme(checked ? "dark" : "light"));

    // window.location.reload();
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Space>
          <Switch
            checkedChildren="Dark"
            unCheckedChildren="Light"
            checked={isDarkTheme === "dark"}
            onChange={handleThemeChange}
          />
        </Space>
      </Menu.Item>
      <Menu.SubMenu title={t("language")} key="sub1">
        <Menu.Item key="2" onClick={() => handleLanguageChange("en")}>
          {t("english")}
        </Menu.Item>
        <Menu.Item key="3" onClick={() => handleLanguageChange("al")}>
          {t("albanian")}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Divider />
      <Menu.Item key="4" onClick={logout}>
        {t("Logout")}
      </Menu.Item>
    </Menu>
  );

  const handleLanguageChange = (value: string) => {
    dispatch(setLanguage(value));
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={!drawerVisible}
        width={300}
        theme="light"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="logo"
            style={{
              padding: 15,
              width: "70%",
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              width="100%"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
          </div>
          <div>
            <Tooltip title={drawerVisible ? t("Mbyll") : t("Hap")}>
              <Button
                type="dashed"
                shape="default"
                size="small"
                icon={drawerVisible ? <LeftOutlined /> : <RightOutlined />}
                onClick={toggleDrawer}
              />
            </Tooltip>
          </div>
        </div>
        <ClientNavigationMenu
          version={packageJson.version}
          drawerVisible={drawerVisible}
        />
      </Layout.Sider>
      <Layout>
        <Header
          style={{
            background: "#fff",
            // padding: "5px 16px",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "0px",
          }}
        >
          <div
            style={{
              width: "85%",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <div className="tabs" ref={tabsRef}>
              {tabs?.map((tab, index) => {
                const isSelected = location.pathname === `/${tab.tabPath}`;

                const handleRemoveClick = (e: any) => {
                  e.stopPropagation();
                  if (tab.data !== null && tab.data !== undefined) {
                    handleRemoveTab(tab);
                  } else {
                    handleRemoveTab(tab);
                  }
                };

                // const showPopconfirm = (onConfirm:any) => {
                //   Modal.confirm({
                //     title: 'Are you sure you want to remove this tab?',
                //     content: 'Data will be lost if the tab is removed.',
                //     okText: 'Yes',
                //     okType: 'danger',
                //     cancelText: 'No',
                //     onOk: onConfirm, showPopconfirm(() =>
                //   });
                // };

                return (
                  <div
                    ref={isSelected ? activeTabRef : null}
                    key={index}
                    className={`tabWrapper tabContainer ${
                      location.pathname === `/${tab.tabPath}`
                        ? "selectedTabContainer"
                        : ""
                    }`}
                    onClick={() => navigate(`/${tab.tabPath}`)}
                  >
                    <Text
                      type="secondary"
                      className={`tabTitle ${
                        location.pathname === `/${tab.tabPath}`
                          ? "selectedTabText"
                          : ""
                      }`}
                    >
                      {tab.tabName}
                    </Text>
                    <Button
                      type="link"
                      danger
                      className="closeTab"
                      size="small"
                      onClick={handleRemoveClick}
                    >
                      {/* <DeleteTabIcon /> */}
                      {/* <span >x</span> */}
                      <CloseTabIcon
                        color={
                          location.pathname === `/${tab.tabPath}`
                            ? undefined
                            : "#D4D4D4"
                        }
                      />
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Popconfirm
              placement="top"
              title={t("closeTab")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={closeAllTabs}
            >
              <Button
                type="default"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  border: "1px solid #d9d9d9",
                  height: "40px",
                  minWidth: "146px",
                  opacity: 1,
                  lineHeight: "40px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <NewTrashIcon />
                  {t("closeTabs")}
                </span>
              </Button>
            </Popconfirm>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                type="link"
                className="ant-dropdown-link custom-settings-user"
              >
                <span className="icon-wrapper">
                  <span className="user-icon-wrapper">
                    <UserIcon />
                  </span>
                  <span className="down-icon-wrapper">
                    <DownIcon />
                  </span>
                </span>
              </Button>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: "2px 2px 0",
            minHeight: "calc(100vh - 64px)",
            backgroundColor: "white",
          }}
        >
          {" "}
          <div style={{ padding: 30 }}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ClientLayoutComponent;
