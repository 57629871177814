import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CreateOrUpdateSaleDetailsDto } from "../../../../models/clientDashboard/Sales/SalesInvoiceDetail/CreateOrUpdateSaleDetailsDto";
import { v4 as uuidv4 } from "uuid";
import { CreateOrUpdateSaleDto } from "../../../../models/clientDashboard/Sales/SalesInvoice/CreateOrUpdateSalesDto";
import useFormData from "../../../../customHooks/useFormData";
import moment from "moment";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import apiService from "../../../../extensions/api";
import { db } from "../../../../indexDB/clientSideDatabase";
import {
  correctSaleAsync,
  getSaleDataById,
  handlePrintSalesForm,
  invalidateSaleAsync,
  validateSaleAsync,
} from "../../../../redux/slices/salesSlice";
import { ItemsClassifierDto } from "../../../../models/clientDashboard/Classifier/ItemClassifierDto";
import { convertClassifierValueToObject } from "../../../../helperMethods/convertClassifierValueToObject";
import { getClassifiersByTypeAsync } from "../../../../redux/slices/classifierSlice";
import { getEntitiesByTypeAsync } from "../../../../redux/slices/entitySlice";
import { getCurrenciesAsync } from "../../../../redux/slices/currencySlice";
import { getTaxesAsync } from "../../../../redux/slices/taxSlice";
import { getDocumentTypesWithSignHAsync } from "../../../../redux/slices/documentTypeSlice";
import { getUserBranches } from "../../../../redux/slices/userSlice";
import { Form, Spin, Tabs } from "antd";
import { Formik } from "formik";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import * as Yup from "yup";
import MainFieldsComponent from "./CreateSaleComponents/MainFieldsComponent";
import OtherFieldsComponent from "./CreateSaleComponents/OtherFieldsComponent";
import SaleClassifiersComponent from "./CreateSaleComponents/SaleClassifiersComponent";
import { getPaymentMethodsAsync } from "../../../../redux/slices/paymentMethodsSlice";
import { CreatePaymentExecutionsDetailsDto } from "../../../../models/clientDashboard/Sales/SalesInvoiceDetail/CreatePaymentExecutionsDetailsDto";
import _ from "lodash";
import { DocumentTypeEnum } from "../../../../enums/DocumentTypeEnum";
import { CopiedDocumentDetailsTable } from "../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { SelectOption } from "../../../../models/SelectOption";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import printBase64PDF from "../../../../helperMethods/printBase64PDF";

export default function CreateSale() {
  const navigate = useNavigate();
  const { mode, id, branchId } = useParams<{
    mode: string;
    id?: string;
    branchId?: string;
  }>();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.CreateSale");
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
  const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
    setSupplierOptions(options);
  };
  const [supplierBranchOptions, setSupplierBranchOptions] = useState<
    SelectOption[]
  >([]);
  const addInitialOptionsOfSupplierBranch = (options: SelectOption[]) => {
    setSupplierBranchOptions(options);
  };

  const formikRef = useRef<any>(null);
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const [saleDetails, setSaleDetails] = useState<
    CreateOrUpdateSaleDetailsDto[]
  >([]);
  const [paymentExecutions, setPaymentExecutions] = useState<
    CreatePaymentExecutionsDetailsDto[]
  >([]);

  const generatedIdForCorrection = uuidv4();
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const setAdditionalStates = useCallback(
    (data: CreateOrUpdateSaleDto | null) => {
      if (data !== null) {
        if (data.details) {
          setSaleDetails(data.details);
        }
        if (data.paymentExecutions) {
          setPaymentExecutions(data.paymentExecutions);
        }
      }
    },
    []
  );

  const getData = async () => {
    if (id && branchId) {
      const response = await dispatch(
        getSaleDataById({ salesId: id, branchID: Number(branchId) })
      );
      const data = response.payload as CreateOrUpdateSaleDto;
      if (response.type === "Sales/getSaleDataById/fulfilled") {
        const sale = response.payload as CreateOrUpdateSaleDto;
        setInitialValues(sale);
        formikRef?.current?.setValues(sale);
        if(sale.subject){
        setSupplierOptions([
          {
            key: sale.subjectId,
            label: sale.subject ?? "",
            value: sale.subjectId ?? 0,
          },
        ]);}
        if(sale.branchSubjectId){
        setSupplierBranchOptions([
          {
            key: sale.branchSubjectId,
            label: sale.branchSubject ?? "",
            value: sale.branchSubjectId ?? 0,
          },
        ]);
        }
        setSaleDetails(sale.details);
        setPaymentExecutions(sale.paymentExecutions);
      }
      return data;
    }
    return {
      generatedId: mode !== "update" ? uuidv4() : "",
      date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      invoiceDate: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      branchId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
          ? userBranches[0].branchId
          : undefined,
      paymentDeadline: 0,
    } as CreateOrUpdateSaleDto;
  };

  const { initialValues, setInitialValues } =
    useFormData<CreateOrUpdateSaleDto>(
      mode === "update" ? `sales/update/${id}/${branchId}` : "sales/register",
      {
        generatedId: mode !== "update" ? uuidv4() : "",
        date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
        invoiceDate: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
        branchId:
          user?.isMainBranch === false
            ? Number(user.branchId)
            : userBranches.length === 1
            ? userBranches[0].branchId
            : undefined,
        paymentDeadline: 0,
      } as CreateOrUpdateSaleDto,
      setAdditionalStates,
      {
        fetchData: getData,
      }
    );
  const removeSaleDetail = (index: number) => {
    setSaleDetails((prevSaleDetails) => {
      const activeSaleDetails = prevSaleDetails.filter(
        (saleDetails) => saleDetails.rowAction !== "D"
      );
      const selectedSaleDetail = activeSaleDetails[index];
      if (
        selectedSaleDetail.saleDetailId === 0 ||
        !selectedSaleDetail.saleDetailId
      ) {
        prevSaleDetails.splice(index, 1);
      } else {
        selectedSaleDetail.rowAction = "D";
      }
      return [...prevSaleDetails];
    });
  };
  const removePaymentExecutions = (index: number) => {
    setPaymentExecutions((prevPaymentExecutions) => {
      const activePaymentExecutions = prevPaymentExecutions?.filter(
        (paymentExecutions) => paymentExecutions.rowAction !== "D"
      );
      const selectedPaymentExecutions = activePaymentExecutions[index];
      if (
        selectedPaymentExecutions.paymentExecutionId === 0 ||
        !selectedPaymentExecutions.paymentExecutionId
      ) {
        prevPaymentExecutions?.splice(index, 1);
      } else {
        selectedPaymentExecutions.rowAction = "D";
      }
      return [...prevPaymentExecutions];
    });
  };
  const addPaymentExecutions = (paymentExecutions: any) => {
    setPaymentExecutions((prevPaymentExecutions) => {
      return [...prevPaymentExecutions, paymentExecutions];
    });
  };
  const addSaleDetail = (saleDetail: CreateOrUpdateSaleDetailsDto) => {
    setSaleDetails((prevSaleDetails) => {
      return [...prevSaleDetails, saleDetail];
    });
  };

  const validationSchema = Yup.object({
    branchId: Yup.number().required(t("requiredBranch")),
    documentTypeId: Yup.number().required(t("requiredDocumentType")),
    date: Yup.date().required(t("requiredDate")),
    paymentDeadline: Yup.number().required(t("requiredPaymentDeadline")),
    subjectId: Yup.number().required(t("requiredSubjectId")),
    currencyId: Yup.number()
      .nullable()
      .when("documentTypeId", (documentTypeId: any, schema) => {
        if (documentTypeId[0] === DocumentTypeEnum.goodsExport) {
          return schema.required(t("currencyIdIsRequired"));
        }
        return schema;
      }),
    exchangeRate: Yup.number()
      .nullable()
      .when("documentTypeId", (documentTypeId: any, schema) => {
        if (documentTypeId[0] === DocumentTypeEnum.goodsExport) {
          return schema.required(t("exchangeRateIsRequired"));
        }
        return schema;
      }),
    customNoX3: Yup.number()
      .nullable()
      .when("documentTypeId", (documentTypeId: any, schema) => {
        if (documentTypeId[0] === DocumentTypeEnum.goodsExport) {
          return schema.required(t("customNoX3IsRequired"));
        }
        return schema;
      }),
  });

  const calculateTotalPaymentAmount = () => {
    return paymentExecutions
      .filter((payment) => payment.rowAction !== "D")
      .reduce((total, payment) => {
        return total + (payment.amount || 0);
      }, 0);
  };

  const calculateTotalSalesAmount = () => {
    return saleDetails
      .filter((payment) => payment.rowAction !== "D")
      .reduce((total, saleDetail) => {
        return (
          total +
          (saleDetail.quantity *
            saleDetail.salesPrice *
            (1.0 - saleDetail.discount / 100.0) *
            (1.0 - saleDetail.extraDiscount / 100.0) || 0)
        );
      }, 0);
  };

  const convertCategoryValue = (
    values: CreateOrUpdateSaleDto,
    categoryKey: keyof CreateOrUpdateSaleDto,
    classifierDescription: string
  ) => {
    const classifier = classifiers.find(
      (c) => c.description === classifierDescription
    );

    if (!classifier) return;

    const classifierId = classifier.classifierId;
    const detail = classifier.classifierDetails?.find(
      (cd) => cd?.classifierDetailId === values[categoryKey]
    );
    const categoryDescription =
      detail?.description ?? (values[categoryKey] as string);
    const categoryDetailId = detail?.classifierDetailId;

    if (
      typeof values[categoryKey] === "object" &&
      !Array.isArray(values[categoryKey])
    ) {
      const categoryObject = values[categoryKey] as ItemsClassifierDto;
      if (categoryObject?.classifierDetailId) {
        categoryObject.classifierDetailDescription =
          categoryDescription?.toString();
        categoryObject.classifierId = classifierId;
        categoryObject.classifierDetailId = categoryDetailId;
      } else {
        values[categoryKey] = null;
      }
    } else {
      if (categoryDetailId) {
        values[categoryKey] = convertClassifierValueToObject(
          classifierId,
          categoryDescription?.toString(),
          categoryDetailId
        ) as ItemsClassifierDto;
      } else {
        values[categoryKey] = null;
      }
    }
  };

  const onFinish = async (values: CreateOrUpdateSaleDto) => {
    dispatch(setLoadingState(true));

    const totalPaymentAmount = calculateTotalPaymentAmount();
    const totalSalesAmount = calculateTotalSalesAmount();

    if (totalPaymentAmount !== totalSalesAmount) {
      toast.error(t("totalAmountsDoNotMatch"));
      dispatch(setLoadingState(false));
      return;
    }

    if (saleDetails.length === 0) {
      toast.error(t("atLeastOneDetailIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }
    if (user?.isMainBranch && !values.branchId) {
      toast.error(t("branchIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }
    const formattedPaymentExecutions = paymentExecutions
      .filter(
        (paymentExecution) =>
          (paymentExecution.amount !== null &&
            paymentExecution.amount !== undefined &&
            paymentExecution.amount !== 0) ||
          paymentExecution.rowAction === "D"
      )
      .map((paymentExecution) => ({
        ...paymentExecution,
        amount: paymentExecution.amount ?? 0,
      }));

    values.paymentExecutions = formattedPaymentExecutions;
    values.details = saleDetails;

    const classifierKeys = ["k60", "k61", "k62", "k63", "k64", "k65"];

    const convertClassifiersToIntegers = (
      obj: any,
      classifierKeys: string[]
    ) => {
      Object.keys(obj).forEach((key) => {
        if (classifierKeys.includes(key) && !isNaN(obj[key])) {
          obj[key] = parseInt(obj[key], 10);
        }
      });
    };

    if (values) {
      convertClassifiersToIntegers(values, classifierKeys);
    }

    if (values.details && Array.isArray(values.details)) {
      values.details.forEach((detail) => {
        convertClassifiersToIntegers(detail, classifierKeys);
      });
    }

    if (mode === "update" && id) {
      values.saleIdAsString = values?.saleId?.toString();
      values.registeredById = 0;
      values.registeredBy = 0;
      const result = await apiService
        .put(`/api/Sales/update`, values)
        .then(async (response) => {
          if (response.status === 200) {
            toast.success(t("updatedSuccessfully"));
            getData();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    } else {
      await apiService
        .post("/api/Sales/create", values)
        .then(async (response) => {
          toast.success(t("createdSuccessfully"));
          formikRef.current.setFieldValue("saleId", response.data.Data);
          formikRef.current.setFieldValue("saleIdAsString", response.data.Data);

          const createdId = response.data.Data;

          await db.updateTab(
            "sales/register",
            `sales/update/${createdId}/${values.branchId}`,
            t("tabs.updateSale")
          );

          navigate(`/sales/update/${createdId}/${values.branchId}`);
        })
        .catch(() => {})
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    }
    dispatch(setLoadingState(false));
  };

  useEffect(() => {
    dispatch(getClassifiersByTypeAsync("Sales"));
    dispatch(getEntitiesByTypeAsync(false));
    dispatch(getCurrenciesAsync());
    dispatch(getTaxesAsync());
    dispatch(getDocumentTypesWithSignHAsync("SH"));
    dispatch(getUserBranches());
    dispatch(getPaymentMethodsAsync());
  }, [dispatch, mode, id]);
  const [tab, setTab] = useState<any>(null);

  useEffect(() => {
    const updateIndexedDB = async () => {
      setTab(await db.tabs.get(`sales/update/${id}`));
      const tab = await db.tabs.get(
        mode === "update" ? `sales/update/${id}/${branchId}` : "sales/register"
      );
      if (tab) {
        await db.tabs.put({
          ...tab,
          data: {
            ...tab.data,
            details: saleDetails,
          },
        });
      }
    };
    updateIndexedDB();
  }, [saleDetails]);

  const handleSubmitValidationForm = async (
    setTouched: ({}: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = await validateForm();
    setTouched({
      saleId: true,
      saleIdAsString: true,
      branchId: true,
      branchName: true,
      date: true,
      documentNo: true,
      documentTypeId: true,
      subjectId: true,
      branchSubjectId: true,
      comment: true,
      paymentDeadline: true,
      correctedSaleId: true,
      customNoX3: true,
      invoiceDate: true,
      exchangeRate: true,
      currencyId: true,
      currencyName: true,
      k60: true,
      k61: true,
      k62: true,
      k63: true,
      k64: true,
      validationDate: true,
      validatedBy: true,
      totalDiscount: true,
      discountByUserId: true,
      deliveryDeadline: true,
      fromDate: true,
      toDate: true,
      palleteCount: true,
      generatedId: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };
  const handleSaleValidation = async (saleId: string, branchId: string) => {
    const totalPaymentAmount = calculateTotalPaymentAmount();
    const totalSalesAmount = calculateTotalSalesAmount();

    if (totalPaymentAmount !== totalSalesAmount) {
      toast.error(t("totalAmountsDoNotMatch"));
      dispatch(setLoadingState(false));
      return;
    }
    const result = await dispatch(
      validateSaleAsync({ saleId: saleId, branchID: branchId ?? "" })
    );

    if (result.type === "Sales/validateSale/fulfilled") {
      toast.success(t("validatedSuccessfully"));
      formikRef.current.setFieldValue("validated", true);
    } else {
      toast.error(t("validationFailed"));
    }
    return;
  };
  const handleSaleCorrection = async (
    saleId: string,
    generatedId: string,
    branchId: number
  ) => {
    const result = await dispatch(
      correctSaleAsync({
        saleId: saleId,
        generatedId: generatedId,
        branchID: branchId,
      })
    );
    if (result.type === "Sales/correctSale/fulfilled") {
      const newDocumentNo = result.payload;
      await db.updateTab(
        `sales/update/${saleId}`,
        `sales/update/${newDocumentNo}`,
        t("tabs.updateSale")
      );
      toast.success(t("correctedSuccessfully"));
      navigate(`/sales/update/${newDocumentNo}/${branchId}`);
    } else {
      toast.error(t("correctionFailed"));
    }
  };
  const isCorrectionButtonDisabled = (values: CreateOrUpdateSaleDto) => {
    return isLoading || !values.validated || mode !== "update";
  };

  const handleSaleInvalidation = async (saleId: string, branchId: number) => {
    const result = await dispatch(
      invalidateSaleAsync({ saleId: saleId, branchID: branchId })
    );
    if (result.type === "Sales/invalidateSales/fulfilled") {
      toast.success(t("invalidatedSuccessfully"));
      formikRef.current.setFieldValue("validated", false);
    } else {
      toast.error(t("invalidationFailed"));
    }
  };
  const copyDetails = () => {
    const details: CopiedDocumentDetailsTable<CreateOrUpdateSaleDetailsDto> = {
      id: "saleDetails",
      list: saleDetails,
    };
    db.upsertGenericData(details);
  };

  const isInvalidateButtonDisabled = (values: CreateOrUpdateSaleDto) => {
    return isLoading || !values.validated;
  };

  const handlePrintPdf = async (
    saleId: string,
  ) => {
    const result = await dispatch(
      handlePrintSalesForm(
        saleId
      )
    );
    if (result.type === "Sales/handlePrintForm/fulfilled") {
      printBase64PDF(result.payload as string);
    } else {
      toast.error("error");
    }
  };

  const isHandlePrintDisabled = (
    values: CreateOrUpdateSaleDto
  ) =>{
    return isLoading || !values.validated;
  };

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({} as CreateOrUpdateSaleDto)}
        validationSchema={validationSchema}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          handleSubmit,
          submitForm,
          validateForm,
          setTouched,
          setFieldValue,
        }) => (
          <>
            <FormHeaderOptions
              title={
                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
              }
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createButton")}
              submitButtonIsDisabled={
                isLoading ||
                saleDetails.filter((detail) => detail.rowAction !== "D")
                  .length === 0 ||
                values.validated
              }
              createAccessEnum={MenuOptionEnum.SaleInvoiceCreate}
              validateAccessEnum={MenuOptionEnum.SaleInvoiceValidate}
              validateButtonIsDisabled={
                isLoading || mode !== "update" || values.validated
              }
              validateButtonText={t("validateButton")}
              handleDocumentValidation={async () => {
                if (values.saleId) {
                  await handleSaleValidation(
                    values.saleId.toString(),
                    values.branchId.toString()
                  );
                }
              }}
              invalidateAccessEnum={MenuOptionEnum.SaleInvoiceInvalidate}
              invalidateButtonText={t("invalidateButton")}
              invalidateButtonIsDisabled={isInvalidateButtonDisabled(values)}
              handleDocumentInvalidation={async () => {
                await handleSaleInvalidation(
                  values.saleId ?? "",
                  values.branchId ?? 0
                );
              }}
              correctionAccessEnum={MenuOptionEnum.SaleInvoiceCorrection}
              correctionButtonText={t("correctButton")}
              correctionButtonIsDisabled={isCorrectionButtonDisabled(values)}
              handleDocumentCorrection={async () => {
                if (values.saleId && generatedIdForCorrection) {
                  await handleSaleCorrection(
                    values.saleId.toString(),
                    generatedIdForCorrection,
                    values.branchId
                  );
                }
              }}
              copyButtonIsDisabled={
                isLoading || mode !== "update" || saleDetails.length === 0
              }
              copyButtonText={t("CopyButton")}
              handleCopyDetails={
                mode === "update" ? () => copyDetails() : undefined
              }
              printAccessEnum={MenuOptionEnum.SaleInvoicePrint}
              handlePrintText={t("printButton")}
              handlePrintIsDisabled={isHandlePrintDisabled(values)}
              handlePrintForm={mode === "update" ? async () => {
                if(values.saleId){
                  await handlePrintPdf(
                    values.saleId
                  );
                }
              } : undefined}
            />

            <Form onFinish={handleSubmit} layout="vertical">
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: t("sale"),
                    key: "1",
                    children: (
                      <MainFieldsComponent
                        supplierOptions={supplierOptions}
                        supplierBranchOptions={supplierBranchOptions}
                        values={values}
                        addPaymentExecutions={addPaymentExecutions}
                        saleDetails={saleDetails}
                        addSaleDetail={addSaleDetail}
                        setSaleDetails={setSaleDetails}
                        removeSaleDetail={removeSaleDetail}
                        removePaymentExecutionDetail={removePaymentExecutions}
                        paymentExecutionsDetails={paymentExecutions}
                        mode={mode ?? ""}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                  {
                    label: t("classifiers"),
                    key: "2",
                    children: (
                      <SaleClassifiersComponent
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                  {
                    label: t("other"),
                    key: "3",
                    children: (
                      <OtherFieldsComponent
                        values={values}
                        uploadedFiles={uploadedFiles}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                ]}
              />

              <HandleFormDataForTabSaving
                tabPath={
                  mode === "update"
                    ? `sales/update/${id}/${branchId}`
                    : "sales/register"
                }
                additionalStates={{
                  details: saleDetails,
                }}
                // initialValues={{
                //   ...initialValues,
                //   details: saleDetails,
                // }}
              />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
}
