import { Button, Popconfirm, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { db } from "../../../../indexDB/clientSideDatabase";
import { Agents as AgentsDto } from "../../../../models/Users/agents/Agents";
import {
  getAgentsFilterColumnsAsync,
  getAgentsWithFilters,
} from "../../../../redux/slices/agentsSlice";
import { FilterData } from "../../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../../customHooks/useFilter";
import { exportDataToCsv } from "../../../../models/helper/exportDataToCsv";
import { renderFilterInputs } from "../../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../../CustomComponents/FilterModal";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import WithPermission from "../../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import { usePermission } from "../../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;
export default function Agents() {
  const agents = useSelector((state: RootState) => state.agent.agents);
  const t = useAppTranslation("ClientDashboard.Agents");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef<AgentsDto>[]>([
    {
      field: "agentId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return (
          <Text className="ag-grid-id-column">{params?.data?.agentId}</Text>
        );
      },
    },
    // { field: "agentId", headerName: t("tableHeaders.agentId"), suppressMenu: true},
    {
      field: "fullName",
      headerName: t("tableHeaders.FullName"),
      suppressMenu: true,
    },
    {
      field: "agentCode",
      headerName: t("tableHeaders.agentCode"),
      suppressMenu: true,
    },
    {
      field: "status",
      headerName: t("tableHeaders.status"),
      suppressMenu: true,
    },
    { field: "email", headerName: t("tableHeaders.email"), suppressMenu: true },
    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.AgentCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateAgents"),
                  `Agents/update/${params?.data?.agentId}`,
                  undefined
                );
                navigate(`/Agents/update/${params?.data?.agentId}`);
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 90,
      suppressMenu: true,
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["agentId"]);
    params.columnApi.autoSizeColumns(["fullName"]);
    params.columnApi.autoSizeColumns(["agentCode"]);
    params.columnApi.autoSizeColumns(["status"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);

  const getAgents = async () => {
    await dispatch(getAgentsWithFilters([]));
  };

  useEffect(() => {
    getAgents();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.AgentCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateAgents"),
        `Agents/update/${event?.data?.agentId}`,
        undefined
      );
      navigate(`/Agents/update/${event?.data?.agentId}`);
    };
  };

  const handleCreateNewPrice = async () => {
    navigate("/Agents/register");
    const existingTab = await db.tabs.get("Agents/register");
    if (!existingTab) {
      db.addTab(t("tabs.createAgents"), "Agents/register", undefined);
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "agentId",
      "fullName",
      "agentCode",
      "status",
      "email",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.Agents")}
        createRecord={handleCreateNewPrice}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.AgentCreate}
      />

      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={agents}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
    </>
  );
}
