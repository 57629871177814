import { Col, Form, message, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { useEffect, useRef } from "react";
import useFormData from "../../customHooks/useFormData";
import { PaymentMethodsDto } from "../../models/clientDashboard/PaymentMethods/PaymentMethodsDto";
import useAppTranslation from "../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { setLoadingState } from "../../redux/slices/loadingSlice";
import apiService from "../../extensions/api";
import { toast } from "react-toastify";
import { getPaymentMethodsSuccess } from "../../redux/slices/paymentMethodsSlice";
import { db } from "../../indexDB/clientSideDatabase";
import * as Yup from "yup";
import { Formik } from "formik";
import FormHeaderOptions from "../CustomComponents/FormHeaderOptions";
import FormInput from "../CustomComponents/FormInput";
import HandleFormDataForTabSaving from "../../helperMethods/handleFormDataForTabSaving";
import FormSwitch from "../CustomComponents/FormSwitch";
import FormSelect from "../CustomComponents/FormSelect";
import { getFiscalPayMethodTypesAsync } from "../../redux/slices/fiscalPayMethodSlice";
import { getPaymentMethodTypesAsync } from "../../redux/slices/paymentMethodTypesSlice";
import { MenuOptionEnum } from "../../enums/MenuOptionEnum";

const { Title, Text } = Typography;

const CreatePaymentMethods = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const formikRef = useRef<any>(null);
  const { initialValues } = useFormData<PaymentMethodsDto>(
    "paymentMethod/register",
    {} as PaymentMethodsDto
  );

  const t = useAppTranslation("ClientDashboard.CreatePaymentMethods");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );

  const fiscalPayMethodTypes = useSelector(
    (state: RootState) => state.fiscalPayMethodType.fiscalPayMethodTypes
  );

  const paymentMethodsTypes = useSelector(
    (state: RootState) => state.paymentMethodType.paymentMethodTypes
  );


  const onFinish = async (values: PaymentMethodsDto) => {
    dispatch(setLoadingState(true));

    await apiService
      .post("/api/PaymentMethod", values)
      .then((response) => {
        toast.success(t("paymentMethodsCreatedSuccessfully"));
        db.deleteTab("paymentMethod/register");
        dispatch(getPaymentMethodsSuccess([]));
        navigate("/paymentMethod");
      })
      .catch(() => { })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const validationSchema = Yup.object({
    description: Yup.string().required(t("descriptionRequired")),
    shortDescription: Yup.string().required(t("shortDescriptionRequired")),
    displayedInPos: Yup.boolean(),
    typeId: Yup.number().required(t("typeIdRequired")).test("typeId", t("typeIdRequired"),
      (value) => value > 0
    ),
    sortingOrder: Yup.number()
      .required(t("sortingOrderRequired"))
      .test("sortingOrder", t("sortingOrderZero"),
        (value) => value > 0
      ),
    fiscalPayMethodType: Yup.number()
      .required(t("fiscalTypeRequired"))
      .test("fiscalPayMethodType", t("fiscalTypeZero"),
        (value) => value > 0
      ),
    comission: Yup.number()
      .required(t("comissionRequired"))
      .test("comission", t("comissionZero"),
        (value) => value > 0
      ),
  });

  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = (await validateForm()) || {};
    setTouched({
      description: true,
      shortDescription: true,
      displayedInPos: true,
      typeId: true,
      sortingOrder: true,
      paymentWithBonus: true,
      directPayment: true,
      comission: true,
      fiscalPayMethodType: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };

  useEffect(() => {
    dispatch(getPaymentMethodTypesAsync());
    dispatch(getFiscalPayMethodTypesAsync());
  }, [dispatch]);

  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({} as PaymentMethodsDto)}
        onSubmit={onFinish}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, submitForm, setTouched, validateForm }) => (
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            style={{ marginTop: 20 }}
          >
            <FormHeaderOptions
              title={t("formTitle")}
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createPaymentMethodsButton")}
              submitButtonIsDisabled={isLoading}
              createAccessEnum={MenuOptionEnum.PaymentMethodsCreate}
            />

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <FormInput
                  name="shortDescription"
                  label={t("shortDescriptionInputLabel")}
                  type="text"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: t("shortDescriptionPlaceholder"),
                    },
                  ]}
                  value={values.shortDescription}
                />
                <FormInput
                  name="description"
                  label={t("descriptionInputLabel")}
                  type="text"
                  rules={[
                    {
                      required: true,
                      message: t("descriptionPlaceholder"),
                    },
                  ]}
                  value={values.description}
                />
                <FormInput
                  name="comission"
                  type="number"
                  label={t("comissionInputLabel")}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: t("comissionPlaceholder"),
                    }
                  ]}
                  value={values.comission}
                />
                <FormInput
                  name="sortingOrder"
                  type="number"
                  label={t("sortingOrderLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("sortingOrderPlaceholder"),
                    },
                  ]}
                  value={values.sortingOrder}
                />

              </Col>
              <Col xs={24} sm={12}>
                <FormSelect
                  name="fiscalPayMethodType"
                  label={t("fiscalPayMethodTypeLabel")}
                  required={true}
                  value={values.fiscalPayMethodType}
                  options={
                    fiscalPayMethodTypes?.map((item) => {
                      return {
                        key: item.fiscalPayMethodTypeId,
                        label: item.description,
                        value: item.fiscalPayMethodTypeId,
                      };
                    }) ?? []
                  }
                />
                <FormSelect
                  name="typeId"
                  label={t("typeId")}
                  required={true}
                  value={values.typeId}
                  options={
                    paymentMethodsTypes?.map((item) => {
                      return {
                        key: item.paymentMethodTypeId,
                        label: item.description,
                        value: item.paymentMethodTypeId,
                      };
                    }) ?? []
                  }
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                  <FormSwitch
                    className="switch-for-sale"
                    name="displayedInPos"
                    label={t("displayedInPosLabel")}
                    rules={[
                      {
                        message: t("displayedInPosPlaceholder"),
                      },
                    ]}
                    value={values.displayedInPos}
                  />
                  <FormSwitch
                    className="switch-for-sale"
                    name="paymentWithBonus"
                    label={t("paymentWithBonusLabel")}
                    rules={[
                      {
                        message: t("paymentWithBonusPlaceholder"),
                      },
                    ]}
                    value={values.paymentWithBonus}
                  />
                  <FormSwitch
                    className="switch-for-sale"
                    name="directPayment"
                    label={t("directPaymentLabel")}
                    rules={[
                      {
                        message: t("directPaymentPlaceholder"),
                      },
                    ]}
                    value={values.directPayment}
                  />
                </div>
              </Col>
            </Row>
            <HandleFormDataForTabSaving tabPath="paymentMethod/register" />
          </Form>
        )}
      </Formik>
    </Spin>
  );
};

export default CreatePaymentMethods;
