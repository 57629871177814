import { RefObject } from "react";
import { CreateOrUpdateSaleOrderDetailDto } from "../../../../../models/clientDashboard/EntriesModule/SaleOrder/CreateOrUpdateSaleOrderDetailsDto";
import { ColDef } from "ag-grid-community";
import { suppressEnter } from "../../../../../helperMethods/suppressEnter";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";


export const getSaleOrdersDetailColumns = (
    deleteSaleDetail: (index: number) => void,
    t: (text: string) => string,
    searchItemInputRef: RefObject<any>,
    mode: string
) => {
    const getMaxSaleOrderDetailId = (rowData: CreateOrUpdateSaleOrderDetailDto[]) => {
        return Math?.max(0, ...rowData?.map((item) => item.saleOrderDetailId ?? 0));
    };
    const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const parseDate = (dateStr: string) => {
        const [day, month, year] = dateStr.split('-');
        return `${year}-${month}-${day}`;
    };

    const columnDefs: ColDef<CreateOrUpdateSaleOrderDetailDto>[] = [
        {
            headerName: t("tableHeaders.saleOrderDetailId"),
            field: "saleOrderDetailId",
            editable: false,
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            sortable: false,
            filter: false,
            width: 80,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
            valueSetter: (params) => {
                const newValue = parseInt(params.newValue, 10);

                const allData: CreateOrUpdateSaleOrderDetailDto[] = [];
                params.api.forEachNode((node: any) => allData.push(node.data));

                const isDuplicate = allData.some(
                    (row) => row.saleOrderDetailId === newValue && row !== params.data
                );

                if (isNaN(newValue) || newValue <= 0 || isDuplicate) {
                    params.data.saleOrderDetailId = params.oldValue;
                    return false;
                }

                if (mode === 'update' && params.data.saleOrderDetailId !== 0 && params.data.saleOrderDetailId !== undefined && params.data.saleOrderDetailId !== null) {
                    params.data.rowAction = 'U';
                }
                params.data.saleOrderDetailId = newValue;
                return true;
            },
            valueGetter: (params: any) => {
                if (params.data.no === undefined || params.data.no === null) {

                    const allData: CreateOrUpdateSaleOrderDetailDto[] = [];
                    params.api.forEachNode((node: any) => allData.push(node.data));
                    const maxNo = getMaxSaleOrderDetailId(allData);
                    params.data.no = maxNo + 1;
                }
                return params.data.no;
            },
        },
        {
            hide: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.itemId"),
            field: "itemId",
            editable: false,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.itemDescription"),
            field: "itemDescription",
            editable: false,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.barcode"),
            field: "barcode",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            valueSetter: (params) => {
                const newValue = params.newValue;
                if (mode === 'update' && params.data.saleOrderDetailId !== 0 && params.data.saleOrderDetailId !== undefined && params.data.saleOrderDetailId !== null) {
                    params.data.rowAction = 'U';
                }
                params.data.barcode = newValue;
                return true;
            }
        },
        {
            headerName: t("tableHeaders.quantity"),
            wrapHeaderText: true,
            filter: false,
            field: "quantity",
            editable: true,
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            singleClickEdit: true,
            suppressMenu: true,
            valueSetter: (params: any) => {
                const value = params.newValue;
                if (parseInt(value) <= 0) {
                    params.data.quantity = 1;
                } else {
                    params.data.quantity = value;
                }
                if (mode === 'update' && params.data.saleOrderDetailId !== 0 && params.data.saleOrderDetailId !== undefined && params.data.saleOrderDetailId !== null) {
                    params.data.rowAction = 'U';
                }
                params.data.package = (Number(value) / params?.data?.itemPackaging)?.toFixed(4);
                return true;
            },
            sortable: false,
            suppressMovable: true,
        },
        {
            headerName: t("tableHeaders.itemCost"),
            field: "itemCost",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.avarageCost"),
            field: "avarageCost",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.comment"),
            field: "comment",
            sortable: false,
            editable: true,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.vAT"),
            field: "vAT",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            editable: false,
            filter: false,
            singleClickEdit: true,
            valueSetter: (params) => {
                const vat = parseFloat(params.newValue);
                //   //const priceWithoutVat = params.data.purchasePrice;
                //   //const purchasePriceWithVat = priceWithoutVat * (1 + vat / 100);
                //   params.data.v = parseFloat(vat?.toFixed(2));
                //   //const purchasePrice = params?.data?.purchasePrice;
                //   const qty = params?.data?.quantity;

                //   const discount = params?.data?.discount;
                //   const extraDiscount = parseFloat(params?.newValue?.toFixed(2));

                //   if (purchasePrice !== undefined && vat !== undefined) {
                //     let discountedPrice = purchasePrice;

                //     if (discount !== undefined) {
                //       const discountValue = purchasePrice * (discount / 100);
                //       discountedPrice -= discountValue;
                //     }

                //     if (extraDiscount !== undefined) {
                //       const extraDiscountValue = purchasePrice * (extraDiscount / 100);
                //       discountedPrice -= extraDiscountValue;
                //     }

                //     const purchaseSumWithVat = (
                //       discountedPrice *
                //       (1 + vat / 100) *
                //       (qty !== undefined ? qty : 1)
                //     ).toFixed(2);
                //   }
                if (
                    mode === "update" &&
                    params.data.saleOrderDetailId !== 0 &&
                    params.data.saleOrderDetailId !== undefined &&
                    params.data.saleOrderDetailId !== null
                ) {
                    params.data.rowAction = "U";
                }
                return true;
            },
            maxWidth: 80,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.discount"),
            wrapHeaderText: true,
            field: "discount",

            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            valueSetter: (params: any) => {
                const previousdiscount = params?.oldValue;
                const newValue = params.newValue;
                if (isNaN(newValue) || newValue < 0) {
                    toast.error(t("discountNegative"));
                    params.data.discount = previousdiscount;
                    return false;
                }
                return true;
            },
            editable: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.extraDiscount"),
            wrapHeaderText: true,
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            valueSetter: (params: any) => {
                const previousextraDiscount = params?.oldValue;
                const newValue = params.newValue;
                if (isNaN(newValue) || newValue < 0) {
                    toast.error(t("extraDiscountNegative"));
                    params.data.extraDiscount = previousextraDiscount;
                    return false;
                }
                return true;
            },
            field: "extraDiscount",
            filter: false,
            singleClickEdit: true,
            maxWidth: 120,
            suppressMenu: true,
            editable: true,
        },
        {
            headerName: t("tableHeaders.salePrice"),
            field: "salePrice",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            wrapHeaderText: true,
            filter: false,
            singleClickEdit: true,
            maxWidth: 120,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.options"),
            suppressMenu: true,
            maxWidth: 100,
            cellRenderer: (params: any) => {
                return (
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteSaleDetail(params.rowIndex)}
                        style={{ color: "#007FFF" }}
                    ></Button>
                );
            },
        },
    ];
    return columnDefs;
};
