import { Col, Form, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import { useEffect, useRef, useState } from "react";
import useFormData from "../../../../customHooks/useFormData";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { CreateAndEditCashRegisterDto } from "../../../../models/clientDashboard/Registrations/CashRegister/CreateAndEditCashRegisterDto";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import apiService from "../../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getCashRegisterSuccess } from "../../../../redux/slices/cashRegisterSlice";
import * as Yup from "yup";
import { Formik } from "formik";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import FormInput from "../../../CustomComponents/FormInput";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import FormSelect from "../../../CustomComponents/FormSelect";
import style from "antd/es/affix/style";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import { getUserBranches } from "../../../../redux/slices/userSlice";
import { UserBranchDto } from "../../../../models/clientDashboard/Branch/UserBranchDto";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";

const { Title, Text } = Typography;

const CreateCashRegister = () => {
    const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
    const formikRef = useRef<any>(null);
    const userBranches = useSelector((state: RootState) => state.user.loggedInUserBranches);
    const user = useSelector((state: RootState) => state.user.loggedInUser);

    const { initialValues } = useFormData<CreateAndEditCashRegisterDto>(
        'cashRegister/register',
        {
            status: true,
            branchId: user?.isMainBranch === false ? Number(user.branchId) : (userBranches.length === 1 ? userBranches[0].branchId : undefined),
        } as CreateAndEditCashRegisterDto
    );
    const t = useAppTranslation("ClientDashboard.CreateCashRegister");

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector(
        (rootState: RootState) => rootState.loading.isLoading
    );

    useEffect(() => {
        dispatch(getUserBranches());
    }, [dispatch])
    const onFinish = async (values: CreateAndEditCashRegisterDto) => {
        dispatch(setLoadingState(true));

        await apiService
            .post("/api/CashRegister", values)
            .then((response) => {
                toast.success(t("cashRegisterCreatedSuccessfully"));
                db.deleteTab('cashRegister/register');
                dispatch(getCashRegisterSuccess([]))
                navigate("/cashRegisters");
            })
            .catch(() => { })
            .finally(() => {
                dispatch(setLoadingState(false));
            });
    };

    const validationSchema = Yup.object({
        branchId: Yup.string().required(t(" branchIdRequired")),
        cashRegisterNo: Yup.string().required(t("cashRegisterNoRequired")),
    });

    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm() || {};
        setTouched({
            branchId: true,
            cashRegisterNo: true,
            hostName: true,
            entryDate: true,
            printerType: true,
            port: true,
            printsDirectly: true,
            FiscalizationCode: true,
            macAddress: true,
            status: true,
        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    };

    return (
        <Spin tip="Loading..." spinning={loadScreen}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={initialValues ?? {
                    status: true,
                    branchId: user?.isMainBranch === false ? Number(user.branchId) : (userBranches.length === 1 ? userBranches[0].branchId : undefined),
                } as CreateAndEditCashRegisterDto}
                onSubmit={onFinish}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ values, handleSubmit, submitForm, setTouched, validateForm }) => (
                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                        style={{ marginTop: 20 }}
                    >
                        <FormHeaderOptions
                            title={t("formTitle")}
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("createCashRegisterButton")}
                            submitButtonIsDisabled={isLoading}
                            createAccessEnum={MenuOptionEnum.CashRegisterCreate}
                        />

                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <FormSelect
                                    name="branchId"

                                    label={t("branchIdInputLabel")}
                                    required={true}
                                    value={values.branchId}
                                    options={
                                        userBranches?.map((userBarnch) => {
                                            return {
                                                key: userBarnch.branchId,
                                                label: userBarnch.description,
                                                value: userBarnch.branchId,
                                            };
                                        }) ?? []
                                    }
                                />
                                <FormInput
                                    name="cashRegisterNo"
                                    type="number"
                                    label={t("cashRegisterNoNameInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("cashRegisterNoInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.cashRegisterNo}
                                />
                                <FormInput
                                    name="hostName"
                                    label={t("hostNameSymbolInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("hostNameInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.hostName}
                                />
                            </Col>
                            <Col xs={24} sm={12}>

                                <FormInput
                                    name="fiscalizationCode"
                                    label={t("fiscalizationCodeInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("fiscalizationCodeInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.fiscalizationCode}
                                />
                                <FormInput
                                    name="macAddress"
                                    label={t("macAddressInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("macAddressInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.macAddress}
                                />
                                <FormSwitch
                                    className="switch-for-cashRegister"
                                    name="status"
                                    label={t("statusInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("statusInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.status}
                                />


                            </Col>
                        </Row>
                        <HandleFormDataForTabSaving tabPath="cashRegister/register" />
                    </Form>
                )}
            </Formik>
        </Spin>
    );
};

export default CreateCashRegister;