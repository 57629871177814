import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { Col, Form, Modal, Row, Switch } from "antd";
import { formatDate } from "../../../../helperMethods/DateFormat";
import {
  getEntriesForPricesImport,
  getEntryDetailsForPricesImport,
} from "../../../../redux/slices/localPurchaseSlice";
import { AgGridReact } from "ag-grid-react";
import { LocalPurchase } from "../../../../models/clientDashboard/LocalPurchase/LocalPurchase";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import "./ChoosePurchaseModal.css";
import { PricesDetail } from "../../../../models/clientDashboard/ItemPricesDetail/PricesDetail/PricesDetail";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import { Field } from "formik";
interface Props {
  open: boolean;
  onClose: () => void;
  branchesForPriceDetail: {
    branchId: number;
    branchName?: string;
    branchPrice: number;
    itemId: number;
  }[];
  fetchAndSetPricesDetails: (pricesDetails: PricesDetail[]) => void;
  setFieldValue: (field: string, value: any) => void;
  branchId: number;
}

export default function ChoosePurchaseModal({
  open,
  onClose,
  branchesForPriceDetail,
  fetchAndSetPricesDetails,
  setFieldValue,
  branchId,
}: Props) {
  const [includingCondition, setIncludingCondition] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState<LocalPurchase[]>([]);
  const priceDetailGridRef = useRef(null);
  const gridRef = useRef(null);

  const dispatch = useDispatch<AppDispatch>();
  const [isClosingModal, setIsClosingModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const t = useAppTranslation("ChoosePurchaseModal");


  const columnDefs = [
    {
      field: "entryItemsId",
      headerName: t("tableHeaders.entryItemsId"),
      suppressMenu: true,
      width: 150,
    },
    {
      field: "documentTypeId",
      headerName: t("tableHeaders.documentTypeId"),
      suppressMenu: true,
      width: 140,
    },
    {
      field: "documentNo",
      headerName: t("tableHeaders.documentNo"),
      suppressMenu: true,
      width: 140,
    },
    {
      field: "date",
      headerName: t("tableHeaders.date"),
      valueFormatter: formatDate,
      suppressMenu: true,
      width: 110,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };
  const handleRowDoubleClick = (event: any) => {
    fetchEntryDetailsForPricesImport(event.data.entryItemsId);
    setFieldValue("entryItemsIdAsString", event.data?.entryItemsId?.toString());
  };

  const fetchEntryDetailsForPricesImport = async (entryItemsId: string) => {
    try {
      const result = await dispatch(
        getEntryDetailsForPricesImport({
          entryItemsId: entryItemsId,
          branches: branchesForPriceDetail.map((branch) => branch.branchId),
          includeCondition: includingCondition,
        })
      ).unwrap();
  
      const priceDetailsArray: PricesDetail[] = Object.values(
        result.reduce((acc, item) => {
          if (!acc[item.itemId]) {
            // Create a temporary array to store branch details for this item
            const tempBranchDetails = branchesForPriceDetail.map((branchDetail) => {
              const matchingResult = result.find(
                (r) => r.branchId === branchDetail.branchId && r.itemId === item.itemId
              );
              if (matchingResult) {
                return {
                  ...branchDetail,
                  branchPrice: matchingResult.salesPrice,
                  itemId: matchingResult.itemId,
                };
              }
              return branchDetail;
            });
            console.log(tempBranchDetails);
            acc[item.itemId] = {
              pricesDetailsId: undefined,
              itemId: item.itemId,
              branchId: null,
              itemDescription: item.itemDescription,
              branchPriceDetails: tempBranchDetails, 
              purchasePrice: item.purchasePrice,
              discount: item.discount,
              extraDiscount: item.extraDiscount,
              salesPrice: 0,
              wholeSalePrice: item.wholesalePrice,
              rowAction: "A",
            };
          }
          return acc;
        }, {} as { [key: string]: PricesDetail })
      );
  
      fetchAndSetPricesDetails(priceDetailsArray);
  
      onClose();
    } catch (error) {
      console.error("Error fetching entry details for price import:", error);
      throw error;
    }
  };

  useEffect(() => {
    dispatch(getEntriesForPricesImport(branchId))
      .unwrap()
      .then((data) => {
        setRowData(data);
      })
      .catch((error) => {
        console.error("Failed to fetch entries:", error);
      });
  }, [dispatch,branchId]);

  return (
    <Modal
      title={t("ChoosePurchase")}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <div className="ag-theme-alpine" style={{ height: 650, width: "100%" }}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Form.Item label={t("choose")} className="custom-form-item">
              <Switch
                value={includingCondition}
                onChange={(value) => setIncludingCondition(value)}
              />
            </Form.Item>
            <AgGridTableComponent
              ref={gridRef}
              rowData={rowData}
              isLoading={isLoading}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              onRowDoubleClicked={handleRowDoubleClick}
            />
          </>
        )}
      </div>
    </Modal>
  );
}
