import React from "react";
import { Modal, Button } from "antd";
import { Typography } from "antd";

const { Text } = Typography;

interface SuccessModalProps {
  visible: boolean;
  username: string;
  password: string;
  handleOk: () => void;
  handleCancel: () => void;
}

const ClientCreatedModal: React.FC<SuccessModalProps> = ({
  visible,
  username,
  password,
  handleOk,
  handleCancel,
}) => {
  return (
    <Modal
      title="Kompania u krijua me sukses"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {/* <CheckCircleOutlined style={{ color: "#52c41a", fontSize: 50 }} /> */}

      <Text italic>
        Ne menyre qe ju te kyceni ne aplikacion, ju duhet te perdorni
        perdoruesin e saporkijuar per kompanine tuaj, ne menyre qe te kryeni
        transaksionet fillestare
      </Text>
      <div
        style={{
          marginTop: 20,
        }}
      >
        <div>
          <Text>
            Përdoruesi: <Text strong>{username}</Text>
          </Text>
        </div>
        <div>
          <Text>
            Fjalëkalimi: <Text strong>{password}</Text>
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default ClientCreatedModal;
