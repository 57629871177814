import { ColDef } from "ag-grid-community";
import { suppressEnter } from "../../../helperMethods/suppressEnter";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CreateAndUpdateGoodsImportExpenseDto } from "../../../models/clientDashboard/GoodsImportExpense/CreateAndUpdateGoodsImportExpenseDto";

export const getExpensesDetailColumns = (
    deleteExpensesDetail: (index: number) => void,
    t: (text: string) => string,
    updateExpensesDetail: (index: string) => void,
    mode: string
) => {
    const getMaxNo = (rowData: CreateAndUpdateGoodsImportExpenseDto[]) => {
        return Math?.max(0, ...rowData?.map((item) => item.no ?? 0));
    };
    const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const parseDate = (dateStr: string) => {
        const [day, month, year] = dateStr.split('-');
        return `${year}-${month}-${day}`;
    };

    const columnDefs: ColDef<CreateAndUpdateGoodsImportExpenseDto>[] = [
        {
            headerName: t("tableHeaders.no"),
            field: "no",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            sortable: false,
            hide: mode === 'update' ? false : true,
            filter: false,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,

        },
        {
            hide: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.entity"),
            field: "entity",
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.invoiceNo"),
            field: "invoiceNumber",
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            maxWidth: 130
        },
        {
            headerName: t("tableHeaders.comment"),
            field: "comment",
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.value"),
            field: "value",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            maxWidth: 130
        },
        {
            headerName: t("tableHeaders.vat"),
            field: "vat",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            maxWidth: 80
        },
        {
            headerName: t("tableHeaders.expenseType"),
            field: "expenseType",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            width: 200
        },
        {
            headerName: t("tableHeaders.distributionMethod"),
            field: "distributionMethod",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            width: 180,
        },
        {
            headerName: t("tableHeaders.currencyName"),
            field: "currencyName",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            width: 120
        },
        {
            headerName: t("tableHeaders.exchangeRate"),
            field: "exchangeRate",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            width: 80
        },
        {
            headerName: t("tableHeaders.nivf"),
            field: "nivf",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            width: 90
        },
        {
            headerName: t("tableHeaders.date"),
            field: "date",
            sortable: false,
            filter: false,
            width: 90,
            suppressMovable: true,
            suppressMenu: true,
            valueFormatter: (params) => formatDate(params.value)
        },

        {
            headerName: t("tableHeaders.fiscalized"),
            field: "fiscalized",
            sortable: false,
            filter: false,
            width: 100,
            suppressMovable: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.fromDate"),
            field: "fromDate",
            sortable: false,
            filter: false,
            width: 100,
            suppressMovable: true,
            suppressMenu: true,
            valueFormatter: (params) => {
                if (params.value === undefined || params.value === "" || params.value === null) {
                    return "";
                }
                else {
                    return formatDate(params.value)
                }
            }
        },
        {
            headerName: t("tableHeaders.toDate"),
            field: "toDate",
            sortable: false,
            filter: false,
            width: 120,
            suppressMovable: true,
            suppressMenu: true,
            valueFormatter: (params) => {
                if (params.value === undefined || params.value === "" || params.value === null) {
                    return "";
                }
                else {
                    return formatDate(params.value)
                }
            }
        },
        {
            headerName: t("tableHeaders.paymentDeadline"),
            field: "paymentDeadline",
            sortable: false,
            filter: false,
            width: 100,
            suppressMovable: true,
            suppressMenu: true
        },
        {
            headerName: t("tableHeaders.options"),
            suppressMenu: true,
            width: 100,
            cellRenderer: (params: any) => {
                return (
                    <div style={{ display: "flex", gap: "8px" }}>
                        <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => deleteExpensesDetail(params.rowIndex)}
                            style={{ color: "#007FFF" }}
                        ></Button>
                        <Button
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => updateExpensesDetail(params.data.tempId)}
                            style={{ color: "#28a745" }}
                        ></Button>
                    </div>
                );
            },
        },
    ];
    return columnDefs;
};
