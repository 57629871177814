import { Select, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import useAppTranslation from "../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  getAllConfigurationsAsync,
  getAllConfigurationsSuccess,
  getAllConfigurationValues,
} from "../../redux/slices/getAllConfigurationsSlice";
import apiService from "../../extensions/api";
import AgGridTableComponent from "../CustomComponents/AgGridTableComponent";
import _ from "lodash";
import { GetAllConfigurationsDto } from "../../models/Configurations/GetAllConfigurationsDto";

const { Title, Text } = Typography;

const GetAllConfigurations = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.GetAllConfigurations");
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact | null>(null);
  const gridApi = useRef<any>(null);
  const gridColumnApi = useRef<any>(null);
  const [getAllConfigurations, setGetAllConfigurations] = useState<
    GetAllConfigurationsDto[]
  >([]);
  const configurationValues = useSelector(
    (state: RootState) => state.configuration.configurationValues
  );
  const [colDefs, setColDefs] = useState<any[]>([]);

  const getGetAllConfigurations = async () => {
    const response = await dispatch(getAllConfigurationsAsync());
    if (response.type === "AllConfigurations/getAllConfigurations/fulfilled") {
      setGetAllConfigurations(response.payload as GetAllConfigurationsDto[]);
    }
  };

  const getAllConfigurationValuesAsync = async () => {
    await dispatch(getAllConfigurationValues());
  };

  useEffect(() => {
    getGetAllConfigurations();
    getAllConfigurationValuesAsync();
  }, []);

  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;

    const allColumnIds: string[] = gridColumnApi.current
      .getAllDisplayedColumns()
      .map((column: any) => column.getColId());
    gridColumnApi.current.autoSizeColumns(allColumnIds);
  };

  const onFirstDataRendered = () => {
    gridColumnApi.current.autoSizeColumns([
      "description",
      "type",
      "status",
    ]);
  };

  const updateConfigurations = (
    updatedConfigurations: GetAllConfigurationsDto[],
    updatedConfigurationId: number
  ) => {
    const updatedConfiguration = updatedConfigurations.find(
      (configuration) =>
        configuration.configurationId === updatedConfigurationId
    );
    if (updatedConfiguration) {
      apiService
        .put("/api/Configuration/updateConfiguration", updatedConfiguration)
        .then(() => {
          dispatch(getAllConfigurationsSuccess([]));
          getGetAllConfigurations();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useMemo(() => {
    setColDefs([
      {
        field: "configurationId",
        suppressMenu: true,
        headerName: "ID",
        width: 80,
        cellRenderer: (params: any) => {
          return (
            <Text className="ag-grid-id-column">
              {params.data.configurationId}
            </Text>
          );
        },
      },
      {
        field: "description",
        headerName: t("tableHeaders.description"),
        suppressMenu: true,
        editable: true,
      },
      { field: "type", headerName: t("tableHeaders.type"), suppressMenu: true },
      {
        field: "status",
        suppressMenu: true,
        headerName: t("tableHeaders.status"),
        editable: true,
        cellRenderer: "agCheckboxCellRenderer",
        onCellValueChanged: (params: any) => {
          const value = params.newValue;

          const updated: GetAllConfigurationsDto = {
            ...params.data,
            configurationId: params.data.configurationId,
            status: value,
            valueId:
              value === false
                ? null
                : params.data.valueId === null
                ? configurationValues.find(
                    (configValue) =>
                      configValue.configurationId ===
                      params.data.configurationId
                  )?.configurationValueId ?? null
                : params.data.valueId,
          };
          apiService
            .put("/api/Configuration/updateConfiguration", updated)
            .then(() => {
              dispatch(getAllConfigurationsSuccess([]));
              getGetAllConfigurations();
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },
      {
        field: "valueId",
        suppressMenu: true,
        headerName: t("tableHeaders.valueId"),
        editable: false,
        width: 501,
        cellRenderer: (params: any) => {
          const currentIndex = params.node.rowIndex;
          return (
            <Select
              style={{ width: "100%"}}
              value={params.value}
              className="ConfigurationSelect"
              disabled={!params.data.status}
              showSearch={false}
              onChange={(value) => {
                const updatedValues = [
                  ...(gridRef.current?.props
                    ?.rowData as GetAllConfigurationsDto[]),
                ];
                const configurationForEdit = updatedValues[currentIndex];
                if (configurationForEdit) {
                  configurationForEdit.valueId = value;
                  configurationForEdit.status = true;
                }
                updateConfigurations(
                  updatedValues,
                  configurationForEdit.configurationId
                );
              }}
              options={configurationValues
                .filter(
                  (configValue) =>
                    configValue.configurationId === params.data.configurationId
                )
                .map((configurationValue) => ({
                  key: configurationValue.configurationValueId,
                  label: configurationValue.value,
                  value: configurationValue.configurationValueId,
                }))}
            />
          );
        },
        onCellValueChanged: (params: any) => {
          const value = params.newValue;
          const updated: GetAllConfigurationsDto = {
            ...params.data,
            configurationId: params.data.configurationId,
            valueId: value,
          };
          apiService
            .put("/api/Configuration/updateConfiguration", updated)
            .then(() => {
              dispatch(getAllConfigurationsSuccess([]));
              getGetAllConfigurations();
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },
    ]);
  }, [configurationValues]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const mutableObject = _.cloneDeep(getAllConfigurations);

  return (
    <>
      <AgGridTableComponent
        rowHeight={40}
        gridRef={gridRef}
        isLoading={isLoading}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        rowData={mutableObject}
        pagination={true}
        paginationPageSize={20}
        suppressCellFocus={true}
        stopEditingWhenCellsLoseFocus={true}
        enableCellTextSelection={true}
        onCellEditingStopped={(params: any) => {
          const data = params.data;
          if (data?.configurationId === 0 && data?.description?.trim() === "") {
            setGetAllConfigurations((prevValues) =>
              prevValues.filter(
                (configuration) => configuration.configurationId !== 0
              )
            );
          } else if (
            data?.configurationId !== 0 &&
            data?.description?.trim() === ""
          ) {
            setGetAllConfigurations((prevValues) => {
              const configurations = [...prevValues];
              const configuration = configurations.find(
                (configuration) =>
                  configuration.configurationId === data.configurationId
              );
              if (configuration) {
                configuration.description = data.description;
              }
              return configurations;
            });
          }
        }}
      />
    </>
  );
};

export default GetAllConfigurations;
