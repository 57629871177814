import { Entity } from "../../../models/clientDashboard/Entity/Entity";
import { CreateAndUpdateEntityBranchDto } from "../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { CreateOrUpdateInternalSaleDetailDto } from "../../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDetailDto";
import { CreateOrUpdateInternalSaleDto } from "../../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDto";
import { CreateAndEditLocalPurchaseDto } from "../../../models/clientDashboard/LocalPurchase/CreateAndEditLocalPurchaseDto";

export   const categories: { key: keyof Entity; description: string }[] = [
    { key: "k25", description: "K25" },
    { key: "k26", description: "K26" },
    { key: "k27", description: "K27" },
    { key: "k28", description: "K28" },
    { key: "k29", description: "K29" },
    { key: "k30", description: "K30" },
    { key: "k31", description: "K31" },
    { key: "k32", description: "K32" },
    { key: "k33", description: "K33" },
    { key: "k34", description: "K34" },
    { key: "k35", description: "K35" },
    { key: "k36", description: "K36" },
    { key: "k37", description: "K37" },
    { key: "k38", description: "K38" },
    { key: "k39", description: "K39" },
    { key: "k40", description: "K40" },
    { key: "k41", description: "K41" },
    { key: "k42", description: "K42" },
    { key: "k43", description: "K43" },
    { key: "k44", description: "K44" },
    { key: "k45", description: "K45" },
    { key: "k46", description: "K46" },
    { key: "k47", description: "K47" },
    { key: "k48", description: "K48" },
  ];
  export const entityBranchClassifiers: { key: keyof CreateAndUpdateEntityBranchDto; description: string }[] = [
    { key: "k55", description: "K55" },
    { key: "k56", description: "K56" },
    { key: "k57", description: "K57" },
    { key: "k58", description: "K58" },
    { key: "k59", description: "K59" },
  ]


  export   const entryItemCategories: { key: keyof CreateAndEditLocalPurchaseDto; description: string }[] = [
    { key: "k45", description: "K45" },
    { key: "k46", description: "K46" },
    { key: "k47", description: "K47" },
    { key: "k48", description: "K48" },
    { key: "k49", description: "K49" },
  ];

  export   const internalSaleCategories: { key: keyof CreateOrUpdateInternalSaleDto; description: string }[] = [
    { key: "k65", description: "K65" },
    { key: "k66", description: "K66" },
    { key: "k67", description: "K67" },
    { key: "k68", description: "K68" },
    { key: "k69", description: "K69" },
  ];

  export   const internalSaleDetailsCategories: { key: keyof CreateOrUpdateInternalSaleDetailDto; description: string }[] = [
    { key: "k70", description: "K70" },
    { key: "k71", description: "K71" },
    { key: "k72", description: "K72" },
    { key: "k73", description: "K73" },
    { key: "k74", description: "K74" },
  ];