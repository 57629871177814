import { Button, Modal, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { getDocumentPeriodsAsync, getDocumentPeriodsSuccess } from "../../../../redux/slices/documentPeriodsSlice";
import { CreateAndEditDocumentPeriodsDto } from "../../../../models/clientDashboard/Registrations/DocumentPeriods/CreateAndEditDocumentPeriodsDto";
import apiService from "../../../../extensions/api";
import { useNavigate } from "react-router-dom";
import { setEditingCell } from "../../../../helperMethods/setEditingCell";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { CellKeyDownEvent } from "ag-grid-community";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";
import { DocumentPeriod } from "../../../../models/clientDashboard/Registrations/DocumentPeriods/DocumentPeriod";
import _, { get } from "lodash";
import { formatDate } from "../../../../helperMethods/DateFormat";
import moment from "moment";
import { handleNextGridColumnClick } from "../../../../helperMethods/handleNextGridColumnClick";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import { DateTime } from "luxon";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";


const { Title, Text, Link } = Typography;

const DocumentPeriods = () => {
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const t = useAppTranslation("ClientDashboard.DocumentPeriods");
    const gridRef = useRef<AgGridReact | null>(null);
    const [documentPeriods, setDocumentPeriods] = useState<DocumentPeriod[]>([]);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [newRowIndex, setNewRowIndex] = useState<number | null>(null);
    const [menuDocumentPeriodsDisabled, setMenuDocumentPeriodsDisabled] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const getDocumentPeriods = async () => {
        const response = await dispatch(getDocumentPeriodsAsync());
        if (response.type === "DocumentPeriods/getDocumentPeriods/fulfilled") {
            setDocumentPeriods(response.payload as DocumentPeriod[]);
        }
    };

    const handleAddDocumentPeriods = () => {
        const now = moment().startOf('day');
        const startDate = now.format("YYYY-MM-DD");
        const endDate = "";

        const newDocumentPeriods: DocumentPeriod = {
            documentPeriodsId: 0,
            startDate,
            endDate,
            isClosed: false,
        };

        const existingEditRow = documentPeriods.find(dp => dp.documentPeriodsId === 0);
        if (existingEditRow) {
            setEditMode(true);
            setNewRowIndex(documentPeriods.indexOf(existingEditRow));
            setTimeout(() => {
                setEditingCell(gridRef, "startDate");
            }, 0);
            return;
        }

        setDocumentPeriods([...documentPeriods, newDocumentPeriods]);
        setEditMode(true);
        setNewRowIndex(documentPeriods.length);

        setTimeout(() => {
            setEditingCell(gridRef, "startDate");
        }, 0);
    };

    const handleCheckButtonClick = (params: any) => {
        const data = params.data;
        const updatedDocumentPeriods = {
            documentPeriodsId: data.documentPeriodsId,
            startDate: data.startDate,
            endDate: data.endDate,
            isClosed: data.isClosed,
        };

        if (data.documentPeriodsId === 0) {
            if (updatedDocumentPeriods.startDate && updatedDocumentPeriods.endDate) {
                apiService.post("/api/DocumentPeriods/create", updatedDocumentPeriods)
                    .then(() => {
                        dispatch(getDocumentPeriodsSuccess([]));
                        getDocumentPeriods();
                    })
                    .catch((error) => {
                        console.error("Error creating document period:", error);
                        dispatch(getDocumentPeriodsSuccess([]));
                        getDocumentPeriods();
                    });
            }
        } else {
            apiService.put("/api/DocumentPeriods/update", updatedDocumentPeriods)
                .then(() => {
                    dispatch(getDocumentPeriodsSuccess([]));
                    getDocumentPeriods();
                })
                .catch((error) => {
                    console.error("Error updating document period:", error);
                    dispatch(getDocumentPeriodsSuccess([]));
                    getDocumentPeriods();
                });
        }
    };

    const addOrUpdateDocumentPeriodsOnCellChange = async (params: any, field: string) => {
        try {
            let value = params.newValue;
            const data = params.data;

            if (field === "startDate" || field === "endDate") {
                const newDate = moment(value, 'YYYY-MM-DD', true);

                if (!newDate.isValid()) {
                    Modal.error({
                        title: t("InvalidDate"),
                        content: t("validDate"),
                    });
                    params.api.stopEditing();
                    return;
                }

                const startDate = field === "startDate" ? newDate : moment(data.startDate, 'YYYY-MM-DD');
                const endDate = field === "endDate" ? newDate : moment(data.endDate, 'YYYY-MM-DD');
                const today = moment().startOf('day');

                if (startDate.isAfter(endDate)) {
                    Modal.error({
                        title: "Invalid Date Range",
                        content: "The startDate cannot be later than the endDate.",
                    });
                    params.api.stopEditing();
                    return;
                }

                if (startDate.isBefore(today)) {
                    Modal.error({
                        title: "Invalid Start Date",
                        content: "The startDate cannot be earlier than today.",
                    });
                    params.api.stopEditing();
                    return;
                }

                params.node.setDataValue(field, newDate.format('YYYY-MM-DD'));
            } else if (field === "isClosed") {
                params.node.setDataValue(field, value);
            }

            params.api.stopEditing(true);

            if (editMode && newRowIndex !== null && params.rowIndex === newRowIndex) {
                const updatedDocumentPeriods = {
                    documentPeriodsId: params.data.documentPeriodsId,
                    startDate: field === "startDate" ? moment(value).format('YYYY-MM-DD') : data.startDate,
                    endDate: field === "endDate" ? moment(value).format('YYYY-MM-DD') : data.endDate,
                    isClosed: field === "isClosed" ? value : data.isClosed,
                };

                console.log("Updated Document Periods:", updatedDocumentPeriods);

                if (params.data.documentPeriodsId === 0) {
                    if (updatedDocumentPeriods.startDate && updatedDocumentPeriods.endDate) {
                        apiService.post("/api/DocumentPeriods/create", updatedDocumentPeriods)
                            .then(() => {
                                dispatch(getDocumentPeriodsSuccess([]));
                                getDocumentPeriods();
                            })
                            .catch((error) => {
                                console.error("Error creating document period:", error);
                                dispatch(getDocumentPeriodsSuccess([]));
                                getDocumentPeriods();
                            });
                    }
                } else {
                    apiService.put("/api/DocumentPeriods/update", updatedDocumentPeriods)
                        .then(() => {
                            dispatch(getDocumentPeriodsSuccess([]));
                            getDocumentPeriods();
                        })
                        .catch((error) => {
                            console.error("Error updating document period:", error);
                            dispatch(getDocumentPeriodsSuccess([]));
                            getDocumentPeriods();
                        });
                }
            }
        } catch (e) {
            console.error("Error handling cell change:", e);
        }
    };

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };
    const handleIsClosedChange = async (params: any) => {
        try {
            const data = params.data;
            const newIsClosedValue = params.newValue;

            if (data.documentPeriodsId !== 0) {
                const updatedDocumentPeriods = {
                    documentPeriodsId: data.documentPeriodsId,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    isClosed: newIsClosedValue,
                };

                await apiService.put("/api/DocumentPeriods/update", updatedDocumentPeriods);
                console.log("Update response: Document period updated successfully");

                dispatch(getDocumentPeriodsSuccess([]));
                await getDocumentPeriods();
            }
        } catch (error) {
            console.error("Error updating document period:", error);
        }
    };
    const colDefs = [
        {
            field: "documentPeriodsId",
            suppressMenu: true,
            headerName: "ID",
            width: 80,
            cellRenderer: (params: any) => {
                return <span>{params.data.documentPeriodsId}</span>;
            },
        },
        {
            field: "startDate",
            suppressMenu: true,
            headerName: t("tableHeaders.startDate"),
            cellEditor: 'agDateCellEditor',
            cellEditorParams: (params: any) => {
                const endDate = params.data.endDate;
                return {
                    min: DateTime?.now()?.toISODate(),
                    max: endDate ? DateTime.fromISO(endDate).toISODate() : null,
                }
            },
            editable: (params: any) => params.data.documentPeriodsId === 0,
            valueFormatter: ({ value }: { value: string | null | undefined }) => {
                return value ? moment(value).format('YYYY-MM-DD') : "";
            },
            valueParser: (params: any) => {
                const date = moment(params.newValue, 'YYYY-MM-DD').startOf('day');
                return date.isValid() ? date.format('YYYY-MM-DD') : params.oldValue;
            },
            onCellValueChanged: (params: any) => {
                addOrUpdateDocumentPeriodsOnCellChange(params, "startDate");
            },
        },
        {
            field: "endDate",
            suppressMenu: true,
            headerName: t("tableHeaders.endDate"),
            cellEditor: 'agDateCellEditor',
            cellEditorParams: (params: any) => {
                const startDate = params.data.startDate;
                return {
                    min: startDate ? DateTime.fromISO(startDate).toISODate() : null,
                }
            },
            editable: (params: any) => params.data.documentPeriodsId === 0,
            valueFormatter: ({ value }: { value: string | null | undefined }) => {
                return value ? moment(value).format('YYYY-MM-DD') : "";
            },
            valueParser: (params: any) => {
                const date = moment(params.newValue, 'YYYY-MM-DD').startOf('day');
                return date.isValid() ? date.format('YYYY-MM-DD') : params.oldValue;
            },
            onCellValueChanged: (params: any) => {
                addOrUpdateDocumentPeriodsOnCellChange(params, "endDate");
            },
        },
        {
            field: "isClosed",
            suppressMenu: true,
            width: 80,
            headerName: t("tableHeaders.isClosed"),
            editable: (params: any) => params.data.documentPeriodsId !== 0,
            onCellValueChanged: (params: any) => {
                handleIsClosedChange(params)
            },
        },
        {
            headerName: "Actions",
            suppressMenu: true,
            width: 100,
            cellRenderer: (params: any) => {
                if (params.data.documentPeriodsId === 0) {
                    return (
                        <Button onClick={() => handleCheckButtonClick(params)} icon={<CheckOutlined />} style={{ height: "10px", border: "none" }} />
                    );
                }
                return null;
            },

        },
    ];

    useEffect(() => {
        getDocumentPeriods();
    }, []);

    const onGridReady = (params: any) => {
        params.columnApi.autoSizeColumns(['value']);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const [gridApi, setGridApi] = useState<any>(null);
    const [gridColumnApi, setGridColumnApi] = useState<any>(null);
    const mutableObject = _.cloneDeep(documentPeriods);
    return (
        <>
            <ListHeaderComponent
                title={t("titles.documentPeriods")}
                createRecord={handleAddDocumentPeriods}
                createTitle={t("create")}
                menuItemsDisabled={false}
                createAccessEnum={MenuOptionEnum.DocumentPeriodCreate}
            />
            <AgGridTableComponent
                gridRef={gridRef}
                isLoading={isLoading}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                rowData={mutableObject}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
                suppressCellFocus={true}
                stopEditingWhenCellsLoseFocus={true}
                enableCellTextSelection={true}
                singleClickEdit={true}
                onCellKeyDown={(event: CellKeyDownEvent) => {
                    handleNextGridColumnClick(event)
                }}
            />
        </>
    );
};

export default DocumentPeriods;
