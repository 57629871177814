import { Col, Row } from "antd";
import { CreateOrUpdateFinInvoiceRegistration } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistration";
import { Classifier } from "../../../models/clientDashboard/Classifier/Classifier";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { CreateOrUpdateFinInvoiceRegistrationAccounting } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationAccounting";
import { GenericDocumentDto } from "../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import FormSelectWithSearch from "../../CustomComponents/FormSelectWithSearch";
import FormSelect from "../../CustomComponents/FormSelect";

interface Props {
    values: GenericDocumentDto<CreateOrUpdateFinInvoiceRegistration, CreateOrUpdateFinInvoiceRegistrationAccounting>;
    setFieldValue: (name: string, value: any) => void;
    mode:string;
  }

export default function ClassifiersComponent({values, setFieldValue,mode}: Props) {
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const getClassifierValue = (type: string) => {
    switch (type) {
      case "K75":
        return values.header?.k75;
      case "K76":
        return values.header?.k76;
      case "K77":
        return values.header?.k77;
      case "K78":
        return values.header?.k78;
      case "K77":
        return values.header?.k77;
      default:
        return null;
    }
  };
  const renderClassifiers = () => {
    if (!classifiers) return [];
  
    const renderedClassifiers = new Set<string>();
    const inputComponents = classifiers
      .filter((classifier) => classifier.status === true)
      .map((classifier) => {
        if (renderedClassifiers.has(classifier.description)) {
          return null;
        }
        renderedClassifiers.add(classifier.description);
  
        const value = getClassifierValue(classifier.description);
        return (
          <FormSelect
            required={classifier.mandatory}
            key={classifier.description}
            name={"values.header." + classifier.description.toLowerCase()}
            label={classifier.clientDescription || classifier.description}
            options={classifier.classifierDetails?.map((detail) => ({
              key: detail.classifierDetailId?.toString(),
              label: detail.description,
              value: detail.classifierDetailId ?? 0,
            }))}
            handleCustomChange={(selectedValue) => {
              setFieldValue(
                `values.header.${classifier.description.toLowerCase()}`,
                selectedValue
              );
            }}
            value={value}
          />
        );
      })
      .filter(Boolean);
  
    return inputComponents;
  };


  return (
    <Row>
      <Col xs={24} sm={8}>{renderClassifiers()}</Col>
    </Row>
  );
}