import React from "react";
import { Spin, Row, Col, Typography, Button, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { AppDispatch, RootState } from "../../../redux";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import FormInput from "../../CustomComponents/FormInput";


const CreateServer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const t = useAppTranslation("CreateServer");

  const initialValues = {
    serverName: "",
    domain: "",
    dbName: "",
    userName: "",
    password: "",
    isActive: false,
  };

  const validationSchema = Yup.object({
    serverName: Yup.string().required(t("Please enter server name!")),
    domain: Yup.string().required(t("Please enter domain!")),
    dbName: Yup.string().required(t("Please enter database name!")),
    userName: Yup.string().required(t("Please enter username!")),
    password: Yup.string().required(t("Please enter password!")),
  });

  const onSubmit = async (values: typeof initialValues) => {
    dispatch(setLoadingState(true));

    try {
      await apiService.post("/api/dashboard/servers", values);
      toast.success(t("Server created successfully!"));
      navigate("/servers");
    } catch (error) {
      toast.error(t("Error creating server!"));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}  className="serverName">
                <FormInput
                  label={t("Server Name")}
                  name="serverName"
                  value={values.serverName}
                />
             
              </Col>

              <Col xs={24} sm={12}>
                <FormInput
                  label={t("Domain")}
                  name="domain"
                  value={values.domain}
                />
              </Col>

              <Col xs={24} sm={12}>
                <FormInput
                  label={t("Database Name")}
                  name="dbName"
                  value={values.dbName}
                />
              </Col>

              <Col xs={24} sm={12}>
                <FormInput
                  label={t("Username")}
                  name="userName"
                  value={values.userName}
                />
              </Col>

              <Col xs={24} sm={12}>
                <FormInput
                  label={t("Password")}
                  name="password"
                  type="password"
                  value={values.password}
                />
              </Col>

              <Col xs={24} sm={12}>
                <div className="ant-form-item">
                  <Checkbox
                    name="isActive"
                    checked={values.isActive}
                    value={values.isActive}
                    onChange={(e) => setFieldValue("isActive", e.target.checked)}
                  >
                    {t("Is Active")}
                  </Checkbox>
                </div>
              </Col>
            </Row>

            <Button type="primary" htmlType="submit">
              {t("Register Server")}
            </Button>
          </form>
        )}
      </Formik>
    </Spin>
  );
};

export default CreateServer;
