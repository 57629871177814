import { Button, Col, Form, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { useEffect, useRef, useState } from "react";
import useFormData from "../../../customHooks/useFormData";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../indexDB/clientSideDatabase";
import { getBranchesAsync, getBranchesSuccess } from "../../../redux/slices/branchSlice";
import { Formik } from "formik";
import FormInput from "../../CustomComponents/FormInput";
import { CreateBranchDto } from "../../../models/clientDashboard/Branch/CreateBranchDto";
import FormSwitch from "../../CustomComponents/FormSwitch";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { AgGridReact } from "ag-grid-react";
import { BranchUsers } from "../../../models/clientDashboard/BranchUsers/BranchUsers";
import { DeleteOutlined } from "@ant-design/icons";
import SelectedUsersForBranchModal from "./SelectedUsersForBranchModal";
import * as Yup from "yup";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import FormSelect from "../../CustomComponents/FormSelect";
import WithPermission from "../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";



const { Title, Text } = Typography;

const CreateBranch = () => {
    const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
    const branches = useSelector((state: RootState) => state.branch.branches);
    const formikRef = useRef<any>(null);
    const { initialValues } = useFormData<CreateBranchDto>(
        'branch/register',
        { isActive: true } as CreateBranchDto
    );
    const [branchUsers, setBranchUsers] = useState<BranchUsers[]>([]);
    const [selectedUsersModal, setSelectedUsersModal] = useState<boolean>(false);
    const gridRef = useRef<AgGridReact<any> | null>(null);
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };

    const t = useAppTranslation("ClientDashboard.CreateBranch");

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const onFinish = async (values: CreateBranchDto) => {
        dispatch(setLoadingState(true));

        await apiService
            .post("/api/Branches", values)
            .then((response) => {
                toast.success(t("branchCreatedSuccessfully"));
                db.deleteTab('branch/register');
                dispatch(getBranchesSuccess([]))
                navigate("/branches");
            })
            .catch(() => { })
            .finally(() => {
                dispatch(setLoadingState(false));
            });
    };
    const onGridReady = (params: any) => {
    };
    const [colDefs, setColDefs] = useState([
        {
            field: "index",
            headerName: "No.",
            width: 80,
            cellRenderer: (params: any) => {
                const rowIndex = params.node.rowIndex + 1;
                return <Text type="secondary">{rowIndex}</Text>;
            },
        },
        { field: "fullName", headerName: t("tableHeaders.fullName") },
        {
            field: "options",
            headerName: t("tableHeaders.options"),
            cellRenderer: (params) => (
                <WithPermission
                    menuOptionId={MenuOptionEnum.BranchesCreate}
                    children={
                        <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => null}
                            style={{ color: "#007FFF" }}
                        ></Button>
                    }
                />
            ),
            width: 100,
        },
    ]);

    const validationSchema = Yup.object({
        description: Yup.string().required(t("descriptionRequired")),
        fiscalCode: Yup.string().required(t("fiscalCodeRequired")),
        importItemPricesBranchId: Yup.number().required(t("importItemPricesBranchRequired"))
    });

    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm() || {};
        setTouched({
            description: true,
            shortDescription: true,
            fiscalCode: true,
            isActive: true,
        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    };

    useEffect(() => {
        dispatch(getBranchesAsync())

    }, [])
    return (
        <Spin tip="Loading..." spinning={loadScreen}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={initialValues ?? { isActive: true } as CreateBranchDto}
                onSubmit={onFinish}
                enableReinitialize
                validationSchema={validationSchema}

            >
                {({ values, handleSubmit, submitForm, setTouched, validateForm }) => (
                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                        style={{ marginTop: 20 }}
                    >
                        <FormHeaderOptions
                            title={t("formTitle")}
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("createBranchButton")}
                            submitButtonIsDisabled={isLoading}
                            createAccessEnum={MenuOptionEnum.BranchesCreate}
                        />
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <FormInput
                                    name="description"
                                    label={t("descriptionInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("descriptionInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.description}
                                />
                                <FormInput
                                    name="shortDescription"
                                    label={t("shortDescriptionInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("shortDescriptionInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.shortDescription}
                                />
                                <FormSwitch
                                    name="isActive"
                                    label={t("isActiveInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("isActiveInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.isActive}
                                />

                            </Col>
                            <Col xs={24} sm={12}>
                                <FormInput
                                    name="fiscalCode"
                                    label={t("fiscalCodeInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("fiscalCodeInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.fiscalCode}
                                />
                                <FormSelect
                                    name="importItemPricesBranchId"
                                    label={t("importItemPricesBranchIdInputLabel")}
                                    value={values.importItemPricesBranchId}
                                    required={true}
                                    options={branches?.map((branches) => {
                                        return {
                                            key: branches.branchId,
                                            label: branches.description,
                                            value: branches.branchId
                                        };
                                    }) ?? []
                                    }
                                />
                            </Col>
                        </Row>
                        <HandleFormDataForTabSaving tabPath="branch/register" />
                    </Form>
                )}
            </Formik>
            <SelectedUsersForBranchModal selectedUsers={branchUsers} selectedUsersModal={selectedUsersModal} setSelectedUsersModal={setSelectedUsersModal} />
        </Spin>
    );
};

export default CreateBranch;