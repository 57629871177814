import { ColDef } from "ag-grid-community";
import { CreateOrUpdateFinInvoiceRegistrationVat } from "../../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationVat";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export const getFinInvoiceRegistrationVatColumns = (
  t: (text: string) => string,
  setFinInvoiceRegistrationVats: (values: CreateOrUpdateFinInvoiceRegistrationVat[]) => void,
  finInvoiceRegistrationVats: CreateOrUpdateFinInvoiceRegistrationVat[],
  mode: string
) => {
  const columnDefs: ColDef<CreateOrUpdateFinInvoiceRegistrationVat>[] = [
    {
      headerName: t("tableHeaders.vat"),
      field: "vat",
      editable: false,
      singleClickEdit: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.invoiceAmount"),
      field: "invoiceAmount",
      editable: true,
      singleClickEdit: true,
      sortable: false,
      filter: false,
      valueSetter: (params) => {
        if (params?.node) {
           const invoiceAmount = parseFloat(params.newValue);
          params.data.invoiceAmount = invoiceAmount;
          params.api.refreshCells({
            rowNodes: [params.node],
            force: true,
          });
            const newValues = [...finInvoiceRegistrationVats];
            const vat = newValues.find((vat)=>vat.vat === params.data.vat)
            if(vat)
            vat.invoiceAmount= Number(invoiceAmount);
          setFinInvoiceRegistrationVats(newValues);
          return true;
        }
        return false;
      },
      suppressMovable: true,
      suppressMenu: true,
    },
  ];
  return columnDefs;
};
