import { Col, Form, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { useNavigate, useParams } from "react-router-dom";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useEffect, useRef, useState } from "react";
import { Currency } from "../../../models/clientDashboard/Currencies/Currency";
import { setLoading } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { Brand } from "../../../models/clientDashboard/Brand/Brand";
import { Formik } from "formik";
import FormInput from "../../CustomComponents/FormInput";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import FormSwitch from "../../CustomComponents/FormSwitch";
import {
  getCurrencyDataById,
  getCurrenciesSuccess,
  getEFiscalCurrencies,
} from "../../../redux/slices/currencySlice";
import useFormData from "../../../customHooks/useFormData";
import { CurrenciesDto } from "../../../models/clientDashboard/Currencies/CurrenciesDto";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import { db } from "../../../indexDB/clientSideDatabase";
import * as Yup from "yup";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import FormSelect from "../../CustomComponents/FormSelect";
import { EFiscalCurrenciesDto } from "../../../models/clientDashboard/Currencies/EfiscalCurrenciesDto";

const { Title, Text } = Typography;

const UpdateCurrency = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const { id } = useParams();
  const t = useAppTranslation("ClientDashboard.UpdateCurrency");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<any>(null);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const [fiscalType, setFiscalTypes] = useState<EFiscalCurrenciesDto[]>([]);
  const validationSchema = Yup.object({
    currencyCode: Yup.string().required(t("currencyCodeRequired")),
    currencyName: Yup.string().required(t("currencyNameRequired")),
    exchangeRate: Yup.number()
      .required(t("exchangeRateRequired"))
      .min(0, t("exchangeRatePositive")),
  });

  const onFinish = async (values: Currency) => {
    dispatch(setLoading(true));

    await apiService
      .put("/api/Currencies", values)
      .then((response) => {
        toast.success(t("currencyUpdatedSuccessfully"));
        db.deleteTab(`currency/update/${id}`);
        dispatch(getCurrenciesSuccess([]));
        navigate("/currencies");
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const { initialValues } = useFormData<Currency>(
    `currency/update/${id}`,
    { status: true } as Currency,
    undefined,
    {
      fetchData: async () => {
        const currencyAction = await dispatch(
          getCurrencyDataById(parseInt(id ?? "0"))
        );
        const currency = currencyAction.payload as Currency;
        return currency;
      },
    }
  );
  const getEFiscalTypeOptions = async () => {
    const response = await dispatch(getEFiscalCurrencies());
    if (response.type === "EFiscalCurrencies/getEFiscalCurrencies/fulfilled") {
      setFiscalTypes(response.payload as EFiscalCurrenciesDto[]);
    }
  };

  useEffect(() => {
    getEFiscalTypeOptions();
  });

  const handleSubmitValidationForm = async (
    setTouched: ({}: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = (await validateForm()) || {};
    setTouched({
      currencyCode: true,
      currencyName: true,
      currencySymbol: true,
      exchangeRate: true,
      fiscalType: true,
      status: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };

  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({} as Currency)}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, handleSubmit, submitForm, setTouched, validateForm }) => (
          <>
            <FormHeaderOptions
              title={t("formTitle")}
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("updateCurrencyButton")}
              submitButtonIsDisabled={isLoading}
              createAccessEnum={MenuOptionEnum.CurrenciesCreate}
            />
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              style={{ marginTop: 20 }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <FormInput
                    name="currencyCode"
                    label={t("currencyCodeInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("currencyCodeInputPlaceholder"),
                      },
                    ]}
                    value={values.currencyCode}
                  />
                  <FormInput
                    name="currencyName"
                    label={t("currencyNameInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("currencyNameInputPlaceholder"),
                      },
                    ]}
                    value={values.currencyName}
                  />
                  <FormInput
                    name="currencySymbol"
                    label={t("currencySymbolInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("currencySymbolInputPlaceholder"),
                      },
                    ]}
                    value={values.currencySymbol}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <FormInput
                    name="exchangeRate"
                    type="number"
                    label={t("exchangeRateInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("exchangeRateInputPlaceholder"),
                      },
                    ]}
                    value={values.exchangeRate}
                  />
                  <FormSelect
                    name="fiscalType"
                    label={t("fiscalTypeInputLabel")}
                    required={true}
                    value={values.fiscalType}
                    options={
                      fiscalType?.map((fiscalType: any) => {
                        return {
                          key: fiscalType.eFiscalCurrencyId,
                          label: fiscalType.currencyName,
                          value: fiscalType.eFiscalCurrencyId,
                        };
                      }) ?? []
                    }
                  />
                  <FormSwitch
                    className="switch-for-currency"
                    name="status"
                    label={t("statusInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("statusInputPlaceholder"),
                      },
                    ]}
                    value={values.status}
                  />
                </Col>
              </Row>
              <HandleFormDataForTabSaving tabPath={`currency/update/${id}`} />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
};

export default UpdateCurrency;
