import { Button, Popconfirm, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import {
  formatDate,
  formatDateWithTime,
} from "../../../../helperMethods/DateFormat";
import { db } from "../../../../indexDB/clientSideDatabase";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import {
  getSaleFilterColumnsAsync,
  getSalesWithFilters,
} from "../../../../redux/slices/salesSlice";
import { FilterData } from "../../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../../customHooks/useFilter";
import { exportDataToCsv } from "../../../../models/helper/exportDataToCsv";
import { renderFilterInputs } from "../../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../../CustomComponents/FilterModal";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import WithPermission from "../../../Authorization/WithPermission";
import { usePermission } from "../../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;
export default function Sales() {
  const sale = useSelector((state: RootState) => state.sale.sales);
  const t = useAppTranslation("ClientDashboard.Sales");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef[]>([
    {
      field: "saleId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return (
          <Text className="ag-grid-id-column">{params?.data?.saleId}</Text>
        );
      },
    },
    {
      field: "documentNo",
      headerName: t("tableHeaders.documentNo"),
      suppressMenu: true,
    },
    {
      field: "documentType",
      headerName: t("tableHeaders.documentTypeId"),
      suppressMenu: true,
    },
    {
      field: "correctedSaleId",
      headerName: t("tableHeaders.correctedSaleId"),
      suppressMenu: true,
    },
    {
      field: "validated",
      headerName: t("tableHeaders.validated"),
      suppressMenu: true,
    },
    {
      field: "date",
      headerName: t("tableHeaders.date"),
      valueFormatter: formatDate,
      suppressMenu: true,
      width: 120,
    },
    // { field: "invoiceDate", headerName: t("tableHeaders.invoiceDate"), valueFormatter: formatDate, suppressMenu: true },
    {
      field: "comment",
      headerName: t("tableHeaders.comment"),
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.SaleInvoiceCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateSale"),
                  `sale/update/${params?.data?.saleId}/${params.data.branchId}`,
                  undefined
                );
                navigate(
                  `/sales/update/${params?.data?.saleId}/${params.data.branchId}`
                );
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 100,
      suppressMenu: true,
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.api.autoSizeColumns(["branchName"]);
    params.api.autoSizeColumns(["documentNo"]);
    params.api.autoSizeColumns(["documentTypeId"]);
    params.api.autoSizeColumns(["correctedSaleId"]);
    params.api.autoSizeColumns(["validated"]);
    params.api.autoSizeColumns(["date"]);
    params.api.autoSizeColumns(["toDate"]);
    // params.api.autoSizeColumns(['invoiceDate']);
    params.api.autoSizeColumns(["fromDate"]);
    params.api.autoSizeColumns(["deliveryDeadline"]);
    params.api.autoSizeColumns(["currencyName"]);
    params.api.autoSizeColumns(["exchangeRate"]);
    params.api.autoSizeColumns(["totalDiscount"]);
    params.api.autoSizeColumns(["palleteCount"]);
    params.api.autoSizeColumns(["paymentDeadline"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);
  const getSales = async () => {
    const result = await dispatch(getSalesWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    getSales();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.SaleInvoiceCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateSale"),
        `sales/update/${event?.data?.saleId}/${event.data.branchId}`,
        undefined
      );
      navigate(`/sales/update/${event?.data?.saleId}/${event.data.branchId}`);
    };
  };
  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;

    const result = await dispatch(getSalesWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getSaleFilterColumnsAsync());
    return result;
  };
  const handleClearFilters = async () => {
    const result = await dispatch(getSalesWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewSale = async () => {
    navigate("/sales/register");
    const existingTab = await db.tabs.get("sales/register");
    if (!existingTab) {
      db.addTab(t("tabs.createSale"), "sales/register", undefined);
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "saleId",
      "branchName",
      "documentNo",
      "documentTypeId",
      "correctedSaleId",
      "validated",
      "date",
      "toDate",
      "invoiceDate",
      "fromDate",
      "deliveryDeadline",
      "currencyName",
      "exchangeRate",
      "totalDiscount",
      "palleteCount",
      "paymentDeadline",
      "comment",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.sales")}
        createRecord={handleCreateNewSale}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.SaleInvoiceCreate}
        exportAccessEnum={MenuOptionEnum.SaleInvoiceExport}
      />
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState?.filterInputs?.map((input) => (
          <div
            key={input.name}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Text>
              {input.isClassifier
                ? input.name
                : t(`FilterColumns.${input.name}`)}
            </Text>
            {renderFilterInputs({ input: input, filterState: filterState })}
          </div>
        ))}
      </div>
      {filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button onClick={filterState.handleClearFilters}>
            {t("clearFilters")}
          </Button>
          <Button type="primary" onClick={filterState.handleFilterSubmit}>
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={sale}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.Sales"
      />
    </>
  );
}
