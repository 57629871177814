export default function NewTrashIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5H17.5"
        stroke="#A4A4A4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8334 5V16.6667C15.8334 17.5 15.0001 18.3333 14.1667 18.3333H5.83341C5.00008 18.3333 4.16675 17.5 4.16675 16.6667V5"
        stroke="#A4A4A4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 4.99935V3.33268C6.66675 2.49935 7.50008 1.66602 8.33341 1.66602H11.6667C12.5001 1.66602 13.3334 2.49935 13.3334 3.33268V4.99935"
        stroke="#A4A4A4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33325 9.16602V14.166"
        stroke="#A4A4A4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 9.16602V14.166"
        stroke="#A4A4A4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
