import { useEffect, useState } from "react";
import {
    Form,
    Input,
    Button,
    Spin,
    Row,
    Col,
    Popconfirm,
} from "antd";
import { Typography } from "antd";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../../extensions/api";
import { useDispatch, useSelector } from "react-redux";
import {
    getCustomDataById,
} from "../../../redux/slices/customSlice";
import {
    setLoading,
} from "../../../redux/slices/loadingSlice";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { Custom } from "../../../models/clientDashboard/Custom/Custom";
import { AppDispatch, RootState } from "../../../redux";
import FormInput from "../../CustomComponents/FormInput";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import { Formik } from "formik";
import WithPermission from "../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";

const { Title, Text } = Typography;

const UpdateCustom = () => {
    const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
    const { id } = useParams();
    const t = useAppTranslation("ClientDashboard.UpdateCustom");
    const [custom, setCustom] = useState<Custom>({
        customId: 0,
        description: "",
        value: 0
    });
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const onFinish = async (values: Custom) => {
        dispatch(setLoading(true));

        await apiService
            .put("/api/Customs", values)
            .then((response) => {
                toast.success(t("customUpdatedSuccessfully"));
                navigate("/customs");
            })
            .catch(() => { })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    useEffect(() => {
        const fetchCustomData = async () => {
            const customAction = await dispatch(getCustomDataById(parseInt(id ?? "0")));
            const custom = customAction.payload as Custom;
            if (custom)
                setCustom(custom);
            else
                navigate("/customs");
        };

        fetchCustomData();
    }, [id, dispatch]);


    return (
        <Spin tip="Loading..." spinning={loadScreen}>
            <Title level={3}>{t("formTitle")}</Title>
            <Text italic>{t("formSubtitle")}</Text>
            <Formik
                initialValues={custom}
                onSubmit={onFinish}
                enableReinitialize
            >
                {({
                    values,
                    handleSubmit,
                    submitForm
                }) => (
                    <Form
                        layout="vertical"
                        initialValues={custom}
                        onFinish={handleSubmit}
                        style={{ marginTop: 20 }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <FormInput
                                    name="description"
                                    label={t("descriptionInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("descriptionInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.description}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <FormInput
                                    name="value"
                                    label={t("valueInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("valueInputPlaceholder"),
                                        },
                                    ]}
                                    type="number"
                                    value={values.value}
                                />

                            </Col>
                        </Row>

                        <WithPermission
                            menuOptionId={MenuOptionEnum.ClassifiersCreate}
                            children={
                                <FormSubmitButton
                                    handleValidate={undefined}
                                    handleConfirm={submitForm}
                                    buttonText={t("updateCustomButton")}
                                />
                            }
                        />
                    </Form>)}
            </Formik>
        </Spin>
    );
};

export default UpdateCustom;