import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LanguageState } from "../../models/clientDashboard/Language/LanguageState";
import { Language } from "../../models/clientDashboard/Language/Language";
import { setLoading, setLoadingState } from "./loadingSlice";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";

const initialState: LanguageState = {
    languages: [],
    error: null,
};

const LanguageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        getLanguagesStart(state: LanguageState) {
            setLoading(true);
            state.error = null;
        },
        getLanguagesSuccess(
            state: LanguageState,
            action: PayloadAction<Language[]>
        ) {
            setLoading(false);
            state.languages = action.payload;
        },
        getLanguagesFailure(
            state: LanguageState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
       
    }
});

export const getLanguagesAsync = createAsyncThunk(
    "Languages/getLanguages",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const languages = state.language.languages;

        if (languages.length > 0) {
            return languages;
        }

        try {
            dispatch(getLanguagesStart());
            const response = await apiService.get<ApiResponse<Language[]>>(
                "api/configuration/systemLanguages"
            );
            dispatch(getLanguagesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getLanguagesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getLanguageDataById = createAsyncThunk(
    "Languages/getTaxDataById",
    async (taxId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<Language>>(
                `/api/configuration/systemLanguages/${taxId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getLanguagesStart,
    getLanguagesSuccess,
    getLanguagesFailure,
} = LanguageSlice.actions;

export default LanguageSlice.reducer;