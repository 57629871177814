import Typography from "antd/es/typography";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { FinInvoiceRegistration } from "../../../models/clientDashboard/FinInvoiceRegistrations/FinInvoiceRegistration";
import { formatDate } from "../../../helperMethods/DateFormat";
import WithPermission from "../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import { Button } from "antd";
import RightOutlined from "@ant-design/icons/RightOutlined";
import { db } from "../../../indexDB/clientSideDatabase";
import { getFinInvoiceRegistrationColumnsAsync, getFinInvoiceRegistrationWithFilters } from "../../../redux/slices/finInvoiceRegistrationSlice";
import { usePermission } from "../../../customHooks/usePermission";
import { FilterData } from "../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../customHooks/useFilter";
import { exportDataToCsv } from "../../../models/helper/exportDataToCsv";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { renderFilterInputs } from "../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../CustomComponents/FilterModal";

const { Title, Text, Link } = Typography;
export default function FinInvoiceRegistrations() {
  const finInvoiceRegistrations = useSelector(
    (state: RootState) => state.finInvoiceRegistration.finInvoiceRegistrations
  );
  const t = useAppTranslation("ClientDashboard.FinInvoiceRegistrations");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef<FinInvoiceRegistration>[]>([
    {
      field: "finInvoiceRegistrationId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.finInvoiceRegistrationId}</Text>;
      },
    },
    {field:"branchDescription",headerName:t("tableHeaders.branch"),suppressMenu:true},
    { field: "entityName", headerName: t("tableHeaders.entityName"), suppressMenu: true, width: 250 },
    { field: "paymentDeadline", headerName: t("tableHeaders.paymentDeadline"), suppressMenu: true },
    { field: "documentNo", headerName: t("tableHeaders.documentNo"), suppressMenu: true },
    { field: "invoiceNo", headerName: t("tableHeaders.invoiceNo"), suppressMenu: true },
    { field: "invoiceDate", headerName: t("tableHeaders.invoiceDate"), suppressMenu: true },
    { field: "date", headerName: t("tableHeaders.date"), valueFormatter: formatDate, suppressMenu: true },
    { field: "comment", headerName: t("tableHeaders.comment"), suppressMenu: true },
    { field: "fromDate", headerName: t("tableHeaders.fromDate"), valueFormatter: formatDate, suppressMenu: true },
    { field: "toDate", headerName: t("tableHeaders.toDate"), valueFormatter: formatDate, suppressMenu: true },
    { field: "validated", headerName: t("tableHeaders.validated"), suppressMenu: true },
    { field: "exchangeRate", headerName: t("tableHeaders.exchangeRate"), suppressMenu: true, width: 120 },
    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.EntryOrderCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateFinInvoiceRegistration"),
                  `finInvoiceRegistration/update/${params?.data?.finInvoiceRegistrationId}`,
                  undefined
                );
                navigate(
                  `/finInvoiceRegistration/update/${params?.data?.finInvoiceRegistrationId}/${params.data.branchId}`
                );
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 90,
      suppressMenu: true,
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["branchDescription"]);
    params.columnApi.autoSizeColumns(["entityName"]);
    params.columnApi.autoSizeColumns(["paymentDeadline"]);
    params.columnApi.autoSizeColumns(["documentNo"]);
    params.columnApi.autoSizeColumns(["invoiceNo"]);
    params.columnApi.autoSizeColumns(["invoiceDate"]);
    params.columnApi.autoSizeColumns(["comment"]);
    params.columnApi.autoSizeColumns(["fromDate"]);
    params.columnApi.autoSizeColumns(["toDate"]);
    params.columnApi.autoSizeColumns(["validated"]);
    params.columnApi.autoSizeColumns(["exchangeRate"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);

  const getFinInvoiceRegistrations = async () => {
    const result = await dispatch(getFinInvoiceRegistrationWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    getFinInvoiceRegistrations();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.EntryOrderCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(t("tabs.updateFinInvoiceRegistration"), `finInvoiceRegistration/update/${event?.data?.finInvoiceRegistrationId}/${event.data.branchId}`, undefined)
      navigate(`/finInvoiceRegistration/update/${event?.data?.finInvoiceRegistrationId}/${event.data.branchId}`)
    };
  };
  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;
    const result = await dispatch(getFinInvoiceRegistrationWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getFinInvoiceRegistrationColumnsAsync());
    if (
      result.type === "FinInvoiceRegistrations/getFinInvoiceRegistrationColumnsAsync/fulfilled"
    ) {
      return result;
    } else {
      return null;
    }
  };
  const handleClearFilters = async () => {
    const result = await dispatch(getFinInvoiceRegistrationWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewFinInvoiceRegistration = async () => {
    navigate("/finInvoiceRegistration/register");
    const existingTab = await db.tabs.get("finInvoiceRegistration/normal/register");
    if (!existingTab) {
      db.addTab(
        t("tabs.createFinInvoiceRegistration"),
        "finInvoiceRegistration/register",
        undefined
      );
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "finInvoiceRegistrationId",
      "branchDescription",
      "entityName",
      "paymentDeadline",
      "documentNo",
      "invoiceNo",
      "invoiceDate",
      "date",
      "comment",
      "fromDate",
      "toDate",
      "validated",
      "exchangeRate",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.finInvoiceRegistrations")}
        createRecord={handleCreateNewFinInvoiceRegistration}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.EntryOrderCreate}
        exportAccessEnum={MenuOptionEnum.EntryOrderExport}
      />
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState &&
          filterState?.filterInputs?.map((input) => (
            <div
              key={input.name}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Text>
                {input.isClassifier
                  ? input.name
                  : t(`FilterColumns.${input.name}`)}
              </Text>
              {renderFilterInputs({ input: input, filterState: filterState })}
            </div>
          ))}
      </div>
      {filterState && filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button onClick={filterState?.handleClearFilters}>
            {t("clearFilters")}
          </Button>
          <Button type="primary" onClick={filterState?.handleFilterSubmit}>
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={finInvoiceRegistrations}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.FinInvoiceRegistrations"
      />
    </>
  );
}