let config: { [key: string]: any } | null = null;

(function loadConfigFile() {
  const request = new XMLHttpRequest();
  request.open("GET", `${process.env.PUBLIC_URL}/config.json`, false);
  request.send(null);

  if (request.status === 200) {
    try {
      config = JSON.parse(request.responseText);
    } catch (error) {
      console.error("Failed to parse config.json", error);
    }
  } else {
    console.error("Failed to load config.json");
  }
})();

export const CONFIG = config;