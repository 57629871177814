import { Modal } from "antd"
import { BranchUsers } from "../../../models/clientDashboard/BranchUsers/BranchUsers"
import { RootState } from "../../../redux"
import { useEffect, useState } from "react";

interface Props{
    selectedUsers:BranchUsers[],
    selectedUsersModal:boolean,
    setSelectedUsersModal:(value:boolean)=>void
}

export default function SelectedUsersForBranchModal({selectedUsers,selectedUsersModal,setSelectedUsersModal}:Props){
    const [users,setUsers] = useState<any[]>([]);

    useEffect(()=>{

    },[])


    return(
        <Modal open={selectedUsersModal} >
            
        </Modal>
    )
}