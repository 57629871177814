import { DatePicker, Input, Select, Switch } from "antd";
import { PropertyDto } from "../models/clientDashboard/Filter/PropertyDto";

const { Option } = Select;
interface Props {
  input: PropertyDto;
  filterState: any;
}
export const renderFilterInputs = ({ input, filterState }: Props) => {
  switch (input?.type) {
    case 'string':
      return <Input placeholder={`Enter ${input.name}`} onChange={e => filterState?.handleInputChange(input.name, e.target.value)} />;
    case 'int':
      return <Input type="number" pattern="\d*" inputMode="numeric" placeholder={`Enter ${input.name}`} onChange={e => filterState?.handleInputChange(input.name, e.target.value)} />;
    case 'decimal':
    case 'long':
      return <Input type="number" placeholder={`Enter ${input.name}`} onChange={e => filterState?.handleInputChange(input.name, e.target.value)} />;
    case 'bool':
      case 'boolean':
      return <Switch onChange={checked => filterState?.handleInputChange(input.name,checked===true ? 1 : 0 )}
      style={{ width: 20 }}
      />      
    case 'select':
      return (
        <Select placeholder={`Select ${input.name}`} onChange={value => filterState?.handleInputChange(input.name, value)}>
          {input?.values?.map(value => (
            <Option key={value.value} value={value.value}>
              {value.label}
            </Option>
          ))}
        </Select>
      );
    case 'dateTime':
    case 'datetime':
      return <DatePicker placeholder={`Select ${input.name}`} onChange={(_, dateString) => filterState?.handleInputChange(input.name, dateString)} />;
    default:
      return null;
  }
};