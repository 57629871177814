import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { t as t_server } from "i18next";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { CreateOrUpdateFinancialAccountsDto } from "../../../models/clientDashboard/FinancialAccounts/CreateOrUpdateFinancialAccountsDto";
import { Button, Modal, Popconfirm, Select, Spin, Typography } from "antd";
import {
  getFinAccountClassesAync,
  getFinancialAccountAsync,
  getFinancialAccountsWithChildrenAsync,
} from "../../../redux/slices/financialAccountsSlice";
import apiService from "../../../extensions/api";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import { CheckOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import FormSwitch from "../../CustomComponents/FormSwitch";
import _ from "lodash";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { handleNextGridColumnClick } from "../../../helperMethods/handleNextGridColumnClick";
import { FinancialAccount } from "../../../models/clientDashboard/FinancialAccounts/FinancialAccounts";
import { Tree } from "antd";
import type { TreeDataNode, TreeProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { db } from "../../../indexDB/clientSideDatabase";
import CreateFinancialFSNotesLines from "./FinancialFsNotesLines/CreateFinancialFSNotesLines";
import { CreateOrUpdateFinancialFSNotesLineDto } from "../../../models/clientDashboard/FinancialAccounts/FinancialFSNotesLine/CreateOrUpdateFinancialFSNotesLineDto";
import { toast } from "react-toastify";
import {
  getFinancialFSNotesLineDataType,
  getFinancialFSNotesLinesAsync,
} from "../../../redux/slices/financialFSNotesLinesSlice";
import { createSelector } from "@reduxjs/toolkit";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import WithPermission from "../../Authorization/WithPermission";
import { FinancialAccountClassDto } from "../../../models/clientDashboard/FinancialAccounts/FinancialAccountClassDto";

const { Text } = Typography;

const FinancialAccounts = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const t = useAppTranslation("ClientDashboard.FinancialAccounts");
  const gridRef = useRef<AgGridReact | null>(null);
  const [parents, setParents] = useState([]);

  const [financialAccounts, setFinancialAccounts] = useState<
    FinancialAccount[]
  >([]);
  const [fullFinancialAccounts, setFullFinancialAccounts] = useState<
    FinancialAccount[]
  >([]);
  const [financialAccountsClasses, setfinancialAccountsClasses] = useState<
    FinancialAccountClassDto[]
  >([]);
  const [editMode, setEditMode] = useState<number | null>(null);
  const [treeDataNode, setTreeDataNode] = useState<TreeDataNode[]>([]);
  const [selectedFinancialAccount, setSelectedFinancialAccount] = useState<
    number | null
  >(null);
  const [financialFSNotesLinesDetails, setFinancialFSNotesLinesDetails] =
    useState<CreateOrUpdateFinancialFSNotesLineDto[]>([]);

  const finAccountClassTypes = useSelector(
    (state: RootState) => state.financialAccounts.financialAccountsClass
  );
  const fetchFinancialFSNotesLineData = async () => {
    try {
      const data = await dispatch(getFinancialFSNotesLinesAsync()).unwrap();
      setFinancialFSNotesLinesDetails(data);
    } catch (error) {
      toast.error(t("errorFetchingData"));
    }
  };


  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setIsModalVisible(false);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const showModal = () => setIsModalVisible(true);


  const getFullFinancialAccounts = async () => {
    const response = await dispatch(getFinancialAccountAsync());
    if (response.type === "financialAccounts/getFinancialAccounts/fulfilled") {
      var accounts = response.payload as FinancialAccount[];

      setFullFinancialAccounts(accounts);
    }
  };
  const getfinancialAccountsClasses = async () => {
    const response = await dispatch(getFinAccountClassesAync());
    if (response.type === "finAccountClasses/getFinAccountClasses/fulfilled") {
      var accounts = response.payload as FinancialAccountClassDto[];

      setfinancialAccountsClasses(accounts);
    }
  };

  const getFinancialAccounts = async () => {
    const response = await dispatch(getFinancialAccountsWithChildrenAsync());
    if (
      response.type ===
      "financialAccounts/GetFinancialAccountsWithChilds/fulfilled"
    ) {
      var accounts = response.payload as FinancialAccount[];

      var accountsWithChilds = mapToTreeData(accounts);

      setTreeDataNode(accountsWithChilds);

      setFinancialAccounts(response.payload as FinancialAccount[]);
    }
  };

  function mapToTreeData(accounts: FinancialAccount[]): TreeDataNode[] {
    return accounts.map((account) => ({
      title: account.description,
      key: account?.financialAccountId?.toString(),
      children: account.children ? mapToTreeData(account.children) : [],
    })) as TreeDataNode[];
  }

  useEffect(() => {
    getFullFinancialAccounts();
    getFinancialAccounts();
    getfinancialAccountsClasses();
    fetchFinancialFSNotesLineData();
  }, []);

  const handleAddFinancialAccounts = () => {
    const alreadyCreatedAccountIndex = fullFinancialAccounts.findIndex(
      (account) => account.financialAccountId === 0
    );
    const alreadyCreatedAccount = fullFinancialAccounts.find(
      (account) => account.financialAccountId === 0
    );
  
    console.log(alreadyCreatedAccountIndex);
  
    if (alreadyCreatedAccountIndex !== -1) {
      setSelectedFinancialAccount(alreadyCreatedAccount?.parentId ?? null);
      setEditMode(alreadyCreatedAccountIndex);
      return;
    }
  
    const newFinancialAccount: FinancialAccount = {
      financialAccountId: 0,
      code: "-1",
      description: "",
      serverDescription: "",
      type: "",
      class: "",
      parentId: selectedFinancialAccount ?? null,
      receivable: false,
      payable: false,
      fsline: 0,
      fsnote: 0,
      postingToGl: false,
      children: [],
    };
    const updatedFinancialAccounts = [...fullFinancialAccounts, newFinancialAccount];
  
    const groupedAccounts = updatedFinancialAccounts.filter(
      (account) => account.parentId === selectedFinancialAccount
    );
  
    const newAccountIndexInGroup = groupedAccounts.length - 1;
  
    const newAccountIndex = updatedFinancialAccounts.findIndex(
      (account) =>
        account.financialAccountId === 0 && account.parentId === selectedFinancialAccount
    );
  
    setFullFinancialAccounts(updatedFinancialAccounts);
    setEditMode(newAccountIndex);
  };
  

  const handleCheckButtonClick = async (params: any) => {
    const data = params.data;
    const updatedFinancialAccounts = {
      financialAccountId: data.financialAccountId,
      code: data.code,
      description: data.description,
      serverDescription: data.serverDescription,
      type: data.type,
      class: data.class,
      entryDate: data.entryDate,
      parentId: data.parentId,
      receivable: data.receivable,
      payable: data.payable,
      fsLine: data.fsLine,
      fsNote: data.fsNote,
      postingToGL: data.postingToGL,
    };

    try {
      await apiService.post("/api/FinancialAccounts", updatedFinancialAccounts);
      await getFinancialAccounts();
      setEditMode(null);
      params.api.refreshCells({
        rowNodes: [params.node],
        force: true,
      });
    } catch (error) {
      console.error("Error creating document period:", error);
      await getFinancialAccounts();
      setEditMode(null);
      params.api.refreshCells({
        rowNodes: [params.node],
        force: true,
      });
    }
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const parentOptions = useMemo(() => {
    return parents.map((parent) => ({
      key: parent,
      label: parent,
      value: parent,
    }));
  }, [parents]);
  const handleItemTypeIdChange = async (value: number, params: any) => {
    params.data.itemTypeId = value;
    try {
      await apiService.post("/api/FinancialAccounts", {
        financialAccountId: params.data.financialAccountId,
        itemTypeId: value,
      });
    } catch (error) {
      console.error("Error updating itemTypeId:", error);
    }

    params.api.refreshCells({
      rowNodes: [params.node],
      force: true,
    });
  };

  const notes = useMemo(() => {
    return financialFSNotesLinesDetails
      .filter((obj) => obj.type.toLowerCase() === "notes")
      .map((financialFSNotesLine) => ({
        key: financialFSNotesLine?.financialFSNotesLinesId,
        label: financialFSNotesLine.description,
        value: financialFSNotesLine?.code,
      }));
  }, [financialAccounts]);

  const lines = useMemo(() => {
    return financialFSNotesLinesDetails
      .filter((obj) => obj.type.toLowerCase() === "lines")
      .map((financialFSNotesLine) => ({
        key: financialFSNotesLine?.financialFSNotesLinesId,
        label: financialFSNotesLine.description,
        value: financialFSNotesLine?.code,
      }));
  }, [financialAccounts]);

  const finAccountClassTypesOptions = useMemo(() => {
    return finAccountClassTypes.map((finAccountClassType) => ({
      key: finAccountClassType.financialAccountsClassId,
      label: t_server(finAccountClassType.serverDescription.trim()),
      value: finAccountClassType.description,
    }));
  }, [finAccountClassTypes]);

  const colDefs: ColDef<CreateOrUpdateFinancialAccountsDto>[] = [
    {
      field: "financialAccountId",
      headerName: "ID",
      suppressMenu: true,
      editable: true,
      cellRenderer: (params: any) => (
        <Text className="ag-grid-id-column">
          {params?.data?.financialAccountId}
        </Text>
      ),
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      suppressMenu: true,
      editable:(params:any)=>{return params.data.financialAccountId === 0 || editMode === params.node.rowIndex},
    },
    {
      field: "class",
      suppressHeaderMenuButton: true,
      headerName: t("tableHeaders.class"),
      editable: false,
      cellRenderer: (params: any) => {
        const isEditing = editMode === params.node.rowIndex;
        if (params.data.financialAccountId === 0 || isEditing) {
          return (
            <Select
              style={{ width: "100%" }}
              value={params.data.class}
              className="import-select-with-search"
              showSearch={true}
              onChange={(value) => {
                params.data.class = value;
                params.api.refreshCells({
                  rowNodes: [params.node],
                  force: true,
                });
              }}
              options={finAccountClassTypesOptions}
              allowClear={true}
            />
          );
        }
        return finAccountClassTypesOptions.find(
          (option) => option.value === params.value
        )?.label;
      },
    },

    {
      field: "payable",
      headerName: t("tableHeaders.payable"),
      suppressMenu: true,
      editable: true,
    },
    {
      field: "receivable",
      headerName: t("tableHeaders.receivable"),
      suppressMenu: true,
      editable: true,
    },
    {
      field: "fsline",
      suppressHeaderMenuButton: true,
      headerName: t("tableHeaders.fsline"),
      editable: false,
      width: 200,
      cellRenderer: (params: any) => {
        const isEditing = editMode === params.node.rowIndex;
        if (params.data.financialAccountId === 0 || isEditing) {
          return (
            <Select
              style={{ width: "100%" }}
              value={params.data.fsline}
              className="import-select-with-search"
              showSearch={true}
              onChange={(value) => {
                params.data.fsline = value;
                params.api.refreshCells({
                  rowNodes: [params.node],
                  force: true,
                });
              }}
              options={lines}
              allowClear={true}
            />
          );
        }
        return lines.find((option) => option.value === params.value)?.label;
      },
    },

    {
      field: "fsnote",
      suppressHeaderMenuButton: true,
      headerName: t("tableHeaders.fsnote"),
      editable: false,
      width: 200,
      cellRenderer: (params: any) => {
        const isEditing = editMode === params.node.rowIndex;
        if (params.data.financialAccountId === 0 || isEditing) {
          return (
            <Select
              style={{ width: "100%" }}
              value={params.data.itemTypeId}
              className="import-select-with-search"
              showSearch={true}
              onChange={(value) => {
                params.data.itemTypeId = value;
                params.api.refreshCells({
                  rowNodes: [params.node],
                  force: true,
                });
              }}
              options={notes}
              allowClear={true}
            />
          );
        }
        return notes.find((option) => option.value === params.value)?.label;
      },
    },
    {
      field: "postingToGl",
      headerName: t("tableHeaders.postingToGl"),
      suppressMenu: true,
    },
    {
      headerName: "Actions",
      suppressHeaderMenuButton: true,
      width: 100,
      cellRenderer: (params: any) => {
        const isEditing = editMode === params.node.rowIndex;

        if (params.data.financialAccountId === 0 || isEditing) {
          return (
            <Button
              onClick={() => handleCheckButtonClick(params)}
              icon={<CheckOutlined />}
              style={{ border: "none" }}
            />
          );
        } else {
          return (
            <>
              <Button
                onClick={() => {
                  if (
                    financialAccounts.some(
                      (row) => row.financialAccountId === 0
                    )
                  ) {
                    const updatedRowData = financialAccounts.filter(
                      (row) => row.financialAccountId !== 0
                    );
                    setFinancialAccounts(updatedRowData);
                    params.api.setRowData(updatedRowData);
                  }
                  setEditMode(params.node.rowIndex);
                }}
                icon={<EditOutlined />}
                style={{ border: "none" }}
              />
              <Popconfirm
                title={t("deleteConfirmation")}
                onConfirm={() => {
                  params.data.rowAction = "D";
                  handleCheckButtonClick(params);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  icon={<DeleteOutlined />}
                  style={{ border: "none", color: "red" }}
                />
              </Popconfirm>
            </>
          );
        }
      },
    },
  ];

  const findChildrenById = (
    accountId: number,
    accounts: FinancialAccount[]
  ): FinancialAccount[] => {
    const children: FinancialAccount[] = [];

    for (const account of accounts) {
      if (account.parentId === accountId) {
        children.push(account);
      }
    }

    return children;
  };

  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    const selectedKey = selectedKeys[0];

    if (typeof selectedKey === "string") {
      setSelectedFinancialAccount(parseInt(selectedKey));
    } else {
      setSelectedFinancialAccount(null);
    }
  };

  const mutableObject = useMemo(() => {
    return _.cloneDeep(
      fullFinancialAccounts.filter(
        (item) => item.parentId === selectedFinancialAccount
      )
    );
  }, [fullFinancialAccounts, selectedFinancialAccount]);
  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "10px",
          marginBottom: 20,
        }}
      >
        <ListHeaderComponent
          title={t("titles.financialAccounts")}
          createRecord={handleAddFinancialAccounts}
          createTitle={t("create")}
          menuItemsDisabled={false}
          createAccessEnum={MenuOptionEnum.FinancialAccountsCreate}
        />
        <div style={{ marginLeft: "auto" }}>
          <Button type="primary" onClick={showModal}>
            {t("createFinancialFSNotesLines")}
          </Button>
        </div>
      </div>
      <WithPermission
        menuOptionId={MenuOptionEnum.FinancialAccountsCreate}
        children={
          <CreateFinancialFSNotesLines
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
        }
      />
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: 35,
          width: "100%",
        }}
      >
        <Tree
          showLine
          switcherIcon={<DownOutlined />}
          defaultExpandedKeys={["0-0-0"]}
          onSelect={onSelect}
          treeData={treeDataNode}
        />
        <div style={{ width: "50%" }}>
          <AgGridTableComponent
            gridRef={gridRef}
            isLoading={isLoading}
            defaultColDef={defaultColDef}
            rowData={mutableObject}
            columnDefs={colDefs}
            pagination={true}
            paginationPageSize={20}
            suppressCellFocus={true}
            stopEditingWhenCellsLoseFocus={true}
            enableCellTextSelection={true}
            singleClickEdit={true}
            onCellKeyDown={(event: CellKeyDownEvent) => {
              handleNextGridColumnClick(event);
            }}
            style={{
              marginTop: 0,
            }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default FinancialAccounts;
