import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { AgGridReact } from "ag-grid-react";
import { CellKeyDownEvent, CellValueChangedEvent, ColDef, GridApi } from "ag-grid-community";
import {
  ClassifierDetailDto,
  ImportArticleBarcodeDto,
  ImportArticleDto,
} from "../../../../models/clientDashboard/ImportExcel/ImportArticleDto";

import { useDropzone } from "react-dropzone";
import { Divider, Spin, Typography, Button, Select } from "antd";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
// import { GridApi } from "ag-grid-enterprise";
import apiService from "../../../../extensions/api";
import { ApiResponse } from "../../../../models/ApiResponse";
import ImportedArticle from "../../../../models/clientDashboard/ImportExcel/ImportedArticle";
import RequiredFields from "../../../../models/clientDashboard/ImportExcel/RequiredFields";
import { getClassifierDetailsBySearch, getClassifiersByTypeAsync } from "../../../../redux/slices/classifierSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { handleNextGridColumnClick } from "../../../../helperMethods/handleNextGridColumnClick";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { ItemModel } from "../../../../models/clientDashboard/Item/ItemModel";
import { getUnitMeasuresAsync } from "../../../../redux/slices/unitMeasureSlice";
import { getBrandsAsync } from "../../../../redux/slices/brandSlice";
import { getCustomsAsync } from "../../../../redux/slices/customSlice";
import itemStatusSlice, { getItemStatusesAsync } from "../../../../redux/slices/itemStatusSlice";
import { Root } from "react-dom/client";
import { getTaxesAsync } from "../../../../redux/slices/taxSlice";
import { getItemTypesAsync } from "../../../../redux/slices/itemTypeSlice";
import { getEntitiesAsync, getEntitiesBySearch } from "../../../../redux/slices/entitySlice";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";
import { Entity } from "../../../../models/clientDashboard/Entity/Entity";
import FormSelectWithSearchWithoutFormik from "../../../CustomComponents/FormSelectWithSearchWithoutFormik";
import { ClassifierDetail } from "../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";
import { serialize } from "v8";

const { Title } = Typography;

interface RequiredField {
  field: keyof ImportArticleDto;
  message: string;
}

const ImportArticles: React.FC = () => {
  const [rowData, setRowData] = useState<any[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const t = useAppTranslation("ClientDashboard.ImportArticles");
  const gridRef = useRef<AgGridReact | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [gridRowData, setGridRowData] = useState<any[]>(rowData);

  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );

  const units = useSelector((state: RootState) => state.unitMeasure.unitMeasures);
  const brands = useSelector((state: RootState) => state.brand.brands);
  const customs = useSelector((state: RootState) => state.custom.customs);
  const suppliers = useSelector((state: RootState) => state.entity.entities);
  const status = useSelector((state: RootState) => state.itemStatus.itemStatuses);
  const taxes = useSelector((state: RootState) => state.tax.taxes);
  const itemType = useSelector((state: RootState) => state.itemType.itemTypes);

  const [requiredFields, setRequiredFields] = useState<RequiredField[]>([
    { field: "BrandId", message: "BrandId is required" },
    { field: "UnitId", message: "UnitId is required" },
    { field: "CustomId", message: "CustomId is required" },
    { field: "TaxId", message: "TaxId is required" },
    { field: "StatusId", message: "StatusId is required" },
    { field: "PrimarySupplierID", message: "PrimarySupplierID is required" },
    { field: "ItemTypeId", message: "ItemTypeId is required" },
  ]);


  useEffect(() => {
    if (rowData) {
      const updatedData = rowData.map((row) => ({
        ...row,
        ProductCode: row.ProductCode?.toString(),
        SupplierCode: row.SupplierCode?.toString(),
        VariationCode: row.VariationCode?.toString(),
        PLU: row.PLU?.toString(),
      }));

      setGridRowData(updatedData);
    }
  }, [rowData]);
  useEffect(() => {
    dispatch(getClassifiersByTypeAsync("Products"));
    dispatch(getUnitMeasuresAsync());
    dispatch(getBrandsAsync());
    dispatch(getCustomsAsync());
    dispatch(getItemStatusesAsync());
    dispatch(getTaxesAsync());
    dispatch(getItemTypesAsync());
  }, []);


  const fetchClassifiers = async (searchInput: string, classifierId: number) => {
    const result = await dispatch(
      getClassifierDetailsBySearch({ searchInput: searchInput, classifierId: classifierId })
    );

    if (result.payload !== "An error occurred") {
      const payload = result.payload as ClassifierDetail[];
      const options = payload.map((classifier) => ({
        key: `${classifier?.classifierDetailId}-${classifier?.description}`,
        label: `${classifier?.description}`,
        value: `${classifier?.classifierDetailId}-${classifier?.description}`,
      }));
      return options;
    }
    return [];
  };

  
  const getFormInputByType = ( selectedClassifier: Classifier) => {
    switch (selectedClassifier.classifierDataTypeId) {
      case 1:
        return {
          headerName: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          field: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          sortable: false,
          filter: false,
          editable: true,
          suppressMovable: true,
          singleClickEdit: true,
          suppressMenu: true,
        };
        break;
      case 2:
        return {
          headerName: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          field: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          sortable: false,
          filter: false,
          editable: true,
          suppressMovable: true,
          singleClickEdit: true,
          suppressMenu: true,
          valueParser: (params: any) => {
            const value = params.newValue;

            const regex = /^[0-9]+$/;

            if (regex.test(value)) {
              return value;
            } else {
              return params.oldValue;
            }
          }
        };
        break;
      case 3:
        return {
          headerName: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          field: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          sortable: false,
          filter: false,
          editable: true,
          suppressMovable: true,
          singleClickEdit: true,
          suppressMenu: true,
          valueParser: (params: any) => {
            const value = params.newValue;

            const regex = /^[0-9]+\.[0-9]+$/;

            if (regex.test(value)) {
              return value;
            } else {
              return params.oldValue;
            }
          }
        }
        break;
      case 4:
        return {
          headerName: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          field: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          sortable: false,
          filter: false,
          editable: true,
          suppressMovable: true,
          singleClickEdit: true,
          suppressMenu: true,
          valueParser: (params: any) => {
            const value = params.newValue;

            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (regex.test(value)) {
              return value;
            } else {
              return params.oldValue;
            }
          }
        }
        break;
      case 5:
        return {
          field: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          suppressMenu: true,
          headerName: classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription,
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <FormSelectWithSearchWithoutFormik
                    name=""
                    label=""
                    style={{ width: "100%" }}
                    value={params.data[classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription ?? ""]}
                    className="import-select-with-search"
                    fetchOptions={(searchText:string)=>fetchClassifiers(searchText,selectedClassifier.classifierId??"")}
                    placeHolder={t("placeHolder")} 
                    onChange={(option) => {
                      params.data[classifiers.find((classifier) => classifier.description === selectedClassifier.description)?.clientDescription ?? ""] = option.value;
                      params.api.refreshCells({ rowNodes: [params.node], force: true });
                      params.api.applyTransaction({ update: [params.data] });
                    }}
                    />
            );
          },
        }
      break;
      default:
        return {
          field: "",
          suppressMenu: true,
          headerName:"",
          editable: false,
        }
    }
  }

  const fetchRequiredFields = async () => {
    try {
      const response = await apiService.get<ApiResponse<string[]>>(
        "/api/ExcelTemplates/required-fields-for-items-import"
      );

      setRequiredFields((prevFields) => [
        ...prevFields,
        ...response.data.Data.map((field) => ({
          field: field as keyof ImportArticleDto,
          message: `${field} is required`,
        })),
      ]);
    } catch (error) {
      toast.error(`Error fetching required fields: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const gridApi = useRef<GridApi | null>(null);

  const onGridReady = useCallback((params: any) => {
    gridApi.current = params.api;
  }, []);

  const fetchSuppliers = async (searchText: string, excludedId: string) => {
    const result = await dispatch(
      getEntitiesBySearch({ searchText: searchText, isSupplier: true })
    );

    if (result.payload !== "An error occurred") {
      const payload = result.payload as Entity[];
      const options = payload?.filter((entity) => `${entity?.entityId}-${entity?.description}` !== excludedId)?.map((entity) => ({
        key: `${entity?.entityId}-${entity?.description}`,
        label: `${entity?.entityId}-${entity?.description}`,
        value: `${entity?.entityId}-${entity?.description}`,
      }));
      return options;
    }
    return [];
  };

  useMemo(()=>{
    setColumnDefs(
      [
        {
          headerName: "uuid",
          field: "uuid",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          suppressMenu: true,
        },
        {
          headerName: t("tableHeaders.itemId"),
          field: "ItemId",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          suppressMenu: true,
        },
        {
          headerName: t("tableHeaders.description"),
          field: "Description",
          sortable: false,
          filter: false,
          editable: true,
          suppressMovable: true,
          singleClickEdit: true,
          suppressMenu: true,
        },
        {
          headerName: t("tableHeaders.shortDescription"),
          field: "ShortDescription",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
        },
        {
          headerName: t("tableHeaders.productCode"),
          field: "ProductCode",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
          valueFormatter: (params: any) => {
            return params.value?.toString();
          }
        },
        {
          field: "UnitId",
          suppressMenu: true,
          headerName: t("tableHeaders.unitId"),
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.UnitId}
                showSearch={true}
                className="import-select-with-search"
                onChange={(value) => {
                  params.data.UnitId = value;
                  params.api.refreshCells({ rowNodes: [params.node], force: true });
                  params.api.applyTransaction({ update: [params.data] });
                }}
                options={units?.map((unit) => ({
                    key: `${unit.unitMeasureId}-${unit.description}`,
                    label: `${unit.unitMeasureId}-${unit.description}`,
                    value: `${unit.unitMeasureId}-${unit.description}`,
                  }))}
              />
            );
          },
        },
        {
          field: "BrandId",
          suppressMenu: true,
          headerName: t("tableHeaders.BrandId"),
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.BrandId}
                showSearch={true}
                className="import-select-with-search"
                onChange={(value) => {
                  params.data.BrandId = value;
                  params.api.refreshCells({ rowNodes: [params.node], force: true });
                  params.api.applyTransaction({ update: [params.data] });
                }}
                options={brands?.map((brand) => ({
                    key: `${brand.brandId}-${brand.description}`,
                    label: `${brand.brandId}-${brand.description}`,
                    value: `${brand.brandId}-${brand.description}`,
                  }))}
              />
            );
          },
        },
        {
          field: classifiers.find((classifier) => classifier.description === "K1")?.clientDescription,
          suppressMenu: true,
          headerName: classifiers.find((classifier) => classifier.description === "K1")?.clientDescription,
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <FormSelectWithSearchWithoutFormik
              name=""
              label=""
              style={{ width: "100%" }}
              value={params.data[classifiers.find((classifier) => classifier.description === "K1")?.clientDescription ?? ""]}
              className="import-select-with-search"
              fetchOptions={(searchText:string)=>fetchClassifiers(searchText,1)}
              placeHolder={t("placeHolder")} 
              onChange={(option) => {
                params.data[classifiers.find((classifier) => classifier.description === "K1")?.clientDescription ?? ""] = option.value;
                params.api.refreshCells({ rowNodes: [params.node], force: true });
                params.api.applyTransaction({ update: [params.data] });
              }}
              />
            );
          },
        },
        {
          field: classifiers.find((classifier) => classifier.description === "K2")?.clientDescription,
          suppressMenu: true,
          headerName: classifiers.find((classifier) => classifier.description === "K2")?.clientDescription,
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <FormSelectWithSearchWithoutFormik
              name=""
              label=""
              style={{ width: "100%" }}
              value={params.data[classifiers.find((classifier) => classifier.description === "K2")?.clientDescription ?? ""]}
              className="import-select-with-search"
              fetchOptions={(searchText:string)=>fetchClassifiers(searchText,2)}
              placeHolder={t("placeHolder")} 
              onChange={(option) => {
                params.data[classifiers.find((classifier) => classifier.description === "K2")?.clientDescription ?? ""] = option.value;
                params.api.refreshCells({ rowNodes: [params.node], force: true });
                params.api.applyTransaction({ update: [params.data] });
              }}
              />
            );
          },
        },
        {
          field: classifiers.find((classifier) => classifier.description === "K3")?.clientDescription,
          suppressMenu: true,
          headerName: classifiers.find((classifier) => classifier.description === "K3")?.clientDescription,
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <FormSelectWithSearchWithoutFormik
              name=""
              label=""
              style={{ width: "100%" }}
              value={params.data[classifiers.find((classifier) => classifier.description === "K3")?.clientDescription ?? ""]}
              className="import-select-with-search"
              fetchOptions={(searchText:string)=>fetchClassifiers(searchText,3)}
              placeHolder={t("placeHolder")} 
              onChange={(option) => {
                params.data[classifiers.find((classifier) => classifier.description === "K3")?.clientDescription ?? ""] = option.value;
                params.api.refreshCells({ rowNodes: [params.node], force: true });
                params.api.applyTransaction({ update: [params.data] });
              }}
              />
            );
          },
        },
        {
          field: classifiers.find((classifier) => classifier.description === "K4")?.clientDescription,
          suppressMenu: true,
          headerName: classifiers.find((classifier) => classifier.description === "K4")?.clientDescription,
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <FormSelectWithSearchWithoutFormik
              name=""
              label=""
              style={{ width: "100%" }}
              value={params.data[classifiers.find((classifier) => classifier.description === "K4")?.clientDescription ?? ""]}
              className="import-select-with-search"
              fetchOptions={(searchText:string)=>fetchClassifiers(searchText,4)}
              placeHolder={t("placeHolder")} 
              onChange={(option) => {
                params.data[classifiers.find((classifier) => classifier.description === "K4")?.clientDescription ?? ""] = option.value;
                params.api.refreshCells({ rowNodes: [params.node], force: true });
                params.api.applyTransaction({ update: [params.data] });
              }}
              />
            );
          },
        },
        {
          field: "CustomId",
          suppressMenu: true,
          headerName: t("tableHeaders.CustomId"),
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.CustomId}
                showSearch={true}
                className="import-select-with-search"
                onChange={(value) => {
                  params.data.CustomId = value;
                  params.api.refreshCells({ rowNodes: [params.node], force: true });
                  params.api.applyTransaction({ update: [params.data] });
                }}
                options={customs?.map((custom) => ({
                    key: `${custom.customId}-${custom.description}`,
                    label: `${custom.customId}-${custom.description}`,
                    value: `${custom.customId}-${custom.description}`,
                  }))}
              />
            );
          },
        },
        {
          field: "PrimarySupplierID",
          suppressMenu: true,
          headerName: t("tableHeaders.PrimarySupplierID"),
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <FormSelectWithSearchWithoutFormik
              name="PrimarySupplierID"
              label=""
              style={{ width: "100%" }}
              value={params.data.PrimarySupplierID}
              className="import-select-with-search"
              fetchOptions={(searchText:string)=>fetchSuppliers(searchText,params?.data?.SecondarySupplierID??"")}
              placeHolder={t("placeHolder")} 
              onChange={(option) => {
                params.data.PrimarySupplierID = option.value;
                params.api.refreshCells({ rowNodes: [params.node], force: true });
                params.api.applyTransaction({ update: [params.data] });
              }}
              />
            );
          }
        },
        {
          field: "SecondarySupplierID",
          suppressMenu: true,
          headerName: t("tableHeaders.SecondarySupplierID"),
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <FormSelectWithSearchWithoutFormik
              name="SecondarySupplierID"
              label=""
              style={{ width: "100%" }}
              value={params.data.SecondarySupplierID}
              className="import-select-with-search"
              fetchOptions={(searchText:string)=>fetchSuppliers(searchText,params?.data?.PrimarySupplierID??"")}
              placeHolder={t("placeHolder")} 
              onChange={(option) => {
                params.data.PrimarySupplierID = option.value;
                params.api.refreshCells({ rowNodes: [params.node], force: true });
                params.api.applyTransaction({ update: [params.data] });
              }}
              />
            );
          }
        },
        {
          headerName: t("tableHeaders.SupplierCode"),
          field: "SupplierCode",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
          valueFormatter: (params: any) => {
            return params.value?.toString();
          }
        },
        {
          headerName: t("tableHeaders.VariationCode"),
          field: "VariationCode",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
          valueFormatter: (params: any) => {
            return params.value?.toString();
          }
        },
        {
          field: "StatusId",
          suppressMenu: true,
          headerName: t("tableHeaders.StatusId"),
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.StatusId}
                className="ConfigurationSelect"
                showSearch={false}
                onChange={(value) => {
                  params.data.StatusId = value;
                  params.api.refreshCells({ rowNodes: [params.node], force: true });
                  params.api.applyTransaction({ update: [params.data] });
                }}
                options={status?.map((Itemstatus) => ({
                    key: `${Itemstatus.itemStatusId}-${Itemstatus.description}`,
                    label: `${Itemstatus.itemStatusId}-${Itemstatus.description}`,
                    value: `${Itemstatus.itemStatusId}-${Itemstatus.description}`,
                  }))}
              />
            );
          },
        },
        {
          headerName: t("tableHeaders.Comment"),
          field: "Comment",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
        },
        {
          headerName: t("tableHeaders.Ingredients"),
          field: "Ingredients",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
        },
        {
          headerName: t("tableHeaders.Packaging"),
          field: "Packaging",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
        },
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K5")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K6")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K7")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K8")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K9")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K10")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K11")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K12")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K13")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K14")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K15")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K16")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K17")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K18")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K19")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K20")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K21")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K22")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K23")??{}as Classifier),
        getFormInputByType(classifiers.find((classifier) => classifier.description === "K24")??{}as Classifier),
        {
          headerName: t("tableHeaders.Excise"),
          field: "Excise",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
        },
        {
          field: "TaxId",
          suppressMenu: true,
          headerName: t("tableHeaders.TaxId"),
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.TaxId}
                showSearch={true}
                className="import-select-with-search"
                onChange={(value) => {
                  params.data.TaxId = value;
                  params.api.refreshCells({ rowNodes: [params.node], force: true });
                  params.api.applyTransaction({ update: [params.data] });
                }}
                options={taxes?.map((tax) => ({
                    key: `${tax.taxId}-${tax.description}`,
                    label: `${tax.taxId}-${tax.description}`,
                    value: `${tax.taxId}-${tax.description}`,
                  }))}
              />
            );
          },
        },
        {
          field: "ItemTypeId",
          suppressMenu: true,
          headerName: t("tableHeaders.ItemTypeId"),
          editable: true,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.ItemTypeId}
                showSearch={true}
                className="import-select-with-search"
                onChange={(value) => {
                  params.data.ItemTypeId = value;
                  params.api.refreshCells({ rowNodes: [params.node], force: true });
                  params.api.applyTransaction({ update: [params.data] });
                }}
                options={itemType?.map((item) => ({
                    key: `${item.itemTypeId}-${item.description}`,
                    label: `${item.itemTypeId}-${item.description}`,
                    value: `${item.itemTypeId}-${item.description}`,
                  }))}
              />
            );
          },
        },
        {
          headerName: t("tableHeaders.PLU"),
          field: "PLU",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
          valueFormatter: (params: any) => {
            return params.value?.toString();
          }
        },
        {
          headerName: t("tableHeaders.CompositeItems"),
          field: "CompositeItems",
          sortable: false,
          filter: false,
          suppressMovable: true,
          singleClickEdit: true,
          editable: true,
          suppressMenu: true,
        },
      ]
    );
  },[units, brands, customs, status, taxes, itemType])


  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (classifiers.length === 0) {
        toast.error(
          "Please wait for classifiers to load before importing articles"
        );
        return;
      }


      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

          let headers = jsonData[0] as string[];
          const rows = jsonData.slice(1);

          const updatedHeaders = [...headers];

          
          const descriptionIndex = headers.indexOf("Description");

          setGridRowData(
            rows.reduce((acc: any[], row: any) => {
              if (
                row[descriptionIndex] !== null &&
                row[descriptionIndex] !== undefined &&
                row[descriptionIndex] !== ""
              ) {
                const rowObject: any = {};
                headers.forEach((header, index) => {
                  rowObject[header] = row[index];
                });
                rowObject["uuid"] = uuidv4();
                acc.push(rowObject);
              }
              return acc;
            }, [])
          );
        };
        reader.readAsArrayBuffer(file);
      }
    },
    [classifiers]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getPropertyNameBasedOnClassifier = (classifier: string) => {
    const propertyName = classifiers.find(
      (item: Classifier) => item.description === classifier
    )?.clientDescription;
    return propertyName;
  };

  const checkIfColumnIsEditable = (columnName: string) => {
    const temp_classifier = classifiers.find(
      (item: Classifier) =>
        item.clientDescription?.toLowerCase() === columnName?.toLowerCase()
    );

    if (temp_classifier !== undefined) {
      if (
        temp_classifier.classifierTypeDescription.toLowerCase() === "select"
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        columnName.toLowerCase().startsWith("id") ||
        columnName.toLocaleLowerCase().endsWith("id")
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const returnPropertyNameForRequiredFields = (propertyName: string) => {
    if (propertyName === "K1") return "Category1";
    else if (propertyName === "K2") return "Category2";
    else if (propertyName === "K3") return "Category3";
    else if (propertyName === "K4") return "Category4";
    else return propertyName;
  };

  const handleSubmitImportArticles = async () => {
    let articleDto: ImportArticleDto[] = [];

    let required_Fields: RequiredField[] = requiredFields;

    classifiers.map((item: Classifier) => {
      if (item.mandatory) {
        required_Fields.push({
          field: returnPropertyNameForRequiredFields(
            item.description
          ) as keyof ImportArticleDto,
          message: `${item.clientDescription} is required`,
        });
      }
    });

    for (let i = 0; i < gridRowData.length; i++) {
      const item = gridRowData[i];

      try {
        let barcodes_array: ImportArticleBarcodeDto[] = [];
        if (typeof item.Barcodes?.toString() === "string") {
          barcodes_array.push({
            barcode: (item.Barcodes as string | number).toString(),
            primaryBarcode: false,
          });
        }

        const tempItem: ImportArticleDto = {
          uuid: item.uuid,
          ItemId: item.ItemId ?? null,
          Barcodes: barcodes_array,
          Description: item.Description,
          ShortDescription: item.ShortDescription ?? null,
          ProductCode: item.ProductCode?.toString() ?? null,
          UnitId:
            typeof item.UnitId === "string"
              ? convertStringToIntFK(item.UnitId)
              : -1,
          BrandId:
            typeof item.BrandId === "string"
              ? convertStringToIntFK(item.BrandId)
              : -1,
          TaxId:
            typeof item.TaxId === "string"
              ? convertStringToIntFK(item.TaxId)
              : -1,
          Category1:
            typeof item[
              getPropertyNameBasedOnClassifier("K1") as keyof typeof item
            ] === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K1"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 1,
              }
              : null,
          Category2:
            typeof item[
              getPropertyNameBasedOnClassifier("K2") as keyof typeof item
            ] === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K2"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 2,
              }
              : null,
          Category3:
            typeof item[
              getPropertyNameBasedOnClassifier("K3") as keyof typeof item
            ] === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K3"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 3,
              }
              : null,
          Category4:
            typeof item[
              getPropertyNameBasedOnClassifier("K4") as keyof typeof item
            ] === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K4"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 4,
              }
              : null,
          CustomId:
            typeof item.CustomId === "string"
              ? convertStringToIntFK(item.CustomId)
              : -1,
          PrimarySupplierID:
            typeof item.PrimarySupplierID === "string"
              ? convertStringToIntFK(item.PrimarySupplierID)
              : -1,
          SecondarySupplierID:
            typeof item.SecondarySupplierID === "string"
              ? convertStringToIntFK(item.SecondarySupplierID)
              : null,
          SupplierCode: item.SupplierCode?.toString() ?? null,
          VariationCode: item.VariationCode?.toString() ?? null,
          StatusId:
            typeof item.StatusId === "string"
              ? convertStringToIntFK(item.StatusId)
              : -1,
          Comment: item.Comment ?? null,
          Ingredients: item.Ingredients ?? null,
          Packaging: item.Packaging ?? null,
          K5:
            typeof item[
              getPropertyNameBasedOnClassifier("K5") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K5"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 5,
              }
              : null,
          K6:
            typeof item[
              getPropertyNameBasedOnClassifier("K6") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K6"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 6,
              }
              : null,
          K7:
            typeof item[
              getPropertyNameBasedOnClassifier("K7") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K7"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 7,
              }
              : null,
          K8:
            typeof item[
              getPropertyNameBasedOnClassifier("K8") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K8"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 8,
              }
              : null,
          K9:
            typeof item[
              getPropertyNameBasedOnClassifier("K9") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K9"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 9,
              }
              : null,
          K10:
            typeof item[
              getPropertyNameBasedOnClassifier("K10") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K10"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 10,
              }
              : null,
          K11:
            typeof item[
              getPropertyNameBasedOnClassifier("K11") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K11"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 11,
              }
              : null,
          K12:
            typeof item[
              getPropertyNameBasedOnClassifier("K12") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K12"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 12,
              }
              : null,
          K13:
            typeof item[
              getPropertyNameBasedOnClassifier("K13") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K13"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 13,
              }
              : null,
          K14:
            typeof item[
              getPropertyNameBasedOnClassifier("K14") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K14"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 14,
              }
              : null,
          K15:
            typeof item[
              getPropertyNameBasedOnClassifier("K15") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K15"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 15,
              }
              : null,
          K16:
            typeof item[
              getPropertyNameBasedOnClassifier("K16") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K16"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 16,
              }
              : null,
          K17:
            typeof item[
              getPropertyNameBasedOnClassifier("K17") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K17"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 17,
              }
              : null,
          K18:
            typeof item[
              getPropertyNameBasedOnClassifier("K18") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K18"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 18,
              }
              : null,
          K19:
            typeof item[
              getPropertyNameBasedOnClassifier("K19") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K19"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 19,
              }
              : null,
          K20:
            typeof item[
              getPropertyNameBasedOnClassifier("K20") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K20"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 20,
              }
              : null,
          K21:
            typeof item[
              getPropertyNameBasedOnClassifier("K21") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K21"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 21,
              }
              : null,
          K22:
            typeof item[
              getPropertyNameBasedOnClassifier("K22") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K22"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 22,
              }
              : null,
          K23:
            typeof item[
              getPropertyNameBasedOnClassifier("K23") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K23"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 23,
              }
              : null,
          K24:
            typeof item[
              getPropertyNameBasedOnClassifier("K24") as keyof typeof item
            ]?.toString() === "string"
              ? {
                classifierDetailId: null,
                classifierDetailDescription:
                  item[
                    getPropertyNameBasedOnClassifier(
                      "K24"
                    ) as keyof typeof item
                  ]?.toString(),
                classifierId: 24,
              }
              : null,
          Excise: item.Excise ?? null,
          PLU: item.PLU?.toString() ?? null,
          ItemTypeId:
            typeof item.ItemTypeId === "string"
              ? convertStringToIntFK(item.ItemTypeId)
              : -1,
        };

        requiredFields.forEach(({ field, message }) => {
          if (
            tempItem[field] === -1 ||
            tempItem[field] === null ||
            tempItem[field] === undefined ||
            tempItem[field] === ""
          ) {
            throw new Error(
              `${message} for the following item: ${item.Description}`
            );
          }
        });

        let classifiersArray = [
          "K5",
          "K6",
          "K7",
          "K8",
          "K9",
          "K10",
          "K11",
          "K12",
          "K13",
          "K14",
          "K15",
          "K16",
          "K17",
          "K18",
          "K19",
          "K20",
          "K21",
          "K22",
          "K23",
          "K24",
        ];

        classifiersArray.forEach((classifier: string) => {
          const temp_classifier = classifiers.find(
            (item: Classifier) =>
              item.description?.toLowerCase() === classifier?.toLowerCase()
          );

          if (temp_classifier !== undefined) {
            const value: ClassifierDetailDto | null | undefined = tempItem[
              classifier as keyof typeof tempItem
            ] as ClassifierDetailDto | null | undefined;

            if (value !== null && value !== undefined) {
              const regex = new RegExp(temp_classifier.regex ?? "");

              if (
                temp_classifier.classifierTypeDescription.toLowerCase() ===
                "number"
              ) {
                if (!regex.test(value.classifierDetailDescription)) {
                  throw new Error(
                    `Incorrect number format for the following item: ${item.Description} - ${temp_classifier.clientDescription}`
                  );
                }
              } else if (
                temp_classifier.classifierTypeDescription.toLowerCase() ===
                "decimal number"
              ) {
                if (!regex.test(value.classifierDetailDescription)) {
                  throw new Error(
                    `Incorrect decimal number format for the following item: ${item.Description} - ${temp_classifier.clientDescription}`
                  );
                }
              } else if (
                temp_classifier.classifierTypeDescription.toLowerCase() ===
                "email"
              ) {
                if (!regex.test(value.classifierDetailDescription)) {
                  throw new Error(
                    `Incorrect email format for the following item: ${item.Description} - ${temp_classifier.clientDescription}`
                  );
                }
              }
            }
          }
        });

        articleDto.push(tempItem);
      } catch (error) {
        toast.error(
          `Incorrect format for the following item: ${item.Description} + Error: ${error}`
        );
        break;
      }
    }
    if (articleDto.length > 0) {
      setIsLoading(true);
      try {
      
        var response = await apiService.post<ApiResponse<ImportedArticle[]>>(
          "/api/items/multiItemsImport",
          articleDto
        );
        var data = response.data.Data;

        setGridRowData((prevRowData) => {
          return prevRowData.map((row) => {
            const importedArticle = data.find(
              (x) =>
                x.uuid === row.uuid &&
                (x.created === true || x.updated === true)
            );
            if (importedArticle) {
              return { ...row, ItemId: importedArticle.itemId };
            }
            return row;
          });
        });

        toast.success("Articles imported successfully");
      } catch (error) {
        toast.error(`Error importing articles: ${error}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const convertStringToIntFK = (str: string) => {
    if (str.length > 0) {
      return parseInt(str.split("-")[0]);
    } else {
      return -1;
    }
  };

  const onCellValueChanged = useCallback(
    (params: CellValueChangedEvent) => {
      const { data } = params;
      const updatedRowData = gridRowData.map((row: ImportArticleDto) =>
        row.uuid === data.uuid ? data : row
      );

      setRowData(updatedRowData);
    },
    [gridRowData]
  );


  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <div>
        <>
          <Title level={3}>
          {t("importForm.title")}
          </Title>
          <Divider />
        </>

        {gridRowData.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <Button onClick={handleSubmitImportArticles} type="primary">
                Submit
              </Button>
            </div>
            <AgGridTableComponent
              style={{ overflow: "auto" }}
              onGridReady={onGridReady}
              isLoading={isLoading}
              columnDefs={columnDefs}
              className="import-articles-grid"
              rowData={gridRowData}
              stopEditingWhenCellsLoseFocus={true}
              suppressCellFocus={true}
              pagination={true}
              paginationPageSize={100}
              onCellValueChanged={onCellValueChanged}
              onCellKeyDown={(event: CellKeyDownEvent) =>
                handleNextGridColumnClick(event)
              }
            />
           </>
        ) : (
          <>
            <div
              {...getRootProps()}
              style={{
                border: "2px dashed #008080	",
                padding: "20px",
                paddingTop: "100px",
                paddingBottom: "100px",
                cursor: "pointer",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input {...getInputProps()} />
              <p></p>
              <Title level={4}>
                Drag and drop an Excel file here, or click to select a file
              </Title>
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

export default ImportArticles;
