import { RefObject } from "react";
import { CreateOrUpdateEntryItemsOrderDetailDto } from "../../../../../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDetailDto";
import { ColDef } from "ag-grid-community";
import { suppressEnter } from "../../../../../../helperMethods/suppressEnter";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";


export const getEntryItemsOrdersDetailColumns = (
  deleteImportPurchaseDetail: (index: number) => void,
  t: (text: string) => string,
  searchItemInputRef: RefObject<any>,
  mode: string,
  type: string
) => {
  const getMaxEntryItemsOrderDetailId = (rowData: CreateOrUpdateEntryItemsOrderDetailDto[]) => {
    return Math?.max(0, ...rowData?.map((item) => item.entryItemsOrderDetailId ?? 0));
  };

  const columnDefs: ColDef<CreateOrUpdateEntryItemsOrderDetailDto>[] = [
    {
      headerName: t("tableHeaders.entryItemsOrderDetailId"),
      field: "entryItemsOrderDetailId",
      editable: false,
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      sortable: false,
      filter: false,
      width: 80,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
      valueSetter: (params) => {
        const newValue = parseInt(params.newValue, 10);

        const allData: CreateOrUpdateEntryItemsOrderDetailDto[] = [];
        params.api.forEachNode((node: any) => allData.push(node.data));

        const isDuplicate = allData.some(
          (row) => row.entryItemsOrderDetailId === newValue && row !== params.data
        );

        if (isNaN(newValue) || newValue <= 0 || isDuplicate) {
          params.data.entryItemsOrderDetailId = params.oldValue;
          return false;
        }

        if (mode === 'update' && params.data.entryItemsOrderDetailId !== 0 && params.data.entryItemsOrderDetailId !== undefined && params.data.entryItemsOrderDetailId !== null) {
          params.data.rowAction = 'U';
        }
        params.data.entryItemsOrderDetailId = newValue;
        return true;
      },
      valueGetter: (params: any) => {
        if (params.data.no === undefined || params.data.no === null) {

          const allData: CreateOrUpdateEntryItemsOrderDetailDto[] = [];
          params.api.forEachNode((node: any) => allData.push(node.data));
          const maxNo = getMaxEntryItemsOrderDetailId(allData);
          params.data.no = maxNo + 1;
        }
        return params.data.no;
      },
    },
    {
      hide: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true
    },
    {
      headerName: t("tableHeaders.itemId"),
      field: "itemId",
      editable: false,
      singleClickEdit: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true
    },
    {
      headerName: t("tableHeaders.itemDescription"),
      field: "itemDescription",
      editable: false,
      singleClickEdit: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true
    },
    {
      headerName: t("tableHeaders.barcode"),
      field: "barcode",
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      singleClickEdit: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
      valueSetter: (params) => {
        const newValue = params.newValue;
        if (mode === 'update' && params.data.entryItemsOrderDetailId !== 0 && params.data.entryItemsOrderDetailId !== undefined && params.data.entryItemsOrderDetailId !== null) {
          params.data.rowAction = 'U';
        }
        params.data.barcode = newValue;
        return true;
      }
    },
    {
      headerName: t("tableHeaders.quantity"),
      wrapHeaderText: true,
      filter: false,
      field: "quantity",
      editable: true,
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      singleClickEdit: true,
      suppressMenu: true,
      valueSetter: (params: any) => {
        const value = params.newValue;
        if (parseInt(value) <= 0) {
          params.data.quantity = 1;
        } else {
          params.data.quantity = value;
        }
        params.data.package = (Number(value)/params?.data?.itemPackaging)?.toFixed(4);
        return true;
      },
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: t("tableHeaders.package"),
      field: "package",
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      editable: true,
      singleClickEdit: true,
      sortable: false,
      valueSetter: (params: any) => {
        const value = params.newValue;
        if (parseInt(value) <= 0) {
          params.data.package = 1;
        } else {
          params.data.package = value;
        }
        params.data.quantity = (Number(value)*params?.data?.itemPackaging)?.toFixed(2);
        return true
      },
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
    }];
    if(type !== "normal"){
      columnDefs.push({
        headerName: t("tableHeaders.proposedQuantity"),
        field: "proposedQuantity",
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      })
    }
    columnDefs.push(
      {
        headerName: t("tableHeaders.supplyPrice"),
        field: "supplyPrice",
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
    {
      headerName: t("tableHeaders.stock"),
      field: "stock",
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true
    },
    {
      headerName: t("tableHeaders.comment"),
      field: "comment",
      sortable: false,
      editable: true,
      filter: false,
      suppressMovable: true,
      suppressMenu: true
    },
    {
      headerName: t("tableHeaders.discount"),
      wrapHeaderText: true,
      field: "discount",
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      valueSetter: (params) => {
        const previousDiscount = params.oldValue;
        const newValue = params.newValue

        if(isNaN(newValue) || newValue < 0){
          toast.error(t("discountNegative"));
          params.data.discount = previousDiscount;
          return false;
        }
        return true;
      },
      suppressMenu: true,
      editable: true,
    },
    {
      headerName: t("tableHeaders.extraDiscount"),
      wrapHeaderText: true,
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      valueSetter: (params) => {
        const previousExtraDiscount = params.oldValue;
        const newValue = params.newValue

        if(isNaN(newValue) || newValue < 0){
          toast.error(t("extraDiscountNegative"));
          params.data.extraDiscount = previousExtraDiscount;
          return false;
        }
        return true;
      },
      field: "extraDiscount",
      filter: false,
      singleClickEdit: true,
      maxWidth: 120,
      suppressMenu: true,
      editable: true,
    },
    {
      headerName: t("tableHeaders.salesPrice"),
      field: "salesPrice",
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      wrapHeaderText: true,
      filter: false,
      singleClickEdit: true,
      maxWidth: 120,
      suppressMenu: true
    },
    {
      headerName: t("tableHeaders.options"),
      suppressMenu: true,
      maxWidth: 100,
      cellRenderer: (params: any) => {
        return (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => deleteImportPurchaseDetail(params.rowIndex)}
            style={{ color: "#007FFF" }}
          ></Button>
        );
      },
    });
  return columnDefs;
};
