import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/Brand/BrandState";
import { ClassifierState } from "../../models/clientDashboard/Classifier/ClassifierState";
import { Classifier } from "../../models/clientDashboard/Classifier/Classifier";
import { ClassifierDetail } from "../../models/clientDashboard/ClassifierDetail/ClassifierDetail";
import { ClassifierDataType } from "../../models/clientDashboard/ClassifierDataType/ClassifierDataType";
import { ClassifierEditDto } from "../../models/clientDashboard/Classifier/ClassifierEditDto";

const initialState: ClassifierState = {
    classifiers: [],
    classifierDataTypes: [],
    error: null,
};

const ClassifierSlice = createSlice({
    name: "classifiers",
    initialState,
    reducers: {
        getClassifiersStart(state: ClassifierState) {
            setLoading(true);
            state.error = null;
        },
        getClassifiersSuccess(
            state: ClassifierState,
            action: PayloadAction<Classifier[]>
        ) {
            setLoading(false);
            state.classifiers = action.payload;
        },
        getClassifiersFailure(
            state: ClassifierState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        setClassifierDataTypes(
            state: ClassifierState,
            action: PayloadAction<ClassifierDataType[]>
        ) {
            state.classifierDataTypes = action.payload;
        }

    }
});

export const getClassifiersByTypeAsync = createAsyncThunk(
    "Classifiers/getClassifiersByType",
    async (type: string, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getClassifiersStart());
            const response = await apiService.get<ApiResponse<Classifier[]>>(
                "api/Classifier/byType", {
                params: {
                    type: type
                }
            }
            );
            // toast.success("Clas were successfully retrieved!");
            dispatch(getClassifiersSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getClassifiersFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const getClassifiersByTypeReturnAsync = createAsyncThunk(
    "Classifiers/getClassifiersByType",
    async (type: string, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getClassifiersStart());
            const response = await apiService.get<ApiResponse<Classifier[]>>(
                "api/Classifier/byType", {
                params: {
                    type: type
                }
            }
            );
            // toast.success("Clas were successfully retrieved!");
            //dispatch(getClassifiersSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getClassifiersFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const getClassifiersForSubjectBranchesAsync = createAsyncThunk(
    "Classifiers/getClassifiersForSubjectBranchesAsync",
    async (type: string, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getClassifiersStart());
            const response = await apiService.get<ApiResponse<Classifier[]>>(
                "api/Classifier/byType", {
                params: {
                    type: type
                }
            }
            );
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getClassifiersFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getClassifiersAsync = createAsyncThunk(
    "Classifiers/getAll",
    async (_, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getClassifiersStart());
            const response = await apiService.get<ApiResponse<Classifier[]>>(
                "api/Classifier/getAll"
            );
            toast.success("Classifiers were successfully retrieved!");
            dispatch(getClassifiersSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getClassifiersFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getClassifierByIdForEditAsync = createAsyncThunk(
    "Classifiers/getClassifierByIdForEditAsync",
    async (classifierId: string, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getClassifiersStart());
            const response = await apiService.get<ApiResponse<ClassifierEditDto>>(
                "api/Classifier/infoDetails", {
                params: {
                    classifierId: classifierId
                }
            }
            );
            // toast.success("Clas were successfully retrieved!");
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getClassifiersFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getClassifierDataTypesAsync = createAsyncThunk(
    "Classifiers/getClassifierDataTypesAsync",
    async (_, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getClassifiersStart());
            const response = await apiService.get<ApiResponse<ClassifierDataType[]>>(
                "api/Classifier/dataTypes"
            );
            // toast.success("Classifiers were successfully retrieved!");
            dispatch(setClassifierDataTypes(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getClassifiersFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const getClassifierDetailsBySearch = createAsyncThunk(
    "ClassifierDetailsBySearch/detailsBySearchInput",
    async (
        data: { searchInput: string; classifierId: number; },
        { dispatch, getState, rejectWithValue }
    ) => {

        try {
            let url = `api/Classifier/detailsBySearchInput`;


            dispatch(getClassifiersStart());
            const response = await apiService.get<ApiResponse<ClassifierDetail[]>>(url, {
                params: {
                    classifierId: data.classifierId,
                    searchInput: data.searchInput
                }
            });
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getClassifiersFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const fetchClassifiers = async (searchInput: string, classifierId: number) => {
    let url = `api/Classifier/detailsBySearchInput`;
            const result = await apiService.get<ApiResponse<ClassifierDetail[]>>(url, {
                params: {
                    classifierId: classifierId,
                    searchInput: searchInput
                }
            });
    if (result.status === 200) {
      const payload = result.data.Data as ClassifierDetail[];
      const options = payload.map((classifier) => ({
        key: classifier?.classifierDetailId || 0,
        label: classifier?.description,
        value: classifier?.classifierDetailId || 0,
      }));
      return options;
    }
    return [];
  };

export const {
    getClassifiersStart,
    getClassifiersSuccess,
    getClassifiersFailure,
    setClassifierDataTypes
} = ClassifierSlice.actions;

export default ClassifierSlice.reducer;