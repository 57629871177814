import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/ItemType/ItemTypeState";
import { ItemTypeState } from "../../models/clientDashboard/ItemType/ItemTypeState";
import { ItemType } from "../../models/clientDashboard/ItemType/ItemType";
import { RootState } from "..";

const initialState: ItemTypeState = {
    itemTypes: [],
    error: null,
};

const ItemTypeSlice = createSlice({
    name: "itemType",
    initialState,
    reducers: {
        getItemTypesStart(state: ItemTypeState) {
            setLoading(true);
            state.error = null;
        },
        getItemTypesSuccess(
            state: ItemTypeState,
            action: PayloadAction<ItemType[]>
        ) {
            setLoading(false);
            state.itemTypes = action.payload;
        },
        getItemTypesFailure(
            state: ItemTypeState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        deleteItemTypeFromState(state: ItemTypeState, action: PayloadAction<number>) {
            setLoading(false);
            const itemTypeId = action.payload;
            state.itemTypes = state.itemTypes.filter(itemType => itemType.itemTypeId !== itemTypeId);
        },
    }
});

export const getItemTypesAsync = createAsyncThunk(
    "ItemTypes/getItemTypes",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const itemTypes = state.itemType.itemTypes;

        if (itemTypes.length > 0) {
            return itemTypes;
        }

        try {
            dispatch(getItemTypesStart());
            const response = await apiService.get<ApiResponse<ItemType[]>>(
                "api/ItemTypes"
            );
            dispatch(getItemTypesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getItemTypesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getItemTypeDataById = createAsyncThunk(
    "ItemTypes/getItemTypeDataById",
    async (itemTypeId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<ItemType>>(
                `/api/ItemTypes/${itemTypeId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteItemTypeAsync = createAsyncThunk(
    "ItemTypes/deleteItemType",
    async (itemTypeId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/ItemTypes/${itemTypeId}`
            );
            dispatch(deleteItemTypeFromState(itemTypeId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const {
    getItemTypesStart,
    getItemTypesSuccess,
    getItemTypesFailure,
    deleteItemTypeFromState
} = ItemTypeSlice.actions;

export default ItemTypeSlice.reducer;