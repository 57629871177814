import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { Classifier } from "../../../models/clientDashboard/Classifier/Classifier";
import { CreateOrUpdateFinInvoiceRegistration } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistration";
import { CreateOrUpdateFinInvoiceRegistrationAccounting } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationAccounting";
import { GenericDocumentDto } from "../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { SelectOption } from "../../../models/SelectOption";
import { AppDispatch, RootState } from "../../../redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import {
  getItemDetailsForLocalPurchase,
  getItemsBySearchValue,
} from "../../../redux/slices/itemSlice";
import { CopiedDocumentDetailsTable } from "../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { GenerateItemOrderDetailsDto } from "../../../models/clientDashboard/EntriesModule/Orders/GenerateItemOrderDetailsDto";
import { toast } from "react-toastify";
import { GetItemDetailsDto } from "../../../models/clientDashboard/Item/GetItemDetailsDto";
import { mapDataToInterface } from "../../../helperMethods/mapDataToInterface";
import { db } from "../../../indexDB/clientSideDatabase";
import { debounce } from "../../../helperMethods/debounce";
import { getEntitiesBySearch } from "../../../redux/slices/entitySlice";
import { Entity } from "../../../models/clientDashboard/Entity/Entity";
import { Button, Col, Row, Spin } from "antd";
import FormSelect from "../../CustomComponents/FormSelect";
import FormSelectWithSearch from "../../CustomComponents/FormSelectWithSearch";
import FormInput from "../../CustomComponents/FormInput";
import { AgGridReact } from "ag-grid-react";
import { ItemDescriptionIdDto } from "../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { handleNextGridColumnClick } from "../../../helperMethods/handleNextGridColumnClick";
import { getFinInvoiceRegistrationAccountingColumns } from "./GridColumnDefs/AgGridFinInvoiceRegistrationAccountingColumns";
import AddAccountingDetail from "./AddAccountingDetail";
import { GetFinancialAccountsDto } from "../../../models/clientDashboard/AccountinConfigurations/GetFinancialAccountsDto";
import { getFinancialAccountsAsync } from "../../../redux/slices/accountingConfigurationSlice";
import { CreateOrUpdateFinInvoiceRegistrationVat } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationVat";
import { isReturnStatement } from "typescript";
import { getFinInvoiceRegistrationVatColumns } from "./GridColumnDefs/AgGridFininvoiceRegistrationVatColumns";

interface Props {
  values: GenericDocumentDto<
    CreateOrUpdateFinInvoiceRegistration,
    CreateOrUpdateFinInvoiceRegistrationAccounting
  >;
  finInvoiceRegistrationAccounting: CreateOrUpdateFinInvoiceRegistrationAccounting[];
  addFinInvoiceRegistrationAccounting: (
    finInvoiceRegistrationAccounting: CreateOrUpdateFinInvoiceRegistrationAccounting
  ) => void;
  setFinInvoiceRegistrationAccounting: (
    finInvoiceRegistrationAccounting: CreateOrUpdateFinInvoiceRegistrationAccounting[]
  ) => void;
  removeFinInvoiceRegistrationAccounting: (rowIndex: number) => void;
  mode: string;
  setFieldValue: (name: string, value: any) => void;
  entityOptions: SelectOption[];
  financialAccounts: GetFinancialAccountsDto[];
  handelVatsOnDocumentTypeChange: (documentTypeId: number) => void;
  setFinInvoiceRegistrationVats: (
    values: CreateOrUpdateFinInvoiceRegistrationVat[]
  ) => void;
  finInvoiceRegistrationVats: CreateOrUpdateFinInvoiceRegistrationVat[];
  balancInfo: {totalInvoiceAmount:number,debitAmount:number,creditAmount:number,remainingInvoiceAmount:number}
}
export default function MainFieldsComponent({
  values,
  finInvoiceRegistrationAccounting,
  addFinInvoiceRegistrationAccounting,
  removeFinInvoiceRegistrationAccounting,
  setFinInvoiceRegistrationAccounting,
  entityOptions,
  mode,
  setFieldValue,
  financialAccounts,
  handelVatsOnDocumentTypeChange,
  setFinInvoiceRegistrationVats,
  finInvoiceRegistrationVats,
  balancInfo
}: Props) {
  const t = useAppTranslation("ClientDashboard.CreateFinInvoiceRegistration");
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const [searchValue, setSearchValue] = useState("");
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const searchInputRef = useRef<any | null>(null);
  const suppliers = useSelector((state: RootState) => state.entity.entities);
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const dispatch = useDispatch<AppDispatch>();
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const documentTypes = useSelector(
    (state: RootState) => state.documentType.documentTypes
  );
  const finInvoiceRegistrationAccountingGridRef = useRef(null);
  const currencies = useSelector(
    (state: RootState) => state.currency.currencies
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const finInvoiceRegistrationVatGridRef = useRef(null);
  const [columnDefs] = useState<
    ColDef<CreateOrUpdateFinInvoiceRegistrationAccounting>[]
  >(
    getFinInvoiceRegistrationAccountingColumns(
      removeFinInvoiceRegistrationAccounting,
      t,
      searchInputRef,
      mode
    )
  );

  const handleItemSearch = async (value: any, entityId: number) => {
    const itemsBasedOnValueAction = await dispatch(
      getItemsBySearchValue({ searchValue: value, supplierId: entityId })
    );
    const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

    if (!items || items.length === 0) {
      setItemOptions([
        {
          label: t("noItemsFound"),
          value: "no-items",
          disabled: true,
        },
      ]);
    } else {
      setItemOptions([]);
      setItemOptions(
        items?.map((item) => ({
          key: item.itemId,
          label: item.description,
          value: item.itemId.toString(),
        }))
      );
    }
  };

  const [copiedData, setCopiedData] = useState<
    CopiedDocumentDetailsTable<any> | undefined
  >(undefined);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pasteDetails = async () => {
    try {
      if (copiedData) {
        const targetType = {
          finInvoiceRegistrationAccountingId: 0,
          finInvoiceRegistrationId: "",
          description: "",
          accountId: 0,
          amount: 0,
          dk: "",
          registrationDate: new Date(),
          registeredBy: 0,
          branchId: 0,
          entryItemsDetailsId: 0,
          k80: undefined,
          k81: undefined,
          k82: undefined,
          k83: undefined,
          k84: undefined,
          undiscountable: false,
          brandId: 0,
          brandName: "",
          itemId: 0,
          itemDescription: "",
          rowAction: "A",
        } as CreateOrUpdateFinInvoiceRegistrationAccounting;
        const details = mapDataToInterface(
          copiedData.list as any[],
          targetType
        );
        setFinInvoiceRegistrationAccounting(details);
      } else {
        toast.error(t("toastMessages.no_document"));
      }
    } catch (error) {
      toast.error(t("toastMessages.something_wrong"));
    }
  };

  const getCopiedData = async () => {
    const data = await db.getGenericData();
    if (data) setCopiedData(data[0]);
    else setCopiedData(undefined);
  };

  useEffect(() => {
    getCopiedData();
  }, []);

  const onChange = (data: any) => {
    setSearchValue(data);
  };

  const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

  const fetchSuppliers = async (searchText: string) => {
    const result = await dispatch(
      getEntitiesBySearch({ searchText: searchText, isSupplier: true })
    );
    if (result.payload !== "An error occurred") {
      const payload = result.payload as Entity[];
      const options = payload.map((entity) => ({
        key: entity.entityId.toString(),
        label: entity.description,
        value: entity.entityId,
      }));
      return options;
    }
    return [];
  };

  const handleOk = async (
    data: CreateOrUpdateFinInvoiceRegistrationAccounting
  ) => {
    data.registrationDate = new Date();
    addFinInvoiceRegistrationAccounting(data);
    setIsModalVisible(false);
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  

  const openAccountModal = () => {
    setIsModalVisible(true);
  };

  const accountDocument = () => {
    const defaultAmountForFirstAccount = finInvoiceRegistrationVats.reduce(
      (total, vat) => vat.invoiceAmount + total,
      0
    );
    const defaultAmountForSecondAccount = finInvoiceRegistrationVats.reduce(
      (total, vat) => {
        const vatAmount = (vat.invoiceAmount * vat.vat) / (100 + vat.vat);
        return total + vatAmount;
      },
      0
    );

    const defaultFinanicalAccounts: CreateOrUpdateFinInvoiceRegistrationAccounting[] =
      financialAccounts
        .filter(
          (account) => account.code === "200100" || account.code === "109400"
        )
        .map((account) => {
          return {
            finInvoiceRegistrationAccountingId: 0,
            finInvoiceRegistrationId: "",
            description: account.description ?? "",
            accountId: account.financialAccountId,
            accountDescription: account.description,
            amount:
              account.code === "200100"
                ? defaultAmountForFirstAccount
                : defaultAmountForSecondAccount,
            dk: account.code === "200100" ? "K" : "D",
            registrationDate: new Date(),
            registeredBy: user?.userId ?? 0,
            branchId: values.branchId,
            entryItemsDetailsId: undefined,
            k80: undefined,
            k81: undefined,
            k82: undefined,
            k83: undefined,
            k84: undefined,
            undiscountable: false,
            brandId: undefined,
            brandName: "",
            itemId: undefined,
            rowAction: "A",
          };
        });
    setFinInvoiceRegistrationAccounting(defaultFinanicalAccounts);
  };
  const vatColumnDefs = useMemo<
    ColDef<CreateOrUpdateFinInvoiceRegistrationVat>[]
  >(() => {
    return getFinInvoiceRegistrationVatColumns(
      t,
      setFinInvoiceRegistrationVats,
      finInvoiceRegistrationVats,
      mode
    );
  }, [finInvoiceRegistrationVats]);

  const memoizedRowData = useMemo(() => {
    return finInvoiceRegistrationAccounting.filter(
      (detail) => detail.rowAction !== "D"
    );
  }, [finInvoiceRegistrationAccounting]);

  const memoizedVatData = useMemo(() => {
    return finInvoiceRegistrationVats;
  }, [finInvoiceRegistrationVats]);

  const accoundDocumentButtonDisabled =
    values.branchId === undefined ||
    values.documentTypeId === undefined ||
    finInvoiceRegistrationAccounting.some(
      (account) => account.accountId === 200100 || account.accountId === 109400
    );

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xs={12} sm={8}>
          <FormSelect
            name="branchId"
            label={t("branchIdInputLabel")}
            required={true}
            value={values.branchId}
            // handleCustomChange={(value)=>{
            //     setFieldValue("branchId", value)
            // }}
            options={
              userBranches?.map((userBarnch) => {
                return {
                  key: userBarnch.branchId,
                  label: userBarnch.description,
                  value: userBarnch.branchId,
                };
              }) ?? []
            }
            disabled={
              !user?.isMainBranch ||
              values.validated ||
              finInvoiceRegistrationAccounting.length > 0
            }
          />
          <FormSelect
            name="documentTypeId"
            label={t("documentTypeInputLabel")}
            style={{ width: "100%" }}
            value={values.documentTypeId}
            required={true}
            disabled={
              values.validated || finInvoiceRegistrationAccounting.length > 0
            }
            options={
              documentTypes.map((documentType) => {
                return {
                  key: documentType.documentId,
                  label: documentType.description,
                  value: documentType.documentId,
                };
              }) ?? []
            }
            handleCustomChange={(value) => {
              handelVatsOnDocumentTypeChange(value);
              setFieldValue("documentTypeId", value);
            }}
          />
          <FormSelectWithSearch
            name="header.entityId"
            label={t("entityIdInputLabel")}
            required={true}
            value={values?.header?.entityId}
            disabled={
              values.validated || finInvoiceRegistrationAccounting.length > 0
            }
            fetchOptions={fetchSuppliers}
            fetchInitialOption={() => {
              return {
                key: values?.header?.entityId ?? "",
                label: values?.header?.entityName ?? "",
                value: values?.header?.entityId,
              };
            }}
            placeHolder={t("placeHolder")}
            onChange={(option) => {
              setFieldValue("header.entityId", option.value);
              setFieldValue("header.entityName", option.label);
            }}
          />
       <FormInput
            name="header.invoiceNo"
            label={t("invoiceNoInputLabel")}
            required={true}
            value={values?.header?.invoiceNo}
            disabled={values.validated}
          />
          <FormInput
            name="header.invoiceDate"
            type="date"
            required={true}
            disabled={values.validated}
            label={t("invoiceDateInputLabel")}
            value={values?.header?.invoiceDate?.split("T")[0] || ""}
            style={{ width: "100%" }}
          />
          <FormInput
            name="header.fromDate"
            type="date"
            min={new Date().toISOString().split("T")[0]}
            max={values?.header?.toDate ?? undefined}
            required={false}
            disabled={values.validated}
            label={t("fromDateInputLabel")}
            value={values?.header?.fromDate?.split("T")[0] || ""}
            style={{ width: "100%" }}
          />
          <FormInput
            name="header.toDate"
            type="date"
            min={values?.header?.fromDate ?? undefined}
            required={false}
            disabled={values.validated}
            label={t("toDateInputLabel")}
            value={values?.header?.toDate?.split("T")[0] || ""}
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs={12} sm={8}>
          <FormInput
            name="genericDocumentIdAsString"
            label={t("referenceNoInputLabel")}
            required={false}
            value={values.genericDocumentIdAsString}
            disabled={true}
          />
          <FormInput
            name="documentNo"
            label={t("documentNoInputLabel")}
            required={false}
            value={values.documentNo}
            disabled={true}
          />
          <FormInput
            name="date"
            type="date"
            required={true}
            min={new Date().toISOString().split("T")[0]}
            label={t("dateInputLabel")}
            value={values?.date?.split("T")[0] || ""}
            disabled={values.validated}
          />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div style={{ flex: 1, marginRight: "10px" }}>
              <FormSelect
                name="header.currencyId"
                label={t("currencyInputLabel")}
                style={{ width: "100%" }}
                required={false}
                value={values?.header?.currencyId}
                disabled={values.validated}
                options={currencies.map((currency) => ({
                  label: `${currency.currencySymbol} ${currency.currencyName}`,
                  value: currency.currencyId,
                }))}
              />
            </div>
            <div style={{ width: "100px" }}>
              <FormInput
                name="header.exchangeRate"
                type="number"
                required={false}
                label={t("exchangeRateInputLabel")}
                style={{ width: "100%" }}
                value={values?.header?.exchangeRate}
                disabled={
                  values?.header?.currencyId === undefined || values.validated
                }
              />
            </div>
          </div>

          <FormInput
            name="header.paymentDeadline"
            type="number"
            required={false}
            label={t("paymentDeadlineInputLabel")}
            value={values?.header?.paymentDeadline}
            disabled={values.validated}
          />

          <FormInput
            name="header.comment"
            label={t("commentInputLabel")}
            required={false}
            value={values?.header?.comment}
            disabled={values.validated}
          />
        </Col>
        <Col xs={12} sm={8}>
          <Spin tip="Loading..." spinning={isLoading}>
            <div
              className="ag-theme-quartz custom-theme-grid"
              style={{ height: 300, position: "relative" }}
            >
              <AgGridReact
                defaultColDef={defaultColDef}
                rowData={memoizedVatData}
                ref={finInvoiceRegistrationVatGridRef}
                columnDefs={vatColumnDefs}
                suppressRowHoverHighlight={true}
                suppressCellFocus={false}
                suppressRowClickSelection={true}
                rowSelection="single"
                paginationPageSizeSelector={[10, 20, 50, 100]}
              />
              {(values.validated ||
                finInvoiceRegistrationAccounting?.filter(
                  (account) => account.rowAction !== "D"
                )?.length > 0) && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    zIndex: 1000,
                    cursor: "not-allowed",
                  }}
                />
              )}
            </div>
          </Spin>
        </Col>
      </Row>
      <Row>
        <Col
          className="custom-row"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div  style={{
            display: "flex",
            gap: "15px",
          }}>
          <Button
            disabled={accoundDocumentButtonDisabled}
            onClick={accountDocument}
          >
            {t("accountDocument")}
          </Button>
          <Button
            disabled={finInvoiceRegistrationAccounting?.length === 0}
            onClick={openAccountModal}
          >
            {t("addAccountingDetail")}
          </Button>
         
          {copiedData && (
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Button
                onClick={pasteDetails}
                disabled={
                  finInvoiceRegistrationAccounting.length > 0 ||
                  !values.branchId ||
                  !values.documentTypeId ||
                  !values?.header?.entityId
                }
              >
                {t("paste")}
              </Button>
            </div>
          )}
          </div>
           <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
          <div>{t("debit")} <b>{balancInfo?.debitAmount.toFixed(2)}</b></div>
          <div>{t("credit")} <b>{balancInfo?.creditAmount.toFixed(2)}</b></div>
          <div>{t("balanceLeft")} <b>{balancInfo?.remainingInvoiceAmount}</b></div>
          </div>
        </Col>
      </Row>
      <Row gutter={[12, 8]}>
        <Col xs={24} sm={24}>
          <Spin tip="Loading..." spinning={isLoading}>
            <div
              className={`ag-theme-quartz custom-theme-grid`}
              style={{ height: 550, marginTop: 15, position: "relative" }}
            >
              <AgGridReact
                defaultColDef={defaultColDef}
                rowData={memoizedRowData}
                ref={finInvoiceRegistrationAccountingGridRef}
                columnDefs={columnDefs}
                suppressRowHoverHighlight={true}
                suppressCellFocus={false}
                suppressRowClickSelection={true}
                rowSelection="single"
                paginationPageSizeSelector={[10, 20, 50, 100]}
                onCellKeyDown={(event: CellKeyDownEvent) =>
                  handleNextGridColumnClick(event, searchInputRef)
                }
              />
              {values.validated && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    zIndex: 1000,
                    cursor: "not-allowed",
                  }}
                />
              )}
            </div>
          </Spin>
        </Col>
      </Row>
      <AddAccountingDetail
        isModalVisible={isModalVisible}
        values={values}
        t={t}
        handleOk={handleOk}
        handleCancel={handleCancel}
        financialAccounts={financialAccounts}
        invoiceRemainingAmount={balancInfo.remainingInvoiceAmount}
        finInvoiceRegistrationAccounting={finInvoiceRegistrationAccounting}
      />
    </>
  );
}
