import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getMenusAsync } from "../../../../redux/slices/menuSlice";
import { MenuDto } from "../../../../models/clientDashboard/Menu/MenuDto";
import { MenuRole } from "../../../../models/clientDashboard/MenuRole/MenuRole";
import { RoleDto } from "../../../../models/clientDashboard/Role/RoleDto";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { db } from "../../../../indexDB/clientSideDatabase";
import apiService from "../../../../extensions/api";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import { MenuOptionDto } from "../../../../models/clientDashboard/MenuOption/MenuOptionDto";
import { Formik } from "formik";
import { Col, Form, Row, Spin } from "antd";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import FormInput from "../../../CustomComponents/FormInput";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import RenderMenus from "./RenderMenus";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import useFormData from "../../../../customHooks/useFormData";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";

export default function CreateRole(){
    const t  = useAppTranslation("ClientDashboard.CreateRole");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const formikRef = useRef<any>(null);
  const menus = useSelector((state: RootState) => state.menu.menus);
  const [activeMenu, setActiveMenu] = useState<number | null>(null);
  const {initialValues, setInitialValues} = useFormData<RoleDto>('role/register',{
    roleId: 0,
    roleDescription: "",
    details: [],
  });
  
  useEffect(() => {
    dispatch(getMenusAsync());
  }, [dispatch]);

  const processMenuDetails = (
    menu: MenuDto,
    roleID: number,
    initialPermissionsSet: Set<number>,
    initialPermissionsMap: Map<number, boolean>
  ) => {
    const isRootModule = menu.parentId === null;

    if (menu.menuOptions) {
      menu.menuOptions.forEach((menu) => {
        const isViewPermission = menu.menuToolId === 8;
        // Enable view permissions only for root modules
        const disabled = !(isRootModule && isViewPermission);
        initialPermissionsSet.add(menu.menuOptionId);
        initialPermissionsMap.set(menu.menuOptionId, disabled);
      });
    }

    if (menu.subMenus) {
      menu.subMenus.forEach((subMenu) => {
        processMenuDetails(subMenu, roleID, initialPermissionsSet, initialPermissionsMap);
      });
    }
  };

  useEffect(() => {
    if (menus.length > 0) {
      const initialPermissionsSet = new Set<number>();
      const initialPermissionsMap = new Map<number, boolean>();
      menus.forEach((menu) => {
        processMenuDetails(menu, 0, initialPermissionsSet, initialPermissionsMap);
      });

      const initialPermissions: MenuRole[] = Array.from(initialPermissionsSet).map(menuOptionId => ({
        menuOptionId,
        status: false,
        roleId: 0,
        disabled: initialPermissionsMap.get(menuOptionId) ?? true
      }));

      setInitialValues({
        roleDescription: "",
        details: initialPermissions,
      });
    }
  }, [menus]);
  const validationSchema = Yup.object({
    roleDescription: Yup.string().required("Required"),
  });

  async function handleSubmit(values: RoleDto) {
    try {
      const allStatusFalse = values?.details?.every(role => !role.status);

      if (allStatusFalse) {
        setActiveMenu(1)
        toast.error('Please select at least one module detail role status as true.');
        return;
      }
      dispatch(setLoadingState(true));
      await apiService
      .post("/api/Role/create", values)
      .then((response) => {
          toast.success(t("roleCreatedSuccessfully"));
          db.deleteTab('role/register');
          navigate("/roles");
      })
      .catch(() => { })
      .finally(() => {
          dispatch(setLoadingState(false));
      });
    } catch (error) {
      console.log(error);
    }
  }
  const organizeMenus = (menus: MenuDto[]): MenuDto[] => {
    const menuMap: { [key: number]: MenuDto } = {};
    const roots: MenuDto[] = [];
  
    menus.forEach((menu) => {
      menuMap[menu.menuId] = { ...menu };
    });
  
    menus.forEach((menu) => {
      if (menu.parentId) {
        menuMap[menu.parentId]?.subMenus?.push(menuMap[menu.menuId]);
      } else {
        roots.push(menuMap[menu.menuId]);
      }
    });
  
    return roots;
  };
  const organizeMenusForRendering = (menus: MenuDto[]): MenuDto[] => {
    // if (!activeMenu) return [];
    const menuMap: { [key: number]: MenuDto } = {};

    menus.forEach((menu) => {
      menuMap[menu.menuId] = { ...menu };
    });

    const organizedMenus: MenuDto[] = [];

    menus.forEach((menu) => {
      if (menu.parentId === activeMenu) {
        organizedMenus.push(menuMap[menu.menuId]);
      }
    });
    return organizedMenus;
  };

  const findMenuDetail = (
    menus: MenuDto[],
    menuOptionId: number
  ): { menu: MenuDto; menuOption: MenuOptionDto } | null => {
    for (const menu of menus) {
      for (const menuOption of menu.menuOptions) {
        if (menuOption.menuOptionId === menuOptionId) {
          return { menu, menuOption };
        }
      }
      const foundInSubMenu = findMenuDetail(menu.subMenus??[], menuOptionId);
      if (foundInSubMenu) {
        return foundInSubMenu;
      }
    }
    return null;
  };
  const handleCheckboxChange = (
    menuOptionId: number,
    checked: boolean,
    values: RoleDto,
    setFieldValue: (fieldName: string, value: any) => void
) => {
    let menuRoles = values?.details?.map((detailRole) =>
        detailRole.menuOptionId === menuOptionId
            ? { ...detailRole, status: checked }
            : detailRole
    );
    const menuDetailInfo = findMenuDetail(organizeMenus(menus), menuOptionId);
    if (!menuDetailInfo) return;

    const { menu, menuOption } = menuDetailInfo;
    if (menu.parentId === null && checked && menuOption.menuToolId === 8) {
      menuRoles = menuRoles?.map((detailRole) =>
          menu.menuOptions.some((detail) => detail.menuOptionId === detailRole.menuOptionId && detail.menuOptionId !== menuOptionId)
              ? { ...detailRole, status: false, disabled: false }
              : detailRole
      );

      if (menu.subMenus && menu.subMenus.length > 0) {
        menu?.subMenus?.forEach((subMenu) => {
            subMenu.menuOptions.forEach((detail) => {
                if (detail.menuToolId === 8) {
                    menuRoles = menuRoles?.map((detailRole) =>{
                        if(detailRole.menuOptionId === detail.menuOptionId){
                            return { ...detailRole, disabled: false }}
                            else{
                              return detailRole
                            }
                          }
                    );
                }
            });
        });
      }
    }
    else if(menu.parentId === null && !checked && menuOption.menuToolId === 8){
        menuRoles = menuRoles?.map((detailRole) =>
          menu.menuOptions.some((detail) => detail.menuOptionId === detailRole.menuOptionId && detail.menuOptionId !== menuOptionId)
              ? { ...detailRole, status: false, disabled: true }
              : detailRole
        );
        if (menu.subMenus && menu.subMenus.length > 0) {
          menu.subMenus.forEach((subMenu) => {
            subMenu.menuOptions.forEach((detail) => {
                      menuRoles = menuRoles?.map((detailRole) =>{
                          if(detailRole.menuOptionId === detail.menuOptionId){
                              return { ...detailRole,status:false, disabled: true }}
                              else{
                                return detailRole
                              }
                            }
                      );
              });
          });
        }
    }else if(menu.parentId !== null && checked && menuOption.menuToolId === 8){
      menu.menuOptions.forEach((detail) => {
        menuRoles = menuRoles?.map((detailRole) =>
          detailRole.menuOptionId === detail.menuOptionId && detail.menuOptionId !== menuOptionId
            ? { ...detailRole, status: false, disabled: false }
            : detailRole
        );
      });
    }
    else if(menu.parentId !== null && !checked && menuOption.menuToolId === 8){
      menu.menuOptions.forEach((detail) => {
        menuRoles = menuRoles?.map((detailRole) =>
          detailRole.menuOptionId === detail.menuOptionId && detail.menuOptionId !== menuOptionId
            ? { ...detailRole, status: false, disabled: true }
            : detailRole
        );
      });
    }
    setFieldValue("details", menuRoles);
};

const handleSelectAll = (
  menuId: number,
  checked: boolean,
  values: RoleDto,
  setFieldValue: (fieldName: string, value: any) => void
) => {
  const organizedMenus = organizeMenus(menus);

  const menu = findMenuById(organizedMenus, menuId);
  if (!menu) return;

  let menuRoles = [...values?.details];

  menu.menuOptions.forEach((menuOption) => {
    menuRoles = menuRoles.map((detailRole) =>
      detailRole.menuOptionId === menuOption.menuOptionId
        ? { ...detailRole, status: checked }
        : detailRole
    );
  });

  if (menu.subMenus && menu.subMenus.length > 0) {
    menu.subMenus.forEach((subMenu) => {
      subMenu.menuOptions.forEach((subMenuOption) => {
        menuRoles = menuRoles.map((detailRole) =>
          detailRole.menuOptionId === subMenuOption.menuOptionId
            ? { ...detailRole, status: checked,disabled:!checked }
            : detailRole
        );
      });
    });
  }
  setFieldValue("details", menuRoles);
};

const findMenuById = (menus: MenuDto[], menuId: number): MenuDto | undefined => {
  for (const menu of menus) {
    if (menu.menuId === menuId) {
      return menu;
    }
    const foundInSubMenu = findMenuById(menu.subMenus ?? [], menuId);
    if (foundInSubMenu) {
      return foundInSubMenu;
    }
  }
  return undefined;
};
    return (
        <Spin tip="Loading..." spinning={isLoading}>
    
    <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={initialValues ?? { 
                    roleDescription: "",
                    details: [],
                 } as RoleDto}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <Form onFinish={handleSubmit}>
                     <FormHeaderOptions
                            title={t("formTitle")}
                            handleSubmitForm={handleSubmit}
                            handleSubmitValidation={async () => {
                                //handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("createRoleButton")}
                            submitButtonIsDisabled={isLoading}
                            createAccessEnum={MenuOptionEnum.RoleCreate}
                        />
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <FormInput
                                    name="roleDescription"
                    
                                    label={t("roleDescriptionInputLabel")}
                                    required={true}
                                    value={values.roleDescription}
                                />
                                </Col>
                                </Row>
                                <Row style={{ display: "flex" }}>
  <Col xs={24} sm={24} style={{ height: "600px", overflowY: "auto", gap: "20px" }}>
    <RenderMenus
      menus={organizeMenusForRendering(menus)}
      values={values}
      handleCheckboxChange={(moduleDetailID, checked) => {
        handleCheckboxChange(moduleDetailID, checked, values, setFieldValue);
      }}
      handleSelectAll= {(menuId,checked)=>{
        handleSelectAll(menuId,checked,values,setFieldValue)
      }}
      paddingLeft={30}
    />
  </Col>
</Row>
                 
                    <HandleFormDataForTabSaving tabPath="role/register" />                   
                </Form>
              )}
            </Formik>
        </Spin>
    )
}