// DashboardLoggedIn.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import { useDashboardAuth } from "../../customHooks/useDashboardAuth";
import Login from "./Login";

const ProtectedDashboardLoggedIn: React.FC = () => {
  const isDashboardLoggedIn = useDashboardAuth();
  return isDashboardLoggedIn ? <Outlet /> : <Login />;
};

export default ProtectedDashboardLoggedIn;
