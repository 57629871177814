export default function InternalMovementsIcon() {
  return (
    <svg
      fill="#5E5E5E"
      width="20px"
      height="20px"
      className="icon-for-sidebar"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#5E5E5E"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g data-name="26 delivery truck" id="_26_delivery_truck">
          {" "}
          <path d="M17.03,44.12a3.175,3.175,0,1,0,3.18,3.17A3.18,3.18,0,0,0,17.03,44.12Zm0,4.35a1.175,1.175,0,1,1,1.18-1.18A1.176,1.176,0,0,1,17.03,48.47Z"></path>{" "}
          <path d="M48.63,44.12a3.175,3.175,0,1,0,3.18,3.17A3.18,3.18,0,0,0,48.63,44.12Zm0,4.35a1.175,1.175,0,1,1,1.18-1.18A1.176,1.176,0,0,1,48.63,48.47Z"></path>{" "}
          <path d="M51.52,24.73l-4.11-4.35a1.007,1.007,0,0,0-.73-.32h-4.6a1,1,0,0,0-1,1V27.6a1,1,0,0,0,1,1h8.71a1,1,0,0,0,1-1V25.42A1.03,1.03,0,0,0,51.52,24.73ZM49.79,26.6H43.08V22.06h3.17l3.54,3.75Z"></path>{" "}
          <path d="M60.02,28.83,49.7,16.46a2.113,2.113,0,0,0-1.6-.76H38.81a2.1,2.1,0,0,0-2.09,2.09V30.95H35.46V21.06a1,1,0,0,0-1-1H27.83V10.17a1,1,0,0,0-1-1H15.94a1,1,0,0,0-1,1v9.89H8.32a1,1,0,0,0-1,1v9.89H4.5a1,1,0,0,0-1,1V42.84a1,1,0,0,0,1,1h5.84a7.535,7.535,0,1,0,14.23,3.45,7.461,7.461,0,0,0-.85-3.45H41.94a7.531,7.531,0,1,0,14.22,3.45,7.453,7.453,0,0,0-.84-3.45H59.5a1,1,0,0,0,1-1V30.17A2.118,2.118,0,0,0,60.02,28.83ZM33.46,22.06v8.89H24.57V22.06ZM16.94,11.17h8.89v8.89H16.94Zm5.63,10.89v8.89H20.21V22.06Zm-13.25,0h8.89v8.89H9.32Zm7.71,30.77a5.521,5.521,0,0,1-4.09-9.24,1.5,1.5,0,0,0,.11-.13,5.533,5.533,0,1,1,3.98,9.37ZM36.72,41.84H22.22a7.5,7.5,0,0,0-10.37,0H5.5V32.95H36.72ZM48.63,52.83a5.535,5.535,0,1,1,5.53-5.54A5.537,5.537,0,0,1,48.63,52.83ZM58.5,37.49H56.14V35.13H58.5Zm0-4.36H56.14a2.006,2.006,0,0,0-2,2v2.36a2.006,2.006,0,0,0,2,2H58.5v2.35H53.81a7.49,7.49,0,0,0-10.36,0H38.72V17.79a.1.1,0,0,1,.09-.09l9.35.04L58.5,30.17Z"></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}
