import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SelectOption } from "../../../models/SelectOption";
import { CreateOrUpdateFinInvoiceRegistration } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistration";
import { CreateOrUpdateFinInvoiceRegistrationAccounting } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationAccounting";
import { CreateOrUpdateFinInvoiceRegistrationVat } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationVat";
import { v4 as uuidv4 } from "uuid";
import { CreateOrUpdateFinInvoiceRegistrationDelayedExpense } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationDelayedExpense";
import { GenericDocumentDto } from "../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { getFinInvoiceRegistrationDataById, invalidateFinInvoiceRegistrationAsync, validateFinInvoiceRegistrationAsync } from "../../../redux/slices/finInvoiceRegistrationSlice";
import moment from "moment";
import { DocumentTypeEnum } from "../../../enums/DocumentTypeEnum";
import useFormData from "../../../customHooks/useFormData";
import * as Yup from "yup";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import apiService from "../../../extensions/api";
import { db } from "../../../indexDB/clientSideDatabase";
import { getItemsWithFilters } from "../../../redux/slices/itemSlice";
import { getClassifiersByTypeAsync, getClassifiersForSubjectBranchesAsync } from "../../../redux/slices/classifierSlice";
import { getEntitiesByTypeAsync } from "../../../redux/slices/entitySlice";
import { getCurrenciesAsync } from "../../../redux/slices/currencySlice";
import { getDocumentTypesWithSignHAsync } from "../../../redux/slices/documentTypeSlice";
import { getUserBranches } from "../../../redux/slices/userSlice";
import { getBrandsAsync } from "../../../redux/slices/brandSlice";
import { Classifier } from "../../../models/clientDashboard/Classifier/Classifier";
import { CopiedDocumentDetailsTable } from "../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { Form, Spin, Tabs } from "antd";
import { Formik } from "formik";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import MainFieldsComponent from "./MainFieldsComponent";
import { getFinancialAccountsAsync, getFinancialAccountsForFinancialFinInvoicesAsync } from "../../../redux/slices/accountingConfigurationSlice";
import ClassifiersComponent from "./ClassifiersComponent";
import ExpensesAndVatsComponent from "./ExpensesAndVatsComponent";
import { GetFinancialAccountsDto } from "../../../models/clientDashboard/AccountinConfigurations/GetFinancialAccountsDto";
import { getTaxesAsync } from "../../../redux/slices/taxSlice";

export default function CreateFinInvoiceRegistration(){
    const navigate = useNavigate();
    const { mode, id, branchId } = useParams<{
        mode: string;
        id?: string;
        branchId?: string;
    }>();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const dispatch = useDispatch<AppDispatch>();
    const t = useAppTranslation("ClientDashboard.CreateFinInvoiceRegistration");
    const formikRef = useRef<any>(null);
    const [entityOptions, setEntityOptions] = useState<SelectOption[]>([]);
    const userBranches = useSelector(
        (state: RootState) => state.user.loggedInUserBranches
    );
    const vats = useSelector((state:RootState)=>state.tax.taxes);

    const [finInvoiceRegistrationAccounting, setFinInvoiceRegistrationAccounting] = useState<
        CreateOrUpdateFinInvoiceRegistrationAccounting[]
    >([]);
    const [finInvoiceRegistrationVats, setFinInvoiceRegistrationVats] = useState<
    CreateOrUpdateFinInvoiceRegistrationVat[]
>([]);
const [finInvoiceRegistrationDelayedExpenses, setFinInvoiceRegistrationDelayedExpenses] = useState<
    CreateOrUpdateFinInvoiceRegistrationDelayedExpense[]
>([]);
    const generatedIdForCorrection = uuidv4();

    const classifiers = useSelector(
        (state: RootState) => state.classifier.classifiers
    );
    const [isDocumentValidated, setIsDocumentValidated] = useState<
        boolean | null
    >(null);

    const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
        setEntityOptions(options);
    };
    const user = useSelector((state: RootState) => state.user.loggedInUser);
    const setAdditionalStates = useCallback(
        (
            data: GenericDocumentDto<
                CreateOrUpdateFinInvoiceRegistration,
                CreateOrUpdateFinInvoiceRegistrationAccounting
            >
        ) => {
            if (data?.details) {
                setFinInvoiceRegistrationAccounting(data.details);
            }
            if(data?.header?.finInvoiceRegistrationDelayedExpenses){
                setFinInvoiceRegistrationDelayedExpenses(data.header.finInvoiceRegistrationDelayedExpenses);
            }
            if(data?.header?.finInvoiceRegistrationVats){
                setFinInvoiceRegistrationVats(data.header.finInvoiceRegistrationVats);
            }
        },
        []
    );

    const getData = async () => {
        if (id && branchId) {
            const response = await dispatch(
                getFinInvoiceRegistrationDataById({ finInvoiceRegistrationId: id, branchID: branchId })
            );
            const data = response.payload as GenericDocumentDto<
                CreateOrUpdateFinInvoiceRegistration,
                CreateOrUpdateFinInvoiceRegistrationAccounting>;
            if (
                response.type ===
                "FinInvoiceRegistrations/getFinInvoiceRegistrationDataById/fulfilled"
            ) {
                const finInvoiceRegistration = response.payload as GenericDocumentDto<
                    CreateOrUpdateFinInvoiceRegistration,
                    CreateOrUpdateFinInvoiceRegistrationAccounting
                >;

                setInitialValues(finInvoiceRegistration);
                setEntityOptions([
                    {
                        key: finInvoiceRegistration?.header?.entityId,
                        label: finInvoiceRegistration?.header?.entityName ?? "",
                        value: finInvoiceRegistration?.header.entityId ?? 0,
                    }
                ]);

                formikRef?.current?.setValues(finInvoiceRegistration);
                const updatedDetails = finInvoiceRegistration.details.map((detail) => ({
                    ...detail,
                }));
                setFinInvoiceRegistrationAccounting(updatedDetails);
                setFinInvoiceRegistrationDelayedExpenses(finInvoiceRegistration.header.finInvoiceRegistrationDelayedExpenses);
                setFinInvoiceRegistrationVats(finInvoiceRegistration.header.finInvoiceRegistrationVats);
            }
            return data;
        }
        return {
            generatedId: mode !== "update" ? uuidv4() : "",
            header: {},
            date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
            branchId:
                user?.isMainBranch === false
                    ? Number(user.branchId)
                    : userBranches.length === 1
                        ? userBranches[0].branchId
                        : undefined,
        } as GenericDocumentDto<
            CreateOrUpdateFinInvoiceRegistration,
            CreateOrUpdateFinInvoiceRegistrationAccounting>;
    }

    const { initialValues, setInitialValues } = useFormData<
        GenericDocumentDto<
            CreateOrUpdateFinInvoiceRegistration,
            CreateOrUpdateFinInvoiceRegistrationAccounting>
    >(
        mode === "update"
            ? `finInvoiceRegistration/update/${id}/${branchId}`
            : `finInvoiceRegistration/register`,
        {
            generatedId: mode !== "update" ? uuidv4() : "",
            header: {},
            date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
            branchId:
                user?.isMainBranch === false
                    ? Number(user.branchId)
                    : userBranches.length === 1
                        ? userBranches[0].branchId
                        : undefined,
        
        } as GenericDocumentDto<
        CreateOrUpdateFinInvoiceRegistration,
        CreateOrUpdateFinInvoiceRegistrationAccounting>,
        setAdditionalStates,
        {
            fetchData: getData
        }
    );
    const removeFinInvoiceRegistrationAccounting = (index: number) => {
        setFinInvoiceRegistrationAccounting((prevFinInvoiceRegistrationAccounting) => {
            const activeFinInvoiceRegistrationAccounts = prevFinInvoiceRegistrationAccounting.filter(
                (finInvoiceRegistrationAccounting) => finInvoiceRegistrationAccounting.rowAction !== "D"
            );
            const selectedFinInvoiceRegistrationAccount =
                activeFinInvoiceRegistrationAccounts[index];
            if (
                selectedFinInvoiceRegistrationAccount.finInvoiceRegistrationAccountingId === 0 ||
                !selectedFinInvoiceRegistrationAccount.finInvoiceRegistrationAccountingId
            ) {
                prevFinInvoiceRegistrationAccounting.splice(index, 1);
            } else {
                selectedFinInvoiceRegistrationAccount.rowAction = "D";
            }
            return [...prevFinInvoiceRegistrationAccounting];
        });
    };
    const addFinInvoiceRegistrationAccounting = (
        finInvoiceRegistrationAccounting:CreateOrUpdateFinInvoiceRegistrationAccounting
    ) => {
        setFinInvoiceRegistrationAccounting((prevFinInvoiceRegistrationAccounting) => {
            return [...prevFinInvoiceRegistrationAccounting, finInvoiceRegistrationAccounting];
        });
    };

    const validationSchema = Yup.object({
        branchId: Yup.number().required(t("branchIsRequired")),
        documentTypeId: Yup.number().required(t("documentTypeIsRequired")),
        date: Yup.date().required(t("dateIsRequired")),
        header: Yup.object({
            entityId: Yup.number()
                .required(t("subjectIsRequired"))
        }),
    });
    const balancInfo = useMemo(() => {
        const debit = finInvoiceRegistrationAccounting
          .filter((account) => account.dk === "D")
          .reduce((total, account) => total + account.amount, 0);
    
        const credit = finInvoiceRegistrationAccounting
          .filter((account) => account.dk === "K")
          .reduce((total, account) => total + account.amount, 0);
        const invoiceAmount = finInvoiceRegistrationVats.reduce((total, vat) => total + vat.invoiceAmount, 0);
        return {
          totalInvoiceAmount:invoiceAmount,
          remainingInvoiceAmount: Number((debit - credit).toFixed(2)),
          debitAmount: debit,
          creditAmount: credit,
        };
      }, [finInvoiceRegistrationVats, finInvoiceRegistrationAccounting]);
    
      const onFinish = async (
        values: GenericDocumentDto<
            CreateOrUpdateFinInvoiceRegistration,
            CreateOrUpdateFinInvoiceRegistrationAccounting
        >
    ) => {
        dispatch(setLoadingState(true));
        if (finInvoiceRegistrationAccounting.length === 0) {
            toast.error(t("atLeastOneDetailIsRequired"));
            dispatch(setLoadingState(false));
            return;
        }
        if (user?.isMainBranch && !values.branchId) {
            toast.error(t("branchIsRequired"));
            dispatch(setLoadingState(false));
            return;
        }
        if(balancInfo.remainingInvoiceAmount !== 0){
            toast.error(t("balanceIsNotZero"));
            dispatch(setLoadingState(false));
            return;
        }
        values.details = finInvoiceRegistrationAccounting;
        values.header.finInvoiceRegistrationVats = finInvoiceRegistrationVats;
        values.header.finInvoiceRegistrationDelayedExpenses = finInvoiceRegistrationDelayedExpenses;


        if (mode === "update" && id) {
            const result = await apiService
                .post(`/api/FinInvoiceRegistration/update`, values)
                .then(async (response) => {
                    if (response.status === 200) {
                        toast.success(t("updatedSuccessfully"));
                        getData();
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        } else {
            await apiService
                .post("/api/FinInvoiceRegistration/create", values)
                .then(async (response) => {
                    toast.success(t("createdSuccessfully"));
                    formikRef.current.setFieldValue(
                        "genericDocumentId",
                        response.data.Data
                    );
                    formikRef.current.setFieldValue(
                        "genericDocumentIdAsString",
                        response.data.Data
                    );

                    const createdId = response.data.Data;

                    await db.updateTab(
                        `finInvoiceRegistrations/register`,
                        `finInvoiceRegistrations/update/${createdId}/${values.branchId}`,
                        t("tabs.updateFinInvoiceRegistration")
                    );

                    navigate(`/finInvoiceRegistration/update/${createdId}/${values.branchId}`);
                })
                .catch(() => { })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        }
        dispatch(setLoadingState(false));
    };
    const [financialAccounts, setFinancialAccounts] = useState<
    GetFinancialAccountsDto[]
  >([]);
    const getFinancialAccounts = async () => {
        const result = await dispatch(getFinancialAccountsForFinancialFinInvoicesAsync());
        if (result.payload !== "An error occurred") {
          setFinancialAccounts(result.payload as GetFinancialAccountsDto[]);
        }
        return [];
      };


    useEffect(() => {
        dispatch(getItemsWithFilters([]));
        dispatch(getClassifiersByTypeAsync("FinInvoiceRegistrations"));
        dispatch(getEntitiesByTypeAsync(true));
        dispatch(getCurrenciesAsync());
        dispatch(getDocumentTypesWithSignHAsync("Shpen"));
        dispatch(getUserBranches());
        dispatch(getBrandsAsync());
        dispatch(getTaxesAsync());
        getFinancialAccounts();
        // dispatch(getFinancialAccountsAsync())
    }, [dispatch, mode, id]);

    const [tab, setTab] = useState<any>(null);

    useEffect(() => {
        const updateIndexedDB = async () => {
            setTab(await db.tabs.get(`finInvoiceRegistrations/update/${id}`));

            const tab = await db.tabs.get(
                mode === "update"
                    ? `finInvoiceRegistrations/update/${id}`
                    : "finInvoiceRegistrations/register"
            );
            if (tab) {
                await db.tabs.put({
                    ...tab,
                    data: {
                        ...tab.data,
                        details: finInvoiceRegistrationAccounting
                    },
                });
            }
        };

        updateIndexedDB();
    }, [finInvoiceRegistrationAccounting]);
    

    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm();
        setTouched({
            genericDocumentId: true,
            genericDocumentIdAsString: true,
            branchId: true,
            documentTypeId: true,
            date: true,
            documentNo: true,
            header: true,
            generatedId: true,
            registeredBy: true,
            validated: true,
            details: true
        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    };
    const handleFinInvoiceRegistrationValidation = async (
        finInvoiceRegistrationId: string,
        branchId: string
    ) => {
        const result = await dispatch(
            validateFinInvoiceRegistrationAsync({
                finInvoiceRegistrationId:finInvoiceRegistrationId,
                branchID: branchId ?? "",
            })
        );
        if (result.type === "FinInvoiceRegistrations/validateFinInvoiceRegistration/fulfilled") {
            toast.success(t("validatedSuccessfully"));
            formikRef.current.setFieldValue("validated", true);
        } else {
            toast.error(t("ValidationFailed"));
        }
    };

    const handleFinInvoiceRegistrationInvalidation = async (
        finInvoiceRegistrationId: string,
        branchId: number
    ) => {
        const result = await dispatch(
            invalidateFinInvoiceRegistrationAsync({
                finInvoiceRegistrationId: finInvoiceRegistrationId,
                branchID: branchId,
            })
        );
        if (
            result.type === "FinInvoiceRegistrations/invalidateFinInvoiceRegistration/fulfilled"
        ) {
            toast.success(t("invalidatedSuccessfully"));
            formikRef.current.setFieldValue("validated", false);
        } else {
            toast.error(t("invalidationFailed"));
        }
    };

    const isInvalidateButtonDisabled = (
        values: GenericDocumentDto<
            CreateOrUpdateFinInvoiceRegistration,
            CreateOrUpdateFinInvoiceRegistrationAccounting
        >
    ) => {
        return isLoading || !values.validated;
    };
    const copyDetails = () => {
        const details: CopiedDocumentDetailsTable<CreateOrUpdateFinInvoiceRegistrationAccounting> = {
            id: "FinInvoiceRegistrationAccounting",
            list: finInvoiceRegistrationAccounting
        }
        db.upsertGenericData(details);
    }
    const handelVatsOnDocumentTypeChange = (documentTypeId: number) => {
         if(documentTypeId !== DocumentTypeEnum.purchasesWithoutVAT){
            const newVats:CreateOrUpdateFinInvoiceRegistrationVat[] = vats?.map((vat)=>{
                return {
                    finInvoiceRegistrationVatId:0,
                    finInvoiceRegistrationId:"",
                    vat:vat.value,
                    invoiceAmount:0
                }
            });
            setFinInvoiceRegistrationVats(newVats);
         }else{
            setFinInvoiceRegistrationVats([{
                finInvoiceRegistrationVatId:0,
                finInvoiceRegistrationId:"",
                vat:0,
                invoiceAmount:0
            }]);
         }
    }
    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={
                    initialValues ??
                    ({} as GenericDocumentDto<
                        CreateOrUpdateFinInvoiceRegistration,
                        CreateOrUpdateFinInvoiceRegistrationAccounting
                    >)
                }
                validationSchema={validationSchema}
                onSubmit={onFinish}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    handleSubmit,
                    submitForm,
                    validateForm,
                    setTouched,
                    setFieldValue,
                }) => (
                    <>
                        <FormHeaderOptions
                            title={
                                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
                            }
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("createButton")}
                            submitButtonIsDisabled={
                                isLoading ||
                                finInvoiceRegistrationAccounting.filter(
                                    (detail) => detail.rowAction !== "D"
                                ).length === 0 ||
                                values.validated
                            }
                            createAccessEnum={MenuOptionEnum.EntryOrderCreate}
                            validateAccessEnum={MenuOptionEnum.EntryOrderValidate}
                            validateButtonIsDisabled={
                                isLoading || mode !== "update" || values.validated
                            }
                            validateButtonText={t("validateButton")}
                            handleDocumentValidation={async () => {
                                if (values.genericDocumentIdAsString) {
                                    await handleFinInvoiceRegistrationValidation(
                                        values.genericDocumentIdAsString,
                                        values?.branchId?.toString() ?? ""
                                    );
                                }
                            }}
                            invalidateAccessEnum={MenuOptionEnum.EntryOrderInvalidate}
                            invalidateButtonText={t("invalidateButton")}
                            invalidateButtonIsDisabled={isInvalidateButtonDisabled(values)}
                            handleDocumentInvalidation={async () => {
                                await handleFinInvoiceRegistrationInvalidation(
                                    values.genericDocumentIdAsString ?? "",
                                    values?.branchId ?? 0
                                );
                            }}
                            copyButtonIsDisabled={
                                isLoading || mode !== "update" || finInvoiceRegistrationAccounting.length === 0
                            }
                            copyButtonText={t("CopyButton")}
                            handleCopyDetails={mode === "update" ? () => copyDetails() : undefined}
                        />

                        <Form onFinish={handleSubmit} layout="vertical">
                            <Tabs
                                defaultActiveKey="1"
                                items={[
                                    {
                                        label:t("finInvoiceRegistrations"),
                                        key: "1",
                                        children: (
                                            <MainFieldsComponent
                                                entityOptions={entityOptions}
                                                setFinInvoiceRegistrationAccounting={setFinInvoiceRegistrationAccounting}
                                                values={values}
                                                finInvoiceRegistrationAccounting={finInvoiceRegistrationAccounting}
                                                addFinInvoiceRegistrationAccounting={addFinInvoiceRegistrationAccounting}
                                                removeFinInvoiceRegistrationAccounting={
                                                    removeFinInvoiceRegistrationAccounting
                                                }
                                                mode={mode ?? ""}
                                                setFieldValue={setFieldValue}
                                                financialAccounts={financialAccounts}
                                                handelVatsOnDocumentTypeChange={handelVatsOnDocumentTypeChange}
                                                finInvoiceRegistrationVats={finInvoiceRegistrationVats}
                                                setFinInvoiceRegistrationVats={setFinInvoiceRegistrationVats}
                                                balancInfo={balancInfo}
                                            />
                                        ),
                                    },
                                    {
                                        label:t("classifiers"),
                                        key: "2",
                                        children: (
                                            <ClassifiersComponent
                                                values={values}
                                                mode={mode ?? ""}
                                                setFieldValue={setFieldValue}
                                            />
                                        ),
                                    },
                                    {
                                        label:t("expensesAndVats"),
                                        key: "3",
                                        children: (
                                            <ExpensesAndVatsComponent
                                                values={values}
                                                mode={mode ?? ""}
                                                setFieldValue={setFieldValue}
                                                t={t}
                                                finInvoiceRegistrationVats={finInvoiceRegistrationVats}
                                                finInvoiceRegistrationDelayedExpenses={finInvoiceRegistrationDelayedExpenses}
                                                setFinInvoiceRegistrationDelayedExpenses={setFinInvoiceRegistrationDelayedExpenses}   
                                                financialAccounts={financialAccounts}
                                                balancInfo={balancInfo}
                                            />
                                        ),
                                    },
                                ]}
                            />

                            <HandleFormDataForTabSaving
                                tabPath={
                                    mode === "update"
                                        ? `finInvoiceRegistrations/update/${id}/${branchId}`
                                        : `finInvoiceRegistrations/register`
                                }
                                additionalStates={{
                                    details: finInvoiceRegistrationAccounting,
                                }}
                            />
                        </Form>
                    </>
                )}
            </Formik>
        </Spin>
    );
}