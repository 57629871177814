import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CreateAndEditPricesDto } from "../../../../models/clientDashboard/ItemPrices/Prices/CreateAndEditPricesDto";
import { PricesDetail } from "../../../../models/clientDashboard/ItemPricesDetail/PricesDetail/PricesDetail";
import useFormData from "../../../../customHooks/useFormData";
import moment from "moment";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import apiService from "../../../../extensions/api";
import { db } from "../../../../indexDB/clientSideDatabase";
import {
  getPriceDataById,
  invalidatePriceAsync,
  validatePriceAsync,
} from "../../../../redux/slices/priceSlice";
import { getUserBranches } from "../../../../redux/slices/userSlice";
import { getDocumentTypesWithSignHAsync } from "../../../../redux/slices/documentTypeSlice";
import { AutoComplete, Button, Col, Form, Modal, Row, Spin, Tabs } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import FormInput from "../../../CustomComponents/FormInput";
import FormSelect from "../../../CustomComponents/FormSelect";
import Search from "antd/es/input/Search";
import {
  getItemDetailsForImportPurchase,
  getItemsBySearchValue,
} from "../../../../redux/slices/itemSlice";
import { ItemDescriptionIdDto } from "../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { GetItemDetailsDto } from "../../../../models/clientDashboard/Item/GetItemDetailsDto";
import { debounce } from "../../../../helperMethods/debounce";
import { focusOnAgGridEditingCell } from "../../../../helperMethods/focusOnAgGridEditingCell";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import { getPriceDetailColumns } from "../../ItemPricesDetails/PricesDetails/AgGridPricesDetailColumns";
import { getBranchesAsync } from "../../../../redux/slices/branchSlice";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { handleNextGridColumnClick } from "../../../../helperMethods/handleNextGridColumnClick";
import { getEntitiesByTypeAsync } from "../../../../redux/slices/entitySlice";
import { v4 as uuidv4 } from "uuid";
import { BranchPricesDetail } from "../../../../models/clientDashboard/ItemPricesDetail/PricesDetail/BranchPricesDetail";
import { NumberLiteralType } from "typescript";
import ChoosePurchaseModal from "./ChoosePurchaseModal";
import { DocumentTypeEnum } from "../../../../enums/DocumentTypeEnum";
import { CopiedDocumentDetailsTable } from "../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { mapDataToInterface } from "../../../../helperMethods/mapDataToInterface";
import { SelectOption } from "../../../../models/SelectOption";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";

export default function CreatePrice() {
  const navigate = useNavigate();
  const { mode, id, branchId } = useParams<{
    mode: string;
    id?: string;
    branchId?: string;
  }>();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.CreatePrice");
  const formikRef = useRef<any>(null);
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const [copiedData, setCopiedData] = useState<CopiedDocumentDetailsTable<any> | undefined>(undefined);
  const [selectedBranches, setSelectedBranches] = useState<number[]>([]);
  const branches = useSelector((state: RootState) => state.branch.branches);
  const priceDetailGridRef = useRef(null);
  const [pricesDetail, setPricesDetails] = useState<PricesDetail[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const searchInputRef = useRef<any | null>(null);
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const suppliers = useSelector((state: RootState) => state.entity.entities);
  const documentTypes = useSelector(
    (state: RootState) => state.documentType.documentTypes
  );
  const pricesDetailGridRef = useRef(null);
  const [initialPriceDetails, setInitialPriceDetails] = useState<
    PricesDetail[]
  >([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [isDocumentValidated, setIsDocumentValidated] = useState<
    boolean | null
  >(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const setAdditionalStates = useCallback((data: CreateAndEditPricesDto) => {
    if (data.priceDetails) {
      setPricesDetails(data.priceDetails);
    }
  }, []);

  const removePriceDetail = (index: number) => {
    setPricesDetails((prevPricesDetails) => {
      const activePricesDetails = prevPricesDetails.filter(
        (pricesDetails) => pricesDetails.rowAction !== "D"
      );

      const selectedPricesDetail = activePricesDetails[index];
      if (
        selectedPricesDetail.pricesDetailsId === 0 ||
        !selectedPricesDetail.pricesDetailsId
      ) {
        prevPricesDetails.splice(index, 1);
      } else {
        selectedPricesDetail.rowAction = "D";
      }
      const isStartDateToday = (startDate: string) => {
        const today = moment().startOf("day");
        const startDateMoment = moment(startDate).startOf("day");
        return today.isSame(startDateMoment);
      };

      return [...prevPricesDetails];
    });
  };

  const branchesForPriceDetail = branches
    ?.filter(
      (branch) =>
        selectedBranches.find(
          (selectedBranch) => selectedBranch === branch.branchId
        ) === branch.branchId
    )
    .map((branch) => {
      return {
        branchId: branch.branchId,
        branchName: branch.description,
        branchPrice: 0,
        itemId: 0,
      };
    });
  const [columnDefs, setColumnDefs] = useState<ColDef<PricesDetail>[]>([]);

  useMemo(() => {
    setColumnDefs(getPriceDetailColumns(
      removePriceDetail,
      t,
      searchInputRef,
      mode ?? "",
      branchesForPriceDetail
    ))
  }, [selectedBranches, pricesDetail]);

  const getData = async () => {
    if (id && branchId) {
      const response = await dispatch(
        getPriceDataById({ pricesId: id, branchID: Number(branchId) })
      );
      const data = response.payload as CreateAndEditPricesDto;
      if (response.type === "Price/getPriceDataById/fulfilled") {
        const Price = response.payload as CreateAndEditPricesDto;
        formikRef.current.setValues(Price);
        setInitialPriceDetails(Price.details);
        const groupedDetails: PricesDetail[] = [];

        Price.details.forEach((detail) => {
          const existingDetail = groupedDetails.find(
            (d) => d.itemId === detail.itemId
          );

          if (existingDetail) {
            existingDetail.branchPriceDetails = [
              ...existingDetail.branchPriceDetails,
              ...createBranchPriceDetails(detail),
            ];
          } else {
            groupedDetails.push({
              ...detail,
              branchPriceDetails: createBranchPriceDetails(detail),
            });
          }
        });
        setPricesDetails(groupedDetails);
        if (groupedDetails.length > 0) {
          const firstDetail = groupedDetails[0];

          firstDetail.branchPriceDetails.forEach((branchDetail) => {
            if (
              branchDetail.branchId &&
              !selectedBranches.includes(branchDetail.branchId)
            ) {
              selectedBranches.push(branchDetail.branchId);
            }
          });
        }
        setSelectedBranches(selectedBranches);
      }
      return data;
    }
    return {
      generatedId: mode !== "update" ? uuidv4() : "",
      entryDate: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      branchId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
            ? userBranches[0].branchId
            : undefined,
    } as CreateAndEditPricesDto;
  }

  const { initialValues } = useFormData<CreateAndEditPricesDto>(
    mode === "update" ? `price/update/${id}/${branchId}` : "price/register",
    {
      generatedId: mode !== "update" ? uuidv4() : "",
      entryDate: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      branchId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
            ? userBranches[0].branchId
            : undefined,

    } as CreateAndEditPricesDto,
    setAdditionalStates,
    {
      fetchData: getData
    }
  );

  const addPricesDetail = (pricesDetail: PricesDetail) => {
    setPricesDetails((prevPricesDetails) => {
      return [...prevPricesDetails, pricesDetail];
    });
  };

  const validationSchema = Yup.object({
    branchId: Yup.number().required(t("branchIsRequired")),
    documentTypeId: Yup.number().required(t("documentTypeIdIsRequired")),
    startDate: Yup.date().required(t("startDateIsRequired")),
    endDate: Yup.string()
      .nullable()
      .when("documentTypeId", (documentTypeId: any, schema) => {
        if (documentTypeId === 22) {
          return schema
            .required(t("endDateIsRequiredWhenDocumentTypeIdIs22"))
            .test(
              "is-valid-date",
              t("endDateCannotBeBeforeStartDate"),
              function (value) {
                const { startDate } = this.parent;
                return !value || new Date(value) >= new Date(startDate);
              }
            );
        }
        return schema.test(
          "is-valid-date",
          t("endDateCannotBeBeforeStartDate"),
          function (value) {
            const { startDate } = this.parent;
            return !value || new Date(value) >= new Date(startDate);
          }
        );
      }),
  });

  const reconstructDetails = (
    pricesDetails: PricesDetail[]
  ): PricesDetail[] => {
    const reconstructedDetails: PricesDetail[] = [];

    pricesDetails.forEach((detail) => {
      const { branchPriceDetails, rowAction, ...rest } = detail;

      if (mode === "update" && (rowAction === "U" || rowAction === "D")) {
        const matchingInitialDetails = initialPriceDetails.filter(
          (initialDetail) => initialDetail.itemId === detail.itemId
        );

        matchingInitialDetails.forEach((initialDetail) => {
          const updatedBranchDetail = branchPriceDetails.find(
            (branchDetail) => branchDetail.branchId === initialDetail.branchId
          );

          if (updatedBranchDetail) {
            reconstructedDetails.push({
              ...rest,
              pricesDetailsId: initialDetail.pricesDetailsId,
              salesPrice: Number(updatedBranchDetail.branchPrice),
              branchId: updatedBranchDetail.branchId,
              branchPriceDetails: [],
              rowAction: rowAction,
            });
          }
        });
      } else {
        if (!branchPriceDetails || branchPriceDetails.length === 0) {
          reconstructedDetails.push({
            ...rest,
            rowAction: rowAction,
            branchPriceDetails: [],
          });
          return;
        }

        branchPriceDetails.forEach((branchDetail) => {
          reconstructedDetails.push({
            ...rest,
            salesPrice: Number(branchDetail.branchPrice),
            branchId: branchDetail.branchId,
            branchPriceDetails: [],
            rowAction: rowAction,
          });
        });
      }
    });

    return reconstructedDetails;
  };
  const onFinish = async (values: CreateAndEditPricesDto) => {
    dispatch(setLoadingState(true));
    if (pricesDetail.length === 0) {
      toast.error(t("atLeastOneDetailIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }
    if (user?.isMainBranch && !values.branchId) {
      toast.error(t("branchIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }
    const reconstructedDetails = reconstructDetails(pricesDetail);

    values.details = reconstructedDetails;

    values.endDate =
      values.endDate === "" ||
        values.endDate === undefined ||
        values.endDate === null
        ? null
        : values.endDate;

    if (
      (values.endDate === null || values.endDate === "") &&
      values.documentTypeId === 22
    ) {
      dispatch(setLoadingState(false));
      toast.error(t("endDateIsRequired"));
      return;
    }

    if (mode === "update" && id) {
      values.pricesIdAsString = values?.pricesId?.toString();
      const result = await apiService
        .put(`/api/Prices/update`, values)
        .then(async (response) => {
          if (response.status === 200) {
            toast.success(t("PriceUpdatedSuccessfully"));
            getData();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    } else {
      await apiService
        .post("/api/Prices/create", values)
        .then(async (response) => {
          toast.success(t("PriceCreatedSuccessfully"));
          formikRef.current.setFieldValue("pricesId", response.data.Data);
          formikRef.current.setFieldValue(
            "pricesIdAsString",
            response.data.Data
          );

          const newDocumentId = response.data.Data;

          await db.updateTab(
            "price/register",
            `price/update/${newDocumentId}`,
            t("tabs.updateprice")
          );

          navigate(`/price/update/${newDocumentId}/${values.branchId}`);
        })
        .catch(() => { })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    }
    dispatch(setLoadingState(false));
  };
  function createBranchPriceDetails(
    detail: PricesDetail
  ): BranchPricesDetail[] {
    const branchPriceDetails: BranchPricesDetail[] = [];

    branchPriceDetails.push({
      branchId: detail.branchId || 0,
      branchName:
        branches.find((branch) => branch.branchId === detail.branchId)
          ?.description ?? "",
      branchPrice: detail.salesPrice,
    });

    return branchPriceDetails;
  }
  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(getEntitiesByTypeAsync(true));
    getCopiedData()
    dispatch(getDocumentTypesWithSignHAsync("NJF,NJFP"));
    dispatch(getUserBranches());
  }, [dispatch, mode, id]);

  useEffect(() => {
    const updateIndexedDB = async () => {
      const tab = await db.tabs.get(
        mode === "update"
          ? `localPurchase/update/${id}/${branchId}`
          : "price/register"
      );
      if (tab) {
        await db.tabs.put({
          ...tab,
          data: {
            ...tab.data,
            details: pricesDetail,
          },
        });
      }
    };
    updateIndexedDB();
  }, [pricesDetail]);
  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = await validateForm();
    setTouched({
      pricesIdAsString: true,
      branchId: true,
      branchName: true,
      documentTypeId: true,
      documentType: true,
      entityId: true,
      entity: true,
      comment: true,
      startDate: true,
      endDate: true,
      documentNo: true,
      validated: true,
      entryDate: true,
      registeredBy: true,
      notificationsDate: true,
      generatedId: true,
      details: true,
    });

    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };

  const handlePriceValidation = async (pricesId: string, branchId: string) => {
    const result = await dispatch(
      validatePriceAsync({ pricesId: pricesId, branchID: branchId })
    );

    if (result.type === "Price/validatePrice/fulfilled") {
      toast.success(t("validatedSuccessfully"));
      formikRef.current.setFieldValue("validated", true);
    } else {
      toast.error(t("validationFailed"));
    }
  };
  const handlePriceInvalidation = async (
    pricesId: string,
    branchId: number
  ) => {
    const result = await dispatch(
      invalidatePriceAsync({ pricesId: pricesId, branchID: branchId })
    );
    if (result.type === "Price/invalidatePrice/fulfilled") {
      toast.success(t("invalidatedSuccessfully"));
      formikRef.current.setFieldValue("validated", false);
    } else {
      toast.error(t("invalidationFailed"));
    }
  };
  const handleItemSearch = async (value: any) => {
    const itemsBasedOnValueAction = await dispatch(
      getItemsBySearchValue({ searchValue: value })
    );
    const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];
    setItemOptions([]);
    setItemOptions(
      items?.map((item) => ({
        label: item.description,
        value: item.itemId.toString(),
      }))
    );
  };

  const handleOnSelect = async (value: any) => {
    if (!value || isNaN(parseInt(value))) {
      toast.error("Please enter a valid search term");
      return;
    }
    const itemID = parseInt(value);
    const itemOnDatabase = await dispatch(
      getItemDetailsForImportPurchase(itemID)
    );
    if (
      itemOnDatabase.type === "Items/getItemDetailsForImportPurchase/rejected"
    ) {
      toast.error("Item not found");
      return;
    } else if (
      itemOnDatabase.type === "Items/getItemDetailsForImportPurchase/fulfilled"
    ) {
      const item = itemOnDatabase.payload as GetItemDetailsDto;

      if (item) {
        const isItemExist = pricesDetail.some(
          (detail) => detail.itemId === item.itemId
        );
        if (isItemExist) {
          toast.success(t("Item"));
          return;
        }
        let firstBranchColKey = "";
        const updatedBranches = branchesForPriceDetail.map((branch, index) => {
          if (index === 0) {
            firstBranchColKey = `branchPriceDetails[${index}].branchPrice`;
          }
          return {
            ...branch,
            itemId: item.itemId,
          };
        });

        const newPricesDetail: PricesDetail = {
          pricesDetailsId: 0,
          itemId: item.itemId,
          itemDescription: item.description,
          purchasePrice: item.purchasePrice ?? 0,
          discount: item.discount ?? 0,
          extraDiscount: item.extraDiscount ?? 0,
          salesPrice: 0,
          wholeSalePrice: item.wholeSalePrice ?? 0,
          branchPriceDetails: updatedBranches,
          rowAction: "A",
        };
        addPricesDetail(newPricesDetail);

        setTimeout(() => {
          focusOnAgGridEditingCell(pricesDetailGridRef, "discount");
        }, 0);
        setSearchValue("");
      } else {
        toast.error("Item not found");
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const onChange = (data: any) => {
    setSearchValue(data);
  };

  const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

  const handleSearchChange = (value: string) => {
    if (!value.trim()) {
      setSearchValue("");
      return;
    }
    setSearchValue(value);
    debouncedItemSearch(value);
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const isStartDateInPastOrToday = (startDate: string) => {
    const today = moment().startOf("day");
    const startDateMoment = moment(startDate).startOf("day");
    return startDateMoment.isSameOrBefore(today);
  };
  const isInvalidateButtonDisabled = (values: CreateAndEditPricesDto) => {
    return (
      isLoading ||
      !values.validated ||
      (isStartDateInPastOrToday(values.startDate ?? "") && values.validated)
    );
  };

  const onPriceDetailGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const fetchAndSetPricesDetails = async (
    priceDetailsArray: PricesDetail[]
  ) => {
    try {
      setPricesDetails(priceDetailsArray);
    } catch (error) {
      console.error("Error fetching entry details for price import:", error);
    }
  };

  const memoizedRowData = useMemo(() => {
    return pricesDetail.filter((detail) => detail.rowAction !== "D");
  }, [pricesDetail]);

  const getCopiedData = async () => {
    const data = await db.getGenericData();
    if (data)
      setCopiedData(data[0])
    else
      setCopiedData(undefined);
  }


  const copyDetails = () => {
    const details: CopiedDocumentDetailsTable<PricesDetail> = {
      id: "priceDetails",
      list: pricesDetail
    }
    db.upsertGenericData(details);
  }
  const pasteDetails = async () => {
    try {
      if (copiedData) {

        const targetType = {
          itemId: 0,
          branchId: null,
          itemDescription: "",
          branchPriceDetails: branchesForPriceDetail,
          purchasePrice: 0,
          discount: 0,
          extraDiscount: 0,
          salesPrice: 0,
          wholeSalePrice: 0,
          rowAction: "A"
        } as PricesDetail
        const details = mapDataToInterface(copiedData.list as any[], targetType)
        setPricesDetails(details);
      } else {
        toast.error(t('toastMessages.no_document'));
      }
    } catch (error) {
      toast.error(t('toastMessages.something_wrong'));
    }
  }
  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({} as CreateAndEditPricesDto)}
        validationSchema={validationSchema}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          handleSubmit,
          submitForm,
          validateForm,
          setTouched,
          setFieldValue,
        }) => (
          <>
            <FormHeaderOptions
              title={
                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
              }
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createButton")}
              submitButtonIsDisabled={
                isLoading ||
                pricesDetail.filter((detail) => detail.rowAction !== "D")
                  .length === 0 ||
                values.validated
              }
              createAccessEnum={MenuOptionEnum.PricesCreate}
              validateAccessEnum={MenuOptionEnum.PricesValidate}
              validateButtonIsDisabled={
                isLoading || mode !== "update" || values.validated
              }
              
              validateButtonText={t("validateButton")}
              handleDocumentValidation={async () => {
                if (values.pricesId) {
                  const today = moment().startOf("day").format("YYYY-MM-DD");
                  if (values.startDate === today) {
                    Modal.confirm({
                      title: t("validateTodaysDate"),
                      onOk() {
                        if (values.pricesId)
                          handlePriceValidation(
                            values?.pricesId.toString(),
                            values.branchId.toString()
                          );
                      },
                    });
                  } else {
                    await handlePriceValidation(
                      values.pricesId.toString(),
                      values.branchId.toString()
                    );
                  }
                }
              }}
              invalidateAccessEnum={MenuOptionEnum.PricesInvalidate}
              invalidateButtonText={t("invalidateButton")}
              invalidateButtonIsDisabled={isInvalidateButtonDisabled(values)}
              handleDocumentInvalidation={async () => {
                await handlePriceInvalidation(
                  values.pricesId ?? "",
                  values.branchId
                );
              }}
              copyButtonIsDisabled={
                isLoading || mode !== "update"
              }
              copyButtonText={t("CopyButton")}
              handleCopyDetails={mode === "update" ? () => copyDetails() : undefined}
            />

            <Form onFinish={handleSubmit} layout="vertical">
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={8}>
                  <FormSelect
                    name="branchId"
                    label={t("branchIdInputLabel")}
                    required={true}
                    value={values.branchId}
                    options={
                      userBranches?.map((userBarnch) => {
                        return {
                          key: userBarnch.branchId,
                          label: userBarnch.description,
                          value: userBarnch.branchId,
                        };
                      }) ?? []
                    }
                    disabled={values.validated || pricesDetail.length > 0}
                  />
                  <FormSelect
                    name="documentTypeId"
                    label={t("documentTypeInputLabel")}
                    style={{ width: "100%" }}
                    required={true}
                    value={values.documentTypeId}
                    disabled={values.validated || pricesDetail.length > 0}
                    options={
                      documentTypes.map((documentType) => {
                        return {
                          key: documentType.documentId,
                          label: documentType.description,
                          value: documentType.documentId,
                        };
                      }) ?? []
                    }
                  />
                  <FormInput
                    name="comment"
                    type="text"
                    label={t("commentInputLabel")}
                    value={values.comment}
                    disabled={values.validated}
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <FormInput
                    name="startDate"
                    type="date"
                    required={true}
                    label={t("startDateInputLabel")}
                    max={values.endDate ?? undefined}
                    value={values.startDate?.split("T")[0] || ""}
                    disabled={values.validated}
                  />
                  <FormInput
                    name="endDate"
                    type="date"
                    required={values.documentTypeId === 22}
                    disabled={values.documentTypeId === 21 || values.validated}
                    label={t("endDateInputLabel")}
                    min={values.startDate ?? undefined}
                    value={values.endDate?.split("T")[0] || ""}
                  />
                  <FormSelect
                    name="branches"
                    multiple={true}
                    label={t("branchesInputLabel")}
                    rules={[
                      {
                        message: t("branchesInputPlaceholder"),
                      },
                    ]}
                    required={true}
                    value={selectedBranches}
                    disabled={pricesDetail.length > 0 || mode === "update"}
                    options={
                      userBranches?.map((branch) => {
                        return {
                          key: branch.branchId,
                          label: branch.description,
                          value: branch.branchId,
                        };
                      }) ?? []
                    }
                    handleCustomChange={(value) => {
                      setSelectedBranches(value);
                    }}
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <FormInput
                    name="pricesId"
                    label={t("generatedIdInputLabel")}
                    required={false}
                    value={values.pricesId}
                    disabled={true}
                  />
                  <FormInput
                    name="documentNo"
                    label={t("documentNoInputLabel")}
                    required={false}
                    value={values.documentNo}
                    disabled={true}
                  />
                  {mode === "update" ||
                    values.documentTypeId === 0 ||
                    values.documentTypeId === undefined ||
                    values.documentTypeId === null ||
                    values.documentTypeId ===
                    DocumentTypeEnum.promotionnalAnnouncement ? (
                    <></>
                  ) : (
                    <div>
                      <Form.Item style={{ margin: "0" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            type="primary"
                            style={{
                              width: "100%",
                              maxWidth: "400px",
                              height: "40px",
                            }}
                            disabled={
                              selectedBranches.length === 0 ||
                              pricesDetail.length > 0
                            }
                            className="button-margin"
                            onClick={() => setIsModalOpen(true)}
                          >
                            {t("ImportButton")}
                          </Button>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="custom-row" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <AutoComplete
                    className="custom-search"
                    options={itemOptions}
                    onSelect={handleOnSelect}
                    onSearch={handleSearchChange}
                    onChange={onChange}
                    value={searchValue}
                    style={{ width: 300 }}
                    disabled={values.validated || selectedBranches.length === 0}
                  >
                    <Search
                      placeholder={t("searchPlaceholder")}
                      allowClear
                      ref={searchInputRef}
                      size="large"
                      onSearch={handleSearchChange}
                    />
                  </AutoComplete>
                  {copiedData && (
                    <Button onClick={pasteDetails} disabled={pricesDetail.length > 0 ||
                      !values.branchId ||
                      !values.documentTypeId ||
                      selectedBranches.length === 0}>{t("paste")}</Button>
                  )}

                </Col>
              </Row>
              <Row gutter={[12, 8]}>
                <Col xs={24} sm={24}>
                  <AgGridTableComponent
                    style={{ overflow: "auto" }}
                    onGridReady={onPriceDetailGridReady}
                    gridRef={priceDetailGridRef}
                    isLoading={isLoading}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={memoizedRowData}
                    stopEditingWhenCellsLoseFocus={true}
                    suppressCellFocus={true}
                    disabled={values.validated}
                    onCellKeyDown={(event: CellKeyDownEvent) =>
                      handleNextGridColumnClick(event, searchInputRef)
                    }
                  />
                </Col>
              </Row>
              <HandleFormDataForTabSaving
                tabPath={
                  mode === "update"
                    ? `price/update/${id}/${branchId}`
                    : "price/register"
                }
                additionalStates={{
                  priceDetails: pricesDetail,
                }}
              />
              <ChoosePurchaseModal
                open={isModalOpen}
                setFieldValue={setFieldValue}
                onClose={() => setIsModalOpen(false)}
                branchesForPriceDetail={branchesForPriceDetail}
                fetchAndSetPricesDetails={fetchAndSetPricesDetails}
                branchId={values.branchId}
              />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
}
