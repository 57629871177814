import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { CreateOrUpdateInternalSaleDetailDto } from "../../../../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDetailDto";
import { CreateOrUpdateInternalSaleDto } from "../../../../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDto";
import { GenericDocumentDto } from "../../../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { SelectOption } from "../../../../../models/SelectOption";
import { AppDispatch, RootState } from "../../../../../redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CreateAndUpdateEntityBranchDto } from "../../../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { getBranchesByEntityId, getEntitiesBySearch } from "../../../../../redux/slices/entitySlice";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import { getItemDetailsForLocalPurchase, getItemsBySearchValue } from "../../../../../redux/slices/itemSlice";
import { ItemDescriptionIdDto } from "../../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { CopiedDocumentDetailsTable } from "../../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { toast } from "react-toastify";
import { GetItemDetailsDto } from "../../../../../models/clientDashboard/Item/GetItemDetailsDto";
import { focusOnAgGridEditingCell } from "../../../../../helperMethods/focusOnAgGridEditingCell";
import { mapDataToInterface } from "../../../../../helperMethods/mapDataToInterface";
import { db } from "../../../../../indexDB/clientSideDatabase";
import { debounce } from "lodash";
import { Entity } from "../../../../../models/clientDashboard/Entity/Entity";
import { AutoComplete, Button, Col, Row } from "antd";
import FormSelect from "../../../../CustomComponents/FormSelect";
import FormSelectWithSearch from "../../../../CustomComponents/FormSelectWithSearch";
import FormInput from "../../../../CustomComponents/FormInput";
import Search from "antd/es/input/Search";
import { handleNextGridColumnClick } from "../../../../../helperMethods/handleNextGridColumnClick";
import AgGridTableComponent from "../../../../CustomComponents/AgGridTableComponent";
import { getInternalSalesDetailColumns } from "../InternalSaleDetails/AgGridInternalSaleDetailColumns";
import { Classifier } from "../../../../../models/clientDashboard/Classifier/Classifier";
import { getClassifierDetailsBySearch, getClassifiersByTypeReturnAsync } from "../../../../../redux/slices/classifierSlice";
import { ClassifierDetail } from "../../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";



interface Props {
    type: string;
    values: GenericDocumentDto<CreateOrUpdateInternalSaleDto, CreateOrUpdateInternalSaleDetailDto>;
    internalSalesDetails: CreateOrUpdateInternalSaleDetailDto[];
    addInternalSalesDetail: (
        internalSalesDetail: CreateOrUpdateInternalSaleDetailDto
    ) => void;
    setInternalSaleDetails: (internalSale: CreateOrUpdateInternalSaleDetailDto[]
    ) => void;
    removeInternalSalesDetail: (rowIndex: number) => void;
    mode: string;
    setFieldValue: (name: string, value: any) => void;
    supplierOptions: SelectOption[];
    classifiers: Classifier[];
    classifiersForInternalSale: Classifier[];
    setClassifiersForInternalSale: (classifiers: Classifier[]) => void;
    fetchClassifiers: (searchInput: string, classifierId: number) => any,
}
export default function MainFieldsComponent({
    values,
    internalSalesDetails,
    addInternalSalesDetail,
    removeInternalSalesDetail,
    setInternalSaleDetails,
    mode,
    type,
    setFieldValue,
    supplierOptions,
    classifiers,
    setClassifiersForInternalSale,
    classifiersForInternalSale,
    fetchClassifiers,
}: Props) {
    const t = useAppTranslation("ClientDashboard.CreateInternalSale");
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const [searchValue, setSearchValue] = useState("");
    const [itemOptions, setItemOptions] = useState<any[]>([]);
    const searchInputRef = useRef<any | null>(null);
    const suppliers = useSelector((state: RootState) => state.entity.entities);
    const [entityBranches, setEntityBranches] = useState<CreateAndUpdateEntityBranchDto[]>([]);
    const user = useSelector((state: RootState) => state.user.loggedInUser);
    const dispatch = useDispatch<AppDispatch>();
    const userBranches = useSelector((state: RootState) => state.user.loggedInUserBranches);
    const documentTypes = useSelector((state: RootState) => state.documentType.documentTypes);
    const internalSalesDetailGridRef = useRef(null);


    const [gridApi, setGridApi] = useState<any>(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const onInternalSalesDetailGridReady = (params: any) => {
        params.columnApi.autoSizeColumns(['saleId']);
        params.columnApi.autoSizeColumns(['itemId']);
        params.columnApi.autoSizeColumns(['quantity']);
        params.columnApi.autoSizeColumns(['itemCost']);
        params.columnApi.autoSizeColumns(['averageCost']);
        params.columnApi.autoSizeColumns(['salesPrice']);
        params.columnApi.autoSizeColumns(['discount']);
        params.columnApi.autoSizeColumns(['vAT']);
        params.columnApi.autoSizeColumns(['extraDiscount']);
        params.columnApi.autoSizeColumns(['barcode']);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const getEntityBranches = async (entityId: number) => {
        const result = await dispatch(getBranchesByEntityId(entityId));
        if (result.type === "Entities/getBranchesByEntityId/fulfilled") {
            setEntityBranches(result.payload as CreateAndUpdateEntityBranchDto[])
        }
    }

    const [columnDefs, setColumnDefs] = useState<ColDef<CreateOrUpdateInternalSaleDetailDto>[]>(
        getInternalSalesDetailColumns(removeInternalSalesDetail, t, searchInputRef, mode, classifiersForInternalSale, fetchClassifiers)
    );
 

    const handleItemSearch = async (value: any, supplierId: number) => {
        const itemsBasedOnValueAction = await dispatch(
            getItemsBySearchValue({ searchValue: value, supplierId: supplierId })
        );
        const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

        if (!items || items.length === 0) {
            setItemOptions([
                {
                    label: t("noItemsFound"),
                    value: "no-items",
                    disabled: true
                }
            ]);
        } else {
            setItemOptions([]);
            setItemOptions(
                items?.map((item) => ({
                    key: item.itemId,
                    label: item.description,
                    value: item.itemId.toString(),
                }))
            );
        }
    };
    const [copiedData, setCopiedData] = useState<CopiedDocumentDetailsTable<any> | undefined>(undefined);

    const handleOnSelect = async (value: any) => {
        if (!value || isNaN(parseInt(value))) {
            toast.error("Please enter a valid search term");
            return;
        }
        const itemID = parseInt(value);
        const itemOnDatabase = await dispatch(
            getItemDetailsForLocalPurchase({ itemID, branchID: values.branchId ?? 0 })
        );
        if (
            itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/rejected"
        ) {
            toast.error("Item not found");
            return;
        } else if (
            itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/fulfilled"
        ) {
            const item = itemOnDatabase.payload as GetItemDetailsDto;

            if (item) {
                const isItemExist = internalSalesDetails.some(
                    (detail) => detail.itemId === item.itemId
                );
                if (isItemExist) {
                    toast.success(t("Item"));
                    return;
                }
                const newInternalSalesDetail: CreateOrUpdateInternalSaleDetailDto = {
                    internalSaleDetailId: 0,
                    itemId: item.itemId,
                    itemDescription: item.description ?? "",
                    quantity: 0,
                    itemCost: 0,
                    averageCost: item.averageCost,
                    vAT: item.vat,
                    salesPrice: item.salesPrice,
                    comment: "",
                    entryItemsDetailsId: 0,
                    k70: 0,
                    k71: 0,
                    k72: 0,
                    k73: 0,
                    k74: 0,
                    rowAction: 'A',
                };
                addInternalSalesDetail(newInternalSalesDetail);

                setTimeout(() => {
                    focusOnAgGridEditingCell(internalSalesDetailGridRef, "quantity");
                }, 0);
                setSearchValue("");
            } else {
                toast.error("Item not found");
            }
        } else {
            toast.error("Something went wrong");
        }
    };

    const pasteDetails = async () => {
        try {
            if (copiedData) {
                const targetType = {
                    internalSaleDetailId: 0,
                    itemId: 0,
                    itemDescription: "",
                    quantity: 0,
                    itemCost: 0,
                    averageCost: 0,
                    vAT: 0,
                    salesPrice: 0,
                    comment: "",
                    entryItemsDetailsId: 0,
                    rowAction: 'A',
                } as CreateOrUpdateInternalSaleDetailDto
                const details = mapDataToInterface(copiedData.list as any[], targetType)
                setInternalSaleDetails(details);
            } else {
                toast.error(t('toastMessages.no_document'));
            }
        } catch (error) {
            toast.error(t('toastMessages.something_wrong'));
        }
    }

    const getCopiedData = async () => {
        const data = await db.getGenericData();
        if (data)
            setCopiedData(data[0])
        else
            setCopiedData(undefined);
    }

    useEffect(() => {
        getCopiedData();
    }, []);

    const onChange = (data: any) => {
        setSearchValue(data);
    };

    const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

    const handleSearchChange = (value: string, supplierId: number) => {
        if (!value.trim()) {
            setSearchValue("");
            return;
        }
        setSearchValue(value);
        debouncedItemSearch(value, supplierId);
    };

    const fetchBuyers = async (searchText: string) => {
        const result = await dispatch(getEntitiesBySearch({ searchText: searchText, isBuyer: true }));
        if (result.payload !== "An error occurred") {
            const payload = result.payload as Entity[]
            const options = payload.map((entity) => ({
                key: entity.entityId.toString(),
                label: entity.description,
                value: entity.entityId
            }))
            return options;
        }
        return [];
    }

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };

    const memoizedRowData = useMemo(() => {
        return internalSalesDetails.filter((detail) => detail.rowAction !== "D");
    }, [internalSalesDetails]);

    useMemo(() => {
        setColumnDefs(getInternalSalesDetailColumns(removeInternalSalesDetail, t, searchInputRef, mode, classifiersForInternalSale, fetchClassifiers))
    }, [classifiersForInternalSale,memoizedRowData]);
    
    useEffect(() => {
    }, [supplierOptions]);


    const classifierColumns = useMemo(() => {
        return classifiers
            .filter((classifier) => classifier.status && classifier.type === 'InternalSaleDetails')
            .map((classifier): ColDef<CreateOrUpdateInternalSaleDetailDto> => ({
                headerName: classifier.clientDescription || classifier.description,
                field: classifier.description.toLowerCase(),
                editable: true,
                filter: true,
                suppressMovable: true,
                suppressMenu: true,
                valueSetter: (params: any) => {
                    const newValue = params.newValue;
                    params.data[classifier.description.toLowerCase()] = newValue;
                    if (mode === 'update') {
                        params.data.rowAction = 'U';
                    }
                    return true;
                },
                valueGetter: (params: any) => {
                    return params.data[classifier.description.toLowerCase()];
                }
            }));
    }, [classifiers, mode]);

    const combinedColumnDefs = useMemo(() => {
        return [...columnDefs, ...classifierColumns];
    }, [columnDefs, classifierColumns]);


    return (
        <>
            <Row gutter={[8, 8]}>
                <Col xs={12} sm={8}>
                    <FormSelect
                        name="branchId"
                        label={t("branchIdInputLabel")}
                        required={true}
                        value={values.branchId}
                        handleCustomChange={(value) => {
                            setFieldValue("branchId", value)
                        }}
                        options={userBranches?.map((userBarnch) => {
                            return {
                                key: userBarnch.branchId,
                                label: userBarnch.description,
                                value: userBarnch.branchId
                            };
                        }) ?? []
                        }
                        disabled={!user?.isMainBranch || values.validated || internalSalesDetails.length > 0}
                    />
                            <FormSelect
                                name="documentTypeId"
                                label={t("documentTypeInputLabel")}
                                style={{ width: "100%" }}
                                value={values.documentTypeId}
                                required={true}
                                disabled={values.validated || internalSalesDetails.length > 0}
                                options={documentTypes.map((documentType) => {
                                    return {
                                        key: documentType.documentId,
                                        label: documentType.description,
                                        value: documentType.documentId
                                    };
                                }) ?? []
                                }
                            />
                </Col>
                <Col xs={12} sm={8}>
                    <FormInput
                        name="date"
                        type="date"
                        required={true}
                        min={new Date().toISOString().split("T")[0]}
                        label={t("dateInputLabel")}
                        value={values?.date?.split("T")[0] || ""}
                        disabled={values.validated}
                    />
                    <FormInput
                        name="header.comment"
                        label={t("commentInputLabel")}
                        required={false}
                        value={values?.header?.comment}
                        disabled={values.validated}
                    />
                </Col>
                <Col xs={12} sm={8}>
                    <FormInput
                        name="genericDocumentIdAsString"
                        label={t("referenceNoInputLabel")}
                        required={false}
                        value={values.genericDocumentIdAsString}
                        disabled={true}
                    />
                    <FormInput
                        name="documentNo"
                        label={t("documentNoInputLabel")}
                        required={false}
                        value={values.documentNo}
                        disabled={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="custom-row" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <AutoComplete
                        options={itemOptions}
                        onSelect={handleOnSelect}
                        onSearch={(value) => handleSearchChange(value, values.branchId)}
                        onChange={onChange}
                        value={searchValue}
                        className="custom-search"
                        style={{ width: 300 }}
                        disabled={values.branchId === 0 || values.branchId === null
                            || values.branchId === undefined || values.validated
                            || values.documentTypeId === undefined
                            || values.documentTypeId === null}
                    >
                        <Search
                            placeholder={t('searchPlaceholder')}
                            allowClear
                            ref={searchInputRef}
                            size="large"
                            onSearch={(value) => handleSearchChange(value, values.branchId)}
                        />
                    </AutoComplete>
                    {copiedData && (
                        <Button onClick={pasteDetails} disabled={internalSalesDetails.length > 0 || !values.branchId || !values.documentTypeId}>{t("paste")}</Button>
                    )}
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col xs={24} sm={24}>
                    <AgGridTableComponent
                        style={{ overflow: "auto" }}
                        onGridReady={onInternalSalesDetailGridReady}
                        gridRef={internalSalesDetailGridRef}
                        isLoading={isLoading}
                        defaultColDef={defaultColDef}
                        columnDefs={combinedColumnDefs}
                        rowData={memoizedRowData}
                        stopEditingWhenCellsLoseFocus={true}
                        suppressCellFocus={true}
                        disabled={values.validated}
                        onCellKeyDown={(event: CellKeyDownEvent) =>
                            handleNextGridColumnClick(event, searchInputRef)
                        }
                    />
                </Col>
            </Row>
        </>
    );
}