import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalPurchaseState } from "../../models/states/LocalPurchaseState";
import { LocalPurchase } from "../../models/clientDashboard/LocalPurchase/LocalPurchase";
import { setLoading, setLoadingState } from "./loadingSlice";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import { ApiResponse } from "../../models/ApiResponse";
import apiService from "../../extensions/api";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { RootState } from "..";
import { GetEntryItemDetailsForPricesImportDto } from "../../models/clientDashboard/LocalPurchase/GetEntryItemDetailsForPricesImportDto";

const initialState: LocalPurchaseState = {
  localPurchases: [],
  error: null,
};

const localPurchaseSlice = createSlice({
  name: "localPurchase",
  initialState,
  reducers: {
    getLocalPurchasesStart(state: LocalPurchaseState) {
      setLoading(true);
      state.error = null;
    },
    getLocalPurchasesSuccess(
      state: LocalPurchaseState,
      action: PayloadAction<LocalPurchase[]>
    ) {
      setLoading(false);
      state.localPurchases = action.payload;
    },
    getLocalPurchasesFailure(
      state: LocalPurchaseState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
    addNewLocalPurchaseToState(
      state: LocalPurchaseState,
      action: PayloadAction<LocalPurchase>
    ) {
      setLoading(false);
      state.localPurchases.push(action.payload);
    },
    updateLocalPurchaseInState(
      state: LocalPurchaseState,
      action: PayloadAction<LocalPurchase>
    ) {
      setLoading(false);
      const updatedLocalPurchase = action.payload;
      const index = state.localPurchases.findIndex(
        (localPurchase) =>
          localPurchase.entryItemsId === updatedLocalPurchase.entryItemsId
      );
      if (index !== -1) {
        state.localPurchases[index] = updatedLocalPurchase;
      }
    },
    deleteLocalPurchaseFromState(
      state: LocalPurchaseState,
      action: PayloadAction<number>
    ) {
      setLoading(false);
      const localPurchaseId = action.payload;
      state.localPurchases = state.localPurchases.filter(
        (localPurchase) => localPurchase.entryItemsId !== localPurchaseId
      );
    },
  },
});

export const getLocalPurchasesAsync = createAsyncThunk(
  "LocalPurchase/getLocalPurchases",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    // const items = state.item.items;

    // if (items.length > 0) {
    //     return items;
    // }

    try {
      let url = `api/LocalPurchase/getDataWithPagination`;
      // if(data.pageNumber && data.pageSize){
      //     url += `?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
      // }
      const response = await apiService.get<ApiResponse<LocalPurchase[]>>(url);
      dispatch(getLocalPurchasesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getLocalPurchasesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
    }
  }
);

export const getLocalPurchaseFilterColumnsAsync = createAsyncThunk(
  "Items/getLocalPurchaseFilterColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<ApiResponse<PropertyDto[]>>(
        "api/Filter/local-purchase"
      );
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getLocalPurchasesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const getLocalPurchaseDataById = createAsyncThunk(
  "LocalPurchases/getLocalPurchaseDataById",
  async (
    data: { localPurchaseId: string; branchID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<LocalPurchase>>(
        `/api/LocalPurchase/getLocalPurchaseById?entryItemsId=${data.localPurchaseId}&branchId=${data.branchID}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const deleteLocalPurchaseAsync = createAsyncThunk(
  "LocalPurchases/deleteLocalPurchase",
  async (localPurchaseId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.delete<ApiResponse<void>>(
        `/api/LocalPurchase/${localPurchaseId}`
      );
      dispatch(deleteLocalPurchaseFromState(localPurchaseId));
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const validateLocalPurchaseAsync = createAsyncThunk(
  "LocalPurchases/validateLocalPurchase",
  async (
    data: { entryItemId: string; branchID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/LocalPurchase/Validate?entryItemId=${data.entryItemId}&branchId=${data.branchID}`
      );
      // dispatch(deleteLocalPurchaseFromState(localPurchaseId));
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const correctLocalPurchaseAsync = createAsyncThunk(
  "LocalPurchases/correctLocalPurchase",
  async (
    data: { entryItemsId: string; generatedId: string; branchID: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      const result = await apiService.put<ApiResponse<void>>(
        `/api/LocalPurchase/Correct?originalEntryItemId=${data.entryItemsId}&generatedId=${data.generatedId}&branchID=${data.branchID}`
      );
      return result.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getLocalPurchasesWithFilters = createAsyncThunk(
  "LocalPurchases/getLocalPurchasesWithFilters",
  async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<LocalPurchase[]>>(
        "/api/LocalPurchase/GetDataWithFilter",
        data
      );
      dispatch(getLocalPurchasesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(setLoadingState(false));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const invalidateLocalPurchaseAsync = createAsyncThunk(
  "LocalPurchases/invalidateLocalPurchases",
  async (
    data: { entryItemId: string; branchID: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/LocalPurchase/Unvalidate?entryItemId=${data.entryItemId}&branchID=${data.branchID}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getEntriesForPricesImport = createAsyncThunk(
  "LocalPurchase/GetEntriesForPricesImport",
  async (branchId: number, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;

    try {
      let url = `api/LocalPurchase/GetEntriesForPricesImport`;
      const response = await apiService.get<ApiResponse<LocalPurchase[]>>(url, {
        params: {
          branchId: branchId,
        },
      });
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getLocalPurchasesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
    }
  }
);

export const getEntryDetailsForPricesImport = createAsyncThunk(
  "LocalPurchase/getEntryDetailsForPricesImport",
  async (
    data: {
      entryItemsId: string;
      branches: number[];
      includeCondition: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;

    try {
      let url = `api/LocalPurchase/getEntrydetailsForPricesImport`;
      const response = await apiService.post<
        ApiResponse<GetEntryItemDetailsForPricesImportDto[]>
      >(url, data);
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getLocalPurchasesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
    }
  }
);

export const handlePrintLocalPurchaseForm = createAsyncThunk(
  "LocalPurchase/handlePrintForm",
  async ( entryItemsId: string ,
    {dispatch, getState, rejectWithValue}
  ) => {
    try{
      dispatch(setLoadingState(true));
      const result = await apiService.get<ApiResponse<string>>(
        `/api/LocalPurchase/get-report?entryItemId=${entryItemsId}`
      );
      return result.data.Data;
    }catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  getLocalPurchasesStart,
  getLocalPurchasesSuccess,
  getLocalPurchasesFailure,
  addNewLocalPurchaseToState,
  updateLocalPurchaseInState,
  deleteLocalPurchaseFromState,
} = localPurchaseSlice.actions;

export default localPurchaseSlice.reducer;
