import { Button, Popconfirm, Tooltip, Typography } from "antd";
import { renderFilterInputs } from "../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../CustomComponents/FilterModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import { LocalPurchase } from "../../../models/clientDashboard/LocalPurchase/LocalPurchase";
import { db } from "../../../indexDB/clientSideDatabase";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import {
  getLocalPurchaseFilterColumnsAsync,
  getLocalPurchasesAsync,
  getLocalPurchasesWithFilters,
} from "../../../redux/slices/localPurchaseSlice";
import { FilterData } from "../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../customHooks/useFilter";
import {
  formatDate,
  formatDateWithTime,
} from "../../../helperMethods/DateFormat";
import { exportDataToCsv } from "../../../models/helper/exportDataToCsv";
import { AgGridReact } from "ag-grid-react";
import "../../../assets/generalStyles/ListHeaderStyles.css";
import FilterIcon from "../../../assets/icons/FilterIcon";
import ExportIcon from "../../../assets/icons/ExportIcon";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import WithPermission from "../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import { usePermission } from "../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;
export default function LocalPurchases() {
  const localPurchases = useSelector(
    (state: RootState) => state.localPurchase.localPurchases
  );
  const t = useAppTranslation("ClientDashboard.LocalPurchases");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef[]>([
    {
      field: "entryItemsId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return (
          <Text className="ag-grid-id-column">
            {params?.data?.entryItemsId}
          </Text>
        );
      },
    },
    {
      field: "branch",
      headerName: t("tableHeaders.branch"),
      suppressMenu: true,
    },
    {
      field: "supplier",
      headerName: t("tableHeaders.supplier"),
      suppressMenu: true,
    },
    {
      field: "documentType",
      headerName: t("tableHeaders.documentType"),
      suppressMenu: true,
    },
    {
      field: "documentNo",
      headerName: t("tableHeaders.documentNo"),
      suppressMenu: true,
    },
    {
      field: "validated",
      headerName: t("tableHeaders.validated"),
      suppressMenu: true,
    },
    {
      field: "date",
      headerName: t("tableHeaders.date"),
      valueFormatter: formatDate,
      suppressMenu: true,
      width: 120,
    },
    {
      field: "entryDate",
      headerName: t("tableHeaders.entryDate"),
      valueFormatter: formatDateWithTime,
      suppressMenu: true,
    },
    {
      field: "invoiceDate",
      headerName: t("tableHeaders.invoiceDate"),
      valueFormatter: formatDate,
      suppressMenu: true,
    },
    {
      field: "lastUpdatedDate",
      headerName: t("tableHeaders.lastUpdatedDate"),
      valueFormatter: formatDateWithTime,
      suppressMenu: true,
    },
    {
      field: "supplierInvoiceNo",
      headerName: t("tableHeaders.supplierInvoiceNo"),
      suppressMenu: true,
    },
    {
      field: "paymentDeadline",
      headerName: t("tableHeaders.paymentDeadline"),
      suppressMenu: true,
      maxWidth: 140,
    },
    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.LocalPurchasesCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateLocalPurchase"),
                  `localPurchase/update/${params?.data?.entryItemsId}`,
                  undefined
                );
                navigate(
                  `/localPurchase/update/${params?.data?.entryItemsId}/${params.data.branchId}`
                );
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 90,
      suppressMenu: true,
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.api.autoSizeColumns(["branch"]);
    params.api.autoSizeColumns(["supplier"]);
    params.api.autoSizeColumns(["documentType"]);
    params.api.autoSizeColumns(["documentNo"]);
    params.api.autoSizeColumns(["paymentDeadline"]);
    params.api.autoSizeColumns(["entryDate"]);
    params.api.autoSizeColumns(["invoiceDate"]);
    params.api.autoSizeColumns(["lastUpdatedDate"]);
    params.api.autoSizeColumns(["supplierInvoiceNo"]);
    params.api.autoSizeColumns(["validated"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);
  const getLocalPurchases = async () => {
    const result = await dispatch(getLocalPurchasesWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    getLocalPurchases();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.LocalPurchasesCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateLocalPurchase"),
        `localPurchase/update/${event?.data?.entryItemsId}/${event?.data?.branchId}`,
        undefined
      );
      navigate(
        `/localPurchase/update/${event?.data?.entryItemsId}/${event.data.branchId}`
      );
    }
  };
  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;

    const result = await dispatch(getLocalPurchasesWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getLocalPurchaseFilterColumnsAsync());
    return result;
  };
  const handleClearFilters = async () => {
    const result = await dispatch(getLocalPurchasesWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewLocalPurchase = async () => {
    navigate("/localPurchase/register");
    const existingTab = await db.tabs.get("localPurchase/register");
    if (!existingTab) {
      db.addTab(
        t("tabs.createLocalPurchase"),
        "localPurchase/register",
        undefined
      );
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "entryItemsId",
      "branch",
      "supplier",
      "documentType",
      "documentNo",
      "customDocNo",
      "validated",
      "date",
      "entryDate",
      "invoiceDate",
      "lastUpdatedDate",
      "supplierInvoiceNo",
      "paymentDeadline",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.localPurchases")}
        createRecord={handleCreateNewLocalPurchase}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.LocalPurchasesCreate}
        exportAccessEnum={MenuOptionEnum.LocalPurchasesExport}
      />
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState?.filterInputs?.map((input) => (
          <div
            key={input.name}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Text>
              {input.isClassifier
                ? input.name
                : t(`FilterColumns.${input.name}`)}
            </Text>
            {renderFilterInputs({ input: input, filterState: filterState })}
          </div>
        ))}
      </div>
      {filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button onClick={filterState.handleClearFilters}>
            {t("clearFilters")}
          </Button>
          <Button type="primary" onClick={filterState.handleFilterSubmit}>
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={localPurchases}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      // paginationPageSize={20}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.LocalPurchases"
      />
    </>
  );
}
