import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/Country/CountryState";
import { CountryState } from "../../models/clientDashboard/Country/CountryState";
import { Country } from "../../models/clientDashboard/Country/Country";
import { RootState } from "..";

const initialState: CountryState = {
    countries: [],
    error: null,
};

const CountrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        getCountriesStart(state: CountryState) {
            setLoading(true);
            state.error = null;
        },
        getCountriesSuccess(
            state: CountryState,
            action: PayloadAction<Country[]>
        ) {
            setLoading(false);
            state.countries = action.payload;
        },
        getCountriesFailure(
            state: CountryState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewCountryToState(state: CountryState, action: PayloadAction<Country>) {
            setLoading(false);
            state.countries.push(action.payload);
        },
        updateCountryInState(state: CountryState, action: PayloadAction<Country>) {
            setLoading(false);
            const updatedCountry = action.payload;
            const index = state.countries.findIndex(country => country.countryId === updatedCountry.countryId);
            if (index !== -1) {
                state.countries[index] = updatedCountry;
            }
        },
        deleteCountryFromState(state: CountryState, action: PayloadAction<number>) {
            setLoading(false);
            const countryId = action.payload;
            state.countries = state.countries.filter(country => country.countryId !== countryId);
        },
    }
});

export const getCountriesAsync = createAsyncThunk(
    "Countries/getCountries",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const countries=state.country.countries;

        if (countries.length > 0) {
            return countries;
        }

        try {
            dispatch(getCountriesStart());
            const response = await apiService.get<ApiResponse<Country[]>>(
                "api/Entity/countries"
            );
            // toast.success("Countries were successfully retrieved!");
            dispatch(getCountriesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getCountriesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getCountryDataById = createAsyncThunk(
    "Countries/getCountryDataById",
    async (countryId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<Country>>(
                `/api/clientDashboard/Country/GetCountryDataById/${countryId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteCountryAsync = createAsyncThunk(
    "Countries/deleteCountry",
    async (countryId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/clientDashboard/Country/DeleteCountry/${countryId}`
            );
            dispatch(deleteCountryFromState(countryId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getCountriesStart,
    getCountriesSuccess,
    getCountriesFailure,
    addNewCountryToState,
    updateCountryInState,
    deleteCountryFromState
} = CountrySlice.actions;

export default CountrySlice.reducer;