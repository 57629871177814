import { useEffect, useState } from "react";
import {
    Form,
    Spin,
    Row,
    Col,
} from "antd";
import { Typography } from "antd";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import apiService from "../../../extensions/api";
import { useDispatch, useSelector } from "react-redux";
import {
    setLoading,
} from "../../../redux/slices/loadingSlice";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { AppDispatch, RootState } from "../../../redux";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import FormInput from "../../CustomComponents/FormInput";
import { Formik } from "formik";
import { Brand } from "../../../models/clientDashboard/Brand/Brand";
import { getBrandDataById } from "../../../redux/slices/brandSlice";

const { Title, Text } = Typography;

const UpdateBrand = () => {
    const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
    const { id } = useParams();
    const t = useAppTranslation("ClientDashboard.UpdateBrand");
    const [brand, setBrand] = useState<Brand>({
        brandId: 0,
        description: "",
    });

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const onFinish = async (values: Brand) => {
        dispatch(setLoading(true));

        await apiService
            .put("/api/Brands", values)
            .then((response) => {
                toast.success(t("brandUpdatedSuccessfully"));
                navigate("/brands");
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    useEffect(() => {
        const fetchUnitMeasureData = async () => {
            const brandAction = await dispatch(getBrandDataById(parseInt(id ?? "0")));
            const brand = brandAction.payload as Brand;
            if(brand)
            setBrand(brand);
            else{
                navigate("/brands");
            }
        };

        fetchUnitMeasureData();
    }, [id, dispatch]);

    return (
        <Spin tip="Loading..." spinning={loadScreen}>
            <Title level={3}>{t("formTitle")}</Title>
            <Text italic>{t("formSubtitle")}</Text>
            <Formik
        initialValues={brand}
        onSubmit={onFinish}
        enableReinitialize
      >
        {({ values, handleSubmit, submitForm }) => (
            <Form
                layout="vertical"
                onFinish={handleSubmit}
                style={{ marginTop: 20 }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <FormInput
                          name="description"
                          label={t("descriptionInputLabel")}
                          rules={[
                              {
                                  required: true,
                                  message: t("descriptionInputPlaceholder"),
                              },
                          ]}
                          value={values.description}
                        />
                    </Col>
                </Row>
                <FormSubmitButton
                handleValidate={undefined}
                handleConfirm={submitForm}
                buttonText={t("updateBrandButton")}
                />
            </Form>
        )}
        </Formik>
        </Spin>
    );
};

export default UpdateBrand;