import { AutoComplete, Col, Row } from "antd";
import { ItemModel } from "../../../../models/clientDashboard/Item/ItemModel";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { handleNextGridColumnClick } from "../../../../helperMethods/handleNextGridColumnClick";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import Search from "antd/es/input/Search";
import { useCallback, useMemo, useRef, useState } from "react";
import { CreateOrUpdateCompositeItemDto } from "../../../../models/clientDashboard/Item/CreateOrUpdateCompositeItemDto";

import { GetItemDetailsDto } from "../../../../models/clientDashboard/Item/GetItemDetailsDto";
import { getItemDetailsForLocalPurchase, getItemsBySearchValue, getItemsBySearchValueForCompositeItem } from "../../../../redux/slices/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import { debounce } from "lodash";
import { getImportPurchaseDetailColumns } from "../../ImportPurchaseDetails/AgGridImportPurchaseDetailColumns";
import { ItemDescriptionIdDto } from "../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { getCompositeItemColumns } from "./GetCompositeItemColumns";
import { toast } from "react-toastify";

interface Props {
  values: ItemModel;
  compositeItems: CreateOrUpdateCompositeItemDto[];
  addNewCompositeItem: (compositeItem: CreateOrUpdateCompositeItemDto) => void;
  removeCompositeItem: (index: number) => void;
  updateCompositeItem: (compositeItem: CreateOrUpdateCompositeItemDto[]) => void;
  mode: string;
}

export default function CompositeItemListComponent({ values, compositeItems, addNewCompositeItem, removeCompositeItem, updateCompositeItem, mode }: Props) {
  const [itemOptions, setItemOptions] = useState<any[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const [searchValue, setSearchValue] = useState("");
  const t = useAppTranslation("ClientDashboard.CreateItem")
  const searchInputRef = useRef<any | null>(null);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onImportPurchaseDetailGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['compositeItemId']);
    params.columnApi.autoSizeColumns(['itemId']);
    params.columnApi.autoSizeColumns(['quantityCoefficient']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const gridRef = useRef(null);
  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const [columnDefs] = useState<ColDef<CreateOrUpdateCompositeItemDto>[]>(
    getCompositeItemColumns(removeCompositeItem, t, mode)
  );
  const onChange = (data: any) => {
    setSearchValue(data);
  };

  const handleItemSearch = async (value: any, supplierId: number) => {
    const itemsBasedOnValueAction = await dispatch(
      getItemsBySearchValueForCompositeItem({ searchValue: value, supplierId: supplierId })
    );
    const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

    if (!items || items.length === 0) {
      setItemOptions([
        {
          label: t("noItemsFound"),
          value: "no-items",
          disabled: true
        }
      ]);
    } else {
      setItemOptions([]);
      setItemOptions(
        items?.map((item) => ({
          key: item.itemId,
          label: item.description,
          value: item.itemId.toString(),
        }))
      );
    }
  };
  const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

  const handleOnSelect = async (value: any) => {
    const itemID = parseInt(value);
    const itemOnDatabase = await dispatch(
      getItemDetailsForLocalPurchase({ itemID, branchID: values.branchId ?? 0 })
    );
    if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/rejected"
    ) {
      toast.error("Item not found");
      return;
    } else if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/fulfilled"
    ) {
      const item = itemOnDatabase.payload as GetItemDetailsDto;

      if (item) {
        const isItemExist = compositeItems.some(
          (detail: any) => detail.itemId === item.itemId
        );
        if (isItemExist) {
          return;
        }
        const newItemDetail: CreateOrUpdateCompositeItemDto = {
          compositeItemId: 0,
          itemId: item.itemId,
          itemDescription: item.description,
          quantityCoefficient: 1,
          rowAction: 'A'
        };
        addNewCompositeItem(newItemDetail);

      }
    }
  }
  const handleSearchChange = (value: string, supplierId: number) => {
    setSearchValue(value);
    debouncedItemSearch(value, supplierId);
  };

  const memoizedRowData3 = useMemo(() => {
    return compositeItems.filter((detail) => detail.rowAction === "D");
  }, [compositeItems]);

  return (
    <>  <Row gutter={[8, 8]}>
      <Col xs={12} sm={8}>
        <AutoComplete
          options={itemOptions}
          onSelect={handleOnSelect}
          onSearch={(item) => handleSearchChange(item, values.primarySupplierID ?? 0)}
          onChange={onChange}
          className="custom-search"
          value={searchValue ?? ""}
          disabled={values.primarySupplierID === 0 || values.primarySupplierID === null
            || values.primarySupplierID === undefined}
        >
          <Search
            placeholder={t('searchPlaceholder')}
            allowClear
            ref={searchInputRef}
            size="large"
            onSearch={(item: any) => handleSearchChange(item, item.primarySupplierID)}
          />
        </AutoComplete>
      </Col>
    </Row>
      <Row gutter={[12, 8]}>
        <Col xs={24} sm={24}>
          <AgGridTableComponent
            style={{ overflow: "auto" }}
            onGridReady={onImportPurchaseDetailGridReady}
            gridRef={gridRef}
            isLoading={isLoading}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={memoizedRowData3}
            stopEditingWhenCellsLoseFocus={true}
            suppressCellFocus={true}
            onCellKeyDown={(event: CellKeyDownEvent) =>
              handleNextGridColumnClick(event, searchInputRef)
            }
          />
        </Col>
      </Row></>
  )
}