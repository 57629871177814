import { RefObject, useRef } from "react";
import { CreatePaymentExecutionsDetailsDto } from "../../../../../models/clientDashboard/Sales/SalesInvoiceDetail/CreatePaymentExecutionsDetailsDto";
import { ColDef, GridApi } from "ag-grid-community";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";


export const getPaymentExecutionDetailColumns = (
    paymentExecutionsRef: RefObject<any>,
    totalWithVat: number,
    deletePaymentExecutionDetail: (index: number) => void,
    t: (text: string) => string,
    mode: string
) => {

    const columnDefs: ColDef[] = [
        {
            headerName: t("tableHeaders.paymentMethodId"),
            field: "paymentMethodId",
            sortable: false,
            filter: false,
            width: 120,
            suppressMovable: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.description"),
            field: "paymentMethodDescription",
            editable: false,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            width: 120,
            suppressMovable: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.amount"),
            field: "amount",
            editable: true,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            width: 120,
            suppressMovable: true,
            suppressMenu: true,
            valueSetter: (params) => {
                let newValue = parseFloat(params.newValue);
                const rowIndex = params?.node?.rowIndex ?? 0;

                if (isNaN(newValue) || newValue <= 0 || newValue === undefined || newValue === null) {
                    toast.error(t("amountZero"));
                    params.data.amount = params.oldValue;
                    return true;
                }

                newValue = parseFloat(newValue.toFixed(4));

                const updatedExecutions = [...paymentExecutionsRef.current.props.rowData ?? []];
                updatedExecutions[rowIndex] = {
                    ...updatedExecutions[rowIndex],
                    amount: newValue,
                };
                const paymentExecutionsSum = updatedExecutions
                    .filter(payment => payment.rowAction !== 'D')
                    .reduce((sum, execution) => sum + (execution.amount ?? 0), 0).toFixed(4);

                if (parseFloat(paymentExecutionsSum) > parseFloat(totalWithVat.toString())) {
                    toast.error(t("sumEquall"));
                    updatedExecutions[rowIndex].amount = params.oldValue;
                    params.data.amount = params.oldValue;
                } else {
                    params.data.amount = newValue;
                }

                return true;
            }
        },
        {
            headerName: t("tableHeaders.options"),
            suppressMenu: true,
            maxWidth: 120,
            cellRenderer: (params: any) => {
                return (
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => deletePaymentExecutionDetail(params.rowIndex)}
                        style={{ color: "#007FFF" }}
                    ></Button>
                );
            },
        },
    ];


    return columnDefs;
};