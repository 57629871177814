import { Button, Modal, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../indexDB/clientSideDatabase";
import { deleteBranchAsync, getBranchesAsync } from "../../../redux/slices/branchSlice";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import AgGridCustomContextMenu from "../../CustomComponents/AgGridCustomContextMenu";
import { AgGridReact } from "ag-grid-react";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import WithPermission from "../../Authorization/WithPermission";
import { usePermission } from "../../../customHooks/usePermission";


const { Title, Text, Link } = Typography;

const Branches = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const branches = useSelector((state: RootState) => state.branch.branches);
  const t = useAppTranslation("ClientDashboard.Branches");
  const dispatch = useDispatch<AppDispatch>();
  const [menuBranchesDisabled, setMenuBranchesDisabled] = useState(false);

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

  const deleteBranch = async (branchID: number) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      dispatch(deleteBranchAsync(branchID));
    }
  };

  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "branchId",
      headerName: "ID",
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.branchId}</Text>;
      },
      suppressMenu: true
    },
    { field: "description", headerName: t("tableHeaders.description"), suppressMenu: true },
    { field: "shortDescription", headerName: t("tableHeaders.shortDescription"), suppressMenu: true },
    { field: "isActive", headerName: t("tableHeaders.isActive"), suppressMenu: true },
    { field: "fiscalCode", headerName: t("tableHeaders.fiscalCode"), suppressMenu: true },
    { field: "isMainBranch", headerName: t("tableHeaders.isMainBranch"), suppressMenu: true },
    {
      headerName: t("tableHeaders.options"), suppressMenu: true, width: 100,
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.BranchesCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateBranch"),
                  `branch/update/${params?.data?.branchId}`,
                  undefined
                );
                navigate(`/branch/update/${params?.data?.branchId}`);
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
    },
  ]);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['shortDescription']);
    params.columnApi.autoSizeColumns(['isActive']);
    params.columnApi.autoSizeColumns(['fiscalCode']);
    params.columnApi.autoSizeColumns(['isMainBranch']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getBranches = async () => {
    const result = await dispatch(getBranchesAsync());
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  useEffect(() => {
    getBranches();
  }, [dispatch, gridApi]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.BranchesCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateBranch"),
        `branch/update/${event.data.branchId}`,
        undefined
      );
      navigate(`/branch/update/${event.data.branchId}`);
    }
  };

  const handleCreateNewBranch = () => {
    navigate("/branch/register");
    db.addTab(t("tabs.createBranch"), "branch/register", undefined);
  };
  const handleRowClick = (event: any) => {
    setSelectedRow(event.data);
  };

  const divRef = useRef<HTMLDivElement>(null);

  const handleRightClick = (event: MouseEvent) => {
    if (divRef.current && divRef.current.contains(event.target as Node)) {
      // Check if the right-click happened inside the wrapping div
      event.preventDefault();
      setShowContextMenu(true);
      setContextMenuPosition({ x: event.clientX, y: event.clientY });
    } else {
      setShowContextMenu(false);
    }
  };


  useEffect(() => {
    // Add right-click listener
    document.addEventListener('contextmenu', handleRightClick);
    // Cleanup the listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  const handleCopyRow = () => {
    if (selectedRow) {
      // Logic for copying the row data
      console.log("Copied row:", selectedRow);
    }
  };

  return (
    <>
      <ListHeaderComponent
        title={t("titles.branch")}
        createRecord={handleCreateNewBranch}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.BranchesCreate}
      />
      <AgGridTableComponent
        onGridReady={onGridReady}
        divRef={divRef}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={branches}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
        onRowClicked={handleRowClick}
      />
      <AgGridCustomContextMenu
        gridRef={divRef}
        contextMenuPosition={contextMenuPosition}
        showContextMenu={showContextMenu}
        onCopy={handleCopyRow}
      />
    </>
  );
};

export default Branches;
