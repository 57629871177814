import { Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { GetSaleOrderDto } from "../../../../models/clientDashboard/EntriesModule/SaleOrder/GetSaleOrderDto";
import { formatDate } from "../../../../helperMethods/DateFormat";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getInternalSalesColumnsAsync, getInternalSalesWithFilters } from "../../../../redux/slices/internalSaleSlice";
import { FilterData } from "../../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../../customHooks/useFilter";
import { exportDataToCsv } from "../../../../models/helper/exportDataToCsv";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import { renderFilterInputs } from "../../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../../CustomComponents/FilterModal";
import {
  RightOutlined,
} from "@ant-design/icons";
import { GetInternalSalesDto } from "../../../../models/clientDashboard/EntriesModule/InternalSale/GetInternalSalesDto";
import WithPermission from "../../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import { usePermission } from "../../../../customHooks/usePermission";


const { Title, Text, Link } = Typography;
export default function InternalSale() {
  const internalSales = useSelector(
    (state: RootState) => state.internalSale.internalSales
  );
  const t = useAppTranslation("ClientDashboard.InternalSale");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const colDefs = useMemo(() => {
    const columns = [
      {
        field: "internalSaleId",
        headerName: "ID",
        suppressMenu: true,
        cellRenderer: (params: any) => {
          return <Text className="ag-grid-id-column">{params?.data?.internalSaleId}</Text>;
        },
      },

      { field: "registrationDate", headerName: t("tableHeaders.registrationDate"), suppressMenu: true, width: 210 },
      { field: "branchId", headerName: t("tableHeaders.branchId"), suppressMenu: true, width: 250 },
      { field: "registeredBy", headerName: t("tableHeaders.registeredBy"), suppressMenu: true },
      { field: "comment", headerName: t("tableHeaders.comment"), suppressMenu: true },
      { field: "validated", headerName: t("tableHeaders.validated"), suppressMenu: true },
      { field: "documentNo", headerName: t("tableHeaders.documentNo"), suppressMenu: true },
      { field: "documentTypeId", headerName: t("tableHeaders.documentTypeId"), suppressMenu: true },
      { field: "parentId", headerName: t("tableHeaders.parentId"), suppressMenu: true },
      {
        headerName: t("tableHeaders.details"),
        cellRenderer: (params: any) => (
          <WithPermission
            menuOptionId={MenuOptionEnum.InternalSaleCreate}
            children={
              <Button
                type="text"
                icon={<RightOutlined />}
                onClick={() => {
                  db.addTab(t("tabs.updateInternalSale"), `internalSale/update/${params?.data?.internalSaleId}/${params.data.branchId}`, undefined);
                  navigate(`/internalSale/update/${params?.data?.internalSaleId}/${params.data.branchId}`);
                }}
                style={{
                  color: "#007FFF",
                }}
              />
            }
          />
        ),
        width: 100,
        suppressMenu: true,
      },
    ]
    return columns;
  }
    , []);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["registrationDate"]);
    params.columnApi.autoSizeColumns(["branchId"]);
    params.columnApi.autoSizeColumns(["registeredBy"]);
    params.columnApi.autoSizeColumns(["validated"]);
    params.columnApi.autoSizeColumns(["documentNo"]);
    params.columnApi.autoSizeColumns(["documentTypeId"]);
    params.columnApi.autoSizeColumns(["parentId"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);
  const getInternalSales = async () => {
    const result = await dispatch(getInternalSalesWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    getInternalSales();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.InternalSaleCreate)


  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(t("tabs.updateInternalSale"), `internalSale/update/${event?.data?.internalSaleId}/${event.data.branchId}`, undefined)
      navigate(`/internalSale/update/${event?.data?.internalSaleId}/${event.data.branchId}`)
    };
  };
  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;
    const result = await dispatch(getInternalSalesWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getInternalSalesColumnsAsync());
    if (
      result.type === "InternalSales/getInternalSalesColumnsAsync/fulfilled"
    ) {
      return result;
    } else {
      return null;
    }
  };
  const handleClearFilters = async () => {
    const result = await dispatch(getInternalSalesWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewInternalSales = async () => {
    navigate("/internalSale/register");
    const existingTab = await db.tabs.get("internalSale/register");
    if (!existingTab) {
      db.addTab(
        t("tabs.createInternalSale"),
        "internalSale/register",
        undefined
      );
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "internalSaleId",
      "branch",
      "supplier",
      "documentType",
      "documentNo",
      "validated",
      "date",
      "registrationDate",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.internalSale")}
        createRecord={handleCreateNewInternalSales}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.InternalSaleCreate}
        exportAccessEnum={MenuOptionEnum.InternalSaleExport}
      />
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState &&
          filterState?.filterInputs?.map((input) => (
            <div
              key={input.name}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Text>
                {input.isClassifier
                  ? input.name
                  : t(`FilterColumns.${input.name}`)}
              </Text>
              {renderFilterInputs({ input: input, filterState: filterState })}
            </div>
          ))}
      </div>
      {filterState && filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button onClick={filterState?.handleClearFilters}>
            {t("clearFilters")}
          </Button>
          <Button type="primary" onClick={filterState?.handleFilterSubmit}>
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={internalSales}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.InternalSale"
      />
    </>
  );
}
