import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../redux";
import { useEffect, useState } from "react";
import { getCompanyDataById } from "../../../../redux/slices/dashboardCompanySlice";
import { CompanyDetails } from "../../../../models/dashboard/Company/SingleCompany/CompanyDetails";
import { Divider, Spin, Tabs } from "antd";
import CompanyDetailsComponent from "./CompanyDetailsComponent";
import CompanySubscriptionsComponent from "./CompanySubscriptionsComponent";
import { getClientTypesAsync } from "../../../../redux/slices/clientTypesSlice";
import { ClientType } from "../../../../models/dashboard/Company/ClientType";
import CompanyUsersComponent from "./CompanyUsersComponent";
import { rolesForAdmin } from "../../../../lookups/AdminRoles";
import AntSelectValue from "../../../../models/helper/AntSelectValueModel";
import { Typography } from "antd";
import CompanyServersComponent from "./CompanyServersComponents";
import CompanyPayments from "./CompanyPayments";

const { Title } = Typography;

const ClientComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const navigate = useNavigate();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const clientTypes = useSelector((state: RootState) =>
    state.clientTypes.clientTypes?.map((item: ClientType) => {
      return {
        label: item.typeDescription,
        value: item.clientTypeID,
        ...item,
      };
    })
  );
  const [userRoles, setUserRoles] = useState<AntSelectValue[]>([]);

  const [initialValues, setInitialValues] = useState<CompanyDetails>({
    company: {
      bussinesNumber: "",
      clientTypeID: 0,
      companyID: 0,
      companyName: "",
      createdBy: 0,
      createdBy_FullName: "",
      dateCreated: new Date(),
      email: "",
      phone: "",
      status: true,
      typeDescription: "",
      vatNumber: "",
      logo: "",
    },
    companyUsers: [],
    subscriptions: [],
    companyServers: [],
    companyPayments: {
      totalPaidAmount: 0,
      totalSubscriptionAmount: 0,
      subscriptionPayments: [],
    },
  });
  const [logoBase64, setLogoBase64] = useState<string>("");

  useEffect(() => {
    dispatch(getClientTypesAsync());
    fetchCompany();

    const adminRoles = rolesForAdmin();

    setUserRoles(adminRoles);
  }, [dispatch, id]);

  const fetchCompany = async () => {
    const companyAction = await dispatch(
      getCompanyDataById(parseInt(id ? id : "0", 10))
    );
    const company = companyAction.payload as CompanyDetails;
    setInitialValues(company);
    setLogoBase64(company.company.logo)
  };


  return (
    <>
      <Spin tip="Loading..." spinning={isLoading}>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Detale",
              key: "1",
              children: (
                <CompanyDetailsComponent
                  logoBase64={logoBase64}
                  setLogoBase64={setLogoBase64}
                  company={initialValues.company}
                  clientTypes={clientTypes}
                  companyID={parseInt(id ? id : "0", 10)}
                />
              ),
            },
            {
              label: "Abonimet",
              key: "2",
              children: (
                <CompanySubscriptionsComponent
                  subscriptions={initialValues.subscriptions}
                />
              ),
            },
            {
              label: "Përdouesit",
              key: "3",
              children: (
                <CompanyUsersComponent
                  users={initialValues.companyUsers}
                  companyId={parseInt(id ? id : "0", 10)}
                  userRoles={userRoles}
                  fetchCompany={fetchCompany}
                />
              ),
            },
            {
              label: "Serverat",
              key: "4",
              children: (
                <>
                  <CompanyServersComponent
                    servers={initialValues.companyServers}
                  />
                </>
              ),
            },
            {
              label: "Pagesat",
              key: "5",
              children: (
                <>
                  <CompanyPayments
                    subscriptionPaymentInfo={initialValues.companyPayments}
                  />
                </>
              ),
            },
          ]}
        />
      </Spin>
    </>
  );
};

export default ClientComponent;
