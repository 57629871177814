import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/UnitMeasure/UnitMeasureState";
import { UnitMeasureState } from "../../models/clientDashboard/UnitMeasure/UnitMeasureState";
import { UnitMeasure } from "../../models/clientDashboard/UnitMeasure/UnitMeasure";
import { RootState } from "..";

const initialState: UnitMeasureState = {
    unitMeasures: [],
    error: null,
};

const UnitMeasureSlice = createSlice({
    name: "unitMeasure",
    initialState,
    reducers: {
        getUnitMeasuresStart(state: UnitMeasureState) {
            setLoading(true);
            state.error = null;
        },
        getUnitMeasuresSuccess(
            state: UnitMeasureState,
            action: PayloadAction<UnitMeasure[]>
        ) {
            setLoading(false);
            state.unitMeasures = action.payload;
        },
        getUnitMeasuresFailure(
            state: UnitMeasureState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewUnitMeasureToState(state: UnitMeasureState, action: PayloadAction<UnitMeasure>) {
            setLoading(false);
            state.unitMeasures.push(action.payload);
        },
        updateUnitMeasureInState(state: UnitMeasureState, action: PayloadAction<UnitMeasure>) {
                setLoading(false);
                const updatedUnitMeasure = action.payload;
                const index = state.unitMeasures.findIndex(unitMeasure => unitMeasure.unitMeasureId === updatedUnitMeasure.unitMeasureId);
                if (index !== -1) {
                state.unitMeasures[index] = updatedUnitMeasure;
                }
        },
        deleteUnitMeasureFromState(state: UnitMeasureState, action: PayloadAction<number>) {
            setLoading(false);
            const unitMeasureId = action.payload;
            state.unitMeasures = state.unitMeasures.filter(unitMeasure => unitMeasure.unitMeasureId !== unitMeasureId);
        },
    }
});

export const getUnitMeasuresAsync = createAsyncThunk(
    "UnitMeasures/getUnitMeasures",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const unitMeasures = state.unitMeasure.unitMeasures;

        if (unitMeasures.length > 0) {
            return unitMeasures;
        }

        try {
            dispatch(getUnitMeasuresStart());
            const response = await apiService.get<ApiResponse<UnitMeasure[]>>(
                "api/Items/unitMeasures"
            );
            // toast.success("Unit measures were successfully retrieved!");
            dispatch(getUnitMeasuresSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getUnitMeasuresFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

//see if we need it
export const getUnitMeasureDataById = createAsyncThunk(
    "UnitMeasures/getUnitMeasureDataById",
    async (unitMeasureId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<UnitMeasure>>(
                `/api/UnitMeasures/${unitMeasureId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

// export const deleteUnitMeasureAsync = createAsyncThunk(
//     "UnitMeasures/deleteUnitMeasure",
//     async (unitMeasureId: number, { dispatch, rejectWithValue }) => {
//         try {
//             dispatch(setLoadingState(true));
//             await apiService.delete<ApiResponse<void>>(
//                 `/api/UnitMeasures/${unitMeasureId}`
//             );
//             dispatch(deleteUnitMeasureFromState(unitMeasureId));
//         } catch (error: any) {
//             const errorMessage =
//                 typeof error === "string" ? error : "An error occurred";
//             return rejectWithValue(errorMessage);
//         } finally {
//             dispatch(setLoadingState(false));
//         }
//     }
// );

export const {
    getUnitMeasuresStart,
    getUnitMeasuresSuccess,
    getUnitMeasuresFailure,
    addNewUnitMeasureToState,
    updateUnitMeasureInState,
    deleteUnitMeasureFromState
} = UnitMeasureSlice.actions;

export default UnitMeasureSlice.reducer;