import { useCallback, useEffect, useRef, useState } from "react";
import { exportDataToCsv } from "../../../models/helper/exportDataToCsv";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { Button, Typography, Popconfirm } from "antd"; // Import Popconfirm
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { ProductSearchColumnDto } from "../../../models/clientDashboard/ProductSearch/ProductSearchColumnDto";
import { AppDispatch, RootState } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { RightOutlined } from "@ant-design/icons";
import { getProductSearchAsync } from "../../../redux/slices/productSearchSlice";
import _ from "lodash";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";

const { Text } = Typography;

export default function ProductSearchColumnList() {
  const productSearchColumns = useSelector(
    (state: RootState) => state.productSearchColumn.productSearch
  );
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const t = useAppTranslation("ClientDashboard.ProductSearchColumn");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const gridRef = useRef<AgGridReact | null>(null);

  const colDefs: ColDef<ProductSearchColumnDto>[] = [
    {
      field: "columnId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return (
          <Text className="ag-grid-id-column">{params?.data?.columnId}</Text>
        );
      },
    },
    {
      field: "columnName",
      headerName: t("tableHeaders.columnName"),
      valueFormatter: (params: any) => {
        return t(`${params?.data.columnName}`);
      },
      width: 290,
    },
    {
      field: "columnStatus",
      headerName: t("tableHeaders.columnStatus"),
      editable: true,
    },
    {
      field: "columnOrder",
      headerName: t("tableHeaders.columnOrder"),
      editable: true,
      valueSetter: (params: any) => {
        const newValue = Number(params.newValue);
        if (isNaN(newValue)) {
          alert(t("alerts.numericValueRequired"));
          return false;
        }
        let hasDuplicate = false;
        params.api.forEachNode((node: any) => {
          if (
            node.data.columnOrder === newValue &&
            node.rowIndex !== params.rowIndex
          ) {
            hasDuplicate = true;
          }
        });
        if (hasDuplicate) {
          alert(t("alerts.duplicateColumnOrder"));
          return false;
        }
        params.data.columnOrder = newValue;
        return true;
      },
    },
  ];

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns([
      "columnId",
      "columnName",
      "columnStatus",
      "columnOrder",
    ]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const [perPage, setPerPage] = useState(20);

  useEffect(() => {
    dispatch(getProductSearchAsync());
  }, [dispatch]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "columnId",
      "columnName",
      "columnStatus",
      "columnOrder",
    ]);
  }, [gridRef]);

  const mutableObject = _.cloneDeep(productSearchColumns);

  const handleSubmit = async () => {
    await apiService
      .put("/api/ProductSearchColumns", mutableObject)
      .then((response) => {
        toast.success(t("success"));
      });
  };

  const submitButtonIsDisabled = isLoading || mutableObject.length === 0;

  return (
    <>
      <ListHeaderComponent
        title={t("titles.productSearchColumn")}
        menuItemsDisabled={menuItemsDisabled}
        createAccessEnum={MenuOptionEnum.ProductColumnsAccess}
      />

      <Popconfirm
        title={t("confirm.saveChanges")} 
        onConfirm={handleSubmit} 
        onCancel={() => {}}
        okText={t("confirm.yes")}
        cancelText={t("confirm.no")} 
      >
        <Button
          type="primary"
          disabled={submitButtonIsDisabled}
          style={{ marginBottom: "20px" }}
        >
          {t("createButton")}
        </Button>
      </Popconfirm>

      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={mutableObject}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={perPage}
      />
    </>
  );
}
