import jwt_decode from "jwt-decode";
import DecodedUser from "../models/dashboard/Users/DecodedUser";

//create a method that accepts jwt token and with  jwt-decode library extract the jwt and returns a object with user role, exp, and user full name

export default function decodeToken(token: string): DecodedUser | null {
  try {
    const decoded: any = jwt_decode(token);
    return {
      roleId: decoded.roleId,
      userId: decoded.userId,
      exp: decoded.exp,
      fullName: decoded.unique_name,
      userRoleId: decoded.userRoleId,
      companyId: decoded.companyId,
      branchId: decoded.branchId,
      isMainBranch: true,
      branchName: decoded.branchName,
    };
  } catch (e) {
    console.error("Invalid JWT Token");
    return null;
  }
}
