import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/City/CityState";
import { CityState } from "../../models/clientDashboard/City/CityState";
import { CreateCityDto } from "../../models/clientDashboard/City/CreateCityDto";
import { City } from "../../models/clientDashboard/City/City";
import { GetCitiesDto } from "../../models/clientDashboard/City/GetCitiesDto";
import { RootState } from "..";

const initialState: CityState = {
    cities: [],
    error: null,
    citiesBasicInfo:[]
};

const CitySlice = createSlice({
    name: "city",
    initialState,
    reducers: {
        getCitiesStart(state: CityState) {
            setLoading(true);
            state.error = null;
        },
        getCitiesSuccess(
            state: CityState,
            action: PayloadAction<City[]>
        ) {
            setLoading(false);
            state.cities = action.payload;
        },
        setCitiesBasicInfo(
            state: CityState,
            action: PayloadAction<GetCitiesDto[]>
        ){
            setLoading(false);
            state.citiesBasicInfo = action.payload;
        },
        getCitiesFailure(
            state: CityState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewCityToState(state: CityState, action: PayloadAction<City>) {
            setLoading(false);
            state.cities.push(action.payload);
        },
        updateCityInState(state: CityState, action: PayloadAction<City>) {
            setLoading(false);
            const updatedCity = action.payload;
            const index = state.cities.findIndex(city => city.cityId === updatedCity.cityId);
            if (index !== -1) {
                state.cities[index] = updatedCity;
            }
        },
        deleteCityFromState(state: CityState, action: PayloadAction<number>) {
            setLoading(false);
            const cityId = action.payload;
            state.cities = state.cities.filter(city => city.cityId !== cityId);
        },
    }
});

export const getCitiesAsync = createAsyncThunk(
    "Cities/getCities",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const cities= state.city.cities;

        if (cities.length > 0) {
            return cities;
        }

        try {
            dispatch(getCitiesStart());
            const response = await apiService.get<ApiResponse<GetCitiesDto[]>>(
                "api/Entity/cities"
            );
            // toast.success("Cities were successfully retrieved!");
            dispatch(setCitiesBasicInfo(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getCitiesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getCitiesForStateAsync = createAsyncThunk(
    "Cities/getCities",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const cities= state.city.cities;

        if (cities.length > 0) {
            return cities;
        }

        try {
            dispatch(getCitiesStart());
            const response = await apiService.get<ApiResponse<GetCitiesDto[]>>(
                "api/Entity/cities"
            );
            // toast.success("Cities were successfully retrieved!");
            // dispatch(setCitiesBasicInfo(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getCitiesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getCityDataById = createAsyncThunk(
    "Cities/getCityDataById",
    async (cityId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<City>>(
                `/api/City/GetCityDataById/${cityId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteCityAsync = createAsyncThunk(
    "Cities/deleteCity",
    async (cityId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/clientDashboard/City/DeleteCity/${cityId}`
            );
            dispatch(deleteCityFromState(cityId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getCitiesStart,
    getCitiesSuccess,
    getCitiesFailure,
    addNewCityToState,
    updateCityInState,
    deleteCityFromState,
    setCitiesBasicInfo
} = CitySlice.actions;

export default CitySlice.reducer;