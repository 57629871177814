import { ColDef } from "ag-grid-community";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CreateAndUpdateEntityBranchDto } from "../../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import { GetCitiesDto } from "../../../../models/clientDashboard/City/GetCitiesDto";



export const getEntityBranchesDetailColumns = (
    removeEntityBranchesDetail: (index: number) => void,
    updateEntityBranchesDetail: (tempId: string) => void,
    t: (text: string) => string,
    classifiers: Classifier[],
    cities: GetCitiesDto[],
) => {
    const columnDefs: ColDef<CreateAndUpdateEntityBranchDto>[] = [
        {
            headerName: t("tableHeaders.description"),
            field: "description",
            sortable: false,
            filter: false,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.email"),
            field: "email",
            sortable: false,
            filter: false,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.phoneNumber"),
            field: "phoneNumber",
            sortable: false,
            filter: false,
            maxWidth: 150,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.address"),
            field: "address",
            sortable: false,
            filter: false,
            maxWidth: 100,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.cityId"),
            field: "cityId",
            sortable: false,
            valueGetter: (params) => {
                const cityId = params?.data?.cityId;
                const city = cities?.find((city) =>
                    city.cityId === cityId)
                return city?.cityDescription?? ""
            },
            filter: false,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.contactPerson"),
            field: "contactPerson",
            sortable: false,
            filter: false,
            maxWidth: 140,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.comment"),
            field: "comment",
            sortable: false,
            filter: false,
            maxWidth: 140,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
        }];
        classifiers.forEach((classifier, index) =>{
            columnDefs.push({
                headerName: classifier.clientDescription,
                field: classifier?.description?.toLowerCase(),
                valueGetter: (params) => {
                    const fieldName = classifier?.description?.toLowerCase();
            
                    const fieldValue = params?.data ? params?.data[fieldName] : null;
                    if(typeof fieldValue === "object"){
                    return( fieldValue?.label || null)
                    }else if(typeof fieldValue === "number"){
                        return params?.data ? params?.data[`${fieldName}Description`] : null
                    }
                },
                sortable: false,
                filter: false,
                maxWidth: 140,
                suppressMovable: true,
                singleClickEdit: true,
                suppressMenu: true,
            });
            
        })
        columnDefs.push(
        {
            headerName: t("tableHeaders.options"),
            suppressMenu: true,
            maxWidth: 80,
            cellRenderer: (params: any) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => removeEntityBranchesDetail(params.rowIndex)}
                    style={{ color: "#007FFF" }}
                />
                 <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => updateEntityBranchesDetail(params.data.tempId)}
                    style={{ color: "#4CAF50", marginRight: '8px' }}
                />
            </div>
                );
            },
        })

    return columnDefs;
}