export default function Regjistrimet() {
  return (
    <svg
      width="20"
      height="20"
      className="icon-for-sidebar"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 1.66669H5C4.55798 1.66669 4.13405 1.84228 3.82149 2.15484C3.50893 2.4674 3.33334 2.89133 3.33334 3.33335V16.6667C3.33334 17.1087 3.50893 17.5326 3.82149 17.8452C4.13405 18.1578 4.55798 18.3334 5 18.3334H15C15.442 18.3334 15.866 18.1578 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V5.83335L12.5 1.66669Z"
        stroke="#5E5E5E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 1.66669V5.00002C11.6667 5.44205 11.8423 5.86597 12.1548 6.17853C12.4674 6.49109 12.8913 6.66669 13.3333 6.66669H16.6667"
        stroke="#5E5E5E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66666 15V13.3333"
        stroke="#5E5E5E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15V11.6667"
        stroke="#5E5E5E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 15V10"
        stroke="#5E5E5E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
