import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BranchState } from "../../models/clientDashboard/Branch/BranchState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { SelectBranchDto } from "../../models/clientDashboard/Branch/SelectBranchDto";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { ItemModel } from "../../models/clientDashboard/Item/ItemModel";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import { Branch } from "../../models/clientDashboard/Branch/Branch";

const initialState: BranchState = {
    branches: [],
    error: null
};

const BranchSlice = createSlice({
    name: "branch",
    initialState,
    reducers: {
        getBranchesStart(state: BranchState) {
            setLoading(true);
            state.error = null;
        },
        getBranchesSuccess(
            state: BranchState,
            action: PayloadAction<Branch[]>
        ) {
            setLoading(false);
            state.branches = action.payload;
        },
        getBranchesFailure(
            state: BranchState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewBranchToState(state: BranchState, action: PayloadAction<Branch>) {
            setLoading(false);
            state.branches.push(action.payload);
        },
        updateBranchInState(state: BranchState, action: PayloadAction<Branch>) {
            setLoading(false);
            const updatedBranch = action.payload;
            const index = state.branches.findIndex(branch=> branch.branchId === updatedBranch.branchId);
            if (index !== -1) {
                state.branches[index] = updatedBranch;
            }
        },
        deleteBranchFromState(state: BranchState, action: PayloadAction<number>) {
            setLoading(false);
            const branchId = action.payload;
            state.branches = state.branches.filter(branch => branch.branchId !== branchId);
        },
    }
});

export const getBranchesAsync = createAsyncThunk(
    "Branches/getBranches",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;


        try {
            let url  = `api/Branches`;
            const response = await apiService.get<ApiResponse<Branch[]>>(
                url
            );
            dispatch(getBranchesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getBranchesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
        }
    }
);

export const getBranchDataById = createAsyncThunk(
    "Branches/getBranchDataById",
    async (branchId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<ItemModel>>(
                `/api/Branches/byId?branchId=${branchId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteBranchAsync = createAsyncThunk(
    "Branches/deleteBranch",
    async (branchId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/Branches?branchId=${branchId}`
            );
            dispatch(deleteBranchFromState(branchId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const getActiveBranchesAsync = createAsyncThunk(
    "Branches/getActiveBranches",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;


        try {
            let url  = `api/Branches/activeBranches`;
            const response = await apiService.get<ApiResponse<Branch[]>>(
                url
            );
            dispatch(getBranchesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getBranchesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
        }
    }
);

export const {
    getBranchesStart,
    getBranchesSuccess,
    getBranchesFailure,
    addNewBranchToState,
    updateBranchInState,
    deleteBranchFromState
} = BranchSlice.actions;

export default BranchSlice.reducer;