export default function PurchaseIcon() {
  return (
    <svg
      width="20"
      height="20"
      className="icon-for-sidebar"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 6.66669C17.4997 6.37442 17.4225 6.08736 17.2763 5.83432C17.13 5.58128 16.9198 5.37116 16.6667 5.22502L10.8333 1.89169C10.58 1.74541 10.2926 1.6684 10 1.6684C9.70744 1.6684 9.42003 1.74541 9.16667 1.89169L3.33333 5.22502C3.08022 5.37116 2.86998 5.58128 2.72372 5.83432C2.57745 6.08736 2.5003 6.37442 2.5 6.66669V13.3334C2.5003 13.6256 2.57745 13.9127 2.72372 14.1657C2.86998 14.4188 3.08022 14.6289 3.33333 14.775L9.16667 18.1084C9.42003 18.2546 9.70744 18.3316 10 18.3316C10.2926 18.3316 10.58 18.2546 10.8333 18.1084L16.6667 14.775C16.9198 14.6289 17.13 14.4188 17.2763 14.1657C17.4225 13.9127 17.4997 13.6256 17.5 13.3334V6.66669Z"
        stroke="#5E5E5E"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 5.83331L10 9.99998L17.25 5.83331"
        stroke="#5E5E5E"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18.3333V10"
        stroke="#5E5E5E"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
