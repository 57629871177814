import { Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import {
  formatDate,
  formatDateWithTime,
} from "../../../helperMethods/DateFormat";
import { db } from "../../../indexDB/clientSideDatabase";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import {
  getSupplierAgreementAsync,
  getSupplierAgreementColumnsAsync,
  getSupplierAgreementWithFilter,
} from "../../../redux/slices/supplierAgreementSlice";
import { exportDataToCsv } from "../../../models/helper/exportDataToCsv";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { FilterData } from "../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../customHooks/useFilter";
import { renderFilterInputs } from "../../../helperMethods/renderFilterInputs";
import FilterModal from "../../CustomComponents/FilterModal";
import WithPermission from "../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import { usePermission } from "../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;
export default function SupplierAgreements() {
  const agreements = useSelector(
    (state: RootState) => state.supplierAgreements.supplierAgreement
  );
  const t = useAppTranslation("ClientDashboard.SupplierAgreements");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef[]>([
    {
      field: "supplierAgreementId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return (
          <Text className="ag-grid-id-column">
            {params?.data?.supplierAgreementId}
          </Text>
        );
      },
    },
    // {
    //   field: "supplierId",
    //   headerName: t("tableHeaders.supplierId"),
    //   suppressMenu: true,
    // },
    {
      field: "supplierDescription",
      headerName: t("tableHeaders.supplierDescription"),
      suppressMenu: true,
    },
    // {
    //   field: "signedBy",
    //   headerName: t("tableHeaders.signedBy"),
    //   suppressMenu: true,
    // },
    {
      field: "signedByFullName",
      headerName: t("tableHeaders.signedByFullName"),
      suppressMenu: true,
    },
    // {
    //   field: "registeredBy",
    //   headerName: t("tableHeaders.registeredBy"),
    //   suppressMenu: true,
    // },
    {
      field: "registeredByFullName",
      headerName: t("tableHeaders.registeredByFullName"),
      suppressMenu: true,
    },
    {
      field: "validFrom",
      headerName: t("tableHeaders.validFrom"),
      valueFormatter: formatDate,
      suppressMenu: true,
      width: 120,
    },
    {
      field: "validUntil",
      headerName: t("tableHeaders.validUntil"),
      valueFormatter: formatDate,
      suppressMenu: true,
    },
    {
      field: "paymentDeadline",
      headerName: t("tableHeaders.paymentDeadline"),
      suppressMenu: true,
    },
    {
      field: "comment",
      headerName: t("tableHeaders.comment"),
      suppressMenu: true,
      maxWidth: 140,
    },
    {
      field: "bonus",
      headerName: t("tableHeaders.bonus"),
      suppressMenu: true,
      maxWidth: 140,
    },
    {
      field: "fixedBalance",
      headerName: t("tableHeaders.fixedBalance"),
      suppressMenu: true,
      maxWidth: 140,
    },
    {
      field: "discount",
      headerName: t("tableHeaders.discount"),
      suppressMenu: true,
      maxWidth: 140,
    },
    {
      field: "contractValidAfterExpiration",
      headerName: t("tableHeaders.contractValidAfterExpiration"),
      suppressMenu: true,
      maxWidth: 140,
    },
    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.SupplierAgreementsCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateSupplierAgreement"),
                  `supplierAgreement/update/${params?.data?.supplierAgreementId}`,
                  undefined
                );
                navigate(
                  `/supplierAgreement/update/${params?.data?.supplierAgreementId}`
                );
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 90,
      suppressMenu: true,
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    // params.api.autoSizeColumns(["supplierId"]);
    params.api.autoSizeColumns(["supplierDescription"]);
    // params.api.autoSizeColumns(["signedBy"]);
    params.api.autoSizeColumns(["signedByFullName"]);
    params.api.autoSizeColumns(["registeredBy"]);
    params.api.autoSizeColumns(["registeredByFullName"]);
    params.api.autoSizeColumns(["validFrom"]);
    params.api.autoSizeColumns(["validUntil"]);
    params.api.autoSizeColumns(["paymentDeadline"]);
    params.api.autoSizeColumns(["comment"]);
    params.api.autoSizeColumns(["bonus"]);
    params.api.autoSizeColumns(["fixedBalance"]);
    params.api.autoSizeColumns(["discount"]);
    params.api.autoSizeColumns(["contractValidAfterExpiration"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);
  const getLocalPurchases = async () => {
    const result = await dispatch(getSupplierAgreementWithFilter([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    dispatch(getSupplierAgreementAsync());
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.SupplierAgreementsCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateSupplierAgreements"),
        `supplierAgreements/update/${event?.data?.supplierAgreementId}`,
        undefined
      );
      navigate(`/supplierAgreements/update/${event?.data?.supplierAgreementId}`);
    }
  };
  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;

    const result = await dispatch(getSupplierAgreementWithFilter(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getSupplierAgreementColumnsAsync());
    return result;
  };
  const handleClearFilters = async () => {
    const result = await dispatch(getSupplierAgreementWithFilter([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewSupplierAgreements = async () => {
    navigate("/supplierAgreements/register");
    const existingTab = await db.tabs.get("supplierAgreements/register");
    if (!existingTab) {
      db.addTab(
        t("tabs.createSupplierAgreements"),
        "supplierAgreements/register",
        undefined
      );
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "supplierAgreementId",
      "supplierId",
      "supplierDescription",
      "signedBy",
      "signedByFullName",
      "registeredBy",
      "registeredByFullName",
      "validFrom",
      "validUntil",
      "paymentDeadline",
      "comment",
      "bonus",
      "fixedBalance",
      "discount",
      "contractValidAfterExpiration",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.supplierAgreements")}
        createRecord={handleCreateNewSupplierAgreements}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.SupplierAgreementsCreate}
        exportAccessEnum={MenuOptionEnum.SupplierAgreementsExport}
      />
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        }}
      >
        {filterState?.filterInputs?.map((input) => (
          <div
            key={input.name}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Text>
              {input.isClassifier
                ? input.name
                : t(`FilterColumns.${input.name}`)}
            </Text>
            {renderFilterInputs({ input: input, filterState: filterState })}
          </div>
        ))}
      </div>
      {filterState?.filterInputs?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
            gap: "1.5rem",
          }}
        >
          <Button onClick={filterState.handleClearFilters}>
            {t("clearFilters")}
          </Button>
          <Button type="primary" onClick={filterState.handleFilterSubmit}>
            {t("filter")}
          </Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={agreements}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      // paginationPageSize={20}
      />
      <FilterModal
        filterState={filterState}
        translationPrefix="ClientDashboard.SupplierAgreements"
      />
    </>
  );
}
