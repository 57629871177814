import { RefObject } from "react";
import { CreateAndEditImportPurchaseDetailDto } from "../../../models/clientDashboard/ImportPurchaseDetail/CreateAndEditImportPurchaseDetailDto";
import { ColDef } from "ag-grid-community";
import { suppressEnter } from "../../../helperMethods/suppressEnter";
import { formatPurchaseColumnValues } from "../../../helperMethods/formatPurchaseColumnValues";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import moment from "moment";
import { GetAllConfigurationsDto } from "../../../models/Configurations/GetAllConfigurationsDto";

export const getImportPurchaseDetailColumns = (
  deleteImportPurchaseDetail: (index: number) => void,
  t: (text: string) => string,
  searchItemInputRef: RefObject<any>,
  mode: string,
  setImportPurchaseDetails: (data: any) => void,
  purchaseConfig: GetAllConfigurationsDto,
) => {
  const getMaxNo = (rowData: CreateAndEditImportPurchaseDetailDto[]) => {
    return Math?.max(0, ...rowData?.map((item) => item.no ?? 0));
  };
  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  };

  const columnDefs: ColDef<CreateAndEditImportPurchaseDetailDto>[] = [
    {
      headerName: t("tableHeaders.no"),
      field: "no",
      editable: true,
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
      valueSetter: (params) => {
        const newValue = parseInt(params.newValue, 10);

        const allData: CreateAndEditImportPurchaseDetailDto[] = [];
        params.api.forEachNode((node: any) => allData.push(node.data));

        const isDuplicate = allData.some(
          (row) => row.no === newValue && row !== params.data
        );

        if (isNaN(newValue) || newValue <= 0 || isDuplicate) {
          params.data.no = params.oldValue;
          return false;
        }

        if (
          mode === "update" &&
          params.data.entryItemsDetailsId !== 0 &&
          params.data.entryItemsDetailsId !== undefined &&
          params.data.entryItemsDetailsId !== null
        ) {
          params.data.rowAction = "U";
        }
        params.data.no = newValue;
        return true;
      },
      valueGetter: (params: any) => {
        if (params.data.no === undefined || params.data.no === null) {
          const allData: CreateAndEditImportPurchaseDetailDto[] = [];
          params.api.forEachNode((node: any) => allData.push(node.data));
          const maxNo = getMaxNo(allData);
          params.data.no = maxNo + 1;
        }
        return params.data.no;
      },
    },
    {
      hide: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.itemId"),
      field: "itemId",
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.itemDescription"),
      field: "itemDescription",
      editable: false,
      singleClickEdit: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.barcode"),
      field: "barcode",
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      editable: true,
      singleClickEdit: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
      valueSetter: (params) => {
        const newValue = params.newValue;
        if (
          mode === "update" &&
          params.data.entryItemsDetailsId !== 0 &&
          params.data.entryItemsDetailsId !== undefined &&
          params.data.entryItemsDetailsId !== null
        ) {
          params.data.rowAction = "U";
        }
        params.data.barcode = newValue;
        return true;
      },
    },
    {
      headerName: t("tableHeaders.serialNumber"),
      field: "serialNumber",
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      editable: true,
      singleClickEdit: true,
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
      valueSetter: (params) => {
        const newValue = params.newValue;
        if (
          mode === "update" &&
          params.data.entryItemsDetailsId !== 0 &&
          params.data.entryItemsDetailsId !== undefined &&
          params.data.entryItemsDetailsId !== null
        ) {
          params.data.rowAction = "U";
        }
        params.data.serialNumber = newValue;
        return true;
      },
    },
    {
      headerName: t("tableHeaders.unitDescription"),
      field: "unitDescription",
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.stock"),
      field: "stock",
      sortable: false,
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "expirationDate",
      suppressMenu: true,
      headerName: t("tableHeaders.expirationDate"),
      cellEditor: "agDateCellEditor",
      editable: true,
      valueFormatter: ({ value }: { value: string | null | undefined }) => {
        return value && moment(value, "YYYY-MM-DD", true).isValid()
          ? moment(value).format("YYYY-MM-DD")
          : "";
      },
      valueParser: (params: any) => {
        const date = moment(params.newValue, "YYYY-MM-DD", true).startOf("day");
        return date.isValid() ? date.format("YYYY-MM-DD") : null;
      },
      valueSetter: (params) => {
        const newValue = params.newValue;
        const formattedDate = moment(newValue, "YYYY-MM-DD", true).format(
          "YYYY-MM-DD"
        );

        params.data.expirationDate = moment(
          formattedDate,
          "YYYY-MM-DD",
          true
        ).isValid()
          ? formattedDate
          : null;

        if (
          mode === "update" &&
          params.data.entryItemsDetailsId !== 0 &&
          params.data.entryItemsDetailsId !== undefined &&
          params.data.entryItemsDetailsId !== null
        ) {
          params.data.rowAction = "U";
        }

        return true;
      },
    },
    {
      headerName: t("tableHeaders.quantity"),
      wrapHeaderText: true,
      maxWidth: 200,
      width: 80,
      filter: false,
      field: "quantity",
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      editable: true,
      singleClickEdit: true,
      suppressMenu: true,
      onCellValueChanged: (params: any) => {
        const value = params.newValue;
        if (parseInt(value) <= 0) {
          params.data.quantity = 1;
        } else {
          params.data.quantity = value;
        }
      },
      valueSetter: (params) => {
        const purchasePrice = params?.data?.purchasePrice;
        const qty = params.newValue;
        const vat = params?.data?.vat;
        const discount = params?.data?.discount;
        const extraDiscount = params?.data?.extraDiscount;

        if (isNaN(qty) || qty <= 0 || qty === undefined || qty === null) {
          toast.error(t("quantityZero"));
          params.data.quantity = 1;
          return true;
        }

        if (
          mode === "update" &&
          params.data.entryItemsDetailsId !== 0 &&
          params.data.entryItemsDetailsId !== undefined &&
          params.data.entryItemsDetailsId !== null
        ) {
          params.data.rowAction = "U";
        }
        params.data.quantity = qty;
        setImportPurchaseDetails((prevSaleDetails: any) => {
          const updatedDetails = prevSaleDetails.map((detail: any) =>
            detail.entryItemsDetailsId === params.data.entryItemsDetailsId
              ? { ...detail, qty: params.data.quantity }
              : detail
          );
          return updatedDetails;
        });
        return true;
      },
    },
    {
      headerName: t("tableHeaders.purchasePrice"),
      wrapHeaderText: true,
      suppressMenu: true,
      // wrapHeaderText: true,
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      field: "purchasePrice",
      valueFormatter: formatPurchaseColumnValues,
      valueSetter: (params: any) => {
        const purchasePrice = parseFloat(params.newValue);

        if (purchasePrice === 0 || purchasePrice < 0) {
          toast.error(t("purchasePriceZero"));

          return false;
        }
        const vat = params?.data?.vat;
        const discount = params?.data?.discount;
        const extraDiscount = params?.data?.extraDiscount;

        if (purchasePrice !== undefined && vat !== undefined) {
          let discountedPrice = purchasePrice;

          if (discount !== undefined) {
            const discountValue = purchasePrice * (discount / 100);
            discountedPrice -= discountValue;
          }

          if (extraDiscount !== undefined) {
            const extraDiscountValue = purchasePrice * (extraDiscount / 100);
            discountedPrice -= extraDiscountValue;
          }

          if (
            mode === "update" &&
            params.data.entryItemsDetailsId !== 0 &&
            params.data.entryItemsDetailsId !== undefined &&
            params.data.entryItemsDetailsId !== null
          ) {
            params.data.rowAction = "U";
          }
          params.data.purchasePrice = parseFloat(purchasePrice.toFixed(2));
          const vatRate = params.data.vat / 100;
          const priceWithoutVat = params.data.salesPrice / (1 + vatRate);
          const profit = priceWithoutVat - params.data.purchasePrice;
          
          const margin = ((params.data.salesPrice-params.data.purchasePrice)/params.data.salesPrice)*100;
          if(!isNaN(parseFloat(margin?.toFixed(2)))){
            params.data.margin = parseFloat(margin?.toFixed(2));
          }
          setImportPurchaseDetails((prevImportPurchaseDetails: any) => {
            const updatedDetails = [...prevImportPurchaseDetails];
            updatedDetails[params.rowIndex] = {
              ...updatedDetails[params.rowIndex], 
              purchasePrice, 
              margin: !isNaN(parseFloat(margin?.toFixed(2))) ? parseFloat(margin.toFixed(2)) : params.data.margin
            };
            return updatedDetails; 
          });
          // if (params.node) {
          //   params.api.refreshCells({
          //     rowNodes: [params.node],
          //     columns: ['margin', 'purchasePrice', 'discount', 'extraDiscount'],
          //   });
          // }
        }
        return true;
      },
      filter: false,
      editable: true,
      singleClickEdit: true,
    },
    {
      headerName: t("tableHeaders.discount"),
      wrapHeaderText: true,
      field: "discount",

      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      valueSetter: (params: any) => {
        const previousDiscount = params.oldValue;
        const newValue = params.newValue;
        if (isNaN(newValue) || newValue < 0) {
          toast.error(t("discountNegative"));
          params.data.discount = previousDiscount;
          return false;
        }
        params.data.discount = parseFloat(params?.newValue?.toFixed(2));

        setImportPurchaseDetails((prevImportPurchaseDetails: any) => {
          const updatedDetails = [...prevImportPurchaseDetails];
          updatedDetails[params.rowIndex] = {
            ...updatedDetails[params.rowIndex], 
            discount: params.data.discount
          };
          return updatedDetails; 
        });
        if (params.node) {
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: ['margin', 'purchasePrice'],
          });
        }

        return true;
      },
      valueFormatter: formatPurchaseColumnValues,
      editable: true,
      filter: false,
      singleClickEdit: true,
      maxWidth: 80,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.extraDiscount"),
      wrapHeaderText: true,
      suppressMenu: true,
      maxWidth: 100,
      // wrapHeaderText: true,
      suppressKeyboardEvent: (params: any) => {
        return suppressEnter(params);
      },
      field: "extraDiscount",
      valueSetter: (params: any) => {
        const previousextraDiscount = params?.oldValue;

        const newValue = params.newValue;
        if (isNaN(newValue) || newValue < 0) {
          toast.error(t("extraDiscountNegative"));
          params.data.extraDiscount = previousextraDiscount;
          return false;
        }
        params.data.extraDiscount = parseFloat(params?.newValue?.toFixed(2));

        setImportPurchaseDetails((prevImportPurchaseDetails: any) => {
          const updatedDetails = [...prevImportPurchaseDetails];
          updatedDetails[params.rowIndex] = {
            ...updatedDetails[params.rowIndex], 
            extraDiscount: params.data.extraDiscount
          };
          return updatedDetails; 
        });

      if (params.node) {
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: ['margin', 'purchasePrice'],
        });
      }
        return true;
      },
      valueFormatter: formatPurchaseColumnValues,
      editable: true,
      filter: false,
      singleClickEdit: true,
    },
    {
      headerName: t("tableHeaders.vAT"),
      field: "vat",
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      editable: true,
      filter: false,
      singleClickEdit: true,
      valueSetter: (params: any) => {
        const vat = parseFloat(params.newValue);
        const priceWithoutVat = params.data.purchasePrice;
        const purchasePriceWithVat = priceWithoutVat * (1 + vat / 100);
        params.data.vat = parseFloat(vat?.toFixed(2));
        const purchasePrice = params?.data?.purchasePrice;
        const qty = params?.data?.quantity;

        const discount = params?.data?.discount;
        const extraDiscount = parseFloat(params?.newValue?.toFixed(2));

        if (purchasePrice !== undefined && vat !== undefined) {
          let discountedPrice = purchasePrice;

          if (discount !== undefined) {
            const discountValue = purchasePrice * (discount / 100);
            discountedPrice -= discountValue;
          }

          if (extraDiscount !== undefined) {
            const extraDiscountValue = purchasePrice * (extraDiscount / 100);
            discountedPrice -= extraDiscountValue;
          }

          const purchaseSumWithVat = (
            discountedPrice *
            (1 + vat / 100) *
            (qty !== undefined ? qty : 1)
          ).toFixed(2);
        }
        if (
          mode === "update" &&
          params.data.entryItemsDetailsId !== 0 &&
          params.data.entryItemsDetailsId !== undefined &&
          params.data.entryItemsDetailsId !== null
        ) {
          params.data.rowAction = "U";
        }
        setImportPurchaseDetails((prevImportPurchaseDetails: any) => {
          const updatedDetails = [...prevImportPurchaseDetails];
          updatedDetails[params.rowIndex] = {
            ...updatedDetails[params.rowIndex], 
            vat,
          };
          return updatedDetails; 
        });
        return true;
      },
      maxWidth: 80,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.margin"),
      field: "margin",
      width: 100,
      wrapHeaderText: true,
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      editable: false,
      valueParser: (params) => {
        if (params.data.purchasePrice === 0) {
          return 0;
        } else {
          return parseFloat(params.newValue);
        }
      },
      valueFormatter: formatPurchaseColumnValues,
      valueSetter: (params) => {
        params.data.margin = parseFloat(params.newValue);
        const purchasePrice = params.data.purchasePrice;

        if (purchasePrice === 0) {
          params.data.margin = 0;
          return true;
        }

        if (!isNaN(params.data.margin) && purchasePrice) {
          const sellingPriceWithoutVAT =
            purchasePrice * (1 + params.data.margin / 100);
          const vatRate = 1 + params.data.vat / 100;
          const sellingPrice = sellingPriceWithoutVAT * vatRate;
          params.data.salesPrice = parseFloat(sellingPrice.toFixed(2));
          if (
            mode === "update" &&
            params.data.entryItemsDetailsId !== 0 &&
            params.data.entryItemsDetailsId !== undefined &&
            params.data.entryItemsDetailsId !== null
          ) {
            params.data.rowAction = "U";
          }
          return true;
        }
        return false;
      },
      singleClickEdit: true,
      filter: false,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClassRules: {
        "negative-value": (params) => parseFloat(params.value) < 0,
      },
    },
    {
      headerName: t("tableHeaders.salesPrice"),
      field: "salesPrice",
      maxWidth: 100,
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params);
      },
      // wrapHeaderText: true,
      editable: true,
      suppressMenu: true,
      valueParser: (params: any) => parseFloat(params.newValue),
      valueFormatter: formatPurchaseColumnValues,
      valueSetter: (params: any) => {
        const purchasePrice = params.data.purchasePrice;

        const salesPrice = parseFloat(params.newValue);

        if (salesPrice === 0 || salesPrice < 0) {
          toast.error(t("salesPriceZero"));
          return false;
        }

        params.data.salesPrice = parseFloat(params.newValue);
       

        if (!isNaN(params.data.salesPrice) && purchasePrice) {
          
          const vatRate = params.data.vat / 100;
          const priceWithoutVat = params.data.salesPrice / (1 + vatRate);
          const profit = priceWithoutVat - params.data.purchasePrice;
          const margin = ((params.data.salesPrice-params.data.purchasePrice)/params.data.salesPrice)*100;
          params.data.margin = parseFloat(margin?.toFixed(2));

          if (searchItemInputRef.current) {
            searchItemInputRef.current.focus();
          }

          if (
            mode === "update" &&
            params.data.entryItemsDetailsId !== 0 &&
            params.data.entryItemsDetailsId !== undefined &&
            params.data.entryItemsDetailsId !== null
          ) {
            params.data.rowAction = "U";
          }

          params.data.salesPrice = salesPrice;
          setImportPurchaseDetails((prevImportPurchaseDetails: any) => {
            const updatedDetails = [...prevImportPurchaseDetails];
            updatedDetails[params.rowIndex] = {
              ...updatedDetails[params.rowIndex], 
              salesPrice, 
              margin,
            };
            return updatedDetails; 
          });

          return true;
        }
        return false;
      },
      filter: false,
      hide: !purchaseConfig?.status,
      singleClickEdit: true,
    },
    {
      headerName: t("tableHeaders.transport"),
      field: "transport",
      sortable: false,
      filter: false,
      suppressMovable: true,
      editable: true,
      maxWidth: 100,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.transportCorrection"),
      field: "transportCorrection",
      sortable: false,
      filter: false,
      suppressMovable: true,
      editable: true,
      maxWidth: 170,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.excise"),
      field: "excise",
      sortable: false,
      filter: false,
      suppressMovable: true,
      editable: true,
      maxWidth: 80,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.otherExpenses"),
      field: "otherExpenses",
      sortable: false,
      filter: false,
      suppressMovable: true,
      editable: true,
      maxWidth: 150,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.options"),
      suppressMenu: true,
      maxWidth: 100,
      cellRenderer: (params: any) => {
        return (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => deleteImportPurchaseDetail(params.rowIndex)}
            style={{ color: "#007FFF" }}
          ></Button>
        );
      },
    },
  ];
  return columnDefs;
};
