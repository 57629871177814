import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { getSubscriptionPaymentsAsync } from "../../../redux/slices/dashboardSubscriptionsSlice";
import { AppDispatch, RootState } from "../../../redux";
import { ColDef } from "ag-grid-community";
import { Typography } from "antd";
import { formatDateAsDDMMYYYY, formatDateTimeAsDDMMYYYY } from "../../../helperMethods/formatDate";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { useNavigate } from "react-router-dom";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuAdminOptionEnum } from "../../../enums/MenuAdminOptionEnum";

const { Title, Text, Link } = Typography;

const SubscriptionPayments: React.FC = () => {
  const subscriptionPayments = useSelector(
    (state: RootState) => state.dashboardSubscriptions.paymentSubscription
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("AdminDashboard.SubscriptionPayments");
  useEffect(() => {
    dispatch(getSubscriptionPaymentsAsync());
  }, [dispatch]);

  const columnDefs: ColDef[] = [
    { headerName: "Payment ID", field: "paymentId" },
    { headerName: "Payment Amount", field: "paymentAmount" },
    { headerName: "Currency Name", field: "currencyName" },
    {
      headerName: "Payment Method Description",
      field: "paymentMethodDescription",
    },
    {
      headerName: "Payment Date",
      field: "paymentDate",
      cellRenderer: (params: any) => {
        return <Text>{formatDateAsDDMMYYYY(params.data.paymentDate)}</Text>;
      },
    },
    { headerName: "Company Name", field: "companyName" },
    { headerName: "VAT Number", field: "vatNumber" },
    {
      headerName: "Entry Date",
      field: "entryDate",
      cellRenderer: (params: any) => {
        return <Text>{formatDateTimeAsDDMMYYYY(params.data.entryDate)}</Text>;
      },
    },
  ];

  return (
    <>
        <ListHeaderComponent
          title={t("titles.createServer")}
          createRecord={() => {
            navigate("/dashboard/subscription-payment/register");
          }}
          menuItemsDisabled={false}
          createTitle={t("create")}
          createAccessEnum={MenuAdminOptionEnum.AdminSubscriptionPaymentsCreate}
        />
        <AgGridTableComponent
          defaultColDef={columnDefs}
          isLoading={false}
          rowData={subscriptionPayments}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={20}
        />
    </>
  );
};

export default SubscriptionPayments;
