
import { Button, Checkbox, Modal, Select, Spin, Typography } from "antd";
import useAppTranslation from "../../customHooks/useAppTranslation";



interface Props{
    filterState:any;
    translationPrefix:string;
}
const { Title, Text, Link } = Typography;
const { Option } = Select;

export default function FilterModal({filterState,translationPrefix}:Props){
    const t = useAppTranslation(translationPrefix);
    return (
        <Modal
          width={400}
          open={filterState?.filterModal}
          onCancel={() => filterState?.setFilterModal(false)}
          footer={[
            <Button key="clear" onClick={filterState?.clearAllCheckboxes}>
                 {t('clearAll')}
            </Button>,
            <Button key="cancel" onClick={() => filterState?.setFilterModal(false)}>
              {t('cancel')}
            </Button>,
            <Button key="apply" type="primary" onClick={filterState?.handleFilterModalSubmit}>
              {t('apply')}
            </Button>,
          ]}
        >
          <div style={{ minHeight:"200px" , display: 'flex', alignItems: 'center',justifyContent:"center",marginTop:"10px"}}>
          <Spin spinning={filterState?.loadingFilterColumns}>
          <div style={{ maxHeight: '600px' ,overflowY: 'auto',display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
            {filterState && filterState?.filterColumns?.map((column:any) => (
              <div key={column.name}>
                <Checkbox
                  checked={filterState?.selectedFilterColumns?.includes(column.name)}
                  onChange={() => filterState?.handleCheckboxChange(column.name)}
                >
                  {column.isClassifier ? column.name : t(`FilterColumns.${column.name}`)}
                </Checkbox>
              </div>
            ))}
          </div>
          </Spin>
          </div>
        </Modal>
      );
    };