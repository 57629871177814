import axios, { AxiosInstance } from "axios";
import { ApiResponse } from "../models/ApiResponse";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { navigateWithRefresh } from "../helperMethods/navigateWithRefresh";
import { t } from "i18next";
import { CONFIG } from "../config";

const apiService: AxiosInstance = axios.create({
  baseURL: CONFIG?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL,
  timeout: 20000000,
});

apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    // if (response.data) {
    //   const apiResponse: ApiResponse = response.data;
    //   if (apiResponse.Message) {
    //     toast.success(apiResponse.Message);
    //   }
    // }

    return response;
  },
  (error) => {
    const response: ApiResponse = error?.response?.data;

    if (error.response.status === 401) {
      localStorage.removeItem("jwt");
      toast.error("Sesioni ka skaduar, ju lutem kyçuni përseri.");
      navigateWithRefresh("/login");
      // Redirect to login page or handle logout logic as needed
      // Example: window.location.href = '/login';
    }

    if (error.response.status === 400) {
      if (error?.response?.data?.Data?.message) {
        toast.error(t(error?.response?.data?.Data?.message));
      } else {
        toast.error(t(error?.response?.data?.Data?.toString()));
      }
    }

    if (response && response.Error) {
      if (response) toast.error(t(response.Error));
    }
    throw error;
  }
);
export default apiService;
