import { useState, useEffect, useRef } from "react";
import { Typography, Button, Modal, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import {
  deleteBrandAsync,
  getBrandsAsync,
  getBrandsSuccess,
} from "../../../redux/slices/brandSlice";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { setEditingCell } from "../../../helperMethods/setEditingCell";
import { Brand } from "../../../models/clientDashboard/Brand/Brand";
import apiService from "../../../extensions/api";
import { CreateBrandDto } from "../../../models/clientDashboard/Brand/CreateBrandDto";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import AgGridCustomContextMenu from "../../CustomComponents/AgGridCustomContextMenu";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import WithPermission from "../../Authorization/WithPermission";

const { Title, Text } = Typography;

const Brands = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  // const brands = useSelector((state: RootState) => state.brand.brands);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.BrandsList");
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact | null>(null);
  const gridApi = useRef<any>(null);
  const gridColumnApi = useRef<any>(null);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [menuBrandsDisabled, setMenuBrandsDisabled] = useState(false);

  const getBrands = async () => {
    const response = await dispatch(getBrandsAsync());
    if (response.type === "Brands/getBrands/fulfilled") {
      setBrands(response.payload as Brand[]);
    }
  };

  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
  };

  useEffect(() => {
    getBrands();
  }, []);

  const handleAddBrand = () => {
    const newBrand = {
      brandId: 0,
      description: "",
    };
    setBrands([...brands, newBrand]);
    setTimeout(() => {
      setEditingCell(gridRef, "description");
    }, 0);
  };

  const deleteBrand = async (brandID: number) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      const result = await dispatch(deleteBrandAsync(brandID));
      if (result.type === "Brands/deleteBrand/fulfilled") {
        setBrands((prevValues) => prevValues.filter((brand) => brand.brandId !== brandID));
      }
    }
  };

  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: t("deleteBrand"),
        okText: t("Yes"),
        cancelText: t("No"),
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const [colDefs, setColDefs] = useState([
    {
      field: "brandId",
      suppressMenu: true,
      headerName: "ID",
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params.data.brandId}</Text>;
      },
    },
    {
      field: "description",
      suppressMenu: true,
      headerName: t("tableHeaders.description"),
      editable: true,
      onCellValueChanged: (params: any) => {
        try {
          const value = params.newValue;
          const data = params.data;
          if (value === null || (value?.trim() === "" && data.brandId !== 0)) {
            setBrands((prevValues) => {
              const brands = [...prevValues];
              const brand = { ...brands.find((brand) => brand.brandId === data.brandId) };
              if (brand) {
                brand.description = data.description;
              }
              return brands;
            });
            return;
          }
          else if (value === null || (value.trim() === "" && data.brandId === 0)) {
            setBrands((prevValues) => prevValues.filter((brand) => brand.brandId !== 0));
            return;
          }
          params?.node?.setDataValue("description", value);
          params.api.stopEditing(true);
          if (params.data.brandId === 0) {
            const newBrand: CreateBrandDto = {
              description: value,
            };
            apiService
              .post("/api/Brands", newBrand)
              .then(async (response) => {
                try {
                  dispatch(getBrandsSuccess([]));
                  getBrands();
                } catch (error) {
                  console.error("Error fetching brands:", error);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            const updatedBrand: Brand = {
              brandId: params.data.brandId,
              description: value,
            };
            apiService
              .put("/api/Brands", updatedBrand)
              .then((response) => {
                dispatch(getBrandsSuccess([]));
                getBrands();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } catch (e) {
          console.log(e);
        }
      },

    },
    {
      field: "options",
      suppressMenu: true,
      headerName: t("tableHeaders.options"),
      cellRenderer: (params: any) => (
        <WithPermission
        menuOptionId={MenuOptionEnum.BrandsCreate}
        children={
          <Button
        type="text"
          icon={<DeleteOutlined />}
          onClick={() => deleteBrand(params.data.brandId)}
          style={{ color: "#007FFF" }}
        ></Button>
}
        />
      ),
      width: 100,
    },
  ]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);



  const handleRowClick = (event: any) => {
    setSelectedRow(event.data);
  };

  const divRef = useRef<HTMLDivElement>(null);

  const handleRightClick = (event: MouseEvent) => {
    console.log('Event Target:', event.target);
    console.log('Div Ref Current:', divRef.current);

    if (divRef.current && divRef.current.contains(event.target as Node)) {
      event.preventDefault();
      console.log('Inside grid div');
      setShowContextMenu(true);
      setContextMenuPosition({ x: event.clientX, y: event.clientY });
    } else {
      console.log('Outside grid div');
      setShowContextMenu(false);
    }
  };


  const handleClick = () => {
    setShowContextMenu(false);
  };

  // useEffect(() => {
  //   // Add right-click and left-click listeners
  //   document.addEventListener('contextmenu', handleRightClick);
  //   document.addEventListener('mousedown', handleClick);

  //   // Cleanup listeners on component unmount
  //   return () => {
  //     document.removeEventListener('contextmenu', handleRightClick);
  //     document.removeEventListener('mousedown', handleClick);
  //   };
  // }, []);

  const handleCopyRow = () => {
    if (selectedRow) {
      // Logic for copying the row data
      console.log("Copied row:", selectedRow);
    }
  };

  const mutableObject = _.cloneDeep(brands)


  return (

    <>
      <ListHeaderComponent
        title={t("titles.brands")}
        createRecord={handleAddBrand}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.BrandsCreate}
      />

      <AgGridTableComponent
        gridRef={gridRef}
        isLoading={isLoading}
        onGridReady={onGridReady}
        divRef={divRef}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        rowData={mutableObject}
        pagination={true}
        paginationPageSize={20}
        suppressCellFocus={false}
        stopEditingWhenCellsLoseFocus={true}
        enableCellTextSelection={true}
        onRowClicked={handleRowClick}
        onCellEditingStopped={(params: any) => {
          const data = params.data;
          if (data?.brandId === 0 && data?.description?.trim() === "") {
            setBrands((prevValues) => prevValues.filter((brand) => brand.brandId !== 0));
          }
          else if (data?.brandId !== 0 && data?.description?.trim() === "") {
            setBrands((prevValues) => {
              const brands = [...prevValues];
              const brand = brands.find((brand) => brand.brandId === data.brandId);
              if (brand) {
                brand.description = data.description;
              }
              return brands;
            });
          }
        }}
      />
      {/* <AgGridCustomContextMenu
        gridRef={divRef}
        contextMenuPosition={contextMenuPosition}
        showContextMenu={showContextMenu}
        onCopy={handleCopyRow}
      /> */}
    </>
  );
};

export default Brands;
