export enum ItemTypeEnum
{
    itemforPurchase = 1,
    processedItem=2,
    rawMaterial=3,
    manufacturedItem=4,
    semiFinishedItem=5,
    basicMaterials=6,
    service=7,
    packaging=8,
    transferOfProcessedItem=9,
    salesVoucher=10,
    prepayment=11,
    

}