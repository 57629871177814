export function mapDataToInterface<TSource extends object, TTarget extends object>(
  sourceList: TSource[],
  targetPrototype: TTarget
): TTarget[] {
  return sourceList.map(sourceItem => {
    const newItem = { ...targetPrototype };
    
    for (const targetKey of Object.keys(newItem) as Array<keyof TTarget>) {
      if (targetKey in sourceItem) {
        (newItem as any)[targetKey] = (sourceItem as any)[targetKey];
      }
    }
    
    return newItem;
  });
}
