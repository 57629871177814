import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetAllConfigurationsState } from "../../models/states/GetAllConfigurationsState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { GetAllConfigurationsDto } from "../../models/Configurations/GetAllConfigurationsDto";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { GetAllConfigurationsValuesDto } from "../../models/Configurations/GetAllConfigurationValuesDto";
import { decryptData, encryptData } from "../../helperMethods/decryptToken";
import { ConfigurationFromToken } from "../../models/Configurations/ConfigurationFromToken";

const initialState: GetAllConfigurationsState = {
  configurations: [],
  configurationValues: [],
  configurationValuesFromToken: [],
  error: null,
};

const GetAllConfigurationsSlice = createSlice({
  name: "configurations",
  initialState,
  reducers: {
    getAllConfigurationsStart(state: GetAllConfigurationsState) {
      setLoading(true);
      state.error = null;
    },
    getAllConfigurationsSuccess(
      state: GetAllConfigurationsState,
      action: PayloadAction<GetAllConfigurationsDto[]>
    ) {
      setLoading(false);
      state.configurations = action.payload;
    },
    getConfigurationsValuesSuccess(
      state: GetAllConfigurationsState,
      action: PayloadAction<GetAllConfigurationsValuesDto[]>
    ) {
      setLoading(false);
      state.configurationValues = action.payload;
    },
    getAllConfigurationsFailure(
      state: GetAllConfigurationsState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
    setConfigurationValuesFromToken(
      state: GetAllConfigurationsState,
      action: PayloadAction<GetAllConfigurationsDto[]>
    ) {
      state.configurationValuesFromToken = action.payload;
    },
    addNewAllConfigurationsToState(
      state: GetAllConfigurationsState,
      action: PayloadAction<GetAllConfigurationsDto>
    ) {
      state.configurations.push(action.payload);
    },
  },
});

export const getAllConfigurationsAsync = createAsyncThunk(
  "AllConfigurations/getAllConfigurations",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      // dispatch(getAllConfigurationsStart());
      const response = await apiService.get<
        ApiResponse<GetAllConfigurationsDto[]>
      >("/api/Configuration/allConfigurations");
      dispatch(getAllConfigurationsSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAllConfigurationsFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getAllConfigurationValues = createAsyncThunk(
  "ConfigurationValues/getConfigurationValues",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      // dispatch(getAllConfigurationsStart());
      const response = await apiService.get<
        ApiResponse<GetAllConfigurationsValuesDto[]>
      >("/api/Configuration/getConfigurationValues");
      dispatch(getConfigurationsValuesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAllConfigurationsFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getConfigurationToken = createAsyncThunk(
  "ConfigurationValues/getConfigurationToken",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await apiService.get<
        ApiResponse<ConfigurationFromToken>
      >("/api/Configuration/getConfigurationToken");
      const encryptedData = encryptData(response.data.Data);
      localStorage.setItem("configToken", encryptedData);
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAllConfigurationsFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const decryptConfigurationToken = createAsyncThunk(
  "ConfigurationValues/decryptConfigurationToken",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const root = getState() as RootState;
      const loginToken = localStorage.getItem("token");
      if(loginToken){
      const token = localStorage.getItem("configToken");

        let decryptedData: ConfigurationFromToken | null = null;
        let lastFetch = 0;

        if (token) {
          decryptedData = decryptData(token);

          lastFetch = decryptedData?.lastFetch
            ? new Date(decryptedData.lastFetch).getTime()
            : 0;
        }

        const currentTime = Date.now();
        const fiveMinutes = 5 * 60 * 1000;

        if (!decryptedData || currentTime - lastFetch > fiveMinutes) {
          const response = await apiService.get<
            ApiResponse<ConfigurationFromToken>
          >("/api/Configuration/getConfigurationToken");
          if (response.status === 200) {
            const newData = encryptData(response.data.Data);
            localStorage.setItem("configToken", newData);
            decryptedData = response.data.Data;
          }
        }

        dispatch(
          setConfigurationValuesFromToken(decryptedData?.configurations || [])
        );
      }
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAllConfigurationsFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const {
  getAllConfigurationsStart,
  getAllConfigurationsSuccess,
  getAllConfigurationsFailure,
  getConfigurationsValuesSuccess,
  setConfigurationValuesFromToken,
} = GetAllConfigurationsSlice.actions;

export default GetAllConfigurationsSlice.reducer;
