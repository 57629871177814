import { Button, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import { useState } from "react";
import AntSelectValue from "../../../../models/helper/AntSelectValueModel";
import { toast } from "react-toastify";
import RegisterUserDto from "../../../../models/dashboard/Users/RegisterUserDto";
import apiService from "../../../../extensions/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setLoadingState,
} from "../../../../redux/slices/loadingSlice";
import { RootState } from "../../../../redux";

interface AddUserModalProps {
  visible: boolean;
  companyId: number;
  onCancel: () => void;
  onOk: () => void;
  userRoles: AntSelectValue[];
}
const CreateUserModal: React.FC<AddUserModalProps> = ({
  visible,
  companyId,
  onCancel,
  onOk,
  userRoles,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const data: RegisterUserDto = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          username: values.username,
          adminRoleId: values.adminRoleId,
          companyId: companyId,
        };

        dispatch(setLoading(true));
        await apiService
          .post("/api/dashboard/auth/register", data)
          .then((response) => {
            toast.success("Përdoruesi u krijua me sukses!");
            form.resetFields();
            onOk();
          })
          .catch((error) => {})
          .finally(() => {
            dispatch(setLoading(false));
          });
      })
      .catch((error) => {
        toast.error("Plotëso fushat e kërkuara");
      });
  };

  return (
    <>
      <Spin tip="Loading..." spinning={loadScreen}>
        <Modal
          title="Krijo përdorues"
          open={visible}
          onCancel={onCancel}
          onOk={handleOk}
          footer={[
            loadScreen ? (
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>
            ) : null,
            <Button key="submit" type="primary" onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="firstName"
                  label="Emri"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="lastName"
                  label="Mbiemri"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="username"
                  label="Përdoruesi"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="password"
                  label="Fjalëkalimi"
                  rules={[{ required: true }]}
                >
                  <Input.Password placeholder="" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="adminRoleId"
                  label="Roli perdoruesit"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder="Zgjedh rolin"
                    options={userRoles}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    </>
  );
};

export default CreateUserModal;
