import React, { useEffect, useState } from "react";
import { Button, Form, Spin, Typography, Select, Input } from "antd";
import { LockOutlined, UserOutlined, SunOutlined, MoonOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { authenticateUser, getMenuRolesForAccessForAdmin } from "../../redux/slices/userSlice";
import LoginData from "../../models/dashboard/Users/LoginData";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { stringIsEmptyOrNull } from "../../utils/utilMethods";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/kubit-lite-logo.png";
import { changeTheme } from "../../redux/slices/appearanceSlice";
import { getConfigurationToken } from "../../redux/slices/getAllConfigurationsSlice";
import { getMenuRolesForAccess } from "../../redux/slices/userSlice";
import { DecodedUserAndBranches } from "../../models/dashboard/Users/DecodedUserAndBranches";

const { Text, Title } = Typography;
const { Option } = Select;

const Login: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [theme, setTheme] = useState<string>(localStorage.getItem("theme") || "light");
  const [language, setLanguage] = useState<string>("en");
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(changeTheme(theme));
  }, [theme, dispatch]);

  const logThemeChange = (newTheme: string) => {
    console.log(`Theme changed to: ${newTheme}`);
  };

  const onFinish = async (values: any) => {
    const request: LoginData = {
      username: values.username,
      password: values.password,
    };

    if (stringIsEmptyOrNull(request.username)) {
      toast.error(language === "en" ? "Please enter your username." : "Ju lutem shkruani emrin tuaj të përdoruesit.");
      return;
    }

    if (stringIsEmptyOrNull(request.password)) {
      toast.error(language === "en" ? "Please enter your password." : "Ju lutem shkruani fjalëkalimin tuaj.");
      return;
    }

    const resultAction = await dispatch(authenticateUser(request));
    if (typeof resultAction === "object") {
      const userData = resultAction.payload as DecodedUserAndBranches;
      if (userData?.user?.roleId === "1" || userData?.user?.roleId === "2" || userData?.user?.roleId === "3") {
        await dispatch(getConfigurationToken());
        await dispatch(getMenuRolesForAccessForAdmin(parseInt(userData.user.roleId ?? "0")));
        navigate("/dashboard");
      } else if (userData?.user?.roleId === "4") {
        await dispatch(getConfigurationToken());
        await dispatch(getMenuRolesForAccess(parseInt(userData?.user?.userRoleId ?? "0")));
        navigate("/");
      }
    } else {
      toast.error(language === "en" ? "An error occurred while logging in." : "Ka ndodhur një gabim gjatë identifikimit.");
    }
  };

  const toggleDarkMode = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme); 
    localStorage.setItem("theme", newTheme); 
    logThemeChange(newTheme); 
  };

  const handleLanguageChange = (value: string) => {
    setLanguage(value); 
  };

  const isDarkMode = theme === "dark";

  return (
    <>
      <Spin tip="Loading..." spinning={isLoading}>
        <section
          className="login-section"
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: isDarkMode ? "#001529" : "#f0f2f5",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              zIndex: 1000,
            }}
          >
            <Select
              defaultValue="en"
              className="language-select-login"
              style={{
                marginLeft: "10px",
                width: "70px",
                fontSize: "18px",
                lineHeight: "normal",
                textAlign: "center",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                color: isDarkMode ? "#ffffff" : "#000000",
                backgroundColor: isDarkMode ? "#001529" : "#ffffff",
              }}
              onChange={handleLanguageChange}
              dropdownStyle={{
                minWidth: "80px",
                padding: "0",
                borderRadius: "4px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Option value="en">EN</Option>
              <Option value="al">AL</Option>
            </Select>
            <div
              onClick={toggleDarkMode}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                transition: "transform 0.3s",
              }}
              className="theme-toggle"
            >
              {isDarkMode ? (
                <SunOutlined style={{ fontSize: "24px", color: "#ffffff" }} />
              ) : (
                <MoonOutlined style={{ fontSize: "24px", color: "#001529" }} />
              )}
            </div>
          </div>

          <div
            className="login-s-select"
            style={{
              width: "380px",
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: isDarkMode ? "#001529" : "#ffffff",
              boxShadow: isDarkMode ? "0 4px 8px rgba(0,0,0,0.5)" : "0 4px 8px rgba(0,0,0,0.2)",
            }}
          >
            <img
              className="login-img-select"
              src={Logo}
              alt="Logo"
              width="100%"
              style={{ marginBottom: "10px" }}
            />
            <Title style={{ color: isDarkMode ? "#ffffff" : "#000000" }}>
              {language === "en" ? "Login" : "Kyçu"}
            </Title>
            <Text style={{ color: isDarkMode ? "#ffffff" : "#000000" }}>
              {language === "en"
                ? "Welcome to KuBIT Lite. Please log in to continue."
                : "Mirë se vini në KuBIT Lite. Ju lutem kyçuni për të vazhduar."}
            </Text>

            <Form
              name="normal_login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              className="login-form"
              layout="vertical"
            >
              <Form.Item name="username">
                <Input
                  className="login-us-select"
                  prefix={<UserOutlined />}
                  placeholder={language === "en" ? "Username" : "Emri i përdoruesit"}
                  style={{
                    color: isDarkMode ? "#ffffff" : "#000000",
                    backgroundColor: isDarkMode ? "#001529" : "#ffffff",
                  }}
                />
              </Form.Item>
              <Form.Item name="password">
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder={language === "en" ? "Password" : "Fjalëkalimi"}
                  style={{
                    color: isDarkMode ? "#ffffff" : "#000000",
                    backgroundColor: isDarkMode ? "#001529" : "#ffffff",
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-button-select"
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    backgroundColor: isDarkMode ? "#1890ff" : "#40a9ff",
                  }}
                >
                  {language === "en" ? "Log in" : "Kyçu"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </section>
      </Spin>
    </>
  );
};

export default Login;
