
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-material.css";


const applyTheme = (theme: string) => {
  document.body.classList.remove("darkMode", "lightMode");
  document.body.classList.add(theme === "dark" ? "darkMode" : "lightMode");
};

const initialTheme = localStorage.getItem("theme") || "light";
applyTheme(initialTheme);


store.subscribe(() => {
  const state = store.getState();
  const theme = state.appearance.theme;
  applyTheme(theme);
  localStorage.setItem("theme", theme); 
});

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
