import { Form, Spin, Row, Col } from "antd";
import { Typography } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import apiService from "../../../extensions/api";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import FormInput from "../../CustomComponents/FormInput";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import { AppDispatch, RootState } from "../../../redux";
import { CreateBrandDto } from "../../../models/clientDashboard/Brand/CreateBrandDto";
import { Formik } from "formik";
import { useState } from "react";

const { Title, Text } = Typography;

const CreateBrand = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const [initialValues, setInitialValues] = useState<CreateBrandDto>(
    {} as CreateBrandDto
  );
  const t = useAppTranslation("ClientDashboard.CreateBrand");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const onFinish = async (values: CreateBrandDto) => {
    dispatch(setLoadingState(true));

    await apiService
      .post("/api/Brands", values)
      .then((response) => {
        toast.success(t("brandCreatedSuccessfully"));
        navigate("/brands");
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Title level={3}>{t("formTitle")}</Title>
      <Text italic>{t("formSubtitle")}</Text>
      <Formik
        initialValues={initialValues}
        onSubmit={onFinish}
        enableReinitialize
      >
        {({ values, handleSubmit, submitForm }) => (
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            style={{ marginTop: 20 }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <FormInput
                  name="description"
                  label={t("descriptionInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("descriptionInputPlaceholder"),
                    },
                  ]}
                />
              </Col>
            </Row>
            <FormSubmitButton
              handleValidate={undefined}
              handleConfirm={submitForm}
              buttonText={t("createBrandButton")}
            />
          </Form>
        )}
      </Formik>
    </Spin>
  );
};

export default CreateBrand;
