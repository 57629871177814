export default function FinancesIcons() {
  return (
    <svg
      fill="#5E5E5E"
      height="20px"
      className="icon-for-sidebar"
      width="20px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M437.024,74.975C388.676,26.627,324.387,0,256,0S123.324,26.627,74.975,74.975S0,187.613,0,256 s26.627,132.676,74.975,181.025C123.324,485.373,187.612,512,256,512s132.676-26.627,181.025-74.975 C485.373,388.676,512,324.387,512,256S485.372,123.324,437.024,74.975z M425.809,425.809 c-45.353,45.353-105.659,70.33-169.809,70.33s-124.456-24.977-169.809-70.33C40.838,380.456,15.861,320.151,15.861,256 s24.977-124.457,70.33-169.809C131.544,40.839,191.849,15.861,256,15.861s124.456,24.977,169.809,70.33 c45.353,45.353,70.33,105.659,70.33,169.809S471.162,380.456,425.809,425.809z"></path>{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M399.708,112.291C361.331,73.916,310.295,52.78,255.999,52.78s-105.332,21.134-143.708,59.511S52.78,201.704,52.78,256 s21.134,105.332,59.511,143.708c38.376,38.375,89.412,59.511,143.708,59.511s105.332-21.134,143.709-59.511 c38.375-38.375,59.511-89.412,59.511-143.708C459.219,201.704,438.084,150.668,399.708,112.291z M256,443.357 C152.69,443.357,68.643,359.31,68.643,256S152.69,68.641,256,68.641S443.359,152.689,443.359,256 C443.357,359.31,359.31,443.357,256,443.357z"></path>{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M124.047,226.957c-16.014,0-29.043,13.028-29.043,29.043s13.028,29.043,29.043,29.043S153.09,272.014,153.09,256 C153.091,239.986,140.062,226.957,124.047,226.957z M124.047,269.182c-7.269,0-13.182-5.913-13.182-13.182 s5.913-13.182,13.182-13.182s13.182,5.913,13.182,13.182C137.23,263.269,131.317,269.182,124.047,269.182z"></path>{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M387.952,226.957c-16.014,0-29.043,13.028-29.043,29.043s13.028,29.043,29.043,29.043s29.043-13.028,29.043-29.043 C416.994,239.986,403.966,226.957,387.952,226.957z M387.952,269.182c-7.269,0-13.182-5.913-13.182-13.182 s5.913-13.182,13.182-13.182c7.269,0,13.182,5.913,13.182,13.182C401.133,263.269,395.22,269.182,387.952,269.182z"></path>{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <polygon points="263.931,211.782 335.5,140.212 324.285,128.997 256,197.282 187.714,128.997 176.499,140.212 248.069,211.782 248.069,258.626 213.775,258.626 213.775,274.487 248.069,274.487 248.069,300.85 213.775,300.85 213.775,316.711 248.069,316.711 248.069,382.673 263.931,382.673 263.931,316.711 298.224,316.711 298.224,300.85 263.931,300.85 263.931,274.487 298.224,274.487 298.224,258.626 263.931,258.626 "></polygon>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}
