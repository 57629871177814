import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InternalSaleState } from "../../models/states/InternalSaleState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { GetInternalSalesDto } from "../../models/clientDashboard/EntriesModule/InternalSale/GetInternalSalesDto";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { GenericDocumentDto } from "../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateInternalSaleDto } from "../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDto";
import { CreateOrUpdateInternalSaleDetailDto } from "../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDetailDto";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";


const initialState: InternalSaleState = {
  internalSales: [],
  error: null,
};

const internalSaleSlice = createSlice({
  name: "internalSales",
  initialState,
  reducers: {
    getInternalSaleStart(state: InternalSaleState) {
      setLoading(true);
      state.error = null;
    },
    getInternalSaleSuccess(
      state: InternalSaleState,
      action: PayloadAction<
       GetInternalSalesDto[]>
      
    ) {
      setLoading(false);
      state.internalSales = action.payload;
    },
    getInternalSaleFailure(
      state: InternalSaleState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});

export const getInternalSaleFilterColumnsAsync = createAsyncThunk(
  "InternalSales/getInternalSaleFilterColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<
        ApiResponse<
          GenericDocumentDto<
            CreateOrUpdateInternalSaleDto,
            CreateOrUpdateInternalSaleDetailDto
          >[]
        >
      >("api/Filter/internalSales");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getInternalSaleFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);
export const getInternalSalesColumnsAsync = createAsyncThunk(
  "InternalSales/getInternalSalesColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<
        ApiResponse<PropertyDto[]>>("api/Filter/internal-sale");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getInternalSaleFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);
export const getInternalSalesDataById = createAsyncThunk(
  "InternalSales/getInternalSalesDataById",
  async (
    data: { internalSaleId: string; branchID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<
        ApiResponse<
          GenericDocumentDto<
            CreateOrUpdateInternalSaleDto,
            CreateOrUpdateInternalSaleDetailDto
          >
        >
      >(
        `/api/InternalSales/getInternalSaleById?internalSaleId=${data.internalSaleId}&branchId=${data.branchID}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const validateInternalSalesAsync = createAsyncThunk(
  "InternalSales/validateInternalSales",
  async (
    data: { internalSaleId: string; branchID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/InternalSales/validate?internalSaleId=${data.internalSaleId}&branchId=${data.branchID}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const deleteInternalSalesAsync = createAsyncThunk(
  "InternalSales/deleteInternalSale",
  async (internalSaleId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.delete<ApiResponse<void>>(
        `/api/InternalSales/${internalSaleId}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getInternalSalesWithFilters = createAsyncThunk(
  "InternalSales/getInternalSalesWithFilters",
  async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<GetInternalSalesDto[]>>
        ("/api/InternalSales/getDataWithFilter", data);
      dispatch(getInternalSaleSuccess(response.data.Data));
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(setLoadingState(false));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const invalidateInternalSalesAsync = createAsyncThunk(
    "InternalSales/invalidateInternalSales",
    async (data: { internalSaleId: string, branchID: number }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/InternalSales/unvalidate?internalSaleId=${data.internalSaleId}&branchId=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const {
  getInternalSaleStart,
  getInternalSaleSuccess,
  getInternalSaleFailure,
} = internalSaleSlice.actions;

export default internalSaleSlice.reducer;
