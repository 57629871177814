import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClientTypesState } from "../../models/dashboard/Company/ClientTypesState";
import { setLoading } from "./loadingSlice";
import { ClientType } from "../../models/dashboard/Company/ClientType";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";

const initialState: ClientTypesState = {
  clientTypes: [],
  error: null,
};

const clientTypesSlice = createSlice({
  name: "clientTypes",
  initialState,
  reducers: {
    getClientTypesStart(state: ClientTypesState) {
      setLoading(true);
      state.error = null;
    },
    getClientTypesSuccess(
      state: ClientTypesState,
      action: PayloadAction<ClientType[]>
    ) {
      setLoading(false);
      state.clientTypes = action.payload;
    },
    getClientTypesFailure(
      state: ClientTypesState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});

export const getClientTypesAsync = createAsyncThunk(
  "dashboard/Company/client-types",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { clientTypes } = (getState() as { clientTypes: ClientTypesState })
      .clientTypes;

    if (clientTypes.length > 0) {
      return clientTypes;
    }

    try {
      dispatch(getClientTypesStart());
      const response = await apiService.get<ApiResponse<ClientType[]>>(
        "api/dashboard/Company/client-types"
      );
      dispatch(getClientTypesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getClientTypesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const {
  getClientTypesStart,
  getClientTypesSuccess,
  getClientTypesFailure,
} = clientTypesSlice.actions;

export default clientTypesSlice.reducer;
