import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountingConfiguration } from "../../models/clientDashboard/AccountinConfigurations/AccountingConfiguration";
import { CreateOrUpdateAccountingConfigurationDto } from "../../models/clientDashboard/AccountinConfigurations/CreateOrUpdateAccountingConfigurationDto";
import { AccountingConfigurationState } from "../../models/states/AccountingConfigurationState";
import { setLoading, setLoadingState } from "./loadingSlice";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { GetAccountingGroupDto } from "../../models/clientDashboard/AccountingGroup/GetAccountingGroupDto";
import { GetAccountingValuationTypeDto } from "../../models/AccountingValuationType/GetAccountingValuationTypeDto";
import { GetFinancialAccountsDto } from "../../models/clientDashboard/AccountinConfigurations/GetFinancialAccountsDto";

const initialState: AccountingConfigurationState = {
  accountingConfigurations: [],
  error: null,
};

const accountingConfigurationSlice = createSlice({
  name: "accountingConfiguration",
  initialState,
  reducers: {
    getAccountingConfigurationStart(state: AccountingConfigurationState) {
      setLoading(true);
      state.error = null;
    },
    getAccountingConfigurationSuccess(
      state: AccountingConfigurationState,
      action: PayloadAction<CreateOrUpdateAccountingConfigurationDto[]>
    ) {
      setLoading(false);
      state.accountingConfigurations = action.payload;
    },
    getAccountingConfigurationFailure(
      state: AccountingConfigurationState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});

export const getAccountingConfigurationAsync = createAsyncThunk(
  "accountingConfiguration/getAccountingConfiguration",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getAccountingConfigurationStart());
      const response = await apiService.get<
        ApiResponse<CreateOrUpdateAccountingConfigurationDto[]>
      >("api/AccountingConfiguration");
      dispatch(getAccountingConfigurationSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAccountingConfigurationFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getDocumentTypeAsync = createAsyncThunk(
  "DocumentType/getDocumentType",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getAccountingConfigurationStart());
      const response = await apiService.get<
        ApiResponse<GetAccountingGroupDto[]>
      >("api/AccountingGroup");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAccountingConfigurationFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getAccountingValuationTypeAsync = createAsyncThunk(
  "AccountingValuationType/getAccountingValuationType",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getAccountingConfigurationStart());
      const response = await apiService.get<
        ApiResponse<GetAccountingValuationTypeDto[]>
      >("api/AccountingValuationType");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAccountingConfigurationFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getFinancialAccountsAsync = createAsyncThunk(
  "FinancialAccounting/getFinancialAccountsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getAccountingConfigurationStart());
      const response = await apiService.get<
        ApiResponse<GetFinancialAccountsDto[]>
      >("api/FinancialAccounts");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAccountingConfigurationFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getFinancialAccountsForFinancialFinInvoicesAsync = createAsyncThunk(
  "FinancialAccounting/getFinancialAccountsForFinancialFinInvoicesAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getAccountingConfigurationStart());
      const response = await apiService.get<
        ApiResponse<GetFinancialAccountsDto[]>
      >("api/FinancialAccounts/forFinInvoices");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAccountingConfigurationFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  getAccountingConfigurationStart,
  getAccountingConfigurationSuccess,
  getAccountingConfigurationFailure,
} = accountingConfigurationSlice.actions;

export default accountingConfigurationSlice.reducer;
