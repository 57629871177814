export enum DocumentTypeEnum
{
    /// <summary>
    /// Hyrja e mallit
    /// </summary>
    goodsEntry = 1,

    /// <summary>
    /// Korrektim i Hyrjes se Mallit
    /// </summary>
    goodsEntryCorrection = 2,

    /// <summary>
    /// Kthimi Mallit Furnitorit
    /// </summary>
    goodsReturnToSupplier = 3,

    /// <summary>
    /// Korrektim i Kthimit te Mallit
    /// </summary>
    goodsReturnCorrection = 4,

    /// <summary>
    /// Blerjet nga fermeret 1%
    /// </summary>
    purchasesFromFarmers1Prc = 5,

    /// <summary>
    /// Blerje Vendore me TVSH jo te zbritshme
    /// </summary>
    localPurchasesWithNonDeductibleVAT = 6,

    /// <summary>
    /// Kthim i mallit me TVSH jo te zbritshme
    /// </summary>
    goodsReturnWithNonDeductibleVAT = 7,

    /// <summary>
    /// Blerjet nga fermeret 3%
    /// </summary>
    purchasesFromFarmers3Prc = 8,

    /// <summary>
    /// Hyrja e mallit import
    /// </summary>
    importedGoodsEntry = 9,

    /// <summary>
    /// Korrektim i hyrjes import
    /// </summary>
    importedGoodsEntryCorrection = 10,

    /// <summary>
    /// Hyrja pa TVSH
    /// </summary>
    vATFreeEntry = 11,

    /// <summary>
    /// Korrektim i hyrjes pa TVSH
    /// </summary>
    vATFreeEntryCorrection = 12,

    /// <summary>
    /// Kthim i Hyrjes se Mallit Import
    /// </summary>
    importedGoodsReturn = 13,

    /// <summary>
    /// Shitje
    /// </summary>
    sales = 14,

    /// <summary>
    /// Shitje pa TVSH
    /// </summary>
    saleWoVat = 15,

    /// <summary>
    /// Kthim malli
    /// </summary>
    goodsReturn = 16,

    /// <summary>
    /// Eksport malli
    /// </summary>
    goodsExport = 17,

    /// <summary>
    /// Korrigjim i shitjes
    /// </summary>
    salesCorrection = 18,

    /// <summary>
    /// Korrigjim i shitjes pa tvsh
    /// </summary>
    salesCorrectionWoVat = 19,

    /// <summary>
    /// Korrigjim i kthimit mallit
    /// </summary>
    goodsReturnsCorrection = 20,
    
    /// <summary>
    /// Njoftim permanent
    /// </summary>
    permanentNotice = 21,

    /// <summary>
    /// Njoftim promocional
    /// </summary>
    promotionnalAnnouncement = 22,

    /// <summary>
    /// Transfer i mallit
    /// </summary>
    goodsTransfer = 23,

    /// <summary>
    /// Pranim i transferit
    /// </summary>
    acceptanceTransfer = 24,

    /// <summary>
    /// Korrigjim i eksport i mallit
    /// </summary>
    correctionOfGoodsExport = 25,

    /// <summary>
    /// Porosi Automatike
    /// </summary>
    automaticOrder = 28,

    /// <summary>
/// Porosi Nga Kampanja
/// </summary>
campaignOrder = 29,

/// <summary>
/// Porosi Shtese
/// </summary>
additionalOrder = 30,

/// <summary>
/// Fature Interne
/// </summary>
internalInvoice = 31,

/// <summary>
/// Porosi Shitje
/// </summary>
salesOrder = 32,

/// <summary>
/// Perdorim Intern
/// </summary>
internalUse = 33,

/// <summary>
/// Demtim Malli
/// </summary>
productDamage = 34,

/// <summary>
/// (31) Blerjet e Tatueshme Vendore
/// </summary>
taxableLocalPurchases = 35,

/// <summary>
/// (27) Blerjet e Tatueshme Import
/// </summary>
taxableImportPurchases = 36,

/// <summary>
/// (23) Blerjet dhe Importet pa TVSH
/// </summary>
purchasesWithoutVAT = 37,

/// <summary>
/// (25) Blerjet dhe Importet me TVSH jo të zbritshme
/// </summary>
nonDeductibleVATPurchases = 38,

/// <summary>
/// Shpenzimet e pa zbritshme
/// </summary>
nonDeductibleExpenses = 39,

/// <summary>
/// 32,28,29 Blerjet e shërbimeve nga jashtë NGK
/// </summary>
foreignServicePurchasesNGK = 40,

/// <summary>
/// Vetë Faturim
/// </summary>
selfInvoicing = 41,

/// <summary>
/// Stornim i vetëfaturimit
/// </summary>
cancellationOfSelfInvoicing = 42,

/// <summary>
/// Auto ngarkesë
/// </summary>
autoCharge = 43,

/// <summary>
/// Stornim i auto ngarkesës
/// </summary>
cancellationOfAutoCharge = 44,

/// <summary>
/// Korrektim (23) Blerjet dhe Importet pa TVSH
/// </summary>
correctionOfPurchasesWithoutVAT = 45,

/// <summary>
/// Korrektim (31) Blerjet e tatueshme vendore
/// </summary>
correctionOfTaxableLocalPurchases = 46,

}