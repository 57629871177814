import { Button, Popconfirm, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getPriceFilterColumnsAsync, getPriceWithFilters } from "../../../../redux/slices/priceSlice";
import { FilterData } from "../../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../../customHooks/useFilter";
import { renderFilterInputs } from "../../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../../CustomComponents/FilterModal";
import { formatDate, formatDateWithTime } from "../../../../helperMethods/DateFormat";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
  CopyOutlined
} from "@ant-design/icons";
import { Prices } from "../../../../models/clientDashboard/ItemPrices/Prices/Prices";
import { exportDataToCsv } from "../../../../models/helper/exportDataToCsv";
import { AgGridReact } from "ag-grid-react";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import { usePermission } from "../../../../customHooks/usePermission";
import WithPermission from "../../../Authorization/WithPermission";


const { Title, Text, Link } = Typography;
export default function Price() {
  const price = useSelector((state: RootState) => state.price.price);
  const t = useAppTranslation("ClientDashboard.Price");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef<Prices>[]>([
    {
      field: "pricesId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.pricesId}</Text>;
      },
    },

    { field: "branchName", headerName: t("tableHeaders.branchName"), suppressMenu: true },
    { field: "documentType", headerName: t("tableHeaders.documentType"), suppressMenu: true },
    // { field: "entityId", headerName: t("tableHeaders.entityId"), suppressMenu: true },
    { field: "comment", headerName: t("tableHeaders.comment"), suppressMenu: true },
    { field: "startDate", headerName: t("tableHeaders.startDate"), valueFormatter: formatDate, suppressMenu: true },
    { field: "endDate", headerName: t("tableHeaders.endDate"), valueFormatter: formatDate, suppressMenu: true },
    { field: "documentNo", headerName: t("tableHeaders.documentNo"), suppressMenu: true },
    { field: "validated", headerName: t("tableHeaders.validated"), suppressMenu: true },
    // { field: "notificationsDate", headerName: t("tableHeaders.notificationsDate"), valueFormatter:formatDateWithTime, suppressMenu: true},

    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.PricesCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(t("tabs.updatePrice"), `price/update/${params?.data?.pricesId}/${params.data.branchId}`, undefined);
                navigate(`/price/update/${params?.data?.pricesId}/${params.data.branchId}`);
              }}
              style={{
                color: "#007FFF",
                marginRight: 8,
              }}
            />
          }
        />
      ),
      maxWidth: 90,
      suppressMenu: true
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    // params.columnApi.autoSizeColumns(['branchName']);
    //   params.columnApi.autoSizeColumns(['documentType']);
    //   params.columnApi.autoSizeColumns(['comment']);
    //   params.columnApi.autoSizeColumns(['startDate']);
    //   params.columnApi.autoSizeColumns(['endDate']);
    //   params.columnApi.autoSizeColumns(['documentNo']);
    //   params.columnApi.autoSizeColumns(['validated']);
    //   params.columnApi.autoSizeColumns(['entryDate']);
    //   params.columnApi.autoSizeColumns(['registeredBy']); 
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);
  const getPrice = async () => {
    const result = await dispatch(getPriceWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    getPrice()
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.PricesCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(t("tabs.updatePrice"), `price/update/${event?.data?.pricesId}/${event.data.branchId}`, undefined)
      navigate(`/price/update/${event?.data?.pricesId}/${event.data.branchId}`)
    }
  };

  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;

    const result = await dispatch(getPriceWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getPriceFilterColumnsAsync());
    if (result.type === "Items/getPriceFilterColumnsAsync/fulfilled") {
      return result;
    } else {
      return null;
    }
  }
  const handleClearFilters = async () => {
    const result = await dispatch(getPriceWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  }
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewPrice = async () => {
    navigate("/price/register");
    const existingTab = await db.tabs.get("price/register");
    if (!existingTab) {
      db.addTab(t("tabs.createPrice"), "price/register", undefined);
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "pricesId",
      "branch",
      "documentType",
      "comment",
      "startDate",
      "endDate",
      "documentNo",
      "validated",
      "options",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.Price")}
        createRecord={handleCreateNewPrice}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.PricesCreate}
        exportAccessEnum={MenuOptionEnum.PricesExport}
      />
      <div
        style={{
          display: 'grid',
          gap: '16px',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))'
        }}
      >
        {filterState && filterState?.filterInputs?.map(input => (
          <div key={input.name} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>{input.isClassifier ? input.name : t(`FilterColumns.${input.name}`)}</Text>
            {renderFilterInputs({ input: input, filterState: filterState })}
          </div>
        ))}
      </div>
      {filterState && filterState?.filterInputs?.length > 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem", gap: "1.5rem" }}>
          <Button onClick={filterState.handleClearFilters}>{t("clearFilters")}</Button>
          <Button type="primary" onClick={filterState.handleFilterSubmit}>{t("filter")}</Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={price}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal filterState={filterState} translationPrefix="ClientDashboard.Price" />
    </>
  )
}