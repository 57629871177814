import { Button, Empty, Tooltip, Typography } from "antd";
import { CompanyUser } from "../../../../models/dashboard/Company/SingleCompany/CompanyDetails";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { DeleteOutlined } from "@ant-design/icons";
import CreateUserModal from "./CreateUserModal";
import AntSelectValue from "../../../../models/helper/AntSelectValueModel";
import { formatDateTimeAsDDMMYYYY } from "../../../../helperMethods/formatDate";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
const { Title, Text, Link } = Typography;

interface CompanyUsersComponentProps {
  users: CompanyUser[];
  companyId: number;
  userRoles: AntSelectValue[];
  fetchCompany: () => void;
}

const CompanyUsersComponent: React.FC<CompanyUsersComponentProps> = ({
  users,
  companyId,
  userRoles,
  fetchCompany,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const columnDefs: ColDef[] = [
    { headerName: "ID", field: "companyUserID", width: 80 },
    { headerName: "User ID", field: "userID", width: 80 },
    { headerName: "Emri i plotë", field: "userFullName" },
    { headerName: "Roli", field: "adminRoleDescription" },
    { headerName: "Email", field: "email" },
    { headerName: "Emri përdoruesit", field: "username" },
    {
      headerName: "Data regj.",
      field: "dateCreated",
      cellRenderer: (params: any) => {
        return (
          <Text type="secondary">
            {formatDateTimeAsDDMMYYYY(params.data.dateCreated)}
          </Text>
        );
      },
    },
    { headerName: "Statusi", field: "status" },
    {
      field: "edit",
      headerName: "#",
      cellRenderer: (params: any) => (
        <Tooltip placement="left" title={"Fshij"}>
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => console.log(params.data)}
            danger
          ></Button>
        </Tooltip>
      ),
      width: 70,
    },
  ];

  const openModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    fetchCompany();
  };

  return (
    <>
      <div>
        <Button
          type="dashed"
          onClick={openModal}
          style={{
            margin: 15,
          }}
        >
          Krijo përdorues të ri për këtë kompani
        </Button>
        <div
          className="ag-theme-balham"
          style={{ height: "400px", width: "100%" }}
        >
          {users.length > 0 ? (
            // <AgGridReact
            //   rowData={users}
            //   columnDefs={columnDefs}
            //   pagination={true}
            //   paginationPageSize={50}
            // />
            <AgGridTableComponent
              defaultColDef={columnDefs}
              isLoading={false}
              rowData={users}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={20}
            />
          ) : (
            <Empty description="Ska përdorues për të listuar" />
          )}
        </div>
        <CreateUserModal
          visible={isModalVisible}
          companyId={companyId}
          onCancel={handleCancel}
          onOk={handleOk}
          userRoles={userRoles}
        />
      </div>
    </>
  );
};

export default CompanyUsersComponent;
