import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/EntityType/EntityTypeState";
import { EntityState } from "../../models/clientDashboard/Entity/EntityState";
import { Entity } from "../../models/clientDashboard/Entity/Entity";
import { RootState } from "..";
import GetDataWithPagination from "../../models/GetDataWithPagination";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import { CreateAndUpdateEntityBranchDto } from "../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { EntityType } from "../../models/clientDashboard/EntityType/EntityType";

const initialState: EntityState = {
  entities: [],
  error: null,
};

const EntitySlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    getEntitiesStart(state: EntityState) {
      setLoading(true);
      state.error = null;
    },
    getEntitiesSuccess(state: EntityState, action: PayloadAction<Entity[]>) {
      setLoading(false);
      state.entities = action.payload;
    },
    getEntitiesFailure(state: EntityState, action: PayloadAction<string>) {
      setLoading(false);
      state.error = action.payload;
    },
    addNewEntityToState(state: EntityState, action: PayloadAction<Entity>) {
      setLoading(false);
      state.entities.push(action.payload);
    },
    updateEntityInState(state: EntityState, action: PayloadAction<Entity>) {
      setLoading(false);
      const updatedEntity = action.payload;
      const index = state.entities.findIndex(
        (entity) => entity.entityId === updatedEntity.entityId
      );
      if (index !== -1) {
        state.entities[index] = updatedEntity;
      }
    },
    deleteEntityFromState(state: EntityState, action: PayloadAction<number>) {
      setLoading(false);
      const entityId = action.payload;
      state.entities = state.entities.filter(
        (entity) => entity.entityId !== entityId
      );
    },
  },
});

export const getEntitiesAsync = createAsyncThunk(
  "Entities/getEntities",
  async (
    data: { pageNumber?: number; pageSize?: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const root = getState() as RootState;
    // const entities = root.entity.entities;

    // if (entities.length > 0) {
    //     return entities;
    // }

    try {
      let url = `api/Entity/getAll`;
      if (data.pageNumber && data.pageSize) {
        url += `?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
      }
      dispatch(getEntitiesStart());
      const response = await apiService.get<
        ApiResponse<GetDataWithPagination<Entity>>
      >(url);
      // toast.success("Entities were successfully retrieved!");
      dispatch(getEntitiesSuccess(response.data.Data.list));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getEntitiesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getEntitiesByTypeAsync = createAsyncThunk(
  "Entities/getEntitiesByType",
  async (isSupplier: boolean, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getEntitiesStart());
      const response = await apiService.get<ApiResponse<Entity[]>>(
        `api/Entity/getEntitiesByType?isSupplier=${isSupplier}`
      );
      // toast.success("Entities were successfully retrieved!");
      dispatch(getEntitiesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getEntitiesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getEntityDataById = createAsyncThunk(
  "Entities/getEntityDataById",
  async (entityId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<Entity>>(
        `/api/Entity/getEntityById?entityId=${entityId}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const deleteEntityAsync = createAsyncThunk(
  "Entities/deleteEntity",
  async (entityId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.delete<ApiResponse<void>>(`/api/Entity/${entityId}`);
      dispatch(deleteEntityFromState(entityId));
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getEntityFilterColumnsAsync = createAsyncThunk(
  "Entities/getEntityFilterColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<ApiResponse<PropertyDto[]>>(
        "api/Filter/entities"
      );
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getEntitiesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const getEntitiesWithFilters = createAsyncThunk(
  "Entities/getEntitiesWithFilters",
  async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<Entity[]>>(
        "/api/entity/getDataWithFilter",
        data
      );
      dispatch(getEntitiesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(setLoadingState(false));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getEntitiesBySearch = createAsyncThunk(
  "EntitiesBySearch/getEntitiesBySearch",
  async (
    data: { searchText: string; isBuyer?: boolean; isSupplier?: boolean },
    { dispatch, getState, rejectWithValue }
  ) => {
    const root = getState() as RootState;
    // const entities = root.entity.entities;

    // if (entities.length > 0) {
    //     return entities;
    // }

    if (data.searchText.length >= 3) {
      try {
        const isBuyer = data.isBuyer ?? null;
        const isSupplier = data.isSupplier ?? null;
        let url = `api/Entity/getBySearchValue?searchValue=${data.searchText}`;

        if (isBuyer !== null) {
          url += `&isBuyer=${isBuyer}`;
        }

        if (isSupplier !== null) {
          url += `&isSupplier=${isSupplier}`;
        }

        dispatch(getEntitiesStart());
        const response = await apiService.get<ApiResponse<Entity[]>>(url);
        // toast.success("Entities were successfully retrieved!");
        return response.data.Data;
      } catch (error) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        dispatch(getEntitiesFailure(errorMessage));
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  }
);

export const getBranchesByEntityId = createAsyncThunk(
  "Entities/getBranchesByEntityId",
  async (entityId: number, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getEntitiesStart());
      const response = await apiService.get<
        ApiResponse<CreateAndUpdateEntityBranchDto[]>
      >(`/api/EntityBranches/getBranchesByEntityId/${entityId}`);
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getEntitiesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getSubjectTypesAsync = createAsyncThunk(
  "SubjectTypes/getSubjectTypes",
  async (_, { dispatch, getState, rejectWithValue }) => {

      try {
          dispatch(getEntitiesStart());
          const response = await apiService.get<ApiResponse<EntityType[]>>(
              "api/Entity/entityTypes"
          );
          return response.data.Data;
      } catch (error) {
          const errorMessage =
              typeof error === "string" ? error : "An error occurred";
          dispatch(getEntitiesFailure(errorMessage));
          return rejectWithValue(errorMessage);
      } finally {
          dispatch(setLoadingState(false));
      }
  }
);

export const {
  getEntitiesStart,
  getEntitiesSuccess,
  getEntitiesFailure,
  addNewEntityToState,
  updateEntityInState,
  deleteEntityFromState,
} = EntitySlice.actions;

export default EntitySlice.reducer;
