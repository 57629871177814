import React from "react";
import { AgGridReact } from "ag-grid-react";
import {
  SubscriptionPaymentsWithTotalsDto,
} from "../../../../models/dashboard/Company/SingleCompany/CompanyDetails";
import { ColDef } from "ag-grid-community";
import {
  formatDateAsDDMMYYYY,
  formatDateTimeAsDDMMYYYY,
} from "../../../../helperMethods/formatDate";
import { Card, Col, Empty, Row, Statistic } from "antd";
import { CheckOutlined, ShrinkOutlined } from "@ant-design/icons";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";

interface CompanyPaymentsProps {
  subscriptionPaymentInfo: SubscriptionPaymentsWithTotalsDto;
}

const CompanyPayments: React.FC<CompanyPaymentsProps> = ({
  subscriptionPaymentInfo,
}) => {
  const columnDefs: ColDef[] = [
    { field: "paymentId", headerName: "Payment ID" },
    { field: "subscriptionInvoiceId", headerName: "Subscription Invoice ID" },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      cellRenderer: (params: any) => {
        return params.data.paymentDate
          ? formatDateAsDDMMYYYY(params.data.paymentDate)
          : "";
      },
    },
    { field: "paymentAmount", headerName: "Payment Amount" },
    {
      field: "entryDate",
      headerName: "Entry Date",
      cellRenderer: (params: any) => {
        return params.data.entryDate
          ? formatDateTimeAsDDMMYYYY(params.data.entryDate)
          : "";
      },
    },
  ];

  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Shuma totale e abonimeve"
              value={subscriptionPaymentInfo.totalSubscriptionAmount}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              //   prefix={<ShrinkOutlined />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Shume totale e pagesave"
              value={subscriptionPaymentInfo.totalPaidAmount}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              //   prefix={<cf1322 />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Për tu paguar"
              value={
                subscriptionPaymentInfo.totalSubscriptionAmount -
                subscriptionPaymentInfo.totalPaidAmount
              }
              precision={2}
              valueStyle={{
                color:
                  subscriptionPaymentInfo.totalSubscriptionAmount -
                    subscriptionPaymentInfo.totalPaidAmount >=
                  0
                    ? "#cf1322"
                    : "#3f8600",
              }}
              //   prefix={<ArrowDownOutlined />}
            />
          </Card>
        </Col>
      </Row>
      <div
        className="ag-theme-balham"
        style={{ height: "400px", width: "100%", marginTop: "20px" }}
      >
        {subscriptionPaymentInfo.subscriptionPayments.length > 0 ? (
          // <AgGridReact
          //   columnDefs={columnDefs}
          //   rowData={subscriptionPaymentInfo.subscriptionPayments}
          //   pagination={true}
          //   paginationPageSize={10}
          // />
          <AgGridTableComponent
          defaultColDef={columnDefs}
          isLoading={false}
          rowData={subscriptionPaymentInfo.subscriptionPayments}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={20}
        />
        ) : (
          <Empty description="Ska pagesa për të listuar" />
        )}
      </div>
    </div>
  );
};

export default CompanyPayments;
