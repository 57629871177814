import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/Custom/CustomState";
import { CustomState } from "../../models/clientDashboard/Custom/CustomState";
import { Custom } from "../../models/clientDashboard/Custom/Custom";
import { RootState } from "..";

const initialState: CustomState = {
    customs: [],
    error: null,
};

const CustomSlice = createSlice({
    name: "custom",
    initialState,
    reducers: {
        getCustomsStart(state: CustomState) {
            setLoading(true);
            state.error = null;
        },
        getCustomsSuccess(
            state: CustomState,
            action: PayloadAction<Custom[]>
        ) {
            setLoading(false);
            state.customs = action.payload;
        },
        getCustomsFailure(
            state: CustomState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        deleteCustomFromState(state: CustomState, action: PayloadAction<number>) {
            setLoading(false);
            const customId = action.payload;
            state.customs = state.customs.filter(custom => custom.customId !== customId);
        },
    }
});

export const getCustomsAsync = createAsyncThunk(
    "Customs/getCustoms",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const customs = state.custom.customs;
        if (customs.length > 0) {
            return customs;
        }

        try {
            dispatch(getCustomsStart());
            
            const response = await apiService.get<ApiResponse<Custom[]>>(
                "api/Customs"
            );
            // toast.success("Customs were successfully retrieved!");
            dispatch(getCustomsSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getCustomsFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getCustomDataById = createAsyncThunk(
    "Customs/getCustomDataById",
    async (customId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<Custom>>(
                `/api/Customs/${customId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

// export const deleteCustomAsync = createAsyncThunk(
//     "Customs/deleteCustom",
//     async (customId: number, { dispatch, rejectWithValue }) => {
//         try {
//             dispatch(setLoadingState(true));
//             await apiService.delete<ApiResponse<void>>(
//                 `/api/Customs/DeleteCustom/${customId}`
//             );
//             dispatch(deleteCustomFromState(customId));
//         } catch (error: any) {
//             const errorMessage =
//                 typeof error === "string" ? error : "An error occurred";
//             return rejectWithValue(errorMessage);
//         } finally {
//             dispatch(setLoadingState(false));
//         }
//     }
// );

export const {
    getCustomsStart,
    getCustomsSuccess,
    getCustomsFailure,
    deleteCustomFromState
} = CustomSlice.actions;

export default CustomSlice.reducer;