import { ColDef } from "ag-grid-community";
import { RefObject } from "react";
import { CreateOrUpdateFinInvoiceRegistrationAccounting } from "../../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationAccounting";
import { suppressEnter } from "../../../../helperMethods/suppressEnter";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { formatPurchaseColumnValues } from "../../../../helperMethods/formatPurchaseColumnValues";

export const getFinInvoiceRegistrationAccountingColumns = (
    removeFinInvoiceRegistrationAccounting: (index: number) => void,
    t: (text: string) => string,
    searchItemInputRef: RefObject<any>,
    mode: string
  ) => {
  
    const columnDefs: ColDef<CreateOrUpdateFinInvoiceRegistrationAccounting>[] = [
      {
        headerName: t("tableHeaders.branchId"),
        field: "branchId",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.accountId"),
        field: "accountId",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.accountDescription"),
        field: "accountDescription",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.amount"),
        field: "amount",
        suppressKeyboardEvent: (params) => {
          return suppressEnter(params);
        },
        singleClickEdit: true,
        valueFormatter:formatPurchaseColumnValues, 
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true,
        editable: false,
      },
      {
        headerName: t("tableHeaders.dk"),
        field:"dk",
        wrapHeaderText: true,
        filter: false,
        editable: false,
        singleClickEdit: true,
        suppressMenu: true,
        sortable: false,
        suppressMovable: true,
      },
      {
        headerName: t("tableHeaders.registrationDate"),
        field: "registrationDate",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true,
      },
      {
        headerName: t("tableHeaders.itemId"),
        field: "itemId",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true,
      },
      {
        headerName: t("tableHeaders.itemDescription"),
        field: "itemDescription",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true,
      },
      {
        headerName: t("tableHeaders.undiscountable"),
        field: "undiscountable",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true,
      },
      {
        headerName: t("tableHeaders.options"),
        suppressMenu: true,
        maxWidth: 100,
        cellRenderer: (params: any) => {
          return (
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => removeFinInvoiceRegistrationAccounting(params.rowIndex)}
              style={{ color: "#007FFF" }}
            ></Button>
          );
        },
      }
    ];
      
      
    return columnDefs;
  };
  