import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { CreateOrUpdateInternalSaleDto } from "../../../../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDto";
import { AppDispatch, RootState } from "../../../../../redux";
import { Classifier } from "../../../../../models/clientDashboard/Classifier/Classifier";
import FormInput from "../../../../CustomComponents/FormInput";
import FormSelect from "../../../../CustomComponents/FormSelect";
import { Col, Row } from "antd";
import FormSelectWithSearch from "../../../../CustomComponents/FormSelectWithSearch";
import { fetchClassifiers, getClassifierDetailsBySearch } from "../../../../../redux/slices/classifierSlice";
import { ClassifierDetail } from "../../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";


interface Props {
  values: CreateOrUpdateInternalSaleDto;
  setFieldValue: (name: string, value: any) => void;
}
export default function ClassifierFieldsComponent({ values, setFieldValue }: Props) {
  const t = useAppTranslation("ClientDashboard.CreateInternalSale.ClassifiersComponent");
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const dispatch = useDispatch<AppDispatch>();
  const filteredClassifiers = classifiers.filter(
    (classifier) =>
      classifier.description !== "K1" &&
      classifier.description !== "K2" &&
      classifier.description !== "K3" &&
      classifier.description !== "K4" &&
      classifier.status === true
  );
  function getNestedValue(obj: any, path: any) {
    return path.split('.').reduce((acc: any, part: any) => acc && acc[part], obj);
  }

  const groupSize = Math.ceil(filteredClassifiers.length / 3);
  const groups = [
    filteredClassifiers.slice(0, groupSize),
    filteredClassifiers.slice(groupSize, groupSize * 2),
    filteredClassifiers.slice(groupSize * 2)
  ];

  const getClassifierValueByNameForSelect = (name: string, item: any) => {
    const categoryValue = getNestedValue(item, name);
    if (typeof categoryValue === 'number') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue.classifierDetailId || undefined;
    } else {
      return undefined;
    }
  };
  const getClassifierValueByNameForInput = (name: string, item: any) => {
    const categoryValue = getNestedValue(item, name);
    if (typeof categoryValue === 'number' || typeof categoryValue === 'string') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue.classifierDetailDescription || undefined;
    } else {
      return undefined;
    }
  };

  const getClassifierDescriptionValue = (name: string, item: any) => {
    const descriptionValue = getNestedValue(item, name);
    switch (name) {
      case "k65":
        return item?.header?.k65Description;
      case "k66":
        return item?.header?.k66Description;
      case "k67":
        return item?.header?.k67Description;
      case "k68":
        return item?.header?.k68Description;
      case "k69":
        return item?.header?.k69Description;
      default:
        return "";
    }
  };

  const renderGroup = (group: Classifier[]) => {
    const inputComponents = group
      .filter(
        (classifier) =>
          classifier.description !== "K1" &&
          classifier.description !== "K2" &&
          classifier.description !== "K3" &&
          classifier.description !== "K4" &&
          classifier.status === true
      )
      .map((classifier) => {
        switch (classifier.classifierTypeDescription.toLowerCase()) {
          case "text":
            const textValue = getClassifierValueByNameForInput(`header.${classifier?.description?.toLowerCase()}`, values);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={`header.${classifier?.description?.toLowerCase()}`}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage"),
                  },
                ]}
                value={textValue}
                disabled={values.validated}
              />
            );
          case "select":
            const value = getClassifierValueByNameForSelect(`header.${classifier?.description?.toLowerCase()}`, values)
            const label = getClassifierDescriptionValue(`${classifier?.description?.toLowerCase()}`, values);
        

            return (
              <FormSelectWithSearch
                key={classifier.description}
                name={`header.${classifier?.description?.toLowerCase()}`}
                label={classifier.clientDescription || classifier.description}
                required={classifier.mandatory}
                value={value}
                disabled={values.validated}
                fetchOptions={(searchText: string) => fetchClassifiers(searchText, classifier.classifierId ?? "")}
                fetchInitialOption={() => {
                  return {
                    key: value,
                    label: label,
                    value: value,
                  };
                }}
                placeHolder={t("placeHolder")}
                onChange={(option) => {
                  setFieldValue(`header.${classifier?.description?.toLowerCase()}`, option.value);
                }}
              />
            );
          case "number":
          case "decimal number":
            const numberValue = getClassifierValueByNameForInput(`header.${classifier?.description?.toLowerCase()}`, values);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={`header.${classifier?.description?.toLowerCase()}`}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage")
                  },
                  {
                    pattern: {
                      value: new RegExp(classifier.regex ?? ""),
                      message: t("numberPatternMessage"),
                    },
                  },
                ]}
                type="number"
                value={numberValue}
                disabled={values.validated}
              />
            );
          case "email":
            const emailValue = getClassifierValueByNameForInput(`header.${classifier?.description?.toLowerCase()}`, values);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={`header.${classifier?.description?.toLowerCase()}`}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage"),
                  },
                  {
                    pattern: {
                      value: new RegExp(classifier.regex ?? ""),
                      message: t("emailPatternMessage"),
                    },
                  },
                ]}
                value={emailValue}
                type="email"
                disabled={values.validated}
              />
            );
          default:
            return null;
        }
      });

    return inputComponents;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={8}>{renderGroup(groups[0])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[1])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[2])}</Col>
    </Row>
  );
}
