import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../customHooks/useAuth";
import { Spin } from 'antd'; // Import Spin from Ant Design for loading spinner

const RedirectUserIfLoggedIn: React.FC = () => {
  const navigate = useNavigate();
  const user = useAuth();

  // New state for loading
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
      if (user.roleId === "1" || user.roleId === "2" || user.roleId === "3") {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    } else {
      setLoading(false);
    }
  }, [user, navigate]);

  // If loading, return a spinner
  if (loading) {
    return <Spin />;
  }

  return <Outlet />;
};

export default RedirectUserIfLoggedIn;