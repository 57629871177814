import { Button, Modal, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useEffect, useState } from "react";
import { deleteCurrencyAsync } from "../../../../redux/slices/currencySlice";
import { useNavigate } from "react-router-dom";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getCashRegistersAsync } from "../../../../redux/slices/cashRegisterSlice";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import WithPermission from "../../../Authorization/WithPermission";
import { usePermission } from "../../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;

const CashRegisters = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const cashRegister = useSelector((state: RootState) => state.cashRegister.cashRegisters);
  const t = useAppTranslation("ClientDashboard.CashRegisters");
  const dispatch = useDispatch<AppDispatch>();
  const [menuCashRegistersDisabled, setMenuCashRegistersDisabled] = useState(false);
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const deleteCashRegister = async (cashRegisterId: number) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      dispatch(deleteCurrencyAsync(cashRegisterId));
    }
  };

  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "cashRegisterId",
      headerName: "ID",
      suppressMenu: true,
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.cashRegisterId}</Text>;
      },
    },
    { field: "cashRegisterNo", headerName: t("tableHeaders.cashRegisterNo"), suppressMenu: true },
    { field: "hostName", headerName: t("tableHeaders.hostName"), suppressMenu: true },
    { field: "printerType", headerName: t("tableHeaders.printerType"), suppressMenu: true },
    { field: "port", headerName: t("tableHeaders.port"), suppressMenu: true },
    { field: "printsDirectly", headerName: t("tableHeaders.printsDirectly"), suppressMenu: true },
    { field: "fiscalizationCode", headerName: t("tableHeaders.FiscalizationCode"), suppressMenu: true },
    { field: "macAddress", headerName: t("tableHeaders.macAddress"), suppressMenu: true },
    { field: "status", headerName: t("tableHeaders.status"), suppressMenu: true },
    {
      headerName: t("tableHeaders.options"),
      suppressMenu: true,
      width: 90,
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.CashRegisterCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updatecashRegister"),
                  `cashRegister/update/${params?.data?.cashRegisterId}`,
                  undefined
                );
                navigate(`/cashRegister/update/${params?.data?.cashRegisterId}`);
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
    },
  ]);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['cashRegisterNo']);
    params.columnApi.autoSizeColumns(['hostName']);
    params.columnApi.autoSizeColumns(['entryDate']);
    params.columnApi.autoSizeColumns(['printerType']);
    params.columnApi.autoSizeColumns(['port']);
    params.columnApi.autoSizeColumns(['printsDirectly']);
    params.columnApi.autoSizeColumns(['fiscalizationCode']);
    params.columnApi.autoSizeColumns(['macAddress']);
    params.columnApi.autoSizeColumns(['status']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getCashRegister = async () => {
    // if(user?.branchId){
    const result = await dispatch(getCashRegistersAsync());
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
    //}
  };

  useEffect(() => {
    getCashRegister();

  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.CashRegisterCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateCashRegister"),
        `cashRegister/update/${event.data.cashRegisterId}`,
        undefined
      );
      navigate(`/cashRegister/update/${event.data.cashRegisterId}`);
    }
  };

  const handleCreateNewCashRegister = () => {
    navigate("/cashRegister/register");
    db.addTab(t("tabs.createCashRegister"), "cashRegister/register", undefined);
  };

  return (
    <>
      <ListHeaderComponent
        title={t("titles.cashRegister")}
        createRecord={handleCreateNewCashRegister}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.CashRegisterCreate}
      />
      <AgGridTableComponent
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={cashRegister}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
    </>
  );
};

export default CashRegisters;
