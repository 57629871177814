import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/Brand/BrandState";
import { BrandState } from "../../models/clientDashboard/Brand/BrandState";
import { Brand } from "../../models/clientDashboard/Brand/Brand";
import { RootState } from "..";

const initialState: BrandState = {
    brands: [],
    error: null,
};

const BrandSlice = createSlice({
    name: "brand",
    initialState,
    reducers: {
        getBrandsStart(state: BrandState) {
            setLoading(true);
            state.error = null;
        },
        getBrandsSuccess(
            state: BrandState,
            action: PayloadAction<Brand[]>
        ) {
            setLoading(false);
            state.brands = action.payload;
        },
        getBrandsFailure(
            state: BrandState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
       
        deleteBrandFromState(state: BrandState, action: PayloadAction<number>) {
            setLoading(false);
            const brandId = action.payload;
            state.brands = state.brands.filter(brand => brand.brandId !== brandId);
        },
        addNewBrandToState(state: BrandState, action: PayloadAction<Brand>) {
            state.brands.push(action.payload);
        }
    }
});

export const getBrandsAsync = createAsyncThunk(
    "Brands/getBrands",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const brands = state.brand.brands;

        if (brands.length > 0) {
            return brands;
        }

        try {
            dispatch(getBrandsStart());
            const response = await apiService.get<ApiResponse<Brand[]>>(
                "api/Brands"
            );
            // toast.success("Brands were successfully retrieved!");
            dispatch(getBrandsSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getBrandsFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getBrandDataById = createAsyncThunk(
    "Brands/getBrandDataById",
    async (brandId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<Brand>>(
                `/api/Brands/${brandId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteBrandAsync = createAsyncThunk(
    "Brands/deleteBrand",
    async (brandId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/Brands?brandId=${brandId}`
            );
            dispatch(deleteBrandFromState(brandId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getBrandsStart,
    getBrandsSuccess,
    getBrandsFailure,
    deleteBrandFromState,
    addNewBrandToState
} = BrandSlice.actions;

export default BrandSlice.reducer;