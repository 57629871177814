import { Button, Tabs, Typography } from "antd";
import { AppDispatch, RootState } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import { Classifier } from "../../../models/clientDashboard/Classifier/Classifier";
import { EditOutlined } from "@ant-design/icons";
import {
  getClassifiersAsync,
  getClassifiersByTypeAsync,
} from "../../../redux/slices/classifierSlice";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { db } from "../../../indexDB/clientSideDatabase";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import { usePermission } from "../../../customHooks/usePermission";
import WithPermission from "../../Authorization/WithPermission";

const { Title, Text, Link } = Typography;

const Classifiers = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const t = useAppTranslation("ClientDashboard.ClassifiersList");
  const navigate = useNavigate();

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.ClassifiersCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateClassifier"),
        `classifier/update/${event.data.classifierId}`,
        undefined
      );
      navigate(`/classifier/update/${event.data.classifierId}`);
    }
  };
  const [colDefs, setColDefs] = useState<ColDef<Classifier>[]>([
    {
      field: "classifierId",
      headerName: "ID",
      suppressMenu: true,
      width: 80,
      cellRenderer: (params: any) => {
        return (
          <Text className="ag-grid-id-column">{params.data.classifierId}</Text>
        );
      },
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      suppressMenu: true,
    },
    {
      field: "clientDescription",
      headerName: t("tableHeaders.clientDescription"),
      suppressMenu: true,
    },
    {
      field: "mandatory",
      headerName: t("tableHeaders.mandatory"),
      suppressMenu: true,
    },
    {
      field: "status",
      headerName: t("tableHeaders.status"),
      suppressMenu: true,
    },
    {
      field: "classifierTypeDescription",
      headerName: t("tableHeaders.classifierTypeDescription"),
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.options"),
      suppressMenu: true,
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.ClassifiersCreate}
          children={
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() =>
                navigate(`/classifier/update/${params.data.classifierId}`)
              }
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 100,
    },
  ]);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["mandatory"]);
    params.columnApi.autoSizeColumns(["status"]);
    params.columnApi.autoSizeColumns(["classifierTypeDescription"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getClassifiersAsync());
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: t("tabs.products"),
            key: "1",
            children: (
              <AgGridTableComponent
                isLoading={isLoading}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                rowData={classifiers.filter(
                  (classifier) => classifier.type === "Products"
                )}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
                onRowDoubleClicked={handleRowDoubleClick}
              />
            ),
          },
          {
            label: t("tabs.entities"),
            key: "2",
            children: (
              <AgGridTableComponent
                isLoading={isLoading}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                rowData={classifiers.filter(
                  (classifier) => classifier.type === "Entities"
                )}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
                onRowDoubleClicked={handleRowDoubleClick}
              />
            ),
          },
          {
            label: t("tabs.EntryItems"),
            key: "3",
            children: (
              <AgGridTableComponent
                isLoading={isLoading}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                rowData={classifiers.filter(
                  (classifier) => classifier.type === "EntryItems"
                )}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
                onRowDoubleClicked={handleRowDoubleClick}
              />
            ),
          },
          {
            label: t("tabs.EntityBranches"),
            key: "4",
            children: (
              <AgGridTableComponent
                isLoading={isLoading}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                rowData={classifiers.filter(
                  (classifier) => classifier.type === "EntityBranches"
                )}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
                onRowDoubleClicked={handleRowDoubleClick}
              />
            ),
          },
          {
            label: t("tabs.Sales"),
            key: "5",
            children: (
              <AgGridTableComponent
                isLoading={isLoading}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                rowData={classifiers.filter(
                  (classifier) => classifier.type === "Sales"
                )}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
                onRowDoubleClicked={handleRowDoubleClick}
              />
            ),
          },
          {
            label: t("tabs.InternalSales"),
            key: "6",
            children: (
              <AgGridTableComponent
                isLoading={isLoading}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                rowData={classifiers.filter(
                  (classifier) => classifier.type === "InternalSales"
                )}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
                onRowDoubleClicked={handleRowDoubleClick}
              />
            ),
          },
          {
            label: t("tabs.InternalSaleDetails"),
            key: "7",
            children: (
              <AgGridTableComponent
                isLoading={isLoading}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                rowData={classifiers.filter(
                  (classifier) => classifier.type === "InternalSaleDetails"
                )}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
                onRowDoubleClicked={handleRowDoubleClick}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default Classifiers;
