export default function Unvalidate() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 18.3337H15C15.4421 18.3337 15.866 18.1581 16.1786 17.8455C16.4911 17.5329 16.6667 17.109 16.6667 16.667V5.83366L12.5 1.66699H5.00004C4.55801 1.66699 4.13409 1.84259 3.82153 2.15515C3.50897 2.46771 3.33337 2.89163 3.33337 3.33366V6.66699"
        stroke="#E00000"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6666 1.66699V5.00033C11.6666 5.44235 11.8422 5.86628 12.1548 6.17884C12.4673 6.4914 12.8913 6.66699 13.3333 6.66699H16.6666"
        stroke="#E00000"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66667 10.417L2.5 14.5837"
        stroke="#E00000"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 10.417L6.66667 14.5837"
        stroke="#E00000"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
