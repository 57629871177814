import Title from "antd/es/typography/Title";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { Button, Tooltip } from "antd";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoleDto } from "../../../../models/clientDashboard/Role/RoleDto";
import { ColDef } from "ag-grid-community";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getRolesAsync } from "../../../../redux/slices/roleSlice";
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import WithPermission from "../../../Authorization/WithPermission";
import { usePermission } from "../../../../customHooks/usePermission";

export default function Roles() {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const roles = useSelector((state: RootState) => state.role.roles);
  const t = useAppTranslation("ClientDashboard.Roles");
  const dispatch = useDispatch<AppDispatch>();
  const [menuRolesDisabled, setMenuRolesDisabled] = useState(false);

  // const deleteBranch = async (branchID: number) => {
  //   const confirmed = await showDeleteConfirmation();
  //   if (confirmed) {
  //     dispatch(deleteRoleAsync(branchID));
  //   }
  // };

  // const showDeleteConfirmation = () => {
  //   return new Promise((resolve) => {
  //     Modal.confirm({
  //       title: "Are you sure you want to delete this record?",
  //       okText: "Yes",
  //       cancelText: "No",
  //       onOk: () => resolve(true),
  //       onCancel: () => resolve(false),
  //     });
  //   });
  // };

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState<ColDef<RoleDto>[]>([
    {
      field: "roleId",
      headerName: "ID",
      width: 80,
      cellRenderer: (params: any) => {
        return params?.data?.roleId;
      },
      suppressMenu: true,
    },
    {
      field: "roleDescription",
      headerName: t("tableHeaders.roleDescription"),
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.options"),
      suppressMenu: true,
      width: 100,
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.RoleCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateRole"),
                  `role/update/${params?.data?.roleId}`,
                  undefined
                );
                navigate(`/role/update/${params?.data?.roleId}`);
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
    },
  ]);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["shortDescription"]);
    params.columnApi.autoSizeColumns(["isActive"]);
    params.columnApi.autoSizeColumns(["fiscalCode"]);
    params.columnApi.autoSizeColumns(["isMainBranch"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getRoles = async () => {
    const result = await dispatch(getRolesAsync());
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  useEffect(() => {
    getRoles();
  }, [dispatch, gridApi]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.RoleCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateRole"),
        `role/update/${event.data.roleId}`,
        undefined
      );
      navigate(`/role/update/${event.data.roleId}`);
    }
  };

  const handleCreateNewRole = () => {
    navigate("/role/register");
    db.addTab(t("tabs.createRole"), "role/register", undefined);
  };

  return (
    <>
      <ListHeaderComponent
        title={t("titles.roles")}
        createRecord={handleCreateNewRole}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.RoleCreate}
      />
      <AgGridTableComponent
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={roles}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
    </>
  );
}
