import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface AppearanceState {
  theme: string;
  language: string;
  drawerOpen: boolean;
}

const initialState: AppearanceState = {
  theme: localStorage.getItem("theme") || "light",
  language: localStorage.getItem("langCode") || "en",
  drawerOpen: true,
};

const appearanceSlice = createSlice({
  name: "appearanceSlice",
  initialState,
  reducers: {
    setTheme(state: AppearanceState, action: PayloadAction<string>) {
      state.theme = action.payload;
      localStorage.setItem("theme", action.payload);
    },
    setLanguage(state: AppearanceState, action: PayloadAction<string>) {
      state.language = action.payload;
      localStorage.setItem("langCode", action.payload);
    },
    setDrawerOpen(state: AppearanceState, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
      localStorage.setItem("drawerOpen", action.payload.toString());
    },
  },
});
export const changeTheme = createAsyncThunk(
  "appearance/changeTheme",
  async (theme:string, { dispatch, getState, rejectWithValue }) => {
      try {
        // console.log(theme);
       dispatch(setTheme(theme));
      } catch (error) {
        
          return rejectWithValue(error);
      } finally {
      }
  }
);

export const { setTheme, setLanguage, setDrawerOpen } = appearanceSlice.actions;

export default appearanceSlice.reducer;
