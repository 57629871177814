import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrencyState } from "../../models/clientDashboard/Currencies/CurrencyState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { Currency } from "../../models/clientDashboard/Currencies/Currency";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { EFiscalCurrenciesDto } from "../../models/clientDashboard/Currencies/EfiscalCurrenciesDto";

const initialState: CurrencyState = {
    currencies: [],
    error: null,
};

const CurrencySlice = createSlice({
    name: "currencie",
    initialState,
    reducers: {
        getCurrenciesStart(state: CurrencyState) {
            setLoading(true);
            state.error = null;
        },
        getCurrenciesSuccess(
            state: CurrencyState,
            action: PayloadAction<Currency[]>
        ) {
            setLoading(false);
            state.currencies = action.payload;
        },
        getCurrenciesFailure(
            state: CurrencyState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
       
        deleteCurrencyFromState(state: CurrencyState, action: PayloadAction<number>) {
            setLoading(false);
            const currencyId = action.payload;
            state.currencies = state.currencies.filter(currencie => currencie.currencyId !== currencyId);
        },
        addNewCurrencyToState(state: CurrencyState, action: PayloadAction<Currency>) {
            state.currencies.push(action.payload);
        }
    }
});

export const getCurrenciesAsync = createAsyncThunk(
    "Currencies/getCurrencies",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const currencies = state.currency.currencies;

        if (currencies.length > 0) {
            return currencies;
        }

        try {
            dispatch(getCurrenciesStart());
            const response = await apiService.get<ApiResponse<Currency[]>>(
                "api/Currencies"
            );
            // toast.success("Brands were successfully retrieved!");
            dispatch(getCurrenciesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getCurrenciesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getCurrencyDataById = createAsyncThunk(
    "Currencies/getCurrencieDataById",
    async (currencyId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<Currency>>(
                `/api/Currencies/byId?currencyId=${currencyId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getEFiscalCurrencies = createAsyncThunk(
    "EFiscalCurrencies/getEFiscalCurrencies",
    async (_, { dispatch, getState, rejectWithValue }) => {
      try {
        dispatch(getCurrenciesStart());
        const response = await apiService.get<
          ApiResponse<EFiscalCurrenciesDto[]>
        >("/api/Currencies/EFiscalCurrencies");
        return response.data.Data;
      } catch (error) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        dispatch(getCurrenciesFailure(errorMessage));
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  );

export const deleteCurrencyAsync = createAsyncThunk(
    "Currencies/deleteCurrencie",
    async (currencyId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/Currencies?currencyId=${currencyId}`
            );
            dispatch(deleteCurrencyFromState(currencyId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getCurrenciesStart,
    getCurrenciesSuccess,
    getCurrenciesFailure,
    deleteCurrencyFromState,
    addNewCurrencyToState
} = CurrencySlice.actions;

export default CurrencySlice.reducer;