import { CellKeyDownEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";

export function focusOnAgGridEditingCell(gridRef:RefObject<AgGridReact | null>,colKey:string='qty'){
    if (gridRef.current) {
      gridRef.current.api.refreshCells({force:true});
      
      setTimeout(() => {
        const lastIndex = gridRef.current?.props.rowData?.length?(gridRef.current?.props.rowData?.length -1):0;
      gridRef.current?.api.startEditingCell({rowIndex:lastIndex,colKey:colKey,rowPinned:null});
    },100);
  }
}