import React, { useState } from "react";

interface Props {
  children: any;
  label: string;
  value: any;
  type?: string;
  required?: boolean;
}

const FloatLabel = ({ children, label, value, type, required }: Props) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value || value?.toString() === "0") || type === "date" || type === "file" ? "label label-float" : "label";

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      {
        <label className={labelClass}>
          {label}
          {required && <span className="required-sign">{" "}*</span>}

        </label>
      }
    </div>
  );
};

export default FloatLabel;
