import React, { useState } from "react";
import { Upload, Button } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CropPhotoModal from "./cropPhotoModal";
import useAppTranslation from "../../customHooks/useAppTranslation";

interface Props {

  logoBase64: string;
  setLogoBase64: (value: string) => void;
}

export default function UploadPhoto({
  logoBase64,
  setLogoBase64
}:Props) {

  const t = useAppTranslation("AdminDashboard.uploadPhoto");
  const [openCropModal, setOpenCropModal] = useState(false);
  

  const handleLogoChange = (file: File) => {
    const reader = new FileReader();
  
    reader.onloadend = () => {
      const base64String = reader.result as string;
  
      const image = new Image();
      image.src = base64String;
  
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        canvas.width = 400;
        canvas.height = 320;
  
        ctx?.drawImage(image, 0, 0, 400, 320);
  
        const resizedBase64 = canvas.toDataURL("image/png");
        const resizedBase64Data = resizedBase64.split(",")[1];
        
        setLogoBase64(resizedBase64Data);
      };
    };
  
    reader.readAsDataURL(file);
    return false;
  };

  const handleDeleteLogo = () => {
    setLogoBase64("");
  };

  const openCropper = () => {
    setOpenCropModal(true);
  };


  return (
    <div>
      <Upload
        name="logo"
        listType="picture-card"
        showUploadList={false}
        beforeUpload={(file) => {
          handleLogoChange(file);
          return false;
        }}
      >
        {logoBase64 && logoBase64 !== "" ? (
          <img
            src={`data:image/png;base64,${logoBase64}`}
            alt="logo"
            style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
          />
        ) : (
          <div>
            <PlusOutlined />
            <div className="upload-logo-text" style={{ marginTop: 8 }}>{t("uploadLogo")}</div>
          </div>
        )}
      </Upload>
      {logoBase64 && logoBase64 !== "" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button type="text" danger icon={<DeleteOutlined />} onClick={handleDeleteLogo} style={{right: "285px", bottom: "30px"}}/>
          <Button type="text" danger icon={<EditOutlined />} onClick={openCropper} style={{right: "285px", bottom: "30px"}}/>
        </div>
      )}
      <CropPhotoModal openCropModal={openCropModal} setOpenCropModal={setOpenCropModal} logoBase64={logoBase64} setLogoBase64={setLogoBase64}/>
    </div>

  );
};

