import { Form, Spin, Row, Col } from "antd";
import { Typography } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import apiService from "../../../extensions/api";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import FormInput from "../../CustomComponents/FormInput";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import { AppDispatch, RootState } from "../../../redux";
import { CreateCustomDto } from "../../../models/clientDashboard/Custom/CreateCustomDto";
import { Formik } from "formik";
import { useState } from "react";

const { Title, Text } = Typography;

const CreateCustom = () => {
    const [form] = Form.useForm();
    const loadScreen = useSelector((state:RootState) => state.loading.isLoading);
    const [initialValues, setInitialValues] = useState<CreateCustomDto>({} as CreateCustomDto);
    const t = useAppTranslation("ClientDashboard.CreateCustom");
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const onFinish = async (values:CreateCustomDto) => {
        const custom:CreateCustomDto = {
            customId:0,
            description: values.description,
            value: values.value,
        };
                await apiService
                    .post("/api/Customs", custom)
                    .then((response) => {
                        toast.success(t("customCreatedSuccessfully"));
                        navigate("/customs");
                    })
                    .catch(() => {})
                    .finally(() => {
                        dispatch(setLoadingState(false));
                    });
    };

   
    return (
        <Spin tip="Loading..." spinning={loadScreen}>
            <Title level={3}>{t("formTitle")}</Title>
            <Text italic>
                {t("formSubtitle")}
            </Text>
            <Formik
              initialValues={initialValues}
              onSubmit={onFinish}
                enableReinitialize
            >
                 {({
              values,
              handleSubmit,
              submitForm
            }) => (
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                style={{ marginTop: 20 }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <FormInput
                         name="description"
                         label={t("descriptionInputLabel")}
                         rules={[
                             {
                                 required: true,
                                 message: t("descriptionInputPlaceholder"),
                             },
                         ]}
                        />
                    </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <FormInput
                          name="value"
                          label={t("valueInputLabel")}
                          rules={[
                              {
                                  required: true,
                                  message: t("valueInputPlaceholder"),
                              },
                          ]}
                          type="number"
                        />
                    </Col>
                </Row>
                <FormSubmitButton
                handleValidate={undefined}
                handleConfirm={submitForm}
                buttonText={t("createCustomButton")}
                />
               
            </Form>
        )}
            </Formik>
        </Spin>
    );
};

export default CreateCustom;