import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/EntityType/EntityTypeState";
import { ItemState } from "../../models/clientDashboard/Item/ItemState";
import { GetItemsDto } from "../../models/clientDashboard/Item/GetItemsDto";
import { RootState } from "..";
import GetDataWithPagination from "../../models/GetDataWithPagination";
import { ItemModel } from "../../models/clientDashboard/Item/ItemModel";
import { ItemDescription } from "../../models/clientDashboard/ItemDescription/ItemDescription";
import {PropertyDto} from "../../models/clientDashboard/Filter/PropertyDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import { ItemDescriptionIdDto } from "../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { GetItemDetailsDto } from "../../models/clientDashboard/Item/GetItemDetailsDto";
const initialState: ItemState = {
    items: [],
    error: null
};

const ItemSlice = createSlice({
    name: "item",
    initialState,
    reducers: {
        getItemsStart(state: ItemState) {
            setLoading(true);
            state.error = null;
        },
        getItemsSuccess(
            state: ItemState,
            action: PayloadAction<GetItemsDto[]>
        ) {
            setLoading(false);
            state.items = action.payload;
        },
        getItemsFailure(
            state: ItemState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewItemToState(state: ItemState, action: PayloadAction<GetItemsDto>) {
            setLoading(false);
            state.items.push(action.payload);
        },
        updateItemInState(state: ItemState, action: PayloadAction<GetItemsDto>) {
            setLoading(false);
            const updatedItem = action.payload;
            const index = state.items.findIndex(item=> item.itemId === updatedItem.itemId);
            if (index !== -1) {
                state.items[index] = updatedItem;
            }
        },
        deleteItemFromState(state: ItemState, action: PayloadAction<number>) {
            setLoading(false);
            const itemId = action.payload;
            state.items = state.items.filter(item => item.itemId !== itemId);
        },
    }
});

export const getItemsAsync = createAsyncThunk(
    "Items/getItems",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        // const items = state.item.items;

        // if (items.length > 0) {
        //     return items;
        // }

        try {
            let url  = `api/Items/getDataWithPagination`;
            // if(data.pageNumber && data.pageSize){
            //     url += `?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
            // }
            const response = await apiService.get<ApiResponse<GetItemsDto[]>>(
                url
            );
            dispatch(getItemsSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getItemsFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
        }
    }
);

export const getItemFilterColumnsAsync = createAsyncThunk(
    "Items/getItemFilterColumnsAsync",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        // const items = state.item.items;

        // if (items.length > 0) {
        //     return items;
        // }
        try {
            const response = await apiService.get<ApiResponse<PropertyDto[]>>('api/Filter/items');
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getItemsFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);


export const getItemDataById = createAsyncThunk(
    "Items/getItemDataById",
    async (itemId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<ItemModel>>(
                `/api/Items/getItemById?id=${itemId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteItemAsync = createAsyncThunk(
    "Items/deleteItem",
    async (itemId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/Items/${itemId}`
            );
            dispatch(deleteItemFromState(itemId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const getItemsWithFilters = createAsyncThunk(
    "Items/getItemsWithFilters",
    async (data:FilterData[],{dispatch,getState,rejectWithValue}) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.post<ApiResponse<GetItemsDto[]>>(
                "/api/items/getDataWithFilter",data
            );
            dispatch(getItemsSuccess(response.data.Data));
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(setLoadingState(false));
            return rejectWithValue(errorMessage);
        }
        finally {
            dispatch(setLoadingState(false));
        }
    }
)
export const getItemsBySearchValue = createAsyncThunk(
    'Items/getItemsBySearchValue',
    async(data:{searchValue: string,supplierId?:number}, {dispatch, rejectWithValue}) => {
        try{
            // dispatch(setLoadingState(true));
            let url = `/api/items/getBySearchValue?searchValue=${data.searchValue}`;

            if(data.supplierId){
                url += `&supplierId=${data.supplierId}`;
            }
            const response = await apiService.get<ApiResponse<ItemDescriptionIdDto[]>>(
                url
            );
            return response.data.Data;
        }catch(error:any){

        }finally{
            // dispatch(setLoadingState(false));
        }
    }
)
export const getItemsBySearchValueForCompositeItem = createAsyncThunk(
    'Items/getItemsBySearchValueForCompositeItem',
    async(data:{searchValue: string,supplierId?:number}, {dispatch, rejectWithValue}) => {
        try{
            // dispatch(setLoadingState(true));
            let url = `/api/items/getBySearchValueForCompositeItem?searchValue=${data.searchValue}`;

            if(data.supplierId){
                url += `&supplierId=${data.supplierId}`;
            }
            const response = await apiService.get<ApiResponse<ItemDescriptionIdDto[]>>(
                url
            );
            return response.data.Data;
        }catch(error:any){

        }finally{
            // dispatch(setLoadingState(false));
        }
    }
)
export const getItemDetailsForLocalPurchase = createAsyncThunk(
    'Items/getItemDetailsForLocalPurchase',
    async (data : {itemID: number, branchID: number}, {dispatch, rejectWithValue}) => {
        try{
            let url = `/api/Items/GetItemDetails?itemId=${data.itemID}`;

            if(data.branchID){
                url += `&branchId=${data.branchID}`
            }
            const response = await apiService.get<ApiResponse<GetItemDetailsDto>>(url);
            return response.data.Data;
        }catch(error:any){
            return rejectWithValue(error);
        }
    }
)
export const getItemDetailsForImportPurchase = createAsyncThunk(
    'Items/getItemDetailsForImportPurchase',
    async (itemID: number, {dispatch, rejectWithValue}) => {
        try{
            const response = await apiService.get<ApiResponse<GetItemDetailsDto>>(
                `/api/ImportPurchase/GetItemDetails?itemId=${itemID}`
            );
            return response.data.Data;
        }catch(error:any){
            return rejectWithValue(error);
        }
    }
)
export const {
    getItemsStart,
    getItemsSuccess,
    getItemsFailure,
    addNewItemToState,
    updateItemInState,
    deleteItemFromState
} = ItemSlice.actions;

export default ItemSlice.reducer;