import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Popconfirm, Checkbox, Switch } from "antd";
import { Company } from "../../../../models/dashboard/Company/SingleCompany/Company";
import { UpdateCompanyDto } from "../../../../models/dashboard/Company/UpdateCompany";
import apiService from "../../../../extensions/api";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import { Formik } from "formik";
import FormInput from "../../../CustomComponents/FormInput";
import FormSelect from "../../../CustomComponents/FormSelect";
import UploadPhoto from "../../../CustomComponents/uploadPhoto";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import useAppTranslation from "../../../../customHooks/useAppTranslation";

interface CompanyDetailsProps {
  company: Company;
  clientTypes: { label: string; value: number }[];
  companyID: number;
  logoBase64: string;
  setLogoBase64: (value: string) => void;
}

const CompanyDetailsComponent: React.FC<CompanyDetailsProps> = ({
  company,
  clientTypes,
  companyID,
  logoBase64,
  setLogoBase64
}) => {
  const t = useAppTranslation("AdminDashboard.CompanyDetailsComponent");
  const dispatch = useDispatch();

  useEffect(() => {
    // Any necessary effect logic can go here
  }, [company, dispatch]);

  const onFinish = async (values: Company) => {
    const data: UpdateCompanyDto = {
      bussinesNumber: values.bussinesNumber,
      clientTypeID: values.clientTypeID,
      companyID: companyID,
      email: values.email,
      phone: values.phone,
      companyName: values.companyName,
      status: Boolean(values.status),
      vatNumber: values.vatNumber,
      base64Logo: logoBase64,
    };

    dispatch(setLoading(true));

    try {
      await apiService.put("/api/dashboard/company", data);
      toast.success(t("companyUpdatedSuccessfuly"));
    } catch (error) {
      toast.error(t("updateFailed"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Formik
      initialValues={company}
      onSubmit={onFinish}
      enableReinitialize
    >
      {({ submitForm, values, setFieldValue }) => (
        <Form layout="vertical" onFinish={submitForm}>
          <Col xs={24} sm={12} style={{ marginBottom: "20px" }}>
            <UploadPhoto logoBase64={logoBase64} setLogoBase64={setLogoBase64} />
          </Col>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <FormInput
                label="Emri kompanise"
                name="companyName"
                value={values.companyName}
                onChange={(event) => setFieldValue("companyName", event.target.value)}
              />
            </Col>
            <Col xs={24} sm={12}>
              <FormInput
                label="Numri biznesit"
                name="bussinesNumber"
                value={values.bussinesNumber}
                onChange={(event) => setFieldValue("bussinesNumber", event.target.value)}
              />
            </Col>
            <Col xs={24} sm={12}>
              <FormInput
                label="Numri fiskal"
                name="vatNumber"
                value={values.vatNumber}
                onChange={(event) => setFieldValue("vatNumber", event.target.value)}
              />
            </Col>
            <Col xs={24} sm={12}>
              <FormInput
                label="Email"
                name="email"
                type="email"
                value={values.email}
                onChange={(event) => setFieldValue("email", event.target.value)}
              />
            </Col>
            <Col xs={24} sm={12}>
              <FormInput
                label="Numri telefonit"
                name="phone"
                value={values.phone}
                onChange={(event) => setFieldValue("phone", event.target.value)}
              />
            </Col>
            <Col xs={24} sm={12}>
              <FormSelect
                label="Lloji biznesit"
                name="clientTypeID"
                value={values.clientTypeID}
                onChange={(value) => setFieldValue("clientTypeID", value)}
                options={clientTypes}
              />
            </Col>
            <Col xs={24} sm={12}>
              <FormInput
                label="Data regjistrimit"
                name="dateCreated"
                value={values.dateCreated}
                disabled
              />
            </Col>
            <Col xs={24} sm={12}>
              <FormInput
                label="Krijuar nga"
                name="createdBy_FullName"
                value={values.createdBy_FullName}
                disabled
              />
            </Col>
            <Col xs={24} sm={12}>
            </Col>


              <FormSwitch
                name="status"
                label={t("statusInputLabel")}
                rules={[
                  {
                    required: true,
                    message: t("statusInputPlaceholder"),
                  },

                ]}
                value={values.status as boolean}
              />
          </Row>
          <Form.Item>
            <Popconfirm
              title="A jeni te sigurt se doni te dergoni formularin?"
              okText="Po"
              cancelText="Jo"
              onConfirm={submitForm}
            >
              <Button type="primary">
                {t("Submit")}
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyDetailsComponent;
