import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GoodsTransferState } from "../../models/states/GoodsTransferState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { GoodsTransfer } from "../../models/clientDashboard/InternalMovements/GoodsTransfers/GoodsTransfer";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { GoodsTransferDto } from "../../models/clientDashboard/InternalMovements/GoodsTransfers/GoodsTransferDto";

const initialState: GoodsTransferState = {
  goodsTransfer: [],
  error: null,
};

const goodsTransferSlice = createSlice({
  name: "goodsTransfer",
  initialState,
  reducers: {
    getGoodsTransferStart(state: GoodsTransferState) {
      setLoading(true);
      state.error = null;
    },
    getGoodsTransferSuccess(
      state: GoodsTransferState,
      action: PayloadAction<GoodsTransfer[]>
    ) {
      setLoading(false);
      state.goodsTransfer = action.payload;
    },
    getGoodsTransferFailure(
      state: GoodsTransferState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
    addNewGoodsTransferToState(
      state: GoodsTransferState,
      action: PayloadAction<GoodsTransfer>
    ) {
      setLoading(false);
      state.goodsTransfer.push(action.payload);
    },
    updateGoodsTransferInState(
      state: GoodsTransferState,
      action: PayloadAction<GoodsTransfer>
    ) {
      setLoading(false);
      const updatedGoodsTransferState = action.payload;
      const index = state.goodsTransfer.findIndex(
        (GoodsTransfer) =>
          GoodsTransfer.goodsTransferId ===
          updatedGoodsTransferState.goodsTransferId
      );
      if (index !== -1) {
        state.goodsTransfer[index] = updatedGoodsTransferState;
      }
    },
    deleteGoodsTransferFromState(
      state: GoodsTransferState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      const goodsTransferId = action.payload;
      state.goodsTransfer = state.goodsTransfer.filter(
        (goodsTransfer) =>
          goodsTransfer.goodsTransferId !== goodsTransferId.toString()
      );
    },
  },
});

export const getGoodsTransferWithFilters = createAsyncThunk(
  "GoodsTransferState/getGoodsTransferStateWithFilters",
  async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<GoodsTransfer[]>>(
        "/api/GoodsTransfer/GetDataWithFilter",
        data
      );
      dispatch(getGoodsTransferSuccess(response.data.Data));
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(setLoadingState(false));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getGoodsTransferDataById = createAsyncThunk(
  "GoodsTransfer/getGoodsTransferDataById",
  async (goodsTransferId: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<GoodsTransferDto>>(
        `/api/GoodsTransfer/getGoodsTransferById?goodsTransferId=${goodsTransferId}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getGoodsTransferFilterColumnsAsync = createAsyncThunk(
  "GoodsTransfer/getGoodsTransferFilterColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<ApiResponse<PropertyDto[]>>(
        "api/Filter/goods-transfer"
      );
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getGoodsTransferFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteGoodsTransferAsync = createAsyncThunk(
  "GoodsTrans/deleteGoodsTrans",
  async (goodsTransferId: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.delete<ApiResponse<void>>(
        `/api/GoodsTransfer/${goodsTransferId}`
      );
      dispatch(deleteGoodsTransferFromState(goodsTransferId));
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const correctGoodsTransferAsync = createAsyncThunk(
  "GoodsTransfer/correctGoodsTransfer",
  async (
    data: { goodsTransferId: string; generatedId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/GoodsTransfer/Correct?originalGoodsTransferId=${data.goodsTransferId}&generatedId=${data.generatedId}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const validateGoodsTransferAsync = createAsyncThunk(
  "GoodsTransfer/validateGoodsTransfer",
  async (goodsTransferId: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/GoodsTransfer/Validate?goodsTransferId=${goodsTransferId}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const handlePrintGoodsTransferForm = createAsyncThunk(
  "GoodsTransfer/handlePrintForm",
  async ( goodsTransferId: string ,
    {dispatch, getState, rejectWithValue}
  ) => {
    try{
      dispatch(setLoadingState(true));
      const result = await apiService.get<ApiResponse<string>>(
        `/api/GoodsTransfer/get-report?goodsTransferId=${goodsTransferId}`
      );
      return result.data.Data;
    }catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  getGoodsTransferStart,
  getGoodsTransferSuccess,
  getGoodsTransferFailure,
  addNewGoodsTransferToState,
  updateGoodsTransferInState,
  deleteGoodsTransferFromState,
} = goodsTransferSlice.actions;

export default goodsTransferSlice.reducer;
