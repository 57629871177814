import { Button, Col, Input, Row } from "antd";
import { CreateAndEditLocalPurchaseDto } from "../../../../models/clientDashboard/LocalPurchase/CreateAndEditLocalPurchaseDto";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import FormSelect from "../../../CustomComponents/FormSelect";
import FormInput from "../../../CustomComponents/FormInput";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import style from "../LocalPurchaseStyle.module.css";
import { useState } from "react";
import { ErrorMessage } from "formik";
import FormItem from "antd/es/form/FormItem";
import classes from "./CustomInputStyle.module.css";
import { PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { CreateInvoiceWithRemarksReasonDto } from "../../../../models/clientDashboard/InvoiceWithRemarksReason/CreateInvoiceWithRemarksReasonDto";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import apiService from "../../../../extensions/api";
import { getInvoiceWithRemarksReasonsAsync, getInvoiceWithRemarksReasonsSuccess } from "../../../../redux/slices/invoiceWithRemarksReasonSlice";

interface Props {
  values: CreateAndEditLocalPurchaseDto;
  setFieldValue: (field: string, value: any) => void;
  uploadedFiles: File[];
}

export default function OtherFieldsComponent({ values, uploadedFiles, setFieldValue }: Props) {
  const currencies = useSelector((state: RootState) => state.currency.currencies);
  const goodsReturnReason = useSelector((state: RootState) => state.goodsReturnReason.goodsReturnReasons);
  const t = useAppTranslation(
    "ClientDashboard.CreateLocalPurchase.OtherFieldsComponent"
  );
  const dispatch = useDispatch<AppDispatch>();
  const invoiceWithRemarksReasons = useSelector((state: RootState) => state.invoiceWithRemarksReason.invoiceWithRemarksReasons)
  const [InvoiceWithRemarksReasonsToAdd, setInvoiceWithRemarksReasonsToAdd] = useState<{
    description: string,
    isActive: boolean;
  }>({
    description: "",
    isActive: true
  });

  const handleAddInvoiceWithRemarksReasonsFieldChange = (e: any) => {
    setInvoiceWithRemarksReasonsToAdd({
      ...InvoiceWithRemarksReasonsToAdd,
      [e.target.name]: e.target.value,
    });
  };

  const addNewInvoiceWithRemarksReasons = async () => {
    if (InvoiceWithRemarksReasonsToAdd.description === "") {
      toast.error("Please fill out the data first")
      return;
    }
    const newReason: CreateInvoiceWithRemarksReasonDto = {
      description: InvoiceWithRemarksReasonsToAdd.description,
      isActive: true
    }
    dispatch(setLoadingState(true));

    await apiService
      .post('/api/InvoiceWithRemarksReasons', newReason)
      .then((response) => {
        dispatch(getInvoiceWithRemarksReasonsSuccess([]))
        dispatch(getInvoiceWithRemarksReasonsAsync())
      })
      .catch(() => { })
      .finally(() => {
        setInvoiceWithRemarksReasonsToAdd({
          description: "",
          isActive: true
        });
        dispatch(setLoadingState(false));
      });
  }

  return (
    <Row gutter={[8, 8]}>
      <Col xs={12} sm={8}>
        <FormInput
          name="supplierNIVF"
          label={(t("supplierNIVFInputLabel"))}
          required={false}
          value={values.supplierNIVF}
          disabled={values.validated}
        />
        <FormInput
          name="fromDate"
          label={t("fromDateInputLabel")}
          required={false}
          value={(values.fromDate?.split("T")[0] || "")}
          type="date"
          max={values.toDate}
          disabled={values.validated}
        />
        <FormInput
          name="toDate"
          label={t("toDateInputLabel")}
          required={false}
          value={(values.toDate?.split("T")[0] || "")}
          type="date"
          min={values.fromDate}
          disabled={values.validated}
        />
        <FormInput
          name="paymentDeadline"
          type="number"
          className={style.localPurchaseInput}
          required={false}
          label={t("paymentDeadlineInputLabel")}
          value={values.paymentDeadline}
          disabled={values.validated}
        />
      </Col>
      <Col xs={12} sm={8}>
        <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          <FormSelect
            name="currencyId"
            label={t("currencyInputLabel")}
            style={{ width: "100%" }}
            required={false}
            value={values.currencyId}
            disabled={values.validated}
            options={currencies.map((currency) => (
              { label: `${currency.currencySymbol} ${currency.currencyName}`, value: currency.currencyId }))}
          />
          <FormInput
            name="exchangeRate"
            type="number"
            label={t("exchangeRateInputLabel")}
            style={{ width: "100px" }}
            required={values.currencyId !== undefined && values.currencyId !== 0 && values.currencyId !== null}
            value={values.exchangeRate}
            disabled={!values.currencyId || values.validated}
          />
        </div>
        <FormSwitch
          className="switch-for-localPurchasee"
          name="invoiceWithRemarks"
          label={t("invoiceWithRemarksInputLabel")}
          onChange={(value) => {
            if (value === false) {
              setFieldValue("invoiceWithRemarksReasonId", undefined)
            }
          }}
          value={values.invoiceWithRemarks}
          disabled={values.validated}
        />
        <FormSelect
          name="invoiceWithRemarksReasonId"
          label={t("invoiceWithRemarksReasonIdInputLabel")}
          value={values.invoiceWithRemarksReasonId}
          options={invoiceWithRemarksReasons.map((reason) => (
            { label: reason.description, value: reason.invoiceWithRemarksReasonId }))}
          required={values.invoiceWithRemarks}
          disabled={!values.invoiceWithRemarks || values.validated}
          hasAddOption={true}
          dropdownContent={() => (
            <>
              <Input
                placeholder="Please enter description"
                value={InvoiceWithRemarksReasonsToAdd.description}
                name="description"
                onChange={handleAddInvoiceWithRemarksReasonsFieldChange}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={addNewInvoiceWithRemarksReasons}
              >
                Add item
              </Button>
            </>
          )}

        />
      </Col>

      <Col xs={12} sm={8}>
        <FormInput
          name="uploadedFiles"
          label={t("uploadedFilesInputLabel")}
          required={false}
          value={values.uploadedFiles}
          type="file"
          multiple={true}
          disabled={values.validated}
        />
        <FormSelect
          name="goodsReturnReasonId"
          label={t("goodsReturnReasonIdInputLabel")}
          value={values.goodsReturnReasonId}
          disabled={values.validated}
          options={goodsReturnReason.map((goodsReturn) => (
            { label: goodsReturn.description, value: goodsReturn.goodsReturnReasonId }))}
        />
        <FormSwitch
          className="switch-for-purchase"
          name="verified"
          label={t("verifiedInputLabel")}
          value={values.verified}
          disabled={values.validated}
        />
      </Col>
    </Row>
  );
}
