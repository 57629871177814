import { useSelector } from "react-redux";
import { RootState } from "../../redux";

export default function ValidateIcon() {
    const theme = useSelector((state:RootState)=>state.appearance.theme);
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.16667 15H2.5"
          stroke={theme==='dark'?"rgb(187, 187, 187)":"#121212"}
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 14.9997L14.1667 16.6663L17.5 13.333"
          stroke={theme==='dark'?"rgb(187, 187, 187)":"#121212"}
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 10H2.5"
          stroke={theme==='dark'?"rgb(187, 187, 187)":"#121212"}
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 5H2.5"
          stroke={theme==='dark'?"rgb(187, 187, 187)":"#121212"}
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  