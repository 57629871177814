import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { AppDispatch, RootState } from "../../../../../redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getItemDetailsForLocalPurchase, getItemsBySearchValue } from "../../../../../redux/slices/itemSlice";
import { ItemDescriptionIdDto } from "../../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { toast } from "react-toastify";
import { GetItemDetailsDto } from "../../../../../models/clientDashboard/Item/GetItemDetailsDto";
import { focusOnAgGridEditingCell } from "../../../../../helperMethods/focusOnAgGridEditingCell";
import { debounce } from "lodash";
import { getBranchesByEntityId, getEntitiesBySearch } from "../../../../../redux/slices/entitySlice";
import { Entity } from "../../../../../models/clientDashboard/Entity/Entity";
import { AutoComplete, Button, Checkbox, Col, Input, Modal, Row, Select, Spin } from "antd";
import FormSelect from "../../../../CustomComponents/FormSelect";
import FormInput from "../../../../CustomComponents/FormInput";
import FormSelectWithSearch from "../../../../CustomComponents/FormSelectWithSearch";
import Search from "antd/es/input/Search";
import AgGridTableComponent from "../../../../CustomComponents/AgGridTableComponent";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import { handleNextGridColumnClick } from "../../../../../helperMethods/handleNextGridColumnClick";
import { CreateOrUpdateEntryItemsOrderDetailDto } from "../../../../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDetailDto";
import { GenericDocumentDto } from "../../../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateEntryItemsOrderDto } from "../../../../../models/clientDashboard/EntriesModule/Orders/CreateOrUpdateEntryItemsOrderDto";
import { getEntryItemsOrdersDetailColumns } from "./EntryItemsOrdersDetails/AgGridEntryItemsOrdersDetailColumns";
import FormSwitch from "../../../../CustomComponents/FormSwitch";
import { CopiedDocumentDetailsTable } from "../../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { mapDataToInterface } from "../../../../../helperMethods/mapDataToInterface";
import { db } from "../../../../../indexDB/clientSideDatabase";
import { Classifier } from "../../../../../models/clientDashboard/Classifier/Classifier";
import { CreateAndUpdateEntityBranchDto } from "../../../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { getClassifierValueByNameForSelect } from "../../../../../helperMethods/getClassifierValueByNameForSelect";
import GenerateOrderModal from "./GenerateOrderModal";
import { SelectOption } from "../../../../../models/SelectOption";
import { GenerateItemOrderDetailsDto } from "../../../../../models/clientDashboard/EntriesModule/Orders/GenerateItemOrderDetailsDto";
import { postEntryItemsOrderDetailsAsync } from "../../../../../redux/slices/entryItemsOrdersSlice";
import { AutomaticOrderDetailDto } from "../../../../../models/clientDashboard/EntriesModule/Orders/AutomaticOrderDetailDto";
import { AgGridReact } from "ag-grid-react";

interface Props {
    type: string;
    values: GenericDocumentDto<CreateOrUpdateEntryItemsOrderDto, CreateOrUpdateEntryItemsOrderDetailDto>;
    entryItemsOrdersDetails: CreateOrUpdateEntryItemsOrderDetailDto[];
    addEntryItemsOrdersDetail: (
        entryItemsOrdersDetail: CreateOrUpdateEntryItemsOrderDetailDto
    ) => void;
    setEntryItemsOrderDetails: (entryItemOrder: CreateOrUpdateEntryItemsOrderDetailDto[]
    ) => void;
    removeEntryItemsOrdersDetail: (rowIndex: number) => void;
    mode: string;
    setFieldValue: (name: string, value: any) => void
    setClassifiersForBrand: (classifiers: Classifier[]) => void;
    classifiersForBrand: Classifier[];
    supplierOptions: SelectOption[];
}
export default function MainFieldsComponent({
    values,
    entryItemsOrdersDetails,
    addEntryItemsOrdersDetail,
    removeEntryItemsOrdersDetail,
    setEntryItemsOrderDetails,
    setClassifiersForBrand,
    classifiersForBrand,
    supplierOptions,
    mode,
    type,
    setFieldValue
}: Props) {
    const t = useAppTranslation("ClientDashboard.CreateEntryItemsOrders");
    const tForDocumentType = useAppTranslation("ClientDashboard.DocumentTypes");
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const [searchValue, setSearchValue] = useState("");
    const [itemOptions, setItemOptions] = useState<any[]>([]);
    const searchInputRef = useRef<any | null>(null);
    const suppliers = useSelector((state: RootState) => state.entity.entities);
    const user = useSelector((state: RootState) => state.user.loggedInUser);
    const dispatch = useDispatch<AppDispatch>();
    const userBranches = useSelector((state: RootState) => state.user.loggedInUserBranches);
    const documentTypes = useSelector((state: RootState) => state.documentType.documentTypes);
    const entryItemsOrdersDetailGridRef = useRef(null);
    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [entityBranches, setEntityBranches] = useState<CreateAndUpdateEntityBranchDto[]>([]);



    const [gridApi, setGridApi] = useState<any>(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const onEntryItemsOrdersDetailGridReady = (params: any) => {
        params.columnApi.autoSizeColumns(['no']);
        params.columnApi.autoSizeColumns(['itemId']);
        params.columnApi.autoSizeColumns(['excise']);
        params.columnApi.autoSizeColumns(['otherExpenses']);
        params.columnApi.autoSizeColumns(['unitDescription']);
        params.columnApi.autoSizeColumns(['stock']);
        params.columnApi.autoSizeColumns(['expirationDate']);
        params.columnApi.autoSizeColumns(['quantity']);
        params.columnApi.autoSizeColumns(['purchasePrice']);
        params.columnApi.autoSizeColumns(['discount']);
        params.columnApi.autoSizeColumns(['vat']);
        params.columnApi.autoSizeColumns(['margin']);
        params.columnApi.autoSizeColumns(['transport']);
        params.columnApi.autoSizeColumns(['transportCorrection']);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };
    const [columnDefs] = useState<ColDef<CreateOrUpdateEntryItemsOrderDetailDto>[]>(
        getEntryItemsOrdersDetailColumns(removeEntryItemsOrdersDetail, t, searchInputRef, mode, type)
    );
    const handleItemSearch = async (value: any, supplierId: number) => {
        const itemsBasedOnValueAction = await dispatch(
            getItemsBySearchValue({ searchValue: value, supplierId: supplierId })
        );
        const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

        if (!items || items.length === 0) {
            setItemOptions([
                {
                    label: t("noItemsFound"),
                    value: "no-items",
                    disabled: true
                }
            ]);
        } else {
            setItemOptions([]);
            setItemOptions(
                items?.map((item) => ({
                    key: item.itemId,
                    label: item.description,
                    value: item.itemId.toString(),
                }))
            );
        }
    };



    const [copiedData, setCopiedData] = useState<CopiedDocumentDetailsTable<any> | undefined>(undefined);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = async (data: GenerateItemOrderDetailsDto) => {
        try {
            data.branchId = values.branchId;
            data.limit = values.header.packageLimit ?? 0;
            data.numOfDays = values.header.salesAnalysis ?? 0;
            data.stockRotation = values.header.stability ?? 0;
            data.entityId = values.header.subjectId;

            const itemsReturned = await dispatch(postEntryItemsOrderDetailsAsync(data));
            if (itemsReturned.type === 'EntryItemsOrders/postEntryItemsOrderDetails/fulfilled') {
                const items = itemsReturned.payload as AutomaticOrderDetailDto[];
                const entryItemOrderDetailsToAdd: CreateOrUpdateEntryItemsOrderDetailDto[] = [];
                items?.map((item) => {
                    const newItemOrderDetail: CreateOrUpdateEntryItemsOrderDetailDto = {
                        entryItemsOrderDetailId: 0,
                        entryItemsOrderId: "",
                        itemId: item.itemId,
                        quantity: item.quantity,
                        package: item.packaging,
                        supplyPrice: 0,
                        salesPrice: item.sales,
                        stock: item.stock,
                        itemDescription: item.itemDescription ?? "",
                        itemPackaging: item.packageQuantity,
                        proposedQuantity: item.proposedQuantity,
                        rowAction: 'A'
                    }
                    entryItemOrderDetailsToAdd.push(newItemOrderDetail);
                });
                setEntryItemsOrderDetails(entryItemOrderDetailsToAdd);
                setIsModalVisible(false);
            } else {
                toast.error(t("somethingWentWrong"))
            }
        } catch (error) {
            toast.error(t("somethingWentWrong"))
        }
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOnSelect = async (value: any) => {
        if (!value || isNaN(parseInt(value))) {
            toast.error("Please enter a valid search term");
            return;
        }
        const itemID = parseInt(value);
        const itemOnDatabase = await dispatch(
            getItemDetailsForLocalPurchase({ itemID, branchID: values.branchId ?? 0 })
        );
        if (
            itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/rejected"
        ) {
            toast.error("Item not found");
            return;
        } else if (
            itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/fulfilled"
        ) {
            const item = itemOnDatabase.payload as GetItemDetailsDto;

            if (item) {
                const isItemExist = entryItemsOrdersDetails.some(
                    (detail) => detail.itemId === item.itemId && detail.rowAction !== 'D'
                );
                if (isItemExist) {
                    toast.success(t("Item"));
                    return;
                }
                const newEntryItemsOrdersDetail: CreateOrUpdateEntryItemsOrderDetailDto = {
                    entryItemsOrderDetailId: 0,
                    entryItemsOrderId: "",
                    itemId: item.itemId,
                    package: 0,
                    supplyPrice: item.purchasePrice,
                    itemDescription: item.description ?? "",
                    comment: "",
                    stock: item.stock ?? 0,
                    quantity: 0,
                    proposedQuantity: 0,
                    discount: item.discount ?? 0,
                    extraDiscount: item.extraDiscount ?? 0,
                    barcode: "",
                    itemPackaging: item.packaging,
                    salesPrice: item.salesPrice ?? 0,
                    rowAction: 'A',
                };
                addEntryItemsOrdersDetail(newEntryItemsOrdersDetail);

                setTimeout(() => {
                    focusOnAgGridEditingCell(entryItemsOrdersDetailGridRef, "quantity");
                }, 0);
                setSearchValue("");
            } else {
                toast.error("Item not found");
            }
        } else {
            toast.error("Something went wrong");
        }
    };

    const pasteDetails = async () => {
        try {
            if (copiedData) {
                const targetType = {
                    entryItemsOrderId: "",
                    itemId: 0,
                    itemDescription: "",
                    comment: undefined,
                    quantity: 0,
                    package: 0,
                    supplyPrice: 0,
                    discount: 0,
                    extraDiscount: 0,
                    salesPrice: 0,
                    stock: 0,
                    itemPackaging: 0,
                    proposedQuantity: 0,
                    barcode: undefined,
                    rowAction: 'A'
                } as CreateOrUpdateEntryItemsOrderDetailDto
                const details = mapDataToInterface(copiedData.list as any[], targetType)
                setEntryItemsOrderDetails(details);
            } else {
                toast.error(t('toastMessages.no_document'));
            }
        } catch (error) {
            toast.error(t('toastMessages.something_wrong'));
        }
    }

    const getCopiedData = async () => {
        const data = await db.getGenericData();
        if (data)
            setCopiedData(data[0])
        else
            setCopiedData(undefined);
    }

    useEffect(() => {
        getCopiedData();
    }, []);

    const onChange = (data: any) => {
        setSearchValue(data);
    };

    const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

    const handleSearchChange = (value: string, supplierId: number) => {
        if (!value.trim()) {
            setSearchValue("");
            return;
        }
        setSearchValue(value);
        debouncedItemSearch(value, supplierId);
    };

    const getEntityBranches = async (entityId: number) => {
        const result = await dispatch(getBranchesByEntityId(entityId));
        if (result.type === "Entities/getBranchesByEntityId/fulfilled") {
            setEntityBranches(result.payload as CreateAndUpdateEntityBranchDto[])
        }
    }

    const fetchSuppliers = async (searchText: string) => {
        const result = await dispatch(getEntitiesBySearch({ searchText: searchText, isSupplier: true }));
        if (result.payload !== "An error occurred") {
            const payload = result.payload as Entity[]
            const options = payload.map((entity) => ({
                key: entity.entityId.toString(),
                label: entity.description,
                value: entity.entityId
            }))
            return options;
        }
        return [];
    }

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };

    const memoizedRowData = useMemo(() => {
        return entryItemsOrdersDetails.filter((detail) => detail.rowAction !== "D");
    }, [entryItemsOrdersDetails]);

    useEffect(() => {
        if (values?.header?.subjectId !== undefined && values?.header?.subjectId !== 0) {
            getEntityBranches(values?.header?.subjectId)
        }
    }, [values?.header?.subjectId])


    return (
        <>
            <Row gutter={[8, 8]}>
                <Col xs={12} sm={8}>
                    <FormSelect
                        name="branchId"
                        label={t("branchIdInputLabel")}
                        required={true}
                        value={values.branchId}
                        // handleCustomChange={(value)=>{
                        //     setFieldValue("branchId", value)
                        // }}
                        options={userBranches?.map((userBarnch) => {
                            return {
                                key: userBarnch.branchId,
                                label: userBarnch.description,
                                value: userBarnch.branchId
                            };
                        }) ?? []
                        }
                        disabled={!user?.isMainBranch || values.validated || entryItemsOrdersDetails.length > 0}
                    />
                    <FormSelect
                        name="documentTypeId"
                        label={t("documentTypeInputLabel")}
                        style={{ width: "100%" }}
                        value={values.documentTypeId}
                        required={true}
                        disabled={values.validated || entryItemsOrdersDetails.length > 0}
                        options={documentTypes.map((documentType) => {
                            return {
                                key: documentType.documentId,
                                label: documentType.description,
                                value: documentType.documentId
                            };
                        }) ?? []
                        }
                    />
                    <FormSelectWithSearch
                        name="header.subjectId"
                        label={t("subjectIdInputLabel")}
                        required={true}
                        value={values?.header?.subjectId}
                        disabled={values.validated || entryItemsOrdersDetails.length > 0}
                        fetchOptions={fetchSuppliers}
                        fetchInitialOption={() => {
                            return {
                                key: values?.header?.subjectId ?? "",
                                label: values?.header?.subjectDescription ?? "",
                                value: values?.header?.subjectId
                            }
                        }}
                        placeHolder={t("placeHolder")}
                        onChange={(option) => {
                            setFieldValue("header.subjectId", option.value)
                            setFieldValue("header.subjectDescription", option.label);
                        }}
                    />

                    {type !== 'normal' && (<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <FormInput
                            name="header.startDate"
                            type="date"
                            min={new Date().toISOString().split("T")[0]}
                            max={values?.header?.endDate ?? undefined}
                            required={type === "automatic"}
                            disabled={values.validated}
                            label={t("startDateInputLabel")}
                            value={(values?.header?.startDate?.split("T")[0] || "")}
                            style={{ width: '100%' }}
                        />
                        <FormInput
                            name="header.endDate"
                            type="date"
                            min={values?.header?.startDate ?? undefined}
                            required={type === "automatic"}
                            disabled={values.validated}
                            label={t("endDateInputLabel")}
                            value={(values?.header?.endDate?.split("T")[0] || "")}
                            style={{ width: '100%' }}
                        /></div>)}

                </Col>
                <Col xs={12} sm={8}>
                    <FormInput
                        name="date"
                        type="date"
                        required={true}
                        min={new Date().toISOString().split("T")[0]}
                        label={t("dateInputLabel")}
                        value={values?.date?.split("T")[0] || ""}
                        disabled={values.validated}
                    />

                    {type === "automatic" ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                            }}
                        >
                            <div style={{ flex: 1, marginRight: '10px' }}>
                                <FormSelect
                                    name="header.currencyId"
                                    label={t("currencyInputLabel")}
                                    style={{ width: "100%" }}
                                    required={type === "automatic"}
                                    value={values?.header?.currencyId}
                                    disabled={values.validated}
                                    options={currencies.map((currency) => ({
                                        label: `${currency.currencySymbol} ${currency.currencyName}`,
                                        value: currency.currencyId,
                                    }))}
                                />
                            </div>
                            <div style={{ width: "100px" }}>
                                <FormInput
                                    name="header.exchangeRate"
                                    type="number"
                                    required={type === "automatic"}
                                    label={t("exchangeRateInputLabel")}
                                    style={{ width: "100%" }}
                                    value={values?.header?.exchangeRate}
                                    disabled={values?.header?.currencyId === undefined || values.validated}
                                />
                            </div>
                        </div>
                    ) : ("")}
                    {type !== "automatic" && (
                        <FormInput
                            name="header.deliveryDeadline"
                            type="number"
                            required={true}
                            label={t("deliveryDeadlineInputLabel")}
                            value={values?.header?.deliveryDeadline}
                            disabled={values.validated}
                        />

                    )}
                    {type !== "automatic" && (
                        <FormInput
                            name="comment"
                            label={t("commentInputLabel")}
                            required={false}
                            value={values?.header?.comment}
                            disabled={values.validated}
                        />
                    )}
                    {type !== "normal" && (
                        <FormInput
                            name="header.deliveryDeadline"
                            type="number"
                            required={true}
                            label={t("deliveryDeadlineInputLabel")}
                            value={values?.header?.deliveryDeadline}
                            disabled={values.validated}
                        />
                    )}

                    {type === "automatic" && (
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <FormInput
                                name="header.stability"
                                label={t("stabilityInputLabel")}
                                type="number"
                                required={true}
                                value={values?.header?.stability}
                                disabled={values.validated}
                                style={{ flex: 1, margin: 0 }}
                            />
                            <FormInput
                                name="header.salesAnalysis"
                                label={t("salesAnalysisInputLabel")}
                                type="number"
                                required={true}
                                value={values?.header?.salesAnalysis}
                                disabled={values.validated}
                                style={{ flex: 1, margin: 0 }}
                            />
                        </div>
                    )}

                </Col>
                <Col xs={12} sm={8}>
                    <FormInput
                        name="genericDocumentIdAsString"
                        label={t("referenceNoInputLabel")}
                        required={false}
                        value={values.genericDocumentIdAsString}
                        disabled={true}
                    />
                    <FormInput
                        name="documentNo"
                        label={t("documentNoInputLabel")}
                        required={false}
                        value={values.documentNo}
                        disabled={true}
                    />
                    {type !== "normal" && (
                        <FormInput
                            name="comment"
                            label={t("commentInputLabel")}
                            required={false}
                            value={values?.header?.comment}
                            disabled={values.validated}
                        />
                    )}
                    {type !== "normal" && (<>
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <FormSwitch
                                className="orderOutside"
                                name="header.orderOutsideRoute"
                                label={t("orderOutsideRouteInputLabel")}
                                value={values?.header?.orderOutsideRoute}
                                disabled={values.validated}
                            />
                            <FormSwitch
                                className="fulfillPackage"
                                name="header.fulfillPackage "
                                label={t("fulfillPackageInputLabel")}
                                value={values?.header?.fulfillPackage}
                                onChange={(checked) => {
                                    setFieldValue("header.fulfillPackage", checked)
                                }}
                                disabled={values.validated}
                            />
                            {values?.header?.fulfillPackage && (
                                <FormInput
                                    name="header.packageLimit"
                                    type="number"
                                    label={t("packageLimitInputLabel")}
                                    value={values?.header?.packageLimit}
                                    disabled={values.validated}
                                    style={{ flex: 1 }}
                                    addonAfter="%"
                                />)}
                        </div>
                    </>)}
                </Col>
            </Row>
            <Row>
                <Col className="custom-row" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <AutoComplete
                        options={itemOptions}
                        onSelect={handleOnSelect}
                        onSearch={(value) => handleSearchChange(value, values.branchId)}
                        onChange={onChange}
                        value={searchValue}
                        className="custom-search"
                        style={{ width: 300 }}
                        disabled={values.branchId === 0 || values.branchId === null
                            || values.branchId === undefined || values.validated
                            || values?.header?.subjectId === 0 || values?.header?.subjectId === null
                            || values?.header?.subjectId === undefined || values.documentTypeId === undefined
                            || values.documentTypeId === null}
                    >
                        <Search
                            placeholder={t('searchPlaceholder')}
                            allowClear
                            ref={searchInputRef}
                            size="large"
                            onSearch={(value) => handleSearchChange(value, values.branchId)}
                        />
                    </AutoComplete>
                    {copiedData && (
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <Button onClick={pasteDetails} disabled={entryItemsOrdersDetails.length > 0 || !values.branchId || !values.documentTypeId || !values?.header?.subjectId}>{t("paste")}</Button>

                            {type === "automatic" && (
                                <Button type="primary" onClick={showModal} disabled={values.branchId === 0 || values.branchId === null
                                    || values.branchId === undefined || values.validated
                                    || values?.header?.subjectId === 0 || values?.header?.subjectId === null
                                    || values?.header?.subjectId === undefined || values.documentTypeId === undefined
                                    || values.documentTypeId === null || values?.header?.stability === 0
                                    || values?.header?.stability === undefined || values?.header?.salesAnalysis === null
                                    || values?.header?.salesAnalysis === undefined}>
                                    {t("customInputsButtonLabel")}
                                </Button>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col xs={24} sm={24}>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <div
                            className={`ag-theme-quartz custom-theme-grid`}
                            style={{ height: 550, marginTop: 15, position: 'relative' }}
                        >
                            <AgGridReact
                                onGridReady={onEntryItemsOrdersDetailGridReady}
                                defaultColDef={defaultColDef}
                                rowData={memoizedRowData}
                                ref={entryItemsOrdersDetailGridRef}
                                columnDefs={columnDefs}
                                suppressRowHoverHighlight={true}
                                suppressCellFocus={false}
                                suppressRowClickSelection={true}
                                rowSelection="single"
                                paginationPageSizeSelector={[10, 20, 50, 100]}
                                onCellKeyDown={(event: CellKeyDownEvent) =>
                                    handleNextGridColumnClick(event, searchInputRef)
                                }
                            />
                            {values.validated && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'transparent',
                                        zIndex: 1000,
                                        cursor: 'not-allowed',
                                    }}
                                />
                            )}
                        </div>
                    </Spin>
                </Col>
            </Row>
            <GenerateOrderModal isModalVisible={isModalVisible} handleOk={handleOk}
                handleCancel={handleCancel} classifiersForBrand={classifiersForBrand} />
        </>
    );
}
