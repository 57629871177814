import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentPeriodsState } from "../../models/clientDashboard/Registrations/DocumentPeriods/DocumentPeriodsState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { DocumentPeriod } from "../../models/clientDashboard/Registrations/DocumentPeriods/DocumentPeriod";


const initialState: DocumentPeriodsState = {
    documentPeriods: [],
    error: null,
};

const DocumentPeriodsSlice = createSlice({
    name: "documentPeriods",
    initialState,
    reducers: {
        getDocumentPeriodsStart(state: DocumentPeriodsState) {
            setLoading(true);
            state.error = null;
        },
        getDocumentPeriodsSuccess(
            state: DocumentPeriodsState,
            action: PayloadAction<DocumentPeriod[]>
        ) {
            setLoading(false);
            state.documentPeriods = action.payload;
        },
        getDocumentPeriodsFailure(
            state: DocumentPeriodsState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        deleteDocumentPeriodsFromState(state: DocumentPeriodsState, action: PayloadAction<number>) {
            setLoading(false);
            const documentPeriodsId = action.payload;
            state.documentPeriods = state.documentPeriods.filter(documentPeriods => documentPeriods.documentPeriodsId !== documentPeriodsId);
        },
    }
});

export const getDocumentPeriodsAsync = createAsyncThunk(
    "DocumentPeriods/getDocumentPeriods",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const documentPeriods = state.documentPeriods.documentPeriods;
        if (documentPeriods.length > 0) {
            return documentPeriods;
        }

        try {
            dispatch(getDocumentPeriodsStart());
            
            const response = await apiService.get<ApiResponse<DocumentPeriod[]>>(
                "api/DocumentPeriods/getAll"
            );
            dispatch(getDocumentPeriodsSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getDocumentPeriodsFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getDocumentPeriodsDataById = createAsyncThunk(
    "DocumentPeriods/getDocumentPeriodsDataById",
    async (documentPeriodsId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<DocumentPeriod>>(
                `/api/DocumentPeriods/${documentPeriodsId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteDocumentPeriodsAsync = createAsyncThunk(
    "DocumentPeriods/deleteDocumentPeriod",
    async (documentPeriodsId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/DocumentPeriods/DeleteDocumentPeriod/${documentPeriodsId}`
            );
            dispatch(deleteDocumentPeriodsFromState(documentPeriodsId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getDocumentPeriodsStart,
    getDocumentPeriodsSuccess,
    getDocumentPeriodsFailure,
    deleteDocumentPeriodsFromState
} = DocumentPeriodsSlice.actions;

export default DocumentPeriodsSlice.reducer;