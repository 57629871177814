import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExpensTypes } from "../../models/clientDashboard/ExpensTypes/ExpensTypes";
import { ExpensTypesState } from "../../models/clientDashboard/ExpensTypes/ExpensTypeState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { RootState } from "..";
import { ApiResponse } from "../../models/ApiResponse";
import apiService from "../../extensions/api";


const initialState: ExpensTypesState = {
    expensTypes: [],
    error: null,
};

const ExpensTypesSlice = createSlice({
    name: "expensType",
    initialState,
    reducers: {
        getExpensTypesStart(state:ExpensTypesState) {
            setLoading(true);
            state.error = null;
        },
        getExpensTypesSuccess(
            state:ExpensTypesState,
            action: PayloadAction<ExpensTypes[]>
        ) {
            setLoading(false);
            state.expensTypes = action.payload;
        },
        getExpensTypesFailure(
            state: ExpensTypesState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
       
      
    }
});

export const getExpensTypesAsync = createAsyncThunk(
    "ExpensTypes/getExpensTypes",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const expensTypes = state.expensType.expensTypes;

        if (expensTypes.length > 0) {
            return expensTypes;
        }

        try {
            dispatch(getExpensTypesStart());
            const response = await apiService.get<ApiResponse<ExpensTypes[]>>(
                "api/GoodsImportExpenses/GoodsEntryExpenseTypes"
            );
            dispatch(getExpensTypesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getExpensTypesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);



export const {
    getExpensTypesStart,
    getExpensTypesSuccess,
    getExpensTypesFailure,
} = ExpensTypesSlice.actions;

export default ExpensTypesSlice.reducer;