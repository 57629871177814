import { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Authorization/Login";
import DasbhoardLayoutRoutes from "./components/Layout/Dashboard/DashboardLayoutRoutes";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setDecodedUser } from "./redux/slices/userSlice";
import decodeToken from "./extensions/decodeToken";
import ProtectedDashboardLoggedIn from "./components/Authorization/ProtectedDashboardLoggedIn";
import ProtectedAppLoggedIn from "./components/Authorization/ProtectedAppLoggedIn";
import RedirectUserIfLoggedIn from "./components/Authorization/RedirectUserIfLoggedIn";
import NotFoundPage from "./components/Authorization/NotFoundPage";
import { setDrawerOpen, setLanguage } from "./redux/slices/appearanceSlice";
import { ConfigProvider, Flex, Spin, ThemeConfig } from "antd";
import { RootState } from "./redux";
import ClientDashboardLayoutRoutes from "./components/Layout/ClientDashboard/ClientDashboardLayoutRoutes";
import useConfigurationTokenRefresh from "./helperMethods/useConfigurationTokenRefresh";
import { LoadingOutlined } from "@ant-design/icons";

function App() {
  const dispatch = useDispatch();
  const theme = useSelector(
    (rootState: RootState) => rootState.appearance.theme
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var data = localStorage.getItem("token");
    if (data) {
      const decodedUser = decodeToken(data);
      if (decodedUser) {
        dispatch(setDecodedUser(decodedUser));
      }
    }

    var drawerOpen = localStorage.getItem("drawerOpen");
    if (data) {
      if (drawerOpen === "true") {
        dispatch(setDrawerOpen(true));
      } else {
        dispatch(setDrawerOpen(false));
      }
    }

    var langCode = localStorage.getItem("langCode");
    if (langCode) {
      dispatch(setLanguage(langCode));
    } else {
      dispatch(setLanguage("al"));
    }

    fetch(`${process.env.PUBLIC_URL}/config.json`)
      .then((response) => response.json())
      .then((config) => {
        const root = document.documentElement;
        const theme = config.theme;

        // // Set CSS variables from the JSON theme data
        root.style.setProperty(
          "--dark-theme-background-color",
          theme["dark-theme-background-color"]
        );
        root.style.setProperty(
          "--dark-theme-ag-grid-header-background-color",
          theme["dark-theme-ag-grid-header-background-color"]
        );
        root.style.setProperty(
          "--dark-theme-ag-grid-row-even-background-color",
          theme["dark-theme-ag-grid-row-even-background-color"]
        );
        root.style.setProperty(
          "--dark-theme-ag-grid-row-odd-background-color",
          theme["dark-theme-ag-grid-row-odd-background-color"]
        );
        root.style.setProperty(
          "--dark-theme-ag-grid-row-text-background-color",
          theme["dark-theme-ag-grid-row-text-background-color"]
        );
      })
      .catch((error) => {
        console.error("Error loading config.json:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const lightThemeConfig: ThemeConfig = {
    token: {
      colorPrimary: "#1890ff", // Light blue
      colorTextSecondary: "black", // Light gray
      colorBgContainer: "#ffffff", // White
      colorBorder: "#d9d9d9", // Light gray
    },
  };

  const darkThemeConfig: ThemeConfig = {
    token: {
      colorPrimary: "#1890ff", // Light blue
      colorTextSecondary: "#bfbfbf", // Light gray
      colorBgContainer: "#141d2c", // Almost black
      colorBorder: "#434343", // Dark gray
    },
  };

  return (
    <>
      {isLoading ? (
        <div className="">
          <Flex align="center" gap="middle">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
            />
          </Flex>
        </div>
      ) : (
        <div className={theme === "dark" ? "darkMode" : "lightMode"}>
          <ToastContainer
            position="bottom-right"
            hideProgressBar
            autoClose={5000}
            closeButton={true}
            pauseOnHover={false}
            transition={Slide}
          />

          <ConfigProvider
            theme={theme === "dark" ? darkThemeConfig : lightThemeConfig}
          >
            <Routes>
              <Route element={<RedirectUserIfLoggedIn />}>
                <Route path="/login" element={<Login />} />
              </Route>

              <Route element={<ProtectedDashboardLoggedIn />}>
                {/* Route to all the components made for sys admin, accountant and owner */}
                <Route
                  path="dashboard/*"
                  element={<DasbhoardLayoutRoutes />}
                />
              </Route>

              <Route element={<ProtectedAppLoggedIn />}>
                {/*Replact path="/" of main route element to DashboardAppLayoutRotes in the future */}
                {/*Route to all the components made for company users app */}
                <Route path="/*" element={<ClientDashboardLayoutRoutes />} />
              </Route>

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ConfigProvider>
        </div>
      )}
    </>
  );
}

export default App;
