import { Col, Form, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Branch } from "../../../models/clientDashboard/Branch/Branch";
import { setLoading } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../indexDB/clientSideDatabase";
import { getBranchDataById, getBranchesSuccess } from "../../../redux/slices/branchSlice";
import useFormData from "../../../customHooks/useFormData";
import { Formik } from "formik";
import FormInput from "../../CustomComponents/FormInput";
import FormSwitch from "../../CustomComponents/FormSwitch";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import { BranchUsers } from "../../../models/clientDashboard/BranchUsers/BranchUsers";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";


const { Title, Text } = Typography;

const UpdateBranch = () => {
    const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
    const { id } = useParams();
    const t = useAppTranslation("ClientDashboard.UpdateBranch");


    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    
    const onFinish = async (values: Branch) => {
        dispatch(setLoading(true));

        await apiService
            .put("/api/Branches", values)
            .then((response) => {
                toast.success(t("branchUpdatedSuccessfully"));
                db.deleteTab(`branch/update/${id}`);
                dispatch(getBranchesSuccess([]))
                navigate("/branches");
            })
            .catch(() => { })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    const { initialValues } = useFormData<Branch>(
        `branch/update/${id}`,
        { isActive: true } as Branch,
        undefined,
        {
            fetchData: async () => {
                const branchAction = await dispatch(getBranchDataById(parseInt(id ?? "0")));
                const branchData = branchAction.payload as 
                {
                    branch:Branch
                    branchUserDetails:BranchUsers[]
                };
                return branchData.branch
            }
        }
    )

    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm() || {};
        setTouched({
            description: true,
            shortDescription: true,
            fiscalCode: true,
            isActive: true,
        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
      };

    return (
        <Spin tip="Loading..." spinning={loadScreen}>
            <Formik
                initialValues={initialValues ?? {} as Branch}
                onSubmit={onFinish}
                enableReinitialize
            >
                {({ values, handleSubmit, submitForm, setTouched, validateForm}) => (
                    <>
                    <FormHeaderOptions
                            title={t("formTitle")}
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("updateBranchButton")}
                            submitButtonIsDisabled={isLoading}
                            createAccessEnum={MenuOptionEnum.BranchesCreate}
                        />
                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                        style={{ marginTop: 20 }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                            <FormInput
                                    name="description"
                                    label={t("descriptionInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("descriptionInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.description}
                                />
                                <FormInput
                                    name="shortDescription"
                                    label={t("shortDescriptionInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("shortDescriptionInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.shortDescription}
                                />
                                </Col>
                            <Col xs={24} sm={12}>
                            <FormInput
                                    name="fiscalCode"
                                    label={t("fiscalCodeInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("fiscalCodeInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.fiscalCode}
                                />
                                <FormSwitch
                                    name="isActive"
                                    label={t("isActiveInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("isActiveInputPlaceholder"),
                                        },

                                    ]}
                                    value={values.isActive}
                                />
                            </Col>
                        </Row>
                        <HandleFormDataForTabSaving tabPath={`branch/update/${id}`} />
                    </Form>
                    </>
                )}
            </Formik>
        </Spin>
    );
};

export default UpdateBranch;