import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useCallback, useEffect, useRef, useState } from "react";
import { CreateAndEditImportPurchaseDetailDto } from "../../../models/clientDashboard/ImportPurchaseDetail/CreateAndEditImportPurchaseDetailDto";
import { CreateAndEditImportPurchaseDto } from "../../../models/clientDashboard/ImportPurchase/CreateAndEditImportPurchaseDto";
import useFormData from "../../../customHooks/useFormData";
import moment from "moment";
import * as Yup from "yup";
import { ItemsClassifierDto } from "../../../models/clientDashboard/Classifier/ItemClassifierDto";
import { convertClassifierValueToObject } from "../../../helperMethods/convertClassifierValueToObject";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import { entryItemCategories } from "../Entities/EntityClassifiersMapped";
import apiService from "../../../extensions/api";
import { db } from "../../../indexDB/clientSideDatabase";
import {
  correctImportPurchaseAsync,
  getImportPurchaseDataById,
  handlePrintImportPurchaseForm,
  invalidateImportPurchaseAsync,
  validateImportPurchaseAsync,
} from "../../../redux/slices/importPurchaseSlice";
import { Formik, FormikErrors } from "formik";
import { Form, Spin, Tabs } from "antd";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import ImportPurchaseClassifiersComponent from "./CreateImportPurchaseComponent/ImportPurchaseClassifiersComponent";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import { v4 as uuidv4 } from "uuid";
import MainFieldsComponent from "./CreateImportPurchaseComponent/MainFieldsComponent";
import OtherFieldsComponent from "./CreateImportPurchaseComponent/OtherFieldsComponent";
import { getItemsWithFilters } from "../../../redux/slices/itemSlice";
import { getEntitiesByTypeAsync } from "../../../redux/slices/entitySlice";
import { getClassifiersByTypeAsync } from "../../../redux/slices/classifierSlice";
import { getCurrenciesAsync } from "../../../redux/slices/currencySlice";
import { getDocumentTypesWithSignHAsync } from "../../../redux/slices/documentTypeSlice";
import { getUserBranches } from "../../../redux/slices/userSlice";
import { CreateAndUpdateGoodsImportExpenseDto } from "../../../models/clientDashboard/GoodsImportExpense/CreateAndUpdateGoodsImportExpenseDto";
import { getExpensTypesAsync } from "../../../redux/slices/expenseTypesSlice";
import ExpensesFieldsComponent from "./CreateImportPurchaseComponent/ExpensesFieldsComponent";
import { CopiedDocumentDetailsTable } from "../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import ImportPurchases from ".";
import { SelectOption } from "../../../models/SelectOption";
import { ConfigurationEnum } from "../../../enums/ConfigurationEnum";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import printBase64PDF from "../../../helperMethods/printBase64PDF";
import { getTaxesAsync } from "../../../redux/slices/taxSlice";

export default function CreateImportPurchase() {
  const navigate = useNavigate();
  const { mode, id, branchId } = useParams<{
    mode: string;
    id?: string;
    branchId?: string;
  }>();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.CreateImportPurchase");
  const formikRef = useRef<any>(null);
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);

  const [importPurchaseDetails, setImportPurchaseDetails] = useState<
    CreateAndEditImportPurchaseDetailDto[]
  >([]);
  const [expensesDetails, setExpensesDetails] = useState<
    CreateAndUpdateGoodsImportExpenseDto[]
  >([]);
  const generatedIdForCorrection = uuidv4();
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [isDocumentValidated, setIsDocumentValidated] = useState<
    boolean | null
  >(null);

  const configurations = useSelector(
    (state: RootState) => state.configuration.configurationValuesFromToken
  );
  const purchaseConfig = configurations.find(
    (config) =>
      config.configurationId ===
      ConfigurationEnum.Mundesovendosjenecmimeveteshitjestekthyrjaemallit
  );

  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const setAdditionalStates = useCallback(
    (data: CreateAndEditImportPurchaseDto) => {
      if (data.details) {
        setImportPurchaseDetails(data.details);
      }
      if (data.goodsImportExpenses) {
        setExpensesDetails(data.goodsImportExpenses);
      }
      setSupplierOptions([
        {
          key: data.supplierId,
          label: data.supplier ?? "",
          value: data.supplierId ?? 0,
        },
      ]);
    },
    []
  );

  const getData = async () => {
    if (id && branchId) {
      const response = await dispatch(
        getImportPurchaseDataById({ importPurchaseId: id, branchID: branchId })
      );
      const data = response.payload as CreateAndEditImportPurchaseDto;
      if (
        response.type ===
        "ImportPurchases/getImportPurchaseDataById/fulfilled"
      ) {
        const importPurchase =
          response.payload as CreateAndEditImportPurchaseDto;

        importPurchase.k45Description = importPurchase?.k45?.classifierDetailDescription;
        importPurchase.k45 = importPurchase?.k45?.classifierDetailId;

        importPurchase.k46Description = importPurchase?.k46?.classifierDetailDescription;
        importPurchase.k46 = importPurchase?.k46?.classifierDetailId;

        importPurchase.k47Description = importPurchase?.k47?.classifierDetailDescription;
        importPurchase.k47 = importPurchase?.k47?.classifierDetailId;

        importPurchase.k48Description = importPurchase?.k48?.classifierDetailDescription;
        importPurchase.k48 = importPurchase?.k48?.classifierDetailId;

        importPurchase.k49Description = importPurchase?.k49?.classifierDetailDescription;
        importPurchase.k49 = importPurchase?.k49?.classifierDetailId;

        setInitialValues(importPurchase);
        setSupplierOptions([
          {
            key: importPurchase.supplierId,
            label: importPurchase.supplier ?? "",
            value: importPurchase.supplierId ?? 0,
          },
        ]);
        formikRef?.current?.setValues(importPurchase);
        const updatedDetails = importPurchase.details.map((detail) => ({
          ...detail,
          expirationDate:
            detail.expirationDate === null
              ? ""
              : new Date(detail.expirationDate as string),
        }));
        setImportPurchaseDetails(updatedDetails);
        setExpensesDetails(importPurchase.goodsImportExpenses);
      }
      return data;
    }
    return {
      generatedId: mode !== "update" ? uuidv4() : "",
      date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      invoiceDate: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      exchangeRate: 1,
      branchId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
            ? userBranches[0].branchId
            : undefined,
    } as CreateAndEditImportPurchaseDto;
  }

  const { initialValues, setInitialValues } = useFormData<CreateAndEditImportPurchaseDto>(
    mode === "update"
      ? `importPurchase/update/${id}/${branchId}`
      : "importPurchase/register",
    {
      generatedId: mode !== "update" ? uuidv4() : "",
      date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      invoiceDate: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      exchangeRate: 1,
      branchId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
            ? userBranches[0].branchId
            : undefined,
    } as CreateAndEditImportPurchaseDto,
    setAdditionalStates,
    {
      fetchData: getData
    }
  );

  const removePurchaseDetail = (index: number) => {
    setImportPurchaseDetails((prevPurchaseDetails) => {
      const activePurchaseDetails = prevPurchaseDetails.filter(
        (purchaseDetails) => purchaseDetails.rowAction !== "D"
      );
      const selectedPurchaseDetail = activePurchaseDetails[index];
      if (
        selectedPurchaseDetail.entryItemsDetailsId === 0 ||
        !selectedPurchaseDetail.entryItemsDetailsId
      ) {
        prevPurchaseDetails.splice(index, 1);
      } else {
        selectedPurchaseDetail.rowAction = "D";
      }
      return [...prevPurchaseDetails];
    });
  };
  const addImportPurchaseDetail = (
    importPurchaseDetail: CreateAndEditImportPurchaseDetailDto
  ) => {
    setImportPurchaseDetails((prevPurchaseDetails) => {
      return [...prevPurchaseDetails, importPurchaseDetail];
    });
  };
  const addExpensesDetail = (
    expensesDetail: CreateAndUpdateGoodsImportExpenseDto
  ) => {
    setExpensesDetails((prevExpensesDetails) => {
      return [...prevExpensesDetails, expensesDetail];
    });
  };
  const removeExpensesDetail = (index: number) => {
    setExpensesDetails((prevExpensesDetails) => {
      const activeExpensesDetails = prevExpensesDetails.filter(
        (expensesDetails) => expensesDetails.rowAction !== "D"
      );
      const selectedExpensesDetail = activeExpensesDetails[index];
      if (
        selectedExpensesDetail.entityId === 0 ||
        !selectedExpensesDetail.entityId
      ) {
        prevExpensesDetails.splice(index, 1);
      } else {
        selectedExpensesDetail.rowAction = "D";
      }
      return [...prevExpensesDetails];
    });
  };

  const validationSchema = Yup.object({
    branchId: Yup.number().required(t("branchIsRequired")),
    supplierId: Yup.number().required(t("supplierIsRequired")),
    date: Yup.date().required(t("dateIsRequired")),
  });

  const getExpenseDetail = (tempId: string) => {
    let foundExpense = null;
    setExpensesDetails((prevValues) => {
      const expenseExists = prevValues.find(
        (expense) => expense.tempId === tempId
      );
      if (expenseExists) {
        foundExpense = expenseExists;
      }
      return prevValues;
    });
    return foundExpense;
  };
  const convertCategoryValue = (
    values: CreateAndEditImportPurchaseDto,
    categoryKey: keyof CreateAndEditImportPurchaseDto,
    classifierDescription: string
  ) => {
    const classifier = classifiers.find(
      (c) => c.description === classifierDescription
    );

    if (!classifier) return;

    const classifierId = classifier.classifierId;

    if (
      typeof values[categoryKey] === "object" &&
      !Array.isArray(values[categoryKey])
    ) {
      const categoryObject = values[categoryKey] as ItemsClassifierDto;
      if (categoryObject && categoryObject?.classifierDetailId !== 0) {
        const classifierDetailDescription = categoryObject.classifierDetailDescription ?? '';
        categoryObject.classifierDetailDescription = typeof values[categoryKey] === "object" ? values[categoryKey].classifierDetailDescription : values[categoryKey]?.toString();
        categoryObject.classifierId = classifierId;
        categoryObject.classifierDetailId = (categoryObject.classifierDetailId as unknown) as number;
      } else {
        values[categoryKey] = null;
      }
    } else {
      if (values[categoryKey] === null || values[categoryKey] === undefined) {
        values[categoryKey] = null;
      }
      else if (typeof values[categoryKey] !== "object") {
        values[categoryKey] = convertClassifierValueToObject(
          classifierId,
          values[categoryKey]?.toString(),
          values[categoryKey]
        ) as ItemsClassifierDto;
      }
    }
  }

  const validatePrices = (details: CreateAndEditImportPurchaseDetailDto[]) => {
    for (let i = 0; i < details.length; i++) {
      if (details[i].salesPrice === 0 || details[i].purchasePrice === 0) {
        return false;
      }
    }
    return true;
  };

  const onFinish = async (values: CreateAndEditImportPurchaseDto) => {
    dispatch(setLoadingState(true));

    if (importPurchaseDetails.length === 0) {
      toast.error(t("atLeastOneDetailIsRequired"));
      dispatch(setLoadingState(false));
      return;
    };

    if (user?.isMainBranch && !values.branchId) {
      toast.error(t("branchIsRequired"));
      dispatch(setLoadingState(false));
      return;
    };

    if (purchaseConfig?.status === true) {
      const itemWithoutSalePrice = importPurchaseDetails.find(
        (detail) => detail.salesPrice === 0
      );
      if (itemWithoutSalePrice) {
        const errorMsg = `${itemWithoutSalePrice.itemDescription} - ${t("purchasePriceCannotBeZeroWhenStatusIsTrue")}`
        toast.error(errorMsg);
        dispatch(setLoadingState(false));
        return;
      };
    };

    const formattedImportPurchasesDetails = importPurchaseDetails.map(
      (purchaseDetail) => {
        return {
          ...purchaseDetail,
          expirationDate:
            purchaseDetail.expirationDate === ""
              ? null
              : purchaseDetail.expirationDate,
        };
      }
    );

    values.details = formattedImportPurchasesDetails;

    const formattedExpensesDetails = expensesDetails.map((expense) => {
      return {
        ...expense,
        fromDate: expense.fromDate === "" ? null : expense.fromDate,
        toDate: expense.toDate === "" ? null : expense.toDate,
      };
    });

    values.goodsImportExpenses = formattedExpensesDetails;


    const itemWithoutPurchasePrice = importPurchaseDetails.find(
      (detail) => detail.purchasePrice === 0
    );

    if (!validatePrices(importPurchaseDetails)) {
      const errorMsg = `${itemWithoutPurchasePrice?.itemDescription} - ${t("salesPriceCannotBeZeroWhenStatusIsTrue")}`
      toast.error(errorMsg);
      dispatch(setLoadingState(false));
      return;
    };

    entryItemCategories.forEach(({ key, description }) => {
      convertCategoryValue(values, key, description);
    });

    if (mode === "update" && id) {
      values.entryItemsIdAsString = values?.entryItemsId?.toString();
      const result = await apiService
        .put(`/api/ImportPurchase/update`, values)
        .then(async (response) => {
          if (response.status === 200) {
            toast.success(t("updatedSuccessfully"));
            getData();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    } else {
      await apiService
        .post("/api/ImportPurchase", values)
        .then(async (response) => {
          toast.success(t("createdSuccessfully"));
          formikRef.current.setFieldValue("entryItemsId", response.data.Data);
          formikRef.current.setFieldValue(
            "entryItemsIdAsString",
            response.data.Data
          );

          const createdId = response.data.Data;

          await db.updateTab(
            "importPurchase/register",
            `importPurchase/update/${createdId}/${values.branchId}`,
            t("tabs.updateImportPurchase")
          );

          navigate(`/importPurchase/update/${createdId}/${values.branchId}`);
        })
        .catch(() => { })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    }
    dispatch(setLoadingState(false));
  };

  useEffect(() => {
    dispatch(getItemsWithFilters([]));
    dispatch(getClassifiersByTypeAsync("EntryItems"));
    dispatch(getEntitiesByTypeAsync(true));
    dispatch(getCurrenciesAsync());
    dispatch(getUserBranches());
    dispatch(getDocumentTypesWithSignHAsync("HI"));
    dispatch(getExpensTypesAsync());
    dispatch(getTaxesAsync())
  }, [dispatch, mode, id]);
  const [tab, setTab] = useState<any>(null);

  useEffect(() => {
    const updateIndexedDB = async () => {
      setTab(await db.tabs.get(`importPurchase/update/${id}`));

      const tab = await db.tabs.get(
        mode === "update"
          ? `importPurchase/update/${id}/${branchId}`
          : "importPurchase/register"
      );
      if (tab) {
        await db.tabs.put({
          ...tab,
          data: {
            ...tab.data,
            details: importPurchaseDetails,
          },
        });
      }
    };

    updateIndexedDB();
  }, [importPurchaseDetails]);

  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = await validateForm();
    setTouched({
      branchId: true,
      documentTypeId: true,
      supplierId: true,
      documentNo: true,
      date: true,
      invoiceDate: true,
      plannedDate: true,
      supplierInvoiceNo: true,
      aymentDeadline: true,
      currencyId: true,
      exchangeRate: true,
      k45: true,
      k46: true,
      k47: true,
      k48: true,
      k49: true,
      k50: true,
      k51: true,
      k52: true,
      k53: true,
      k54: true,
      customDocNo: true,
      comment: true,
      filesUploaded: true,
      verified: true,
      fromDate: true,
      toDate: true,
      generatedId: true,
      details: true,
      correctionId: true,
      validated: true,
      invoiceNo: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };
  const handleImportPurchaseValidation = async (
    entryItemsId: string,
    branchId: string
  ) => {
    const result = await dispatch(
      validateImportPurchaseAsync({
        entryItemId: entryItemsId,
        branchID: branchId ?? "",
      })
    );
    if (result.type === "ImportPurchases/validateImportPurchase/fulfilled") {
      toast.success(t("validatedSuccessfully"));
      formikRef.current.setFieldValue("validated", true);
    } else {
      toast.error(t("ValidationFailed"));
    }
  };
  const handleImportPurchaseCorrection = async (
    entryItemsId: string,
    generatedId: string,
    branchId: number
  ) => {
    const result = await dispatch(
      correctImportPurchaseAsync({
        entryItemsId: entryItemsId,
        generatedId: generatedId,
        branchID: branchId,
      })
    );
    if (result.type === "ImportPurchases/correctImportPurchase/fulfilled") {
      const newDocumentNo = result.payload;
      await db.updateTab(
        `importPurchase/update/${entryItemsId}`,
        `importPurchase/update/${newDocumentNo}`,
        t("tabs.updateImportPurchase")
      );
      toast.success(t("correctedSuccessfully"));
      navigate(`/importPurchase/update/${newDocumentNo}/${branchId}`);
    } else {
      toast.error(t("correctionFailed"));
    }
  };
  const handleImportPurchaseInvalidation = async (
    entryItemsId: string,
    branchId: number
  ) => {
    const result = await dispatch(
      invalidateImportPurchaseAsync({
        entryItemId: entryItemsId,
        branchID: branchId,
      })
    );
    if (result.type === "ImportPurchases/invalidateImportPurchases/fulfilled") {
      toast.success(t("invalidatedSuccessfully"));
      formikRef.current.setFieldValue("validated", false);
    } else {
      toast.error(t("invalidationFailed"));
    }
  };

  const isInvalidateButtonDisabled = (
    values: CreateAndEditImportPurchaseDto
  ) => {
    return isLoading || !values.validated;
  };
  const copyDetails = () => {
    const details: CopiedDocumentDetailsTable<CreateAndEditImportPurchaseDetailDto> = {
      id: "importPurchasesDetails",
      list: importPurchaseDetails
    }
    db.upsertGenericData(details);
  }

  const handlePrintPdf = async (
    entryItemsId: string,
  ) => {
    const result = await dispatch(
      handlePrintImportPurchaseForm(
         entryItemsId
      )
    );
    if (result.type === "ImportPurchase/handlePrintForm/fulfilled") {
      printBase64PDF(result.payload as string);
    } else {
      toast.error("error");
    }
  };

  const isHandlePrintDisabled = (
    values: CreateAndEditImportPurchaseDto
  ) =>{
    return isLoading || !values.validated;
  };
  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({} as CreateAndEditImportPurchaseDto)}
        validationSchema={validationSchema}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          handleSubmit,
          submitForm,
          validateForm,
          setTouched,
          setFieldValue,
        }) => (
          <>
            <FormHeaderOptions
              title={
                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
              }
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createButton")}
              submitButtonIsDisabled={
                isLoading ||
                importPurchaseDetails.filter(
                  (detail) => detail.rowAction !== "D"
                ).length === 0 ||
                values.validated
              }
              createAccessEnum={MenuOptionEnum.ImportPurchasesCreate}
              validateAccessEnum={MenuOptionEnum.ImportPurchasesValidate}
              validateButtonIsDisabled={
                isLoading || mode !== "update" || values.validated
              }
              validateButtonText={t("validateButton")}
              handleDocumentValidation={async () => {
                if (values.entryItemsId) {
                  await handleImportPurchaseValidation(
                    values.entryItemsId.toString(),
                    values?.branchId?.toString() ?? ""
                  );
                }
              }}
              correctionButtonIsDisabled={
                isLoading || mode !== "update" || !values.validated
              }
              correctionAccessEnum={MenuOptionEnum.ImportPurchasesStorno}
              correctionButtonText={t("correctButton")}
              handleDocumentCorrection={async () => {
                if (values.entryItemsId && generatedIdForCorrection) {
                  await handleImportPurchaseCorrection(
                    values.entryItemsId.toString(),
                    generatedIdForCorrection,
                    values?.branchId ?? 0
                  );
                }
              }}
              invalidateAccessEnum={MenuOptionEnum.ImportPurchasesInvalidate}
              invalidateButtonText={t("invalidateButton")}
              invalidateButtonIsDisabled={isInvalidateButtonDisabled(values)}
              handleDocumentInvalidation={async () => {
                if (values.entryItemsId) {
                  await handleImportPurchaseInvalidation(
                    values.entryItemsId ?? "",
                    values?.branchId ?? 0
                  );
                }
              }}
              copyButtonIsDisabled={
                isLoading || mode !== "update" || importPurchaseDetails.length === 0
              }
              copyButtonText={t("CopyButton")}
              handleCopyDetails={mode === "update" ? () => copyDetails() : undefined}
              
              printAccessEnum={MenuOptionEnum.ImportPurchasesPrint}
              handlePrintText={t("printButton")}
              handlePrintIsDisabled={isHandlePrintDisabled(values)}
              handlePrintForm={mode === "update" ? async () => {
                if(values.entryItemsId){
                  await handlePrintPdf(
                    values.entryItemsId
                  );
                }
              } : undefined}
            />

            <Form onFinish={handleSubmit} layout="vertical">
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: t("importPurchase"),
                    key: "1",
                    children: (
                      <MainFieldsComponent
                        supplierOptions={supplierOptions}
                        values={values}
                        setImportPurchaseDetails={setImportPurchaseDetails}
                        importPurchaseDetails={importPurchaseDetails}
                        addImportPurchaseDetail={addImportPurchaseDetail}
                        removePurchaseDetail={removePurchaseDetail}
                        mode={mode ?? ""}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                  {
                    label: t("classifiers"),
                    key: "2",
                    children: (
                      <ImportPurchaseClassifiersComponent
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                  {
                    label: t("expenses"),
                    key: "3",
                    children: (
                      <ExpensesFieldsComponent
                        values={values}
                        getExpenseDetail={getExpenseDetail}
                        setExpenseDetails={setExpensesDetails}
                        expensesDetails={expensesDetails}
                        addExpensesDetail={addExpensesDetail}
                        removeExpensesDetail={removeExpensesDetail}
                        mode={mode ?? ""}
                        supplierOptions={supplierOptions}
                        setFieldValue={setFieldValue}
                        setSupplierOptions={setSupplierOptions}
                      />
                    ),
                  },
                  {
                    label: t("other"),
                    key: "4",
                    children: (
                      <OtherFieldsComponent
                        values={values}
                        uploadedFiles={uploadedFiles}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                ]}
              />

              <HandleFormDataForTabSaving
                tabPath={
                  mode === "update"
                    ? `importPurchase/update/${id}/${branchId}`
                    : "importPurchase/register"
                }
                additionalStates={{
                  importPurchaseDetails: importPurchaseDetails,
                  goodsImportExpenses: expensesDetails,
                }}
              />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
}
