import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/ItemStatus/ItemStatusState";
import { ItemStatusState } from "../../models/clientDashboard/ItemStatus/ItemStatusState";
import { ItemStatus } from "../../models/clientDashboard/ItemStatus/ItemStatus";
import { RootState } from "..";

const initialState: ItemStatusState = {
    itemStatuses: [],
    error: null,
};

const ItemStatusSlice = createSlice({
    name: "itemStatus",
    initialState,
    reducers: {
        getItemStatusesStart(state: ItemStatusState) {
            setLoading(true);
            state.error = null;
        },
        getItemStatusesSuccess(
            state: ItemStatusState,
            action: PayloadAction<ItemStatus[]>
        ) {
            setLoading(false);
            state.itemStatuses = action.payload;
        },
        getItemStatusesFailure(
            state: ItemStatusState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        deleteItemStatusFromState(state: ItemStatusState, action: PayloadAction<number>) {
            setLoading(false);
            const itemStatusId = action.payload;
            state.itemStatuses = state.itemStatuses.filter(itemStatus => itemStatus.itemStatusId !== itemStatusId);
        },
    }
});

export const getItemStatusesAsync = createAsyncThunk(
    "ItemStatuses/getItemStatuses",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const itemStatuses = state.itemStatus.itemStatuses;

        if (itemStatuses.length > 0) {
            return itemStatuses;
        }

        try {
            dispatch(getItemStatusesStart());
            const response = await apiService.get<ApiResponse<ItemStatus[]>>(
                "api/Items/statuses"
            );
            // toast.success("Item statuses were successfully retrieved!");
            dispatch(getItemStatusesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getItemStatusesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getItemStatusDataById = createAsyncThunk(
    "ItemStatuses/getItemStatusDataById",
    async (itemStatusId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<ItemStatus>>(
                `/api/ItemStatuses/${itemStatusId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteItemStatusAsync = createAsyncThunk(
    "ItemStatuses/deleteItemStatus",
    async (itemStatusId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/ItemStatuses/${itemStatusId}`
            );
            dispatch(deleteItemStatusFromState(itemStatusId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getItemStatusesStart,
    getItemStatusesSuccess,
    getItemStatusesFailure,
    deleteItemStatusFromState
} = ItemStatusSlice.actions;

export default ItemStatusSlice.reducer;