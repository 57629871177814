import { AgGridReact } from "ag-grid-react";
import { CompanySubscription } from "../../../../models/dashboard/Company/SingleCompany/CompanyDetails";
import { ColDef } from "ag-grid-community";
import { Button, Tooltip, Typography } from "antd";
import { formatDateTimeAsDDMMYYYY } from "../../../../helperMethods/formatDate";
import { DeleteOutlined } from "@ant-design/icons";
import { formatDateAsDDMMYYYY } from "../../../../helperMethods/formatDate";
import { Empty } from "antd";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
const { Title, Text, Link } = Typography;

interface CompanySubscriptionsComponentProps {
  subscriptions: CompanySubscription[];
}

const CompanySubscriptionsComponent: React.FC<
  CompanySubscriptionsComponentProps
> = ({ subscriptions }) => {

  // based on CompanySubscription interface create column defs, show all of them in the grid
  const columnDefs: ColDef[] = [
    {
      headerName: "#",
      field: "subscriptionInvoiceId",
      sortable: true,
      filter: true,
      width:70
    },
    {
      headerName: "Plan Name",
      field: "subscriptionPlanName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Start Date",
      field: "subscriptionStartDate",
      sortable: true,
      filter: true,
      valueFormatter: (params) =>
        formatDateTimeAsDDMMYYYY(params.value),
    },
    {
      headerName: "End Date",
      field: "subscriptionEndDate",
      sortable: true,
      filter: true,
      valueFormatter: (params) =>
        formatDateTimeAsDDMMYYYY(params.value),
    },
    {
      headerName: "Active",
      field: "isActive",
      sortable: true,
      filter: true,
      valueFormatter: (params) => (params.value ? "Yes" : "No"),
      width: 90
    },
    {
      headerName: "Currency",
      field: "currencyCode",
      sortable: true,
      filter: true,
      width: 70
    },
    {
      headerName: "Number of Users",
      field: "numOfUsers",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Price per User",
      field: "pricePerUser",
      sortable: true,
      filter: true,
    },
    {
      headerName: "VAT",
      field: "vat",
      sortable: true,
      filter: true,
      width: 90
    },
    {
      headerName: "Entry Date",
      field: "entryDate",
      sortable: true,
      filter: true,
      valueFormatter: (params) =>
        formatDateTimeAsDDMMYYYY(params.value),
    },
    {
      headerName: "Days Until Expiration",
      field: "daysUntilExpiration",
      sortable: true,
      filter: true,
    },
  ];


  const getRowStyle = (params: any) => {
    if (params.data.isActive) {
      return { backgroundColor: "lightgreen" };
    } else {
      return { backgroundColor: "lightcoral" };
    }
  };

  const defaultColDef = {
    sortable: true,
    resizable: true,
  };

  return (
    <>
      <div
        className="ag-theme-balham"
        style={{ height: "500px", width: "100%" }}
      >
        {subscriptions.length > 0 ? (
          // <AgGridReact
          //   rowData={subscriptions}
          //   columnDefs={columnDefs}
          //   defaultColDef={defaultColDef}
          //   pagination={true}
          //   paginationPageSize={50}
          //   getRowStyle={getRowStyle}
          // />
          <AgGridTableComponent
          defaultColDef={columnDefs}
          isLoading={false}
          rowData={subscriptions}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={20}
        />
        ) : (
          <Empty description="Ska abonime për të listuar" />
        )}
      </div>
    </>
  );
};

export default CompanySubscriptionsComponent;
