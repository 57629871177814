import { RefObject } from "react";
import { suppressEnter } from "../../../../helperMethods/suppressEnter";
import { CreateOrUpdateCompositeItemDto } from "../../../../models/clientDashboard/Item/CreateOrUpdateCompositeItemDto";
import Button from "antd/es/button";
import { DeleteOutlined } from "@ant-design/icons";
import { ColDef } from "ag-grid-community";

export const getCompositeItemColumns = (
    deleteImportPurchaseDetail: (index: number) => void,
    t: (text: string) => string,
    mode:string
  ) => {

  
    const columnDefs: ColDef<CreateOrUpdateCompositeItemDto>[] = [
      {
        headerName: t("tableHeaders.itemId"),
        field: "itemId",
        sortable: false,
        filter: false,
        suppressMovable: true,
        singleClickEdit: true,
        suppressMenu: true,
      },
      {
        headerName: t("tableHeaders.itemDescription"),
        field: "itemDescription",
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.quantityCoefficient"),
        field: "quantityCoefficient",
        sortable: false,
        filter: false,
        suppressMovable: true,
        editable: true,
        suppressKeyboardEvent: (params: any) => {
          return suppressEnter(params);
        },
        suppressMenu: true,
        valueSetter: (params: any) => {
          const newValue = Number(params.newValue);
          if (
            mode === "update" &&
            params.data.entryItemsDetailsId !== 0 &&
            params.data.entryItemsDetailsId !== undefined &&
            params.data.entryItemsDetailsId !== null
          ) {
            params.data.rowAction = "U";
          }
          if(newValue <= 0){
            params.data.quantityCoefficient = 1;
            return true;
          }
          if (newValue > 0) {
            params.data.quantityCoefficient = newValue;
            return true;
          } else {
            return false; 
          }
        }
      },
      {
        headerName: t("tableHeaders.options"),
        suppressMenu: true,
        maxWidth: 80,
        cellRenderer: (params: any) => {
          return (
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => deleteImportPurchaseDetail(params.rowIndex)}
              style={{ color: "#007FFF"}}
            ></Button>
          );
        },
      },
    ];
    return columnDefs;
  };
  