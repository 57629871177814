import { ColDef } from "ag-grid-community";
import { PricesDetail } from "../../../../models/clientDashboard/ItemPricesDetail/PricesDetail/PricesDetail";
import { RefObject } from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Branch } from "../../../../models/clientDashboard/Branch/Branch";
import { BranchPricesDetail } from "../../../../models/clientDashboard/ItemPricesDetail/PricesDetail/BranchPricesDetail";
import { toast } from "react-toastify";

export const getPriceDetailColumns = (
  removePriceDetail: (index: number) => void,
  t: (text: string) => string,
  searchItemInputRef: RefObject<any>,
  mode: string,
  branches: BranchPricesDetail[],
  fromLocalPurchase?: boolean
) => {
  const columnDefs: ColDef<PricesDetail>[] = [
    {
      headerName: t("tableHeaders.itemId"),
      field: "itemId",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.itemDescription"),
      field: "itemDescription",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.purchasePrice"),
      field: "purchasePrice",
      sortable: false,
      filter: false,
      maxWidth: 100,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.discount"),
      field: "discount",
      editable: true,
      sortable: false,
      filter: false,
      maxWidth: 80,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
      valueSetter: (params) => {
        const newValue = params.newValue;
        if (isNaN(newValue) || newValue < 0) {
          toast.error(t("discountNegative"));
          return false;
        }
        params.data.discount = newValue;
        return true;
      }
    },
    {
      headerName: t("tableHeaders.extraDiscount"),
      field: "extraDiscount",
      sortable: false,
      filter: false,
      maxWidth: 100,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
      editable: true,
      valueSetter: (params) => {
        const newValue = params.newValue;
        if (isNaN(newValue) || newValue < 0) {
          toast.error(t("extraDiscountNegative"));
          return false;
        }
        params.data.extraDiscount = newValue;
        return true;
      }
    },
    {
      headerName: t("tableHeaders.wholeSalePrice"),
      field: "wholeSalePrice",
      sortable: false,
      filter: false,
      maxWidth: 140,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.salesPrice"),
      sortable: false,
      filter: false,
      maxWidth: 140,
      suppressMovable: true,
      singleClickEdit: true,
      editable: true,
      suppressMenu: true,
      valueSetter: (params) => {
        const newValue = params.newValue;
        if (isNaN(newValue) || newValue < 0) {
          toast.error(t("saleNegative"));
          return false;
        }
        branches?.map((branch, index) => {
          const branchDetail = params.data?.branchPriceDetails?.find(
            (branchPriceDetail) =>
              branchPriceDetail.branchId === branch.branchId
          );
          if (branchDetail) branchDetail.branchPrice = newValue;
        });
        if (
          mode === "update" &&
          params?.data?.pricesDetailsId !== 0 &&
          params?.data?.pricesDetailsId !== undefined &&
          params?.data?.pricesDetailsId !== null
        ) {
          params.data.rowAction = "U";
        }
        return true;
      },
      // valueGetter: (params) => {
      //   const branchIndex = branches.findIndex(branch => branch.branchId === params?.data?.branchPriceDetails[0]?.branchId);
      //   if (branchIndex !== -1) {
      //     return params?.data?.branchPriceDetails[branchIndex]?.branchPrice;
      //   }
      // },
    },
  ];

  branches.forEach((branch, index) => {
    columnDefs.push({
      headerName: branch.branchName || `Branch ${index + 1}`,
      valueGetter: (params) => {
        if (
          params.data &&
          params.data.branchPriceDetails &&
          Array.isArray(params.data.branchPriceDetails)
        ) {
          return params.data.branchPriceDetails[index]?.branchPrice ?? 0;
        }
        return 0;
      },

      valueSetter: (params) => {
        const newValue = parseFloat(params.newValue);

        if (isNaN(newValue) || newValue < 0) {
          return false;
        }
        const branchPriceDetail = params.data.branchPriceDetails[index];
        if (branchPriceDetail) {
          branchPriceDetail.branchPrice = params.newValue;
          if (
            mode === "update" &&
            params.data.pricesDetailsId !== 0 &&
            params.data.pricesDetailsId !== undefined &&
            params.data.pricesDetailsId !== null
          ) {
            params.data.rowAction = "U";
          }
          return true;
        }
        return false;
      },
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      minWidth: 150,
      suppressMenu: true,
      editable: true,
    });
  });
  columnDefs.push({
    headerName: t("tableHeaders.options"),
    suppressMenu: true,
    hide: fromLocalPurchase,
    maxWidth: 100,
    cellRenderer: (params: any) => {
      return (
        <Button
          type="text"
          icon={<DeleteOutlined />}
          onClick={() => removePriceDetail(params.rowIndex)}
          style={{ color: "#007FFF" }}
        ></Button>
      );
    },
  });

  return columnDefs;
};
