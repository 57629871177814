import { Divider, Form, Spin, Tabs } from "antd";
import { Formik, FormikErrors } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Title from "antd/es/typography/Title";
import MainFormCreateItemComponent from "./CreateItemComponents/MainFormCreateItemComponent";
import { getUnitMeasuresAsync } from "../../../redux/slices/unitMeasureSlice";
import { getBrandsAsync } from "../../../redux/slices/brandSlice";
import { getTaxesAsync } from "../../../redux/slices/taxSlice";
import { getCustomsAsync } from "../../../redux/slices/customSlice";
import { getItemStatusesAsync } from "../../../redux/slices/itemStatusSlice";
import BarcodeListComponent from "./CreateItemComponents/BarcodeListComponent";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import ItemDescriptionListComponent from "./CreateItemComponents/ItemDescriptionListComponent";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import ItemClassifiersComponent from "./CreateItemComponents/ItemClassifiersComponent";
import { getClassifiersByTypeAsync } from "../../../redux/slices/classifierSlice";
import { getItemTypesAsync } from "../../../redux/slices/itemTypeSlice";
import { getLanguagesAsync } from "../../../redux/slices/languageSlice";
import { ItemModel } from "../../../models/clientDashboard/Item/ItemModel";
import { Barcode } from "../../../models/clientDashboard/Barcode/Barcode";
import { ItemDescription } from "../../../models/clientDashboard/ItemDescription/ItemDescription";
import { convertClassifierValueToObject } from "../../../helperMethods/convertClassifierValueToObject";
import { ItemsClassifierDto } from "../../../models/clientDashboard/Classifier/ItemClassifierDto";
import { categories } from "./ItemClassifiersMapped";
import { getEntitiesByTypeAsync } from "../../../redux/slices/entitySlice";
import * as Yup from "yup";
import { ItemValidationSchema } from "./CreateItemComponents/ItemValidationSchema";
import useFormData from "../../../customHooks/useFormData";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import { db } from "../../../indexDB/clientSideDatabase";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { ItemTypeEnum } from "../../../enums/ItemTypesEnum";
import CompositeItemListComponent from "./CreateItemComponents/CompositeItemListComponent";
import { CreateOrUpdateCompositeItemDto } from "../../../models/clientDashboard/Item/CreateOrUpdateCompositeItemDto";
import OtherFormCreateItemComponent from "./CreateItemComponents/OtherFormCreateItemComponent";
import { SelectOption } from "../../../models/SelectOption";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";

export default function CreateItem() {
  const navigate = useNavigate();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.CreateItem");
  // const [initialValues, setInitialValues] = useState<ItemModel>({
  //   statusId: 1,
  // } as ItemModel);
  const formikRef = useRef<any>(null);
  const location = useLocation();
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
  const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
    setSupplierOptions(options);
  };

  const [itemDescriptions, setItemDescriptions] = useState<ItemDescription[]>(
    []
  );

  const [barcodes, setBarcodes] = useState<Barcode[]>([]);
  const [compositeItems, setCompositeItems] = useState<CreateOrUpdateCompositeItemDto[]>([]);

  const setAdditionalStates = useCallback((data: ItemModel) => {
    if (data.itemDescriptions) {
      setItemDescriptions(data.itemDescriptions);
    }
    if (data.barcodes) {
      setBarcodes(data.barcodes);
    }
    if (data.compositeItems) {
      setCompositeItems(data.compositeItems)
    }
    if (data) {
      setSupplierOptions([
        {
          key: data.primarySupplierID?.toString(),
          label: data.primarySupplierName ?? "",
          value: data.primarySupplierID ?? 0,
        },
        ...(data.secondarySupplierID && data.secondarySupplierID !== 0
          ? [{
            key: data.secondarySupplierID?.toString(),
            label: data.secondarySupplierName ?? "",
            value: data.secondarySupplierID ?? 0,
          }]
          : []),
      ]);
    }
  }, []);

  const { initialValues } = useFormData<ItemModel>(
    'item/register',
    { statusId: 1, packaging: 1 } as ItemModel,
    setAdditionalStates,
  );
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const updateCompositeItem = (
    updatedCompositeItem: CreateOrUpdateCompositeItemDto[]
  ) => {
    setCompositeItems(updatedCompositeItem);
  };

  const addNewCompositeItem = (compositeItemDescriptions: CreateOrUpdateCompositeItemDto) => {
    setCompositeItems((prevCompositeItemDescriptions) => {
      const updatedCompositeItemDescriptions = [...prevCompositeItemDescriptions];
      updatedCompositeItemDescriptions.push(compositeItemDescriptions);
      return updatedCompositeItemDescriptions;
    });
  };
  const removeCompositeItem = (index: number) => {
    setCompositeItems((prevCompositeItems) => {
      const activeCompositeItems = prevCompositeItems.filter(
        (compositeItem) => compositeItem.rowAction !== 'D'
      );
      const selectedCompositeItem = activeCompositeItems[index];
      if (
        selectedCompositeItem.compositeItemId === 0 ||
        !selectedCompositeItem.compositeItemId
      ) {
        prevCompositeItems.splice(index, 1);
      } else {
        selectedCompositeItem.rowAction = 'D';
      }
      return [...prevCompositeItems];
    });
  };

  const updatedItemDescriptions = (
    updatedItemDescription: ItemDescription[]
  ) => {
    setItemDescriptions(updatedItemDescription);
  };

  const addNewItemDescription = (itemDescription: ItemDescription) => {
    setItemDescriptions((prevItemDescriptions) => {
      const updatedItemDescriptions = [...prevItemDescriptions];
      updatedItemDescriptions.push(itemDescription);
      return updatedItemDescriptions;
    });
  };
  const removeItemDescription = (index: number) => {
    setItemDescriptions((prevItemDescriptions) => {
      const activeItemDescriptions = prevItemDescriptions.filter(
        (itemDescription) => itemDescription.isDeleted === false
      );
      const selectedItemDescription = activeItemDescriptions[index];
      if (
        selectedItemDescription.itemDescriptionId === 0 ||
        !selectedItemDescription.itemDescriptionId
      ) {
        prevItemDescriptions.splice(index, 1);
      } else {
        selectedItemDescription.isDeleted = true;
      }
      return [...prevItemDescriptions];
    });
  };
  const updateBarcodes = (barcodes: Barcode[]) => {
    setBarcodes(barcodes);
  };

  const addNewBarcode = (barcode: Barcode) => {
    setBarcodes((prevBarcodes) => {
      const updatedBarcodes = [...prevBarcodes];
      updatedBarcodes.push(barcode);
      return updatedBarcodes;
    });
  };

  const removeBarcode = (index: number) => {
    setBarcodes((prevBarcodes) => {
      const activeBarcodes = prevBarcodes.filter(
        (barcode) => !barcode.isDeleted
      );

      if (index >= 0 && index < activeBarcodes.length) {
        const selectedBarcode = activeBarcodes[index];
        const isPrimary = selectedBarcode.primaryBarcode;

        if (selectedBarcode.barcodeId === 0) {
          const originalIndex = prevBarcodes.findIndex(
            (barcode) => barcode === selectedBarcode
          );
          if (originalIndex !== -1) {
            prevBarcodes.splice(originalIndex, 1);
          }
        } else {
          selectedBarcode.isDeleted = true;
        }

        if (isPrimary) {
          const firstNonDeletedBarcode = prevBarcodes.find(
            (barcode) => !barcode.isDeleted
          );

          if (firstNonDeletedBarcode) {
            firstNonDeletedBarcode.primaryBarcode = true;
          }
        }
      }

      return [...prevBarcodes];
    });
  };

  const convertCategoryValue = (
    values: ItemModel,
    categoryKey: keyof ItemModel,
    classifierDescription: string
  ) => {
    if (
      values[categoryKey] !== undefined &&
      values[categoryKey] !== null &&
      (typeof values[categoryKey] !== "object" ||
        Array.isArray(values[categoryKey]))
    ) {
      const classifier = classifiers.find(
        (c) => c.description === classifierDescription
      );
      if (classifier) {
        const classifierId = classifier.classifierId;
        const detail = classifier.classifierDetails?.find(
          (cd) => cd.classifierDetailId === values[categoryKey]
        );
        const categoryDescription =
          detail?.description ?? (values[categoryKey] as string);
        const categoryDetailId = detail?.classifierDetailId;
        values[categoryKey] = convertClassifierValueToObject(
          classifierId,
          categoryDescription.toString(),
          categoryDetailId
        ) as ItemsClassifierDto;
      }
    } else if (typeof values[categoryKey] === "object"){
      console.log( values[categoryKey])
      const classifier = classifiers.find(
        (c) => c.description === classifierDescription
      );
      if (classifier) {
        values[categoryKey] = convertClassifierValueToObject(
          classifier?.classifierId,
          values[categoryKey].label,
          values[categoryKey].value
        )
      }
    }
  };

  const onFinish = async (values: ItemModel) => {

    categories.forEach(({ key, description }) => {
      convertCategoryValue(values, key, description);
    });





    values.barcodes = barcodes;
    const filteredItemDescriptions = itemDescriptions.filter(
      (itemDescription) =>
        itemDescription.description !== undefined &&
        itemDescription.description !== null &&
        itemDescription.description.trim() !== "" &&
        itemDescription.language !== undefined &&
        itemDescription.language !== null &&
        itemDescription.language.trim() !== ""
    );

    values.descriptions = filteredItemDescriptions;
    values.primarySupplierID =
      values.primarySupplierID === 0 ? null : values.primarySupplierID;
    values.secondarySupplierID =
      values.secondarySupplierID === 0 ? null : values.secondarySupplierID;
    if (values.itemTypeId === ItemTypeEnum.processedItem) {
      values.compositeItems = compositeItems
    }
    await apiService
      .post("/api/Items", values)
      .then(async (response) => {
        toast.success(t("itemCreatedSuccessfully"));
        await db.tabs.delete("item/register");
        navigate("/items");
      })
      .catch(() => { })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  useEffect(() => {
    dispatch(getUnitMeasuresAsync());
    dispatch(getItemTypesAsync());
    dispatch(getBrandsAsync());
    dispatch(getTaxesAsync());
    dispatch(getCustomsAsync());
    dispatch(getItemStatusesAsync());
    dispatch(getClassifiersByTypeAsync("Products"));
    dispatch(getLanguagesAsync());
    dispatch(getEntitiesByTypeAsync(true));
  }, [dispatch]);
  const validationSchema = ItemValidationSchema();

  useEffect(() => {
    const updateIndexedDB = async () => {
      const tab = await db.tabs.get('item/register');
      if (tab) {
        await db.tabs.put({
          ...tab,
          data: {
            ...tab.data,
            descriptions: itemDescriptions,
            barcodes: barcodes
          }
        });
      }
    };
    updateIndexedDB();
  }, [itemDescriptions, barcodes]);

  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (
      values?: any
    ) => Promise<FormikErrors<ItemModel>>
  ) => {
    const errors = await validateForm();
    setTouched({
      description: true,
      customId: true,
      itemTypeId: true,
      statusId: true,
      unitId: true,
      brandId: true,
      taxId: true,
      primarySupplierID: true,
      category1: true,
      category2: true,
      category3: true,
      category4: true,
      k10: true,
      k11: true,
      k12: true,
      k13: true,
      k14: true,
      k15: true,
      k16: true,
      k17: true,
      k18: true,
      k19: true,
      k20: true,
      k21: true,
      k22: true,
      k23: true,
      k24: true,
      k5: true,
      k6: true,
      k7: true,
      k8: true,
      k9: true,
    });

    return errors;
  };

  const handleValidation = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      return {};
    } catch (err: any) {
      const validationErrors: { [key: string]: string } = {};
      err.inner.forEach((error: any) => {
        validationErrors[error.path] = error.message;
        toast.error(error.message);
      });
      return validationErrors;
    }
  };
  const memoizedRowData = useMemo(() => {
    return barcodes.filter((detail) => detail.isDeleted === false);
  }, [barcodes]);

  const memoizedRowData2 = useMemo(() => {
    return itemDescriptions.filter((detail) => detail.isDeleted === false);
  }, [itemDescriptions]);

  return (
    <Spin tip="Loading..." spinning={isLoading}>

      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? { statusId: 1 } as ItemModel}
        validationSchema={ItemValidationSchema()}
        onSubmit={onFinish}
        enableReinitialize
        validate={handleValidation}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, handleSubmit, submitForm, validateForm, setTouched, setFieldValue }) => {
          return (
            <Form onFinish={handleSubmit} layout="vertical">
              <FormHeaderOptions
                title={t("createFormTitle")}
                handleSubmitForm={submitForm}
                handleSubmitValidation={async () => {
                  handleSubmitValidationForm(setTouched, validateForm);
                }}
                submitButtonText={t("createItemButton")}
                submitButtonIsDisabled={isLoading}
                createAccessEnum={MenuOptionEnum.ItemsCreate}
                />
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: t("item"),
                    key: "1",
                    children: (
                      <MainFormCreateItemComponent
                        supplierOptions={supplierOptions}
                        item={values}
                        removeItemDescription={removeItemDescription}
                        updateItemDescriptions={updatedItemDescriptions}
                        addNewItemDescription={addNewItemDescription}
                        removeBarcode={removeBarcode}
                        updateBarcodes={updateBarcodes}
                        addNewBarcode={addNewBarcode}
                        itemDescriptions={memoizedRowData2}
                        barcodes={memoizedRowData}
                        formikRef={formikRef}
                        setFieldValue={setFieldValue} mode="create"/>
                    ),
                  },
                  {
                    label: t("classifiers"),
                    key: "2",
                    children:
                      <ItemClassifiersComponent
                        mode="create"
                        item={values}
                        setFieldValue={setFieldValue}
                      />,
                  },

                  ...(values.itemTypeId === ItemTypeEnum.processedItem ? [
                    {
                      label: t("compositeItems"),
                      key: "3",
                      children: <CompositeItemListComponent
                        values={values}
                        compositeItems={compositeItems}
                        removeCompositeItem={removeCompositeItem}
                        updateCompositeItem={updateCompositeItem}
                        addNewCompositeItem={addNewCompositeItem}
                        mode="create" />,
                    }
                  ] : []),
                  {
                    label: t("others"),
                    key: "4",
                    children: (
                      <OtherFormCreateItemComponent
                        item={values}
                        addNewItemDescription={addNewItemDescription}
                        removeItemDescription={removeItemDescription}
                        updatedItemDescriptions={updatedItemDescriptions}
                        itemDescriptions={memoizedRowData2}
                        formikRef={formikRef}
                        setFieldValue={setFieldValue} />
                    ),
                  },
                ]} />
              <HandleFormDataForTabSaving tabPath="item/register" additionalStates={{ descriptions: itemDescriptions, barcodes: barcodes, compositeItems: compositeItems }} />

            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
}
