import { Col, Descriptions, Divider, Form, Row, Spin, Tabs } from "antd";
import { Formik, FormikErrors } from "formik";
import FormSubmitButton from "../../CustomComponents/FormSubmitButton";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import { CreateAndEditLocalPurchaseDto } from "../../../models/clientDashboard/LocalPurchase/CreateAndEditLocalPurchaseDto";
import Title from "antd/es/typography/Title";
import { db } from "../../../indexDB/clientSideDatabase";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { CreateAndEditLocalPurchaseDetailDto } from "../../../models/clientDashboard/LocalPurchaseDetail/CreateAndEditLocalPurchaseDetailDto";
import useFormData from "../../../customHooks/useFormData";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import OtherFieldsComponent from "./CreateLocalPurchaseComponents/OtherFieldsComponent";
import MainFieldsComponent from "./CreateLocalPurchaseComponents/MainFieldsComponent";
import { getEntitiesByTypeAsync } from "../../../redux/slices/entitySlice";
import { getClassifiersByTypeAsync } from "../../../redux/slices/classifierSlice";
import * as Yup from "yup";
import { getInvoiceWithRemarksReasonsAsync } from "../../../redux/slices/invoiceWithRemarksReasonSlice";
import { getCurrenciesAsync } from "../../../redux/slices/currencySlice";
import { getGoodsReturnReasonsAsync } from "../../../redux/slices/goodsReturnReasonSlice";
import { getDocumentTypesWithSignHAsync } from "../../../redux/slices/documentTypeSlice";
import {
  correctLocalPurchaseAsync,
  getLocalPurchaseDataById,
  handlePrintLocalPurchaseForm,
  invalidateLocalPurchaseAsync,
  validateLocalPurchaseAsync,
} from "../../../redux/slices/localPurchaseSlice";
import FormSwitch from "../../CustomComponents/FormSwitch";
import { getBranchesAsync } from "../../../redux/slices/branchSlice";
import { getUserBranches } from "../../../redux/slices/userSlice";
import { ItemsClassifierDto } from "../../../models/clientDashboard/Classifier/ItemClassifierDto";
import { convertClassifierValueToObject } from "../../../helperMethods/convertClassifierValueToObject";
import { entryItemCategories } from "../Entities/EntityClassifiersMapped";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { CreateAndUpdateGoodsImportExpenseDto } from "../../../models/clientDashboard/GoodsImportExpense/CreateAndUpdateGoodsImportExpenseDto";
import ExpensesFieldsComponent from "./CreateLocalPurchaseComponents/ExpenseFieldsComponent";
import { getExpensTypesAsync } from "../../../redux/slices/expenseTypesSlice";
import LocalPurchaseClassifiersComponent from "./CreateLocalPurchaseComponents/LocalPurchaseClassifiersComponent";
import { getTaxesAsync } from "../../../redux/slices/taxSlice";
import { PricesDetail } from "../../../models/clientDashboard/ItemPricesDetail/PricesDetail/PricesDetail";
import { BranchPricesDetail } from "../../../models/clientDashboard/ItemPricesDetail/PricesDetail/BranchPricesDetail";
import { SelectOption } from "../../../models/SelectOption";
import { CopiedDocumentDetailsTable } from "../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { LocalPurchaseDetail } from "../../../models/clientDashboard/LocalPurchaseDetail/LocalPurchaseDetail";
import { ConfigurationEnum } from "../../../enums/ConfigurationEnum";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import printBase64PDF from "../../../helperMethods/printBase64PDF";

export default function CreateLocalPurchase() {
  const navigate = useNavigate();
  const { mode, id, branchId } = useParams<{
    mode: string;
    id?: string;
    branchId?: string;
  }>();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.CreateLocalPurchase");
  const formikRef = useRef<any>(null);
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const [localPurchaseDetails, setLocalPurchaseDetails] = useState<
    CreateAndEditLocalPurchaseDetailDto[]
  >([]);
  const configurations = useSelector(
    (state: RootState) => state.configuration.configurationValuesFromToken
  );
  const purchaseConfig = configurations.find(
    (config) =>
      config.configurationId ===
      ConfigurationEnum.Mundesovendosjenecmimeveteshitjestekthyrjaemallit
  );
  const [itemPriceDetails, setItemPriceDetails] = useState<PricesDetail[]>([]);
  const [expensesDetails, setExpensesDetails] = useState<
    CreateAndUpdateGoodsImportExpenseDto[]
  >([]);
  const generatedIdForCorrection = uuidv4();
  const [initialPriceDetails, setInitialPriceDetails] = useState<
    PricesDetail[]
  >([]);
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  //
  const [isDocumentValidated, setIsDocumentValidated] = useState<
    boolean | null
  >(null);
  const user = useSelector((state: RootState) => state.user.loggedInUser);

  const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
    setSupplierOptions(options);
  };
  const [copiedData, setCopiedData] = useState<
    CopiedDocumentDetailsTable<any> | undefined
  >(undefined);
  const setAdditionalStates = useCallback(
    (data: CreateAndEditLocalPurchaseDto | null) => {
      if (data !== null) {
        if (data.details) {
          setLocalPurchaseDetails(data.details);
        }
        if (data.goodsImportExpenses) {
          setExpensesDetails(data.goodsImportExpenses);
        }
      }
      setSupplierOptions([
        {
          key: data?.supplierId?.toString(),
          label: data?.supplierDescription ?? "",
          value: data?.supplierId ?? 0,
        },
      ]);
    },
    []
  );
  const getData = async () => {
    if (id && branchId) {
      const response = await dispatch(
        getLocalPurchaseDataById({ localPurchaseId: id, branchID: branchId })
      );
      const data = response.payload as CreateAndEditLocalPurchaseDto;
      if (
        response.type === "LocalPurchases/getLocalPurchaseDataById/fulfilled"
      ) {
        const localPurchase = response.payload as CreateAndEditLocalPurchaseDto;

        localPurchase.k45Description =
          localPurchase?.k45?.classifierDetailDescription;
        localPurchase.k45 = localPurchase?.k45?.classifierDetailId;

        localPurchase.k46Description =
          localPurchase?.k46?.classifierDetailDescription;
        localPurchase.k46 = localPurchase?.k46?.classifierDetailId;

        localPurchase.k47Description =
          localPurchase?.k47?.classifierDetailDescription;
        localPurchase.k47 = localPurchase?.k47?.classifierDetailId;

        localPurchase.k48Description =
          localPurchase?.k48?.classifierDetailDescription;
        localPurchase.k48 = localPurchase?.k48?.classifierDetailId;

        localPurchase.k49Description =
          localPurchase?.k49?.classifierDetailDescription;
        localPurchase.k49 = localPurchase?.k49?.classifierDetailId;

        setInitialValues(localPurchase);
        setSupplierOptions([
          {
            key: localPurchase.supplierId,
            label: localPurchase.supplierDescription ?? "",
            value: localPurchase.supplierId ?? 0,
          },
        ]);

        formikRef?.current?.setValues(localPurchase);
        const updatedDetails = localPurchase.details.map((detail) => {
          let expirationDateTemp = null;

          if (detail.expirationDate !== null) {
            const formattedDate = moment(detail.expirationDate).format(
              "YYYY-MM-DD"
            );
            expirationDateTemp = formattedDate;
          }

          return {
            ...detail,
            expirationDate: expirationDateTemp,
          };
        });

        setLocalPurchaseDetails(updatedDetails);
        const updatedGoodsImportExpenses =
          localPurchase.goodsImportExpenses.map((expense) => ({
            ...expense,
            tempId: uuidv4(),
          }));
        setExpensesDetails(localPurchase.goodsImportExpenses);
      }
      return data;
    }
    return {
      generatedId: mode !== "update" ? uuidv4() : "",
      date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      invoiceDate: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      exchangeRate: 1,
      branchId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
          ? userBranches[0].branchId
          : undefined,
    } as CreateAndEditLocalPurchaseDto;
  };
  const removePriceDetail = (index: number) => {
    setItemPriceDetails((prevPricesDetails) => {
      const activePricesDetails = prevPricesDetails.filter(
        (pricesDetails) => pricesDetails.rowAction !== "D"
      );

      const selectedPricesDetail = activePricesDetails[index];
      if (
        selectedPricesDetail.pricesDetailsId === 0 ||
        !selectedPricesDetail.pricesDetailsId
      ) {
        prevPricesDetails.splice(index, 1);
      } else {
        selectedPricesDetail.rowAction = "D";
      }
      const isStartDateToday = (startDate: string) => {
        const today = moment().startOf("day");
        const startDateMoment = moment(startDate).startOf("day");
        return today.isSame(startDateMoment);
      };

      return [...prevPricesDetails];
    });
  };
  const { initialValues, setInitialValues } =
    useFormData<CreateAndEditLocalPurchaseDto>(
      mode === "update"
        ? `localPurchase/update/${id}/${branchId}`
        : "localPurchase/register",
      {
        generatedId: mode !== "update" ? uuidv4() : "",
        date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
        invoiceDate: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
        branchId:
          user?.isMainBranch === false
            ? Number(user.branchId)
            : userBranches.length === 1
            ? userBranches[0].branchId
            : undefined,
      } as CreateAndEditLocalPurchaseDto,
      setAdditionalStates,
      {
        fetchData: getData,
      }
    );
  const removePurchaseDetail = (index: number) => {
    setLocalPurchaseDetails((prevPurchaseDetails) => {
      const activePurchaseDetails = prevPurchaseDetails.filter(
        (purchaseDetails) => purchaseDetails.rowAction !== "D"
      );
      const selectedPurchaseDetail = activePurchaseDetails[index];
      if (selectedPurchaseDetail) {
        deletePriceDetail(selectedPurchaseDetail.itemId);
      }
      if (
        selectedPurchaseDetail.entryItemsDetailsId === 0 ||
        !selectedPurchaseDetail.entryItemsDetailsId
      ) {
        prevPurchaseDetails.splice(index, 1);
      } else {
        selectedPurchaseDetail.rowAction = "D";
      }
      return [...prevPurchaseDetails];
    });
  };
  const addLocalPurchaseDetail = (
    localPurchaseDetail: CreateAndEditLocalPurchaseDetailDto
  ) => {
    setLocalPurchaseDetails((prevPurchaseDetails) => {
      return [...prevPurchaseDetails, localPurchaseDetail];
    });
  };

  const addPricesDetail = (pricesDetail: PricesDetail) => {
    setItemPriceDetails((prevPriceDetails) => {
      return [...prevPriceDetails, pricesDetail];
    });
  };
  const addOrUpdateExpensesDetail = (
    expensesDetail: CreateAndUpdateGoodsImportExpenseDto
  ) => {
    const expenseExists = expensesDetails.find(
      (expense) => expense.tempId === expensesDetail.tempId
    );

    if (expenseExists) {
      const index = expensesDetails.findIndex(
        (expense) => expense.tempId === expensesDetail.tempId
      );
      setExpensesDetails((prevExpensesDetails) => {
        const newExpensesDetails = [...prevExpensesDetails];
        newExpensesDetails[index] = expensesDetail;
        return newExpensesDetails;
      });
    } else {
      setExpensesDetails((prevExpensesDetails) => [
        ...prevExpensesDetails,
        expensesDetail,
      ]);
    }
  };
  useEffect(() => {}, [expensesDetails]);

  const getExpenseDetail = (tempId: string) => {
    let foundExpense = null;
    setExpensesDetails((prevValues) => {
      const expenseExists = prevValues.find(
        (expense) => expense.tempId === tempId
      );
      if (expenseExists) {
        foundExpense = expenseExists;
      }
      return prevValues;
    });
    return foundExpense;
  };
  const removeExpensesDetail = (index: number) => {
    setExpensesDetails((prevExpensesDetails) => {
      const activeExpensesDetails = prevExpensesDetails.filter(
        (expensesDetails) => expensesDetails.rowAction !== "D"
      );
      const selectedExpensesDetail = activeExpensesDetails[index];
      if (
        selectedExpensesDetail.entryItemsId === 0 ||
        !selectedExpensesDetail.entryItemsId
      ) {
        prevExpensesDetails.splice(index, 1);
      } else {
        selectedExpensesDetail.rowAction = "D";
      }
      return [...prevExpensesDetails];
    });
  };
  const deletePriceDetail = (itemId: number) => {
    setItemPriceDetails((prevItemPriceDetails: PricesDetail[]) => {
      const activeItemPriceDetails = prevItemPriceDetails.filter(
        (priceDetail) => priceDetail.rowAction !== "D"
      );
      const index = activeItemPriceDetails.findIndex(
        (priceDetail) => priceDetail.itemId === itemId
      );

      if (index !== -1) {
        const selectedPriceDetail = activeItemPriceDetails[index];
        if (
          selectedPriceDetail.pricesDetailsId === 0 ||
          !selectedPriceDetail.pricesDetailsId
        ) {
          activeItemPriceDetails.splice(index, 1);
        } else {
          selectedPriceDetail.rowAction = "D";
        }
      }

      return [...activeItemPriceDetails];
    });
  };

  const validationSchema = Yup.object({
    branchId: Yup.number().required(t("requiredBranch")),
    supplierId: Yup.number().required(t("requiredSupplier")),
    date: Yup.date().required(t("requiredDate")),
    invoiceWithRemarks: Yup.boolean(),
    invoiceWithRemarksReasonId: Yup.number()
      .nullable()
      .when("invoiceWithRemarks", {
        is: true,
        then: (schema) => schema.required(t("requiredInvoice")),
        otherwise: (schema) => schema.nullable(),
      }),
    plannedDate: Yup.date()
      .nullable()
      .when([], {
        is: () => purchaseConfig?.status === true,
        then: (schema) => schema.required(t("requiredPlannedDate")),
        otherwise: (schema) => schema.nullable(),
      }),
  });

  const convertCategoryValue = (
    values: CreateAndEditLocalPurchaseDto,
    categoryKey: keyof CreateAndEditLocalPurchaseDto,
    classifierDescription: string
  ) => {
    const classifier = classifiers.find(
      (c) => c.description === classifierDescription
    );

    if (!classifier) return;

    const classifierId = classifier.classifierId;

    if (
      typeof values[categoryKey] === "object" &&
      !Array.isArray(values[categoryKey])
    ) {
      const categoryObject = values[categoryKey] as ItemsClassifierDto;
      if (categoryObject && categoryObject?.classifierDetailId) {
        const classifierDetailDescription =
          categoryObject.classifierDetailDescription ?? "";
        categoryObject.classifierDetailDescription =
          typeof values[categoryKey] === "object"
            ? values[categoryKey].classifierDetailDescription
            : values[categoryKey]?.toString();
        categoryObject.classifierId = classifierId;
        categoryObject.classifierDetailId =
          categoryObject.classifierDetailId as unknown as number;
      } else {
        values[categoryKey] = null;
      }
    } else {
      if (values[categoryKey] === null || values[categoryKey] === undefined) {
        values[categoryKey] = null;
      } else if (typeof values[categoryKey] !== "object") {
        values[categoryKey] = convertClassifierValueToObject(
          classifierId,
          values[categoryKey]?.toString(),
          values[categoryKey]
        ) as ItemsClassifierDto;
      }
    }
  };

  const reconstructDetails = (
    pricesDetails: PricesDetail[]
  ): PricesDetail[] => {
    const reconstructedDetails: PricesDetail[] = [];

    pricesDetails?.forEach((detail) => {
      const { branchPriceDetails, rowAction, ...rest } = detail;

      if (mode === "update" && (rowAction === "U" || rowAction === "D")) {
        const matchingInitialDetails = initialPriceDetails.filter(
          (initialDetail) => initialDetail.itemId === detail.itemId
        );

        matchingInitialDetails?.forEach((initialDetail) => {
          const updatedBranchDetail = branchPriceDetails?.find(
            (branchDetail) => branchDetail.branchId === initialDetail.branchId
          );

          if (
            updatedBranchDetail &&
            updatedBranchDetail.branchPrice !== null &&
            updatedBranchDetail.branchPrice !== undefined &&
            updatedBranchDetail.branchPrice > 0
          ) {
            reconstructedDetails?.push({
              ...rest,
              pricesDetailsId: initialDetail.pricesDetailsId,
              salesPrice: Number(updatedBranchDetail.branchPrice),
              branchId: updatedBranchDetail.branchId,
              branchPriceDetails: [],
              rowAction: rowAction,
            });
          }
        });
      } else {
        if (
          !branchPriceDetails ||
          branchPriceDetails === null ||
          branchPriceDetails === undefined ||
          branchPriceDetails.length === 0
        ) {
          reconstructedDetails?.push({
            ...rest,
            rowAction: rowAction,
            branchPriceDetails: [],
          });
          return;
        }

        branchPriceDetails?.forEach((branchDetail) => {
          if (
            branchDetail.branchPrice > 0 &&
            branchDetail.branchPrice !== null &&
            branchDetail.branchPrice !== undefined
          ) {
            reconstructedDetails?.push({
              ...rest,
              salesPrice: Number(branchDetail.branchPrice),
              branchId: branchDetail.branchId,
              branchPriceDetails: [],
              rowAction: rowAction,
            });
          }
        });
      }
    });

    return reconstructedDetails;
  };
  const onFinish = async (values: CreateAndEditLocalPurchaseDto) => {
    console.log(values);
    // return;
    dispatch(setLoadingState(true));
    if (purchaseConfig?.status === true) {
      const itemWithoutSalePrice = localPurchaseDetails?.find(
        (detail) => detail.salesPrice === 0
      );
      if (itemWithoutSalePrice) {
        const errorMsg = `${itemWithoutSalePrice.itemDescription} - ${t(
          "salesPriceCannotBeZeroWhenStatusIsTrue"
        )}`;
        toast.error(errorMsg);
        dispatch(setLoadingState(false));
        return;
      }
    }

    if (localPurchaseDetails.length === 0) {
      toast.error(t("atLeastOneDetailIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }
    if (user?.isMainBranch && !values.branchId) {
      toast.error(t("branchIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }
    const formattedLocalPurchasesDetails = localPurchaseDetails?.map(
      (purchaseDetail) => {
        return {
          ...purchaseDetail,
          expirationDate:
            purchaseDetail.expirationDate === ""
              ? null
              : purchaseDetail.expirationDate,
        };
      }
    );

    values.details = formattedLocalPurchasesDetails;
    values.goodsImportExpenses = expensesDetails;
    const reconstructedDetails = reconstructDetails(itemPriceDetails);
    values.priceDetails = reconstructedDetails;

    const findItemWithoutPrice = itemPriceDetails?.find(
      (detail) => detail.purchasePrice === 0
    );

    console.log(findItemWithoutPrice)

    if (findItemWithoutPrice) {
      toast.error(
        `${findItemWithoutPrice.itemDescription} - ${t(
          "purchasePriceCannotBeZeroWhenStatusIsTrue"
        )}`
      );
      dispatch(setLoadingState(false));
      return;
    }

    const formattedExpensesDetails = expensesDetails.map((expense) => {
      return {
        ...expense,
        fromDate: expense.fromDate === "" ? null : expense.fromDate,
        toDate: expense.toDate === "" ? null : expense.toDate,
      };
    });

    values.goodsImportExpenses = formattedExpensesDetails;
    entryItemCategories.forEach(({ key, description }) => {
      convertCategoryValue(values, key, description);
    });

    if (mode === "update" && id) {
      values.entryItemsIdAsString = values?.entryItemsId?.toString();
      const result = await apiService
        .put(`/api/LocalPurchase/UpdateLocalPurchase`, values)
        .then(async (response) => {
          if (response.status === 200) {
            toast.success(t("updatedSuccessfully"));
            getData();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    } else {
      await apiService
        .post("/api/LocalPurchase", values)
        .then(async (response) => {
          toast.success(t("createdSuccessfully"));
          formikRef.current.setFieldValue("entryItemsId", response.data.Data);
          formikRef.current.setFieldValue(
            "entryItemsIdAsString",
            response.data.Data
          );

          const createdId = response.data.Data;

          await db.updateTab(
            "localPurchase/register",
            `localPurchase/update/${createdId}/${values.branchId}`,
            t("tabs.updateLocalPurchase")
          );

          navigate(`/localPurchase/update/${createdId}/${values.branchId}`);
        })
        .catch(() => {})
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    }
    dispatch(setLoadingState(false));
  };

  useEffect(() => {
    dispatch(getDocumentTypesWithSignHAsync("H"));
    dispatch(getClassifiersByTypeAsync("EntryItems"));
    dispatch(getInvoiceWithRemarksReasonsAsync());
    dispatch(getCurrenciesAsync());
    dispatch(getTaxesAsync());
    dispatch(getGoodsReturnReasonsAsync());
    dispatch(getUserBranches());
    dispatch(getBranchesAsync());
    dispatch(getExpensTypesAsync());
  }, [dispatch, mode, id]);

  useEffect(() => {
    const updateIndexedDB = async () => {
      const tab = await db.tabs.get(
        mode === "update"
          ? `localPurchase/update/${id}/${branchId}`
          : "localPurchase/register"
      );
      if (tab) {
        await db.tabs.put({
          ...tab,
          data: {
            ...tab.data,
            details: localPurchaseDetails,
          },
        });
      }
    };
    updateIndexedDB();
  }, [localPurchaseDetails]);

  const handleSubmitValidationForm = async (
    setTouched: ({}: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = await validateForm();
    setTouched({
      branchId: true,
      documentTypeId: true,
      supplierId: true,
      documentNo: true,
      date: true,
      invoiceDate: true,
      plannedDate: true,
      supplierInvoiceNo: true,
      currencyId: true,
      exchangeRate: true,
      k45: true,
      k46: true,
      k47: true,
      k48: true,
      k49: true,
      k50: true,
      k51: true,
      k52: true,
      k53: true,
      k54: true,
      comment: true,
      invoiceWithRemarks: true,
      invoiceWithRemarksReasonId: true,
      filesUploaded: true,
      supplierNIVF: true,
      verified: true,
      fromDate: true,
      toDate: true,
      generatedId: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };
  const handleLocalPurchaseValidation = async (
    entryItemsId: string,
    branchId: string
  ) => {
    const result = await dispatch(
      validateLocalPurchaseAsync({
        entryItemId: entryItemsId,
        branchID: branchId ?? "",
      })
    );
    if (result.type === "LocalPurchases/validateLocalPurchase/fulfilled") {
      toast.success(t("validatedSuccessfully"));
      formikRef.current.setFieldValue("validated", true);
    } else {
      toast.error(t("validationFailed"));
    }
  };
  const handleLocalPurchaseCorrection = async (
    entryItemsId: string,
    generatedId: string,
    branchId: number
  ) => {
    const result = await dispatch(
      correctLocalPurchaseAsync({
        entryItemsId: entryItemsId,
        generatedId: generatedId,
        branchID: branchId,
      })
    );
    if (result.type === "LocalPurchases/correctLocalPurchase/fulfilled") {
      const newDocumentNo = result.payload;
      await db.updateTab(
        `localPurchase/update/${entryItemsId}`,
        `localPurchase/update/${newDocumentNo}`,
        t("tabs.updateLocalPurchase")
      );
      toast.success(t("correctedSuccessfully"));
      navigate(`/localPurchase/update/${newDocumentNo}/${branchId}`);
    } else {
      toast.error(t("correctionFailed"));
    }
  };
  const isCorrectionButtonDisabled = (
    values: CreateAndEditLocalPurchaseDto
  ) => {
    return isLoading || !values.validated || mode !== "update";
  };

  const handleLocalPurchaseInvalidation = async (
    entryItemsId: string,
    branchId: number
  ) => {
    const result = await dispatch(
      invalidateLocalPurchaseAsync({
        entryItemId: entryItemsId,
        branchID: branchId,
      })
    );
    if (result.type === "LocalPurchases/invalidateLocalPurchases/fulfilled") {
      toast.success(t("invalidatedSuccessfully"));
      formikRef.current.setFieldValue("validated", false);
    } else {
      toast.error(t("invalidationFailed"));
    }
  };

  const isInvalidateButtonDisabled = (
    values: CreateAndEditLocalPurchaseDto
  ) => {
    return isLoading || !values.validated;
  };
  const copyDetails = () => {
    const details: CopiedDocumentDetailsTable<CreateAndEditLocalPurchaseDetailDto> =
      {
        id: "localPurchaseDetails",
        list: localPurchaseDetails,
      };
    db.upsertGenericData(details);
  };

  const handlePrintPdf = async (
    entryItemsId: string,
  ) => {
    const result = await dispatch(
      handlePrintLocalPurchaseForm(
         entryItemsId
      )
    );
    if (result.type === "LocalPurchase/handlePrintForm/fulfilled") {
      printBase64PDF(result.payload as string);
    } else {
      toast.error("error");
    }
  };

  const isHandlePrintDisabled = (
    values: CreateAndEditLocalPurchaseDto
  ) =>{
    return isLoading || !values.validated;
  };

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({} as CreateAndEditLocalPurchaseDto)}
        validationSchema={validationSchema}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          handleSubmit,
          submitForm,
          validateForm,
          setTouched,
          setFieldValue,
        }) => (
          <>
            <FormHeaderOptions
              title={
                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
              }
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createButton")}
              submitButtonIsDisabled={
                isLoading ||
                localPurchaseDetails.filter(
                  (detail) => detail.rowAction !== "D"
                ).length === 0 ||
                values.validated
              }
              createAccessEnum={MenuOptionEnum.LocalPurchasesCreate}
              validateAccessEnum={MenuOptionEnum.LocalPurchasesValidate}
              validateButtonIsDisabled={
                isLoading || mode !== "update" || values.validated
              }
              validateButtonText={t("validateButton")}
              handleDocumentValidation={async () => {
                if (values.entryItemsId) {
                  await handleLocalPurchaseValidation(
                    values?.entryItemsId?.toString(),
                    values?.branchId?.toString()
                  );
                }
              }}
              invalidateAccessEnum={MenuOptionEnum.LocalPurchasesInvalidate}
              invalidateButtonText={t("invalidateButton")}
              invalidateButtonIsDisabled={isInvalidateButtonDisabled(values)}
              handleDocumentInvalidation={async () => {
                await handleLocalPurchaseInvalidation(
                  values.entryItemsId ?? "",
                  values.branchId
                );
              }}
              correctionAccessEnum={MenuOptionEnum.LocalPurchasesStorno}
              correctionButtonText={t("correctButton")}
              correctionButtonIsDisabled={isCorrectionButtonDisabled(values)}
              handleDocumentCorrection={async () => {
                if (values.entryItemsId && generatedIdForCorrection) {
                  await handleLocalPurchaseCorrection(
                    values?.entryItemsId?.toString(),
                    generatedIdForCorrection,
                    values.branchId
                  );
                }
              }}
              copyButtonIsDisabled={
                isLoading ||
                mode !== "update" ||
                localPurchaseDetails.length === 0
              }
              copyButtonText={t("CopyButton")}
              handleCopyDetails={
                mode === "update" ? () => copyDetails() : undefined
              }
              printAccessEnum={MenuOptionEnum.LocalPurchasesPrint}
              handlePrintText={t("printButton")}
              handlePrintIsDisabled={isHandlePrintDisabled(values)}
              handlePrintForm={mode === "update" ? async () => {
                if(values.entryItemsId){
                  await handlePrintPdf(
                    values.entryItemsId
                  );
                }
              } : undefined}
            />

            <Form onFinish={handleSubmit} layout="vertical">
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: t("localPurchase"),
                    key: "1",
                    children: (
                      <MainFieldsComponent
                        values={values}
                        addInitialOptionsOfSupplier={
                          addInitialOptionsOfSupplier
                        }
                        supplierOptions={supplierOptions}
                        localPurchaseDetails={localPurchaseDetails}
                        itemPriceDetails={itemPriceDetails}
                        addLocalPurchaseDetail={addLocalPurchaseDetail}
                        deletePriceDetail={deletePriceDetail}
                        addPricesDetail={addPricesDetail}
                        removePurchaseDetail={removePurchaseDetail}
                        mode={mode ?? ""}
                        setLocalPurchaseDetails={setLocalPurchaseDetails}
                        setItemPriceDetails={setItemPriceDetails}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                  {
                    label: t("classifiers"),
                    key: "2",
                    children: (
                      <LocalPurchaseClassifiersComponent
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                  // {
                  //   label: t("expenses"),
                  //   key: "3",
                  //   children: (
                  //     <ExpensesFieldsComponent
                  //       values={values}
                  //       expensesDetails={expensesDetails}
                  //       addOrUpdateExpensesDetail={addOrUpdateExpensesDetail}
                  //       removeExpensesDetail={removeExpensesDetail}
                  //       mode={mode ?? ""}
                  //       getExpenseDetail={getExpenseDetail}
                  //     />
                  //   ),
                  // },
                  {
                    label: t("other"),
                    key: "4",
                    children: (
                      <OtherFieldsComponent
                        values={values}
                        uploadedFiles={uploadedFiles}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                ]}
              />

              <HandleFormDataForTabSaving
                tabPath={
                  mode === "update"
                    ? `localPurchase/update/${id}/${branchId}`
                    : "localPurchase/register"
                }
                additionalStates={{
                  details: localPurchaseDetails,
                  goodsImportExpenses: expensesDetails,
                }}
                initialValues={{
                  ...initialValues,
                  details: localPurchaseDetails,
                  goodsImportExpenses: expensesDetails,
                }}
              />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
}
