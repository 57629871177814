export default function DashboardIcon() {
  return (
    <svg
      width="20"
      height="20"
      className="icon-for-sidebar"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 2.5V17.5H18"
        stroke="#5E5E5E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 14.1667V7.5"
        stroke="#5E5E5E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 14.167V4.16699"
        stroke="#5E5E5E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16663 14.167V11.667"
        stroke="#5E5E5E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
