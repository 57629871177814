import { useSelector } from "react-redux";
import { RootState } from "../../redux";

interface Props {
  menuOptionId: number;
  children: React.ReactNode;
}

const WithPermission = ({ menuOptionId, children }: Props) => {
  const menuRoles = useSelector((state: RootState) => state.user.loggedInUserMenuRoles);

  const hasAccess = menuRoles.some(
    (menuRole) =>
      menuRole.menuOptionId === menuOptionId &&
      menuRole.status === true
  );

  return hasAccess || menuOptionId === 0 ? (
    <>{children}</>
  ) : null;
};

export default WithPermission;