// useAppTranslation.ts
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../utils/languages/i18n";
import { RootState } from "../redux";
import { useEffect } from "react";

const useAppTranslation = (screenName: string) => {
  const language = useSelector((state: RootState) => state.appearance.language); // Replace with your actual selector
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (key: string) => t(`${screenName}.${key}`);
};

export default useAppTranslation;