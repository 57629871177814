import { Button, Modal, Tooltip, Typography } from "antd";
import { AppDispatch, RootState } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { setEditingCell } from "../../../helperMethods/setEditingCell";
import apiService from "../../../extensions/api";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { InvoiceWithRemarksReason } from "../../../models/clientDashboard/InvoiceWithRemarksReason/InvoiceWithRemarksReason";
import { deleteInvoiceWithRemarksReasonAsync, getInvoiceWithRemarksReasonsAsync, getInvoiceWithRemarksReasonsSuccess } from "../../../redux/slices/invoiceWithRemarksReasonSlice";
import { CreateInvoiceWithRemarksReasonDto } from "../../../models/clientDashboard/InvoiceWithRemarksReason/CreateInvoiceWithRemarksReasonDto";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import WithPermission from "../../Authorization/WithPermission";

const { Title, Text } = Typography;

const InvoiceWithRemarksReasons = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.InvoiceWithRemarksReasonsList");
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact | null>(null);
  const [invoiceWithRemarksReasons, setInvoiceWithRemarksReasons] = useState<InvoiceWithRemarksReason[]>([]);
  const [menuInvoiceWithRemarksReasonsDisabled, setInvoiceWithRemarksReasonsDisabled] = useState(false);

  const getInvoiceWithRemarksReasons = async () => {
    const response = await dispatch(getInvoiceWithRemarksReasonsAsync());
    if (response.type === "InvoiceWithRemarksReasons/getInvoiceWithRemarksReasons/fulfilled") {
      setInvoiceWithRemarksReasons(response.payload as InvoiceWithRemarksReason[]);
    }
  };

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['isActive']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  useEffect(() => {
    getInvoiceWithRemarksReasons();
  }, []);

  const handleAddInvoiceWithRemarksReason = () => {
    const newInvoiceWithRemarksReason = {
        invoiceWithRemarksReasonId: 0,
        description: "",
        isActive: true

    };
    setInvoiceWithRemarksReasons([...invoiceWithRemarksReasons, newInvoiceWithRemarksReason]);
    setTimeout(() => {
      setEditingCell(gridRef, "description");
    }, 0);
  };

  const deleteInvoiceWithRemarksReason = async (invoiceWithRemarksReasonID: number) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      dispatch(deleteInvoiceWithRemarksReasonAsync(invoiceWithRemarksReasonID));
      setInvoiceWithRemarksReasons((prevValues)=>prevValues.filter((invoiceWithRemarksReason)=>invoiceWithRemarksReason.invoiceWithRemarksReasonId!==invoiceWithRemarksReasonID));
    }
  };

  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const [colDefs, setColDefs] = useState([
    {
      field: "invoiceWithRemarksReasonId",
      headerName: "ID",
      suppressMenu: true ,
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params.data.invoiceWithRemarksReasonId}</Text>;
      },
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      suppressMenu: true ,
      editable: true,
      onCellValueChanged: (params: any) => {
        try {
          const value = params.newValue;
          const data = params.data;
          if(value === null || (value?.trim() === "" && data.invoiceWithRemarksReasonId !== 0)) {
            setInvoiceWithRemarksReasons((prevValues)=>{
                const invoiceWithRemarksReasons = [...prevValues];
                const invoiceWithRemarksReason = {...invoiceWithRemarksReasons.find((invoiceWithRemarksReason)=>invoiceWithRemarksReason.invoiceWithRemarksReasonId === data.invoiceWithRemarksReasonId)};
                if(invoiceWithRemarksReason){
                    invoiceWithRemarksReason.description = data.description;
                }
                return invoiceWithRemarksReasons;
            });
            return;
          }
          else if(value === null || (value.trim() === "" && data.invoiceWithRemarksReasonId === 0)){
            setInvoiceWithRemarksReasons((prevValues)=>prevValues.filter((invoiceWithRemarksReason)=>invoiceWithRemarksReason.invoiceWithRemarksReasonId!==0));
            return;
          }
          params?.node?.setDataValue("description", value);
          params.api.stopEditing(true);
          if (params.data.invoiceWithRemarksReasonId === 0) {
            const newInvoiceWithRemarksReason: CreateInvoiceWithRemarksReasonDto = {
              description: value,
              isActive: true,
            };
            apiService
              .post("/api/InvoiceWithRemarksReasons", newInvoiceWithRemarksReason)
              .then(async (response) => {
                    try {
                        dispatch(getInvoiceWithRemarksReasonsSuccess([]));
                        getInvoiceWithRemarksReasons();
                    } catch (error) {
                      console.error("Error fetching invoiceWithRemarksReasons:", error);
                    }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            const updatedInvoiceWithRemarksReason: InvoiceWithRemarksReason = {
                invoiceWithRemarksReasonId: params.data.invoiceWithRemarksReasonId,
              description: value,
              isActive: params.data.isActive
            };
            apiService
              .put("/api/InvoiceWithRemarksReasons", updatedInvoiceWithRemarksReason)
              .then((response) => {
                dispatch(getInvoiceWithRemarksReasonsSuccess([]));
                getInvoiceWithRemarksReasons();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } catch (e) {
          console.log(e);
        }
      },
    },
   
    {
        field: "isActive",
        headerName: t("tableHeaders.isActive"),
        suppressMenu: true ,
        editable: true,
        onCellValueChanged: (params: any) => {
          try {
            const value = params.newValue;
            const data = params.data;

            params?.node?.setDataValue("isActive", value);
            params.api.stopEditing(true);

            const updatedInvoiceWithRemarksReason: InvoiceWithRemarksReason = {
              invoiceWithRemarksReasonId: params.data.invoiceWithRemarksReasonId,
              isActive: value,
              description: params.data.description
          };
          apiService
            .put("/api/InvoiceWithRemarksReasons", updatedInvoiceWithRemarksReason)
            .then((response) => {
              dispatch(getInvoiceWithRemarksReasonsSuccess([]));
              getInvoiceWithRemarksReasons();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      catch (e) {
            console.log(e);
      }
    }
      },{
      field: "options",
      suppressMenu: true ,
      headerName: t("tableHeaders.options"),
      cellRenderer: (params: any) => (
        <WithPermission
        menuOptionId={MenuOptionEnum.InvoiceWithRemarksReasonsDelete}
        children={
        <Button
          type="text"
          icon={<DeleteOutlined />}
          onClick={() => deleteInvoiceWithRemarksReason(params.data.invoiceWithRemarksReasonId)}
          style={{ color: "#007FFF" }}
        ></Button>
        }
        />
      ),
      width: 100,
    },
  ]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  const mutableObject = _.cloneDeep(invoiceWithRemarksReasons)
  return (
    <>
        <ListHeaderComponent
   title={t("titles.invoiceWithRemarksReasons")}
   createRecord={handleAddInvoiceWithRemarksReason}
   createTitle={t("create")}
   menuItemsDisabled={false}
   createAccessEnum={MenuOptionEnum.InvoiceWithRemarksReasonsCreate} 
 />
      <AgGridTableComponent
        gridRef={gridRef}
        isLoading={isLoading}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        rowData={mutableObject}
        pagination={true}
        paginationPageSize={20}
        suppressCellFocus={true}
        stopEditingWhenCellsLoseFocus={true}
        enableCellTextSelection={true}
        onCellEditingStopped={ (params: any) => {
            const data = params.data;
            if(data?.invoiceWithRemarksReasonId === 0 && data?.description?.trim() === ""){
              setInvoiceWithRemarksReasons((prevValues)=>prevValues.filter((invoiceWithRemarksReason)=>invoiceWithRemarksReason.invoiceWithRemarksReasonId!==0));
            }
            else if(data?.invoiceWithRemarksReasonId !== 0 && data?.description?.trim() === ""){
              setInvoiceWithRemarksReasons((prevValues)=>{
                    const invoiceWithRemarksReasons = [...prevValues];
                    const invoiceWithRemarksReason = invoiceWithRemarksReasons.find((invoiceWithRemarksReason)=>invoiceWithRemarksReason.invoiceWithRemarksReasonId === data.invoiceWithRemarksReasonId);
                    if(invoiceWithRemarksReason){
                        invoiceWithRemarksReason.description = data.description;
                    }
                    return invoiceWithRemarksReasons;
                });
            }
        }}
      />
    </>
  );
};

export default InvoiceWithRemarksReasons;
