import { Col, Form, Input, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { useEffect, useRef } from "react";
import useFormData from "../../../customHooks/useFormData";
import { CreateUserDto } from "../../../models/clientDashboard/Users/CreateUserDto";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../indexDB/clientSideDatabase";
import { Formik, FormikErrors } from "formik";
import FormInput from "../../CustomComponents/FormInput";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { getUsersSuccess } from "../../../redux/slices/userSlice";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import FormSwitch from "../../CustomComponents/FormSwitch";
import FormSelect from "../../CustomComponents/FormSelect";
import * as Yup from 'yup';
import { getActiveBranchesAsync, getBranchesAsync } from "../../../redux/slices/branchSlice";
import Password from "antd/es/input/Password";
import { LockOutlined } from "@ant-design/icons";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";

const { Title, Text } = Typography;

const CreateUser = () => {
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const branches = useSelector((state: RootState) => state.branch.branches)
    const formikRef = useRef<any>(null);
    const { initialValues } = useFormData<CreateUserDto>(
        'user/register',
        { status: true } as CreateUserDto
    );

    const t = useAppTranslation("ClientDashboard.CreateUser");

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const onFinish = async (values: CreateUserDto) => {
        dispatch(setLoadingState(true));

        values.passCodeDigit=values.passCodeDigit?.toString();
        await apiService
            .post("/api/client/User/register", values)
            .then((response) => {
                toast.success(t("userCreatedSuccessfully"));
                db.deleteTab('user/register');
                dispatch(getUsersSuccess([]))
                navigate("/users");
            })
            .catch(() => { })
            .finally(() => {
                dispatch(setLoadingState(false));
            });
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t("firstNameRequired")),
        lastName: Yup.string().required(t("lastNameRequired")),
        username: Yup.string().required(t("usernameRequired")),
        email: Yup.string().required(t("emailRequired")),
        password: Yup.string().required(t("passwordRequired")),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), undefined], "Passwords must match")
            .required(t("confirmPasswordRequired")),
        branches: Yup.array()
            .of(Yup.string().required())
            .min(1, "At least one branch must be selected.")
            .required(t("branchRequired")),
        passCodeDigit: Yup.string()
            .matches(/^\d*$/, t("passCodeDigitMustBeNumeric"))
            .max(6, t("passCodeDigitMaxLength"))
    });

    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm() || {};

        setTouched({
            usersID: true,
            firstName: true,
            lastName: true,
            userName: true,
            email: true,
            password: true,
            fiscalCode: true,
            status: true,
            branches: true,
            confirmPassword: true,
            passCodeDigit: true
        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    };


    useEffect(() => {
        //dispatch(getBranchesAsync());
        dispatch(getActiveBranchesAsync());
    }, [])

    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={initialValues ?? { status: true } as CreateUserDto}
                onSubmit={onFinish}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
            >
                {({ values,
                    handleSubmit,
                    submitForm,
                    setTouched,
                    validateForm }) => (

                    <>
                        <FormHeaderOptions
                            title={t("createFormTitle")}
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                await handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("createUserButton")}
                            submitButtonIsDisabled={isLoading}
                            createAccessEnum={MenuOptionEnum.UsersCreate}
                        />
                        <Form
                            layout="vertical"
                            onFinish={handleSubmit}
                            style={{ marginTop: 20 }}
                            autoComplete="off"

                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12}>
                                    <FormInput
                                        name="firstName"
                                        label={t("firstNameInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("firstNameInputPlaceholder"),
                                            },

                                        ]}
                                        required={true}
                                        value={values.firstName}
                                    />
                                    <FormInput
                                        name="lastName"
                                        label={t("lastNameInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("lastNameInputPlaceholder"),
                                            },
                                        ]}
                                        required={true}
                                        value={values.lastName}
                                    />
                                    <FormInput
                                        name="username"
                                        label={t("userNameInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("userNameInputPlaceholder"),
                                            },

                                        ]}
                                        required={true}
                                        value={values.username}
                                    />
                                    <FormInput
                                        name="fiscalCode"
                                        label={t("fiscalCodenputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("fiscalCodeInputPlaceholder"),
                                            },

                                        ]}
                                        value={values.fiscalCode}
                                    />
                                    <FormSwitch
                                        name="status"
                                        label={t("statusInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("statusInputPlaceholder"),
                                            },

                                        ]}
                                        value={values.status}
                                    />
                                </Col>
                                <Col xs={24} sm={12}>
                                    <FormInput
                                        name="email"
                                        type="email"
                                        label={t("emailInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("emailInputPlaceholder"),
                                            },

                                        ]}
                                        required={true}
                                        value={values.email}
                                    />

                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={12}>
                                            {/* <Form.Item
                                                name="password"
                                                label={t("passwordnputLabel")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("passwordInputPlaceholder"),
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    prefix={<LockOutlined />}
                                                    placeholder={t("passwordnputLabel")}
                                                    required={true}
                                                    value={values.password}
                                                    onChange={(e) => formikRef.current.setFieldValue('password', e.target.value)}

                                                />
                                            </Form.Item> */}
                                            <FormInput
                                                name="password"
                                                value={values.password}
                                                onChange={(e) => formikRef.current.setFieldValue('password', e.target.value)}
                                                type="password"
                                                // prefix={<LockOutlined />}
                                                required={true} 
                                                label={t("passwordnputLabel")}                                            />
                                        </Col>
                                        <Col xs={24} sm={12}>

                                            <FormInput
                                                name="confirmPassword"
                                                value={values.confirmPassword}
                                                onChange={(e) => formikRef.current.setFieldValue('confirmPassword', e.target.value)}
                                                type="password"
                                                // prefix={<LockOutlined />}
                                                required={true} 
                                                label={t("confirmpassword")}     
                                            ></FormInput>
                                        </Col>
                                    </Row>
                                    <FormSelect
                                        name="branches"
                                        multiple={true}
                                        label={t("branchesInputLabel")}
                                        rules={[
                                            {
                                                message: t("branchesInputPlaceholder"),
                                            },
                                        ]}
                                        required={true}
                                        value={values.branches}
                                        options={
                                            branches?.map((branch) => {
                                                return {
                                                    key: branch.branchId,
                                                    label: branch.description,
                                                    value: branch.branchId,
                                                };
                                            }) ?? []
                                        }
                                    />

                                    <FormInput
                                        name="passCodeDigit"
                                        type="number"
                                        label={t("passCodeDigitInputLabel")}
                                        maxLength={6}
                                        rules={[
                                            {
                                                message: t("passCodeDigitInputPlaceholder"),
                                            },

                                        ]}
                                        value={values?.passCodeDigit?.toString()}

                                    />
                                </Col>
                            </Row>

                            <HandleFormDataForTabSaving
                                tabPath="user/register"
                            />

                        </Form>
                    </>
                )}
            </Formik>
        </Spin>
    );
};

export default CreateUser;