import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { CreateAndEditImportPurchaseDto } from "../../../../models/clientDashboard/ImportPurchase/CreateAndEditImportPurchaseDto";
import { CreateAndEditImportPurchaseDetailDto } from "../../../../models/clientDashboard/ImportPurchaseDetail/CreateAndEditImportPurchaseDetailDto";
import { AppDispatch, RootState } from "../../../../redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import {
  getItemDetailsForImportPurchase,
  getItemDetailsForLocalPurchase,
  getItemsBySearchValue,
} from "../../../../redux/slices/itemSlice";
import { ItemDescriptionIdDto } from "../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { toast } from "react-toastify";
import { GetItemDetailsDto } from "../../../../models/clientDashboard/Item/GetItemDetailsDto";
import { focusOnAgGridEditingCell } from "../../../../helperMethods/focusOnAgGridEditingCell";
import { debounce } from "lodash";
import { AutoComplete, Button, Col, Row } from "antd";
import FormSelect from "../../../CustomComponents/FormSelect";
import FormInput from "../../../CustomComponents/FormInput";
import Search from "antd/es/input/Search";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { handleNextGridColumnClick } from "../../../../helperMethods/handleNextGridColumnClick";
import style from "../ImportPurchaseStyle.module.css";
import { getImportPurchaseDetailColumns } from "../../ImportPurchaseDetails/AgGridImportPurchaseDetailColumns";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";
import { Entity } from "../../../../models/clientDashboard/Entity/Entity";
import {
  getEntitiesBySearch,
  getEntitiesByTypeAsync,
} from "../../../../redux/slices/entitySlice";
import { CopiedDocumentDetailsTable } from "../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { mapDataToInterface } from "../../../../helperMethods/mapDataToInterface";
import { db } from "../../../../indexDB/clientSideDatabase";
import { SelectOption } from "../../../../models/SelectOption";
import { getDocumentTypesWithSignHAsync } from "../../../../redux/slices/documentTypeSlice";
import { ConfigurationEnum } from "../../../../enums/ConfigurationEnum";
import { GetAllConfigurationsDto } from "../../../../models/Configurations/GetAllConfigurationsDto";

interface Props {
  values: CreateAndEditImportPurchaseDto;
  importPurchaseDetails: CreateAndEditImportPurchaseDetailDto[];
  addImportPurchaseDetail: (
    importPurchaseDetail: CreateAndEditImportPurchaseDetailDto
  ) => void;
  setImportPurchaseDetails: (
    importPurchaseDetail: CreateAndEditImportPurchaseDetailDto[]
  ) => void;
  removePurchaseDetail: (rowIndex: number) => void;
  mode: string;
  setFieldValue: (name: string, value: any) => void;
  supplierOptions: SelectOption[];
}
export default function MainFieldsComponent({
  values,
  importPurchaseDetails,
  addImportPurchaseDetail,
  setImportPurchaseDetails,
  removePurchaseDetail,
  mode,
  setFieldValue,
  supplierOptions,
}: Props) {
  const t = useAppTranslation("ClientDashboard.CreateImportPurchase");
  const tForDocumentType = useAppTranslation("ClientDashboard.DocumentTypes");
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const [searchValue, setSearchValue] = useState("");
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const searchInputRef = useRef<any | null>(null);
  const suppliers = useSelector((state: RootState) => state.entity.entities);
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const dispatch = useDispatch<AppDispatch>();
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const documentTypes = useSelector(
    (state: RootState) => state.documentType.documentTypes
  );
  const [copiedData, setCopiedData] = useState<
    CopiedDocumentDetailsTable<any> | undefined
  >(undefined);
  const importPurchaseDetailGridRef = useRef(null);
  const configurations = useSelector(
    (state: RootState) => state.configuration.configurationValuesFromToken
  );
  const purchaseConfig = configurations.find(
    (config) =>
      config.configurationId ===
      ConfigurationEnum.Mundesovendosjenecmimeveteshitjestekthyrjaemallit
  );
  const [totalWithVat, setTotalWithVat] = useState<number>(0);
  const [totalWoVat, setTotalWoVat] = useState<number>(0);
  const [totalVat, setTotalVat] = useState<number>(0);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onImportPurchaseDetailGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["no"]);
    params.columnApi.autoSizeColumns(["itemId"]);
    params.columnApi.autoSizeColumns(["excise"]);
    params.columnApi.autoSizeColumns(["otherExpenses"]);
    params.columnApi.autoSizeColumns(["unitDescription"]);
    params.columnApi.autoSizeColumns(["stock"]);
    params.columnApi.autoSizeColumns(["expirationDate"]);
    params.columnApi.autoSizeColumns(["quantity"]);
    params.columnApi.autoSizeColumns(["purchasePrice"]);
    params.columnApi.autoSizeColumns(["discount"]);
    params.columnApi.autoSizeColumns(["vat"]);
    params.columnApi.autoSizeColumns(["margin"]);
    params.columnApi.autoSizeColumns(["transport"]);
    params.columnApi.autoSizeColumns(["transportCorrection"]);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [columnDefs] = useState<ColDef<CreateAndEditImportPurchaseDetailDto>[]>(
    getImportPurchaseDetailColumns(
      removePurchaseDetail,
      t,
      searchInputRef,
      mode,
      setImportPurchaseDetails,
      purchaseConfig ?? ({} as GetAllConfigurationsDto)
    )
  );
  const handleItemSearch = async (value: any, supplierId: number) => {
    const itemsBasedOnValueAction = await dispatch(
      getItemsBySearchValue({ searchValue: value, supplierId: supplierId })
    );
    const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

    if (!items || items.length === 0) {
      setItemOptions([
        {
          label: t("noItemsFound"),
          value: "no-items",
          disabled: true,
        },
      ]);
    } else {
      setItemOptions([]);
      setItemOptions(
        items?.map((item) => ({
          key: item.itemId,
          label: item.description,
          value: item.itemId.toString(),
        }))
      );
    }
  };
  useEffect(() => {
    dispatch(getDocumentTypesWithSignHAsync("HI"));
    getCopiedData();
  }, [dispatch]);

  const getCopiedData = async () => {
    const data = await db.getGenericData();
    if (data) setCopiedData(data[0]);
    else setCopiedData(undefined);
  };

  const handleOnSelect = async (value: any) => {
    if (!value || isNaN(parseInt(value))) {
      toast.error("Please enter a valid search term");
      return;
    }
    const itemID = parseInt(value);
    const itemOnDatabase = await dispatch(
      getItemDetailsForLocalPurchase({ itemID, branchID: values.branchId ?? 0 })
    );
    if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/rejected"
    ) {
      toast.error("Item not found");
      return;
    } else if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/fulfilled"
    ) {
      const item = itemOnDatabase.payload as GetItemDetailsDto;

      if (item) {
        const isItemExist = importPurchaseDetails.some(
          (detail) => detail.itemId === item.itemId
        );
        if (isItemExist) {
          toast.success(t("Item"));
          return;
        }
        const calculatedMargin =
          ((item.salesPrice / (1 + item.vat / 100) - item.finalCost) /
            (item.salesPrice / (1 + item.vat / 100))) *
          100;

        const newPurchaseDetail: CreateAndEditImportPurchaseDetailDto = {
          entryItemsDetailsId: 0,
          entryItemsId: values.entryItemsId,
          itemId: item.itemId,
          itemDescription: item.description,
          unitId: item.unitId,
          unitDescription: item.unit,
          stock: item.stock ?? 0,
          quantity: 1,
          purchasePrice: item.purchasePrice ?? 0,
          discount: item.discount ?? 0,
          extraDiscount: item.extraDiscount ?? 0,
          vat: item.vat ?? 0,
          barcode: "",
          serialNumber: "",
          expirationDate: "",
          margin: isNaN(calculatedMargin) ? 0 : calculatedMargin,
          salesPrice: item.salesPrice ?? 0,
          rowAction: "A",
          transport: 0,
          transportCorrection: 0,
          excise: 0,
          otherExpenses: 0,
          customs: 0,
          exciseOutsideTaxes: 0,
          itemCost: 0,
          finalCost: item.finalCost,
        };
        addImportPurchaseDetail(newPurchaseDetail);
        setTimeout(() => {
          focusOnAgGridEditingCell(importPurchaseDetailGridRef, "no");
        }, 0);
        setSearchValue("");
      } else {
        toast.error("Item not found");
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const onChange = (data: any) => {
    setSearchValue(data);
  };
  const pasteDetails = async () => {
    try {
      if (copiedData) {
        const targetType = {
          entryItemsId: "",
          itemId: 0,
          unitId: 0,
          stock: 0,
          itemDescription: "",
          unitDescription: "",
          quantity: 0,
          purchasePrice: 0,
          transport: 0,
          transportCorrection: 0,
          excise: 0,
          otherExpenses: 0,
          customs: 0,
          discount: 0,
          extraDiscount: 0,
          exciseOutsideTaxes: 0,
          barcode: undefined,
          serialNumber: undefined,
          tariffNumber: undefined,
          expirationDate: null,
          itemCost: 0,
          finalCost: 0,
          margin: 0,
          salesPrice: 0,
          no: 0,
          rowAction: "A",
          vat: 0,
        } as CreateAndEditImportPurchaseDetailDto;
        const details = mapDataToInterface(
          copiedData.list as any[],
          targetType
        );
        setImportPurchaseDetails(details);
      } else {
        toast.error(t("toastMessages.no_document"));
      }
    } catch (error) {
      toast.error(t("toastMessages.something_wrong"));
    }
  };

  const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

  const handleSearchChange = (value: string, supplierId: number) => {
    if (!value.trim()) {
      setSearchValue("");
      return;
    }
    setSearchValue(value);
    debouncedItemSearch(value, supplierId);
  };

  const fetchSuppliers = async (searchText: string) => {
    const result = await dispatch(
      getEntitiesBySearch({ searchText: searchText, isSupplier: true })
    );
    if (result.payload !== "An error occurred") {
      const payload = result.payload as Entity[];
      const options = payload.map((entity) => ({
        key: entity.entityId.toString(),
        label: entity.description,
        value: entity.entityId,
      }));
      return options;
    }
    return [];
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  useMemo(() => {
    setTotalWithVat(
      importPurchaseDetails
        .filter((payment) => payment.rowAction !== "D")
        .reduce((sum, detail) => {
          return (
            sum +
            detail.quantity *
              (detail.purchasePrice * (1.0 + detail.vat / 100.0)) *
              (1.0 - detail.discount / 100.0) *
              (1 - detail.extraDiscount / 100.0)
          );
        }, 0.0)
    );
  }, [importPurchaseDetails]);

  useMemo(() => {
    setTotalWoVat(
      importPurchaseDetails
        .filter((payment) => payment.rowAction !== "D")
        .reduce((sum, detail) => {
          return (
            sum +
            detail.quantity *
              detail.purchasePrice *
              (1.0 - detail.discount / 100.0) *
              (1 - detail.extraDiscount / 100.0)
          );
        }, 0.0)
    );
  }, [importPurchaseDetails]);

  useMemo(
    () => setTotalVat(totalWithVat - totalWoVat),
    [totalWithVat, totalWoVat]
  );

  const memoizedRowData = useMemo(() => {
    return importPurchaseDetails.filter((detail) => detail.rowAction !== "D");
  }, [importPurchaseDetails]);

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xs={12} sm={8}>
          <FormSelect
            name="branchId"
            className={style.importPurchaseInput}
            label={t("branchIdInputLabel")}
            required={true}
            value={values.branchId}
            options={
              userBranches?.map((userBarnch) => {
                return {
                  key: userBarnch.branchId,
                  label: userBarnch.description,
                  value: userBarnch.branchId,
                };
              }) ?? []
            }
            disabled={
              !user?.isMainBranch ||
              values.validated ||
              importPurchaseDetails.filter((detail) => detail.rowAction !== "D")
                .length > 0
            }
          />
          <FormSelect
            name="documentTypeId"
            label={t("documentTypeInputLabel")}
            style={{ width: "100%" }}
            className={style.importPurchaseInput}
            value={values.documentTypeId}
            required={true}
            onChange={(value) => {
              console.log("Selected documentTypeId:", value);
              setFieldValue("documentTypeId", value);
            }}
            disabled={
              values.validated ||
              importPurchaseDetails.filter((detail) => detail.rowAction !== "D")
                .length > 0
            }
            options={
              documentTypes.map((documentType) => {
                return {
                  key: documentType.documentId,
                  label: documentType.description,
                  value: documentType.documentId,
                };
              }) ?? []
            }
          />
          <FormSelectWithSearch
            name="supplierId"
            label={t("supplierIdInputLabel")}
            required={true}
            value={values.supplierId}
            disabled={
              values.validated ||
              importPurchaseDetails.filter((detail) => detail.rowAction !== "D")
                .length > 0
            }
            fetchOptions={fetchSuppliers}
            fetchInitialOption={() => {
              return supplierOptions[0];
            }}
            placeHolder={t("placeHolder")}
            onChange={(option) => {
              setFieldValue("supplierId", option.value);
              setFieldValue("supplierDescription", option.label);
            }}
          />
          {purchaseConfig?.status === true && (
            <FormInput
              name="plannedDate"
              type="date"
              required={false}
              label={t("plannedDateInputLabel")}
              value={values.plannedDate?.split("T")[0] || ""}
              disabled={values.validated}
              min={new Date().toISOString().split("T")[0]}
            />
          )}
        </Col>
        <Col xs={12} sm={8}>
          <FormInput
            name="date"
            type="date"
            className={style.importPurchaseInput}
            required={true}
            label={t("dateInputLabel")}
            value={values.date?.split("T")[0] || ""}
            disabled={values.validated}
          />
          <FormInput
            name="supplierInvoiceNo"
            className={style.importPurchaseInput}
            required={false}
            label={t("supplierInvoiceNoInputLabel")}
            value={values.supplierInvoiceNo}
            disabled={values.validated}
          />
          <FormInput
            name="invoiceDate"
            type="date"
            className={style.importPurchaseInput}
            required={false}
            disabled={values.validated}
            label={t("invoiceDateInputLabel")}
            value={values.invoiceDate?.split("T")[0] || ""}
          />
        </Col>
        <Col xs={12} sm={8}>
          <FormInput
            name="entryItemsId"
            label={t("referenceNoInputLabel")}
            className={style.importPurchaseInput}
            required={false}
            value={values.entryItemsId}
            disabled={true}
          />
          <FormInput
            name="documentNo"
            label={t("documentNoInputLabel")}
            className={style.importPurchaseInput}
            required={false}
            value={values.documentNo}
            disabled={true}
          />
          <FormInput
            name="comment"
            label={t("commentInputLabel")}
            className={style.importPurchaseInput}
            required={false}
            value={values.comment}
            disabled={values.validated}
          />
        </Col>
      </Row>
      <Row>
        <Col
          className="custom-row"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <AutoComplete
            options={itemOptions}
            onSelect={handleOnSelect}
            onSearch={(value) => handleSearchChange(value, values.supplierId)}
            onChange={onChange}
            className="custom-search"
            value={searchValue}
            style={{ width: 300 }}
            disabled={
              values.supplierId === 0 ||
              values.supplierId === null ||
              values.supplierId === undefined ||
              values.validated ||
              values.branchId === 0 ||
              values.branchId === null ||
              values.branchId === undefined ||
              values.documentTypeId === undefined ||
              values.documentTypeId === null
            }
          >
            <Search
              placeholder={t("searchPlaceholder")}
              allowClear
              ref={searchInputRef}
              size="large"
              onSearch={(value) => handleSearchChange(value, values.supplierId)}
            />
          </AutoComplete>
          {copiedData && (
            <Button
              onClick={pasteDetails}
              disabled={
                importPurchaseDetails.length > 0 ||
                !values.branchId ||
                !values.documentTypeId ||
                !values.supplierId
              }
            >
              {t("paste")}
            </Button>
          )}
        </Col>
        <Col
          xs={12}
          sm={16}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div className="documentTotals">
              {t("text1")} {totalWithVat?.toFixed(2)}
            </div>
            <div className="documentTotals">
              {t("text2")} {totalWoVat?.toFixed(2)}
            </div>
            <div className="documentTotals">
              {t("text3")} {totalVat?.toFixed(2)}
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[12, 8]}>
        <Col xs={24} sm={24}>
          <AgGridTableComponent
            style={{ overflow: "auto" }}
            onGridReady={onImportPurchaseDetailGridReady}
            gridRef={importPurchaseDetailGridRef}
            isLoading={isLoading}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={memoizedRowData}
            stopEditingWhenCellsLoseFocus={true}
            suppressCellFocus={true}
            disabled={values.validated}
            onCellKeyDown={(event: CellKeyDownEvent) =>
              handleNextGridColumnClick(event, searchInputRef)
            }
          />
        </Col>
      </Row>
    </>
  );
}
