import { Col, Form, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { useNavigate, useParams } from "react-router-dom";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useEffect, useRef } from "react";
import { CashRegister } from "../../../models/clientDashboard/Registrations/CashRegister/CashRegister";
import { setLoading } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../indexDB/clientSideDatabase";
import { getCashRegisterDataById, getCashRegisterSuccess } from "../../../redux/slices/cashRegisterSlice";
import useFormData from "../../../customHooks/useFormData";
import { Formik } from "formik";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import FormInput from "../../CustomComponents/FormInput";
import FormSwitch from "../../CustomComponents/FormSwitch";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import FormSelect from "../../CustomComponents/FormSelect";
import { getUserBranches } from "../../../redux/slices/userSlice";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";


const { Title, Text } = Typography;

const UpdateCashRegister = () => {
    const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
    const { id } = useParams();
    const t = useAppTranslation("ClientDashboard.UpdateCashRegister");
    const userBranches = useSelector((state: RootState) => state.user.loggedInUserBranches);
    const user = useSelector((state: RootState) => state.user.loggedInUser);

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const formikRef = useRef<any>(null);
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);


    const onFinish = async (values: CashRegister) => {
        dispatch(setLoading(true));

        await apiService
            .put("/api/CashRegister", values)
            .then((response) => {
                toast.success(t("cashRegisterUpdatedSuccessfully"));
                db.deleteTab(`cashRegister/update/${id}`);
                dispatch(getCashRegisterSuccess([]))
                navigate("/cashRegisters");
            })
            .catch(() => { })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    const { initialValues } = useFormData<CashRegister>(
        `cashRegister/update/${id}`,
        { status: true, branchId: user?.isMainBranch === false ? Number(user.branchId) : (userBranches.length === 1 ? userBranches[0].branchId : undefined), } as CashRegister,
        undefined,
        {
            fetchData: async () => {
                const cashRegisterAction = await dispatch(getCashRegisterDataById(parseInt(id ?? "0")));
                const cashRegister = cashRegisterAction.payload as CashRegister;
                return cashRegister
            }
        }
    )
    useEffect(() => {
        dispatch(getUserBranches());
    }, [])

    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm() || {};
        setTouched({
            branchID: true,
            cashRegisterNo: true,

        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    };

    return (
        <Spin tip="Loading..." spinning={loadScreen}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={initialValues ?? {
                    branchId: user?.isMainBranch === false ? Number(user.branchId) : (userBranches.length === 1 ? userBranches[0].branchId : undefined),
                } as CashRegister}
                onSubmit={onFinish}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({ values, handleSubmit, submitForm, setTouched, validateForm }) => (
                    <>
                        <FormHeaderOptions
                            title={t("formTitle")}
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("updateCashRegisterButton")}
                            submitButtonIsDisabled={isLoading}
                            createAccessEnum={MenuOptionEnum.CashRegisterCreate}
                        />
                        <Form
                            layout="vertical"
                            onFinish={handleSubmit}
                            style={{ marginTop: 20 }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12}>
                                    <FormSelect
                                        name="branchId"

                                        label={t("branchIdInputLabel")}
                                        required={true}
                                        value={values.branchId}
                                        options={
                                            userBranches?.map((userBarnch) => {
                                                return {
                                                    key: userBarnch.branchId,
                                                    label: userBarnch.description,
                                                    value: userBarnch.branchId,
                                                };
                                            }) ?? []
                                        }
                                        disabled={!user?.isMainBranch}
                                    />
                                    <FormInput
                                        name="cashRegisterNo"
                                        label={t("cashRegisterNoNameInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("cashRegisterNoInputPlaceholder"),
                                            },
                                        ]}
                                        value={values.cashRegisterNo}
                                    />
                                    <FormInput
                                        name="hostName"
                                        label={t("hostNameInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("hostNameInputPlaceholder"),
                                            },
                                        ]}
                                        value={values.hostName}
                                    />
                                </Col>
                                <Col xs={24} sm={12}>
                                    <FormInput
                                        name="fiscalizationCode"
                                        type="text"
                                        label={t("fiscalizationCodeInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("fiscalizationCodeInputPlaceholder"),
                                            },
                                        ]}
                                        value={values.fiscalizationCode}
                                    />
                                    <FormInput
                                        name="macAddress"
                                        type="text"
                                        label={t("macAddressInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("macAddressInputPlaceholder"),
                                            },
                                        ]}
                                        value={values.macAddress}
                                    />
                                    <FormSwitch
                                        className="switch-for-cashRegister"
                                        name="status"
                                        label={t("statusInputLabel")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("statusInputPlaceholder"),
                                            },
                                        ]}
                                        value={values.status}
                                    />
                                </Col>
                            </Row>
                            <HandleFormDataForTabSaving tabPath={`cashRegister/update/${id}`} />
                        </Form>
                    </>
                )}
            </Formik>
        </Spin>
    );
};

export default UpdateCashRegister;