import { Divider, Form, Spin, Tabs } from "antd";
import { Formik, FormikErrors } from "formik";
import ItemClassifiersComponent from "./CreateItemComponents/ItemClassifiersComponent";
import MainFormCreateItemComponent from "./CreateItemComponents/MainFormCreateItemComponent";
import Title from "antd/es/typography/Title";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getUnitMeasuresAsync } from "../../../redux/slices/unitMeasureSlice";
import { getItemTypesAsync } from "../../../redux/slices/itemTypeSlice";
import { getBrandsAsync } from "../../../redux/slices/brandSlice";
import { getTaxesAsync } from "../../../redux/slices/taxSlice";
import { getCustomsAsync } from "../../../redux/slices/customSlice";
import { getItemStatusesAsync } from "../../../redux/slices/itemStatusSlice";
import { getClassifiersByTypeAsync } from "../../../redux/slices/classifierSlice";
import { getLanguagesAsync } from "../../../redux/slices/languageSlice";
import { getEntitiesByTypeAsync } from "../../../redux/slices/entitySlice";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import apiService from "../../../extensions/api";
import { ItemModel } from "../../../models/clientDashboard/Item/ItemModel";
import { convertClassifierValueToObject } from "../../../helperMethods/convertClassifierValueToObject";
import { Barcode } from "../../../models/clientDashboard/Barcode/Barcode";
import { ItemDescription } from "../../../models/clientDashboard/ItemDescription/ItemDescription";
import { AppDispatch, RootState } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate, useParams } from "react-router-dom";
import { getItemDataById } from "../../../redux/slices/itemSlice";
import { GetItemsDto } from "../../../models/clientDashboard/Item/GetItemsDto";
import { categories } from "./ItemClassifiersMapped";
import { ItemsClassifierDto } from "../../../models/clientDashboard/Classifier/ItemClassifierDto";
import { ItemValidationSchema } from "./CreateItemComponents/ItemValidationSchema";
import { db } from "../../../indexDB/clientSideDatabase";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import useFormData from "../../../customHooks/useFormData";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { CreateOrUpdateCompositeItemDto } from "../../../models/clientDashboard/Item/CreateOrUpdateCompositeItemDto";
import { ItemTypeEnum } from "../../../enums/ItemTypesEnum";
import CompositeItemListComponent from "./CreateItemComponents/CompositeItemListComponent";
import { SelectOption } from "../../../models/SelectOption";
import Items from ".";
import OtherFormCreateItemComponent from "./CreateItemComponents/OtherFormCreateItemComponent";
import { Classifier } from "../../../models/clientDashboard/Classifier/Classifier";
import { getAllConfigurationsFailure } from "../../../redux/slices/getAllConfigurationsSlice";
import WithPermission from "../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";

export default function UpdateItem() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const formikRef = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.EditItem");
  const [itemDescriptions, setItemDescriptions] = useState<ItemDescription[]>(
    []
  );
  const [barcodes, setBarcodes] = useState<Barcode[]>([]);
  const [compositeItems, setCompositeItems] = useState<CreateOrUpdateCompositeItemDto[]>([]);
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
  const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
    setSupplierOptions(options);
  };

  const setAdditionalStates = useCallback((data: ItemModel) => {
    if (data.itemDescriptions) {
      setItemDescriptions(data.itemDescriptions);
    }
    if (data.barcodes) {
      setBarcodes(data.barcodes);
    }
    if (data.compositeItems) {
      setCompositeItems(data.compositeItems)
    }
  }, []);

  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );

  const filteredClassifiers = useMemo(() => {
    return classifiers.filter(
      (classifier) =>
        classifier.description !== "K1" &&
        classifier.description !== "K2" &&
        classifier.description !== "K3" &&
        classifier.description !== "K4" &&
        classifier.status === true
    );
  }, [classifiers])


  const { initialValues, setInitialValues } = useFormData<ItemModel>(
    `item/update/${id}`,
    { statusId: 1 } as ItemModel,
    setAdditionalStates,
    {
      fetchData: async () => {
        const response = await dispatch(getItemDataById(Number(id)));
        const data = response.payload as ItemModel;
        setSupplierOptions([
          {
            key: data.primarySupplierID?.toString(),
            label: data.primarySupplierName ?? "",
            value: data.primarySupplierID ?? 0,
          },
          ...(data.secondarySupplierID && data.secondarySupplierID !== 0
            ? [{
              key: data.secondarySupplierID?.toString(),
              label: data.secondarySupplierName ?? "",
              value: data.secondarySupplierID ?? 0,
            }]
            : []),
        ]);

        data.category1 = data.category1 ? {
          key: data.category1.classifierDetailId,
          label: data.category1.classifierDetailDescription,
          value: data.category1.classifierDetailId
        } : null;

        data.category2 = data.category2 ? {
          key: data.category2.classifierDetailId,
          label: data.category2.classifierDetailDescription,
          value: data.category2.classifierDetailId
        } : null;

        data.category3 = data.category3 ? {
          key: data.category3.classifierDetailId,
          label: data.category3.classifierDetailDescription,
          value: data.category3.classifierDetailId
        } : null;

        data.category4 = data.category4 ? {
          key: data.category4.classifierDetailId,
          label: data.category4.classifierDetailDescription,
          value: data.category4.classifierDetailId
        } : null;

        classifiers?.filter(
          (classifier) =>
            classifier.description !== "K1" &&
            classifier.description !== "K2" &&
            classifier.description !== "K3" &&
            classifier.description !== "K4" &&
            classifier.status === true
        )?.map((classifier: Classifier) => {
          if (classifier.classifierTypeDescription === "SELECT") {
            data[`${classifier.description.toLowerCase()}Description`] = data[classifier.description.toLowerCase()]?.classifierDetailDescription
            data[classifier.description.toLowerCase()] = data[classifier.description.toLowerCase()]?.classifierDetailId;
          } else {
            data[classifier.description.toLowerCase()] = data[classifier.description.toLowerCase()]?.classifierDetailDescription;
          }
        });
        return data;
      }
    }
  );

  const getItem = async () => {
    const response = await dispatch(getItemDataById(Number(id)));
    const data = response.payload as ItemModel;
    setBarcodes(data.barcodes ?? []);
    data.descriptions?.map((itemDescription) => {
      itemDescription.isDeleted = false;
      return itemDescription;
    });
    setItemDescriptions(data.descriptions ?? []);
    setSupplierOptions([
      {
        key: data.primarySupplierID?.toString(),
        label: data.primarySupplierName ?? "",
        value: data.primarySupplierID ?? 0,
      },
      {
        key: data.secondarySupplierID?.toString(),
        label: data.secondarySupplierName ?? "",
        value: data.secondarySupplierID ?? 0,
      }
    ]);

    data.category1 = data.category1 ? {
      key: data.category1.classifierDetailId,
      label: data.category1.classifierDetailDescription,
      value: data.category1.classifierDetailId
    } : null;

    data.category2 = data.category2 ? {
      key: data.category2.classifierDetailId,
      label: data.category2.classifierDetailDescription,
      value: data.category2.classifierDetailId
    } : null;

    data.category3 = data.category3 ? {
      key: data.category3.classifierDetailId,
      label: data.category3.classifierDetailDescription,
      value: data.category3.classifierDetailId
    } : null;

    data.category4 = data.category4 ? {
      key: data.category4.classifierDetailId,
      label: data.category4.classifierDetailDescription,
      value: data.category4.classifierDetailId
    } : null;

    classifiers?.filter(
      (classifier) =>
        classifier.description !== "K1" &&
        classifier.description !== "K2" &&
        classifier.description !== "K3" &&
        classifier.description !== "K4" &&
        classifier.status === true
    )?.map((classifier: Classifier) => {
      if (classifier.classifierTypeDescription === "SELECT") {
        data[`${classifier.description.toLowerCase()}Description`] = data[classifier.description.toLowerCase()]?.classifierDetailDescription
        data[classifier.description.toLowerCase()] = data[classifier.description.toLowerCase()]?.classifierDetailId;
      } else {
        data[classifier.description.toLowerCase()] = data[classifier.description.toLowerCase()]?.classifierDetailDescription;
      }
    });
    setInitialValues(data)
  };


  const updateCompositeItem = (
    updatedCompositeItem: CreateOrUpdateCompositeItemDto[]
  ) => {
    setCompositeItems(updatedCompositeItem);
  };
  const addNewCompositeItem = (compositeItemDescriptions: CreateOrUpdateCompositeItemDto) => {
    setCompositeItems((prevCompositeItemDescriptions) => {
      const updatedCompositeItemDescriptions = [...prevCompositeItemDescriptions];
      updatedCompositeItemDescriptions.push(compositeItemDescriptions);
      return updatedCompositeItemDescriptions;
    });
  };
  const removeCompositeItem = (index: number) => {
    setCompositeItems((prevCompositeItems) => {
      const activeCompositeItems = prevCompositeItems.filter(
        (compositeItem) => compositeItem.rowAction !== 'D'
      );
      const selectedCompositeItem = activeCompositeItems[index];
      if (
        selectedCompositeItem.compositeItemId === 0 ||
        !selectedCompositeItem.compositeItemId
      ) {
        prevCompositeItems.splice(index, 1);
      } else {
        selectedCompositeItem.rowAction = 'D';
      }
      return [...prevCompositeItems];
    });
  };

  const updatedItemDescriptions = (
    updatedItemDescription: ItemDescription[]
  ) => {
    setItemDescriptions(updatedItemDescription);
  };
  const addNewItemDescription = (itemDescription: ItemDescription) => {
    setItemDescriptions((prevItemDescriptions) => {
      const updatedItemDescriptions = [...prevItemDescriptions];
      updatedItemDescriptions.push(itemDescription);
      return updatedItemDescriptions;
    });
  };
  const removeItemDescription = (index: number) => {
    setItemDescriptions((prevItemDescriptions) => {
      const activeItemDescriptions = prevItemDescriptions.filter(
        (itemDescription) => itemDescription.isDeleted === false
      );
      const selectedItemDescription = activeItemDescriptions[index];
      if (
        selectedItemDescription.itemDescriptionId === 0 ||
        !selectedItemDescription.itemDescriptionId
      ) {
        prevItemDescriptions.splice(index, 1);
      } else {
        selectedItemDescription.isDeleted = true;
      }
      return [...prevItemDescriptions];
    });
  };

  const updateBarcodes = (barcodes: Barcode[]) => {
    setBarcodes(barcodes);
  };

  const addNewBarcode = (barcode: Barcode) => {
    setBarcodes((prevBarcodes) => {
      const updatedBarcodes = [...prevBarcodes];
      updatedBarcodes.push(barcode);
      return updatedBarcodes;
    });
  };
  const removeBarcode = (index: number) => {
    setBarcodes((prevBarcodes) => {
      const activeBarcodes = prevBarcodes.filter(
        (barcode) => !barcode.isDeleted
      );

      if (index >= 0 && index < activeBarcodes.length) {
        const selectedBarcode = activeBarcodes[index];
        const isPrimary = selectedBarcode.primaryBarcode;

        if (selectedBarcode.barcodeId === 0) {
          const originalIndex = prevBarcodes.findIndex(
            (barcode) => barcode === selectedBarcode
          );
          if (originalIndex !== -1) {
            prevBarcodes.splice(originalIndex, 1);
          }
        } else {
          selectedBarcode.isDeleted = true;
        }

        if (isPrimary) {
          const firstNonDeletedBarcode = prevBarcodes.find(
            (barcode) => !barcode.isDeleted
          );

          if (firstNonDeletedBarcode) {
            firstNonDeletedBarcode.primaryBarcode = true;
          }
        }
      }

      return [...prevBarcodes];
    });
  };

  // const convertCategoryValue = (
  //   values: ItemModel,
  //   categoryKey: keyof ItemModel,
  //   classifierDescription: string
  // ) => {
  //   if (
  //     values[categoryKey] !== undefined &&
  //     values[categoryKey] !== null &&
  //     (typeof values[categoryKey] !== "object" ||
  //       Array.isArray(values[categoryKey]))
  //   ) {
  //     const classifier = classifiers.find(
  //       (c) => c.description === classifierDescription
  //     );
  //     if (classifier) {
  //       const classifierId = classifier?.classifierId;
  //       const detail = classifier?.classifierDetails?.find(
  //         (cd) => cd.classifierDetailId === values[categoryKey]
  //       );
  //       const categoryDescription =
  //         detail?.description ?? (values[categoryKey] as string);
  //       const categoryDetailId = detail?.classifierDetailId;
  //       values[categoryKey] = {
  //         classifierId:classifierId??0,
  //         classifierDetailDescription:categoryDescription.toString(),
  //         classifierDetailId:categoryDetailId
  //       };
  //     }
  //   }
  // };
  const filterItemDescriptions = (itemDescriptions: ItemDescription[]) => {
    const updatedItemDescriptions = itemDescriptions
      .map((itemDescription) => {
        if (
          (itemDescription.description === "" ||
            itemDescription.description === null ||
            itemDescription.description === undefined ||
            itemDescription.language === "" ||
            itemDescription.language === null ||
            itemDescription.language === undefined) &&
          itemDescription.itemDescriptionId
        ) {
          return { ...itemDescription, isDeleted: true };
        } else if (
          (itemDescription.description === "" ||
            itemDescription.description === null ||
            itemDescription.description === undefined ||
            itemDescription.language === "" ||
            itemDescription.language === null ||
            itemDescription.language === undefined) &&
          !itemDescription.itemDescriptionId
        ) {
          return null;
        } else {
          return itemDescription;
        }
      })
      .filter(Boolean) as ItemDescription[];
    return updatedItemDescriptions;
  };
  const convertItemClassifiers = (values: ItemModel) => {
    values.category1 = values.category1 ? {
      classifierId: classifiers.find((classifier) => classifier.description === "K1")?.classifierId,
      classifierDetailDescription: values?.category1?.label,
      classifierDetailId: values?.category1?.value
    } : null;

    values.category2 = values.category2 ? {
      classifierId: classifiers.find((classifier) => classifier.description === "K2")?.classifierId,
      classifierDetailDescription: values?.category2?.label,
      classifierDetailId: values?.category2?.value
    } : null;

    values.category3 = values.category3 ? {
      classifierId: classifiers.find((classifier) => classifier.description === "K3")?.classifierId,
      classifierDetailDescription: values?.category3?.label,
      classifierDetailId: values?.category3?.value
    } : null;

    values.category4 = values.category4 ? {
      classifierId: classifiers.find((classifier) => classifier.description === "K4")?.classifierId,
      classifierDetailDescription: values?.category4?.label,
      classifierDetailId: values?.category4?.value
    } : null;
    classifiers.filter(
      (classifier) =>
        classifier.description !== "K1" &&
        classifier.description !== "K2" &&
        classifier.description !== "K3" &&
        classifier.description !== "K4" &&
        classifier.status === true
    )?.map((classifier) => {
      if (values[classifier.description.toLowerCase()] !== undefined && values[classifier.description.toLowerCase()] !== null) {
        if (classifier.classifierTypeDescription === "SELECT") {
          values[classifier.description.toLowerCase()] = {
            classifierId: classifier.classifierId,
            classifierDetailDescription: values[`${classifier.description.toLowerCase()}Description`],
            classifierDetailId: typeof values[classifier.description.toLowerCase()] === "object" ? values[classifier.description.toLowerCase()].value : values[classifier.description.toLowerCase()]
          }
        }
        else if (classifier.classifierTypeDescription !== "SELECT") {
          values[classifier.description.toLowerCase()] = {
            classifierId: classifier.classifierId,
            classifierDetailDescription: values[classifier.description.toLowerCase()],
            classifierDetailId: typeof values[classifier.description.toLowerCase()] === "object" ? values[classifier.description.toLowerCase()]?.classifierDetailId : 0
          }
        }
        else {
          values[classifier.description.toLowerCase()] = null;
        }
      }
    })
    return;
  }

  const onFinish = async (values: ItemModel) => {
    // categories.forEach(({ key, description }) => {
    //   convertCategoryValue(values, key, description);
    // });
    convertItemClassifiers(values);

    values.barcodes = barcodes;
    values.descriptions = filterItemDescriptions(itemDescriptions);
    values.primarySupplierID =
      values.primarySupplierID === 0 ? null : values.primarySupplierID;
    values.secondarySupplierID =
      values.secondarySupplierID === 0 ? null : values.secondarySupplierID;
    if (values.itemTypeId === ItemTypeEnum.processedItem) {
      values.compositeItems = compositeItems
    }

    await apiService
      .put("/api/Items/update", values)
      .then(async (response) => {
        toast.success(t("itemUpdatedSuccessfully"));
        await db.tabs.delete(`item/update/${id}`);

        navigate("/items");
      })
      .catch(() => { })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (
      values?: any
    ) => Promise<FormikErrors<ItemModel>>
  ) => {
    const errors = await validateForm();
    setTouched({
      description: true,
      customId: true,
      itemTypeId: true,
      statusId: true,
      unitId: true,
      brandId: true,
      taxId: true,
      category1: true,
      category2: true,
      category3: true,
      category4: true,
      k10: true,
      k11: true,
      k12: true,
      k13: true,
      k14: true,
      k15: true,
      k16: true,
      k17: true,
      k18: true,
      k19: true,
      k20: true,
      k21: true,
      k22: true,
      k23: true,
      k24: true,
      k5: true,
      k6: true,
      k7: true,
      k8: true,
      k9: true,
    });
    return errors;
  };

  useEffect(() => {
    dispatch(getUnitMeasuresAsync());
    dispatch(getItemTypesAsync());
    dispatch(getBrandsAsync());
    dispatch(getTaxesAsync());
    dispatch(getCustomsAsync());
    dispatch(getItemStatusesAsync());
    dispatch(getClassifiersByTypeAsync("Products"));
    dispatch(getLanguagesAsync());
    dispatch(getEntitiesByTypeAsync(true));
    getItem();
  }, [dispatch, id]);

  const validationSchema = ItemValidationSchema();

  const handleValidation = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      return {};
    } catch (err: any) {
      const validationErrors: { [key: string]: string } = {};
      err.inner.forEach((error: any) => {
        validationErrors[error.path] = error.message;
        toast.error(error.message);
      });
      return validationErrors;
    }
  };

  const memoizedRowData2 = useMemo(() => {
    return itemDescriptions.filter((detail) => detail.isDeleted === false);
  }, [itemDescriptions]);

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      {initialValues?.description !== "" &&
        initialValues?.description !== null ? (
        <>
        </>
      ) : (
        <></>
      )}
      <Formik
        initialValues={initialValues ?? {} as ItemModel}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validate={handleValidation}
        validateOnChange={false}
      >
        {({ values, handleSubmit, submitForm, validateForm, setTouched, setFieldValue }) => (
          <Form onFinish={handleSubmit} layout="vertical">
                <FormHeaderOptions
                  title={t("updateFormTitle")}
                  handleSubmitForm={submitForm}
                  handleSubmitValidation={async () => {
                    handleSubmitValidationForm(setTouched, validateForm);
                  }}
                  submitButtonText={t("updateItemButton")}
                  submitButtonIsDisabled={isLoading}
                  createAccessEnum={MenuOptionEnum.ItemsCreate}
                />
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: t("item"),
                  key: "1",
                  children: (
                    <MainFormCreateItemComponent
                      supplierOptions={supplierOptions}
                      item={values}
                      formikRef={formikRef}
                      removeItemDescription={removeItemDescription}
                      updateItemDescriptions={updatedItemDescriptions}
                      addNewItemDescription={addNewItemDescription}
                      removeBarcode={removeBarcode}
                      updateBarcodes={updateBarcodes}
                      addNewBarcode={addNewBarcode}
                      itemDescriptions={itemDescriptions}
                      barcodes={barcodes}
                      setFieldValue={setFieldValue}
                      mode="update"
                    />
                  ),
                },
                // {
                //   label: t("itemDescriptions"),
                //   key: "2",
                //   children: (
                //     <ItemDescriptionListComponent
                //       addNewItemDescription={addNewItemDescription}
                //       itemDescriptions={itemDescriptions}
                //       updateItemDescriptions={updateItemDescriptions}
                //       removeItemDescription={removeItemDescription}
                //     />
                //   ),
                // },
                // {
                //   label: t("barcodes"),
                //   key: "3",
                //   children: (
                //     <BarcodeListComponent
                //       barcodes={barcodes}
                //       removeBarcode={removeBarcode}
                //       updateBarcodes={updateBarcodes}
                //       addNewBarcode={addNewBarcode}
                //     />
                //   ),
                // },
                {
                  label: t("classifiers"),
                  key: "2",
                  children:
                    <ItemClassifiersComponent
                      item={values}
                      mode="update"
                      setFieldValue={setFieldValue}
                    />,
                },
                ...(values.itemTypeId === ItemTypeEnum.processedItem ? [
                  {
                    label: t("compositeItems"),
                    key: "3",
                    children: <CompositeItemListComponent
                      values={values}
                      compositeItems={compositeItems}
                      removeCompositeItem={removeCompositeItem}
                      updateCompositeItem={updateCompositeItem}
                      addNewCompositeItem={addNewCompositeItem}
                      mode="update"
                    />,
                  }
                ] : []),
                {
                  label: t("others"),
                  key: "3",
                  children: (
                    <OtherFormCreateItemComponent
                      item={values}
                      addNewItemDescription={addNewItemDescription}
                      removeItemDescription={removeItemDescription}
                      updatedItemDescriptions={updatedItemDescriptions}
                      itemDescriptions={memoizedRowData2}
                      formikRef={formikRef}
                      setFieldValue={setFieldValue} />
                  ),
                },
              ]}
            />
            <HandleFormDataForTabSaving tabPath={`item/update/${id}`} additionalStates={{ descriptions: itemDescriptions, barcodes: barcodes, compositeItems: compositeItems }} />
          </Form>
        )}
      </Formik>
    </Spin>
  );
}
