import { useSelector } from "react-redux";
import { MenuRoleForAccess } from "../models/clientDashboard/MenuRole/MenuRoleForAccess";
import { RootState } from "../redux";

export function usePermission() {
    const menuRoles = useSelector((state: RootState) => state.user.loggedInUserMenuRoles);
  
    const hasPermission = (menuOptionId: number): boolean => {
      return menuRoles.some(
        (menuRole: MenuRoleForAccess) =>
          menuRole.menuOptionId === menuOptionId && menuRole.status === true
      );
    };
  
    return { hasPermission };
  }