import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SaleOrderState } from "../../models/states/SaleOrderState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { GetSaleOrderDto } from "../../models/clientDashboard/EntriesModule/SaleOrder/GetSaleOrderDto";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { GenericDocumentDto } from "../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateSaleOrderDto } from "../../models/clientDashboard/EntriesModule/SaleOrder/CreateOrUpdateSaleOrderDto";
import { CreateOrUpdateSaleOrderDetailDto } from "../../models/clientDashboard/EntriesModule/SaleOrder/CreateOrUpdateSaleOrderDetailsDto";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";

const initialState: SaleOrderState = {
  saleOrders: [],
  error: null,
};

const saleOrderSlice = createSlice({
  name: "saleOrders",
  initialState,
  reducers: {
    getSaleOrdersStart(state: SaleOrderState) {
      setLoading(true);
      state.error = null;
    },
    getSaleOrdersSuccess(
      state: SaleOrderState,
      action: PayloadAction<
       GetSaleOrderDto[]>
      
    ) {
      setLoading(false);
      state.saleOrders = action.payload;
    },
    getSaleOrdersFailure(
      state: SaleOrderState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});
export const getSaleOrdersFilterColumnsAsync = createAsyncThunk(
  "SaleOrders/getSaleOrderFilterColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<
        ApiResponse<
          GenericDocumentDto<
            CreateOrUpdateSaleOrderDto,
            CreateOrUpdateSaleOrderDetailDto
          >[]
        >
      >("api/Filter/sale-order");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getSaleOrdersFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);
export const getSaleOrdersColumnsAsync = createAsyncThunk(
  "SaleOrder/getSaleOrderColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    // const items = state.item.items;

    // if (items.length > 0) {
    //     return items;
    // }
    try {
      const response = await apiService.get<
        ApiResponse<PropertyDto[]>>("api/Filter/sale-order");
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getSaleOrdersFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);
export const getSaleOrdersDataById = createAsyncThunk(
  "SaleOrders/getSaleOrdersDataById",
  async (
    data: { saleOrderId: string; branchID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<
        ApiResponse<
          GenericDocumentDto<
            CreateOrUpdateSaleOrderDto,
            CreateOrUpdateSaleOrderDetailDto
          >
        >
      >(
        `/api/SaleOrders/getOrderById?saleOrderId=${data.saleOrderId}&branchId=${data.branchID}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const validateSaleOrdersAsync = createAsyncThunk(
  "SaleOrder/validateSaleOrders",
  async (
    data: { saleOrderId: string; branchID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/SaleOrders/validate?saleOrderId=${data.saleOrderId}&branchId=${data.branchID}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const deleteSaleOrdersAsync = createAsyncThunk(
  "SaleOrder/deleteSaleOrder",
  async (saleOrderId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.delete<ApiResponse<void>>(
        `/api/SaleOrders/${saleOrderId}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getSaleOrdersWithFilters = createAsyncThunk(
  "SaleOrder/getSaleOrderWithFilters",
  async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<GetSaleOrderDto[]>>
        ("/api/SaleOrders/getDataWithFilter", data);
      dispatch(getSaleOrdersSuccess(response.data.Data));
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(setLoadingState(false));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const invalidateSaleOrdersAsync = createAsyncThunk(
    "SaleOrder/invalidateSaleOrder",
    async (data: { saleOrderId: string, branchID: number }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/SaleOrders/unvalidate?saleOrderId=${data.saleOrderId}&branchId=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const {
  getSaleOrdersStart,
  getSaleOrdersSuccess,
  getSaleOrdersFailure,
} = saleOrderSlice.actions;

export default saleOrderSlice.reducer;
