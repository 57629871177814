import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CompanyState } from "../../models/states/CompanyState";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { GetCompaniesDto } from "../../models/dashboard/Company/GetCompaniesDto";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import { GetCompaniesBasicInfo } from "../../models/dashboard/Company/GetCompaniesBasicInfo";
import { CompanyDetails } from "../../models/dashboard/Company/SingleCompany/CompanyDetails";

const initialState: CompanyState = {
  companies: [],
  error: null,
  companiesBasicInfo: [],
};

const dashboardCompanySlice = createSlice({
  name: "dashboardCompany",
  initialState,
  reducers: {
    getDashboardCompaniesStart(state: CompanyState) {
      setLoading(true);
      state.error = null;
    },
    getDashboardCompaniesSuccess(
      state: CompanyState,
      action: PayloadAction<GetCompaniesDto[]>
    ) {
      setLoading(false);
      state.companies = action.payload;
    },
    getDashboardCompaniesFailure(
      state: CompanyState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
    getCompaniesBasicInfo(
      state: CompanyState,
      action: PayloadAction<GetCompaniesBasicInfo[]>
    ) {
      setLoading(false);
      state.companiesBasicInfo = action.payload;
    },
  },
});

export const getDashboardCompaniesAsync = createAsyncThunk(
  "dashboard/Company",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { companies } = (getState() as { dashboardCompany: CompanyState })
      .dashboardCompany;

    if (companies.length > 0) {
      return companies;
    }

    try {
      dispatch(getDashboardCompaniesStart());
      const response = await apiService.get<ApiResponse<GetCompaniesDto[]>>(
        "api/dashboard/Company"
      );
      toast.success("Kompanite u shkarkuan me sukses!");
      dispatch(getDashboardCompaniesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getDashboardCompaniesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getCompaniesBasicInfoAsync = createAsyncThunk(
  "dashboard/Company/companies-basic-info",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { companiesBasicInfo } = (
      getState() as { dashboardCompany: CompanyState }
    ).dashboardCompany;

    if (companiesBasicInfo.length > 0) {
      return companiesBasicInfo;
    }

    try {
      dispatch(getDashboardCompaniesStart());
      const response = await apiService.get<
        ApiResponse<GetCompaniesBasicInfo[]>
      >("api/dashboard/Company/companies-basic-info");
      // toast.success("Kompanite u shkarkuan me sukses!");
      dispatch(getCompaniesBasicInfo(response.data.Data));

      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getDashboardCompaniesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getCompanyDataById = createAsyncThunk(
  "dashboard/Company",
  async (companyId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<CompanyDetails>>(
        `/api/dashboard/Company/full-details/?id=${companyId}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  getDashboardCompaniesStart,
  getDashboardCompaniesSuccess,
  getDashboardCompaniesFailure,
  getCompaniesBasicInfo,
} = dashboardCompanySlice.actions;

export default dashboardCompanySlice.reducer;
