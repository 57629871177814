import { useCallback, useEffect, useState } from "react";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import {
  getClassifierByIdForEditAsync,
  getClassifierDataTypesAsync,
} from "../../../../redux/slices/classifierSlice";
import { Col, Divider, Form, Row, Spin, Tabs } from "antd";
import Title from "antd/es/typography/Title";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import ClassifierDetailList from "./ClassifierDetailList";
import FormSubmitButton from "../../../CustomComponents/FormSubmitButton";
import { ClassifierEditDto } from "../../../../models/clientDashboard/Classifier/ClassifierEditDto";
import { ClassifierDetail } from "../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";
import { Formik } from "formik";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import apiService from "../../../../extensions/api";
import { toast } from "react-toastify";
import FormInput from "../../../CustomComponents/FormInput";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import FormSelect from "../../../CustomComponents/FormSelect";
import useFormData from "../../../../customHooks/useFormData";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import { db } from "../../../../indexDB/clientSideDatabase";
import { values } from "lodash";
import { padding } from "aes-js";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import WithPermission from "../../../Authorization/WithPermission";

export default function ClassifierMainComponent() {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.ClassifierMainComponent");
  const classifierDataTypes = useSelector(
    (state: RootState) => state.classifier.classifierDataTypes
  );
  const navigate = useNavigate();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const setAdditionalStates = useCallback((data: ClassifierEditDto) => {
    if (data.classifierDetails) {
      setClassifierDetails(data.classifierDetails);
    }
  }, []);
  const { initialValues } = useFormData<ClassifierEditDto>(
    `classifier/update/${id}`,
    {
      classifierId: 0,
      description: "",
      clientDescription: "",
      mandatory: false,
      status: false,
      type: "",
      classifierTypeDescription: "",
      classifierDataTypeId: 0,
      canChangeClassifierDataType: false,
      classifierDetails: [],
      regex: "",
    } as ClassifierEditDto,
    setAdditionalStates,
    {
      fetchData: async () => {
        const response = await dispatch(
          getClassifierByIdForEditAsync(id ?? "")
        );
        const payload = response.payload as ClassifierEditDto;
        if (payload) {
          const updatedDetails =
            payload.classifierDetails?.map((detail) => ({
              ...detail,
              isDeleted: false,
            })) || [];

          payload.classifierDetails = updatedDetails;
          return payload;
        }
        return {} as ClassifierEditDto;
      },
    }
  );

  const [classifierDetails, setClassifierDetails] = useState<
    ClassifierDetail[]
  >([]);

  // const fetchClassifier = async () => {
  //   const response = await dispatch(getClassifierByIdForEditAsync(id ?? ""));
  //   const payload = response.payload as ClassifierEditDto;
  //   if (payload) {
  //     const updatedDetails =
  //       payload.classifierDetails?.map((detail) => ({
  //         ...detail,
  //         isDeleted: false,
  //       })) || [];

  //     payload.classifierDetails = updatedDetails;
  //     setClassifierDetails(updatedDetails);
  //     setInitialValues(payload);
  //   }
  // };

  const onFinish = async (values: ClassifierEditDto) => {
    dispatch(setLoadingState(true));
    const updatedDetails =
      classifierDetails?.map((detail) => {
        if (detail.classifierDetailId === 0) {
          return {
            ...detail,
            classifierDetailId: null,
          };
        } else {
          return detail;
        }
      }) || [];
    values.classifierDetails = updatedDetails;

    if (values.regex !== "") {
      for (let i = 0; i < values.classifierDetails.length; i++) {
        if (
          values.classifierDetails[i].description.match(values.regex) === null
        ) {
          toast.error(t("regexError"));
          dispatch(setLoadingState(false));
          return;
        }
      }
    }

    await apiService
      .post("/api/Classifier/edit", values)
      .then((response) => {
        toast.success(t("classifierUpdatedSuccessfully"));
      })
      .catch(() => { })
      .finally(() => {
        dispatch(setLoadingState(false));
        navigate("/classifiers");
      });
  };

  useEffect(() => {
    dispatch(getClassifierDataTypesAsync());
    // fetchClassifier();
  }, [dispatch, id]);
  useEffect(() => {
    const updateIndexedDB = async () => {
      const tab = await db.tabs.get(`classifier/update/${id}`);
      if (tab) {
        await db.tabs.put({
          ...tab,
          data: {
            ...tab.data,
            classifierDetails: classifierDetails,
          },
        });
      }
    };
    updateIndexedDB();
  }, [classifierDetails]);

  const addOrEditClassifierDetail = (
    classifierDetail: ClassifierDetail,
    classifierIndex?: number
  ) => {
    setClassifierDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      if (classifierIndex !== undefined) {
        updatedDetails[classifierIndex] = classifierDetail;
      } else {
        updatedDetails.push(classifierDetail);
      }
      return updatedDetails;
    });
  };

  const removeClassifierDetail = (index: number) => {
    setClassifierDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      if (index >= 0 && index < updatedDetails.length) {
        if (updatedDetails[index].classifierDetailId === 0) {
          updatedDetails.splice(index, 1);
        } else {
          updatedDetails[index].isDeleted = true;
        }
      }
      return updatedDetails;
    });
  };
  const getClassifierDataTypes = (classifierDescription: string) => {
    let filteredClassifierDataTypes = [];

    switch (classifierDescription) {
      case "K45":
      case "K46":
      case "K47":
      case "K48":
      case "K49":
      case "K55":
      case "K56":
      case "K57":
      case "K58":
      case "K59":
      case "K60":
      case "K61":
      case "K62":
      case "K63":
      case "K64":
        filteredClassifierDataTypes = classifierDataTypes
          .filter((type) => type.description === "SELECT")
          .map((type) => {
            return {
              key: type.classifierDataTypeId,
              label: type.description,
              value: type.classifierDataTypeId,
            };
          });
        break;
      case "K50":
      case "K51":
      case "K52":
      case "K53":
      case "K54":
        filteredClassifierDataTypes = classifierDataTypes
          .filter((type) => type.description !== "SELECT")
          .map((type) => {
            return {
              key: type.classifierDataTypeId,
              label: type.description,
              value: type.classifierDataTypeId,
            };
          });
        break;
      default:
        filteredClassifierDataTypes = classifierDataTypes.map((type) => {
          return {
            key: type.classifierDataTypeId,
            label: type.description,
            value: type.classifierDataTypeId,
          };
        });
        break;
    }
    return filteredClassifierDataTypes;
  };
  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Formik
        initialValues={initialValues ?? ({} as ClassifierEditDto)}
        onSubmit={onFinish}
        enableReinitialize
      >
        {({ values, handleSubmit, submitForm }) => (
          <Form onFinish={handleSubmit} layout="vertical">
            <Row justify="end" style={{ marginBottom: 16 }}>
              <Col>
                <WithPermission
                  menuOptionId={MenuOptionEnum.ClassifiersCreate}
                  children={
                    <FormSubmitButton
                      handleValidate={undefined}
                      handleConfirm={submitForm}
                      buttonText={t("saveClassifierChangesButton")}
                    />
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8}>
                <FormInput
                  name="description"
                  label={t("descriptionInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("descriptionInputPlaceholder"),
                    },
                  ]}
                  disabled={true}
                  value={values.description}
                />
                <FormInput
                  name="clientDescription"
                  label={t("clientDescriptionInputLabel")}
                  rules={[
                    {
                      required: false,
                      message: t("clientDescriptionInputPlaceholder"),
                    },
                  ]}
                  value={values.clientDescription}
                />
              </Col>
              <Col xs={24} sm={8}>
                <FormInput
                  name="type"
                  type="text"
                  label={t("typeInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("typeInputPlaceholder"),
                    },
                  ]}
                  disabled={true}
                  value={values.type}
                />
                <FormSelect
                  name="classifierDataTypeId"
                  label={t("classifierDataTypeIdInputLabel")}
                  rules={[
                    {
                      required: true,
                      message: t("classifierDataTypeIdInputPlaceholder"),
                    },
                  ]}
                  value={values.classifierDataTypeId}
                  options={getClassifierDataTypes(values.description)}
                  disabled={!values.canChangeClassifierDataType || classifierDetails.length > 0}
                />
              </Col>
              <Col xs={24} sm={8}>
                <FormSwitch className="switch-for-classifier" name="mandatory" label={t("mandatoryInputLabel")} />
                <FormSwitch className="switch-for-classifier" name="status" label={t("statusInputLabel")} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24}>
                <ClassifierDetailList
                  classifierDetails={classifierDetails}
                  addOrEditClassifierDetail={addOrEditClassifierDetail}
                  removeClassifierDetail={removeClassifierDetail}
                />
              </Col>
            </Row>
            <HandleFormDataForTabSaving
              tabPath={`classifier/update/${id}`}
              additionalStates={{ classifierDetails: classifierDetails }}
            />
          </Form>
        )}
      </Formik>
    </Spin>
  );
}
