import React from "react";
import { Form, Switch } from "antd";
import { useField } from "formik";

interface Props {
  name: string;
  label: string;
  className?: string;
  style?: any;
  disabled?: boolean;
  value?: boolean;
  rules?: any[];
  onChange?: (checked: boolean) => void; // Add onChange prop
}

const FormSwitch = ({ name, label, className = "", style = {}, disabled = false, value, rules, onChange }: Props) => {
  const [field, meta, helpers] = useField(name);
  const hasError = meta.touched && meta.error;

  const handleChange = (checked: boolean) => {
    helpers.setValue(checked);
    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <Form.Item
      label={label}
      validateStatus={hasError ? "error" : ""}
      help={hasError ? meta.error : ""}
      className={`formSwitch ${className}`}
      style={style}
      rules={rules}
    >
      <Switch
        checked={value ?? field.value}
        onChange={handleChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default FormSwitch;
