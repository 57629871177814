import React, { useState } from "react";
import { Modal, Button } from "antd";
import useAppTranslation from "../../customHooks/useAppTranslation";
import ReactCrop, { PixelCrop } from "react-image-crop";

interface Props {
  openCropModal: boolean;
  setOpenCropModal: (value: boolean) => void;
  logoBase64: string;
  setLogoBase64: (value: string) => void;
}
export default function CropPhotoModal({
  openCropModal,
  setOpenCropModal,
  logoBase64,
  setLogoBase64
}: Props) {

  const t = useAppTranslation("AdminDashboard.CropPhotoModal");
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [crop, setCrop] = useState<PixelCrop>({ unit: "px", x: 0, y: 0, width: 100, height: 100 });

  const cropImage = async () => {
    if (!logoBase64 || !completedCrop) return;

    const image = new Image();
    image.src = `data:image/png;base64,${logoBase64}`;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext("2d");

      ctx?.drawImage(
        image,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height
      );

      canvas.toBlob((blob: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          const base64Data = base64String.split(',')[1];
          setLogoBase64(base64Data);
        };
        reader.readAsDataURL(blob);
      }, "image/jpeg");
    };

    setOpenCropModal(false);
  };
  const onCropComplete = (crop: PixelCrop) => {
    setCompletedCrop(crop);
  };


  return (
    <Modal
      title={t("cropImage")}
      open={openCropModal}
      onCancel={() => setOpenCropModal(false)}
      onOk={cropImage}
      cancelText={t("cancel")}
      okText={t("ok")}
    >
      {logoBase64 && logoBase64 !== "" && (
        <ReactCrop
          crop={crop}
          onComplete={onCropComplete}
          onChange={(newCrop) => setCrop(newCrop)}
          keepSelection
        >
          <img src={`data:image/png;base64,${logoBase64}`}
            alt="Crop preview"
            onLoad={(e) => setCrop(crop)}
          />
        </ReactCrop>
      )}
    </Modal>
  );
};
