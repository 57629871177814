import { useEffect, useMemo, useRef, useState } from "react";
import { CreateAndUpdateEntityBranchDto } from "../../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { ColDef } from "ag-grid-community";
import { Button, Col, Row } from "antd";
import FormInput from "../../../CustomComponents/FormInput";
import FormSelect from "../../../CustomComponents/FormSelect";
import { v4 as uuidv4 } from "uuid";
import { fetchClassifiers, getClassifiersForSubjectBranchesAsync } from "../../../../redux/slices/classifierSlice";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { Entity } from "../../../../models/clientDashboard/Entity/Entity";
import { getCitiesForStateAsync } from "../../../../redux/slices/citySlice";
import { getEntityBranchesDetailColumns } from "../EntityBranchesDetails/AgGridEntityBranchesDetailColumns";
import { GetCitiesDto } from "../../../../models/clientDashboard/City/GetCitiesDto";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import { toast } from "react-toastify";
import validateClassifier from "../../../../helperMethods/validateClassifier";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";

interface Props {
    values: Entity;
    entityBranchesDetails: CreateAndUpdateEntityBranchDto[];
    getEntityBranchesDetail: (rowIndex: string) => CreateAndUpdateEntityBranchDto | null;
    addOrUpdateEntityBranchesDetail: (
        branchesDetail: CreateAndUpdateEntityBranchDto
    ) => void;
    classifiersForBranch: Classifier[];
    setClassifiersForBranch: (classifiers: Classifier[]) => void;
    removeEntityBranchesDetail: (rowIndex: number) => void;
    mode: string;
    setFieldValue: (name: string, value: any) => void;
}

export default function EntityBranchesComponent({ values, entityBranchesDetails, addOrUpdateEntityBranchesDetail, getEntityBranchesDetail, removeEntityBranchesDetail, mode, classifiersForBranch, setClassifiersForBranch, setFieldValue }: Props) {

    const [entityBranch, setEntityBranch] = useState<CreateAndUpdateEntityBranchDto>({
    } as CreateAndUpdateEntityBranchDto)
    // const cities = useSelector((state: RootState) => state.city.cities);
    const [cities, setCities] = useState<GetCitiesDto[]>([]);
    const t = useAppTranslation("ClientDashboard.CreateEntityBranches");
    const entityBranchDetailGridRef = useRef(null);
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const dispatch = useDispatch<AppDispatch>();

    const updateEntityBranchesDetail = (tempId: string) => {
        const entityBranchesDetail = getEntityBranchesDetail(tempId);
        if (entityBranchesDetail) {
            setEntityBranch({ ...entityBranchesDetail, description: entityBranchesDetail?.description ?? "" });
        }
    }

    const [columnDefs, setColumnDefs] = useState<ColDef<CreateAndUpdateEntityBranchDto>[]>([]);

    useEffect(() => {
        const updatedColumnDefs = getEntityBranchesDetailColumns(removeEntityBranchesDetail, updateEntityBranchesDetail, t, classifiersForBranch, cities);
        setColumnDefs(updatedColumnDefs);
    }, [classifiersForBranch]);

    const [gridApi, setGridApi] = useState<any>(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const onExpenseDetailGridReady = (params: any) => {

        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };
    const filteredClassifiers = classifiersForBranch.filter(
        (classifier) =>
            classifier.description !== "K1" &&
            classifier.description !== "K2" &&
            classifier.description !== "K3" &&
            classifier.description !== "K4" &&
            classifier.status === true
    );

    function getNestedValue(obj: any, path: any) {
        return path.split('.').reduce((acc: any, part: any) => acc && acc[part], obj);
    };

    const groupSize = Math.ceil(filteredClassifiers.length / 3);
    const groups = [
        filteredClassifiers.slice(0, groupSize),
        filteredClassifiers.slice(groupSize, groupSize * 2),
        filteredClassifiers.slice(groupSize * 2)
    ];

    const getClassifierValueByNameForSelect = (name: string, entity: any) => {
        const categoryValue = entity[name];
        if (typeof categoryValue === 'number') {
            return categoryValue;
        } else if (categoryValue && typeof categoryValue === 'object') {
            return categoryValue?.classifierDetailId || undefined;
        } else {
            return undefined;
        }
    };

    const getClassifierDescriptionValue = (name: string, item: any) => {
        const descriptionValue = getNestedValue(item, name);
        switch (name) {
            case "k55":
                return item?.k55Description;
            case "k56":
                return item?.k56Description;
            case "k57":
                return item?.k57Description;
            case "k58":
                return item?.k58Description;
            case "k59":
                return item?.k59Description;
            default:
                return "";
        }
    };


    const renderClassifiers = () => {
        const inputComponents = classifiersForBranch !== undefined ?
            classifiersForBranch?.filter(
                (classifier) =>
                classifier.description !== "K1" &&
                classifier.description !== "K2" &&
                classifier.description !== "K3" &&
                classifier.description !== "K4" &&
                classifier.status === true
            )
            .map((classifier) => {
                    const value = getClassifierValueByNameForSelect(classifier.description.toLowerCase(), entityBranch);
                    const label = getClassifierDescriptionValue(`${classifier?.description?.toLowerCase()}Description`, values);
                    return (
                        <FormSelectWithSearch
                            key={classifier.description}
                            name={classifier?.description?.toLowerCase()}
                            label={classifier.clientDescription || classifier.description}
                            required={classifier.mandatory}
                            value={entityBranch[classifier?.description?.toLowerCase()]}
                            disabled={values.validated}
                            fetchOptions={(searchText: string) => fetchClassifiers(searchText, classifier.classifierId ?? "")}
                            placeHolder={t("placeHolder")}
                            onChange={(selectedValue) => {

                                setEntityBranch((prevValues) => {
                                    let updatedValue = { ...prevValues };
                                    if (classifier.classifierId === 55) {
                                        updatedValue.k55 = selectedValue;
                                    } else if (classifier.classifierId === 56) {
                                        updatedValue.k56 = selectedValue;
                                    } else if (classifier.classifierId === 57) {
                                        updatedValue.k57 = selectedValue;
                                    } else if (classifier.classifierId === 58) {
                                        updatedValue.k58 = selectedValue;
                                    } else if (classifier.classifierId === 59) {
                                        updatedValue.k59 = selectedValue;
                                    }

                                    return {
                                        ...updatedValue
                                    };
                                });
                            }}
                        />
                    )
                }
                ) : []
        return inputComponents;
    }


    const handleAddRow = () => {
        if (entityBranch.description?.trim() === "" || entityBranch.description === undefined || entityBranch.description === null) {
            toast.error(t("descriptionRequired"));
            return
        }
        const duplicateDescriptions = entityBranchesDetails.some(detail => detail.description?.toLowerCase() === entityBranch.description?.toLowerCase() && detail.tempId !== entityBranch.tempId);
        if (duplicateDescriptions) {
            toast.error(t("descriptionDuplicate"));
            return;
        }

        const newK55Object = {
            classifierDetailId: entityBranch.k55.value,
            classifierDetailDescription: entityBranch.k55.label
        }
        const k55Result = validateClassifier(newK55Object, classifiersForBranch.find((classifier) => classifier.classifierId === 55) ?? {} as Classifier)
        if (!k55Result) {
            toast.error(t("InvalidData"))
            return
        }
        const newK56Object = {
            classifierDetailId: entityBranch.k55.value,
            classifierDetailDescription: entityBranch.k55.label
        }
        const k56Result = validateClassifier(newK56Object, classifiersForBranch.find((classifier) => classifier.classifierId === 56) ?? {} as Classifier)
        if (!k56Result) {
            toast.error(t("InvalidData"))
            return
        }
        const newK57Object = {
            classifierDetailId: entityBranch.k55.value,
            classifierDetailDescription: entityBranch.k55.label
        }
        const k57Result = validateClassifier(newK57Object, classifiersForBranch.find((classifier) => classifier.classifierId === 57) ?? {} as Classifier)
        if (!k57Result) {
            toast.error(t("InvalidData"))
            return
        }
        const newK58Object = {
            classifierDetailId: entityBranch.k55.value,
            classifierDetailDescription: entityBranch.k55.label
        }
        const k58Result = validateClassifier(newK58Object, classifiersForBranch.find((classifier) => classifier.classifierId === 58) ?? {} as Classifier)
        if (!k58Result) {
            toast.error(t("InvalidData"))
            return
        }
        const newK59Object = {
            classifierDetailId: entityBranch.k55.value,
            classifierDetailDescription: entityBranch.k55.label
        }
        const k59Result = validateClassifier(newK59Object, classifiersForBranch.find((classifier) => classifier.classifierId === 59) ?? {} as Classifier)
        if (!k59Result) {
            toast.error(t("InvalidData"))
            return
        }

        const dataToAdd = {
            ...entityBranch,
            rowAction: entityBranch.entityBranchId !== 0 && entityBranch.entityBranchId !== undefined ? "U" : "A",

            tempId: entityBranch.tempId === "" || entityBranch.tempId === undefined || entityBranch.tempId === null ? uuidv4() : entityBranch.tempId
        };

        addOrUpdateEntityBranchesDetail(dataToAdd);
        setEntityBranch({
            description: "",
            email: "",
            phoneNumber: "",
            address: "",
            cityId: 0,
            contactPerson: "",
            comment: "",
            k55: null,
            k56: null,
            k57: null,
            k58: null,
            k59: null,
        } as CreateAndUpdateEntityBranchDto);
    };

    const getCities = async () => {
        const result = await dispatch(getCitiesForStateAsync());
        if (result.payload !== "An error occurred") {
            const payload = result.payload as GetCitiesDto[];
            setCities(payload);
        }
    }
    const getClassifiersForSubjectBranches = async () => {
        const result = await dispatch(getClassifiersForSubjectBranchesAsync("EntityBranches"));
        if (result.payload !== "An error occurred") {
            const payload = result.payload as Classifier[];
            setClassifiersForBranch(payload);
        }
    }

    useEffect(() => {
        getCities();
        getClassifiersForSubjectBranches();
    }, [])

    const memoizedRowData = useMemo(() => {
        return entityBranchesDetails.filter((detail) => detail.rowAction !== "D");
    }, [entityBranchesDetails]);

    return (
        <div>
            <Row gutter={[8, 8]}>
                <Col xs={12} sm={8}>

                    <FormInput
                        name="description"
                        label={t("descriptionInputLabel")}
                        required={true}
                        value={entityBranch.description}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setEntityBranch((prevValues) => ({
                                ...prevValues,
                                description: value.target.value
                            }));
                        }}
                    />

                    <FormInput
                        name="branchEmail"
                        label={t("emailIputLabel")}
                        value={entityBranch.email}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setEntityBranch((prevValues) => ({
                                ...prevValues,
                                email: value.target.value
                            }));
                        }}
                    />
                    <FormInput
                        name="phoneNumber"
                        label={t("phoneNumberIputLabel")}
                        value={entityBranch.phoneNumber}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setEntityBranch((prevValues) => ({
                                ...prevValues,
                                phoneNumber: value.target.value
                            }));
                        }}
                    />
                    <FormInput
                        name="address"
                        label={t("addressIputLabel")}
                        value={entityBranch.address}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setEntityBranch((prevValues) => ({
                                ...prevValues,
                                address: value.target.value
                            }));
                        }}
                    />
                    <FormSelect
                        name="entityBranch.cityId"
                        label={t("cityIdIputLabel")}
                        rules={[
                            {
                                message: t("cityIdIputLabel"),
                            },
                        ]}
                        value={entityBranch.cityId}
                        options={
                            cities?.map((cityId) => {
                                return {
                                    key: cityId.cityId,
                                    label: cityId.cityDescription,
                                    value: cityId.cityId,
                                };
                            }) ?? []
                        }
                        handleCustomChange={(value) => {
                            setEntityBranch((prevValues) => ({
                                ...prevValues,
                                cityId: value,
                            }));
                        }}
                    />

                </Col>
                <Col xs={12} sm={8}>

                    {renderClassifiers ? renderClassifiers() : null}
                </Col>
                <Col xs={12} sm={8}>
                    <FormInput
                        name="contactPerson"
                        label={t("contactPersonIputLabel")}
                        value={entityBranch.contactPerson}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setEntityBranch((prevValues) => ({
                                ...prevValues,
                                contactPerson: value.target.value,
                            }));
                        }}
                    />
                    <FormInput
                        name="comment"
                        label={t("commentIputLabel")}
                        value={entityBranch.comment}
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setEntityBranch((prevValues) => ({
                                ...prevValues,
                                comment: value.target.value,
                            }));
                        }}
                    />
                </Col>

                <Col span={24} style={{ textAlign: "right" }}>
                    <Button type="primary" onClick={handleAddRow}>
                        {t("addEntityBranchButtonText")}
                    </Button>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col xs={24} sm={24}>
                    <AgGridTableComponent
                        style={{ overflow: "auto" }}
                        onGridReady={onExpenseDetailGridReady}
                        gridRef={entityBranchDetailGridRef}
                        isLoading={isLoading}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowData={memoizedRowData}
                        suppressCellFocus={true}
                        stopEditingWhenCellsLoseFocus={true}
                    />
                </Col>
            </Row>
        </div>
    );

}