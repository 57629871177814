export default function ImportIcon() {
  return(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 1.66663H4.99998C4.55795 1.66663 4.13403 1.84222 3.82147 2.15478C3.50891 2.46734 3.33331 2.89127 3.33331 3.33329V16.6666C3.33331 17.1087 3.50891 17.5326 3.82147 17.8451C4.13403 18.1577 4.55795 18.3333 4.99998 18.3333H15C15.442 18.3333 15.8659 18.1577 16.1785 17.8451C16.4911 17.5326 16.6666 17.1087 16.6666 16.6666V5.83329L12.5 1.66663Z"
      stroke="#5E5E5E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6667 1.66663V4.99996C11.6667 5.44199 11.8423 5.86591 12.1548 6.17847C12.4674 6.49103 12.8913 6.66663 13.3334 6.66663H16.6667"
      stroke="#5E5E5E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V15"
      stroke="#5E5E5E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 12.5L10 10L7.5 12.5"
      stroke="#5E5E5E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>);
}
