import React, { MutableRefObject, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Spin } from 'antd';
import '../../assets/generalStyles/GridThemeStyles.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
type AgGridTableComponentProps = {
  onGridReady?: (params: any) => void;
  isLoading?: boolean;
  gridRef?: MutableRefObject<AgGridReact<any> | null> | null;
  divRef?: MutableRefObject<HTMLDivElement | null> | null;
  defaultColDef?: any;
  rowData?: any[];
  columnDefs?: any[];
  pagination?: boolean;
  paginationPageSize?: number;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  [key: string]: any;
};
const AgGridTableComponent: React.FC<AgGridTableComponentProps> = ({
  onGridReady = () => { },
  isLoading,
  gridRef = null,
  divRef=null,
  defaultColDef,
  rowData,
  columnDefs,
  pagination,
  paginationPageSize,
  className = "",
  style = {},
  disabled,
  ...otherAgGridProps
}) => {
  const defaultColDefTest = {
    wrapHeaderText: true,
    ...defaultColDef, 
  };
  const theme = useSelector(
    (rootState: RootState) => rootState.appearance.theme
  );

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <div
        ref={divRef}
        className={`ag-theme-quartz custom-theme-grid ${className}`}
        style={{ height: 550, marginTop: 15, position: 'relative', ...style }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDefTest}
          
          rowData={rowData}
          ref={gridRef}
          columnDefs={columnDefs}
          suppressRowHoverHighlight={true}
          domLayout='autoHeight'
          pagination={pagination}
          suppressCellFocus={false}
          suppressRowClickSelection={true}
          rowSelection="single"
          paginationPageSizeSelector={[10, 20, 50, 100]}
          paginationPageSize={pagination ? paginationPageSize : undefined}
          {...otherAgGridProps}
        />
        {disabled && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              zIndex: 1000,
              cursor: 'not-allowed',
            }}
          />
        )}
      </div>
    </Spin>
  );
};

export default AgGridTableComponent;
