import { useEffect, useState } from "react";
import { db } from "../indexDB/clientSideDatabase";

interface UseFormDataOptions<T> {
  fetchData?: () => Promise<T>;
}

const useFormData = <T extends object>(
  path: string,
  defaultValues: T|null,
  setAdditionalStates?: (data: T) => void,
  options: UseFormDataOptions<T> = {}
) => {
  const { fetchData } = options;
  const [initialValues, setInitialValues] = useState<T | null>(null);

  useEffect(() => {
    const fetchFormData = async () => {
      const dataFromDB = await db.tabs.get({ tabPath: path });

      if (dataFromDB?.data && dataFromDB.isTouched===true) {
        setInitialValues(dataFromDB.data);
        if(setAdditionalStates)
        setAdditionalStates(dataFromDB.data);
      } else if (fetchData) {
        const dataFromBackend = await fetchData();
        setInitialValues(dataFromBackend);
        if(setAdditionalStates)
        setAdditionalStates(dataFromBackend);
      } else {
        setInitialValues(defaultValues);
        if(setAdditionalStates && defaultValues !== null)
        setAdditionalStates(defaultValues);
      }
    };
    fetchFormData();
  }, [path, setAdditionalStates]);

  return { initialValues, setInitialValues };
};

export default useFormData;
