import { Button, Popconfirm, Tooltip, Typography } from "antd";
import { renderFilterInputs } from "../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../CustomComponents/FilterModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import { db } from "../../../indexDB/clientSideDatabase";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { FilterData } from "../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../customHooks/useFilter";
import { formatDate, formatDateWithTime } from "../../../helperMethods/DateFormat";
import { ImportPurchase } from "../../../models/clientDashboard/ImportPurchase/ImportPurchase";
import { getImportPurchaseFilterColumnsAsync, getImportPurchasesWithFilters } from "../../../redux/slices/importPurchaseSlice";
import { AgGridReact } from "ag-grid-react";
import { exportDataToCsv } from "../../../models/helper/exportDataToCsv";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import WithPermission from "../../Authorization/WithPermission";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import { usePermission } from "../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;
export default function ImportPurchases() {
  const importPurchases = useSelector((state: RootState) => state.importPurchase.importPurchases);
  const t = useAppTranslation("ClientDashboard.ImportPurchases");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef<ImportPurchase>[]>([
    {
      field: "entryItemsId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.entryItemsId}</Text>;
      },
    },
    { field: "branch", headerName: t("tableHeaders.branch"), suppressMenu: true },
    { field: "supplier", headerName: t("tableHeaders.supplier"), suppressMenu: true },
    { field: "documentType", headerName: t("tableHeaders.documentType"), suppressMenu: true },
    { field: "documentNo", headerName: t("tableHeaders.documentNo"), suppressMenu: true },
    { field: "validated", headerName: t("tableHeaders.validated"), suppressMenu: true },
    { field: "customDocNo", headerName: t("tableHeaders.customDocNo"), suppressMenu: true },
    { field: "date", headerName: t("tableHeaders.date"), valueFormatter: formatDate, suppressMenu: true, width: 120 },
    { field: "entryDate", headerName: t("tableHeaders.entryDate"), valueFormatter: formatDateWithTime, suppressMenu: true },
    { field: "invoiceDate", headerName: t("tableHeaders.invoiceDate"), valueFormatter: formatDate, suppressMenu: true },
    { field: "lastUpdatedDate", headerName: t("tableHeaders.lastUpdatedDate"), valueFormatter: formatDateWithTime, suppressMenu: true, width: 180 },
    { field: "supplierInvoiceNo", headerName: t("tableHeaders.supplierInvoiceNo"), suppressMenu: true },
    // { field: "paymentDeadline", headerName: t("tableHeaders.paymentDeadline") },
    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.ImportPurchasesCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(t("tabs.updateImportPurchase"), `importPurchase/update/${params?.data?.entryItemsId}`, undefined)
                navigate(`/importPurchase/update/${params?.data?.entryItemsId}/${params.data.branchId}`)

              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 90,
      suppressMenu: true
    },
  ]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['paymentDeadline']);
    params.columnApi.autoSizeColumns(['branch']);
    params.columnApi.autoSizeColumns(['supplier']);
    params.columnApi.autoSizeColumns(['documentNo']);
    params.columnApi.autoSizeColumns(['customDocNo']);
    params.columnApi.autoSizeColumns(['invoiceDate']);
    params.columnApi.autoSizeColumns(['supplierInvoiceNo']);
    params.columnApi.autoSizeColumns(['validated']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [perPage, setPerPage] = useState(20);
  const getImportPurchases = async () => {
    const result = await dispatch(getImportPurchasesWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };
  useEffect(() => {
    getImportPurchases()
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.ImportPurchasesCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
    db.addTab(t("tabs.updateImportlPurchase"), `importPurchase/update/${event?.data?.entryItemsId}/${event?.data?.branchId}`, undefined)
    navigate(`/importPurchase/update/${event?.data?.entryItemsId}/${event.data.branchId}`)
    }
  };
  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;

    const result = await dispatch(getImportPurchasesWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getImportPurchaseFilterColumnsAsync());
    if (result.type === "Items/getImportPurchaseFilterColumnsAsync/fulfilled") {
      return result;
    } else {
      return null;
    }
  }
  const handleClearFilters = async () => {
    const result = await dispatch(getImportPurchasesWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  }
  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });
  const handleCreateNewImportPurchase = async () => {
    navigate("/importPurchase/register");
    const existingTab = await db.tabs.get("importPurchase/register");
    if (!existingTab) {
      db.addTab(t("tabs.createImportPurchase"), "importPurchase/register", undefined);
    }
  };
  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "entryItemsId",
      "branch",
      "supplier",
      "documentType",
      "documentNo",
      "customDocNo",
      "validated",
      "date",
      "entryDate",
      "invoiceDate",
      "lastUpdatedDate",
      "supplierInvoiceNo",
    ]);
  }, []);


  return (
    <>
      <ListHeaderComponent
        title={t("titles.importPurchases")}
        createRecord={handleCreateNewImportPurchase}
        menuItemsDisabled={menuItemsDisabled}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        createAccessEnum={MenuOptionEnum.ImportPurchasesCreate}
        exportAccessEnum={MenuOptionEnum.ImportPurchasesExport}
      />
      <div
        style={{
          display: 'grid',
          gap: '16px',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))'
        }}
      >
        {filterState && filterState?.filterInputs?.map(input => (
          <div key={input.name} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>{input.isClassifier ? input.name : t(`FilterColumns.${input.name}`)}</Text>
            {renderFilterInputs({ input: input, filterState: filterState })}
          </div>
        ))}
      </div>
      {filterState && filterState?.filterInputs?.length > 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem", gap: "1.5rem" }}>
          <Button onClick={filterState.handleClearFilters}>{t("clearFilters")}</Button>
          <Button type="primary" onClick={filterState.handleFilterSubmit}>{t("filter")}</Button>
        </div>
      )}
      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={importPurchases}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
      <FilterModal filterState={filterState} translationPrefix="ClientDashboard.ImportPurchases" />
    </>
  )
}