import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SalesState } from "../../models/states/SalesState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { CreateOrUpdateSaleDto } from "../../models/clientDashboard/Sales/SalesInvoice/CreateOrUpdateSalesDto";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";

const initialState: SalesState = {
    sales: [],
    error: null
};

const salesSlice = createSlice({
    name: "sales",
    initialState,
    reducers: {
        getSalesStart(state: SalesState) {
            setLoading(true);
            state.error = null;
        },
        getSalesSuccess(
            state: SalesState,
            action: PayloadAction<CreateOrUpdateSaleDto[]>
        ) {
            setLoading(false);
            state.sales = action.payload;
        },
        getSalesFailure(
            state: SalesState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewSalesToState(state: SalesState, action: PayloadAction<CreateOrUpdateSaleDto>) {
            setLoading(false);
            state.sales.push(action.payload);
        },
        updateSalesInState(state: SalesState, action: PayloadAction<CreateOrUpdateSaleDto>) {
            setLoading(false);
            const updatedSale = action.payload;
            const index = state.sales.findIndex(sale=> sale.saleId === updatedSale.saleId);
            if (index !== -1) {
                state.sales[index] = updatedSale;
            }
        },
        deleteSalesFromState(state: SalesState, action: PayloadAction<string>) {
            setLoading(false);
            const salesId = action.payload;
            state.sales = state.sales.filter(sale => sale.saleId !== salesId);
        },
    }
});

export const getSalesAsync = createAsyncThunk(
    "Sale/getSales",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;

        try {
            let url  = `api/Sale/getDataWithPagination`;

            const response = await apiService.get<ApiResponse<CreateOrUpdateSaleDto[]>>(
                url
            );
            dispatch(getSalesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getSalesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
        }
    }
);

export const getSaleFilterColumnsAsync = createAsyncThunk(
    "Sales/getSaleFilterColumnsAsync",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        try {
            const response = await apiService.get<ApiResponse<PropertyDto[]>>('api/Filter/sales');
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getSalesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);


export const getSaleDataById = createAsyncThunk(
    "Sales/getSaleDataById",
    async (data: {salesId: string,branchID: number}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<CreateOrUpdateSaleDto>>(
                `/api/Sales/getSaleById?saleId=${data.salesId}&branchId=${data.branchID}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteSaleAsync = createAsyncThunk(
    "Sales/deleteSale",
    async (saleId: string, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/Sale/${saleId}`
            );
            dispatch(deleteSalesFromState(saleId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const validateSaleAsync = createAsyncThunk(
    "Sales/validateSale",
    async (data: {saleId: string,branchID: string}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/Sales/validate?saleId=${data.saleId}&branchId=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const correctSaleAsync = createAsyncThunk(
    "Sales/correctSale",
    async (data: {saleId:string, generatedId:string, branchID: number}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const result = await apiService.get<ApiResponse<void>>(
                `/api/Sales/correctSale?saleId=${data.saleId}&generatedId=${data.generatedId}&branchID=${data.branchID}`
            );
            return result.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getSalesWithFilters = createAsyncThunk(
    "Sales/getSalesWithFilters",
    async (data:FilterData[],{dispatch,getState,rejectWithValue}) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.post<ApiResponse<CreateOrUpdateSaleDto[]>>(
                "/api/Sales/GetDataWithFilter",data
            );

            dispatch(getSalesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(setLoadingState(false));
            return rejectWithValue(errorMessage);
        }
        finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const invalidateSaleAsync = createAsyncThunk(
    "Sales/invalidateSales",
    async (data: { saleId:string, branchID: number }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/Sales/unvalidate?saleId=${data.saleId}&branchId=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const handlePrintSalesForm = createAsyncThunk(
    "Sales/handlePrintForm",
    async ( saleId: string ,
      {dispatch, getState, rejectWithValue}
    ) => {
      try{
        dispatch(setLoadingState(true));
        const result = await apiService.get<ApiResponse<string>>(
          `/api/Sales/get-report?saleId=${saleId}`
        );
        return result.data.Data;
      }catch (error: any) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  );
export const {
    getSalesStart,
    getSalesSuccess,
    getSalesFailure,
    addNewSalesToState,
    updateSalesInState,
    deleteSalesFromState
} = salesSlice.actions;

export default salesSlice.reducer;