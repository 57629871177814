import { Button, Modal, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../indexDB/clientSideDatabase";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { AppDispatch, RootState } from "../../../redux";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { deleteCurrencyAsync, getCurrenciesAsync } from "../../../redux/slices/currencySlice";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import WithPermission from "../../Authorization/WithPermission";
import { usePermission } from "../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;

const Currencies = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const currencies = useSelector((state: RootState) => state.currency.currencies);
  const t = useAppTranslation("ClientDashboard.Currencies");
  const dispatch = useDispatch<AppDispatch>();
  const [menuCurrenciesDisabled, setMenuCurrenciesDisabled] = useState(false);

  const deleteCurrency = async (currencyID: number) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      dispatch(deleteCurrencyAsync(currencyID));
    }
  };

  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "currencyId",
      headerName: "ID",
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.currencyId}</Text>;
      },
      suppressMenu: true
    },
    { field: "currencyCode", headerName: t("tableHeaders.currencyCode"), suppressMenu: true },
    { field: "currencyName", headerName: t("tableHeaders.currencyName"), suppressMenu: true },
    { field: "currencySymbol", headerName: t("tableHeaders.currencySymbol"), suppressMenu: true },
    { field: "exchangeRate", headerName: t("tableHeaders.exchangeRate"), suppressMenu: true },
    { field: "status", headerName: t("tableHeaders.status"), suppressMenu: true },
    { field: "isMainCurrency", headerName: t("tableHeaders.isMainCurrency"), suppressMenu: true },
    { field: "fiscalType", headerName: t("tableHeaders.fiscalType"), suppressMenu: true },
    {
      headerName: t("tableHeaders.options"), suppressMenu: true, width: 100,
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.CurrenciesCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateCurrency"),
                  `currency/update/${params?.data?.currencyId}`,
                  undefined
                );
                navigate(`/currency/update/${params?.data?.currencyId}`);
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
    },
  ]);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['currencyCode']);
    params.columnApi.autoSizeColumns(['currencyName']);
    params.columnApi.autoSizeColumns(['currencySymbol']);
    params.columnApi.autoSizeColumns(['exchangeRate']);
    params.columnApi.autoSizeColumns(['status']);
    params.columnApi.autoSizeColumns(['isMainCurrency']);
    params.columnApi.autoSizeColumns(['fiscalType']);

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getCurrencies = async () => {
    const result = await dispatch(getCurrenciesAsync());
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  useEffect(() => {
    getCurrencies();
  }, [dispatch, gridApi]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.CurrenciesCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateCurrency"),
        `currency/update/${event.data.currencyId}`,
        undefined
      );
      navigate(`/currency/update/${event.data.currencyId}`);
    }
  };

  const handleCreateNewCurrency = () => {
    navigate("/currency/register");
    db.addTab(t("tabs.createCurrency"), "currency/register", undefined);
  };

  return (
    <>

      <ListHeaderComponent
        title={t("titles.currencies")}
        createRecord={handleCreateNewCurrency}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.CurrenciesCreate}
      />
      <AgGridTableComponent
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={currencies}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
    </>
  );
};

export default Currencies;
