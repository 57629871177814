import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useCallback, useEffect, useRef, useState } from "react";
import { CreateOrUpdateInternalSaleDetailDto } from "../../../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDetailDto";
import { SelectOption } from "../../../../models/SelectOption";
import { GenericDocumentDto } from "../../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateInternalSaleDto } from "../../../../models/clientDashboard/EntriesModule/InternalSale/CreateOrUpdateInternalSaleDto";
import useFormData from "../../../../customHooks/useFormData";
import moment from "moment";
import {
  getInternalSalesDataById,
  invalidateInternalSalesAsync,
  validateInternalSalesAsync,
} from "../../../../redux/slices/internalSaleSlice";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import apiService from "../../../../extensions/api";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getItemsWithFilters } from "../../../../redux/slices/itemSlice";
import { getEntitiesByTypeAsync } from "../../../../redux/slices/entitySlice";
import { getUserBranches } from "../../../../redux/slices/userSlice";
import { CopiedDocumentDetailsTable } from "../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { Form, Spin, Tabs } from "antd";
import { Formik } from "formik";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import MainFieldsComponent from "./CreateInternalSaleComponent/MainFieldsComponent";
import { ItemsClassifierDto } from "../../../../models/clientDashboard/Classifier/ItemClassifierDto";
import { convertClassifierValueToObject } from "../../../../helperMethods/convertClassifierValueToObject";
import {
  getClassifierDetailsBySearch,
  getClassifiersByTypeAsync,
  getClassifiersByTypeReturnAsync,
} from "../../../../redux/slices/classifierSlice";
import ClassifierFieldsComponent from "./CreateInternalSaleComponent/ClassifierFieldsComponent";
import { getDocumentTypesWithSignHAsync } from "../../../../redux/slices/documentTypeSlice";
import {
  internalSaleCategories,
  internalSaleDetailsCategories,
} from "../../Entities/EntityClassifiersMapped";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import { ClassifierDetail } from "../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";

export default function CreateInternalSale() {
  const navigate = useNavigate();
  const { type, mode, id, branchId } = useParams<{
    type: string;
    mode: string;
    id?: string;
    branchId?: string;
  }>();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.CreateInternalSale");
  const formikRef = useRef<any>(null);
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );

  const [internalSalesDetails, setInternalSaleDetails] = useState<
    CreateOrUpdateInternalSaleDetailDto[]
  >([]);
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
  const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
    setSupplierOptions(options);
  };
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const [classifiersForInternalSale, setClassifiersForInternalSale] = useState<
    Classifier[]
  >([]);

  const [isDocumentValidated, setIsDocumentValidated] = useState<
    boolean | null
  >(null);

  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const setAdditionalStates = useCallback(
    (
      data: GenericDocumentDto<
        CreateOrUpdateInternalSaleDto,
        CreateOrUpdateInternalSaleDetailDto
      >
    ) => {
      if (data.details) {
        setInternalSaleDetails(data.details);
      }
    },
    []
  );

  const getData = async () => {
    if (id && branchId) {
      const response = await dispatch(
        getInternalSalesDataById({ internalSaleId: id, branchID: branchId })
      );
      const data = response.payload as GenericDocumentDto<
        CreateOrUpdateInternalSaleDto,
        CreateOrUpdateInternalSaleDetailDto
      >;
      if (
        response.type === "InternalSales/getInternalSalesDataById/fulfilled"
      ) {
        const internalSale = response.payload as GenericDocumentDto<
          CreateOrUpdateInternalSaleDto,
          CreateOrUpdateInternalSaleDetailDto
        >;

        setInitialValues(internalSale);
        setSupplierOptions([]);

        const updatedDetails = internalSale.details.map((detail) => ({
          ...detail,
        }));
        setInternalSaleDetails(updatedDetails);

        formikRef?.current?.setValues(internalSale);
      }
      return data;
    }
    return {
      generatedId: mode !== "update" ? uuidv4() : "",
      branchId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
          ? userBranches[0].branchId
          : undefined,
    } as GenericDocumentDto<
      CreateOrUpdateInternalSaleDto,
      CreateOrUpdateInternalSaleDetailDto
    >;
  };

  const { initialValues, setInitialValues } = useFormData<
    GenericDocumentDto<
      CreateOrUpdateInternalSaleDto,
      CreateOrUpdateInternalSaleDetailDto
    >
  >(
    mode === "update"
      ? `internalSale/update/${id}/${branchId}`
      : `internalSale/register`,
    {
      generatedId: mode !== "update" ? uuidv4() : "",
      branchId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
          ? userBranches[0].branchId
          : undefined,
    } as GenericDocumentDto<
      CreateOrUpdateInternalSaleDto,
      CreateOrUpdateInternalSaleDetailDto
    >,
    setAdditionalStates,
    {
      fetchData: getData,
    }
  );

  const removeInternalSalesDetail = (index: number) => {
    setInternalSaleDetails((prevInternalSalesDetails) => {
      const activeInternalSalesDetails = prevInternalSalesDetails.filter(
        (internalSalesDetails) => internalSalesDetails.rowAction !== "D"
      );
      const selectedInternalSalesDetail = activeInternalSalesDetails[index];
      if (
        selectedInternalSalesDetail.internalSaleDetailId === 0 ||
        !selectedInternalSalesDetail.internalSaleDetailId
      ) {
        prevInternalSalesDetails.splice(index, 1);
      } else {
        selectedInternalSalesDetail.rowAction = "D";
      }
      return [...prevInternalSalesDetails];
    });
  };
  const addInternalSalesDetail = (
    internalSalesDetail: CreateOrUpdateInternalSaleDetailDto
  ) => {
    setInternalSaleDetails((prevInternalSalesDetails) => {
      return [...prevInternalSalesDetails, internalSalesDetail];
    });
  };

  const validationSchema = Yup.object({
    branchId: Yup.number().required(t("branchIsRequired")),
    documentTypeId: Yup.number().required(t("documentTypeIsRequired")),
    date: Yup.date().required(t("dateIsRequired")),
  });
  const onFinish = async (
    values: GenericDocumentDto<
      CreateOrUpdateInternalSaleDto,
      CreateOrUpdateInternalSaleDetailDto
    >
  ) => {
    dispatch(setLoadingState(true));
    if (internalSalesDetails.length === 0) {
      toast.error(t("atLeastOneDetailIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }
    if (user?.isMainBranch && !values.branchId) {
      toast.error(t("branchIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }

    values.details = internalSalesDetails;

    values.details.forEach((value) => {
      if (value.k70 === 0) {
        value.k70 = null;
      }
      if (value.k71 === 0) {
        value.k71 = null;
      }
      if (value.k72 === 0) {
        value.k72 = null;
      }
      if (value.k73 === 0) {
        value.k73 = null;
      }
      if (value.k74 === 0) {
        value.k74 = null;
      }
    });

    if (mode === "update" && id) {
      const result = await apiService
        .post(`/api/InternalSales/update`, values)
        .then(async (response) => {
          if (response.status === 200) {
            toast.success(t("updatedSuccessfully"));
            getData();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    } else {
      await apiService
        .post(`/api/InternalSales/create`, values)
        .then(async (response) => {
          toast.success(t("createdSuccessfully"));
          formikRef.current.setFieldValue(
            "genericDocumentId",
            response.data.Data
          );
          formikRef.current.setFieldValue(
            "genericDocumentIdAsString",
            response.data.Data
          );

          const createdId = response.data.Data;

          await db.updateTab(
            `internalSale/register`,
            `internalSale/update/${createdId}/${values.branchId}`,
            t("tabs.updateInternalSales")
          );

          navigate(`/internalSale/update/${createdId}/${values.branchId}`);
        })
        .catch(() => {})
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    }
    dispatch(setLoadingState(false));
  };
  const getClassifiersForDetails = async () => {
    const result = await dispatch(
      getClassifiersByTypeReturnAsync("InternalSaleDetails")
    );
    if (result.type === "Classifiers/getClassifiersByType/fulfilled") {
      const data = result.payload as Classifier[];
      setClassifiersForInternalSale(data);
    }
  };
  useEffect(() => {
    dispatch(getItemsWithFilters([]));
    dispatch(getEntitiesByTypeAsync(true));
    dispatch(getClassifiersByTypeAsync("InternalSales"));
    getClassifiersForDetails();
    dispatch(getDocumentTypesWithSignHAsync("DI"));
    dispatch(getUserBranches());
  }, [dispatch, mode, id, branchId]);
  const [tab, setTab] = useState<any>(null);

  useEffect(() => {
    const updateIndexedDB = async () => {
      setTab(await db.tabs.get(`internalSale/update/${id}`));

      const tab = await db.tabs.get(
        mode === "update"
          ? `internalSale/update/${id}`
          : "internalSale/register"
      );
      if (tab) {
        await db.tabs.put({
          ...tab,
          data: {
            ...tab.data,
            details: internalSalesDetails,
          },
        });
      }
    };

    updateIndexedDB();
  }, [internalSalesDetails, id]);

  const fetchClassifiers = async (
    searchInput: string,
    classifierId: number
  ) => {
    const result = await dispatch(
      getClassifierDetailsBySearch({
        searchInput: searchInput,
        classifierId: classifierId,
      })
    );

    if (result.payload !== "An error occurred") {
      const payload = result.payload as ClassifierDetail[];
      const options = payload.map((classifier) => ({
        label: `${classifier?.description}`,
        value: classifier?.classifierDetailId,
      }));
      return options;
    }
    return [];
  };

  const handleSubmitValidationForm = async (
    setTouched: ({}: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = await validateForm();
    setTouched({
      genericDocumentId: true,
      genericDocumentIdAsString: true,
      branchId: true,
      documentTypeId: true,
      date: true,
      documentNo: true,
      header: true,
      generatedId: true,
      registeredBy: true,
      validated: true,
      details: true,
      registrationDate: true,
      comment: true,
      parentId: true,
      k65: true,
      k66: true,
      k67: true,
      k68: true,
      k69: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };
  const handleInternalSalesValidation = async (
    internalSaleId: string,
    branchId: string
  ) => {
    const result = await dispatch(
      validateInternalSalesAsync({
        internalSaleId: internalSaleId,
        branchID: branchId ?? "",
      })
    );
    if (result.type === "InternalSales/validateInternalSales/fulfilled") {
      toast.success(t("validatedSuccessfully"));
      formikRef.current.setFieldValue("validated", true);
    } else {
      toast.error(t("ValidationFailed"));
    }
  };

  const handleInternalSalesInvalidation = async (
    internalSaleId: string,
    branchId: number
  ) => {
    const result = await dispatch(
      invalidateInternalSalesAsync({
        internalSaleId: internalSaleId,
        branchID: branchId,
      })
    );
    if (result.type === "InternalSales/invalidateInternalSales/fulfilled") {
      toast.success(t("invalidatedSuccessfully"));
      formikRef.current.setFieldValue("validated", false);
    } else {
      toast.error(t("invalidationFailed"));
    }
  };

  const isInvalidateButtonDisabled = (
    values: GenericDocumentDto<
      CreateOrUpdateInternalSaleDto,
      CreateOrUpdateInternalSaleDetailDto
    >
  ) => {
    return isLoading || !values.validated;
  };
  const copyDetails = () => {
    const details: CopiedDocumentDetailsTable<CreateOrUpdateInternalSaleDetailDto> =
      {
        id: "InternalSaleDetails",
        list: internalSalesDetails,
      };
    db.upsertGenericData(details);
  };

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={
          initialValues ??
          ({} as GenericDocumentDto<
            CreateOrUpdateInternalSaleDto,
            CreateOrUpdateInternalSaleDetailDto
          >)
        }
        validationSchema={validationSchema}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          handleSubmit,
          submitForm,
          validateForm,
          setTouched,
          setFieldValue,
        }) => (
          <>
            <FormHeaderOptions
              title={
                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
              }
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createButton")}
              submitButtonIsDisabled={
                isLoading ||
                internalSalesDetails.filter(
                  (detail) => detail.rowAction !== "D"
                ).length === 0 ||
                values.validated
              }
              createAccessEnum={MenuOptionEnum.InternalSaleCreate}
              validateAccessEnum={MenuOptionEnum.InternalSaleValidate}
              validateButtonIsDisabled={
                isLoading || mode !== "update" || values.validated
              }
              validateButtonText={t("validateButton")}
              handleDocumentValidation={async () => {
                if (values.genericDocumentIdAsString) {
                  await handleInternalSalesValidation(
                    values.genericDocumentIdAsString,
                    values?.branchId?.toString() ?? ""
                  );
                }
              }}
              invalidateAccessEnum={MenuOptionEnum.InternalSaleInvalidate}
              invalidateButtonText={t("invalidateButton")}
              invalidateButtonIsDisabled={isInvalidateButtonDisabled(values)}
              handleDocumentInvalidation={async () => {
                await handleInternalSalesInvalidation(
                  values.genericDocumentIdAsString ?? "",
                  values?.branchId ?? 0
                );
              }}
              copyButtonIsDisabled={
                isLoading ||
                mode !== "update" ||
                internalSalesDetails.length === 0
              }
              copyButtonText={t("CopyButton")}
              handleCopyDetails={
                mode === "update" ? () => copyDetails() : undefined
              }
            />

            <Form onFinish={handleSubmit} layout="vertical">
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: t("internalSale"),
                    key: "1",
                    children: (
                      <MainFieldsComponent
                        fetchClassifiers={fetchClassifiers}
                        setClassifiersForInternalSale={
                          setClassifiersForInternalSale
                        }
                        classifiers={classifiers}
                        supplierOptions={supplierOptions}
                        setInternalSaleDetails={setInternalSaleDetails}
                        values={values}
                        classifiersForInternalSale={classifiersForInternalSale}
                        internalSalesDetails={internalSalesDetails}
                        addInternalSalesDetail={addInternalSalesDetail}
                        removeInternalSalesDetail={removeInternalSalesDetail}
                        type={type ?? ""}
                        mode={mode ?? ""}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                  {
                    label: t("classifier"),
                    key: "2",
                    children: (
                      <ClassifierFieldsComponent
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ),
                  },
                ]}
              />
              <HandleFormDataForTabSaving
                tabPath={
                  mode === "update"
                    ? `internalSale/update/${id}/${branchId}`
                    : `internalSale/register`
                }
                additionalStates={{
                  details: internalSalesDetails,
                }}
              />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
}
