// AppLoggedIn.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import { useAppAuth } from "../../customHooks/useAppAuth";
import Login from "./Login";

const ProtectedAppLoggedIn: React.FC = () => {
  const isAppLoggedIn = useAppAuth();
  return isAppLoggedIn ? <Outlet /> : <Login />;
};

export default ProtectedAppLoggedIn;
