import { AgGridReact } from "ag-grid-react";
import { GetCompanyServers } from "../../../../models/dashboard/Company/SingleCompany/CompanyDetails";
import { ColDef } from "ag-grid-community";
import { Button, Empty, Popconfirm, Spin, Tooltip } from "antd";
import { HighlightOutlined } from "@ant-design/icons";
import apiService from "../../../../extensions/api";
import { toast } from "react-toastify";
import React from "react";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import WithPermission from "../../../Authorization/WithPermission";
import { MenuAdminOptionEnum } from "../../../../enums/MenuAdminOptionEnum";

interface CompanyServersComponentProps {
  servers: GetCompanyServers[];
}

const CompanyServersComponent: React.FC<CompanyServersComponentProps> = ({
  servers,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const colDefs: ColDef[] = [
    {
      headerName: "#",
      field: "companyServersID",
      sortable: true,
      filter: true,
      width: 60,
    },
    {
      headerName: "Server ID",
      field: "serverID",
      sortable: true,
      filter: true,
      width: 70,
    },
    {
      headerName: "Emri skemes",
      field: "schemaName",
      sortable: true,
      filter: true,
    },

    {
      headerName: "Emri serverit",
      field: "serverName",
      sortable: true,
      filter: true,
    },
    { headerName: "Domain", field: "domain", sortable: true, filter: true },
    {
      headerName: "Bazë arkivë",
      field: "isArchive",
      sortable: true,
      filter: true,
      width: 130,
    },
    {
      headerName: "Aktiv",
      field: "isActive",
      sortable: true,
      filter: true,
      width: 100,
    },
    {
      field: "#",
      headerName: "#",
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuAdminOptionEnum.AdminClientExecuteScript}
          children={
            <Tooltip placement="left" title={"Ekzekuto skripten"}>
              <Popconfirm
                placement="top"
                title={
                  "A jeni te sigurt qe deshironi te ekzekutoni skripten e databazës?"
                }
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleExecuteScriptChanges(params.data.schemaName)}
              >
                <Button type="text" icon={<HighlightOutlined />} danger></Button>
              </Popconfirm>
            </Tooltip>
          }
        />
      ),
      width: 70,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
  };

  const handleExecuteScriptChanges = async (schemaId: string) => {
    setIsLoading(true);

    toast.info(
      "Po ekzekutohet skripta e databazës, ju lutem mos e ndërpreni procesin"
    );

    await apiService
      .get(
        `/api/dashboard/DatabaseScripts/executeDataBaseScript?schemaName=${schemaId}`
      )
      .then((response) => {
        toast.success("Skripta u ekzekutua me sukses");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          `Gabim gjate ekzekutimit te skriptes - ${error?.response?.data?.message}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <>
        <Spin tip="Loading..." spinning={isLoading}>
          <div
            className="ag-theme-balham"
            style={{ height: "500px", width: "100%" }}
          >
            {servers.length > 0 ? (
              // <AgGridReact
              //   rowData={servers}
              //   columnDefs={colDefs}
              //   defaultColDef={defaultColDef}
              //   pagination={true}
              //   paginationPageSize={50}
              // />
              <AgGridTableComponent
                // defaultColDef={columnDefs}
                isLoading={false}
                rowData={servers}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={20}
              />
            ) : (
              <Empty description="Nuk ka servera për të listuar" />
            )}
          </div>
        </Spin>
      </>
    </>
  );
};

export default CompanyServersComponent;
