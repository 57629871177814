import { ValueFormatterParams } from "ag-grid-community";

export const formatPurchaseColumnValues = (params: ValueFormatterParams) => {
  const sellingPrice = params.value as number;
  if (typeof sellingPrice === "number") {
    let formattedPrice = sellingPrice?.toFixed(2);
    if (formattedPrice?.split(".")[1]?.length === 1) {
      formattedPrice += "0";
    }
    return formattedPrice;
  }

  if (isNaN(params.value)) {
    return 0;
  }

  return params.value;
};
