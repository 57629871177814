import { useEffect, useState } from "react";
interface ContextMenuProps {
    gridRef: any;
    contextMenuPosition: { x: number, y: number };
    showContextMenu: boolean;
    onCopy: () => void;
    onDelete?: () => void;
  }
  
  export default function AgGridCustomContextMenu({
    gridRef,
    contextMenuPosition,
    showContextMenu,
    onCopy,
    onDelete,
  }: ContextMenuProps) {
  
    return (
      <div style={{
        position:"absolute",
        top: contextMenuPosition.y,
        left:contextMenuPosition.x,
        display:showContextMenu?'block' : 'none',
        background: 'white',
        border: '1px solid #ccc',
        zIndex: 1000,
        padding: '10px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
      }}>
        <p onClick={onCopy}>Copy Row</p>
        <p onClick={onDelete}>Delete Row</p>
      </div>
    );
  }
  