import { Input, Form } from "antd";
import { ErrorMessage, useField } from "formik";
import classes from "./CustomInputStyle.module.css";
import { useState } from "react";
import FloatLabel from "./FloatLabel";

interface Props {
  type?: string;
  name: string;
  label: string;
  rules?: any[];
  className?: string;
  style?: any;
  disabled?: boolean;
  value?: any;
  required?: boolean;
  min?: string; // For date input
  max?: string; // For date input
  multiple?: boolean; // For file input
  accept?: string;
  maxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addonAfter?: string;
  prefix?: any;
}

const FormInput = ({
  type = "text",
  name,
  label,
  rules,
  className = "",
  style = {},
  disabled = false,
  value,
  required,
  min,
  max,
  multiple,
  accept,
  addonAfter,
  maxLength = Infinity,
  onChange,
  prefix,
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const hasError = meta.touched && meta.error;
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setSelectedFiles(filesArray);
      helpers.setValue(filesArray);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (type === "number") {
      if (onChange) {
        onChange(event);
      } else {
      helpers.setValue(inputValue);
      }
    } else {
      if (onChange) {
        onChange(event);
      } else {
        field.onChange(event);
      }
    }
  };
  return (
    <Form.Item
      required={required}
      // label={label}
      // validateStatus={hasError ? 'error' : ''}
      // help={hasError ? meta.error : ''}
      className={className}
      rules={rules}
      style={style}
    >
      <FloatLabel label={label} value={value} type={type} required={required}>
        {type === "file" ? (
          <Input
            type="file"
            multiple={multiple}
            name={name}
            onChange={handleFileChange}
            disabled={disabled}
            accept={accept}
            className={name == "documentTypeId" ? "center-div" : ""}
          />
        ) : (
          <Input
            {...field}
            prefix={prefix}
            value={value}
            type={type}
            disabled={disabled}
            min={type === "date" ? min : undefined}
            max={type === "date" ? max : undefined}
            multiple={type === "file" ? multiple : undefined}
            onChange={handleChange}
            maxLength={maxLength}
            addonAfter={addonAfter}
            autoComplete="off"
            size="small"
            // style={style}
          />
        )}
      </FloatLabel>
      <>
        <ErrorMessage
          name={name}
          component="div"
          className={classes.errorMessage}
        />
      </>
    </Form.Item>
  );
};

export default FormInput;
