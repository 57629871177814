import { useSelector } from "react-redux";
import { RootState } from "../../redux";

export default function UserIcon() {
  const theme = useSelector((state:RootState)=>state.appearance.theme);
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 18.3327C14.6025 18.3327 18.3334 14.6017 18.3334 9.99935C18.3334 5.39698 14.6025 1.66602 10.0001 1.66602C5.39771 1.66602 1.66675 5.39698 1.66675 9.99935C1.66675 14.6017 5.39771 18.3327 10.0001 18.3327Z"
        stroke={theme==='dark'?"rgb(187, 187, 187)":"#121212"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.834C11.3807 10.834 12.5 9.7147 12.5 8.33398C12.5 6.95327 11.3807 5.83398 10 5.83398C8.61929 5.83398 7.5 6.95327 7.5 8.33398C7.5 9.7147 8.61929 10.834 10 10.834Z"
        stroke={theme==='dark'?"rgb(187, 187, 187)":"#121212"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 17.2177V15.8327C5.83325 15.3907 6.00885 14.9667 6.32141 14.6542C6.63397 14.3416 7.05789 14.166 7.49992 14.166H12.4999C12.9419 14.166 13.3659 14.3416 13.6784 14.6542C13.991 14.9667 14.1666 15.3907 14.1666 15.8327V17.2177"
        stroke={theme==='dark'?"rgb(187, 187, 187)":"#121212"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
