import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { Spin, Typography } from "antd";
import {
  formatDateAsDDMMYYYY,
  formatDateTimeAsDDMMYYYY,
} from "../../../helperMethods/formatDate";
import { useCallback, useEffect, useState } from "react";
import apiService from "../../../extensions/api";
import GetDataWithPagination from "../../../models/GetDataWithPagination";
import { GetSubscriptionsDto } from "../../../models/dashboard/Subscriptions/GetSubscriptionsDto";
import { ApiResponse } from "../../../models/ApiResponse";// import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
// import { MenuModule } from "@ag-grid-enterprise/menu";
// import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";

// ModuleRegistry.registerModules([
//   ColumnsToolPanelModule,
//   MenuModule,
//   ServerSideRowModelModule,
// ]);

const { Title, Text, Link } = Typography;

const SubscriptionListComponent: React.FC = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const paginationPageSize: number = 500;
  const [data, setData] = useState<GetSubscriptionsDto[]>([]);

  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const columnDefs: ColDef[] = [
    { field: "companyName", headerName: "Emri klientit" },
    {
      field: "vatNumber",
      headerName: "Numri fiskal",
      cellRenderer: (params: any) => {
        return <Text type="secondary">{params.data.vatNumber}</Text>;
      },
    },
    {
      field: "subscriptionPlanName",
      headerName: "Plan abonimi",
      cellRenderer: (params: any) => {
        return <Text type="secondary">{params.data.subscriptionPlanName}</Text>;
      },
    },
    {
      field: "subscriptionStartDate",
      headerName: "Prej Dates",
      cellRenderer: (params: any) => {
        return (
          <Text>{formatDateAsDDMMYYYY(params.data.subscriptionStartDate)}</Text>
        );
      },
    },
    {
      field: "subscriptionEndDate",
      headerName: "Deri me daten",
      cellRenderer: (params: any) => {
        return (
          <Text>{formatDateAsDDMMYYYY(params.data.subscriptionEndDate)}</Text>
        );
      },
    },
    {
      field: "daysUntilExpiration",
      headerName: "Ditë deri në skadim",
      cellRenderer: (params: any) => {
        if (params.data.daysUntilExpiration < 0) {
          return (
            <Text type="danger" strong>
              Skaduar
            </Text>
          );
        } else {
          return (
            <Text strong type="success">
              {params.data.daysUntilExpiration} ditë
            </Text>
          );
        }
      },
    },
    {
      field: "isActive",
      headerName: "Aktiv",
      cellRenderer: (params: any) => {
        if (params.data.isActive) {
          return (
            <Text strong type="success">
              Po
            </Text>
          );
        } else {
          return (
            <Text type="danger" strong>
              Jo
            </Text>
          );
        }
      },
    },
    {
      field: "entryDate",
      headerName: "Data regjistrimit",
      cellRenderer: (params: any) => {
        return (
          <Text type="secondary">
            {formatDateTimeAsDDMMYYYY(params.data.entryDate)}
          </Text>
        );
      },
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    flex: 1,
    filter: true,
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await apiService.get<
          ApiResponse<GetDataWithPagination<GetSubscriptionsDto>>
        >("/api/dashboard/subscriptions", {
          params: {
            pageNumber: page,
            pageSize: pageSize,
          },
        });

        setData(response.data.Data.list);
        // setPageSize(response.data.Data.pageSize);
        // setPage(response.data.Data.pageNumber);
      } catch (error) { }
    };

    fetchSubscriptions();
  }, [page, pageSize]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    //get current page number
    // const currentPage = params.api!.paginationGetCurrentPage();
    // console.log(currentPage);
    // var datasource = getSubscriptionsWithPagination(1, 5000);
    // params.api!.setGridOption("serverSideDatasource", datasource);
  }, []);

  return (
    <>
      <Spin tip="Loading..." spinning={loadScreen}>
        <div className="ag-theme-balham" style={{ height: 550, marginTop: 15 }}>
          <AgGridTableComponent
            defaultColDef={columnDefs}
            isLoading={false}
            rowData={data}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={20}
          />
        </div>
      </Spin>
    </>
  );
};

export default SubscriptionListComponent;
