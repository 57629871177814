import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentTypeState } from "../../models/clientDashboard/DocumentType/DocumentTypeState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { DocumentTypeModel } from "../../models/clientDashboard/DocumentType/DocumentTypeModel";


const initialState: DocumentTypeState = {
    documentTypes: [],
    error: null,
};

const DocumentTypeSlice = createSlice({
    name: "documentType",
    initialState,
    reducers: {
        getDocumentTypesStart(state: DocumentTypeState) {
            setLoading(true);
            state.error = null;
        },
        getDocumentTypesSuccess(
            state: DocumentTypeState,
            action: PayloadAction<DocumentTypeModel[]>
        ) {
            setLoading(false);
            state.documentTypes = action.payload;
        },
        getDocumentTypesFailure(
            state: DocumentTypeState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
       
        deleteDocumentTypeFromState(state: DocumentTypeState, action: PayloadAction<number>) {
            setLoading(false);
            const documentId = action.payload;
            state.documentTypes = state.documentTypes.filter(documentType => documentType.documentId !== documentId);
        },
        addNewDocumentTypeToState(state: DocumentTypeState, action: PayloadAction<DocumentTypeModel>) {
            state.documentTypes.push(action.payload);
        }
    }
});

export const getDocumentTypesWithSignHAsync = createAsyncThunk(
    "DocumentTypes/getDocumentTypesBySign",
    async (sign: string, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(getDocumentTypesStart());
            const response = await apiService.get<ApiResponse<DocumentTypeModel[]>>(
                `/api/DocumentTypes/GetDocumentTypesBySign?sign=${sign}`
            );
            dispatch(getDocumentTypesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getDocumentTypesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);




export const {
    getDocumentTypesStart,
    getDocumentTypesSuccess,
    getDocumentTypesFailure,
    deleteDocumentTypeFromState,
    addNewDocumentTypeToState
} = DocumentTypeSlice.actions;

export default DocumentTypeSlice.reducer;