import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentMethodsState } from "../../models/states/PaymentMethodsState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { PaymentMethods } from "../../models/clientDashboard/PaymentMethods/PaymentMethods";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";

const initialState: PaymentMethodsState = {
    paymentMethods: [],
    error: null,
};

const PaymentMethodsSlice = createSlice({
    name: "paymentMethods",
    initialState,
    reducers: {
        getPaymentMethodsStart(state: PaymentMethodsState) {
            setLoading(true);
            state.error = null;
        },
        getPaymentMethodsSuccess(
            state: PaymentMethodsState,
            action: PayloadAction<PaymentMethods[]>
        ) {
            setLoading(false);
            state.paymentMethods = action.payload;
        },
        getPaymentMethodsFailure(
            state: PaymentMethodsState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
       
        deletePaymentMethodsFromState(state: PaymentMethodsState, action: PayloadAction<number>) {
            setLoading(false);
            const paymentMethodId = action.payload;
            state.paymentMethods = state.paymentMethods.filter(paymentMethods => paymentMethods.paymentMethodId !== paymentMethodId);
        },
        addNewPaymentMethodsToState(state: PaymentMethodsState, action: PayloadAction<PaymentMethods>) {
            state.paymentMethods.push(action.payload);
        }
    },
});

export const getPaymentMethodsAsync = createAsyncThunk(
    "PaymentMethods/getPaymentMethods",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const paymentMethod = state.paymentMethod.paymentMethods

        if (paymentMethod.length > 0) {
            return paymentMethod;
        }

        try {
            dispatch(getPaymentMethodsStart());
            const response = await apiService.get<ApiResponse<PaymentMethods[]>>(
                "api/PaymentMethod"
            );
            // toast.success("Brands were successfully retrieved!");
            dispatch(getPaymentMethodsSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getPaymentMethodsFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);




export const getPaymentMethodsDataById = createAsyncThunk(
    "PaymentMethods/getPaymentMethodsDataById",
    async (paymentMethodId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<PaymentMethods>>(
                `/api/PaymentMethod/${paymentMethodId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deletePaymentMethodsAsync = createAsyncThunk(
    "PaymentMethods/deletePaymentMethods",
    async (paymentMethodId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/PaymentMethods?paymentMethodId=${paymentMethodId}`
            );
            dispatch(deletePaymentMethodsFromState(paymentMethodId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getPaymentMethodsStart,
    getPaymentMethodsSuccess,
    getPaymentMethodsFailure,
    deletePaymentMethodsFromState,
    addNewPaymentMethodsToState
} = PaymentMethodsSlice.actions;

export default PaymentMethodsSlice.reducer;