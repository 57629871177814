import CryptoJS from 'crypto-js';
import { ConfigurationFromToken } from '../models/Configurations/ConfigurationFromToken';

export const encryptData = (data: any): string => {
    try{
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'ge/aeag-324.geaeg').toString();
    return encryptedData;
    }catch(e){
        console.log(e);
        return "test";
    }
  };

  export const decryptData = (encryptedData: string): any | null => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, 'ge/aeag-324.geaeg');
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (error) {
      console.error('Error decrypting configurations:', error);
      return null;
    }
  };