import { useEffect, useState } from "react";
import { PropertyDto } from "../models/clientDashboard/Filter/PropertyDto";
import { AppDispatch } from "../redux";
import { useDispatch } from "react-redux";
import { FilterData } from "../models/clientDashboard/Filter/FilterData";
interface Props {
    getFilterColumnsMethod: () => Promise<any>;
    clearFiltersMethod: () => void;
    filterSubmitMethod: (filterData: FilterData[]) => Promise<void>;
}

const useFilters = ({ getFilterColumnsMethod, clearFiltersMethod, filterSubmitMethod }: Props) => {
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [filterColumns, setFilterColumns] = useState<PropertyDto[]>([]);
    const [selectedFilterColumns, setSelectedFilterColumns] = useState<string[]>([]);
    const [filterInputs, setFilterInputs] = useState<PropertyDto[]>([]);
    const [inputValues, setInputValues] = useState<Record<string, string>>({});
    const [loadingFilterColumns, setLoadingFilterColumns] = useState<boolean>(false);
    const [filterButtonDisabled, setFilterButtonDisabled] = useState<boolean>(true);
    const [clearFilterButtonDisabled, setClearFilterButtonDisabled] = useState<boolean>(true);
    const [inputsChanged, setInputsChanged] = useState<boolean>(false);

    const handleCheckboxChange = (columnName: string) => {
        setSelectedFilterColumns(prevState =>
            prevState.includes(columnName)
                ? prevState.filter(name => name !== columnName)
                : [...prevState, columnName]
        );
    };

    const clearAllCheckboxes = () => {
        setSelectedFilterColumns([]);
    };

    const handleClearFilters = async () => {
        setClearFilterButtonDisabled(true);
        setFilterButtonDisabled(true);
        await clearFiltersMethod();
        setFilterInputs([]);
        setFilterColumns([]);
        setSelectedFilterColumns([]);
        setInputValues({});
        setInputsChanged(false); 
        setClearFilterButtonDisabled(false);
        setFilterButtonDisabled(false);
    };

    const handleGetFilterColumns = async () => {
        setLoadingFilterColumns(true);
        if (filterColumns.length === 0) {
            const result = await getFilterColumnsMethod();
            if(result !== null){
            const properties = result.payload;
            setFilterColumns(properties);
        }
        }
        setLoadingFilterColumns(false);
    };

    const handleInputChange = (name: string, value: any) => {
        setInputValues(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFilterModalSubmit = () => {
        const selectedColumnsObjects = selectedFilterColumns?.map(columnName =>
            filterColumns.find(column => column.name === columnName)
        ).filter((column): column is PropertyDto => column !== undefined);
        setFilterInputs(selectedColumnsObjects);
        setFilterModal(false);
    };

    const handleFilterSubmit = async () => {
        setFilterButtonDisabled(true);
        setClearFilterButtonDisabled(true);
        const filters: FilterData[] = filterInputs?.map((input) => ({
            key: input.name,
            value: inputValues[input.name]?.toString() || '',
            isForeignKey: input.isForeignKey,
            isClassifier: input.isClassifier,
            classifierName: input.classifierName
        }));

        await filterSubmitMethod(filters);
        setInputsChanged(false); 
        setFilterButtonDisabled(false);
        setClearFilterButtonDisabled(false);
    };

    useEffect(() => {
        setInputsChanged(true);
    }, [inputValues]);

    useEffect(() => {
        setFilterButtonDisabled(!inputsChanged);
    }, [inputsChanged]);

    return {
        filterState: {
            filterModal,
            setFilterModal,
            filterColumns,
            setFilterColumns,
            selectedFilterColumns,
            setSelectedFilterColumns,
            filterInputs,
            setFilterInputs,
            inputValues,
            setInputValues,
            handleCheckboxChange,
            clearAllCheckboxes,
            handleClearFilters,
            handleGetFilterColumns,
            handleInputChange,
            handleFilterModalSubmit,
            handleFilterSubmit,
            loadingFilterColumns,
            filterButtonDisabled,
            clearFilterButtonDisabled,
            inputsChanged
        }
    };
};

export default useFilters;
