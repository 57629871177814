import { ColDef } from "ag-grid-community";
import { CreateOrUpdateGoodsTransferDetails } from "../../../../models/clientDashboard/InternalMovements/GoodsTransfers/CreateOrUpdateGoodsTransferDetails";
import { RefObject } from "react";
import { GoodsTransferDto } from "../../../../models/clientDashboard/InternalMovements/GoodsTransfers/GoodsTransferDto";
import Button from "antd/es/button";
import { DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

export const getGoodsTransferDetailColumns = (
  values: GoodsTransferDto,
  removeGoodsTransferDetail: (index: number) => void,
  t: (text: string) => string,
  searchItemInputRef: RefObject<any>,
  mode: string,
  isDocumentType24:boolean,
  isDocumentType23:boolean
) => {
 
  const isCorrectedDocument=values?.isCorrectedDocument;

  const columnDefs: ColDef<CreateOrUpdateGoodsTransferDetails>[] = [
    {
      headerName: t("tableHeaders.itemId"),
      field: "itemId",
      sortable: false,
      width: 100,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.itemDescription"),
      field: "itemDescription",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.unitDescription"),
      field: "unitDescription",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.sentQuantity"),
      field: "sentQuantity",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
      editable: isDocumentType23 && !isCorrectedDocument,
      valueSetter: (params) => {
        const newValue = parseFloat(params.newValue as string);

        if (isNaN(newValue) || newValue <= 0) {
          console.error("Invalid value: must be a positive number greater than 0.");
          return false;
        }

        if (params.data && typeof params.colDef.field === 'string') {
          (params.data as any)[params.colDef.field] = newValue;
          return true;
        }
        return false;
      },
      valueParser: (params) => {
        return parseFloat(params.newValue as string);
      }
    },
    {
      headerName: t("tableHeaders.receivedQuantity"),
      field: "receivedQuantity",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
      editable: isDocumentType24, 
      valueSetter: (params) => {
        if (!isDocumentType24) return false;

        const newValue = parseFloat(params.newValue as string);

        if (isNaN(newValue) || newValue < 0 ) {
          toast.error(t("tableHeaders.errorReceivedQuantityNegative"))
          return false;
        }
        if ((params.data.sentQuantity && newValue > params.data.sentQuantity)) {
          toast.error(t("tableHeaders.errorReceivedQuantity"))
          return false;
        }

        if (params.data && typeof params.colDef.field === 'string') {
          (params.data as any)[params.colDef.field] = newValue;
          return true;
        }
        return false;
      },
      valueParser: (params) => {
        return parseFloat(params.newValue as string);
      }
    },
    {
      headerName: t("tableHeaders.cost"),
      field: "cost",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.averageCost"),
      field: "averageCost",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.vat"),
      field: "vat",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.sellingPrice"),
      field: "sellingPrice",
      sortable: false,
      filter: false,
      suppressMovable: true,
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.options"),
      suppressMenu: true,
      maxWidth: 100,
      cellRenderer: (params: any) => {
        return (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => removeGoodsTransferDetail(params.rowIndex)}
            style={{ color: "#007FFF" }}
            disabled={isCorrectedDocument}
          ></Button>
        );
      },
    }
  ];

  return columnDefs;
};
