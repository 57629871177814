import { Button, Modal, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GoodsReturnReason } from "../../../models/clientDashboard/GoodsReturnReasons/GoodsReturnReason";
import { deleteGoodsReturnReasonAsync, getGoodsReturnReasonsAsync, getGoodsReturnReasonsSuccess } from "../../../redux/slices/goodsReturnReasonSlice";
import { setEditingCell } from "../../../helperMethods/setEditingCell";
import apiService from "../../../extensions/api";
import _ from "lodash";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { CreateGoodsReturnReasonDto } from "../../../models/clientDashboard/GoodsReturnReasons/CreateGoodsReturnReasonDto";
import { PlusOutlined } from "@ant-design/icons";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import WithPermission from "../../Authorization/WithPermission";


const { Title, Text } = Typography;

const GoodsReturnReasons = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.GoodsReturnReasonsList");
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact | null>(null);
  const [goodsReturnReasons, setGoodsReturnReasons] = useState<GoodsReturnReason[]>([]);
  const [menuGoodReturnReasonsDisabled, setGoodReturnReasonsDisabled] = useState(false);

  const getGoodsReturnReasons = async () => {
    const response = await dispatch(getGoodsReturnReasonsAsync());
    if (response.type === "GoodsReturnReasons/getGoodsReturnReasons/fulfilled") {
      setGoodsReturnReasons(response.payload as GoodsReturnReason[]);
    }
  };

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['isActive']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    getGoodsReturnReasons();
  }, []);

  const handleAddGoodsReturnReason = () => {
    const newGoodsReturnReason = {
      goodsReturnReasonId: 0,
      description: "",
      isActive: true

    };
    setGoodsReturnReasons([...goodsReturnReasons, newGoodsReturnReason]);
    setTimeout(() => {
      setEditingCell(gridRef, "description");
    }, 0);
  };

  const deleteGoodsReturnReason = async (goodsReturnReasonID: number) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      dispatch(deleteGoodsReturnReasonAsync(goodsReturnReasonID));
      setGoodsReturnReasons((prevValues) => prevValues.filter((goodsReturnReason) => goodsReturnReason.goodsReturnReasonId !== goodsReturnReasonID));
    }
  };

  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const [colDefs, setColDefs] = useState([
    {
      field: "goodsReturnReasonId",
      headerName: "ID", suppressMenu: true,
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column" >{params.data.goodsReturnReasonId}</Text>;
      },
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"), suppressMenu: true,
      editable: true,
      onCellValueChanged: (params: any) => {
        try {
          const value = params.newValue;
          const data = params.data;
          if (value === null || (value?.trim() === "" && data.goodsReturnReasonId !== 0)) {
            setGoodsReturnReasons((prevValues) => {
              const goodsReturnReasons = [...prevValues];
              const goodsReturnReason = { ...goodsReturnReasons.find((goodsReturnReason) => goodsReturnReason.goodsReturnReasonId === data.goodsReturnReasonId) };
              if (goodsReturnReason) {
                goodsReturnReason.description = data.description;
              }
              return goodsReturnReasons;
            });
            return;
          }
          else if (value === null || (value.trim() === "" && data.goodsReturnReasonId === 0)) {
            setGoodsReturnReasons((prevValues) => prevValues.filter((goodsReturnReason) => goodsReturnReason.goodsReturnReasonId !== 0));
            return;
          }
          params?.node?.setDataValue("description", value);
          params.api.stopEditing(true);
          if (params.data.goodsReturnReasonId === 0) {
            const newGoodsReturnReason: CreateGoodsReturnReasonDto = {
              description: value,
              isActive: true,
            };
            apiService
              .post("/api/GoodsReturnReasons", newGoodsReturnReason)
              .then(async (response) => {
                try {
                  dispatch(getGoodsReturnReasonsSuccess([]));
                  getGoodsReturnReasons();
                } catch (error) {
                  console.error("Error fetching goodsReturnReasons:", error);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            const updatedGoodsReturnReason: GoodsReturnReason = {
              goodsReturnReasonId: params.data.goodsReturnReasonId,
              description: value,
              isActive: params.data.isActive
            };
            apiService
              .put("/api/GoodsReturnReasons", updatedGoodsReturnReason)
              .then((response) => {
                dispatch(getGoodsReturnReasonsSuccess([]));
                getGoodsReturnReasons();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } catch (e) {
          console.log(e);
        }
      },
    },

    {
      field: "isActive",
      headerName: t("tableHeaders.isActive"),
      editable: true, suppressMenu: true,
      onCellValueChanged: (params: any) => {
        try {
          const value = params.newValue;
          const data = params.data;

          params?.node?.setDataValue("isActive", value);
          params.api.stopEditing(true);

          const updatedGoodsReturnReason: GoodsReturnReason = {
            goodsReturnReasonId: params.data.goodsReturnReasonId,
            isActive: value,
            description: params.data.description
          };
          apiService
            .put("/api/GoodsReturnReasons", updatedGoodsReturnReason)
            .then((response) => {
              dispatch(getGoodsReturnReasonsSuccess([]));
              getGoodsReturnReasons();
            })
            .catch((error) => {
              console.log(error);
            });
        }
        catch (e) {
          console.log(e);
        }
      }
    }, {
      field: "options", suppressMenu: true,
      headerName: t("tableHeaders.options"),
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.GoodsReturnReasonsDelete}
          children={
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => deleteGoodsReturnReason(params.data.goodsReturnReasonId)}
              style={{ color: "#007FFF" }}
            ></Button>
          }
        />
      ),
      width: 100,
    },
  ]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  const mutableObject = _.cloneDeep(goodsReturnReasons)
  return (
    <>
      <ListHeaderComponent
        title={t("titles.goodsReturnReasons")}
        createRecord={handleAddGoodsReturnReason}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.GoodsReturnReasonsCreate}
      />
      <AgGridTableComponent
        gridRef={gridRef}
        isLoading={isLoading}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        rowData={mutableObject}
        pagination={true}
        paginationPageSize={20}
        suppressCellFocus={true}
        stopEditingWhenCellsLoseFocus={true}
        enableCellTextSelection={true}
        onCellEditingStopped={(params: any) => {
          const data = params.data;
          if (data?.goodsReturnReasonId === 0 && data?.description?.trim() === "") {
            setGoodsReturnReasons((prevValues) => prevValues.filter((goodsReturnReason) => goodsReturnReason.goodsReturnReasonId !== 0));
          }
          else if (data?.goodsReturnReasonId !== 0 && data?.description?.trim() === "") {
            setGoodsReturnReasons((prevValues) => {
              const goodsReturnReasons = [...prevValues];
              const goodsReturnReason = goodsReturnReasons.find((goodsReturnReason) => goodsReturnReason.goodsReturnReasonId === data.goodsReturnReasonId);
              if (goodsReturnReason) {
                goodsReturnReason.description = data.description;
              }
              return goodsReturnReasons;
            });
          }
        }}
      />
    </>
  );
};

export default GoodsReturnReasons;
