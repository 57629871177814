import { PaymentMethodsState } from "../../models/states/PaymentMethodsState";
import { PaymentMethodTypeDto } from "../../models/clientDashboard/PaymentMethods/PaymentMethodTypeDto";
import { PaymentMethodTypeState } from "../../models/states/PaymentMethodTypeState";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setLoading, setLoadingState } from "./loadingSlice";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";

const initialState: PaymentMethodTypeState = {
  paymentMethodTypes: [],
  error: null,
};

const PaymentMethodTypesSlice = createSlice({
  name: "paymentMethodTypes",
  initialState,
  reducers: {
    getPaymentMethodTypesStart(state: PaymentMethodTypeState) {
      setLoading(true);
      state.error = null;
    },
    getPaymentMethodTypesSuccess(
      state: PaymentMethodTypeState,
      action: PayloadAction<PaymentMethodTypeDto[]>
    ) {
      setLoading(false);
      console.log(action.payload, 'asds')
      state.paymentMethodTypes = action.payload;
    },
    getPaymentMethodTypesFailure(
      state: PaymentMethodTypeState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});

export const getPaymentMethodTypesAsync = createAsyncThunk(
  "PaymentMethodTypes/getPaymentMethodTypes",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const paymentMethodTypes = state.paymentMethod.paymentMethods;

    if (paymentMethodTypes.length > 0) {
      return paymentMethodTypes;
    }

    try {
      dispatch(getPaymentMethodTypesStart());
      const response = await apiService.get<
        ApiResponse<PaymentMethodTypeDto[]>
      >("api/PaymentMethod/types");
      dispatch(getPaymentMethodTypesSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getPaymentMethodTypesFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  getPaymentMethodTypesStart,
  getPaymentMethodTypesSuccess,
  getPaymentMethodTypesFailure,
} = PaymentMethodTypesSlice.actions;

export default PaymentMethodTypesSlice.reducer;
