import { CsvExportParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";

export const exportDataToCsv = (
  gridRef: RefObject<AgGridReact | null>,
  columnKeys: string[] = []
) => {
  const exportParams: CsvExportParams = {};
  if (columnKeys?.length > 0) {
    exportParams.columnKeys = columnKeys;
  }

  gridRef?.current?.api.exportDataAsCsv(exportParams);
};