import "./FormHeaderOptions.css"; // Import your CSS file
import { Button, Divider, Popconfirm, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
import FormSubmitButton from "./FormSubmitButton";
import {
  SaveOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  PlusOutlined,
  CopyOutlined,
  PrinterOutlined
} from "@ant-design/icons";
import { ReactNode } from "react";
import ValidateIcon from "../../assets/icons/ValidateIcon";
import Unvalidate from "../../assets/icons/Unvalidate";
import { MenuOptionEnum } from "../../enums/MenuOptionEnum";
import WithPermission from "../Authorization/WithPermission";

interface Props {
  title: string;
  handleSubmitForm?: () => void;
  submitButtonIsDisabled?: boolean;
  handleSubmitValidation?: () => Promise<any>;
  submitButtonText?: string;
  validateButtonText?: string;
  validateButtonIsDisabled?: boolean;
  handleDocumentValidation?: () => void;
  correctionButtonText?: string;
  correctionButtonIsDisabled?: boolean;
  handleDocumentCorrection?: () => void;
  invalidateButtonText?: string;
  invalidateButtonIsDisabled?: boolean;
  handleDocumentInvalidation?: () => void;
  handleCopyDetails?: () => void;
  copyButtonIsDisabled?: boolean;
  copyButtonText?: ReactNode;
  createAccessEnum?: number;
  validateAccessEnum?: number;
  invalidateAccessEnum?: number;
  correctionAccessEnum?: number;
  handlePrintForm?: () => void;
  handlePrintIsDisabled?: boolean;
  handlePrintText?: string;
  printAccessEnum?: number;
}

export default function FormHeaderOptions({
  title,
  handleSubmitForm,
  submitButtonIsDisabled,
  handleSubmitValidation,
  submitButtonText,
  validateButtonText,
  validateButtonIsDisabled,
  handleDocumentValidation,
  correctionButtonText,
  correctionButtonIsDisabled,
  handleDocumentCorrection,
  invalidateButtonText,
  invalidateButtonIsDisabled,
  handleDocumentInvalidation,
  handleCopyDetails,
  copyButtonIsDisabled,
  copyButtonText,
  createAccessEnum,
  validateAccessEnum,
  invalidateAccessEnum,
  correctionAccessEnum,
  handlePrintForm,
  handlePrintIsDisabled,
  handlePrintText,
  printAccessEnum
}: Props) {
  return (
    <div className="form-header-options">
      <div className="header-title-container">
        <div className="button-container">
          {handleSubmitForm && (
            <WithPermission
              menuOptionId={createAccessEnum ?? -1}
              children={
                <FormSubmitButton
                  style={{ margin: "0" }}
                  disabled={submitButtonIsDisabled}
                  handleValidate={async () => {
                    if (handleSubmitValidation) {
                      await handleSubmitValidation();
                    }
                  }}
                  handleConfirm={() => {
                    handleSubmitForm && handleSubmitForm();
                  }}
                  buttonText={submitButtonText ?? ""}
                  icon={<SaveOutlined />}
                />
              }
            />
          )}
          {handleDocumentValidation && (
            <WithPermission
              menuOptionId={validateAccessEnum ?? -1}
              children={
                <Popconfirm
                  title="Are you sure you want to validate the form?"
                  onConfirm={handleDocumentValidation}
                  disabled={validateButtonIsDisabled}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    className={
                      validateButtonIsDisabled ? "menu-icon disabled" : "menu-icon"
                    }
                  >
                    <ValidateIcon />
                    <h3 className="span-text">{validateButtonText}</h3>
                  </div>
                </Popconfirm>
              }
            />
          )}
          {handleDocumentInvalidation && (
            <WithPermission
              menuOptionId={invalidateAccessEnum ?? -1}
              children={
                <Popconfirm
                  disabled={invalidateButtonIsDisabled}
                  title="Are you sure you want to invalidate the form?"
                  onConfirm={handleDocumentInvalidation}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    className={
                      invalidateButtonIsDisabled
                        ? "menu-icon disabled"
                        : "menu-icon"
                    }
                  >
                    <Unvalidate

                    />
                    <h3 className="span-text" style={{ color: "red" }}>
                      {invalidateButtonText}
                    </h3>
                  </div>
                </Popconfirm>
              }
            />
          )}

          {handleDocumentCorrection && (
            <WithPermission
              menuOptionId={correctionAccessEnum ?? -1}
              children={
                <Popconfirm
                  title="Are you sure you want to correct the form?"
                  onConfirm={handleDocumentCorrection}
                  okText="Yes"
                  disabled={correctionButtonIsDisabled}
                  cancelText="No"
                >
                  <div
                    className={
                      correctionButtonIsDisabled
                        ? "menu-icon disabled"
                        : "menu-icon"
                    }
                    style={{}}
                  >
                    <FileExcelOutlined style={{ width: "fit-content" }} />
                    <h3 className="span-text">{correctionButtonText}</h3>
                  </div>
                </Popconfirm>
              }
            />
          )}
          {handleCopyDetails && (
            <Popconfirm
              title="Are you sure you want to copy the form?"
              onConfirm={handleCopyDetails}
              disabled={copyButtonIsDisabled}
              okText="Yes"
              cancelText="No"
            >
              <div
                className={
                  copyButtonIsDisabled
                    ? "menu-icon disabled"
                    : "menu-icon"
                }
                style={{}}
              >
                <CopyOutlined style={{ width: "fit-content" }} />
                <h3 className="span-text">{copyButtonText}</h3>
              </div>
            </Popconfirm>
          )}
          {handlePrintForm && (
            <WithPermission
            menuOptionId={printAccessEnum ?? -1}
            children={
            <Popconfirm
              title="Are you sure you want to print the form?"
              onConfirm={handlePrintForm}
              disabled={handlePrintIsDisabled}
              okText="Yes"
              cancelText="No"
            >
              <div
                className={handlePrintIsDisabled ? "menu-icon disabled" : "menu-icon"}
              >
                <PrinterOutlined />
                <h3 className="span-text">{handlePrintText}</h3>
              </div>
            </Popconfirm>
            }
            />
          )}
        </div>
      </div>
    </div >
  );
}
