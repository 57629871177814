import { ColDef } from "ag-grid-community";
import { CreateOrUpdateFinInvoiceRegistrationDelayedExpense } from "../../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationDelayedExpense";
import { Button } from "antd";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
export const getFinInvoiceRegistrationDelayedExpensesColumns = (
    removeFinInvoiceRegistrationDelayedExpense: (index: number) => void,
    t: (text: string) => string,
    mode: string
  ) => {
  
    const columnDefs: ColDef<CreateOrUpdateFinInvoiceRegistrationDelayedExpense>[] = [
      {
        headerName: t("tableHeaders.branchName"),
        field: "branchName",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.accountId"),
        field: "accountId",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.accountDescription"),
        field: "accountDescription",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.date"),
        field: "date",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.amount"),
        field: "amount",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.dK"),
        field: "dK",
        editable: false,
        singleClickEdit: true,
        sortable: false,
        filter: false,
        suppressMovable: true,
        suppressMenu: true
      },
      {
        headerName: t("tableHeaders.options"),
        suppressMenu: true,
        maxWidth: 100,
        cellRenderer: (params: any) => {
          return (
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => removeFinInvoiceRegistrationDelayedExpense(params.rowIndex)}
              style={{ color: "#007FFF" }}
            ></Button>
          );
        },
      }
    ]
    return columnDefs;
}