import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { setLoading, setLoadingState } from "./loadingSlice";
import { toast } from "react-toastify";
import "../../models/clientDashboard/EntityType/EntityTypeState";
import { EntityTypeState } from "../../models/clientDashboard/EntityType/EntityTypeState";
import { EntityType } from "../../models/clientDashboard/EntityType/EntityType";
import { GetEntityTypesDto } from "../../models/clientDashboard/EntityType/GetEntityTypesDto";
import { RootState } from "..";

const initialState: EntityTypeState = {
    entityTypes: [],
    error: null
};

const EntityTypeSlice = createSlice({
    name: "entityType",
    initialState,
    reducers: {
        getEntityTypesStart(state: EntityTypeState) {
            setLoading(true);
            state.error = null;
        },
        getEntityTypesSuccess(
            state: EntityTypeState,
            action: PayloadAction<GetEntityTypesDto[]>
        ) {
            setLoading(false);
            state.entityTypes = action.payload;
        },
        getEntityTypesFailure(
            state: EntityTypeState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        
        deleteEntityTypeFromState(state: EntityTypeState, action: PayloadAction<number>) {
            setLoading(false);
            const entityTypeId = action.payload;
            state.entityTypes = state.entityTypes.filter(entityType => entityType.entityTypeId !== entityTypeId);
        },
    }
});

export const getEntityTypesAsync = createAsyncThunk(
    "EntityTypes/getEntityTypes",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const entityTypes = state.entityType.entityTypes;

        if (entityTypes.length > 0) {
            return entityTypes;
        }

        try {
            dispatch(getEntityTypesStart());
            const response = await apiService.get<ApiResponse<GetEntityTypesDto[]>>(
                "api/Entity/entityTypes"
            );
            // toast.success("Entity types were successfully retrieved!");
            dispatch(getEntityTypesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getEntityTypesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getEntityTypeDataById = createAsyncThunk(
    "EntityTypes/getEntityTypeDataById",
    async (entityTypeId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<EntityType>>(
                `/api/clientDashboard/EntityType/GetEntityTypeDataById/${entityTypeId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteEntityTypeAsync = createAsyncThunk(
    "EntityTypes/deleteEntityType",
    async (entityTypeId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/clientDashboard/EntityType/DeleteEntityType/${entityTypeId}`
            );
            dispatch(deleteEntityTypeFromState(entityTypeId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getEntityTypesStart,
    getEntityTypesSuccess,
    getEntityTypesFailure,
    deleteEntityTypeFromState
} = EntityTypeSlice.actions;

export default EntityTypeSlice.reducer;