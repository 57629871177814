import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserState } from "../../models/states/UserState";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import Cookies from "js-cookie";
import { setLoading, setLoadingState } from "./loadingSlice";
import LoginData from "../../models/dashboard/Users/LoginData";
import decodeToken from "../../extensions/decodeToken";
import DecodedUser from "../../models/dashboard/Users/DecodedUser";
import { setLanguage, setTheme } from "./appearanceSlice";
import { stat } from "fs";
import { DecodedUserAndBranches } from "../../models/dashboard/Users/DecodedUserAndBranches";

import { TokenAndBranches } from "../../models/dashboard/Users/TokenAndBranches";
import { SelectBranchDto } from "../../models/clientDashboard/Branch/SelectBranchDto";
import axios from "axios";
import { UserBranchDto } from "../../models/clientDashboard/Branch/UserBranchDto";
import { RootState } from "..";
import { User } from "../../models/clientDashboard/Users/User";
import  { decryptData, encryptData }  from "../../helperMethods/decryptToken";
import { MenuRoleFromToken } from "../../models/clientDashboard/MenuRole/MenuRoleFromToken";
import {MenuRoleForAccess} from "../../models/clientDashboard/MenuRole/MenuRoleForAccess";
import { setConfigurationValuesFromToken } from "./getAllConfigurationsSlice";
import jwtDecode from "jwt-decode";
const initialState: UserState = {
  loggedInUser: null,
  loggedInUserBranches: [],
  loggedInUserMenuRoles:[],
  users: [],
  adminUsers:[],
  error: "",
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setDecodedUser(state: UserState, action: PayloadAction<DecodedUser>) {
      state.loggedInUser = action.payload;
    },
    setUserToNullAndRemoveToken(state: UserState) {
      state.loggedInUser = null;
      localStorage.removeItem("token");
      localStorage.removeItem("configToken");
      localStorage.removeItem("role");

    },
    setUserBranches(state: UserState, action: PayloadAction<UserBranchDto[]>) {
      state.loggedInUserBranches = action.payload;
    },
    getUserStart(state: UserState) {
      setLoading(true);
      state.error = null;
    },
    getUsersSuccess(state: UserState, action: PayloadAction<User[]>) {
      setLoading(false);
      state.users = action.payload;
    },
    getUsersFailure(state: UserState, action: PayloadAction<string>) {
      setLoading(false);
      state.error = action.payload;
    },
    deleteUserFromState(state: UserState, action: PayloadAction<number>) {
      setLoading(false);
      const usersID = action.payload;
      state.users = state.users.filter((user) => user.usersID !== usersID);
    },
    setLoggedInUserMenuRoles(state: UserState, action: PayloadAction<MenuRoleForAccess[]>) {
      state.loggedInUserMenuRoles = action.payload;
    },
    getAdminUsersSuccess(state: UserState, action: PayloadAction<User[]>) {
      setLoading(false);
      state.users = action.payload;
    },
  },
});

export const authenticateUser = createAsyncThunk(
  "login",
  async (request: LoginData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));

      const response = await apiService.post<ApiResponse<TokenAndBranches>>(
        "/api/dashboard/auth/login",
        request
      );

      const data: ApiResponse = response.data;
      const theme = localStorage.getItem("theme");
      if (theme) {
        dispatch(setTheme(theme));
      } else {
        dispatch(setTheme("light"));
      }

      const language = localStorage.getItem("language");
      if (language) {
        dispatch(setLanguage(language));
      } else {
        dispatch(setLanguage("al"));
      }

      if (typeof data.Data === "object") {
        const tokenAndBranches: TokenAndBranches =
          data.Data as TokenAndBranches;
        const userData: DecodedUser = decodeToken(
          tokenAndBranches.token
        ) as DecodedUser;
        localStorage.setItem("token", tokenAndBranches.token.toString());

        const dataToReturn: DecodedUserAndBranches = {
          user: userData,
          branches: tokenAndBranches.branches,
        };
        return dataToReturn;
      } else if (typeof data.Data === "string") {
        const userData: DecodedUser = decodeToken(
          data.Data.toString()
        ) as DecodedUser;

        localStorage.setItem("token", data.Data.toString());
        dispatch(setDecodedUser(userData));
        const dataToReturn: DecodedUserAndBranches = {
          user: userData,
          branches: [],
        };
        console.log(dataToReturn);
        return dataToReturn;
      }
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const setUserBranch = createAsyncThunk(
  "setUserBranch",
  async (request: SelectBranchDto, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<string>>(
        "/api/dashboard/auth/setUserBranch",
        request
      );
      const data: ApiResponse = response.data;
      if (typeof data.Data === "string") {
        localStorage.setItem("token", data.Data.toString());
        const userData: DecodedUser = decodeToken(data.Data) as DecodedUser;
        dispatch(setDecodedUser(userData));
        return userData;
      }
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getUserBranches = createAsyncThunk(
  "getUserBranches",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<UserBranchDto[]>>(
        "/api/client/User/branches"
      );
      const data: ApiResponse<UserBranchDto[]> = response.data;
      dispatch(setUserBranches(data.Data));
      return data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getUsersAsync = createAsyncThunk(
  "Users/getUsers",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;

    try {
      const response = await apiService.get<ApiResponse<User[]>>(
        "api/client/user/getAllCompanyUsers"
      );
      dispatch(getUsersSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getUsersFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
    }
  }
);

export const getUserDataById = createAsyncThunk(
  "Users/getUserDataById",
  async (usersID: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<User>>(
        `/api/client/User/getUserById?userId=${usersID}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getUserBySearch = createAsyncThunk(
  "User/getBySearchValue",
  async (searchText: string, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<User>>(
        `/api/client/User/getBySearchValue?searchValue=${searchText}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "Users/deleteUser",
  async (usersID: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.delete<ApiResponse<void>>(
        `/api/Users?usersID=${usersID}`
      );
      dispatch(deleteUserFromState(usersID));
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getMenuRolesForAccess = createAsyncThunk(
  "Roles/getMenuRolesForAccess",
  async (roleId: number, { dispatch, rejectWithValue }) => {
      try {
          dispatch(setLoadingState(true));
          const response = await apiService.get<ApiResponse<MenuRoleFromToken>>(
              `/api/Role/access/${roleId}`
          );
          const encryptedRole = encryptData(response.data.Data);
          localStorage.setItem("role", encryptedRole);
          dispatch(setLoggedInUserMenuRoles(response.data.Data.menuRoles|| []));
          return response.data.Data.menuRoles;
      } catch (error: any) {
          const errorMessage =
              typeof error === "string" ? error : "An error occurred";
          return rejectWithValue(errorMessage);
      } finally {
          dispatch(setLoadingState(false));
      }
  }
);
export const getMenuRolesForAccessForAdmin = createAsyncThunk(
  "Roles/getMenuRolesForAccess",
  async (roleId: number, { dispatch, rejectWithValue }) => {
      try {
          dispatch(setLoadingState(true));
          const response = await apiService.get<ApiResponse<MenuRoleFromToken>>(
              `/api/AdminRole/admin/access/${roleId}`
          );
          const encryptedRole = encryptData(response.data.Data);
          localStorage.setItem("role", encryptedRole);
          dispatch(setLoggedInUserMenuRoles(response.data.Data.menuRoles|| []));
          return response.data.Data.menuRoles;
      } catch (error: any) {
          const errorMessage =
              typeof error === "string" ? error : "An error occurred";
          return rejectWithValue(errorMessage);
      } finally {
          dispatch(setLoadingState(false));
      }
  }
);



export const decryptRoleToken = createAsyncThunk(
  "ConfigurationValues/decryptConfigurationToken",
  async (_, { dispatch, getState, rejectWithValue }) => {
      try {
          const root  = getState() as RootState;
          const user = root.user.loggedInUser;

          if(!user) return;
          const token = localStorage.getItem("role");

          let decryptedData: MenuRoleFromToken | null = null;
          let lastFetch = 0;

          if (token) {
              decryptedData = decryptData(token);
              
              lastFetch = decryptedData?.lastFetch ? new Date(decryptedData.lastFetch).getTime() : 0;
          }

          const currentTime = Date.now();
          const fiveMinutes = 5 * 60 * 1000;

          if (!decryptedData || (currentTime - lastFetch > fiveMinutes)) {
          const loginToken = localStorage.getItem("token");
            let url = ''
            if (user?.roleId === "1" || user?.roleId === "2" || user?.roleId === "3") {
              url = `/api/AdminRole/admin/access/${user.roleId}`
            }else{
              url = `/api/Role/access/${user?.userRoleId}`
            }
              const response = await apiService.get<ApiResponse<MenuRoleFromToken>>(
                  url
              );
              if(response.status === 200){
                  const newData = encryptData(response.data.Data);
                  localStorage.setItem("configToken", newData);
                  decryptedData = response.data.Data;
              }
          }

          dispatch(setLoggedInUserMenuRoles(decryptedData?.menuRoles || []));
      } catch (error) {
          const errorMessage = typeof error === "string" ? error : "An error occurred";
          dispatch(getUsersFailure(errorMessage));
          return rejectWithValue(errorMessage);
      } finally {
          dispatch(setLoadingState(false));
      }
  }
);

export const logoutAsync = createAsyncThunk(
  "Users/logoutAsync",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      dispatch(setConfigurationValuesFromToken([]))
      dispatch(setLoggedInUserMenuRoles([]))
      dispatch(setUserToNullAndRemoveToken());
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const decodeAndSetUser = () => (dispatch: any) => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  if (token) {
    // const decodedToken: any = jwtDecode(token);
    const userData: DecodedUser = decodeToken(
      token
    ) as DecodedUser;

    const expirationTime = userData.exp * 1000;
    const currentTime = Date.now();

    if (expirationTime < currentTime) {
       dispatch(logoutAsync());
      return;
    }
    dispatch(setDecodedUser(userData));
  }
};

export const getAdminUsersAsync = createAsyncThunk(
  "AdminUsers/getAdminUsers",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;

    try {
      const response = await apiService.get<ApiResponse<User[]>>(
        "api/AdminUser/getAll"
      );
      dispatch(getAdminUsersSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getUsersFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
    }
  }
);

export const getAdminUserDataById = createAsyncThunk(
  "AdminUsers/getAdminUserDataById",
  async (usersID: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<User>>(
        `/api/AdminUser/getById?usersID=${usersID}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  setDecodedUser,
  setUserToNullAndRemoveToken,
  setUserBranches,
  getUsersSuccess,
  getUsersFailure,
  deleteUserFromState,
  setLoggedInUserMenuRoles,
  getAdminUsersSuccess
} = userSlice.actions;

export default userSlice.reducer;
