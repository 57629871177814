import { Button, Modal, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import useAppTranslation from "../../customHooks/useAppTranslation";
import { useEffect, useState } from "react";
import {
  deletePaymentMethodsAsync,
  getPaymentMethodsAsync,
} from "../../redux/slices/paymentMethodsSlice";
import { useNavigate } from "react-router-dom";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { db } from "../../indexDB/clientSideDatabase";
import AgGridTableComponent from "../CustomComponents/AgGridTableComponent";
import ListHeaderComponent from "../CustomComponents/ListHeaderComponent";
import WithPermission from "../Authorization/WithPermission";
import { MenuOptionEnum } from "../../enums/MenuOptionEnum";
import { usePermission } from "../../customHooks/usePermission";

const { Title, Text, Link } = Typography;

const PaymentMethods = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const PaymentMethods = useSelector(
    (state: RootState) => state.paymentMethod.paymentMethods
  );

  const t = useAppTranslation("ClientDashboard.PaymentMethods");
  const dispatch = useDispatch<AppDispatch>();
  const [menuCurrenciesDisabled, setMenuCurrenciesDisabled] = useState(false);

  const deletePaymentMethods = async (paymentMethodId: number) => {
    const confirmed = await showDeleteConfirmation();
    if (confirmed) {
      dispatch(deletePaymentMethodsAsync(paymentMethodId));
    }
  };

  const showDeleteConfirmation = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Are you sure you want to delete this record?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "paymentMethodId",
      headerName: "ID",
      width: 80,
      cellRenderer: (params: any) => {
        return (
          <Text className="ag-grid-id-column">
            {params?.data?.paymentMethodId}
          </Text>
        );
      },
      suppressMenu: true,
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      suppressMenu: true,
      width: 180
    },
    {
      field: "comission",
      headerName: t("tableHeaders.comission"),
      suppressMenu: true,
    },
    {
      field: "sortingOrder",
      headerName: t("tableHeaders.sortingOrder"),
      suppressMenu: true,
    },
    {
      field: "displayedInPos",
      headerName: t("tableHeaders.displayedInPos"),
      suppressMenu: true,
    },
    {
      field: "paymentWithBonus",
      headerName: t("tableHeaders.paymentWithBonus"),
      suppressMenu: true,
    },
    {
      field: "fiscalPayMethodType",
      headerName: t("tableHeaders.fiscalPayMethodType"),
      suppressMenu: true,
    },
    {
      field: "directPayment",
      headerName: t("tableHeaders.directPayment"),
      suppressMenu: true,
    },
    {
      headerName: t("tableHeaders.options"),
      suppressMenu: true,
      width: 100,
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.PaymentMethodsCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updatePaymentMethod"),
                  `paymentMethod/update/${params?.data?.paymentMethodId}`,
                  undefined
                );
                navigate(`/paymentMethod/update/${params?.data?.paymentMethodId}`);
              }}
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
    },
  ]);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(["shortDescription"]);
    params.columnApi.autoSizeColumns(["description"]);
    params.columnApi.autoSizeColumns(["comission"]);
    params.columnApi.autoSizeColumns(["sortingOrder"]);
    params.columnApi.autoSizeColumns(["displayedInPos"]);
    params.columnApi.autoSizeColumns(["paymentWithBonus"]);
    params.columnApi.autoSizeColumns(["fiscalPayMethodType"]);
    params.columnApi.autoSizeColumns(["directPayment"]);

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getPaymentMethods = async () => {
    const result = await dispatch(getPaymentMethodsAsync());
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, [dispatch, gridApi]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.PaymentMethodsCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updatePaymentMethods"),
        `paymentMethod/update/${event.data.paymentMethodId}`,
        undefined
      );
      navigate(`/paymentMethod/update/${event.data.paymentMethodId}`);
    }
  };

  const handleCreateNewPaymentMethod = () => {
    navigate("/paymentMethod/register");
    db.addTab(
      t("tabs.createPaymentMethods"),
      "paymentMethod/register",
      undefined
    );
  };

  return (
    <>
      <ListHeaderComponent
        title={t("titles.paymentMethods")}
        createRecord={handleCreateNewPaymentMethod}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.PaymentMethodsCreate}
      />
      <AgGridTableComponent
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={PaymentMethods}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
    </>
  );
};

export default PaymentMethods;
