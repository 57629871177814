import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { AgGridReact } from "ag-grid-react";
import { Button, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { formatDateTimeAsDDMMYYYY } from "../../../helperMethods/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardCompaniesAsync } from "../../../redux/slices/dashboardCompanySlice";
import { useNavigate } from "react-router-dom";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuAdminOptionEnum } from "../../../enums/MenuAdminOptionEnum";
import { usePermission } from "../../../customHooks/usePermission";
import WithPermission from "../../Authorization/WithPermission";

const { Title, Text, Link } = Typography;

const Clients = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const clients = useSelector((state) => state.dashboardCompany.companies);
  const t = useAppTranslation("AdminDashboard.ClientsList");

  const navigate = useNavigate();
  const onFirstDataRendered = (params) => {
    const allColumnIds = params.columnApi
      .getAllColumns()
      .map((col) => col.getColId());
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  const [colDefs, setColDefs] = useState([
    {
      field: "companyID",
      headerName: "ID",
      width: 80,
      cellRenderer: (params) => {
        return <Text type="secondary">{params.data.companyID}</Text>;
      },
    },
    { field: "companyName", headerName: t("tableHeaders.clientName") },
    {
      field: "phone",
      headerName: t("tableHeaders.phone"),
      width: 150,
      cellRenderer: (params) => {
        return <Text type="secondary">{params.data.phone}</Text>;
      },
    },
    { field: "email", headerName: "Email" },
    {
      field: "typeDescription",
      headerName: t("tableHeaders.clientType"),
      width: 150,
      cellRenderer: (params) => {
        return <Text type="secondary">{params.data.typeDescription}</Text>;
      },
    },
    { field: "status", headerName: t("tableHeaders.status"), width: 100 },
    {
      field: "vatNumber",
      headerName: t("tableHeaders.fiscalNumber"),
      width: 140,
    },
    {
      field: "dateCreated",
      headerName: t("tableHeaders.registrationDate"),
      cellRenderer: (params) => {
        return (
          <Text type="secondary">
            {formatDateTimeAsDDMMYYYY(params.data.dateCreated)}
          </Text>
        );
      },
      width: 150,
    },
    {
      field: "details",
      headerName: t("tableHeaders.details"),
      cellRenderer: (params) => (
        <WithPermission
          menuOptionId={MenuAdminOptionEnum.AdminClientCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() =>
                navigate(`/dashboard/company/${params.data.companyID}`)
              }
              style={{
                color: "#007FFF",
              }}
            ></Button>
          }
        />
      ),
      width: 100,
    },
  ]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardCompaniesAsync());
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuAdminOptionEnum.AdminClientCreate)

  const handleRowDoubleClick = (event) => {
    if (allowUpdate) {
      navigate(`/dashboard/company/${event?.data?.companyID}`);
    };
  };

  return (
    <>
      <Spin tip="Loading..." spinning={isLoading}>
        <ListHeaderComponent
          title={t("titles.createServer")}
          createRecord={() => {
            navigate("/dashboard/client/register");
          }}
          menuItemsDisabled={false}
          createTitle={t("create")}
          createAccessEnum={MenuAdminOptionEnum.AdminClientCreate}
        />
        <div className="ag-theme-balham" style={{ height: 550, marginTop: 15 }}>
          <AgGridTableComponent
            gridRef={gridApi}
            onGridReady={onGridReady}
            defaultColDef={{
              suppressMenu: true,
            }}
            isLoading={isLoading}
            rowData={clients}
            columnDefs={colDefs}
            pagination={true}
            paginationPageSize={20}
            onRowDoubleClicked={handleRowDoubleClick}
          />
        </div>
      </Spin>
    </>
  );
};

export default Clients;
