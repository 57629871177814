import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RoleState } from "../../models/clientDashboard/Role/RoleState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { Role } from "../../models/clientDashboard/Role/Role";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";

const initialState: RoleState = {
    roles: [],
    error: null,
};

const RoleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        getRolesStart(state: RoleState) {
            setLoading(true);
            state.error = null;
        },
        getRolesSuccess(
            state: RoleState,
            action: PayloadAction<Role[]>
        ) {
            setLoading(false);
            state.roles = action.payload;
        },
        getRolesFailure(
            state: RoleState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        deleteRoleFromState(state: RoleState, action: PayloadAction<number>) {
            setLoading(false);
            const roleId = action.payload;
            state.roles = state.roles.filter(role => role.roleId !== roleId);
        },
    }
});

export const getRolesAsync = createAsyncThunk(
    "Roles/getRoles",
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(getRolesStart());
            const response = await apiService.get<ApiResponse<Role[]>>(
                "api/Role"
            );
            // toast.success("Taxes were successfully retrieved!");
            dispatch(getRolesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getRolesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getRoleDataById = createAsyncThunk(
    "Roles/getRoleDataById",
    async (roleId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<Role>>(
                `/api/Role/${roleId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const {
    getRolesStart,
    getRolesSuccess,
    getRolesFailure,
    deleteRoleFromState
} = RoleSlice.actions;

export default RoleSlice.reducer;