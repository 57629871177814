import { Col, Form, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import { useNavigate, useParams } from "react-router-dom";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useRef } from "react";
import { Agents } from "../../../../models/Users/agents/Agents";
import { setLoading } from "../../../../redux/slices/loadingSlice";
import apiService from "../../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../../indexDB/clientSideDatabase";
import {
  getAgentsDataById,
  getAgentsSuccess,
} from "../../../../redux/slices/agentsSlice";
import useFormData from "../../../../customHooks/useFormData";
import { Formik } from "formik";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import FormInput from "../../../CustomComponents/FormInput";
import FormSwitch from "../../../CustomComponents/FormSwitch";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";
import { getUserBySearch } from "../../../../redux/slices/userSlice";
import { User } from "../../../../models/clientDashboard/Users/User";

const { Title, Text } = Typography;

const UpdateAgents = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const { id } = useParams();
  const t = useAppTranslation("ClientDashboard.UpdateAgents");

  console.log(id, "param");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<any>(null);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  const onFinish = async (values: Agents) => {
    dispatch(setLoading(true));

    await apiService
      .put("/api/client/Agents/update", values)
      .then((response) => {
        toast.success(t("agentsUpdatedSuccessfully"));
        db.deleteTab(`agents/update/${id}`);
        dispatch(getAgentsSuccess([]));
        navigate("/agents");
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  const fetchUsers = async (searchText: string) => {
    const result = await dispatch(getUserBySearch(searchText));
    if (result.payload !== "An error occurred") {
      const payload = result.payload as [];
      const options = payload.map((user: User) => ({
        key: user.usersID.toString(),
        label: user.firstName + " " + user.lastName,
        value: user.usersID,
      }));
      return options;
    }
    return [];
  };
  

  const { initialValues } = useFormData<Agents>(
    `agents/update/${id}`,
    { status: true } as Agents,
    undefined,
    {
      fetchData: async () => {
        const agentsAction = await dispatch(getAgentsDataById(id ?? ""));
        const agents = agentsAction.payload as Agents;
        return agents;
      },
    }
  );

  const handleSubmitValidationForm = async (
    setTouched: ({}: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = (await validateForm()) || {};
    setTouched({});
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };

  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={
          initialValues ??
          ({
            status: true,
          } as Agents)
        }
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          handleSubmit,
          submitForm,
          setTouched,
          validateForm,
          setFieldValue,
        }) => (
          <>
            <FormHeaderOptions
              title={t("formTitle")}
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("updateAgentButton")}
              submitButtonIsDisabled={isLoading}
              createAccessEnum={MenuOptionEnum.AgentCreate}
            />
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              style={{ marginTop: 20 }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <FormInput
                    name="fullName"
                    label={t("fullNameInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("fullNameInputPlaceholder"),
                      },
                    ]}
                    value={values.fullName}
                  />
                  <FormInput
                    name="agentCode"
                    label={t("agentCodeInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("agentCodeInputPlaceholder"),
                      },
                    ]}
                    value={values.agentCode}
                  />
                  <FormSelectWithSearch
                    name="operatori"
                    label={t("operatoriInputLabel")}
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: t("operatoriPlaceholder"),
                      },
                    ]}
                    value={values.userId}
                    fetchOptions={fetchUsers}
                    placeHolder={t("placeHolder")}
                    onChange={(option) => {
                      setFieldValue("userId", option.value);
                      setFieldValue("userDescription", option.label);
                    }}
                    fetchInitialOption={() => {
                        return {
                            key: values?.userId ?? 0,
                            label: values?.userFullName ?? "",
                            value: values?.userId??0
                        }
                    }}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <FormInput
                    name="email"
                    label={t("emailInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("emailInputPlaceholder"),
                      },
                    ]}
                    value={values.email}
                  />
                  <FormSwitch
                    className="switch-for-agents"
                    name="status"
                    label={t("statusInputLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("statusInputPlaceholder"),
                      },
                    ]}
                    value={values.status}
                  />
                </Col>
              </Row>
              <HandleFormDataForTabSaving tabPath={`agents/update/${id}`} />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
};

export default UpdateAgents;
