import React from "react";
import { Layout, Modal, Dropdown, Menu, Space, Switch, Typography, Tooltip, Button } from "antd";
import { LeftOutlined, RightOutlined, LogoutOutlined, UserOutlined, DownOutlined } from "@ant-design/icons";
import Logo from "../../assets/kubit-lite-logo.png";
import packageJson from "../../../package.json"; // Import package.json
import { useDispatch, useSelector } from "react-redux";
import { setUserToNullAndRemoveToken } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { setDrawerOpen } from "../../redux/slices/appearanceSlice";
import { changeTheme, setLanguage } from "../../redux/slices/appearanceSlice"; // Adjust with your actual import
import { useTranslation } from "react-i18next"; // For translations
import NavigationMenu from "./NavigationMenu ";
import { Root } from "react-dom/client";
import { AppDispatch, RootState } from "../../redux";


const { Header, Content } = Layout;
interface Props {
  children: React.ReactNode;
}


const LayoutComponent = ({ children }: Props) => {
  const drawerVisible = useSelector((state: RootState) => state.appearance.drawerOpen);
  const isDarkTheme = useSelector((state: RootState) => state.appearance.theme);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleDrawer = () => {
    dispatch(setDrawerOpen(!drawerVisible));
  };

  const logout = () => {
    Modal.confirm({
      title: "Do you want to logout?",
      onOk() {
        dispatch(setUserToNullAndRemoveToken());
        navigate("/login");
        window.location.reload();
      },
    });
  };

  const handleThemeChange = (checked: boolean) => {
    dispatch(changeTheme(checked ? "dark" : "light"));
  };

  const handleLanguageChange = (value: string) => {
    dispatch(setLanguage(value));
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Space>
          <Switch
            checkedChildren="Dark"
            unCheckedChildren="Light"
            checked={isDarkTheme === "dark"}
            onChange={handleThemeChange}
          />
        </Space>
      </Menu.Item>
      <Menu.SubMenu title={t('language')} key="sub1">
        <Menu.Item key="2" onClick={() => handleLanguageChange("en")}>
          {t('english')}
        </Menu.Item>
        <Menu.Item key="3" onClick={() => handleLanguageChange("al")}>
          {t('albanian')}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Divider />
      <Menu.Item key="4" onClick={logout}>
        {t('Logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={!drawerVisible}
        width={200}
        theme="light"
      >
        <div
          className="logo"
          style={{
            padding: 15,
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            width="100%"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
        </div>
        <NavigationMenu version={packageJson.version} />
      </Layout.Sider>
      <Layout>
        <Header
          style={{
            background: "#fff",
            padding: "5px 16px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tooltip title={drawerVisible ? t("Mbyll") : t("Hap")}>
              <Button
                type="dashed"
                shape="default"
                size="small"
                icon={drawerVisible ? <LeftOutlined /> : <RightOutlined />}
                onClick={toggleDrawer}
              />
            </Tooltip>
          </div>

          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="link" className="ant-dropdown-link">
              <UserOutlined /> <DownOutlined />
            </Button>
          </Dropdown>
        </Header>
        <Content
          style={{
            margin: "2px 2px 0",
            minHeight: "calc(100vh - 64px)",
            backgroundColor: "white",
          }}
        >
          <div style={{ padding: 10 }}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
