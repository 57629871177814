import React, { useState } from "react";
import axios from "axios";
import ImportExcelDto from "../../../models/clientDashboard/ImportExcel/ImportExcelDto";
import { Button, Card, Divider, Typography } from "antd";
import apiService from "../../../extensions/api";
import { ApiResponse } from "../../../models/ApiResponse";
import { toast } from "react-toastify";

const { Title } = Typography;

const ImportTemplates: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [objects, setObjects] = useState<ImportExcelDto[]>([
    {
      key: 1,
      name: "Import artikujt",
      description:
        "Excel file qe ju mundeson te importoni artikujt ne sistem. Sigurohuni qe te mbushni te gjitha kolonat e nevojshme dhe te mos ndryshoni formatin e kolonave.",
      apiEndpoint: "/api/ExcelTemplates/import-items-template",
      fileName: "ItemsTemplate.xlsx",
      isLoading: false,
    },
    // {
    //   key: 2,
    //   name: "Import Entities",
    //   description: "Import entities from an Excel file",
    //   apiEndpoint: "api/ImportEntities",
    //   fileName: "EntitiesTemplate.xlsx",
    //   isLoading: false,
    // },
  ]);

  const handleDownload = async (endpoint: string, key: number) => {
    try {
      const object = objects.find((o) => o.key === key);
      if (object) {
        object.isLoading = true;
        setObjects([...objects]);
      }

      const response = await apiService.get<ApiResponse<string>>(endpoint);
      let base64Data = response.data.Data;

      if (typeof base64Data === "string") {
        //remove "" and / from the base64 string
        base64Data = base64Data.replace(/"/g, "");
      }

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "template.xlsx";
      downloadLink.click();
    } catch (error) {
      toast.error("Error downloading file");
    } finally {
      const object = objects.find((o) => o.key === key);
      if (object) {
        object.isLoading = false;
        setObjects([...objects]);
      }
    }
  };

  return (
    <div>
      <>
        <Title level={3}> Forma për importimin e artikujve permes excel</Title>
        <Divider />
      </>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: "16px",
        }}
      >
        {objects.map((object) => (
          <div key={object.key}>
            <Card title={object.name}>
              <p>{object.description}</p>
              <Button
                onClick={() => handleDownload(object.apiEndpoint, object.key)}
                disabled={object.isLoading}
              >
                {object.isLoading ? "Downloading..." : "Download Template"}
              </Button>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImportTemplates;
