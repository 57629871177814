import { configureStore } from "@reduxjs/toolkit";
import dashboardCompanySlice from "./slices/dashboardCompanySlice";
import loadingSlice from "./slices/loadingSlice";
import clientTypesSlice from "./slices/clientTypesSlice";
import serversSlice from "./slices/serversSlice";
import dashboardSubscriptionsSlice from "./slices/dashboardSubscriptionsSlice";
import userSlice,{decodeAndSetUser, decryptRoleToken} from "./slices/userSlice";
import appearanceSlice from "./slices/appearanceSlice";
import taxSlice from "./slices/taxSlice";
import entityTypeSlice from "./slices/entityTypeSlice";
import countrySlice from "./slices/countrySlice";
import citySlice from "./slices/citySlice";
import unitMeasureSlice from "./slices/unitMeasureSlice";
import itemTypeSlice from "./slices/itemTypeSlice";
import brandSlice from "./slices/brandSlice";
import customSlice from "./slices/customSlice";
import itemStatusSlice from "./slices/itemStatusSlice";
import itemSlice from "./slices/itemSlice";
import classifierSlice from "./slices/classifierSlice";
import languageSlice from "./slices/languageSlice";
import entitySlice from "./slices/entitySlice";
import localPurchaseSlice from "./slices/localPurchaseSlice";
import goodsReturnReasonSlice from "./slices/goodsReturnReasonSlice";
import currencySlice from "./slices/currencySlice";
import branchSlice from "./slices/branchSlice";
import documentTypeSlice from "./slices/documentTypeSlice";
import importPurchaseSlice from "./slices/importPurchaseSlice";
import invoiceWithRemarksReasonSlice from "./slices/invoiceWithRemarksReasonSlice";
import expenseTypesSlice from "./slices/expenseTypesSlice";
import priceSlice from "./slices/priceSlice";
import cashRegisterSlice from "./slices/cashRegisterSlice";
import documentPeriodsSlice from "./slices/documentPeriodsSlice";
import roleSlice from "./slices/roleSlice";
import menuSlice from "./slices/menuSlice";
import goodsTransferSlice from "./slices/goodsTransferSlice";
import agentsSlice from "./slices/agentsSlice";
import salesSlice from "./slices/salesSlice";
import fiscalPayMethodSlice from "./slices/fiscalPayMethodSlice";
import paymentMethodTypesSlice from "./slices/paymentMethodTypesSlice";
import paymentMethodsSlice from "./slices/paymentMethodsSlice";
import getAllConfigurationsSlice, { decryptConfigurationToken } from "./slices/getAllConfigurationsSlice";
import entryItemsOrdersSlice from "./slices/entryItemsOrdersSlice";
import saleOrderSlice from "./slices/saleOrderSlice";
import internalSaleSlice from "./slices/internalSaleSlice";
import productSearchSlice from "./slices/productSearchSlice";
import supplierAgreementSlice from "./slices/supplierAgreementSlice";
import accountingConfigurationSlice from "./slices/accountingConfigurationSlice";
import financialAccountsSlice from "./slices/financialAccountsSlice";
import financialFSNotesLinesSlice from "./slices/financialFSNotesLinesSlice";
import finInvoiceRegistrationSlice from "./slices/finInvoiceRegistrationSlice";

const store = configureStore({
  reducer: {
    dashboardCompany: dashboardCompanySlice,
    loading: loadingSlice,
    clientTypes: clientTypesSlice,
    servers: serversSlice,
    dashboardSubscriptions: dashboardSubscriptionsSlice,
    user: userSlice,
    appearance: appearanceSlice,
    tax: taxSlice,
    entityType: entityTypeSlice,
    country: countrySlice,
    city: citySlice,
    unitMeasure: unitMeasureSlice,
    itemType: itemTypeSlice,
    brand: brandSlice,
    custom: customSlice,
    itemStatus: itemStatusSlice,
    item: itemSlice,
    classifier: classifierSlice,
    language: languageSlice,
    entity: entitySlice,
    localPurchase: localPurchaseSlice,
    invoiceWithRemarksReason: invoiceWithRemarksReasonSlice,
    goodsReturnReason: goodsReturnReasonSlice,
    currency: currencySlice,
    branch: branchSlice,
    documentType: documentTypeSlice,
    users: userSlice,
    importPurchase: importPurchaseSlice,
    expensType: expenseTypesSlice,
    price: priceSlice,
    cashRegister: cashRegisterSlice,
    documentPeriods: documentPeriodsSlice,
    role: roleSlice,
    menu: menuSlice,
    goodsTransfer: goodsTransferSlice,
    agent: agentsSlice,
    sale: salesSlice,
    paymentMethod: paymentMethodsSlice,
    fiscalPayMethodType: fiscalPayMethodSlice,
    paymentMethodType: paymentMethodTypesSlice,
    configuration:getAllConfigurationsSlice,
    entryItemsOrder: entryItemsOrdersSlice,
    saleOrder: saleOrderSlice,
    internalSale: internalSaleSlice,
    productSearchColumn:productSearchSlice,
    supplierAgreements:supplierAgreementSlice,
    accountingConfiguration:accountingConfigurationSlice,
    financialAccounts:financialAccountsSlice,
    financialFSNotesLines: financialFSNotesLinesSlice,
    finInvoiceRegistration:finInvoiceRegistrationSlice,
    fiscalTypes:currencySlice
    
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
store.dispatch(decodeAndSetUser());
store.dispatch(decryptConfigurationToken());
store.dispatch(decryptRoleToken());
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
