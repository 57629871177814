import { Button, Modal, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useEffect, useState } from "react";
import { deleteUserAsync, getUsersAsync } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { db } from "../../../indexDB/clientSideDatabase";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import {
  RightOutlined,
  FilterOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import WithPermission from "../../Authorization/WithPermission";
import { usePermission } from "../../../customHooks/usePermission";

const { Title, Text, Link } = Typography;

const Users = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const users = useSelector((state: RootState) => state.user.users);
  const t = useAppTranslation("ClientDashboard.Users");
  const dispatch = useDispatch<AppDispatch>();
  const [menuUsersDisabled, setMenuUsersDisabled] = useState(false);

  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    {
      field: "usersID",
      headerName: "ID",
      suppressMenu: true,
      width: 80,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.usersID}</Text>;
      },
    },
    { field: "firstName", headerName: t("tableHeaders.firstName"), suppressMenu: true, },
    { field: "lastName", headerName: t("tableHeaders.lastName"), suppressMenu: true, },
    { field: "username", headerName: t("tableHeaders.username"), suppressMenu: true },
    { field: "email", headerName: t("tableHeaders.email"), suppressMenu: true, width: 290 },
    { field: "passCodeDigit", headerName: t("tableHeaders.passCodeDigit"), suppressMenu: true },
    {
      headerName: t("tableHeaders.options"), suppressMenu: true, width: 90,
      cellRenderer: (params: any) => (
        <WithPermission
          menuOptionId={MenuOptionEnum.UsersCreate}
          children={
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => {
                db.addTab(
                  t("tabs.updateUser"),
                  `user/update/${params?.data?.usersID}`,
                  undefined
                );
                navigate(`/user/update/${params?.data?.usersID}`);
              }}
              style={{
                color: "#007FFF",
              }}
            />
          }
        />
      ),
    },
  ]);

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    params.columnApi.autoSizeColumns(['username']);
    params.columnApi.autoSizeColumns(['passCodeDigit']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getUsers = async () => {
    const result = await dispatch(getUsersAsync());
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  useEffect(() => {
    getUsers();
  }, [dispatch, gridApi]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const { hasPermission } = usePermission();
  const allowUpdate = hasPermission(MenuOptionEnum.UsersCreate)

  const handleRowDoubleClick = (event: any) => {
    if (allowUpdate) {
      db.addTab(
        t("tabs.updateUser"),
        `user/update/${event.data.usersID}`,
        undefined
      );
      navigate(`/user/update/${event.data.usersID}`);
    }
  };

  const handleCreateNewUser = () => {
    navigate("/user/register");
    db.addTab(t("tabs.createUser"), "user/register", undefined);
  };

  return (
    <>
      <ListHeaderComponent
        title={t("titles.user")}
        createRecord={handleCreateNewUser}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.UsersCreate}
      />
      <AgGridTableComponent
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        isLoading={isLoading}
        rowData={users}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
      />
    </>
  );
};

export default Users;
