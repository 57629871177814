import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import * as Yup from "yup";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";

export function ItemValidationSchema() {
  const t = useAppTranslation("ClientDashboard.CreateItem.ValidationMessages");
  const classifiers = useSelector((state: RootState) => state.classifier.classifiers);
  const yupSchema: any = {};

  const validateClassifier = (value: any, classifier: Classifier, t: any) => {
    const regex = classifier.regex ? new RegExp(classifier.regex) : null;

    if (!classifier.mandatory && (value === null || value === undefined)) {
      return true;
    }
    if (typeof value === 'object' && value !== null) {
      // console.log(classifierTypeDescription)
      // console.log(classifierDetailId)

      if (classifier.classifierTypeDescription.toLowerCase() === 'select') {
        console.log(value);
        if (typeof value?.value === 'number') {
          return true;
        } else {
          return false;
        }
      } else {
        const detailDescription = value.label;

        if (typeof detailDescription === 'string') {
          if (classifier.classifierTypeDescription.toLowerCase() === 'number' || classifier.classifierTypeDescription.toLowerCase() === 'decimal number') {
            const numberValue = parseFloat(detailDescription);
            if (!isNaN(numberValue) && (!regex || regex.test(detailDescription))) {
              return true;
            } else {
              return false;
            }
          } else {
            if (!regex || regex.test(detailDescription)) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    } else {
      if (typeof value === 'number' || (typeof value === 'string' && (!regex || regex.test(value)))) {
        return true;
      } else {
        return false;
      }
    }
  };



  const formatDescription = (description: string): string => {
    const match = description.match(/^K(\d+)$/);
    if (match) {
      return `category${match[1]}`;
    }
    return description;
  };


  classifiers
    .filter(classifier =>
      (classifier.description === "K1" ||
        classifier.description === "K2" ||
        classifier.description === "K3" ||
        classifier.description === "K4") && classifier.status === true
    )
    .forEach((classifier) => {
      const name = formatDescription(classifier.description);
      let schema = Yup.mixed().nullable().test({
        name: 'validateClassifier',
        message: classifier.mandatory ? t('classifierIsRequired') : t('invalidInput'),
        exclusive: true,
        params: { classifier, t },
        test: (value: any) => validateClassifier(value, classifier, t),
      });
      if (classifier.mandatory) {
        schema = schema.required(t('classifierIsRequired'));
      }
      yupSchema[name] = schema;
    });

  classifiers.filter(
    (classifier) =>
      classifier.description !== "K1" &&
      classifier.description !== "K2" &&
      classifier.description !== "K3" &&
      classifier.description !== "K4" && classifier.status === true
  ).forEach(classifier => {
    const name = classifier.description.toLowerCase();
    switch (classifier.classifierTypeDescription.toLowerCase()) {
      case 'text':
        let textValidation = Yup.mixed().nullable().test({
          name: 'validateClassifier',
          message: t('invalidInput'),
          exclusive: true,
          params: { classifier, t },
          test: (value: any) => validateClassifier(value, classifier, t),
        });
        if (classifier.mandatory) textValidation = textValidation.required(t('classifierIsRequired'));
        yupSchema[name] = textValidation;
        break;
      case 'select':
        yupSchema[name] = classifier.mandatory
          ? Yup.mixed().required(t('classifierIsRequired')).test({
            name: 'validateClassifier',
            message: t('invalidInput'),
            exclusive: true,
            params: { classifier, t },
            test: (value: any) => validateClassifier(value, classifier, t),
          })
          : Yup.mixed().nullable().test({
            name: 'validateClassifier',
            message: t('invalidInput'),
            exclusive: true,
            params: { classifier, t },
            test: (value: any) => validateClassifier(value, classifier, t),
          });
        break;
      case 'number':
      case 'decimal number':
        let numberValidation = Yup.mixed().nullable().test({
          name: 'validateClassifier',
          message: t('invalidInput'),
          exclusive: true,
          params: { classifier, t },
          test: (value: any) => validateClassifier(value, classifier, t),
        });
        if (classifier.mandatory) numberValidation = numberValidation.required(t('classifierIsRequired'));
        yupSchema[name] = numberValidation;
        break;
      case 'email':
        let emailValidation = Yup.mixed().nullable().test({
          name: 'validateClassifier',
          message: t('invalidEmail'),
          exclusive: true,
          params: { classifier, t },
          test: (value: any) => validateClassifier(value, classifier, t),
        });
        if (classifier.mandatory) emailValidation = emailValidation.required(t('classifierIsRequired'));
        yupSchema[name] = emailValidation;
        break;
      default:
        yupSchema[name] = Yup.mixed().nullable().test({
          name: 'invalidInput',
          message: t('invalidClassifier'),
          exclusive: true,
          params: { classifier, t },
          test: (value: any) => validateClassifier(value, classifier, t),
        });
    }
  });

  yupSchema.description = Yup.string().required(t("descriptionIsRequired"));
  yupSchema.customId = Yup.number().required(t("customIdIsRequired"));
  yupSchema.statusId = Yup.number().required(t("statusIdIsRequired"));
  yupSchema.unitId = Yup.number().required(t("unitIdIsRequired"));
  yupSchema.brandId = Yup.number().required(t("brandIdIsRequired"));
  yupSchema.taxId = Yup.number().required(t("taxIdIsRequired"));
  yupSchema.itemTypeId = Yup.number().required(t("itemTypeIdIsRequired"));
  yupSchema.packaging = Yup.number()
    .required(t("packagingIsRequired"))
    .min(1, t("packagingMustBeGreaterThanZero"))
    .positive(t("packagingCannotBeNegative"));
  yupSchema.primarySupplierID = Yup.number().required(t("primarySupplierIsRequired"));
  const validationSchema = Yup.object().shape(yupSchema);
  return validationSchema;
}