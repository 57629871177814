import { Modal, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux";
import { useNavigate } from "react-router-dom";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { getFinancialAccountsAsync } from "../../../redux/slices/accountingConfigurationSlice";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { GetFinancialAccountsDto } from "../../../models/clientDashboard/AccountinConfigurations/GetFinancialAccountsDto";

const { Search } = Input;

interface Props {
  open: boolean;
  onClose: () => void;
  rowIndex: number | null;
  handleAccountSelection: (
    accountId: number,
    rowIndex: number,
    accountDescription: string
  ) => void;
}

export default function ChooseAccountingModal({
  open,
  onClose,
  rowIndex,
  handleAccountSelection,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const gridRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const t = useAppTranslation("ChooseAccountingModal");
  const [accounts, setAccounts] = useState<GetFinancialAccountsDto[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState<
    GetFinancialAccountsDto[]
  >([]);

  const getAccounts = async () => {
    const response = await dispatch(getFinancialAccountsAsync());
    if (
      response.type ===
      "FinancialAccounting/getFinancialAccountsAsync/fulfilled"
    ) {
      const accountsData = response.payload as GetFinancialAccountsDto[];
      setAccounts(accountsData.filter((item) => item.postingToGL === true));
      setFilteredAccounts(accountsData);
    }
  };

  const columnDefs = [
    {
      field: "code",
      headerName: t("tableHeaders.code"),
      suppressMenu: true,
      width: 150,
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      suppressMenu: true,
      width: 140,
    },
    {
      field: "class",
      headerName: t("tableHeaders.class"),
      suppressMenu: true,
      width: 140,
    },
    {
      field: "parentId",
      headerName: t("tableHeaders.parentId"),
      suppressMenu: true,
      width: 140,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const handleRowDoubleClick = (event: any) => {
    handleAccountSelection(
      event.data.financialAccountId,
      rowIndex ?? -1,
      event.data.description
    );
  };

  useEffect(() => {
    getAccounts();
  }, [dispatch]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = accounts.filter((account) => {
        const descriptionMatch = account.description
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const idMatch = account.code?.toString().includes(searchTerm);
        return descriptionMatch || idMatch;
      });
      setFilteredAccounts(filtered);
    } else {
      setFilteredAccounts(accounts);
    }
  }, [searchTerm, accounts]);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <Modal
      title={t("ChooseAccount")}
      open={open}
      onCancel={onClose}
      footer={null}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Search
        placeholder={t("SearchAccount")}
        onChange={(e) => handleSearchChange(e.target.value)}
        allowClear
      />

      <div className="ag-theme-alpine" style={{ height: 700, width: "100%" }}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <AgGridTableComponent
            ref={gridRef}
            rowData={filteredAccounts}
            isLoading={isLoading}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            onRowDoubleClicked={handleRowDoubleClick}
            pagination={true}
            paginationPageSize={10}
          />
        )}
      </div>
    </Modal>
  );
}
