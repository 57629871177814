import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../../redux";
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CreateOrUpdateGoodsTransferDetails } from "../../../../../models/clientDashboard/InternalMovements/GoodsTransfers/CreateOrUpdateGoodsTransferDetails";
import { GoodsTransferDto } from "../../../../../models/clientDashboard/InternalMovements/GoodsTransfers/GoodsTransferDto";
import useFormData from "../../../../../customHooks/useFormData";
import moment from "moment";
import { getUserBranches, setUserBranch } from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { setLoadingState } from "../../../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import { db } from "../../../../../indexDB/clientSideDatabase";
import {
  correctGoodsTransferAsync,
  getGoodsTransferDataById,
  handlePrintGoodsTransferForm,
  validateGoodsTransferAsync,
} from "../../../../../redux/slices/goodsTransferSlice";
import {
  getEntitiesBySearch,
  getEntitiesByTypeAsync,
} from "../../../../../redux/slices/entitySlice";
import { getDocumentTypesWithSignHAsync } from "../../../../../redux/slices/documentTypeSlice";
import {
  AutoComplete,
  Button,
  Col,
  Form,
  message,
  Popconfirm,
  Row,
  Spin,
  Typography,
} from "antd";
import FormHeaderOptions from "../../../../CustomComponents/FormHeaderOptions";
import MainFieldsComponent from "../../../LocalPurchases/CreateLocalPurchaseComponents/MainFieldsComponent";
import ExpensesFieldsComponent from "../../../LocalPurchases/CreateLocalPurchaseComponents/ExpenseFieldsComponent";
import HandleFormDataForTabSaving from "../../../../../helperMethods/handleFormDataForTabSaving";
import AgGridTableComponent from "../../../../CustomComponents/AgGridTableComponent";
import FormSelect from "../../../../CustomComponents/FormSelect";
import FormInput from "../../../../CustomComponents/FormInput";
import style from "antd/es/affix/style";
import GoodsTransfers from "..";
import apiService from "../../../../../extensions/api";
import { handleNextGridColumnClick } from "../../../../../helperMethods/handleNextGridColumnClick";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import Search from "antd/es/input/Search";
import { debounce } from "../../../../../helperMethods/debounce";
import {
  getItemDetailsForLocalPurchase,
  getItemsBySearchValue,
} from "../../../../../redux/slices/itemSlice";
import { GetItemDetailsDto } from "../../../../../models/clientDashboard/Item/GetItemDetailsDto";
import { focusOnAgGridEditingCell } from "../../../../../helperMethods/focusOnAgGridEditingCell";
import { ItemDescriptionIdDto } from "../../../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { getGoodsTransferDetailColumns } from "../../GoodsTransferDetails/AgGridGoodsTransferDetailColumns";
import { Formik } from "formik";
import { getBranchesAsync } from "../../../../../redux/slices/branchSlice";
import { Entity } from "../../../../../models/clientDashboard/Entity/Entity";
import FormSelectWithSearch from "../../../../CustomComponents/FormSelectWithSearch";
import { CopiedDocumentDetailsTable } from "../../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { CopyOutlined } from "@ant-design/icons";
import { mapDataToInterface } from "../../../../../helperMethods/mapDataToInterface";
import { idText } from "typescript";
import { MenuOptionEnum } from "../../../../../enums/MenuOptionEnum";
import printBase64PDF from "../../../../../helperMethods/printBase64PDF";
const { Text, Link } = Typography;

export default function CreateGoodsTransfer() {
  const navigate = useNavigate();
  const { mode, id } = useParams<{ mode: string; id?: string }>();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.CreateGoodsTransfer");
  const formikRef = useRef<any>(null);
  const userBranches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const branches = useSelector((state: RootState) => state.branch.branches);
  const documentTypes = useSelector(
    (state: RootState) => state.documentType.documentTypes
  );
  const entities = useSelector((state: RootState) => state.entity.entities);
  const [goodsTransferDetails, setGoodsTransferDetails] = useState<
    CreateOrUpdateGoodsTransferDetails[]
  >([]);
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const [copiedData, setCopiedData] = useState<CopiedDocumentDetailsTable<any> | undefined>(undefined);
  


  const handleItemSearch = async (value: any) => {
    const itemsBasedOnValueAction = await dispatch(
      getItemsBySearchValue({ searchValue: value })
    );
    const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];
    setItemOptions([]);
    setItemOptions(
      items?.map((item) => ({
        label: item.description,
        value: item.itemId.toString(),
      }))
    );
  };
  const addGoodsTransferDetails = (
    goodsTransferDetails: CreateOrUpdateGoodsTransferDetails
  ) => {
    setGoodsTransferDetails((prevGoodsTransferDetails) => {
      if (prevGoodsTransferDetails) {
        return [...prevGoodsTransferDetails, goodsTransferDetails];
      } else {
        return [goodsTransferDetails];
      }
    });
  };
  const handleOnSelect = async (value: any, values: GoodsTransferDto) => {
    if (!value || isNaN(parseInt(value))) {
      toast.error("Please enter a valid search term");
      return;
    }

    const itemID = parseInt(value);

    const itemOnDatabase = await dispatch(
      getItemDetailsForLocalPurchase({
        itemID,
        branchID: values.sendingOrganizationId,
      })
    );

    if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/rejected"
    ) {
      toast.error("Item not found");
      return;
    } else if (
      itemOnDatabase.type === "Items/getItemDetailsForLocalPurchase/fulfilled"
    ) {
      const item = itemOnDatabase.payload as GetItemDetailsDto;

      if (item) {
        const isItemExist = goodsTransferDetails?.some(
          (detail) => detail.itemId === item.itemId
        );
        if (isItemExist) {
          toast.success(t("Item"));
          return;
        }
        const newTransferDetail: CreateOrUpdateGoodsTransferDetails = {
          goodsTransferDetailsId: 0,
          sendingGoodsTransferId: values.goodsTransferId ?? "0",
          itemId: item.itemId,
          itemDescription: item.description,
          unitId: item.unitId,
          unitDescription: item.unit,
          sentQuantity: 1,
          receivedQuantity: 0,
          cost: item.costPrice,
          averageCost: item.averageCost,
          vat: item.vat,
          sellingPrice: item.salesPrice,
          rowAction: "A",
        };
        addGoodsTransferDetails(newTransferDetail);
        setTimeout(() => {
          focusOnAgGridEditingCell(goodsTransferDetailGridRef, "sentQuantity");
        }, 0);
        setSearchValue("");
      } else {
        toast.error("Item not found");
      }
    } else {
      toast.error("Something went wrong");
    }
  };
  const formValidationSchema = Yup.object({
    documentTypeId: Yup.number().required("Document Type is required"),
    sendingBranchId: Yup.number().required("Sending Branch is required"),
    receivingBranchId: Yup.number().required("Receiving Branch is required"),
    sendingEntityId: Yup.number().test(
      "conditional-required",
      "Sending Entity is required",
      function (value) {
        const { documentTypeId } = this.parent;
        if (documentTypeId === 24) {
          return true;
        }
        return !!value;
      }
    ),
    receivingEntityId: Yup.number().test(
      "conditional-required",
      "Receiving Entity is required",
      function (value) {
        const { documentTypeId } = this.parent;
        if (documentTypeId === 24) {
          return true;
        }
        return !!value;
      }
    ),
    details: Yup.array().of(
      Yup.object({
        itemId: Yup.number().required("Item is required"),
        sentQuantity: Yup.number().test(
          "conditional-required",
          "Sent Quantity is required",
          function (value) {
            const { documentTypeId } = this.parent;
            if (documentTypeId === 23) {
              return !!value;
            }
            return true;
          }
        ),
        receivedQuantity: Yup.number().test(
          "conditional-required",
          "Received Quantity is required",
          function (value) {
            const { documentTypeId } = this.parent;
            if (documentTypeId === 24) {
              return !!value;
            }
            return true;
          }
        ),
      })
    ),
  });

  const onChange = (data: any) => {
    setSearchValue(data);
  };
  const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

  const handleSearchChange = (value: string) => {
    if (!value.trim()) {
      setSearchValue("");
      return;
    }
    setSearchValue(value);
    debouncedItemSearch(value);
  };

  const fetchSuppliers = async (searchText: string) => {
    const result = await dispatch(
      getEntitiesBySearch({ searchText: searchText, isSupplier: true })
    );
    if (result.payload !== "An error occurred") {
      const payload = result.payload as Entity[];
      const options = payload.map((entity) => ({
        key: entity.entityId.toString(),
        label: entity.description,
        value: entity.entityId,
      }));
      return options;
    }
    return [];
  };

  const searchInputRef = useRef(null);
  const onGoodsTransferDetailGridReady = (params: any) => {
  };
  const goodsTransferDetailGridRef = useRef(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const removeGoodsTransferDetail = (index: number) => {
    setGoodsTransferDetails((prevGoodsTransferDetails) => {
      const activeGoodsTransferDetails = prevGoodsTransferDetails.filter(
        (GoodsTransferDetails) => GoodsTransferDetails.rowAction !== "D"
      );
      const selectedGoodsTransferDetail = activeGoodsTransferDetails[index];
      if (
        selectedGoodsTransferDetail.goodsTransferDetailsId === 0 ||
        !selectedGoodsTransferDetail.goodsTransferDetailsId
      ) {
        prevGoodsTransferDetails.splice(index, 1);
      } else {
        selectedGoodsTransferDetail.rowAction = "D";
      }
      return [...prevGoodsTransferDetails];
    });
  };
  const [columnDefs, setColumnDefs] = useState<
    ColDef<CreateOrUpdateGoodsTransferDetails>[]
  >([]);
  const [isDocumentType24, setIsDocumentType24] = useState(false);
  const [isDocumentType23, setIsDocumentType23] = useState(false);
  
  useEffect(() => {
    const documentTypeId = formikRef?.current?.values?.documentTypeId;
    
    setIsDocumentType24(documentTypeId === 24);
    setIsDocumentType23(documentTypeId === 23);
  
    // Update columns with latest values
    const values = formikRef?.current?.values;
    setColumnDefs(
      getGoodsTransferDetailColumns(
        values,
        removeGoodsTransferDetail,
        t,
        searchInputRef,
        mode ?? "",
        documentTypeId === 24,
        documentTypeId === 23
      )
    );
  }, [formikRef?.current?.values, goodsTransferDetails]);
  
  const generatedIdForCorrection = uuidv4();
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [isDocumentValidated, setIsDocumentValidated] = useState<
    boolean | null
  >(null);
  const user = useSelector((state: RootState) => state.user.loggedInUser);

  const setAdditionalStates = useCallback((data: GoodsTransferDto) => {
    if (data.details) {
      setGoodsTransferDetails(data.details);
    }
  }, []);
 const getData = async () => {
  if (id) {
    const response = await dispatch(
      getGoodsTransferDataById(id)
    );
    const data = response.payload as GoodsTransferDto;
    if (
      response.type === "GoodsTransfer/getGoodsTransferDataById/fulfilled"
    ) {
      const goodsTransfer = response.payload as GoodsTransferDto;
      formikRef.current.setValues(goodsTransfer);
      setGoodsTransferDetails(goodsTransfer?.details);
    }
    return data;
  }
  return {
    generatedId: mode !== "update" ? uuidv4() : "",
    date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
    sendingOrganizationId:
      user?.isMainBranch === false
        ? Number(user.branchId)
        : userBranches.length === 1
          ? userBranches[0].branchId
          : undefined,
    documentTypeId: 23,
  } as GoodsTransferDto;
}

  const { initialValues } = useFormData<GoodsTransferDto>(
    mode === "update" ? `goodsTransfer/update/${id}` : "goodsTransfer/register",
    {
      generatedId: mode !== "update" ? uuidv4() : "",
      date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
      sendingOrganizationId:
        user?.isMainBranch === false
          ? Number(user.branchId)
          : userBranches.length === 1
            ? userBranches[0].branchId
            : undefined,
      documentTypeId: 23,
      details: [] as CreateOrUpdateGoodsTransferDetails[],
    } as GoodsTransferDto,
    setAdditionalStates,
    {
      fetchData: getData
    }
  );

  const validationSchema = Yup.object({
    sendingOrganizationId: Yup.number().required(t("sendingOrganizationId")),
    receivingOrganizationId: Yup.number().required(
      t("receivingOrganizationId")
    ),
    date: Yup.date().required(t("date")),
    documentTypeId: Yup.number().required(t("documentTypeId")),
  });

  const onFinish = async (values: GoodsTransferDto) => {
    dispatch(setLoadingState(true));
    if (goodsTransferDetails.length === 0) {
      toast.error(t("atLeastOneDetailIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }
    if (user?.isMainBranch && !values.sendingOrganizationId) {
      toast.error(t("branchIsRequired"));
      dispatch(setLoadingState(false));
      return;
    }

    const formattedGoodsTransferDetails = goodsTransferDetails.map(
      (goodsTransferDetails) => {
        return {
          ...goodsTransferDetails,
        };
      }
    );

    values.details = formattedGoodsTransferDetails;

    if (mode === "update" && id) {
      //   values.entryItemsIdAsString = values?.entryItemsId?.toString();

      values.goodsTransferIdAsString = values.goodsTransferId;

      const result = await apiService
        .put(`/api/GoodsTransfer/update`, values)
        .then(async (response) => {
          if (response.status === 200) {
            toast.success(t("goodsTransferUpdatedSuccessfully"));
            getData();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    } else {
      await apiService
        .post("/api/GoodsTransfer/create", values)
        .then(async (response) => {
          toast.success(t("goodsTransferCreatedSuccessfully"));
          formikRef.current.setValues("goodsTransferId", response.data.Data);

          const createdId = response.data.Data;

          await db.updateTab(
            "goodsTransfer/register",
            `goodsTransfer/update/${createdId}`,
            t("tabs.updateGoodsTransfer")
          );

          navigate(`/goodsTransfer/update/${createdId}`);
        })
        .catch(() => { })
        .finally(() => {
          dispatch(setLoadingState(false));
        });
    }
    dispatch(setLoadingState(false));
  };
  const getCopiedData = async () => {
    const data = await db.getGenericData();
    if (data)
      setCopiedData(data[0])
    else
      setCopiedData(undefined);
  }
  useEffect(() => {
    dispatch(getEntitiesByTypeAsync(true));
    dispatch(getDocumentTypesWithSignHAsync("TM"));
    dispatch(getUserBranches());
    dispatch(getBranchesAsync());
    getCopiedData();
  }, [dispatch, mode, id]);

  useEffect(() => {
    const updateIndexedDB = async () => {
      const tab = await db.tabs.get("goodsTransfer/register");
      if (tab) {
        await db.tabs.put({
          ...tab,
          data: {
            ...tab.data,
            details: goodsTransferDetails,
          },
        });
      }
    };
    updateIndexedDB();
  }, [goodsTransferDetails]);
  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = await validateForm();
    setTouched({
      goodsTransferId: true,
      sendingOrganizationId: true,
      receivingOrganizationId: true,
      documentTypeId: true,
      date: true,
      documentNo: true,
      entryDate: true,
      registeredBy: true,
      registeredByName: true,
      validated: true,
      generatedId: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };

  const handleValdationButtonClick = async (goodsTransferId: string) => {
    if (initialValues?.documentTypeId == 24) {
      var details = initialValues.details;

      let hasDifference = false;

      details.forEach((detail) => {
        if (detail.sentQuantity !== detail.receivedQuantity) {
          hasDifference = true;
        }
      });
      if (hasDifference) {
        var confirm = window.confirm(t("differenceWarningMessage"));

        if (confirm) {
          await handleGoodsTransfersValidation(goodsTransferId);
        }
      } else {
        await handleGoodsTransfersValidation(goodsTransferId);
      }
    } else {
      await handleGoodsTransfersValidation(goodsTransferId);
    }
  };

  const handleGoodsTransfersValidation = async (goodsTransferId: string) => {
    const result = await dispatch(validateGoodsTransferAsync(goodsTransferId));
    if (result.type === "GoodsTransfer/validateGoodsTransfer/fulfilled") {
      await db.deleteTab(`goodsTransfer/update/${goodsTransferId}`);
      toast.success(t("goodsTransferValidatedSuccessfully"));
      navigate("/goodsTransfer");
    } else {
      toast.error(t("goodsTransferValidationFailed"));
    }
  };
  const handleGoodsTransferCorrection = async (
    goodsTransferId: string,
    generatedId: string
  ) => {
    const result = await dispatch(
      correctGoodsTransferAsync({
        goodsTransferId: goodsTransferId,
        generatedId: generatedId,
      })
    );
    if (result.type === "GoodsTransfer/correctGoodsTransfer/fulfilled") {
      const newDocumentNo = result.payload;
      await db.updateTab(
        `goodsTransfer/update/${goodsTransferId}`,
        `goodsTransfer/update/${newDocumentNo}`,
        t("tabs.updateGoodsTransfer")
      );
      toast.success(t("goodsTransferCorrectedSuccessfully"));
      navigate("/goodsTransfer");
    } else {
      toast.error(t("goodsTransferValidationFailed"));
    }
  };
  const handleCopyDetails = () => {
    copyDetails();
    message.success("You copied the document!");
  };

  const memoizedRowData = useMemo(() => {
    return goodsTransferDetails.filter((detail) => detail.rowAction !== "D");
  }, [goodsTransferDetails]);

  const copyDetails = () => {
    const details: CopiedDocumentDetailsTable<CreateOrUpdateGoodsTransferDetails> =
    {
      id: "goodsTransferDetails",
      list: goodsTransferDetails,
    };
    db.upsertGenericData(details);
  };
  const pasteDetails = async () => {
    try {
      if (copiedData) {
        const targetType = {
          sendingGoodsTransferId: "",
          receivingGoodsTransferId: undefined,
          itemId: 0,
          itemDescription: "",
          unitId: 0,
          unitDescription: "",
          sentQuantity: 0,
          receivedQuantity: 0,
          cost: 0,
          averageCost: 0,
          vat: 0,
          sellingPrice: 0,
          rowAction: "A"
        } as CreateOrUpdateGoodsTransferDetails
        const details = mapDataToInterface(copiedData.list as any[], targetType)
        setGoodsTransferDetails(details);
      } else {
        toast.error(t('toastMessages.no_document'));
      }
    } catch (error) {
      toast.error(t('toastMessages.something_wrong'));
    }
  }

  const handlePrintPdf = async (
    goodsTransferId: string,
  ) => {
    const result = await dispatch(
      handlePrintGoodsTransferForm(
        goodsTransferId
      )
    );
    if (result.type === "GoodsTransfer/handlePrintForm/fulfilled") {
      printBase64PDF(result.payload as string);
    } else {
      toast.error("error");
    }
  };

  const isHandlePrintDisabled = (
    values: GoodsTransferDto
  ) =>{
    return isLoading || !values.validated;
  };


  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={initialValues ?? ({} as GoodsTransferDto)}
        validationSchema={validationSchema}
        onSubmit={onFinish}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          handleSubmit,
          submitForm,
          validateForm,
          setTouched,
          setFieldValue,
        }) => (
          <>
            <FormHeaderOptions
              title={
                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
              }
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={t("createGoodsTransferDtoButton")}
              submitButtonIsDisabled={
                isLoading ||
                goodsTransferDetails?.filter(
                  (detail) => detail.rowAction !== "D"
                ).length === 0 ||
                values.validated ||
                values.isCorrectedDocument
              }
              createAccessEnum={MenuOptionEnum.GoodsTransferCreate}
              validateAccessEnum={MenuOptionEnum.GoodsTransferValidate}
              validateButtonIsDisabled={
                isLoading || mode !== "update" || values.validated
              }
              validateButtonText={t("validateButton")}
              handleDocumentValidation={async () => {
                if (values.goodsTransferId) {
                  await handleValdationButtonClick(
                    values.goodsTransferId.toString()
                  );
                }
              }}
              copyButtonIsDisabled={
                isLoading ||
                mode !== "update" ||
                goodsTransferDetails.length === 0
              }
              copyButtonText={
                t("CopyButton")}
              handleCopyDetails={
                mode === "update" ? () => copyDetails() : undefined
              }
              
              printAccessEnum={MenuOptionEnum.GoodsTransferPrint}
              handlePrintText={t("printButton")}
              handlePrintIsDisabled={isHandlePrintDisabled(values)}
              handlePrintForm={mode === "update" ? async () => {
                if(values.goodsTransferId){
                  await handlePrintPdf(
                    values.goodsTransferId
                  );
                }
              } : undefined }
            />
            <Form onFinish={handleSubmit} layout="vertical">
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={8}>
                      <FormSelect
                        name="documentTypeId"
                        label={t("documentTypeInputLabel")}
                        style={{ width: "100%" }}
                        value={values.documentTypeId}
                        required={true}
                        disabled={values.validated || goodsTransferDetails.length !== 0}
                        options={
                          documentTypes.map((documentType) => {
                            return {
                              key: documentType.documentId,
                              label: documentType.description,
                              value: documentType.documentId,
                            };
                          }) ?? []
                        }
                      />
                  <FormSelect
                    name="sendingOrganizationId"
                    label={t("sendingOrganizationIdLabel")}
                    required={true}
                    value={values.sendingOrganizationId}
                    options={
                      branches
                        ?.filter((item) =>
                          item.branchId !== values.receivingOrganizationId
                        )
                        ?.map((userBranch) => {
                          return {
                            key: userBranch.branchId,
                            label: userBranch.description,
                            value: userBranch.branchId,
                          };
                        }) ?? []
                    }
                    disabled={
                      !user?.isMainBranch ||
                      values.validated ||
                      values.documentTypeId == 24
                    }
                  />

                  <FormSelect
                    name="receivingOrganizationId"
                    label={t("receivingOrganizationIdLabel")}
                    required={true}
                    value={values.receivingOrganizationId}
                    options={
                      branches
                        ?.filter((item) =>
                          item.branchId !== values.sendingOrganizationId
                        )
                        ?.map((userBarnch) => {
                          return {
                            key: userBarnch.branchId,
                            label: userBarnch.description,
                            value: userBarnch.branchId,
                          };
                        }) ?? []
                    }
                    disabled={
                      values.validated ||
                      values.documentTypeId == 24 ||
                      values.isCorrectedDocument
                    }
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <FormInput
                    name="date"
                    type="date"
                    required={true}
                    label={t("dateInputLabel")}
                    value={values.date?.split("T")[0] || ""}
                    disabled={values.validated || values.isCorrectedDocument}
                  />

                  <FormSelectWithSearch
                    name="transportingSubjectId"
                    label={t("transportingSubjectIdInputLabel")}
                    value={values.transportingSubjectId}
                    disabled={
                      values.validated ||
                      values.documentTypeId == 24 ||
                      values.isCorrectedDocument
                    }
                    fetchOptions={fetchSuppliers}
                    placeHolder={t("placeHolder")}
                    onChange={(option) => {
                      setFieldValue("transportingSubjectId", option.value);
                      setFieldValue(
                        "transportingSubjectDescription",
                        option.label
                      );
                    }}
                  />
                  <FormInput
                    name="comment"
                    label={t("commentInputLabel")}
                    required={false}
                    value={values.comment}
                    disabled={values.isCorrectedDocument}
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <FormInput
                    name="goodsTransferId"
                    label={t("referenceNoInputLabel")}
                    required={false}
                    value={values.goodsTransferId}
                    disabled={true}
                  />
                  <FormInput
                    name="documentNo"
                    label={t("documentNoInputLabel")}
                    required={false}
                    value={values.documentNo}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="custom-row" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <AutoComplete
                    className="custom-search"
                    options={itemOptions}
                    onSelect={(value) => handleOnSelect(value, values)}
                    onSearch={handleSearchChange}
                    onChange={onChange}
                    value={searchValue}
                    style={{ width: 300 }}
                    disabled={
                      values.validated ||
                      values.documentTypeId == 24 ||
                      values.isCorrectedDocument
                    }
                  >
                    <Search
                      placeholder={t("searchPlaceholder")}
                      allowClear
                      ref={searchInputRef}
                      size="large"
                      onSearch={handleSearchChange}
                    />
                  </AutoComplete>
                  {copiedData && (
                    <Button onClick={pasteDetails} disabled={goodsTransferDetails.length > 0}>{t("paste")}</Button>
                  )}
                </Col>

              </Row>
              <Row gutter={[12, 8]}>
                <Col xs={24} sm={24}>
                  <AgGridTableComponent
                    style={{ overflow: "auto" }}
                    onGridReady={onGoodsTransferDetailGridReady}
                    gridRef={goodsTransferDetailGridRef}
                    isLoading={isLoading}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={memoizedRowData}
                    suppressCellFocus={true}
                    disabled={values.validated}
                    stopEditingWhenCellsLoseFocus={true}
                    onCellKeyDown={(event: CellKeyDownEvent) =>
                      handleNextGridColumnClick(event, searchInputRef)
                    }
                  />
                </Col>
              </Row>

              <HandleFormDataForTabSaving
                tabPath={
                  mode === "update"
                    ? `goodsTransfer/update/${id}`
                    : "goodsTransfer/register"
                }
                additionalStates={{
                  goodsTransferDetails: goodsTransferDetails,
                }}
              />
            </Form>
          </>
        )}
      </Formik>
    </Spin>
  );
}
