interface Props {
  color?: string;
}
export default function CloseTabIcon({ color }: Props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 0.75834C10.925 0.43334 10.4 0.43334 10.075 0.75834L6.00001 4.82501L1.92501 0.750006C1.60001 0.425006 1.07501 0.425006 0.750006 0.750006C0.425006 1.07501 0.425006 1.60001 0.750006 1.92501L4.82501 6.00001L0.750006 10.075C0.425006 10.4 0.425006 10.925 0.750006 11.25C1.07501 11.575 1.60001 11.575 1.92501 11.25L6.00001 7.17501L10.075 11.25C10.4 11.575 10.925 11.575 11.25 11.25C11.575 10.925 11.575 10.4 11.25 10.075L7.17501 6.00001L11.25 1.92501C11.5667 1.60834 11.5667 1.07501 11.25 0.75834Z"
        fill={color ?? "#A80000"}
        fillOpacity="0.8"
      />
    </svg>
  );
}
