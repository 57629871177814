import React, { ReactNode } from "react";
import { Button, Popconfirm, Form, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import SaveIcon from "../../assets/icons/SaveIcon";

interface FormSubmitButtonProps {
  handleValidate: any;
  handleConfirm: () => void;
  buttonText: string;
  icon?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  disabled?: boolean;
}

export default function FormSubmitButton({
  handleValidate,
  handleConfirm,
  buttonText,
  icon = null,
  className = "",
  style = {},
  buttonStyle = {},
  disabled = false,
}: FormSubmitButtonProps) {
  return (
    <Form.Item className={className} style={style}>
      <Popconfirm
       title="Are you sure you want to submit the form?"
       disabled={disabled} 
       onConfirm={async () => {
         if (disabled) return;
     
         if (typeof handleValidate !== "function") {
           handleConfirm();
           return;
         }
     
         try {
           const errors = (await handleValidate()) || {};
           if (Object.keys(errors).length === 0) {
             handleConfirm();
           }
         } catch (error) {
           console.error("Error during validation:", error);
         }
       }}
       okText="Yes"
       cancelText="No"
     >
       <div className={disabled ? "create-icon-form disabled" : "create-icon-form"}>
         <SaveIcon />
         <h3 className="span-text">{buttonText}</h3>
       </div>
     </Popconfirm>
    </Form.Item>
  );
}
