import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriceState } from "../../models/states/PriceState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { Prices } from "../../models/clientDashboard/ItemPrices/Prices/Prices";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";



const initialState: PriceState = {
    price: [],
    error: null
};

const priceSlice = createSlice({
    name: "price",
    initialState,
    reducers: {
        getPriceStart(state: PriceState) {
            setLoading(true);
            state.error = null;
        },
        getPriceSuccess(
            state: PriceState,
            action: PayloadAction<Prices[]>
        ) {
            setLoading(false);
            state.price = action.payload;
        },
        getPriceFailure(
            state: PriceState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewPriceToState(state: PriceState, action: PayloadAction<Prices>) {
            setLoading(false);
            state.price.push(action.payload);
        },
        updatePriceInState(state: PriceState, action: PayloadAction<Prices>) {
            setLoading(false);
            const updatedPrice = action.payload;
            const index = state.price.findIndex(price=> price.pricesId === updatedPrice.pricesId );
            if (index !== -1) {
                state.price[index] = updatedPrice;
            }
        },
        deletePriceFromState(state: PriceState, action: PayloadAction<string>) {
            setLoading(false);
            const pricesId = action.payload;
            state.price = state.price.filter(price => price.pricesId!== pricesId);
        },
    }
});

export const getPriceAsync = createAsyncThunk(
    "Price/getPrice",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;

        try {
            let url  = `api/Prices/getDataWithPagination`;
            
            const response = await apiService.get<ApiResponse<Prices[]>>(
                url
            );
            dispatch(getPriceSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getPriceFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
        }
    }
);

export const getPriceFilterColumnsAsync = createAsyncThunk(
    "Items/getPriceFilterColumnsAsync",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        try {
            const response = await apiService.get<ApiResponse<PropertyDto[]>>('api/Filter/prices');
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getPriceFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);


export const getPriceDataById = createAsyncThunk(
    "Price/getPriceDataById",
    async (data:{pricesId: string, branchID: number}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<Prices>>(
                `api/Prices/GetPricesById?pricesId=${data.pricesId}&branchId=${data.branchID}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deletePriceAsync = createAsyncThunk(
    "Price/deletePrice",
    async (pricesId: string, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/Price/${pricesId}`
            );
            dispatch(deletePriceFromState(pricesId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const validatePriceAsync = createAsyncThunk(
    "Price/validatePrice",
    async (data: {pricesId: string, branchID: string}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/Prices/Validate?pricesId=${data.pricesId}&branchId=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const correctPriceAsync = createAsyncThunk(
    "Price/correctPrice",
    async (data:{pricesId:string,generatedId:string}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const result = await apiService.put<ApiResponse<void>>(
                `/api/Price/Correct?originalpricesId=${data.pricesId}&generatedId=${data.generatedId}`
            );
            return result.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const invalidatePriceAsync = createAsyncThunk(
    "Price/invalidatePrice",
    async (data: {pricesId:string, branchID: number}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/Prices/unvalidate?pricesId=${data.pricesId}&branchId=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getPriceWithFilters = createAsyncThunk(
    "Price/getPriceWithFilters",
    async (data:FilterData[],{dispatch,getState,rejectWithValue}) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.post<ApiResponse<Prices[]>>(
                "/api/Prices/GetDataWithFilter",data
            );
            dispatch(getPriceSuccess(response.data.Data));
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(setLoadingState(false));
            return rejectWithValue(errorMessage);
        }
        finally {
            dispatch(setLoadingState(false));
        }
    }
)
export const {
    getPriceStart,
    getPriceSuccess,
    getPriceFailure,
    addNewPriceToState,
    updatePriceInState,
    deletePriceFromState
} = priceSlice.actions;

export default priceSlice.reducer;