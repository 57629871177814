import useAppTranslation from "../../customHooks/useAppTranslation";
import {
  PlusOutlined,
} from "@ant-design/icons";
import FilterIcon from "../../assets/icons/FilterIcon";
import Popconfirm from "antd/es/popconfirm";
import ExportIcon from "../../assets/icons/ExportIcon";
import "../../assets/generalStyles/ListHeaderStyles.css";
import ImportIcon from "../../assets/icons/ImportIcon";
import UploadIcon from "../../assets/icons/UploadIcon";
import WithPermission from "../Authorization/WithPermission";

interface Props {
  title: string;
  createAccessEnum?: number;
  exportAccessEnum?: number;
  importAccessEnum?: number;
  createRecord?: () => void;
  menuItemsDisabled: boolean;
  createTitle?: string;
  filterTitle?: string;
  filterState?: any;
  exportMethod?: () => void;
  exportTitle?: string;
  importMethod?: () => void;
  importTitle?: string;
  downloadTemplate?: () => void;
  downloadTemplateTitle?: string;
  handleTabClick?: (tabNumber: number) => void;
  tabs?: { tabName: string; tabNumber: number }[];
  isSelected?: number;
}

export default function ListHeaderComponent({
  title,
  createRecord,
  menuItemsDisabled,
  createTitle,
  filterTitle,
  filterState,
  exportMethod,
  exportTitle,
  importMethod,
  importTitle,
  downloadTemplate,
  downloadTemplateTitle,
  handleTabClick,
  tabs,
  isSelected,
  createAccessEnum,
  exportAccessEnum,
  importAccessEnum
}: Props) {
  const t = useAppTranslation("ClientDashboard.ListHeaderComponent");
  return (
    <div className="wrapper">
      {/* <div className="div-border">
         <Title className="headerTitle">{title}</Title>
      </div> */}
      <div className="button-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {createRecord && (
            <WithPermission
            menuOptionId={createAccessEnum??-1}
            children={
            <div
              className={
                menuItemsDisabled ? "create-icon disabled" : "create-icon"
              }
              onClick={createRecord}
            >
              <PlusOutlined />
              <span>{createTitle}</span>
            </div>}
            />
          )}
        </div>
        {handleTabClick && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {tabs?.map((tab) => (
              <div
                className={`styled-div ${
                  isSelected === tab.tabNumber ? "selected" : ""
                }`}
                onClick={() => handleTabClick(tab.tabNumber)}
              >
                {tab.tabName}
              </div>
            ))}

            {/*           
          <div
            className={`styled-div ${isSelected === 24 ? 'selected' : ''}`}
            onClick={() => handleTabClick(24)}
          >
            {t("goodsRecieved")}
          </div> */}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {filterState && (
            <div
              className={menuItemsDisabled ? "menu-icon disabled" : "menu-icon"}
              onClick={() => {
                filterState?.setFilterModal(true);
                filterState?.handleGetFilterColumns();
              }}
            >
              <FilterIcon />
              <h3 className="span-text">{t("filter")}</h3>
            </div>
          )}
          {exportMethod && (
            <WithPermission
            menuOptionId={exportAccessEnum ?? -1}
            children={
            <Popconfirm
              placement="top"
              title={
                "A jeni te sigurt qe deshironi te shkarkoni te dhenat ne excel?"
              }
              okText="Yes"
              cancelText="No"
              onConfirm={exportMethod}
              className={menuItemsDisabled ? "menu-icon disabled" : "menu-icon"}
            >
              <ExportIcon />
              <h3 className="span-text">{t("export")}</h3>
            </Popconfirm>}
            />
          )}
          {importMethod && (
            <WithPermission
            menuOptionId={importAccessEnum ?? -1}
            children={
            <div
              className={menuItemsDisabled ? "menu-icon disabled" : "menu-icon"}
              onClick={importMethod}
            >
              <ImportIcon />
              <h3 className="span-text">{t("import")}</h3>
            </div>}
            />
          )}
          {downloadTemplate && (
            <Popconfirm
              placement="top"
              title={"A jeni te sigurt qe deshironi te shkarkoni shabllonin?"}
              okText="Yes"
              cancelText="No"
              onConfirm={downloadTemplate}
              className={menuItemsDisabled ? "menu-icon disabled" : "menu-icon"}
            >
              <UploadIcon />
              <h3 className="span-text">{t("upload")}</h3>
            </Popconfirm>
          )}
        </div>
      </div>
    </div>
  );
}
