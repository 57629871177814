import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImportPurchaseState } from "../../models/states/ImportPurchaseState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { ImportPurchase } from "../../models/clientDashboard/ImportPurchase/ImportPurchase";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";


const initialState: ImportPurchaseState = {
    importPurchases: [],
    error: null
};

const importPurchaseSlice = createSlice({
    name: "importPurchase",
    initialState,
    reducers: {
        getImportPurchasesStart(state: ImportPurchaseState) {
            setLoading(true);
            state.error = null;
        },
        getImportPurchasesSuccess(
            state: ImportPurchaseState,
            action: PayloadAction<ImportPurchase[]>
        ) {
            setLoading(false);
            state.importPurchases = action.payload;
        },
        getImportPurchasesFailure(
            state: ImportPurchaseState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewImportPurchaseToState(state: ImportPurchaseState, action: PayloadAction<ImportPurchase>) {
            setLoading(false);
            state.importPurchases.push(action.payload);
        },
        updateImportPurchaseInState(state: ImportPurchaseState, action: PayloadAction<ImportPurchase>) {
            setLoading(false);
            const updatedImportPurchase = action.payload;
            const index = state.importPurchases.findIndex(importPurchase => importPurchase.entryItemsId === updatedImportPurchase.entryItemsId);
            if (index !== -1) {
                state.importPurchases[index] = updatedImportPurchase;
            }
        },
        deleteImportPurchaseFromState(state: ImportPurchaseState, action: PayloadAction<number>) {
            setLoading(false);
            const importPurchaseId = action.payload;
            state.importPurchases = state.importPurchases.filter(importPurchase => importPurchase.entryItemsId !== importPurchaseId);
        },
    }
});

export const getImportlPurchasesAsync = createAsyncThunk(
    "ImportPurchase/getImportPurchases",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;

        try {
            let url = `api/ImportPurchase/getDataWithPagination`;

            const response = await apiService.get<ApiResponse<ImportPurchase[]>>(
                url
            );
            dispatch(getImportPurchasesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getImportPurchasesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
        }
    }
);

export const getImportPurchaseFilterColumnsAsync = createAsyncThunk(
    "Items/getImportPurchaseFilterColumnsAsync",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        try {
            const response = await apiService.get<ApiResponse<PropertyDto[]>>('api/Filter/import-purchase');
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getImportPurchasesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);


export const getImportPurchaseDataById = createAsyncThunk(
    "ImportPurchases/getImportPurchaseDataById",
    async (data: { importPurchaseId: string, branchID: string }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<ImportPurchase>>(
                `/api/ImportPurchase/getImportPurchaseById?entryItemsId=${data.importPurchaseId}&branchId=${data.branchID}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const deleteImportPurchaseAsync = createAsyncThunk(
    "ImportPurchases/deleteImportPurchase",
    async (importPurchaseId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.delete<ApiResponse<void>>(
                `/api/ImportPurchase/${importPurchaseId}`
            );
            dispatch(deleteImportPurchaseFromState(importPurchaseId));
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const validateImportPurchaseAsync = createAsyncThunk(
    "ImportPurchases/validateImportPurchase",
    async (data: { entryItemId: string, branchID: string }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/ImportPurchase/Validate?entryItemId=${data.entryItemId}&branchID=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const correctImportPurchaseAsync = createAsyncThunk(
    "ImportPurchases/correctImportPurchase",
    async (data: { entryItemsId: string, generatedId: string, branchID: number }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const result = await apiService.put<ApiResponse<void>>(
                `/api/ImportPurchase/Correct?originalEntryItemId=${data.entryItemsId}&generatedId=${data.generatedId}&branchID=${data.branchID}`
            );
            return result.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getImportPurchasesWithFilters = createAsyncThunk(
    "ImportPurchases/getImportPurchasesWithFilters",
    async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.post<ApiResponse<ImportPurchase[]>>(
                "/api/ImportPurchase/GetDataWithFilter", data
            );
            dispatch(getImportPurchasesSuccess(response.data.Data));
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(setLoadingState(false));
            return rejectWithValue(errorMessage);
        }
        finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const invalidateImportPurchaseAsync = createAsyncThunk(
    "ImportPurchases/invalidateImportPurchases",
    async (data: { entryItemId: string, branchID: number }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            await apiService.put<ApiResponse<void>>(
                `/api/ImportPurchase/Unvalidate?entryItemId=${data.entryItemId}&branchID=${data.branchID}`
            );
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const handlePrintImportPurchaseForm = createAsyncThunk(
    "ImportPurchase/handlePrintForm",
    async ( entryItemsId: string ,
      {dispatch, getState, rejectWithValue}
    ) => {
      try{
        dispatch(setLoadingState(true));
        const result = await apiService.get<ApiResponse<string>>(
          `/api/ImportPurchase/get-report?entryItemId=${entryItemsId}`
        );
        return result.data.Data;
      }catch (error: any) {
        const errorMessage =
          typeof error === "string" ? error : "An error occurred";
        return rejectWithValue(errorMessage);
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  );
export const {
    getImportPurchasesStart,
    getImportPurchasesSuccess,
    getImportPurchasesFailure,
    addNewImportPurchaseToState,
    updateImportPurchaseInState,
    deleteImportPurchaseFromState
} = importPurchaseSlice.actions;

export default importPurchaseSlice.reducer;