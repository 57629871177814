import React, { useEffect, useRef, useState } from "react";
import { Spin, Row, Col, Typography, Button, Form } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { getSubscriptionPlansAsync } from "../../../redux/slices/dashboardSubscriptionsSlice";
import { getClientTypesAsync } from "../../../redux/slices/clientTypesSlice";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import ClientCreatedModal from "./ClinetCreatedModal";
import FormSelect from "../../CustomComponents/FormSelect";
import { AppDispatch, RootState } from "../../../redux";
import FormInput from "../../CustomComponents/FormInput";
import 'react-image-crop/dist/ReactCrop.css';
import UploadPhoto from "../../CustomComponents/uploadPhoto";

const { Title, Text } = Typography;

const CreateClient = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const subscriptionPlans = useSelector(
    (state: RootState) => state.dashboardSubscriptions.subscriptionPlans
  );
  const clientTypes = useSelector(
    (state: RootState) => state.clientTypes.clientTypes
  );

  const [showModal, setShowModal] = useState(false);
  const [createdUser, setCreatedUser] = useState({
    username: "",
    password: "",
  });

  const [logoBase64, setLogoBase64] = useState("");
 


  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const t = useAppTranslation("AdminDashboard.CreateClient");


  const subscriptionPlansOptions =
    subscriptionPlans?.map((item) => ({
      key: item.subscriptionPlanId,
      label: item.subscriptionPlanName,
      value: item.subscriptionPlanId,
    })) || [];

  const clientTypesOptions =
    clientTypes?.map((item) => ({
      key: item.clientTypeID,
      label: item.typeDescription,
      value: item.clientTypeID,
    })) || [];

  useEffect(() => {
    dispatch(getSubscriptionPlansAsync());
    dispatch(getClientTypesAsync());
  }, [dispatch]);

  const onFinish = async (values: any) => {
    const selectedSubscriptionPlan = subscriptionPlans.find(
      (item) => item.subscriptionPlanId === values.subscriptionPlanId
    );

    if (!selectedSubscriptionPlan) {
      toast.error(t("Subscription plan not found!"));
      return;
    }

    const data = {
      companyName: values.name,
      email: values.email,
      phone: values.phone,
      clientTypeID: parseInt(values.clientTypeId, 10),
      VATNumber: values.fiscalNumber,
      businessNumber: values.businessNumber,
      numOfUsers: selectedSubscriptionPlan.numOfUsers,
      pricePerUser: selectedSubscriptionPlan.pricePerUser,
      subscriptionPlanId: values.subscriptionPlanId,
      currencyId: selectedSubscriptionPlan.currencyId,
      mainBranchName: values.mainBranchName || "",
      base64Logo: logoBase64,
    };

    dispatch(setLoadingState(true));

    try {
      const response = await apiService.post(
        "/api/dashboard/company/register",
        data
      );
      toast.success(t("clientCreatedSuccessfull"));
      setShowModal(true);
      setCreatedUser({
        username: response.data.Data.username,
        password: response.data.Data.password,
      });
    } catch (error) {
      toast.error(t("Error creating client!"));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const navigateToClients = () => {
    navigate("/dashboard/clients");
  };

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    fiscalNumber: "",
    businessNumber: "",
    clientTypeId: "",
    subscriptionPlanId: "",
    mainBranchName: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Name is required")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t("Email is required")),
    phone: Yup.string().required(t("Phone number is required")),
    fiscalNumber: Yup.string().required(t("Fiscal number is required")),
    businessNumber: Yup.string().required(t("Business number is required")),
    clientTypeId: Yup.string().required(t("Client type is required")),
    subscriptionPlanId: Yup.string().required(
      t("Subscription plan is required")
    ),
  });

  return (
    <>
      <ClientCreatedModal
        handleCancel={navigateToClients}
        handleOk={navigateToClients}
        visible={showModal}
        username={createdUser.username}
        password={createdUser.password}
      />
      <Spin tip="Loading..." spinning={loadScreen}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFinish}
        >
          {({ handleSubmit, values }) => (
            <Form onFinish={handleSubmit} style={{ marginTop: 20 }}>
              <Button type="primary" htmlType="submit" style={{ marginBottom: '20px' }}>
                {t("submitButton")}
              </Button>
              <Col xs={24} sm={12} style={{ marginBottom: "20px" }}>
                <UploadPhoto logoBase64={logoBase64} setLogoBase64={setLogoBase64}/>
              </Col>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <FormInput
                    label={t("nameInputLabel")}
                    name="name"
                    value={values.name}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <FormInput
                    label={t("emailInputLabel")}
                    name="email"
                    value={values.email}
                  />
                </Col>

                <Col xs={24} sm={12}>
                  <FormInput
                    label={t("phoneInputLabel")}
                    name="phone"
                    type="text"
                    value={values.phone}
                  />
                </Col>

                <Col xs={24} sm={12}>
                  <FormInput
                    label={t("fiscalNumberInputLabel")}
                    name="fiscalNumber"
                    value={values.fiscalNumber}
                  />
                </Col>

                <Col xs={24} sm={12}>
                  <FormInput
                    label={t("businessNumberInputLabel")}
                    name="businessNumber"
                    type="text"
                    value={values.businessNumber}
                  />
                </Col>

                <Col xs={24} sm={12}>
                  <FormInput
                    name="mainBranchName"
                    label={t("mainBranchName")}
                    required={true}
                    value={values.mainBranchName}
                  />
                </Col>

                <Col xs={24} sm={12}>
                  <FormSelect
                    name="subscriptionPlanId"
                    label={t("subscriptionPlan")}
                    options={subscriptionPlansOptions}
                    value={values.subscriptionPlanId}
                    required={true}
                  />
                </Col>

                <Col xs={24} sm={12}>
                  <FormSelect
                    name="clientTypeId"
                    label={t("clientType")}
                    options={clientTypesOptions}
                    required={true}
                    value={values.clientTypeId}
                  />
                </Col>


              </Row>
            </Form>
          )}
        </Formik>
      </Spin>
    
    </>
  );
};

export default CreateClient;
