import React from "react";
import { Menu } from "antd";
import {
  PieChartOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined,
  TrophyOutlined,
  PicLeftOutlined,
  UserAddOutlined,
  StockOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useAppTranslation from "../../customHooks/useAppTranslation";
import DashboardIcon from "../../assets/icons/DashboardIcon";
import { MenuAdminOptionEnum } from "../../enums/MenuAdminOptionEnum";

interface MenuItem {
  label: string;
  key: string;
  icon: JSX.Element;
  path?: string;
  menuOptionId: MenuAdminOptionEnum;
}

interface NavigationMenuProps {
  version: string;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ version }) => {
  const navigate = useNavigate();

  const t = useAppTranslation("AdminDashboard.NavigationMenu");

  const items: MenuItem[] = [
    {
      label: t("home"),
      key: "app",
      // icon: <PieChartOutlined />,
      icon: <DashboardIcon />,
      path: "/dashboard",
      menuOptionId: MenuAdminOptionEnum.AdminDashboardAccess
    },
    {
      label: t("companies"),
      key: "clients",
      icon: <UsergroupAddOutlined />,
      path: "clients",
      menuOptionId: MenuAdminOptionEnum.AdminClientAccess
    },
    // {
    //   label: t("registerCompany"),
    //   key: "create-register",
    //   icon: <UserAddOutlined />,
    //   path: "client/register",
    // },
    {
      label: t("servers"),
      key: "servers",
      icon: <PicLeftOutlined />,
      path: "servers",
      menuOptionId: MenuAdminOptionEnum.AdminServerAccess
    },
    // {
    //   label: t("registerServer"),
    //   key: "servers-register",
    //   icon: <PicLeftOutlined />,
    //   path: "servers/register",
    // },
    {
      label: t("clientSubscriptions"),
      key: "subscriptions",
      icon: <TrophyOutlined />,
      path: "subscriptions",
      menuOptionId: MenuAdminOptionEnum.AdminSubscriptionsAccess
    },
    {
      label: t("subscription_payments"),
      key: "subscription-payments",
      icon: <StockOutlined />,
      path: "subscription-payments",
      menuOptionId: MenuAdminOptionEnum.AdminSubscriptionPaymentsAccess
    },
    {
      label: t("admin_users"),
      key: "adminUsers",
      icon: <StockOutlined />,
      path: "adminUsers",
      menuOptionId: MenuAdminOptionEnum.AdminDashboardAccess
    },
    // {
    //   label: t("createSubscription"),
    //   key: "create-subscriptions",
    //   icon: <TrophyOutlined />,
    //   path: "subscription-register",
    // },
    // {
    //   label: t("settings"),
    //   key: "settings",
    //   icon: <AppstoreOutlined />,
    //   path: "settings",
    // },
  ];

  const onClick = (item: MenuItem) => {
    if (item.path) {
      navigate(item.path);
    }
  };

  return (
    <Menu mode="vertical">
      {items.map((item) => (
        <Menu.Item
          key={item.key ?? "1"}
          icon={item.icon}
          onClick={() => onClick(item)}
        >
          {item.label}
        </Menu.Item>
      ))}
      {/* Version item */}
      <Menu.Item disabled style={{ position: "absolute", bottom: 0 }} key={"1"}>
        Version: {version}
      </Menu.Item>
    </Menu>
  );
};

export default NavigationMenu;
