import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { CreateAndEditLocalPurchaseDto } from "../../../../models/clientDashboard/LocalPurchase/CreateAndEditLocalPurchaseDto";
import { AppDispatch, RootState } from "../../../../redux";
import { Classifier } from "../../../../models/clientDashboard/Classifier/Classifier";
import FormInput from "../../../CustomComponents/FormInput";
import FormSelect from "../../../CustomComponents/FormSelect";
import { Col, Row } from "antd";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";
import { fetchClassifiers } from "../../../../redux/slices/classifierSlice";

interface Props {
  values: CreateAndEditLocalPurchaseDto;
  setFieldValue: (name: string, value: any) => void;
}
export default function LocalPurchaseClassifiersComponent({ values, setFieldValue }: Props) {
  const t = useAppTranslation("ClientDashboard.CreateLocalPurchase.LocalPurchaseClassifiersComponent");
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const filteredClassifiers = classifiers.filter(
    (classifier) =>
      classifier.description !== "K1" &&
      classifier.description !== "K2" &&
      classifier.description !== "K3" &&
      classifier.description !== "K4" &&
      classifier.status === true
  );

  function getNestedValue(obj: any, path: any) {
    return path.split('.').reduce((acc: any, part: any) => acc && acc[part], obj);
  }

  const groupSize = Math.ceil(filteredClassifiers.length / 3);
  const groups = [
    filteredClassifiers.slice(0, groupSize),
    filteredClassifiers.slice(groupSize, groupSize * 2),
    filteredClassifiers.slice(groupSize * 2)
  ];

  const getClassifierValueByNameForSelect = (name: string, item: any) => {
    const categoryValue = item[name];
    if (typeof categoryValue === 'number') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue.classifierDetailId || undefined;
    } else {
      return undefined;
    }
  };
  const getClassifierValueByNameForInput = (name: string, item: any) => {
    const categoryValue = item[name];
    if (typeof categoryValue === 'number' || typeof categoryValue === 'string') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue.classifierDetailDescription || undefined;
    } else {
      return undefined;
    }
  };

  const getClassifierDescriptionValue = (name: string, item: any) => {
    const descriptionValue = getNestedValue(item, name);
    switch (name) {
      case "k45Description":
        return item?.k45Description;
      case "k46Description":
        return item?.k46Description;
      case "k47Description":
        return item?.k47Description;
      case "k48Description":
        return item?.k48Description;
      case "k49Description":
        return item?.k49Description;
      case "k50":
        return item?.k50Description;
      case "k51":
        return item?.k51Description;
      case "k52":
        return item?.k52Description;
      case "k53":
        return item?.k53Description;
      case "k54":
        return item?.k54Description;
      default:
        return "";
    }
  };

  const renderGroup = (group: Classifier[]) => {
    const inputComponents = group
      .filter(
        (classifier) =>
          classifier.description !== "K1" &&
          classifier.description !== "K2" &&
          classifier.description !== "K3" &&
          classifier.description !== "K4" &&
          classifier.status === true
      )
      .map((classifier) => {
        switch (classifier.classifierTypeDescription.toLowerCase()) {
          case "text":
            const textValue = getClassifierValueByNameForInput(classifier.description.toLowerCase(), values);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage"),
                  },
                ]}
                value={textValue}
                disabled={values.validated}
              />
            );
          case "select":
            const value = getClassifierValueByNameForSelect(classifier?.description?.toLowerCase(), values)
            const label = getClassifierDescriptionValue(`${classifier?.description?.toLowerCase()}Description`, values);
            return (
              <FormSelectWithSearch
                key={classifier.description}
                name={classifier?.description?.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                required={classifier.mandatory}
                value={values[classifier?.description?.toLowerCase()]}
                disabled={values.validated}
                fetchOptions={(searchText: string) => fetchClassifiers(searchText, classifier.classifierId ?? "")}
                fetchInitialOption={() => {
                  return {
                    key: values[classifier?.description?.toLowerCase()],
                    label: label,
                    value: values[classifier?.description?.toLowerCase()],
                  };
                }}
                placeHolder={t("placeHolder")}
                onChange={(option) => {
                  setFieldValue(classifier?.description?.toLowerCase(), option.value);
                }}
              />
            );
          case "number":
          case "decimal number":
            const numberValue = getClassifierValueByNameForInput(classifier.description.toLowerCase(), values);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage")
                  },
                  {
                    pattern: {
                      value: new RegExp(classifier.regex ?? ""),
                      message: t("numberPatternMessage"),
                    },
                  },
                ]}
                type="number"
                value={numberValue}
                disabled={values.validated}
              />
            );
          case "email":
            const emailValue = getClassifierValueByNameForInput(classifier.description.toLowerCase(), values);
            return (
              <FormInput
                required={classifier.mandatory}
                key={classifier.description}
                name={classifier.description.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                rules={[
                  {
                    required: classifier.mandatory,
                    message: t("classifierRequiredMessage"),
                  },
                  {
                    pattern: {
                      value: new RegExp(classifier.regex ?? ""),
                      message: t("emailPatternMessage"),
                    },
                  },
                ]}
                value={emailValue}
                type="email"
                disabled={values.validated}
              />
            );
          default:
            return null;
        }
      });

    return inputComponents;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={8}>{renderGroup(groups[0])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[1])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[2])}</Col>
    </Row>
  );
}
