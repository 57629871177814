import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./english.json";
import al from "./albanian.json";

const initializeI18n = () => {
  const storedLanguage = localStorage.getItem('langCode') || "al";

  i18n.use(initReactI18next).init({
    lng: storedLanguage,
    resources: {
      en: { translation: en },
      al: { translation: al },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};

initializeI18n();

export default i18n;
