import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useCallback, useEffect, useRef, useState } from "react";
import { CreateOrUpdateSaleOrderDetailDto } from "../../../../models/clientDashboard/EntriesModule/SaleOrder/CreateOrUpdateSaleOrderDetailsDto";
import { GenericDocumentDto } from "../../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateSaleOrderDto } from "../../../../models/clientDashboard/EntriesModule/SaleOrder/CreateOrUpdateSaleOrderDto";
import useFormData from "../../../../customHooks/useFormData";
import moment from "moment";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import apiService from "../../../../extensions/api";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getSaleOrdersDataById, invalidateSaleOrdersAsync, validateSaleOrdersAsync } from "../../../../redux/slices/saleOrderSlice";
import { getItemsWithFilters } from "../../../../redux/slices/itemSlice";
import { getClassifiersByTypeAsync } from "../../../../redux/slices/classifierSlice";
import { getEntitiesByTypeAsync } from "../../../../redux/slices/entitySlice";
import { getCurrenciesAsync } from "../../../../redux/slices/currencySlice";
import { getDocumentTypesWithSignHAsync } from "../../../../redux/slices/documentTypeSlice";
import { getUserBranches } from "../../../../redux/slices/userSlice";
import { CopiedDocumentDetailsTable } from "../../../../indexDB/databaseTables/copiedDocumentDetailsTable";
import { Form, Spin, Tabs } from "antd";
import { Formik } from "formik";
import FormHeaderOptions from "../../../CustomComponents/FormHeaderOptions";
import HandleFormDataForTabSaving from "../../../../helperMethods/handleFormDataForTabSaving";
import MainFieldsComponent from "./CreateSaleOrderComponent/MainFieldsComponent";
import { SelectOption } from "../../../../models/SelectOption";
import FormInput from "../../../CustomComponents/FormInput";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";


export default function CreateSaleOrder() {
    const navigate = useNavigate();
    const { type, mode, id, branchId } = useParams<{
        type: string;
        mode: string;
        id?: string;
        branchId?: string;
    }>();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const dispatch = useDispatch<AppDispatch>();
    const t = useAppTranslation("ClientDashboard.CreateSaleOrder");
    const formikRef = useRef<any>(null);
    const userBranches = useSelector(
        (state: RootState) => state.user.loggedInUserBranches
    );

    const [saleOrdersDetails, setSaleOrdersDetails] = useState<
        CreateOrUpdateSaleOrderDetailDto[]
    >([]);
    const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
    const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
        setSupplierOptions(options);
    };
    const classifiers = useSelector(
        (state: RootState) => state.classifier.classifiers
    );
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const [isDocumentValidated, setIsDocumentValidated] = useState<
        boolean | null
    >(null);
    const [supplierBranchOptions,setSupplierBranchOptions] = useState<SelectOption[]>([]);
    const addInitialOptionsOfSupplierBranch = (options: SelectOption[]) => {
        setSupplierBranchOptions(options);
    };
    const user = useSelector((state: RootState) => state.user.loggedInUser);
    const setAdditionalStates = useCallback(
        (
            data: GenericDocumentDto<
                CreateOrUpdateSaleOrderDto,
                CreateOrUpdateSaleOrderDetailDto
            >
        ) => {
            if (data.details) {
                setSaleOrdersDetails(data.details);
            }
           
        },
        []
    );

    const getData = async () => {
        if (id && branchId) {
            const response = await dispatch(
                getSaleOrdersDataById({ saleOrderId: id, branchID: branchId })
            );
            const data = response.payload as GenericDocumentDto<
                CreateOrUpdateSaleOrderDto,
                CreateOrUpdateSaleOrderDetailDto>;
                if (
                    response.type ===
                    "SaleOrders/getSaleOrdersDataById/fulfilled"
                ) {
                    const saleOrder = response.payload as GenericDocumentDto<
                        CreateOrUpdateSaleOrderDto,
                        CreateOrUpdateSaleOrderDetailDto
                    >;

                    setInitialValues(saleOrder);
                    setSupplierOptions([
                        {
                            key: saleOrder?.header?.subjectId,
                            label: saleOrder?.header?.subjectDescription ?? "",
                            value: saleOrder?.header?.subjectId ?? 0,
                        }
                    ]);
                    setSupplierBranchOptions([
                        {
                            key: saleOrder?.header?.branchSubjectId,
                            label: saleOrder?.header?.branchSubjectDescription ?? "",
                            value: saleOrder?.header?.branchSubjectId ?? 0,
                        }
                    ])

                    formikRef?.current?.setValues(saleOrder);
                    const updatedDetails = saleOrder.details.map((detail) => ({
                        ...detail,
                    }));
                    setSaleOrdersDetails(updatedDetails);
                }
            return data;
        }
        return {generatedId: mode !== "update" ? uuidv4() : "",
            header: { exchangeRate: 1 },
            date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
            branchId:
                user?.isMainBranch === false
                    ? Number(user.branchId)
                    : userBranches.length === 1
                        ? userBranches[0].branchId
                        : undefined,} as GenericDocumentDto<
            CreateOrUpdateSaleOrderDto,
            CreateOrUpdateSaleOrderDetailDto>;
    }

    const { initialValues, setInitialValues } = useFormData<
        GenericDocumentDto<
            CreateOrUpdateSaleOrderDto,
            CreateOrUpdateSaleOrderDetailDto
        >
    >(
        mode === "update"
            ? `saleOrder/update/${id}/${branchId}`
            : `saleOrder/register`,
        {
            generatedId: mode !== "update" ? uuidv4() : "",
            header: { exchangeRate: 1 },
            date: moment.tz("Europe/Tirane").format("YYYY-MM-DD"),
            branchId:
                user?.isMainBranch === false
                    ? Number(user.branchId)
                    : userBranches.length === 1
                        ? userBranches[0].branchId
                        : undefined,

        } as GenericDocumentDto<
            CreateOrUpdateSaleOrderDto,
            CreateOrUpdateSaleOrderDetailDto
        >,
        setAdditionalStates,
        {
            fetchData: getData
        }
    );
    const removeSaleOrdersDetail = (index: number) => {
        setSaleOrdersDetails((prevSaleOrdersDetails) => {
            const activeSaleOrdersDetails = prevSaleOrdersDetails.filter(
                (saleOrdersDetails) => saleOrdersDetails.rowAction !== "D"
            );
            const selectedSaleOrdersDetail =
                activeSaleOrdersDetails[index];
            if (
                selectedSaleOrdersDetail.saleOrderDetailId === 0 ||
                !selectedSaleOrdersDetail.saleOrderDetailId
            ) {
                prevSaleOrdersDetails.splice(index, 1);
            } else {
                selectedSaleOrdersDetail.rowAction = "D";
            }
            return [...prevSaleOrdersDetails];
        });
    };
    const addSaleOrdersDetail = (
        saleOrdersDetail: CreateOrUpdateSaleOrderDetailDto
    ) => {
        setSaleOrdersDetails((prevSaleOrdersDetails) => {
            return [...prevSaleOrdersDetails, saleOrdersDetail];
        });
    };

    const validationSchema = Yup.object({
        branchId: Yup.number().required(t("branchIsRequired")),
        documentTypeId: Yup.number().required(t("documentTypeIsRequired")),
        header: Yup.object({
            subjectId: Yup.number()
                .required(t("subjectIsRequired"))
        }),
    });

    const onFinish = async (
        values: GenericDocumentDto<
            CreateOrUpdateSaleOrderDto,
            CreateOrUpdateSaleOrderDetailDto
        >
    ) => {
        dispatch(setLoadingState(true));
        if (saleOrdersDetails.length === 0) {
            toast.error(t("atLeastOneDetailIsRequired"));
            dispatch(setLoadingState(false));
            return;
        }
        if (user?.isMainBranch && !values.branchId) {
            toast.error(t("branchIsRequired"));
            dispatch(setLoadingState(false));
            return;
        }

        values.details = saleOrdersDetails;


        if (mode === "update" && id) {
            const result = await apiService
                .post(`/api/SaleOrders/update`, values)
                .then(async (response) => {
                    if (response.status === 200) {
                        toast.success(t("updatedSuccessfully"));
                        getData();
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        } else {
            await apiService
                .post("/api/SaleOrders/create", values)
                .then(async (response) => {
                    toast.success(t("createdSuccessfully"));
                    formikRef.current.setFieldValue(
                        "genericDocumentId",
                        response.data.Data
                    );
                    formikRef.current.setFieldValue(
                        "genericDocumentIdAsString",
                        response.data.Data
                    );

                    const createdId = response.data.Data;

                    await db.updateTab(
                        `saleOrder/register`,
                        `saleOrder/update/${createdId}/${values.branchId}`,
                        t("tabs.updateSaleOrders")
                    );

                    navigate(`/saleOrder/update/${createdId}/${values.branchId}`);
                })
                .catch(() => { })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        }
        dispatch(setLoadingState(false));
    };

    useEffect(() => {
        dispatch(getItemsWithFilters([]));
        dispatch(getEntitiesByTypeAsync(true));
        dispatch(getCurrenciesAsync());
        dispatch(getDocumentTypesWithSignHAsync("PSH"));
        dispatch(getUserBranches());
    }, [dispatch, mode, id, branchId]);
    const [tab, setTab] = useState<any>(null);

    useEffect(() => {
        const updateIndexedDB = async () => {
            setTab(await db.tabs.get(`saleOrder/update/${id}`));

            const tab = await db.tabs.get(
                mode === "update"
                    ? `saleOrder/update/${id}`
                    : "saleOrder/register"
            );
            if (tab) {
                await db.tabs.put({
                    ...tab,
                    data: {
                        ...tab.data,
                        details: saleOrdersDetails,
                    },
                });
            }
        };

        updateIndexedDB();
    }, [saleOrdersDetails, id]);
    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm();
        setTouched({
            genericDocumentId: true,
            genericDocumentIdAsString: true,
            branchId: true,
            documentTypeId: true,
            date: true,
            documentNo: true,
            header: true,
            generatedId: true,
            registeredBy: true,
            validated: true,
            details: true,
            subjectId: true,
            registrationDate: true,
            branchSubjectId: true,
            comment: true,
            saleId: true,
            exchangeRate: true,
            currencyId: true,
            agentId: true,
            refuse: true,
            validationDate: true,
            scanned: true,
            validatedBy: true,
            cCPKompaniaId: true,
        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    };
    const handleSaleOrdersValidation = async (
        saleOrderId: string,
        branchId: string
    ) => {
        const result = await dispatch(
            validateSaleOrdersAsync({
                saleOrderId: saleOrderId,
                branchID: branchId ?? "",
            })
        );
        if (result.type === "SaleOrder/validateSaleOrders/fulfilled") {
            toast.success(t("validatedSuccessfully"));
            formikRef.current.setFieldValue("validated", true);
        } else {
            toast.error(t("ValidationFailed"));
        }
    };

    const handleSaleOrdersInvalidation = async (
        saleOrderId: string,
        branchId: number
    ) => {
        const result = await dispatch(
            invalidateSaleOrdersAsync({
                saleOrderId: saleOrderId,
                branchID: branchId,
            })
        );
        if (
            result.type === "SaleOrder/invalidateSaleOrder/fulfilled"
        ) {
            toast.success(t("invalidatedSuccessfully"));
            formikRef.current.setFieldValue("validated", false);
        } else {
            toast.error(t("invalidationFailed"));
        }
    };

    const isInvalidateButtonDisabled = (
        values: GenericDocumentDto<
            CreateOrUpdateSaleOrderDto,
            CreateOrUpdateSaleOrderDetailDto
        >
    ) => {
        return isLoading || !values.validated;
    };
    const copyDetails = () => {
        const details: CopiedDocumentDetailsTable<CreateOrUpdateSaleOrderDetailDto> = {
            id: "SaleOrderDetails",
            list: saleOrdersDetails
        }
        db.upsertGenericData(details);
    }



    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={
                    initialValues ??
                    ({} as GenericDocumentDto<
                        CreateOrUpdateSaleOrderDto,
                        CreateOrUpdateSaleOrderDetailDto
                    >)
                }
                validationSchema={validationSchema}
                onSubmit={onFinish}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    handleSubmit,
                    submitForm,
                    validateForm,
                    setTouched,
                    setFieldValue,
                }) => (
                    <>
                        <FormHeaderOptions
                            title={
                                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
                            }
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("createButton")}
                            submitButtonIsDisabled={
                                isLoading ||
                                saleOrdersDetails.filter(
                                    (detail) => detail.rowAction !== "D"
                                ).length === 0 ||
                                values.validated
                            }
                            createAccessEnum={MenuOptionEnum.SaleOrderCreate}
                            validateAccessEnum={MenuOptionEnum.SaleOrderValidate}
                            validateButtonIsDisabled={
                                isLoading || mode !== "update" || values.validated
                            }
                            validateButtonText={t("validateButton")}
                            handleDocumentValidation={async () => {
                                if (values.genericDocumentIdAsString) {
                                    await handleSaleOrdersValidation(
                                        values.genericDocumentIdAsString,
                                        values?.branchId?.toString() ?? ""
                                    );
                                }
                            }}
                            invalidateAccessEnum={MenuOptionEnum.SaleOrderInvalidate}
                            invalidateButtonText={t("invalidateButton")}
                            invalidateButtonIsDisabled={isInvalidateButtonDisabled(values)}
                            handleDocumentInvalidation={async () => {
                                await handleSaleOrdersInvalidation(
                                    values.genericDocumentIdAsString ?? "",
                                    values?.branchId ?? 0
                                );
                            }}
                            copyButtonIsDisabled={
                                isLoading || mode !== "update" || saleOrdersDetails.length === 0
                            }
                            copyButtonText={t("CopyButton")}
                            handleCopyDetails={mode === "update" ? () => copyDetails() : undefined}
                        />

                        <Form onFinish={handleSubmit} layout="vertical">
                            <Tabs
                                defaultActiveKey="1"
                                items={[
                                    {
                                        label: t("saleOrder"),
                                        key: "1",
                                        children: (
                                            <MainFieldsComponent
                                                supplierOptions={supplierOptions}
                                                supplierBranchOptions={supplierBranchOptions}
                                                setSaleOrderDetails={setSaleOrdersDetails}
                                                values={values}
                                                saleOrdersDetails={saleOrdersDetails}
                                                addSaleOrdersDetail={addSaleOrdersDetail}
                                                removeSaleOrdersDetail={
                                                    removeSaleOrdersDetail
                                                }
                                                type={type ?? ""}
                                                mode={mode ?? ""}
                                                setFieldValue={setFieldValue}
                                            />
                                        ),
                                    },
                                ]}
                            />
                            <HandleFormDataForTabSaving
                                tabPath={
                                    mode === "update"
                                        ? `saleOrder/update/${id}/${branchId}`
                                        : `saleOrder/register`
                                }
                                additionalStates={{
                                    saleOrdersDetails: saleOrdersDetails,
                                }}
                            />
                        </Form>
                    </>
                )}
            </Formik>
        </Spin>
    );
}
