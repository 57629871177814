import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LayoutComponent from "../LayoutComponent";
import Clients from "../../Dashboard/Clients";
import CreateClient from "../../Dashboard/Clients/CreateClient";
import ServerListComponent from "../../Dashboard/Servers/ServerListComponent";
import CreateServer from "../../Dashboard/Servers/CreateServer";
import SubscriptionListComponent from "../../Dashboard/Subscriptions/SubscriptionListComponent";
import ClientComponent from "../../Dashboard/Clients/SingleClient/ClientComponent";
import DashboardSettingsComponent from "../../Settings/DashboardSettingsComponent";
import Dashboard from "../../Dashboard/Dashboard";
import SubscriptionPayments from "../../Dashboard/Payments/SubscriptionPayments";
import CreateSubscriptionPayment from "../../Dashboard/Payments/CreateSubscriptionPayment";
import AdminUser from "../../Dashboard/AdminUsers";
import CreateAdminUsers from "../../Dashboard/AdminUsers/CreateAdminUsers";
import ProtectedComponent from "../../Authorization/ProtectedComponent";
import { MenuAdminOptionEnum } from "../../../enums/MenuAdminOptionEnum";

const DasbhoardLayoutRoutes: React.FC = () => (
  <LayoutComponent>
    <Routes>
      <Route path="" element={
        <ProtectedComponent component={<Dashboard />} menuOptionId={MenuAdminOptionEnum.AdminDashboardAccess} />} />
      <Route path="/clients" element={
        <ProtectedComponent component={<Clients />} menuOptionId={MenuAdminOptionEnum.AdminClientAccess} />} />
      <Route path="/client/register" element={
        <ProtectedComponent component={<CreateClient />} menuOptionId={MenuAdminOptionEnum.AdminClientCreate} />} />
      <Route path="/servers" element={
        <ProtectedComponent component={<ServerListComponent />} menuOptionId={MenuAdminOptionEnum.AdminServerAccess} />} />
      <Route path="/servers/register" element={
        <ProtectedComponent component={<CreateServer />} menuOptionId={MenuAdminOptionEnum.AdminServerCreate} />} />
      {/* <Route path="/subscription-register" element={<CreateSubscription />} /> */}
      <Route path="/subscriptions" element={
        <ProtectedComponent component={<SubscriptionListComponent />} menuOptionId={MenuAdminOptionEnum.AdminSubscriptionsAccess} />} />
      <Route path="/company/:id" element={
        <ProtectedComponent component={<ClientComponent />} menuOptionId={MenuAdminOptionEnum.AdminClientCreate} />} />
      <Route path="/settings" element={
        <ProtectedComponent component={<DashboardSettingsComponent />} menuOptionId={MenuAdminOptionEnum.AdminDashboardAccess} />} />
      <Route path="/subscription-payments" element={
        <ProtectedComponent component={<SubscriptionPayments />} menuOptionId={MenuAdminOptionEnum.AdminSubscriptionPaymentsAccess} />} />
      <Route path="/subscription-payment/register" element={
        <ProtectedComponent component={<CreateSubscriptionPayment />} menuOptionId={MenuAdminOptionEnum.AdminSubscriptionPaymentsCreate} />} />
      <Route path="/adminUsers" element={
        <ProtectedComponent component={<AdminUser />} menuOptionId={MenuAdminOptionEnum.AdmminUsersAccess} />} />
      <Route path="/adminUsers/:mode/:id?" element={
        <ProtectedComponent component={<CreateAdminUsers />} menuOptionId={MenuAdminOptionEnum.AdminUsersCreate} />} />
    </Routes>
  </LayoutComponent>
);

export default DasbhoardLayoutRoutes;
