import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductSearchState } from "../../models/clientDashboard/ProductSearch/ProductSearchState";
import { setLoading, setLoadingState } from "./loadingSlice";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { ProductSearchColumnDto } from "../../models/clientDashboard/ProductSearch/ProductSearchColumnDto";


const initialState: ProductSearchState = {
    productSearch: [],
    error: null,
};

const productSearchSlice = createSlice({
    name: "productSearch",
    initialState,
    reducers: {
        getProductSearchStart(state: ProductSearchState) {
            setLoading(true);
            state.error = null;
        },
        getProductSearchSuccess(
            state: ProductSearchState,
            action: PayloadAction<ProductSearchColumnDto[]>
        ) {
            setLoading(false);
            state.productSearch = action.payload;
        },
        getProductSearchFailure(
            state: ProductSearchState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
    }
});

export const getProductSearchAsync = createAsyncThunk(
    "productSearch/getProductSearch",
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(getProductSearchStart());
            const response = await apiService.get<ApiResponse<ProductSearchColumnDto[]>>(
                "api/ProductSearchColumns"
            );
            dispatch(getProductSearchSuccess(response.data.Data));
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getProductSearchFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const {
    getProductSearchStart,
    getProductSearchSuccess,
    getProductSearchFailure,
} = productSearchSlice.actions;

export default productSearchSlice.reducer;