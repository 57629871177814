import { useDispatch, useSelector } from "react-redux";
import { CreateOrUpdateSaleDto } from "../../../../../models/clientDashboard/Sales/SalesInvoice/CreateOrUpdateSalesDto";
import { AppDispatch, RootState } from "../../../../../redux";
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { Button, Col, Row } from "antd";
import FormInput from "../../../../CustomComponents/FormInput";
import FormSelect from "../../../../CustomComponents/FormSelect";
import { DocumentTypeEnum } from "../../../../../enums/DocumentTypeEnum";

interface Props {
    values: CreateOrUpdateSaleDto;
    setFieldValue: (field: string, value: any) => void;
    uploadedFiles: File[];
}

export default function OtherFieldsComponent({ values, uploadedFiles, setFieldValue }: Props) {
    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const user = useSelector((state: RootState) => state.user.loggedInUser);
    const t = useAppTranslation(
        "ClientDashboard.CreateSale.OtherFieldsComponent"
    );
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Row gutter={[8, 8]}>
            <Col xs={12} sm={8}>
            <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
                        <FormSelect
                            name="currencyId"
                            label={t("currencyIdInputLabel")}
                            required={values.documentTypeId === DocumentTypeEnum.goodsExport}
                            style={{ width: "100%" }}
                            value={values.currencyId}
                            options={
                                currencies?.map((currencies) => {
                                    return {
                                        key: currencies.currencyId,
                                        label: currencies.currencyName,
                                        value: currencies.currencyId,
                                    };
                                }) ?? []
                            }
                            disabled={!user?.isMainBranch || values.validated}
                        />
                        <FormInput
                            name="exchangeRate"
                            type="number"
                            style={{ width: "30%" }}
                            required={values.documentTypeId === DocumentTypeEnum.goodsExport}
                            label={t("exchangeRateInputLabel")}
                            value={values.exchangeRate}
                            disabled={values.validated || values.currencyId === undefined}
                        />
                </div>
                <FormInput
                    name="invoiceDate"
                    type="date"
                    required={false}
                    label={t("invoiceDateInputLabel")}
                    value={values.invoiceDate?.split("T")[0] || ""}
                    disabled={values.validated}
                />
                <FormInput
                    name="palleteCount"
                    label={t("palleteCountInputLabel")}
                    required={false}
                    value={values.palleteCount}
                    disabled={values.validated}
                />
            </Col>
            <Col xs={12} sm={8}>
                <FormInput
                    name="fromDate"
                    type="date"
                    required={false}
                    label={t("fromDateInputLabel")}
                    value={values.fromDate?.split("T")[0] || ""}
                    disabled={values.validated}
                />
                <FormInput
                    name="toDate"
                    type="date"
                    required={false}
                    label={t("toDateInputLabel")}
                    value={values.toDate?.split("T")[0] || ""}
                    disabled={values.validated}
                />
            </Col>
            <Col xs={12} sm={8}>
                <FormInput
                    name="customNoX3"
                    type="text"
                    required={values.documentTypeId === DocumentTypeEnum.goodsExport}
                    label={t("customNoX3InputLabel")}
                    value={values.customNoX3}
                    disabled={values.validated}
                />
                <FormInput
                    name="comment"
                    required={false}
                    label={t("commentInputLabel")}
                    value={values.comment}
                    disabled={values.validated}
                />
            </Col>
        </Row>
    );
}
