export default function SaveIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14.1667V2.5"
        stroke="white"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 9.16699L10 14.167L15 9.16699"
        stroke="white"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 17.5H4.16667"
        stroke="white"
        strokeWidth="1.04167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
