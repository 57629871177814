import { Button, Typography } from "antd";
import ClassifierDetailForm from "./ClassifierDetailForm";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { ClassifierDetail } from "../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";
import { useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { setEditingCell } from "../../../../helperMethods/setEditingCell";
import { AgGridReact } from "ag-grid-react";

const { Title, Text, Link } = Typography;

interface Props {
  classifierDetails?: ClassifierDetail[];
  addOrEditClassifierDetail: (
    classifierDetail: ClassifierDetail,
    classifierIndex?: number
  ) => void;
  removeClassifierDetail: (index: number) => void;
}

export default function ClassifierDetailList({
  classifierDetails,
  addOrEditClassifierDetail,
  removeClassifierDetail,
}: Props) {
  const t = useAppTranslation(
    "ClientDashboard.ClassifierMainComponent.ClassifierDetailList"
  );
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const gridRef = useRef<AgGridReact<any> | null>(null);
  
  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const [colDefs, setColDefs] = useState<ColDef<ClassifierDetail>[]>([
    {
      headerName: "No.",
      cellRenderer: (params: any) => {
        const rowIndex = params.node.rowIndex + 1;
        return <Text type="secondary">{rowIndex}</Text>;
      },
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      editable: true,
    },
    {
      headerName: t("tableHeaders.options"),
      cellRenderer: (params: any) => (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => removeClassifierDetail(params.rowIndex)}
            style={{ color: "#007FFF" }}
          ></Button>
      ),
    },
  ]);

  const handleAddNewItemDescription = () => {
    addOrEditClassifierDetail({
      classifierDetailId: 0,
      description: "",
      classifierId: 0,
      isDeleted: false,
    });
    setTimeout(() => {
      setEditingCell(gridRef, "description");
    }, 0);
  };

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const filteredClassifierDetails = classifierDetails?.filter(
    (detail) => detail.isDeleted === false
  );

  return (
    <>
      <Button color="blue" onClick={handleAddNewItemDescription}>
        {t("addClassifierDetail")}
      </Button>

      <AgGridTableComponent
        gridRef={gridRef}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={10}
        isLoading={isLoading}
        rowData={filteredClassifierDetails}
        suppressCellFocus={true}
        stopEditingWhenCellsLoseFocus={true}
        enableCellTextSelection={true}
        style={{ height: "550px" }}
      />
    </>
  );
}
