import { Button, Popconfirm, Select, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  getAccountingConfigurationAsync,
  getAccountingValuationTypeAsync,
  getDocumentTypeAsync,
  getFinancialAccountsAsync,
} from "../../../redux/slices/accountingConfigurationSlice";
import apiService from "../../../extensions/api";
import { EditOutlined, CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import ListHeaderComponent from "../../CustomComponents/ListHeaderComponent";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import { CellKeyDownEvent } from "ag-grid-community";
import { handleNextGridColumnClick } from "../../../helperMethods/handleNextGridColumnClick";
import _ from "lodash";
import { getItemTypesAsync } from "../../../redux/slices/itemTypeSlice";
import { CreateOrUpdateAccountingConfigurationDto } from "../../../models/clientDashboard/AccountinConfigurations/CreateOrUpdateAccountingConfigurationDto";
import { GetAccountingGroupDto } from "../../../models/clientDashboard/AccountingGroup/GetAccountingGroupDto";
import { GetAccountingValuationTypeDto } from "../../../models/AccountingValuationType/GetAccountingValuationTypeDto";
import { getEntityTypesAsync } from "../../../redux/slices/entityTypeSlice";
import { GetFinancialAccountsDto } from "../../../models/clientDashboard/AccountinConfigurations/GetFinancialAccountsDto";
import ChoosePurchaseModal from "../ItemPrices/Prices/ChoosePurchaseModal";
import ChooseAccountingModal from "./ChooseAccountingModal";
import { DocumentTypeState } from "../../../models/clientDashboard/DocumentType/DocumentTypeState";
import { DocumentTypeModel } from "../../../models/clientDashboard/DocumentType/DocumentTypeModel";
import FloatLabel from "../../CustomComponents/FloatLabel";
import { getPaymentMethodTypesAsync } from "../../../redux/slices/paymentMethodTypesSlice";
import FormSelectWithSearch from "../../CustomComponents/FormSelectWithSearch";
import { getPaymentMethodsAsync } from "../../../redux/slices/paymentMethodsSlice";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";

const { Title, Text, Link } = Typography;

const AccountingConfigurations = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const t = useAppTranslation("ClientDashboard.AccountingConfigurations");
  const gridRef = useRef<AgGridReact | null>(null);
  const [accountingConfigurations, setAccountingConfigurations] = useState<
    CreateOrUpdateAccountingConfigurationDto[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState<number | null>(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    string | null
  >(null);
  const dispatch = useDispatch<AppDispatch>();
  const subjectTypes = useSelector(
    (state: RootState) => state.entityType.entityTypes
  );
  const itemTypes = useSelector((state: RootState) => state.itemType.itemTypes);
  // const documentTypes = useSelector((state: RootState) => state.documentType.documentTypes);
  const [documentTypes, setDocumentTypes] = useState<GetAccountingGroupDto[]>(
    []
  );
  const [gridData, setGridData] = useState(document);

  const [rowIndex, setrowIndex] = useState<number | null>(null);

  const paymentMethods = useSelector(
    (state: RootState) => state.paymentMethod.paymentMethods
  );

  const [valuationTypes, setValuationTypes] = useState<
    GetAccountingValuationTypeDto[]
  >([]);
  const getDocumentTypes = async () => {
    const response = await dispatch(getDocumentTypeAsync());
    if (response.type === "DocumentType/getDocumentType/fulfilled") {
      setDocumentTypes(response.payload as GetAccountingGroupDto[]);
    }
  };

  const getValuationTypes = async () => {
    const response = await dispatch(getAccountingValuationTypeAsync());
    if (
      response.type ===
      "AccountingValuationType/getAccountingValuationType/fulfilled"
    ) {
      setValuationTypes(response.payload as GetAccountingValuationTypeDto[]);
    }
  };

  useEffect(() => {
    dispatch(getPaymentMethodsAsync());

    getAccountingConfigurations();
    dispatch(getItemTypesAsync());
    dispatch(getEntityTypesAsync());
    getDocumentTypes();
    getValuationTypes();
  }, []);

  const getAccountingConfigurations = async () => {
    const response = await dispatch(getAccountingConfigurationAsync());
    if (
      response.type ===
      "accountingConfiguration/getAccountingConfiguration/fulfilled"
    ) {
      setAccountingConfigurations(
        response.payload as CreateOrUpdateAccountingConfigurationDto[]
      );
    }
  };
  const handleDocumentTypeChange = (value: any) => {
    setSelectedDocumentType(value);
  };
  const handleAccountSelection = (
    accountId: number,
    rowIndex: number,
    accountDescription: string
  ) => {
    const updatedConfigurations = _.cloneDeep(mutableObject).map(
      (config, index) => {
        if (index === rowIndex) {
          return {
            ...config,
            accountId: accountId,
            accountDescription: accountDescription,
          };
        }
        return config;
      }
    );
    setAccountingConfigurations(updatedConfigurations);
    setIsModalOpen(false);
  };

  const handleAddAccountingConfigurations = () => {
    const newAccountingConfiguration: CreateOrUpdateAccountingConfigurationDto =
      {
        accountingConfigurationId: 0,
        itemTypeId: 0,
        subjectTypeId: 0,
        documentType: selectedDocumentType ?? "",
        valuationTypeId: 0,
        accountId: 0,
        debitCredit: "",
        rowAction: "A",
      };

    const existingEditRow = accountingConfigurations.find(
      (dp) => dp.accountingConfigurationId === 0
    );

    if (existingEditRow) {
      const updatedConfigurations = accountingConfigurations.filter(
        (dp) => dp.accountingConfigurationId !== 0
      );
      setAccountingConfigurations(updatedConfigurations);
      setEditMode(null);
    }

    setAccountingConfigurations([
      ...accountingConfigurations,
      newAccountingConfiguration,
    ]);
    setEditMode(null);
  };

  const handleCheckButtonClick = (params: any) => {
    const data = params.data;
    const updatedAccountingConfigurations = {
      accountingConfigurationId: data.accountingConfigurationId,
      itemTypeId: data.itemTypeId,
      subjectTypeId: data.subjectTypeId,
      documentType: data.documentType,
      valuationTypeId: data.valuationTypeId,
      accountId: data.accountId,
      debitCredit: data.debitCredit,
      paymentMethodId: data.paymentMethodId,
      rowAction: data.rowAction,
    };

    apiService
      .post("/api/AccountingConfiguration", updatedAccountingConfigurations)
      .then(async () => {
        await getAccountingConfigurations();
        setEditMode(null);
        params.api.refreshCells({
          rowNodes: [params.node],
          force: true,
        });
      })
      .catch((error) => {
        getAccountingConfigurations();
        setEditMode(null);
        params.api.refreshCells({
          rowNodes: [params.node],
          force: true,
        });
      });
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };
  const itemTypeOptions = useMemo(() => {
    return itemTypes.map((itemType) => ({
      key: itemType.itemTypeId,
      label: itemType.description,
      value: itemType.itemTypeId,
    }));
  }, [itemTypes]);

  const subjectTypeOptions = useMemo(() => {
    return subjectTypes.map((subjectType) => ({
      key: subjectType.entityTypeId,
      label: subjectType.description,
      value: subjectType.entityTypeId,
    }));
  }, [subjectTypes]);

  const documentTypeOptions = useMemo(() => {
    return documentTypes.map((documentType) => ({
      key: documentType?.shortDescription?.toString(),
      label: documentType.description,
      value: documentType?.shortDescription?.toString(),
    }));
  }, [documentTypes]);

  const valuationTypeOptions = useMemo(() => {
    return valuationTypes.map((valuationType) => ({
      key: valuationType.accountingValuationTypeId,
      label: valuationType.description,
      value: valuationType.accountingValuationTypeId,
    }));
  }, [valuationTypes]);
  const paymentMethodsOptions = useMemo(() => {
    return paymentMethods.map((paymentMethods) => ({
      key: paymentMethods.paymentMethodId,
      label: paymentMethods.description,
      value: paymentMethods.paymentMethodId,
    }));
  }, [paymentMethods]);

  const [colDefs, setColDefs] = useState<any[]>([]);
  useMemo(() => {
    setColDefs([
      {
        field: "accountingConfigurationId",
        suppressHeaderMenuButton: true,
        headerName: "ID",
        width: 80,
        cellRenderer: (params: any) => {
          return <span>{params.data.accountingConfigurationId}</span>;
        },
      },
      {
        field: "itemTypeId",
        suppressHeaderMenuButton: true,
        headerName: t("tableHeaders.itemTypeId"),
        editable: false,
        width: 200,
        cellRenderer: (params: any) => {
          const isEditing = editMode === params.node.rowIndex;
          if (params.data.accountingConfigurationId === 0 || isEditing) {
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.itemTypeId}
                className="import-select-with-search"
                showSearch={true}
                onChange={(value) => {
                  params.data.itemTypeId = value;
                  params.api.refreshCells({
                    rowNodes: [params.node],
                    force: true,
                  });
                }}
                options={itemTypeOptions}
                allowClear={true}
              />
            );
          }
          return itemTypeOptions.find((option) => option.value === params.value)
            ?.label;
        },
      },
      {
        field: "subjectTypeId",
        suppressHeaderMenuButton: true,
        headerName: t("tableHeaders.subjectTypeId"),
        editable: false,
        cellRenderer: (params: any) => {
          const isEditing = editMode === params.node.rowIndex;
          if (params.data.accountingConfigurationId === 0 || isEditing) {
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.subjectTypeId}
                className="import-select-with-search"
                showSearch={true}
                onChange={(value) => {
                  params.data.subjectTypeId = value;
                  params.api.refreshCells({
                    rowNodes: [params.node],
                    force: true,
                  });
                }}
                options={subjectTypeOptions}
                allowClear={true}
              />
            );
          }
          return subjectTypeOptions.find(
            (option) => option.value === params.value
          )?.label;
        },
      },
      {
        field: "valuationTypeId",
        suppressHeaderMenuButton: true,
        headerName: t("tableHeaders.valuationTypeId"),
        editable: false,
        cellRenderer: (params: any) => {
          const isEditing = editMode === params.node.rowIndex;
          if (params.data.accountingConfigurationId === 0 || isEditing) {
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.valuationTypeId}
                className="import-select-with-search"
                showSearch={true}
                onChange={(value) => {
                  params.data.valuationTypeId = value;
                  params.api.refreshCells({
                    rowNodes: [params.node],
                    force: true,
                  });
                }}
                options={valuationTypeOptions}
              />
            );
          }
          return valuationTypeOptions.find(
            (option) => option.value === params.value
          )?.label;
        },
      },

      {
        field: "accountId",
        suppressHeaderMenuButton: true,
        headerName: t("tableHeaders.accountId"),
        editable: false,
        width: 300,
        cellRenderer: (params: any) => {
          const isEditing = editMode === params.node.rowIndex;
          if (params.data.accountingConfigurationId === 0 || isEditing) {
            return (
              <Button
                onClick={() => {
                  setIsModalOpen(true);
                  setrowIndex(params.node.rowIndex);
                }}
              >
                {params?.data?.accountId
                  ? params?.data?.accountDescription
                  : t("Zgjidhni llogarine")}
              </Button>
            );
          } else {
            return params?.data?.accountId +  ' - ' + params?.data?.accountDescription ;
          }
        },
      },

      {
        field: "debitCredit",
        suppressMenu: true,
        headerName: t("tableHeaders.debitCredit"),
        editable: false,
        cellRenderer: (params: any) => {
          const isEditing = editMode === params.node.rowIndex;
          if (params.data.accountingConfigurationId === 0 || isEditing) {
            return (
              <Select
                style={{ width: "100%" }}
                value={params.data.debitCredit}
                className="import-select-with-search"
                showSearch={true}
                onChange={(value) => {
                  params.data.debitCredit = value;
                  params.api.refreshCells({
                    rowNodes: [params.node],
                    force: true,
                  });
                }}
                options={[
                  { key: "D", label: "D", value: "D" },
                  { key: "K", label: "K", value: "K" },
                ]}
              />
            );
          }

          return params.value;
        },
      },
      selectedDocumentType === "SH"
        ? {
            field: "paymentMethodId",
            suppressHeaderMenuButton: true,
            headerName: t("tableHeaders.paymentMethodId"),
            editable: false,
            cellRenderer: (params: any) => {
              const isEditing = editMode === params.node.rowIndex;
              if (params.data.accountingConfigurationId === 0 || isEditing) {
                return (
                  <Select
                    style={{ width: "100%" }}
                    value={params.data.paymentMethodId}
                    className="import-select-with-search"
                    showSearch={true}
                    onChange={(value) => {
                      params.data.paymentMethodId = value;
                      params.api.refreshCells({
                        rowNodes: [params.node],
                        force: true,
                      });
                    }}
                    options={paymentMethodsOptions}
                  />
                );
              }
              return paymentMethodsOptions.find(
                (option) => option.value === params.value
              )?.label;
            },
          }
        : {},
      {
        headerName: "Actions",
        suppressHeaderMenuButton: true,
        width: 100,
        cellRenderer: (params: any) => {
          const isEditing = editMode === params.node.rowIndex;

          if (params.data.accountingConfigurationId === 0 || isEditing) {
            return (
              <Button
                onClick={() => handleCheckButtonClick(params)}
                icon={<CheckOutlined />}
                style={{ border: "none" }}
              />
            );
          } else {
            return (
              <>
                <Button
                  onClick={() => {
                    if (
                      accountingConfigurations.some(
                        (row) => row.accountingConfigurationId === 0
                      )
                    ) {
                      const updatedRowData = accountingConfigurations.filter(
                        (row) => row.accountingConfigurationId !== 0
                      );
                      setAccountingConfigurations(updatedRowData);
                      params.api.setRowData(updatedRowData);
                    }
                    setEditMode(params.node.rowIndex);
                  }}
                  icon={<EditOutlined />}
                  style={{ border: "none" }}
                />
                <Popconfirm
                  title={t("deleteConfirmation")}
                  onConfirm={() => {
                    params.data.rowAction = "D";
                    handleCheckButtonClick(params);
                  }}
                  okText={"Yes"}
                  cancelText={"No"}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ border: "none", color: "red" }}
                  />
                </Popconfirm>
              </>
            );
          }
        },
      },
    ]);
  }, [
    itemTypes,
    subjectTypes,
    documentTypes,
    valuationTypes,
    editMode,
    accountingConfigurations,
    selectedDocumentType,
    paymentMethods,
  ]);

  console.log(paymentMethods);

  const mutableObject = useMemo(
    () =>
      _.cloneDeep(
        accountingConfigurations.filter((accountConfig) => {
          return accountConfig.documentType === selectedDocumentType;
        })
      ),
    [accountingConfigurations, selectedDocumentType]
  );
  console.log(documentTypeOptions);
  return (
    <Spin spinning={isLoading}>
      <ListHeaderComponent
        title={t("titles.accountingConfigurations")}
        createRecord={handleAddAccountingConfigurations}
        createTitle={t("create")}
        menuItemsDisabled={false}
        createAccessEnum={MenuOptionEnum.AccountingConfigurationCreate}
      />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <FloatLabel
            label={t("documenttypes")}
            value={selectedDocumentType}
            required={true}
          >
            <Select
              onChange={(selectedValue) => {
                setSelectedDocumentType(selectedValue as string);
              }}
              options={documentTypeOptions}
              style={{ width: "auto", minWidth: 200 }}
            />
          </FloatLabel>
        </div>
      </div>
      <AgGridTableComponent
        gridRef={gridRef}
        isLoading={isLoading}
        defaultColDef={defaultColDef}
        rowData={mutableObject}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        suppressCellFocus={true}
        stopEditingWhenCellsLoseFocus={true}
        enableCellTextSelection={true}
        singleClickEdit={true}
        onCellKeyDown={(event: CellKeyDownEvent) => {
          handleNextGridColumnClick(event);
        }}
      />
      <ChooseAccountingModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        rowIndex={rowIndex}
        handleAccountSelection={handleAccountSelection}
      />
    </Spin>
  );
};

export default AccountingConfigurations;
