import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { Navigate } from "react-router-dom";
import { MenuOptionEnum } from "../../enums/MenuOptionEnum";

interface ProtectedComponentProps {
    component: React.ReactNode;
    menuOptionId: number;
    fallBackRoute?: string;
  }
  
  const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
    component,
    menuOptionId,
    fallBackRoute,
  }) => {
    // const user = useSelector((state: RootState) => state.user.loggedInUser);
    const menuRoles = useSelector((state: RootState) => state.user.loggedInUserMenuRoles);
    const hasModuleAccess = (): boolean => {
      if (!menuRoles) {
        return false;
      }

      return menuRoles.some(
        (menuRole) =>
          menuRole.menuOptionId === menuOptionId &&  
          menuRole.status ===true
      );
    };
  
    const hasAccess = hasModuleAccess();
  
    if (!hasAccess) {
      return fallBackRoute ? <Navigate to={fallBackRoute} /> : <Navigate to="" />;
    }
  
    return <>{component}</>;
  };
  
  export default ProtectedComponent;