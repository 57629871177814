import { useTranslation } from "react-i18next";
import { MenuDto } from "../../../../models/clientDashboard/Menu/MenuDto";
import { RoleDto } from "../../../../models/clientDashboard/Role/RoleDto";
import { useSelector } from "react-redux";
import { renderToolIcon } from "../../../../helperMethods/renderToolIcon";
import './RenderMenus.css';
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useState } from "react";

const RenderMenus = ({
  menus,
  paddingLeft = 0,
  values,
  handleCheckboxChange,
  handleSelectAll
}: {
  menus: MenuDto[];
  paddingLeft?: number;
  values: RoleDto;
  handleCheckboxChange: (menuOptionId: number, checked: boolean) => void;
  handleSelectAll?: (menuId: number, checked: boolean) => void;
}) => {
  const t = useAppTranslation("ClientDashboard.CreateRole");
  const [expandedMenus, setExpandedMenus] = useState<{ [key: string]: boolean }>({});

  const toggleMenu = (menuId: string) => {
    setExpandedMenus((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  };

  const renderMenu = (menu: MenuDto, paddingLeft: number, isParentExpanded: boolean) => {
    const menuKey = `${menu.menuId}`;
    const isExpanded = expandedMenus[menuKey] || false;
    const hasSubMenus = menu.subMenus && menu.subMenus.length > 0;
    const hasMenuOptions = menu.menuOptions && menu.menuOptions.length > 0;

    const shouldShowMenuOptions = hasMenuOptions && (menu.parentId === null ? isExpanded : isParentExpanded);
    const shouldShowSubMenus = hasSubMenus || shouldShowMenuOptions;

    const handleParentCheckboxClick = (menu: MenuDto, isChecked: boolean) => {
      if(handleSelectAll)
      handleSelectAll(menu.menuId, isChecked);
    };

    return (
      <div key={menu.menuId} style={{ paddingLeft: `${paddingLeft}px`, paddingBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {hasSubMenus && (
            <button
              onClick={() => toggleMenu(menuKey)}
              type="button"
              style={{
                marginRight: '10px',
                cursor: 'pointer',
                background: 'none',
                border: 'none',
                fontSize: '1rem',
              }}
            >
              {isExpanded ? '-' : '+'}
            </button>
          )}
          <label style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => hasSubMenus && toggleMenu(menuKey)}>
            {t(`module.${menu.menuDescription}`)}
          </label>
          {menu.parentId === null && (
         <input 
         type="checkbox"
         checked={menu.menuOptions.every(menuOption => 
           values.details.some(detail => detail.menuOptionId === menuOption.menuOptionId && detail.status)
         )}
         onChange={(e: any) => handleParentCheckboxClick(menu, e.target.checked,)}
       />
          )}
        </div>

        {shouldShowMenuOptions && (
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px' }}>
            {menu.menuOptions.map((menuOption) => (
              <div key={menuOption.menuOptionId} style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
                <input
                  type="checkbox"
                  checked={
                    !!values?.details?.find(
                      (detailRole) =>
                        detailRole.menuOptionId === menuOption.menuOptionId
                    )?.status
                  }
                  disabled={
                    values?.details?.find(
                      (detailRole) =>
                        detailRole.menuOptionId === menuOption.menuOptionId
                    )?.disabled ?? false
                  }
                  onChange={() =>
                    handleCheckboxChange(
                      menuOption.menuOptionId,
                      !values?.details?.find(
                        (detailRole) =>
                          detailRole.menuOptionId === menuOption.menuOptionId
                      )?.status
                    )
                  }
                  id={`moduleDetailRoles.${menuOption.toolDescription}`}
                />
                <label style={{ marginLeft: '10px' }}>
                  {t(`${menuOption.toolDescription}`)}
                </label>
              </div>
            ))}
          </div>
        )}

        {shouldShowSubMenus && isExpanded && menu.subMenus && (
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px' }}>
            {menu.subMenus.map((subMenu) => renderMenu(subMenu, paddingLeft + 20, isExpanded))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {menus.map((menu) => renderMenu(menu, paddingLeft, true))}
    </div>
  );
};

export default RenderMenus;