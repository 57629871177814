import React, { useState } from "react";
import { Menu } from "antd";
import {
  PieChartOutlined,
  PercentageOutlined,
  UsergroupAddOutlined,
  PicCenterOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useAppTranslation from "../../customHooks/useAppTranslation";
import { db } from "../../indexDB/clientSideDatabase";
import SubMenu from "antd/es/menu/SubMenu";
import { icons } from "antd/es/image/PreviewGroup";
import ItemIcon from "../../assets/icons/ItemIcon";
import DashboardddIcon from "../../assets/icons/DashboardddIcon";
import DoganatIcon from "../../assets/icons/DoganatIcon";
import EntitiesIcon from "../../assets/icons/EntitiesIcon";
import DashboardIcon from "../../assets/icons/DashboardIcon";
import Regjistrimet from "../../assets/icons/Regjistrimet";
import PriceIcon from "../../assets/icons/PriceIcon";
import PurchaseIcon from "../../assets/icons/PurchasesIcon";
import InternalMovementsIcon from "../../assets/icons/InternalMovementsIcon";
import UsersIcon from "../../assets/icons/UsersIcon";
import SalesIcon from "../../assets/icons/SalesIcon";
import ConfigurationIcon from "../../assets/icons/ConfigurationIcon";
import FrameIcon from "../../assets/icons/FrameIcons";
import FinancesIcons from "../../assets/icons/FinancesIcon";
import WithPermission from "../Authorization/WithPermission";
import { MenuOptionEnum } from "../../enums/MenuOptionEnum";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

interface MenuItem {
  label: string;
  key: string;
  icon: JSX.Element;
  path?: string;
  children?: MenuItem[];
  menuOptionId: MenuOptionEnum;
}

interface NavigationMenuProps {
  version: string;
  drawerVisible: boolean;
}

const ClientNavigationMenu: React.FC<NavigationMenuProps> = ({ version, drawerVisible }: NavigationMenuProps) => {
  const navigate = useNavigate();

  const t = useAppTranslation("ClientDashboard.NavigationMenu");
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const items: MenuItem[] = [
    {
      label: drawerVisible ? t("home") : "",
      key: "app",
      icon: <DashboardIcon />,
      path: "",
      menuOptionId: 0
    },
    {
      label: drawerVisible ? t("registrations") : "",
      key: "registrations",
      icon: <Regjistrimet />,
      menuOptionId: MenuOptionEnum.RegistrationModuleAccess,
      children: [
        {
          label: t("items"),
          key: "items",
          icon: <FrameIcon />,
          path: "items",
          menuOptionId: MenuOptionEnum.ItemsAccess
        },
        {
          label: t("entities"),
          key: "entities",
          icon: <FrameIcon />,
          path: "entities",
          menuOptionId: MenuOptionEnum.EntitiesAccess
        },
        {
          label: t("brands"),
          key: "brands",
          icon: <FrameIcon />,
          path: "brands",
          menuOptionId: MenuOptionEnum.BrandsAccess
        },
        {
          label: t("customs"),
          key: "customs",
          icon: <FrameIcon />,
          path: "customs",
          menuOptionId: MenuOptionEnum.CustomsAccess
        },
        {
          label: t("classifiers"),
          key: "classifiers",
          icon: <FrameIcon />,
          path: "classifiers",
          menuOptionId: MenuOptionEnum.ClassifiersAccess
        },
        {
          label: t("invoiceWithRemarksReasons"),
          key: "invoiceWithRemarksReasons",
          icon: <FrameIcon />,
          path: "invoiceWithRemarksReasons",
          menuOptionId: MenuOptionEnum.InvoiceWithRemarksReasonsAccess
        },
        {
          label: t("goodsReturnReasons"),
          key: "goodsReturnReasons",
          icon: <FrameIcon />,
          path: "goodsReturnReasons",
          menuOptionId: MenuOptionEnum.GoodsReturnReasonsAccess
        },
        {
          label: t("currencies"),
          key: "currencies",
          icon: <FrameIcon />,
          path: "currencies",
          menuOptionId: MenuOptionEnum.CurrenciesAccess
        },
        {
          label: t("branches"),
          key: "branches",
          icon: <FrameIcon />,
          path: "branches",
          menuOptionId: MenuOptionEnum.BranchesAccess
        },
        {
          label: t("users"),
          key: "users",
          icon: <FrameIcon />,
          path: "users",
          menuOptionId: MenuOptionEnum.UsersAccess
        },
        {
          label: t("cashRegisters"),
          key: "cashRegisters",
          icon: <FrameIcon />,
          path: "cashRegisters",
          menuOptionId: MenuOptionEnum.CashRegisterAccess
        },
        {
          label: t("documentPeriods"),
          key: "documentPeriods",
          icon: <FrameIcon />,
          path: "documentPeriods",
          menuOptionId: MenuOptionEnum.DocumentPeriodAccess
        },
      ],
    },
    {
      label: drawerVisible ? t("priceList") : "",
      key: "priceList",
      icon: <PriceIcon />,
      menuOptionId: MenuOptionEnum.PricesModuleAccess,
      children: [
        {
          label: t("prices"),
          key: "prices",
          icon: <FrameIcon />,
          path: "prices",
          menuOptionId: MenuOptionEnum.PricesAccess
        },
      ],
    },
    {
      label: drawerVisible ? t("purchases") : "",
      key: "purchases",
      icon: <PurchaseIcon />,
      menuOptionId: MenuOptionEnum.PurchasesModuleAccess,
      children: [
        {
          label: t("localPurchases"),
          key: "localPurchases",
          icon: <FrameIcon />,
          path: "localPurchases",
          menuOptionId: MenuOptionEnum.LocalPurchasesAccess
        },
        {
          label: t("importPurchases"),
          key: "importPurchases",
          icon: <FrameIcon />,
          path: "importPurchases",
          menuOptionId: MenuOptionEnum.ImportPurchasesAccess
        },
        {
          label: t("entryItemsOrders"),
          key: "entryItemsOrders",
          icon: <FrameIcon />,
          path: "entryItemsOrders",
          menuOptionId: MenuOptionEnum.EntryOrderAccess
        },
        {
          label: t("automaticEntryItemsOrders"),
          key: "automaticEntryItemsOrders",
          icon: <FrameIcon />,
          path: "automaticEntryItemsOrders",
          menuOptionId: MenuOptionEnum.AutomaticOrderAccess
        },
        {
          label: t("supplierAgreement"),
          key: "supplierAgreement",
          icon: <FrameIcon />,
          path: "supplierAgreements",
          menuOptionId: MenuOptionEnum.SupplierAgreementsAccess
        },
      ],
    },
    {
      label: drawerVisible ? t("internalMovements") : "",
      key: "internalM",
      icon: <InternalMovementsIcon />,
      menuOptionId: MenuOptionEnum.InternalMovementModuleAccess,
      children: [
        {
          label: t("goodsTransfer"),
          key: "goodsTransfer",
          icon: <FrameIcon />,
          path: "goodsTransfer",
          menuOptionId: MenuOptionEnum.GoodsTransferAccess
        },
      ],
    },
    {
      label: drawerVisible ? t("Users") : "",
      key: "Users",
      icon: <UsersIcon />,
      menuOptionId: MenuOptionEnum.UsersModuleAccess,
      children: [
        {
          label: t("Agents"),
          key: "agents",
          icon: <FrameIcon />,
          path: "agents",
          menuOptionId: MenuOptionEnum.AgentAccess
        },
        {
          label: t("roles"),
          key: "roles",
          icon: <FrameIcon />,
          path: "roles",
          menuOptionId: MenuOptionEnum.RoleAccess
        },
      ],
    },
    {
      label: drawerVisible ? t("Sales") : "",
      key: "Sales",
      icon: <SalesIcon />,
      menuOptionId: MenuOptionEnum.SalesModuleAccess,
      children: [
        {
          label: t("sales"),
          key: "sales",
          icon: <FrameIcon />,
          path: "sales",
          menuOptionId: MenuOptionEnum.SaleInvoiceAccess
        },
        {
          label: t("PaymentMethods"),
          key: "PaymentMethods",
          icon: <FrameIcon />,
          path: "PaymentMethods",
          menuOptionId: MenuOptionEnum.PaymentMethodsAccess
        },
        {
          label: t("saleOrder"),
          key: "saleOrder",
          icon: <FrameIcon />,
          path: "saleOrder",
          menuOptionId: MenuOptionEnum.SaleOrderAccess
        },
        {
          label: t("internalSale"),
          key: "internalSale",
          icon: <FrameIcon />,
          path: "internalSale",
          menuOptionId: MenuOptionEnum.InternalSaleAccess
        },
      ],
    },
    {
      label: drawerVisible ? t("Configurations") : "",
      key: "configurationss",
      icon: <ConfigurationIcon />,
      menuOptionId: MenuOptionEnum.ConfigurationsModuleAccess,
      children: [
        {
          label: t("Systemconfigurations"),
          key: "configurations",
          icon: <FrameIcon />,
          path: "configurations",
          menuOptionId: MenuOptionEnum.SystemConfigurationsAccess
        },
        {
          label: t("SearchProducts"),
          key: "searchProductsColumns",
          icon: <FrameIcon />,
          path: "searchProductsColumns",
          menuOptionId: MenuOptionEnum.ProductColumnsAccess
        },
        {
          label: t("AccountingConfiguration"),
          key: "accountingConfiguration",
          icon: <FrameIcon />,
          path: "accountingConfiguration",
          menuOptionId: MenuOptionEnum.AccountingConfigurationAccess
        },
      ],
    },
    {
      label: drawerVisible ? t("Finances") : "",
      key: "finances",
      icon: <FinancesIcons />,
      menuOptionId: MenuOptionEnum.FinancialModuleAccess,
      children: [
        {
          label: t("FinancialAccounts"),
          key: "financialAccounts",
          icon: <FrameIcon />,
          path: "financialAccounts",
          menuOptionId: MenuOptionEnum.FinancialAccountsAccess
        },
        {
          label: t("FinancialInvoices"),
          key: "financialInvoices",
          icon: <FrameIcon />,
          path: "finInvoiceRegistrations",
          menuOptionId: MenuOptionEnum.EntryOrderAccess
        }
      ],
    },
  ];

  const onClick = (item: MenuItem) => {
    if (item.path) {
      db.addTab(item.label, item.path, undefined);
      navigate(item.path);
    }
  };

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys.length > 0 ? [keys[keys.length - 1]] : []);
  };
  const menuRoles = useSelector((state: RootState) => state.user.loggedInUserMenuRoles);

  const renderMenuItems = (items: MenuItem[]) => {
    const hasAccess = (menuOptionId: MenuOptionEnum) => {
      return menuRoles.some(
        (menuRole) => menuRole.menuOptionId === menuOptionId && menuRole.status === true
      ) || menuOptionId === 0; 
    };
  
    return items.map((item) => {
      const submenuKey = item.menuOptionId || `submenu-${Math.random().toString(36).substr(2, 9)}`;
  
      if (!hasAccess(item.menuOptionId)) {
        return null;
      }
  
      if (item.children) {
        return (
          <SubMenu key={submenuKey} icon={item.icon} title={item.label}>
            {renderMenuItems(item.children)}
          </SubMenu>
        );
      }
  
      return (
        <Menu.Item key={submenuKey} icon={item.icon} onClick={() => onClick(item)}>
          {item.label}
        </Menu.Item>
      );
    });
  };

  return (
    <Menu
      className="custom-navigation-menu"
      mode="inline"
      openKeys={openKeys}
      onOpenChange={handleOpenChange}>
      {renderMenuItems(items)}
    </Menu>

  );
};

export default ClientNavigationMenu;
