import { RefObject } from "react";
import { CreateOrUpdateSaleDetailsDto } from "../../../../../models/clientDashboard/Sales/SalesInvoiceDetail/CreateOrUpdateSaleDetailsDto";
import { ColDef } from "ag-grid-community";
import { suppressEnter } from "../../../../../helperMethods/suppressEnter";
import moment from "moment";
import { formatPurchaseColumnValues } from "../../../../../helperMethods/formatPurchaseColumnValues";
import { toast } from "react-toastify";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { DocumentTypeEnum } from "../../../../../enums/DocumentTypeEnum";


export const getSaleDetailColumns = (
    deleteSaleDetail: (index: number) => void,
    t: (text: string) => string,
    searchItemInputRef: RefObject<any>,
    mode: string,
    setSaleDetails: (data: any) => void,
    documentTypeId: number,
) => {
    const getMaxNo = (rowData: CreateOrUpdateSaleDetailsDto[]) => {
        return Math?.max(0, ...rowData?.map((item) => item.no ?? 0));
    };
    const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };



    const parseDate = (dateStr: string) => {
        const [day, month, year] = dateStr.split("-");
        return `${year}-${month}-${day}`;
    };

    const columnDefs: ColDef[] = [
        {
            headerName: t("tableHeaders.no"),
            field: "no",
            editable: true,
            suppressKeyboardEvent: (params: any) => {
                return suppressEnter(params);
            },
            sortable: false,
            filter: false,
            wrapHeaderText: true,
            suppressMovable: true,
            singleClickEdit: true,
            suppressMenu: true,
            valueSetter: (params) => {
                const newValue = parseInt(params.newValue, 10);

                const allData: CreateOrUpdateSaleDetailsDto[] = [];
                params.api.forEachNode((node: any) => allData.push(node.data));

                const isDuplicate = allData.some(
                    (row) => row.no === newValue && row !== params.data
                );

                if (isNaN(newValue) || newValue <= 0 || isDuplicate) {
                    params.data.no = params.oldValue;
                    return false;
                }

                if (
                    mode === "update" &&
                    params.data.entryItemsDetailsId !== 0 &&
                    params.data.entryItemsDetailsId !== undefined &&
                    params.data.entryItemsDetailsId !== null
                ) {
                    params.data.rowAction = "U";
                }
                params.data.no = newValue;
                return true;
            },
            valueGetter: (params: any) => {
                if (params.data.no === undefined || params.data.no === null) {
                    const allData: CreateOrUpdateSaleDetailsDto[] = [];
                    params.api.forEachNode((node: any) => allData.push(node.data));
                    const maxNo = getMaxNo(allData);
                    params.data.no = maxNo + 1;
                }
                return params.data.no;
            },
        },
        {
            headerName: t("tableHeaders.itemId"),
            field: "itemId",
            width: 100,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.itemDescription"),
            field: "itemDescription",
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.barcode"),
            field: "barcode",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            editable: true,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            valueSetter: (params) => {
                const newValue = params.newValue;
                if (
                    mode === "update" &&
                    params.data.entryItemsDetailsId !== 0 &&
                    params.data.entryItemsDetailsId !== undefined &&
                    params.data.entryItemsDetailsId !== null
                ) {
                    params.data.rowAction = "U";
                }
                params.data.barcode = newValue;
                return true;
            },
        },
        {
            headerName: t("tableHeaders.comment"),
            field: "comment",
            editable: true,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.stock"),
            field: "stock",
            editable: false,
            singleClickEdit: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            width: 80,
            suppressMenu: true,
        },
        {
            headerName: t("tableHeaders.quantity"),
            wrapHeaderText: true,
            maxWidth: 200,
            width: 80,
            filter: false,
            field: "quantity",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            editable: true,
            singleClickEdit: true,
            suppressMenu: true,
            onCellValueChanged: (params: any) => {
                const value = params.newValue;
                if (parseInt(value) <= 0) {
                    params.data.quantity = 1;
                    return true;

                } else {
                    params.data.quantity = value;
                    return true;

                }
            },
            valueSetter: (params) => {
                const purchasePrice = params?.data?.purchasePrice;
                const qty = params.newValue;
                const vat = params?.data?.vat;
                const discount = params?.data?.discount;
                const extraDiscount = params?.data?.extraDiscount;

                // if (purchasePrice !== undefined && vat !== undefined) {
                //     let discountedPrice = purchasePrice;

                //     if (discount !== undefined) {
                //         const discountValue = purchasePrice * (discount / 100);
                //         discountedPrice -= discountValue;
                //     }

                //     if (extraDiscount !== undefined) {
                //         const extraDiscountValue = purchasePrice * (extraDiscount / 100);
                //         discountedPrice -= extraDiscountValue;
                //     }

                //     const purchaseSumWithVat = (
                //         discountedPrice *
                //         (1 + vat / 100) *
                //         (qty !== undefined ? qty : 1)
                //     ).toFixed(2);

                // params.data.purchaseSumWithVat = parseFloat(purchaseSumWithVat);
                if (isNaN(qty) || qty <= 0 || qty === undefined || qty === null) {
                    toast.error(t("quantityZero"));
                    params.data.quantity = 1;
                    return true;
                }

                if (
                    mode === "update" &&
                    params.data.entryItemsDetailsId !== 0 &&
                    params.data.entryItemsDetailsId !== undefined &&
                    params.data.entryItemsDetailsId !== null
                ) {
                    params.data.rowAction = "U";
                }
                params.data.quantity = qty;
                setSaleDetails((prevSaleDetails: any) => {
                    const updatedDetails = prevSaleDetails.map((detail: any) =>
                        detail.entryItemsDetailsId === params.data.entryItemsDetailsId
                            ? { ...detail, qty: params.data.qty }
                            : detail
                    );
                    return updatedDetails;
                });
                return true;
            },
        },
        {
            headerName: t("tableHeaders.discount"),
            wrapHeaderText: true,
            suppressMenu: true,
            maxWidth: 80,
            field: "discount",
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            valueSetter: (params) => {
                const previousDiscount = params.oldValue;
                const newValue = params.newValue;
                if (isNaN(newValue) || newValue < 0) {
                    toast.error(t("discountNegative"));
                    params.data.discount = previousDiscount;
                    return false;
                }

                params.data.discount = parseFloat(params?.newValue?.toFixed(2));
                setSaleDetails((prevSaleDetails: any) => {
                    const updatedDetails = prevSaleDetails.map((detail: any) =>
                        detail.entryItemsDetailsId === params.data.entryItemsDetailsId
                            ? { ...detail, discount: params.data.discount }
                            : detail
                    );
                    return updatedDetails;
                });
                return true;
            },
            valueFormatter: formatPurchaseColumnValues,
            editable: true,
            filter: false,
            singleClickEdit: true,
        },
        {
            headerName: t("tableHeaders.extraDiscount"),
            wrapHeaderText: true,
            suppressMenu: true,
            maxWidth: 120,
            suppressKeyboardEvent: (params: any) => {
                return suppressEnter(params);
            },
            field: "extraDiscount",
            valueSetter: (params: any) => {
                const previousextraDiscount = params?.oldValue;
                const newValue = params.newValue;
                if (isNaN(newValue) || newValue < 0) {
                    toast.error(t("extraDiscountNegative"));
                    params.data.extraDiscount = previousextraDiscount;
                    return false;
                }

                params.data.extraDiscount = parseFloat(params?.newValue?.toFixed(2));
                setSaleDetails((prevSaleDetails: any) => {
                    const updatedDetails = prevSaleDetails.map((detail: any) =>
                        detail.entryItemsDetailsId === params.data.entryItemsDetailsId
                            ? { ...detail, extraDiscount: params.data.extraDiscount }
                            : detail
                    );
                    return updatedDetails;
                });
                return true;
            },
            valueFormatter: formatPurchaseColumnValues,
            editable: true,
            filter: false,
            singleClickEdit: true,
        },
        {
            headerName: t("tableHeaders.vAT"),
            field: "vat",
            maxWidth: 80,
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            editable: false,
            filter: false,
            singleClickEdit: true,
            suppressMenu: true,
            valueSetter: (params) => {
                const vat = parseFloat(params.newValue);
                const priceWithoutVat = params.data.purchasePrice;
                const purchasePriceWithVat = priceWithoutVat * (1 + vat / 100);
                params.data.vat = parseFloat(vat?.toFixed(2));
                const purchasePrice = params?.data?.purchasePrice;
                const qty = params?.data?.quantity;

                const discount = params?.data?.discount;
                const extraDiscount = parseFloat(params?.newValue?.toFixed(2));

                if (purchasePrice !== undefined && vat !== undefined) {
                    let discountedPrice = purchasePrice;

                    if (discount !== undefined) {
                        const discountValue = purchasePrice * (discount / 100);
                        discountedPrice -= discountValue;
                    }

                    if (extraDiscount !== undefined) {
                        const extraDiscountValue = purchasePrice * (extraDiscount / 100);
                        discountedPrice -= extraDiscountValue;
                    }

                    const purchaseSumWithVat = (
                        discountedPrice *
                        (1 + vat / 100) *
                        (qty !== undefined ? qty : 1)
                    ).toFixed(2);

                    // params.data.purchaseSumWithVat = parseFloat(purchaseSumWithVat);
                    //   params.data.purchasePriceWithVat = parseFloat(
                    // purchasePriceWithVat.toFixed(2)
                    //   );
                }
                if (
                    mode === "update" &&
                    params.data.entryItemsDetailsId !== 0 &&
                    params.data.entryItemsDetailsId !== undefined &&
                    params.data.entryItemsDetailsId !== null
                ) {
                    params.data.rowAction = "U";
                }
                return true;
            },
        },
        {
            headerName: t("tableHeaders.salesPrice"),
            field: "salesPrice",
            wrapHeaderText: true,
            maxWidth: 120,
            suppressKeyboardEvent: (params) => {
                return suppressEnter(params);
            },
            // wrapHeaderText: true,
            editable: true,
            suppressMenu: true,
            valueParser: (params: any) => parseFloat(params.newValue),
            valueFormatter: formatPurchaseColumnValues,
            valueSetter: (params: any) => {
                const documentTypesId = documentTypeId;
                const purchasePrice = params.data.purchasePrice;

                const salesPrice = parseFloat(params.newValue);

                if (salesPrice === 0 || salesPrice < 0) {
                    toast.error(t("salesPriceZero"));
                    return false;
                }

                params.data.salesPrice = parseFloat(params.newValue);

                setSaleDetails((prevSaleDetails: any) => {
                    const updatedDetails = prevSaleDetails.map((detail: any) =>
                        detail.entryItemsDetailsId === params.data.entryItemsDetailsId
                            ? { ...detail, salesPrice }
                            : detail
                    );
                    return updatedDetails;
                });

                if (!isNaN(params.data.salesPrice) && purchasePrice) {
                    const vatRate = params.data.vat / 100;
                    const priceWithoutVat = params.data.salesPrice / (1 + vatRate);
                    const profit = priceWithoutVat - params.data.purchasePrice;
                    const margin = (profit / params.data.purchasePrice) * 100;
                    params.data.margin = parseFloat(margin?.toFixed(2));

                    if (searchItemInputRef.current) {
                        searchItemInputRef.current.focus();
                    }

                    if (
                        mode === "update" &&
                        params.data.entryItemsDetailsId !== 0 &&
                        params.data.entryItemsDetailsId !== undefined &&
                        params.data.entryItemsDetailsId !== null
                    ) {
                        params.data.rowAction = "U";
                    }


                    params.data.salesPrice = salesPrice;


                    return true;
                }
                return false;
            },
            filter: false,
            singleClickEdit: true,
        },
        {
            headerName: t("tableHeaders.options"),
            suppressMenu: true,
            maxWidth: 100,
            cellRenderer: (params: any) => {
                return (
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteSaleDetail(params.rowIndex)}
                        style={{ color: "#007FFF" }}
                    ></Button>
                );
            },
        },
    ];
    return columnDefs;
};
