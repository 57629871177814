import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { AppDispatch } from '../redux';
import { db } from '../indexDB/clientSideDatabase';


interface Props<T = any> {
  tabPath: string;
  additionalStates?: Partial<T>;
  initialValues?: any;
}
export default function HandleFormDataForTabSaving<T>({
  tabPath,
  additionalStates = {},
  initialValues
}: Props<T>) {
  const { values } = useFormikContext<T>();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const updateOrRemoveTabData = async () => {
      const tab = await db.tabs.get(tabPath);
      if (!tab) return;

      let combinedData = {
        ...(values as object),
        ...(additionalStates as object),
      };

      const isInitialValuesEqualToTabData = initialValues ? isEqual(initialValues, tab.data) : false;

      const isTransitioningFromUndefinedToObject = Object.entries(combinedData).some(
        ([key, value]) => typeof tab.data?.[key] === 'undefined' && typeof value === 'object' && value !== null
      );

      const isDataChanged = 
        // !isInitialValuesEqualToTabData &&
        // !isEqual(combinedData, tab.data) &&
        !isTransitioningFromUndefinedToObject;

      // console.log(isTransitioningFromUndefinedToObject);
      // console.log(isInitialValuesEqualToTabData);
      // console.log(isTransitioningFromUndefinedToObject);
      if (!tab.shouldRemoveTab) {
        await db.tabs.put({
          tabPath,
          data: combinedData,
          tabName: tab.tabName,
          createdAt: tab.createdAt,
          isTouched: isDataChanged,
        });
      } else {
        await db.tabs.delete(tabPath);
      }
    };

    updateOrRemoveTabData();
  }, [values, tabPath, additionalStates, initialValues, dispatch]);

  return null;
}
