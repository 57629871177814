import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { Entity } from "../../../models/clientDashboard/Entity/Entity";
import { useCallback, useEffect, useState } from "react";
import { getEntityTypesAsync } from "../../../redux/slices/entityTypeSlice";
import { getCountriesAsync } from "../../../redux/slices/countrySlice";
import { getCitiesAsync } from "../../../redux/slices/citySlice";
import { Form, Spin, Tabs } from "antd";
import { Formik, FormikErrors } from "formik";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import MainFormCreateEntityComponent from "./CreateEntityComponents/MainFormCreateEntityComponent";
import EntityClassifiersComponent from "./CreateEntityComponents/EntityClassifiersComponent";
import { getClassifiersByTypeAsync } from "../../../redux/slices/classifierSlice";
import { categories } from "./EntityClassifiersMapped";
import { convertClassifierValueToObject } from "../../../helperMethods/convertClassifierValueToObject";
import { ItemsClassifierDto } from "../../../models/clientDashboard/Classifier/ItemClassifierDto";
import { EntityValidationSchema } from "./CreateEntityComponents/EntityValidationSchema";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import useFormData from "../../../customHooks/useFormData";
import { db } from "../../../indexDB/clientSideDatabase";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import EntityBranchesComponent from "./CreateEntityComponents/EntityBranchesComponent";
import { CreateAndUpdateEntityBranchDto } from "../../../models/clientDashboard/EntityBranches/CreateAndUpdateEntityBranchDto";
import { Classifier } from "../../../models/clientDashboard/Classifier/Classifier";
import { SelectOption } from "../../../models/SelectOption";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";
import WithPermission from "../../Authorization/WithPermission";


export default function CreateEntity() {
  const navigate = useNavigate();
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const [entityBranchesDetails, setEntityBranchesDetails] = useState<CreateAndUpdateEntityBranchDto[]>([])
  const dispatch = useDispatch<AppDispatch>();
  const t = useAppTranslation("ClientDashboard.CreateEntity");
  const [selectedCountry, setSelectedCountry] = useState<number>(0);

  const setAdditionalStates = useCallback((data: Entity) => {
    if (data) {
      setSupplierOptions([
        {
          key: data.cityId?.toString(),
          label: data.cityName ?? "",
          value: data.cityId ?? 0,
        },
      ])
    }
  }, []);

  const { initialValues } = useFormData<Entity>(
    "entity/register",
    {
      status: true,
      isCustomer: true,
    } as Entity,
    setAdditionalStates,
  );
  const [classifiersForBranch, setClassifiersForBranch] = useState<Classifier[]>([]);
  const classifiers = useSelector((state: RootState) => state.classifier.classifiers);
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
  const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
    setSupplierOptions(options);
  };



  useEffect(() => {
    dispatch(getEntityTypesAsync());
    dispatch(getCountriesAsync());
    dispatch(getClassifiersByTypeAsync("Entities"));
    dispatch(getCitiesAsync())
  }, [dispatch]);

  const convertCategoryValue = (
    values: Entity,
    categoryKey: keyof Entity,
    classifierDescription: string
  ) => {
    const classifier = classifiers.find(
      (c) => c.description === classifierDescription
    );

    if (!classifier) return;

    const classifierId = classifier.classifierId;

    if (
      typeof values[categoryKey] === 'object' &&
      !Array.isArray(values[categoryKey])
    ) {
      const categoryObject = values[categoryKey] as ItemsClassifierDto;
      if (categoryObject?.classifierDetailId !== 0) {
        categoryObject.classifierDetailDescription = values[categoryKey]?.toString();
        categoryObject.classifierId = classifierId;
        categoryObject.classifierDetailId = classifier.classifierDataTypeId === 5 ? values[categoryKey] : 0;
      } else {
        values[categoryKey] = null;
      }
    } else {
      if (values[categoryKey] === null || values[categoryKey] === undefined) {
        values[categoryKey] = null;
      }
      else {
        if (classifier.classifierDataTypeId === 5) {
          values[categoryKey] = convertClassifierValueToObject(
            classifierId,
            values[categoryKey]?.toString(),
            values[categoryKey]
          ) as ItemsClassifierDto;
        } else {
          values[categoryKey] = convertClassifierValueToObject(
            classifierId,
            values[categoryKey]?.toString(),
            0
          ) as ItemsClassifierDto;
        }
      }
    };
  }

  const onFinish = async (values: Entity) => {

    categories.forEach(({ key, description }) => {
      convertCategoryValue(values, key, description);
    });

    entityBranchesDetails.forEach(entityBranch => {
      if (typeof entityBranch.k55 === "object") {
        entityBranch.k55 = entityBranch?.k55?.value;
      }
      else {
        entityBranch.k55 = entityBranch.k55;
      }
      if (typeof entityBranch.k56 === "object") {
        entityBranch.k56 = entityBranch?.k56?.value;
      }
      else {
        entityBranch.k56 = entityBranch.k56;
      }
      if (typeof entityBranch.k57 === "object") {
        entityBranch.k57 = entityBranch?.k57?.value;
      }
      else {
        entityBranch.k57 = entityBranch.k57;
      }
      if (typeof entityBranch.k58 === "object") {
        entityBranch.k58 = entityBranch?.k58?.value;
      }
      else {
        entityBranch.k58 = entityBranch.k58;
      }
      if (typeof entityBranch.k59 === "object") {
        entityBranch.k59 = entityBranch?.k59?.value;
      }
      else {
        entityBranch.k59 = entityBranch.k59;
      }
    })

    values.entityBranches = entityBranchesDetails;
    await apiService
      .post("/api/Entity/createEntity", values)
      .then(async (response) => {
        toast.success(t("entityCreatedSuccessfully"));
        await db.tabs.delete("entity/register");
        navigate("/entities");
      })
      .catch(() => { })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };
  const validationSchema = EntityValidationSchema();

  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (
      values?: any
    ) => Promise<FormikErrors<Entity>>
  ) => {
    const errors = await validateForm();
    setTouched({
      description: true,
      businessNumber: true,
      entityTypeId: true,
      countryId: true,
      cityId: true,
      email: true,
      address: true,
      k25: true,
      k26: true,
      k27: true,
      k28: true,
      k29: true,
      k30: true,
      k31: true,
      k32: true,
      k33: true,
      k34: true,
      k35: true,
      k36: true,
      k37: true,
      k38: true,
      k39: true,
      k40: true,
      k41: true,
      k42: true,
      k43: true,
      k44: true,
    });
    return errors;
  };
  const handleValidation = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      return {};
    } catch (err: any) {
      const validationErrors: { [key: string]: string } = {};
      err.inner.forEach((error: any) => {
        validationErrors[error.path] = error.message;
        toast.error(error.message);
      });
      return validationErrors;
    }
  };
  const addOrUpdateEntityBranchesDetail = (
    entityBranchesDetail: CreateAndUpdateEntityBranchDto
  ) => {
    const entityBranchExists = entityBranchesDetails.find(
      (entityBranch) => entityBranch.tempId === entityBranchesDetail.tempId
    );

    if (entityBranchExists) {
      const index = entityBranchesDetails.findIndex(
        (entityBranch) => entityBranch.tempId === entityBranchesDetail.tempId
      );
      setEntityBranchesDetails((prevEntityBranchesDetails) => {
        const newEntityBranchesDetails = [...prevEntityBranchesDetails];
        newEntityBranchesDetails[index] = entityBranchesDetail;
        return newEntityBranchesDetails;
      });
    } else {
      setEntityBranchesDetails((prevEntityBranchesDetails) => [
        ...prevEntityBranchesDetails,
        entityBranchesDetail,
      ]);
    }
  };
  const getEntityBranchesDetail = (tempId: string) => {

    let foundEntityBranches = null;
    setEntityBranchesDetails((prevValues) => {
      const entityBranchesExists = prevValues.find((entityBranches) => entityBranches.tempId === tempId);
      if (entityBranchesExists) {
        foundEntityBranches = entityBranchesExists;
      }
      return prevValues;
    });
    return foundEntityBranches;
  };
  const removeEntityBranchesDetail = (index: number) => {
    setEntityBranchesDetails((prevEntityBranchesDetails) => {
      const activeEntityBranchesDetails = prevEntityBranchesDetails.filter(
        (entityBranchesDetails) => entityBranchesDetails.rowAction !== "D"
      );
      const selectedEntityBranchesDetail = activeEntityBranchesDetails[index];
      if (
        selectedEntityBranchesDetail.entityId === 0 ||
        !selectedEntityBranchesDetail.entityId
      ) {
        prevEntityBranchesDetails.splice(index, 1);
      } else {
        selectedEntityBranchesDetail.rowAction = "D";
      }
      return [...prevEntityBranchesDetails];
    });
  };
  return (
    <Spin tip="Loading..." spinning={isLoading}>

      <Formik
        initialValues={initialValues ?? {} as Entity}
        onSubmit={onFinish}
        enableReinitialize
        validate={handleValidation}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, handleSubmit, submitForm, validateForm, setTouched, setFieldValue }) => (
          <Form onFinish={handleSubmit} layout="vertical">
                <FormHeaderOptions
                  title={t("createFormTitle")}
                  handleSubmitForm={submitForm}
                  handleSubmitValidation={async () => {
                    handleSubmitValidationForm(setTouched, validateForm);
                  }}
                  submitButtonText={t("createEntityButton")}
                  submitButtonIsDisabled={isLoading}
                  createAccessEnum={MenuOptionEnum.EntitiesCreate}
                />
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: t("entity"),
                  key: "1",
                  children: <MainFormCreateEntityComponent
                    entity={values}
                    setFieldValue={setFieldValue}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                  />,
                },
                {
                  label: t("classifiers"),
                  key: "2",
                  children:
                    <EntityClassifiersComponent
                      entity={values}
                      setFieldValue={setFieldValue}
                    />,
                },
                {
                  label: t("branches"),
                  key: "3",
                  children: (
                    <EntityBranchesComponent
                      values={values}
                      entityBranchesDetails={entityBranchesDetails}
                      getEntityBranchesDetail={getEntityBranchesDetail}
                      addOrUpdateEntityBranchesDetail={addOrUpdateEntityBranchesDetail}
                      removeEntityBranchesDetail={removeEntityBranchesDetail}
                      setClassifiersForBranch={setClassifiersForBranch}
                      classifiersForBranch={classifiersForBranch}
                      mode={"register"}
                      setFieldValue={setFieldValue}
                    />
                  ),
                },

              ]}
            />
            <HandleFormDataForTabSaving tabPath="entity/register" />
          </Form>
        )}
      </Formik>
    </Spin>
  );
}