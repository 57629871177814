import Dexie, { liveQuery } from 'dexie';
import { tabTable } from './databaseTables/tabTable';
import { t } from 'i18next';
import { CopiedDocumentDetailsTable } from './databaseTables/copiedDocumentDetailsTable';
import { toast } from 'react-toastify';

class clientSideDatabase<T = unknown> extends Dexie {
    tabs!: Dexie.Table<tabTable, string>;
    copiedDocumentDetailsTable!: Dexie.Table<CopiedDocumentDetailsTable,string>;

    constructor() {
      super('ClientSideDatabase');
  
      this.version(3).stores({
        tabs: '&tabPath,tabName,isTouched,data,createdAt',
        copiedDocumentDetails:'id,list'
      });
  
      this.tabs = this.table('tabs');
      this.copiedDocumentDetailsTable = this.table('copiedDocumentDetails');
    }
    async upsertGenericData(data: CopiedDocumentDetailsTable<T>): Promise<void> {
      if (!data.id) {
        throw new Error("Data must have an 'id' property to use as the key.");
      }
      
      await this.copiedDocumentDetailsTable.clear();
      try{
      await this.copiedDocumentDetailsTable.put(data, data.id);
      toast.success(t("toastMessages.copySuccess")); // Translatable success message
    } catch (error) {
      toast.error(t("toastMessages.copyError")); // Translatable error message
      }
    }
    
  
    async getGenericData(): Promise<CopiedDocumentDetailsTable<T>[]|undefined> {
      const data = await this.copiedDocumentDetailsTable.toArray(); // Retrieve all records
      if(data){
        return data as CopiedDocumentDetailsTable<T>[];
      }
      else{
        return undefined
      }
    }
  
    async getGenericDataById(id: string): Promise<CopiedDocumentDetailsTable<T> | undefined> {
      const data = await this.copiedDocumentDetailsTable.get(id);
  
      if (data) {
        // Type assertion to ensure TypeScript understands the type
        return data as CopiedDocumentDetailsTable<T>;
      }
  
      return undefined;
    }
  
    async deleteGenericDataById(id: string): Promise<void> {
      await this.copiedDocumentDetailsTable.delete(id); // Delete record by ID
    }
  
    async clearGenericData(): Promise<void> {
      await this.copiedDocumentDetailsTable.clear(); // Clear all records
    }

    async addTab(tabName: string, tabPath: string, data: any): Promise<void> {
      try {
        const existingTab = await this.tabs.get(tabPath);
        if (!existingTab) {
          await this.tabs.add({ tabName, tabPath, data,isTouched:false,shouldRemoveTab:false, createdAt: Date.now()});
        }
      } catch (error) {
        console.error('Failed to add or update tab:', error);
      }
    }

    async updateTab(oldTabPath: string, newTabPath: string, newTabName: string): Promise<void> {
      try {
        const existingTab = await this.tabs.get(oldTabPath);
        
        if (existingTab) {
          const newTabExists = await this.tabs.get(newTabPath);
    
          if (!newTabExists) {
            await this.tabs.update(oldTabPath, {
              tabName: newTabName,
              tabPath: newTabPath,
              isTouched:false,
              shouldRemoveTab: false,
              createdAt: Date.now()
            });
          } else {
            console.log('A tab with the new path already exists.');
          }
        } else {
          console.log('No tab found with the old path.');
        }
      } catch (error) {
        console.error('Failed to update tab:', error);
      }
    }
    

    async getAllTabs(): Promise<tabTable[]> {
      try {
        return await this.tabs.toArray();
      } catch (error) {
        console.error('Failed to retrieve tabs:', error);
        throw new Error('Failed to retrieve tabs');
      }
    }

    async deleteTab(tabPath: string): Promise<void> {
      try {
        await this.tabs.where('tabPath').equals(tabPath).delete();
      } catch (error) {
        console.error('Failed to delete tab:', error);
        throw new Error('Failed to delete tab');
      }
    }
  
    async clearAllTables(): Promise<void> {
      await this.tabs.clear();
    }

    async updateIsTouched(tabPath: string, isTouched: boolean): Promise<void> {
      try {
        const tab = await this.tabs.get(tabPath);
    
        if (tab) {
          await this.tabs.put({
            ...tab,
            isTouched,
          });
          console.log(`Tab ${tabPath} isTouched updated to ${isTouched}`);
        } else {
          console.warn(`Tab with path ${tabPath} not found.`);
        }
      } catch (error) {
        console.error('Failed to update isTouched:', error);
      }
    }
   
  }
  
  export const db = new clientSideDatabase();