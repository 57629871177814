import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AgentsState } from "../../models/states/AgentsState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { Agents } from "../../models/Users/agents/Agents";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { RootState } from "..";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";

const initialState: AgentsState = {
  agents: [],
  error: null,
};

const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    getAgentsStart(state: AgentsState) {
      setLoading(true);
      state.error = null;
    },
    getAgentsSuccess(
      state:AgentsState,
      action: PayloadAction<Agents[]>
    ) {
      setLoading(false);
      state.agents = action.payload;
    },
    getAgentsFailure(
      state: AgentsState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
    addNewAgentsToState(
      state: AgentsState,
      action: PayloadAction<Agents>
    ) {
      setLoading(false);
      state.agents.push(action.payload);
    },
    updateAgentsInState(
      state: AgentsState,
      action: PayloadAction<Agents>
    ) {
      setLoading(false);
      const updatedAgentsState = action.payload;
      const index = state.agents.findIndex(
        (Agents) =>
            Agents.agentId ===
          updatedAgentsState.agentId
      );
      if (index !== -1) {
        state.agents[index] = updatedAgentsState;
      }
    },
    deleteAgentsFromState(
      state: AgentsState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      const agentId = action.payload;
      state.agents = state.agents.filter(
        (agents) =>
          agents.agentId !== Number(agentId)
      );
    },
  },
});

export const getAgentsWithFilters = createAsyncThunk(
  "Agents/GetAll",
  async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<Agents[]>>(
        "/api/client/Agents/GetAll"
      );
      console.log(response.data.Data)
      dispatch(getAgentsSuccess(response.data.Data));
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(setLoadingState(false));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getAgentsDataById = createAsyncThunk(
  "Agents/getAgentsById",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<Agents>>(
        `/api/client/Agents/getById?agentId=${id}`
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);


export const getAgentsFilterColumnsAsync = createAsyncThunk(
  "Agents/getAgentsFilterColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<ApiResponse<PropertyDto[]>>(
        "api/Filter/agents"
      );
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getAgentsFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteAgentsAsync = createAsyncThunk(
  "Agents/deleteAgents",
  async (agentId: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.delete<ApiResponse<void>>(
        `/api/Agents/${agentId}`
      );
      dispatch(deleteAgentsFromState(agentId));
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const correctAgentsAsync = createAsyncThunk(
  "Agents/correctAgents",
  async (
    data: { agentId: string; generatedId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/Agents/Correct?originalId=${data.agentId}&generatedId=${data.generatedId}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const validateAgentsAsync = createAsyncThunk(
  "GoodsTransfer/validateGoodsTransfer",
  async (agentId: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      await apiService.put<ApiResponse<void>>(
        `/api/Agents/Validate?agentId=${agentId}`
      );
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  getAgentsStart,
  getAgentsSuccess,
  getAgentsFailure,
  addNewAgentsToState,
  updateAgentsInState,
  deleteAgentsFromState,
} = agentsSlice.actions;

export default agentsSlice.reducer;
