import { ItemModel } from "../../../models/clientDashboard/Item/ItemModel";

export   const categories: { key: keyof ItemModel; description: string }[] = [
    { key: "category1", description: "K1" },
    { key: "category2", description: "K2" },
    { key: "category3", description: "K3" },
    { key: "category4", description: "K4" },
    { key: "k5", description: "K5" },
    { key: "k6", description: "K6" },
    { key: "k7", description: "K7" },
    { key: "k8", description: "K8" },
    { key: "k9", description: "K9" },
    { key: "k10", description: "K10" },
    { key: "k11", description: "K11" },
    { key: "k12", description: "K12" },
    { key: "k13", description: "K13" },
    { key: "k14", description: "K14" },
    { key: "k15", description: "K15" },
    { key: "k16", description: "K16" },
    { key: "k17", description: "K17" },
    { key: "k18", description: "K18" },
    { key: "k19", description: "K19" },
    { key: "k20", description: "K20" },
    { key: "k21", description: "K21" },
    { key: "k22", description: "K22" },
    { key: "k23", description: "K23" },
    { key: "k24", description: "K24" },
  ];