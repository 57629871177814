import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FinancialFSNotesLineState } from "../../models/states/FinancialFSNotesLineState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { CreateOrUpdateFinancialFSNotesLineDto } from "../../models/clientDashboard/FinancialAccounts/FinancialFSNotesLine/CreateOrUpdateFinancialFSNotesLineDto";
import { RootState } from "..";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";


const initialState: FinancialFSNotesLineState = {
    financialFSNotesLines: [],
    error: null
};

const FinancialFSNotesLinesSlice = createSlice({
    name: "financialFSNotesLines",
    initialState,
    reducers: {
        getFinancialFSNotesLinesStart(state: FinancialFSNotesLineState) {
            setLoading(true);
            state.error = null;
        },
        getFinancialFSNotesLinesSuccess(
            state: FinancialFSNotesLineState,
            action: PayloadAction<CreateOrUpdateFinancialFSNotesLineDto[]>
        ) {
            setLoading(false);
            state.financialFSNotesLines = action.payload;
        },
        getFinancialFSNotesLinesFailure(
            state: FinancialFSNotesLineState,
            action: PayloadAction<string>
        ) {
            setLoading(false);
            state.error = action.payload;
        },
        addNewFinancialFSNotesLineToState(state: FinancialFSNotesLineState, action: PayloadAction<CreateOrUpdateFinancialFSNotesLineDto>) {
            setLoading(false);
            state.financialFSNotesLines.push(action.payload);
        },
        updateFinancialFSNotesLineInState(state: FinancialFSNotesLineState, action: PayloadAction<CreateOrUpdateFinancialFSNotesLineDto>) {
            setLoading(false);
            const updatedFinancialFSNotesLine = action.payload;
            const index = state.financialFSNotesLines.findIndex(financialFSNotesLine => financialFSNotesLine.financialFSNotesLinesId === updatedFinancialFSNotesLine.financialFSNotesLinesId);
            if (index !== -1) {
                state.financialFSNotesLines[index] = updatedFinancialFSNotesLine;
            }
        },
    }
});

export const getFinancialFSNotesLinesAsync = createAsyncThunk(
    "FinancialFSNotesLines/getFinancialFSNotesLines",
    async (_, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getFinancialFSNotesLinesStart());
            const response = await apiService.get<ApiResponse<CreateOrUpdateFinancialFSNotesLineDto[]>>(
                "api/FinancialAccounts/GetFinancialFSByType"
            );
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getFinancialFSNotesLinesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const getFinancialFSNotesLinesForStateAsync = createAsyncThunk(
    "FinancialFSNotesLines/getFinancialFSNotesLines",
    async (_, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const financialFSNotesLines= state.financialFSNotesLines.financialFSNotesLines;

        if (financialFSNotesLines.length > 0) {
            return financialFSNotesLines;
        }

        try {
            dispatch(getFinancialFSNotesLinesStart());
            const response = await apiService.get<ApiResponse<CreateOrUpdateFinancialFSNotesLineDto[]>>(
                "api/FinancialFSNotesLines/financialFSNotesLines"
            );
            return response.data.Data;
        } catch (error) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            dispatch(getFinancialFSNotesLinesFailure(errorMessage));
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);


export const getFinancialFSNotesLineDataById = createAsyncThunk(
    "FinancialFSNotesLines/getFinancialFSNotesLineDataById",
    async (financialFSNotesLinesId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<CreateOrUpdateFinancialFSNotesLineDto>>(
                `/api/FinancialFSNotesLine/GetFinancialFSNotesLineDataById/${financialFSNotesLinesId}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);

export const getFinancialFSNotesLineDataType = createAsyncThunk(
    "FinancialFSNotesLines/getFinancialFSNotesLineDataByType",
    async (type: string|null, { dispatch, rejectWithValue }) => {
        try {
            // dispatch(setLoadingState(true));
            const response = await apiService.get<ApiResponse<CreateOrUpdateFinancialFSNotesLineDto[]>>(
                `/api/FinancialAccounts/GetFinancialFSByType?type=${type}`
            );
            return response.data.Data;
        } catch (error: any) {
            const errorMessage =
                typeof error === "string" ? error : "An error occurred";
            return rejectWithValue(errorMessage);
        } finally {
            dispatch(setLoadingState(false));
        }
    }
);
export const {
    getFinancialFSNotesLinesStart,
    getFinancialFSNotesLinesSuccess,
    getFinancialFSNotesLinesFailure,
    addNewFinancialFSNotesLineToState,
    updateFinancialFSNotesLineInState,
} = FinancialFSNotesLinesSlice.actions;

export default FinancialFSNotesLinesSlice.reducer;