export enum MenuAdminOptionEnum{
    AdminDashboardAccess = 1,
    AdminClientAccess,
    AdminClientCreate,
    AdminClientExecuteScript,
    AdminClientPrint,
    AdminServerAccess,
    AdminServerCreate,
    AdminServerExecuteScript,
    AdminServerPrint,
    AdminSubscriptionsAccess,
    AdminSubscriptionsCreate,
    AdminSubscriptionsPrint = 13,
    AdminSubscriptionPaymentsAccess,
    AdminSubscriptionPaymentsCreate,
    AdminSubscriptionPaymentsPrint = 17,
    AdmminUsersAccess,
    AdminUsersCreate,
    AdminUsersPrint
}