import { Button, Checkbox, Col, Modal, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { AppDispatch, RootState } from "../../../../redux";
import { useMemo, useRef, useState } from "react";
import { CreateOrUpdateFinancialFSNotesLineDto } from "../../../../models/clientDashboard/FinancialAccounts/FinancialFSNotesLine/CreateOrUpdateFinancialFSNotesLineDto";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import apiService from "../../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../../indexDB/clientSideDatabase";
import { Formik } from "formik";
import FormInput from "../../../CustomComponents/FormInput";
import { ColDef } from "ag-grid-community";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { getFinancialFSNotesLineDataType } from "../../../../redux/slices/financialFSNotesLinesSlice";

const { Title, Text } = Typography;

interface Props {
    isModalVisible: any,
    handleOk: any,
    handleCancel: any,
}

const CreateFinancialFSNotesLines = ({ isModalVisible, handleOk, handleCancel }: Props) => {

    const t = useAppTranslation("ClientDashboard.CreateFinancialFSNotesLines");
    const financialFSNotesLineDetailGridRef = useRef(null);
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const dispatch = useDispatch<AppDispatch>();
    const [financialFSNotesLinesDetails, setFinancialFSNotesLinesDetails] = useState<CreateOrUpdateFinancialFSNotesLineDto[]>([])
    const formikRef = useRef<any>(null);
    const [selectedTypes, setSelectedType] = useState<string>("Lines");
    const gridApi = useRef<any>(null);
    const gridColumnApi = useRef<any>(null);

    const onGridReady = (params: any) => {
        gridApi.current = params.api;
        gridColumnApi.current = params.columnApi;
    };

    const [initialValues, setInitialValues] = useState<CreateOrUpdateFinancialFSNotesLineDto>({ type: "Lines" } as CreateOrUpdateFinancialFSNotesLineDto);

    const handleCloseModal = () => {
        setInitialValues({ type: "Lines" } as CreateOrUpdateFinancialFSNotesLineDto);
        handleCancel();
    };

    const fetchFinancialFSNotesLineData = async (type: any) => {
        try {
            const data = await dispatch(getFinancialFSNotesLineDataType(type)).unwrap();
            setFinancialFSNotesLinesDetails(data);
        } catch (error) {
            toast.error(t("errorFetchingData"));
        }
    };

    useMemo(() => {
        fetchFinancialFSNotesLineData(selectedTypes);
    }, [selectedTypes]);

    const validationSchema = Yup.object({
        code: Yup.string().required(t("codeRequired")),
        description: Yup.string().required(t("descriptionRequired")),
        ordinalNo: Yup.number().required(t("ordinalNoRequired"))
    });

    function generateUniqueId() {
        return 'id-' + Math.random().toString(36).substr(2, 9);
    }

    const addOrUpdateFinancialFSNotesLinesDetail = async (
        financialFSNotesLinesDetail: CreateOrUpdateFinancialFSNotesLineDto
    ) => {

        const existingLineIndex = financialFSNotesLinesDetails.findIndex(
            (line) => line.financialFSNotesLinesId === financialFSNotesLinesDetail.financialFSNotesLinesId
        );

        // if (!financialFSNotesLinesDetail.tempId) {
        //     financialFSNotesLinesDetail.tempId = generateUniqueId();
        // }

        // const financialFSNotesLineExists = financialFSNotesLinesDetails.find(
        //     (financialFSNotesLine) => financialFSNotesLine.tempId === financialFSNotesLinesDetail.tempId
        // );

        const typeString = Array.isArray(financialFSNotesLinesDetail.type)
            ? financialFSNotesLinesDetail.type.join(', ')
            : financialFSNotesLinesDetail.type;


            ///ordinalNo: parseInt(financialFSNotesLinesDetail.ordinalNo, 10)
        const dataToSend = { ...financialFSNotesLinesDetail, type: typeString };

        try {
            if (existingLineIndex > -1) {
                await apiService.put("/api/FinancialAccounts/UpdateFinancialFSNotesLine", dataToSend);
            toast.success(t("financialFSNotesLineUpdatedSuccessfully"));
            setFinancialFSNotesLinesDetails(prevDetails => {
                const newDetails = [...prevDetails];
                newDetails[existingLineIndex] = { ...newDetails[existingLineIndex], ...dataToSend };
                return newDetails;
            });
            } else {
                await apiService
                    .post("/api/FinancialAccounts/InsertFinancialFSNotesLine", financialFSNotesLinesDetail)
                    .then(async (response) => {
                        toast.success(t("financialFSNotesLineCreatedSuccessfully"));

                        setFinancialFSNotesLinesDetails((prevDetails) => [
                            ...prevDetails,
                            {
                                ...financialFSNotesLinesDetail,
                                financialFSNotesLinesId: response.data.financialFSNotesLinesId,
                                type: typeString,
                            },
                        ]);
                        await db.tabs.delete("financialFSNotesLine/register");
                    })
                    .catch(() => {
                        toast.error(t("errorCreatingFinancialFSNotesLine"));
                    })
                    .finally(() => {
                        dispatch(setLoadingState(false));
                    });


                setInitialValues({ type: financialFSNotesLinesDetail.type } as CreateOrUpdateFinancialFSNotesLineDto);
            }
        } catch (error) {
            console.error("Error adding/updating financial FS notes line:", error);
            toast.error(t("unexpectedError"));
        }
    };


    const getFinancialFSNotesLinesDetail = (tempId: number): CreateOrUpdateFinancialFSNotesLineDto | null => {
        let foundFinancialFSNotesLines: CreateOrUpdateFinancialFSNotesLineDto | null = null;
        setFinancialFSNotesLinesDetails((prevValues) => {
            const financialFSNotesLinesExists = prevValues.find(
                (financialFSNotesLines) => financialFSNotesLines.financialFSNotesLinesId === tempId
            );
            if (financialFSNotesLinesExists) {
                foundFinancialFSNotesLines = financialFSNotesLinesExists;
            }
            return prevValues;
        });
        return foundFinancialFSNotesLines;
    };



    const removeFinancialFSNotesLinesDetail = (index: number) => {
        setFinancialFSNotesLinesDetails((prevFinancialFSNotesLinesDetails) => {
            const activeFinancialFSNotesLinesDetails = prevFinancialFSNotesLinesDetails.filter(
                (financialFSNotesLinesDetails) => financialFSNotesLinesDetails.rowAction !== "D"
            );
            const selectedFinancialFSNotesLinesDetail = activeFinancialFSNotesLinesDetails[index];
            if (
                selectedFinancialFSNotesLinesDetail.financialFSNotesLinesId === 0 ||
                !selectedFinancialFSNotesLinesDetail.financialFSNotesLinesId
            ) {
                prevFinancialFSNotesLinesDetails.splice(index, 1);
            } else {
                selectedFinancialFSNotesLinesDetail.rowAction = "D";
            }
            return [...prevFinancialFSNotesLinesDetails];
        });
    };

    const updateFinancialFSNotesLinesDetail = (tempId: number) => {
        const financialFSNotesLinesDetail = getFinancialFSNotesLinesDetail(tempId);
        if (financialFSNotesLinesDetail) {
            setInitialValues({ ...(financialFSNotesLinesDetail as CreateOrUpdateFinancialFSNotesLineDto) });
        } else {
            console.error("Invalid data: financialFSNotesLinesDetail is null or undefined");
        }
    };


    const [columnDefs, setColumnDefs] = useState<ColDef<CreateOrUpdateFinancialFSNotesLineDto>[]>([]);

    useMemo(() => {
        setColumnDefs([
            {
                headerName: t("tableHeaders.code"),
                field: "code",
                sortable: false,
                filter: false,
                suppressMovable: true,
                singleClickEdit: true,
                suppressMenu: true,
                width: 80
            },
            {
                headerName: t("tableHeaders.description"),
                field: "description",
                sortable: false,
                filter: false,
                suppressMovable: true,
                singleClickEdit: true,
                suppressMenu: true,
            },
            {
                headerName: t("tableHeaders.serverDescription"),
                field: "serverDescription",
                sortable: false,
                filter: false,
                suppressMovable: true,
                singleClickEdit: true,
                suppressMenu: true,
            },
            {
                headerName: t("tableHeaders.ordinalNo"),
                type: "number",
                field: "ordinalNo",
                sortable: false,
                filter: false,
                suppressMovable: true,
                singleClickEdit: true,
                suppressMenu: true,
            },
            {
                headerName: t("tableHeaders.type"),
                field: "type",
                sortable: false,
                filter: false,
                maxWidth: 140,
                suppressMovable: true,
                singleClickEdit: true,
                suppressMenu: true,
            },
            {
                headerName: t("tableHeaders.options"),
                suppressMenu: true,
                width: 100,
                cellRenderer: (params: any) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => removeFinancialFSNotesLinesDetail(params.rowIndex)}
                                style={{ color: "#007FFF" }}
                            />
                            <Button
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    console.log(params);
                                    updateFinancialFSNotesLinesDetail(params.data.financialFSNotesLinesId)}}
                                style={{ color: "#4CAF50", marginRight: '8px' }}
                            />
                        </div>
                    );
                }
            }
        ])
    }, []);

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
    };


    const memoizedRowData = useMemo(() => {
        return financialFSNotesLinesDetails.filter((detail) => detail.rowAction !== "D");
    }, [financialFSNotesLinesDetails]);

    return (
        <Modal
            className="modal-financialFSNotesLines"
            title={t("titles.FinancialFSNotesLines")}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCloseModal}
        >
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={initialValues ?? ({} as CreateOrUpdateFinancialFSNotesLineDto)}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    const valuesToSubmit = {
                        ...values,
                        ordinalNo: Number(values.ordinalNo),
                    }
                    addOrUpdateFinancialFSNotesLinesDetail(valuesToSubmit);
                    resetForm();
                }}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    handleSubmit,
                    submitForm,
                    validateForm,
                    setTouched,
                    setFieldValue,
                }) => (

                    <div>
                        <Row gutter={[8, 8]}>
                            <Col xs={12} sm={8}>
                                <FormInput
                                    name="code"
                                    label={t("codeInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("codeInputLabel"),
                                        },
                                    ]}
                                    value={values.code}
                                />
                                <FormInput
                                    name="description"
                                    label={t("descriptionInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("descriptionInputLabel"),
                                        },
                                    ]}
                                    value={values.description}
                                />
                                <FormInput
                                    name="serverDescription"
                                    label={t("serverDescriptionInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("serverDescriptionInputLabel"),
                                        },
                                    ]}
                                    value={values.serverDescription}
                                />
                            </Col>
                            <Col xs={12} sm={8}>
                                <FormInput
                                    name="ordinalNo"
                                    type="number"
                                    label={t("ordinalNoInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("ordinalNoInputLabel"),
                                        },
                                    ]}
                                    value={values.ordinalNo}
                                />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>{t("checkboxLabel")}</label>

                                    <Checkbox value="Lines" onChange={(checked) => {
                                        setSelectedType("Lines")
                                        setFieldValue('type', "Lines");
                                    }} checked={values.type === "Lines"}>{"Lines"}</Checkbox>
                                    <Checkbox value="Notes" onChange={(checked) => {
                                        setSelectedType("Notes")
                                        setFieldValue('type', 'Notes')
                                    }} checked={values.type === "Notes"}>{"Notes"}</Checkbox>

                                </div>
                            </Col>
                            <Col span={24} style={{ textAlign: "right" }}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        validateForm().then(errors => {
                                            if (Object.keys(errors).length) {
                                                setTouched({
                                                    code: true,
                                                    description: true,
                                                    ordinalNo: true,
                                                });
                                            } else {
                                                submitForm();
                                            }
                                        });
                                    }}>
                                    {t("addFinancialFSNotesLineButtonText")}
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={[12, 8]}>
                            <Col xs={24} sm={24}>
                                <AgGridTableComponent
                                    onGridReady={onGridReady}
                                    gridRef={financialFSNotesLineDetailGridRef}
                                    isLoading={isLoading}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    rowData={memoizedRowData}
                                    suppressCellFocus={true}
                                    stopEditingWhenCellsLoseFocus={true}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </Formik>
        </Modal>
    )
}


export default CreateFinancialFSNotesLines;