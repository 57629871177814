import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Checkbox,
  Popconfirm,
} from "antd";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { AppDispatch, RootState } from "../../../../redux";
import FormInput from "../../../CustomComponents/FormInput";
import FormSelect from "../../../CustomComponents/FormSelect";
import FormTextArea from "../../../CustomComponents/FormTextArea";
import { ItemModel } from "../../../../models/clientDashboard/Item/ItemModel";
import { CellKeyDownEvent } from "ag-grid-community";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import { DeleteOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { ItemDescription } from "../../../../models/clientDashboard/ItemDescription/ItemDescription";
import { setEditingCell } from "../../../../helperMethods/setEditingCell";
import { Barcode } from "../../../../models/clientDashboard/Barcode/Barcode";
import { ClassifierDetail } from "../../../../models/clientDashboard/ClassifierDetail/ClassifierDetail";
import { setLoadingState } from "../../../../redux/slices/loadingSlice";
import apiService from "../../../../extensions/api";
import {
  fetchClassifiers,
  getClassifiersByTypeAsync,
} from "../../../../redux/slices/classifierSlice";
import { getLanguagesAsync } from "../../../../redux/slices/languageSlice";
import { PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { Custom } from "../../../../models/clientDashboard/Custom/Custom";
import {
  getCustomsAsync,
  getCustomsSuccess,
} from "../../../../redux/slices/customSlice";
import { getEntitiesBySearch } from "../../../../redux/slices/entitySlice";
import { Entity } from "../../../../models/clientDashboard/Entity/Entity";
import FormSelectWithSearch from "../../../CustomComponents/FormSelectWithSearch";
import { handleNextGridColumnClick } from "../../../../helperMethods/handleNextGridColumnClick";
import { SelectOption } from "../../../../models/SelectOption";
import ItemTypes from "../../ItemTypes";
import { t as t_server } from "i18next";

interface Props {
  item: ItemModel;
  addNewItemDescription: (itemDescription: ItemDescription) => void;
  removeItemDescription: (index: number) => void;
  updateItemDescriptions: (itemDescriptions: ItemDescription[]) => void;
  addNewBarcode: (barcode: Barcode) => void;
  removeBarcode: (index: number) => void;
  updateBarcodes: (barcodes: Barcode[]) => void;
  barcodes: Barcode[];
  itemDescriptions: ItemDescription[];
  formikRef: any;
  setFieldValue: (name: string, value: any) => void;
  supplierOptions: SelectOption[];
  mode: string;
}

const { Title, Text } = Typography;

const MainFormCreateItemComponent = ({
  item,
  addNewItemDescription,
  removeItemDescription,
  updateItemDescriptions,
  addNewBarcode,
  removeBarcode,
  updateBarcodes,
  barcodes,
  itemDescriptions,
  formikRef,
  setFieldValue,
  supplierOptions,
  mode,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const units = useSelector(
    (rootState: RootState) => rootState.unitMeasure.unitMeasures
  );
  const languages = useSelector((state: RootState) => state.language.languages);
  const brands = useSelector((rootState: RootState) => rootState.brand.brands);
  const taxes = useSelector((rootState: RootState) => rootState.tax.taxes);
  const customs = useSelector(
    (rootState: RootState) => rootState.custom.customs
  );
  const itemTypes = useSelector((state: RootState) => state.itemType.itemTypes);
  const itemStatuses = useSelector(
    (rootState: RootState) => rootState.itemStatus.itemStatuses
  );
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );

  const [customObjectToAdd, setCustomObjectToAdd] = useState<{
    description: string;
    value: number;
  }>({
    description: "",
    value: 0,
  });
  const formatDescription = (description: string): string => {
    const match = description.match(/^K(\d+)$/);
    if (match) {
      return `category${match[1]}`;
    }
    return description;
  };

  useEffect(() => {
    dispatch(getLanguagesAsync());
  }, []);

  const suppliers = useSelector(
    (rootState: RootState) => rootState.entity.entities
  );

  const t = useAppTranslation("ClientDashboard.CreateItem.ItemForm");

  const filteredClassifiers = classifiers.filter(
    (classifier) =>
      classifier.description !== "K1" &&
      classifier.description !== "K2" &&
      classifier.description !== "K3" &&
      classifier.description !== "K4" &&
      classifier.status === true
  );

  function getNestedValue(obj: any, path: any) {
    return path
      .split(".")
      .reduce((acc: any, part: any) => acc && acc[part], obj);
  }

  const getClassifierValueByNameForSelect = (name: string, entity: any) => {
    const categoryValue = entity[name];
    if (typeof categoryValue === "number") {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === "object") {
    } else {
      return undefined;
    }
  };

  const getClassifierValueByName = (name: string, item: any) => {
    const categoryValue = item[name];
    if (typeof categoryValue === "number") {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === "object") {
      return categoryValue.classifierDetailId || undefined;
    } else {
      return undefined;
    }
  };

  const groupSize = Math.ceil(filteredClassifiers.length / 3);
  const groups = [
    filteredClassifiers.slice(0, groupSize),
    filteredClassifiers.slice(groupSize, groupSize * 2),
    filteredClassifiers.slice(groupSize * 2),
  ];

  const getClassifierDescriptionValue = (name: string, entity: any) => {
    const descriptionValue = getNestedValue(entity, name);
    switch (name) {
      case "k1Description":
        return entity?.k1Description;
      case "k2Description":
        return entity?.k2Description;
      case "k3Description":
        return entity?.k3Description;
      case "k4Description":
        return entity?.k4Description;
      default:
        return "";
    }
  };

  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const itemDescriptionGridRef = useRef<AgGridReact<any> | null>(null);

  const getFilteredLanguages = (currentIndex: number) => {
    return languages.filter((language) => {
      const itemDescriptionsFromGrid = itemDescriptionGridRef.current?.props
        ?.rowData as ItemDescription[];
      const isLanguageUsed = itemDescriptionsFromGrid?.some((desc, index) => {
        return desc.language === language.code && index !== currentIndex;
      });
      return !isLanguageUsed;
    });
  };
  const initialColDefs = [
    {
      field: "index",
      headerName: "No.",
      width: 80,
      cellRenderer: (params: any) => {
        const rowIndex = params.node.rowIndex + 1;
        return <Text type="secondary">{rowIndex}</Text>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
    },
    {
      field: "language",
      headerName: "Language",
      editable: false,
    },
    {
      field: "options",
      headerName: "Options",
      cellRenderer: (params: any) => {
        return (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => removeItemDescription(params.rowIndex)}
            style={{ color: "#007FFF" }}
          />
        );
      },
      width: 100,
    },
  ];

  useEffect(() => {
    const updatedColDefs = initialColDefs.map((colDef) => {
      if (colDef.field === "language") {
        return {
          ...colDef,
          cellRenderer: (params: any) => {
            const currentIndex = params.node.rowIndex;
            const filteredLanguages = getFilteredLanguages(currentIndex);
            return (
              <Select
                style={{ width: "100%", height: "70%" }}
                value={params.value}
                onChange={(value) => {
                  const updatedValues = [
                    ...(itemDescriptionGridRef.current?.props.rowData || []),
                  ];
                  const itemDescriptionForEdit = updatedValues[currentIndex];
                  if (itemDescriptionForEdit) {
                    itemDescriptionForEdit.language = value;
                  }
                  updateItemDescriptions(updatedValues);
                }}
                options={filteredLanguages.map((language) => ({
                  key: language.code,
                  label: language.description,
                  value: language.code,
                }))}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            );
          },
        };
      }
      return colDef;
    });
    setItemDescriptionColDefs(updatedColDefs);
  }, [languages]);

  const [itemDescriptionsColDefs, setItemDescriptionColDefs] = useState([
    {
      field: "index",
      headerName: "No.",
      width: 80,
      cellRenderer: (params: any) => {
        const rowIndex = params.node.rowIndex + 1;
        return <Text type="secondary">{rowIndex}</Text>;
      },
    },
    {
      field: "description",
      headerName: t("tableHeaders.description"),
      editable: true,
    },
    {
      field: "language",
      headerName: t("tableHeaders.language"),
      editable: false,
      cellRenderer: (params: any) => {
        const currentIndex = params.node.rowIndex;
        const filteredLanguages = getFilteredLanguages(currentIndex);
        return (
          <Select
            style={{ width: "100%", height: "70%" }}
            value={params.value}
            onChange={(value) => {
              const updatedValues = [
                ...(itemDescriptionGridRef.current?.props
                  ?.rowData as ItemDescription[]),
              ];
              const itemDescriptionForEdit = updatedValues[currentIndex];
              if (itemDescriptionForEdit) {
                itemDescriptionForEdit.language = value;
              }
              updateItemDescriptions(updatedValues);
            }}
            options={filteredLanguages.map((language) => ({
              key: language.code,
              label: language.description,
              value: language.code,
            }))}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        );
      },
    },
    {
      field: "options",
      headerName: t("tableHeaders.options"),
      cellRenderer: (params: any) => {
        return (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => removeItemDescription(params.rowIndex)}
            style={{ color: "#007FFF" }}
          ></Button>
        );
      },
      width: 100,
    },
  ]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const barcodeGridRef = useRef<AgGridReact<any> | null>(null);

  const handleAddOrUpdateBarcode = () => {
    let isPrimary = false;
    const barcodes =
      barcodeGridRef.current?.props?.rowData ?? ([] as Barcode[]);
    if (
      barcodes.length === 0 ||
      barcodes.every((barcode) => barcode.primaryBarcode === false)
    ) {
      isPrimary = true;
    }
    addNewBarcode({
      barcodeId: 0,
      barcode: "",
      itemId: 0,
      primaryBarcode: isPrimary,
      isDeleted: false,
      packageQuantity: 1,
    });
    setTimeout(() => {
      setEditingCell(barcodeGridRef, "barcode");
    }, 0);
  };

  const handlePrimaryBarcodeChange = (
    barcodeIndex: number,
    isChecked: boolean
  ) => {
    const currentBarcodes = barcodeGridRef.current?.props?.rowData as Barcode[];
    const updatedBarcodes = currentBarcodes.map((barcode) => ({ ...barcode }));

    if (isChecked) {
      updatedBarcodes.forEach((barcode) => {
        barcode.primaryBarcode = false;
      });

      updatedBarcodes[barcodeIndex].primaryBarcode = true;
    } else {
      updatedBarcodes[barcodeIndex].primaryBarcode = false;

      const hasPrimary = updatedBarcodes.some(
        (barcode) => barcode.primaryBarcode
      );

      if (!hasPrimary) {
        const firstNonDeleted = updatedBarcodes.find(
          (barcode) => !barcode.isDeleted
        );
        if (firstNonDeleted) {
          firstNonDeleted.primaryBarcode = true;
        }
      }
    }
    updateBarcodes(updatedBarcodes);
  };

  const [barcodeColDefs, setBarcodeColDefs] = useState([
    {
      field: "index",
      headerName: "No.",
      width: 80,
      cellRenderer: (params: any) => {
        const rowIndex = params.node.rowIndex + 1;
        return <Text type="secondary">{rowIndex}</Text>;
      },
    },
    { field: "barcode", headerName: t("tableHeaders.barcode"), editable: true },
    {
      field: "primaryBarcode",
      headerName: t("tableHeaders.primaryBarcode"),
      editable: true,
      cellRenderer: (params: any) => (
        <Checkbox
          checked={params.value}
          onChange={(e) =>
            handlePrimaryBarcodeChange(params.node.rowIndex, e.target.checked)
          }
        />
      ),
      singleClickEdit: true,
      suppressMenu: true,
    },
    {
      field: "packageQuantity",
      headerName: t("tableHeaders.packageQuantity"),
      editable: true,
      valueSetter: (params: any) => {
        const packageQuantity = parseFloat(params.newValue);
        params.data.packageQuantity = packageQuantity;
        if (packageQuantity === 0 || packageQuantity < 0) {
          toast.error(t("packageQuantityZero"));

          return false;
        }
        return true;
      },
    },
    {
      field: "options",
      headerName: t("tableHeaders.options"),
      cellRenderer: (params) => (
        <Popconfirm
          title={t("deleteBarcode")}
          onConfirm={() => removeBarcode(params.rowIndex)}
          okText={t("Yes")}
          cancelText={t("No")}
        >
          <Button
            type="text"
            icon={<DeleteOutlined />}
            style={{ color: "#007FFF" }}
          ></Button>
        </Popconfirm>
      ),
      width: 120,
    },
  ]);

  const handleAddCustomFieldChange = (e: any) => {
    setCustomObjectToAdd({
      ...customObjectToAdd,
      [e.target.name]: e.target.value,
    });
  };

  const addNewCustomOption = async () => {
    if (customObjectToAdd.description === "" || customObjectToAdd.value === 0) {
      toast.error("Please fill out the data first");
      return;
    }
    const newCustom: Custom = {
      customId: 0,
      description: customObjectToAdd.description,
      value: customObjectToAdd.value,
    };
    dispatch(setLoadingState(true));

    await apiService
      .post("/api/customs", newCustom)
      .then((response) => {
        dispatch(getCustomsSuccess([]));
        dispatch(getCustomsAsync());
      })
      .catch(() => {})
      .finally(() => {
        setCustomObjectToAdd({
          description: "",
          value: 0,
        });
        dispatch(setLoadingState(false));
      });
  };

  const [barcodeGridApi, setBarcodeGridApi] = useState(null);
  const [barcodeGridColumnApi, setBarcodeGridColumnApi] = useState(null);

  const onBarcodeGridReady = (params: any) => {
    setBarcodeGridApi(params.api);
    setBarcodeGridColumnApi(params.columnApi);
  };

  const fetchSuppliers = async (searchText: string) => {
    const result = await dispatch(
      getEntitiesBySearch({ searchText: searchText, isSupplier: true })
    );
    if (result.payload !== "An error occurred") {
      const payload = result.payload as Entity[];
      const options = payload
        ?.filter(
          (entity) =>
            entity.entityId !== item.primarySupplierID &&
            entity.entityId !== item.secondarySupplierID
        )
        .map((entity) => ({
          key: entity.entityId.toString(),
          label: entity.description,
          value: entity.entityId,
        }));
      return options;
    }
    return [];
  };

  useEffect(() => {}, [supplierOptions]);

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col xs={12} sm={8}>
          <FormInput
            name="description"
            label={t("descriptionInputLabel")}
            required={true}
            value={item.description}
          />
          <FormInput
            name="shortDescription"
            label={t("shortDescriptionInputLabel")}
            rules={[
              {
                message: t("shortDescriptionInputPlaceholder"),
              },
            ]}
            value={item.shortDescription}
          />
          <FormInput
            name="productCode"
            label={t("productCodeInputLabel")}
            rules={[
              {
                message: t("productCodeInputPlaceholder"),
              },
            ]}
            value={item.productCode}
          />
          <FormSelect
            name="itemTypeId"
            label={t("itemTypeIdInputLabel")}
            rules={[
              {
                message: t("itemTypeIdInputPlaceholder"),
              },
            ]}
            required={true}
            value={item.itemTypeId}
            options={
              itemTypes?.map((type) => {
                return {
                  key: type.itemTypeId?.toString(),
                  label:
                    type.serverDescription !== ""
                      ? t_server(type.serverDescription)
                      : type.description,
                  value: type.itemTypeId,
                };
              }) ?? []
            }
          />
          <FormSelect
            name="unitId"
            label={t("unitIdInputLabel")}
            rules={[
              {
                required: true,
                message: t("unitIdInputPlaceholder"),
              },
            ]}
            required={true}
            value={item.unitId}
            options={
              units?.map((unit) => {
                return {
                  key: unit.unitMeasureId?.toString(),
                  label: unit.description,
                  value: unit.unitMeasureId,
                };
              }) ?? []
            }
          />
          <FormSelect
            name="brandId"
            label={t("brandIdInputLabel")}
            rules={[
              {
                message: t("brandIdInputPlaceholder"),
              },
            ]}
            required={true}
            value={item.brandId}
            options={
              brands?.map((brand) => {
                return {
                  key: brand.brandId?.toString(),
                  label: brand.description,
                  value: brand.brandId,
                };
              }) ?? []
            }
          />
          <FormSelect
            name="customId"
            label={t("customIdInputLabel")}
            rules={[
              {
                required: true,
                message: t("customIdInputPlaceholder"),
              },
            ]}
            required={true}
            value={item.customId}
            options={
              customs?.map((custom) => {
                return {
                  key: custom.customId?.toString(),
                  label: custom.description,
                  value: custom.customId,
                };
              }) ?? []
            }
            hasAddOption={true}
            dropdownContent={() => (
              <>
                <Input
                  placeholder="Please enter description"
                  value={customObjectToAdd.description}
                  className="select-input-description"
                  name="description"
                  onChange={handleAddCustomFieldChange}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Input
                  placeholder="Please enter value"
                  value={customObjectToAdd.value}
                  name="value"
                  onChange={handleAddCustomFieldChange}
                  onKeyDown={(e) => e.stopPropagation()}
                  type="number"
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={addNewCustomOption}
                >
                  Add item
                </Button>
              </>
            )}
          />
          <FormSelect
            name="taxId"
            label={t("taxIdInputLabel")}
            rules={[
              {
                required: true,
                message: t("taxIdInputPlaceholder"),
              },
            ]}
            required={true}
            value={item.taxId}
            options={
              taxes?.map((tax) => {
                return {
                  key: tax.taxId?.toString(),
                  label: tax.description,
                  value: tax.taxId,
                };
              }) ?? []
            }
          />
          <FormInput
            name="excise"
            label={t("exciseInputLabel")}
            value={item.excise}
            type="number"
          />
          <FormInput
            name="packaging"
            label={t("packagingInputLabel")}
            rules={[
              {
                message: t("packagingInputPlaceholder"),
              },
            ]}
            value={item.packaging}
            type="number"
          />
        </Col>
        <Col xs={24} sm={16}>
          <Row style={{ gap: "10px" }}>
            <div style={{ width: "49.5%" }}>
              <FormSelect
                name="statusId"
                label={t("statusIdInputLabel")}
                required={true}
                rules={[
                  {
                    required: true,
                    message: t("statusIdInputPlaceholder"),
                  },
                ]}
                value={item.statusId}
                options={
                  itemStatuses.map((status) => {
                    return {
                      key: status.itemStatusId?.toString(),
                      label: status.description,
                      value: status.itemStatusId,
                    };
                  }) ?? []
                }
              />
              <FormSelectWithSearch
                className="selectWithSearchRequired"
                name="primarySupplierID"
                label={t("primarySupplierIdInputLabel")}
                value={item.primarySupplierID}
                fetchOptions={fetchSuppliers}
                fetchInitialOption={() => {
                  return (
                    supplierOptions.find(
                      (option) =>
                        option.key === item.primarySupplierID?.toString()
                    ) || null
                  );
                }}
                rules={[
                  {
                    required: true,
                    message: t("primarySupplierIdInputLabel"),
                  },
                ]}
                placeHolder={t("placeHolder")}
                onChange={(option) => {
                  setFieldValue("primarySupplierID", option.value);
                  setFieldValue("primarySupplierName", option.label);
                }}
              />
              <FormSelectWithSearch
                name="secondarySupplierID"
                label={t("secondarySupplierIdInputLabel")}
                required={false}
                value={item.secondarySupplierID}
                fetchOptions={fetchSuppliers}
                fetchInitialOption={() => {
                  return (
                    supplierOptions?.find(
                      (option) =>
                        option.key === item.secondarySupplierID?.toString()
                    ) || null
                  );
                }}
                placeHolder={t("placeHolder")}
                onChange={(option) => {
                  setFieldValue("secondarySupplierID", option.value);
                  setFieldValue("secondarySupplierName", option.label);
                }}
              />
              <FormInput
                name="supplierCode"
                label={t("supplierCodeInputLabel")}
                value={item.supplierCode}
              />
              <FormInput
                name="pLU"
                label={t("pLUInputLabel")}
                value={item.pLU}
              />
            </div>
            <div style={{ width: "49.5%" }}>
              <FormInput
                name="variationCode"
                label={t("variationCodeInputLabel")}
                value={item.variationCode}
              />
              {classifiers
                ?.filter(
                  (c) =>
                    (c.description === "K1" ||
                      c.description === "K2" ||
                      c.description === "K3" ||
                      c.description === "K4") &&
                    c.status === true
                )
                .map((classifier) => {
                  const name = formatDescription(classifier.description);
                  const value = getClassifierValueByNameForSelect(
                    classifier.description.toLowerCase(),
                    item
                  );
                  const label = getClassifierDescriptionValue(
                    `${classifier?.description?.toLowerCase()}Description`,
                    item
                  );

                  return (
                    <FormSelectWithSearch
                      key={classifier.description}
                      name={name}
                      label={
                        classifier.clientDescription || classifier.description
                      }
                      required={classifier.mandatory}
                      value={item[name]}
                      disabled={item.validated}
                      fetchOptions={(searchText: string) =>
                        fetchClassifiers(
                          searchText,
                          classifier.classifierId ?? ""
                        )
                      }
                      placeHolder={t("placeHolder")}
                      onChange={(selectedValue) => {
                        if (classifier.classifierId === 1) {
                          setFieldValue("category1", selectedValue);
                        } else if (classifier.classifierId === 2) {
                          setFieldValue("category2", selectedValue);
                        } else if (classifier.classifierId === 3) {
                          setFieldValue("category3", selectedValue);
                        } else if (classifier.classifierId === 4) {
                          setFieldValue("category4", selectedValue);
                        }
                      }}
                      fetchInitialOption={() => {
                        if (mode === "update") {
                          if (
                            item[classifier?.description?.toLowerCase()] !==
                            null
                          ) {
                            return {
                              key: item[classifier?.description?.toLowerCase()]
                                ?.classifierDetailId,
                              label: label,
                              value:
                                item[classifier?.description?.toLowerCase()]
                                  ?.classifierDetailId,
                            };
                          } else return null;
                        } else {
                          return {
                            key: item[classifier?.description?.toLowerCase()],
                            label: label,
                            value: item[classifier?.description?.toLowerCase()],
                          };
                        }
                      }}
                    />
                  );
                })}
            </div>
          </Row>
          <Row>
            <Col xs={24} style={{ height: "300px" }}>
              <Button color="blue" onClick={handleAddOrUpdateBarcode}>
                {t("addBarcode")}
              </Button>
              <AgGridTableComponent
                onGridReady={onBarcodeGridReady}
                className="item-barcodes-grid"
                gridRef={barcodeGridRef ?? null}
                isLoading={isLoading}
                defaultColDef={defaultColDef}
                rowData={barcodes}
                columnDefs={barcodeColDefs}
                pagination={true}
                style={{ height: "200px" }}
                paginationPageSize={20}
                suppressCellFocus={true}
                stopEditingWhenCellsLoseFocus={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MainFormCreateItemComponent;
