import { Col, Modal, Row, Spin } from "antd";
import FormInput from "../../CustomComponents/FormInput";
import { GenericDocumentDto } from "../../../models/clientDashboard/GenericDtos/GenericDocumentDto";
import { CreateOrUpdateFinInvoiceRegistration } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistration";
import { CreateOrUpdateFinInvoiceRegistrationAccounting } from "../../../models/clientDashboard/FinInvoiceRegistrations/CreateOrUpdateFinInvoiceRegistrationAccounting";
import { useEffect, useState } from "react";
import FormSelect from "../../CustomComponents/FormSelect";
import { AppDispatch, RootState } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { Classifier } from "../../../models/clientDashboard/Classifier/Classifier";
import FormSelectWithSearch from "../../CustomComponents/FormSelectWithSearch";
import {
  getItemsBySearchValue,
  getItemsBySearchValueForCompositeItem,
} from "../../../redux/slices/itemSlice";
import { ItemDescriptionIdDto } from "../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { getFinancialAccountsAsync } from "../../../redux/slices/accountingConfigurationSlice";
import { GetFinancialAccountsDto } from "../../../models/clientDashboard/AccountinConfigurations/GetFinancialAccountsDto";
import FormSwitch from "../../CustomComponents/FormSwitch";
import { getClassifiersForSubjectBranchesAsync } from "../../../redux/slices/classifierSlice";

interface Props {
  isModalVisible: boolean;
  handleOk: (data: CreateOrUpdateFinInvoiceRegistrationAccounting) => void;
  handleCancel: () => void;
  values: GenericDocumentDto<
    CreateOrUpdateFinInvoiceRegistration,
    CreateOrUpdateFinInvoiceRegistrationAccounting
  >;
  t: (text: string) => string;
  financialAccounts: GetFinancialAccountsDto[];
  invoiceRemainingAmount?: number;
  finInvoiceRegistrationAccounting?: CreateOrUpdateFinInvoiceRegistrationAccounting[];
}

export default function AddAccountingDetail({
  isModalVisible,
  handleOk,
  handleCancel,
  values,
  t,
  financialAccounts,
  invoiceRemainingAmount,
  finInvoiceRegistrationAccounting,
}: Props) {
  const [initialValues, setInitialValues] =
    useState<CreateOrUpdateFinInvoiceRegistrationAccounting>({
      amount: invoiceRemainingAmount ?? 0,
    } as CreateOrUpdateFinInvoiceRegistrationAccounting);

  const branches = useSelector(
    (state: RootState) => state.user.loggedInUserBranches
  );
  const dispatch = useDispatch<AppDispatch>();
  const brands = useSelector((state: RootState) => state.brand.brands);
  const fetchItems = async (searchText: string) => {
    const result = await dispatch(
      getItemsBySearchValue({ searchValue: searchText })
    );
    if (result.payload !== "An error occurred") {
      const payload = result.payload as ItemDescriptionIdDto[];
      const options = payload.map((item) => ({
        key: item?.itemId ?? 0,
        label: item?.description,
        value: item?.itemId ?? 0,
      }));
      return options;
    }
    return [];
  };

  useEffect(() => {
  if (isModalVisible) {
    const positiveAmount = Math.abs(invoiceRemainingAmount ?? 0);
    const dkValue = invoiceRemainingAmount && invoiceRemainingAmount < 0 ? "D" : "K";

    setInitialValues({
      ...initialValues,
      amount: positiveAmount,
      dk: dkValue,
    });
  }
}, [isModalVisible, invoiceRemainingAmount]);

  const [classifiersForAccounting, setClassifiersForAccounting] = useState<
    Classifier[]
  >([]);

  const getClassifiersForModal = async () => {
    const result = await dispatch(
      getClassifiersForSubjectBranchesAsync("FinInvoiceRegistrationAccounting")
    );
    if (
      result.type ===
      "Classifiers/getClassifiersForSubjectBranchesAsync/fulfilled"
    ) {
      const payload = result.payload as Classifier[];
      setClassifiersForAccounting(payload);
    }
  };

  useEffect(() => {
    getClassifiersForModal();
  }, []);
  const getClassifierValue = (type: string) => {
    switch (type) {
      case "K80":
        return initialValues.k80;
      case "K81":
        return initialValues.k81;
      case "K82":
        return initialValues.k82;
      case "K83":
        return initialValues.k83;
      case "K84":
        return initialValues.k84;
      default:
        return null;
    }
  };
  const renderClassifiers = () => {
    const inputComponents =
      classifiersForAccounting !== undefined
        ? classifiersForAccounting
            ?.filter((classifier) => classifier.status === true)
            .map((classifier) => {
              const value = getClassifierValue(classifier.description);
              return (
                <FormSelect
                  required={classifier.mandatory}
                  key={classifier.description}
                  name={"accounting." + classifier.description.toLowerCase()}
                  label={classifier.clientDescription || classifier.description}
                  options={classifier.classifierDetails?.map((detail) => {
                    return {
                      key: detail.classifierDetailId?.toString(),
                      label: detail.description,
                      value: detail.classifierDetailId ?? 0,
                    };
                  })}
                  handleCustomChange={(selectedValue) => {
                    if (classifier.description === "K80") {
                      setInitialValues((prevValues) => {
                        return { ...prevValues, k80: selectedValue as number };
                      });
                    } else if (classifier.description === "K81") {
                      setInitialValues((prevValues) => {
                        return { ...prevValues, k81: selectedValue as number };
                      });
                    } else if (classifier.description === "K82") {
                      setInitialValues((prevValues) => {
                        return { ...prevValues, k82: selectedValue as number };
                      });
                    } else if (classifier.description === "K83") {
                      setInitialValues((prevValues) => {
                        return { ...prevValues, k83: selectedValue as number };
                      });
                    } else if (classifier.description === "K84") {
                      setInitialValues((prevValues) => {
                        return { ...prevValues, k84: selectedValue as number };
                      });
                    }
                  }}
                  value={value}
                />
              );
            })
        : [];
    return inputComponents;
  };

  return (
    <Modal
      open={isModalVisible}
      onCancel={() => {
        handleCancel();
        setInitialValues({} as CreateOrUpdateFinInvoiceRegistrationAccounting);
      }}
      onOk={() => {
        handleOk(initialValues);
        setInitialValues({} as CreateOrUpdateFinInvoiceRegistrationAccounting);
      }}
      className="financial-accounting-modal"
    >
      <Row gutter={[12, 8]} style={{ width: "700px", padding: "15px" }}>
        <Col xs={12} sm={8}>
          <FormInput
            name="accounting.description"
            type="text"
            required={true}
            label={t("descriptionInputLabel")}
            value={initialValues.description}
            onChange={(e) =>
              setInitialValues((prevValues) => {
                return {
                  ...prevValues,
                  description: e.target.value,
                };
              })
            }
            style={{ width: "100%" }}
          />
          <FormSelect
            name="accounting.accountId"
            label={t("accountIdInputLabel")}
            style={{ width: "100%" }}
            value={initialValues.accountId}
            required={true}
            options={
              financialAccounts
                ?.filter(
                  (account) =>{
                    const exists  = finInvoiceRegistrationAccounting?.some(
                      (finAccount) =>
                        finAccount.accountId === account.financialAccountId
                    );
                    return !exists
                  })
                ?.map((account) => ({
                  key: account.financialAccountId ?? 0,
                  label: `${account.financialAccountId ?? 0}-${
                    account.description ?? ""
                  }`,
                  value: account.financialAccountId ?? 0,
                })) ?? []
            }
            handleCustomChange={(value) => {
              setInitialValues((prevValues) => {
                return {
                  ...prevValues,
                  accountId: Number(value),
                  accountDescription:
                    financialAccounts?.find(
                      (account) => account.financialAccountId === Number(value)
                    )?.description ?? "",
                };
              });
            }}
          />
          <FormInput
            name="accounting.amount"
            type="number"
            required={true}
            label={t("amountInputLabel")}
            value={initialValues.amount}
            onChange={(e) =>
              setInitialValues((prevValues) => {
                return {
                  ...prevValues,
                  amount: Number(e.target.value),
                };
              })
            }
            style={{ width: "100%" }}
          />
          <FormSelect
            name="accounting.dk"
            label={t("dkInputLabel")}
            style={{ width: "100%" }}
            value={initialValues.dk}
            required={true}
            options={[
              {
                key: "D",
                label: "D",
                value: "D",
              },
              {
                key: "K",
                label: "K",
                value: "K",
              },
            ]}
            handleCustomChange={(value) => {
              setInitialValues((prevValues) => {
                return { ...prevValues, dk: value };
              });
            }}
          />
          <FormSelect
            name="accounting.branchId"
            label={t("branchIdInputLabel")}
            style={{ width: "100%" }}
            value={initialValues.branchId}
            required={true}
            options={
              branches?.map((branch) => {
                return {
                  key: branch.branchId ?? 0,
                  label: branch.description,
                  value: branch.branchId ?? 0,
                };
              }) ?? []
            }
            handleCustomChange={(value) => {
              setInitialValues((prevValues) => {
                return { ...prevValues, branchId: Number(value) };
              });
            }}
          />
        </Col>
        <Col xs={12} sm={8}>
          {renderClassifiers()}
        </Col>
        <Col xs={12} sm={8}>
          <FormSelect
            name="accounting.brandId"
            label={t("brandIdInputLabel")}
            style={{ width: "100%" }}
            value={initialValues.brandId}
            required={true}
            options={
              brands?.map((brand) => {
                return {
                  key: brand.brandId ?? 0,
                  label: brand.description,
                  value: brand.brandId ?? 0,
                };
              }) ?? []
            }
            handleCustomChange={(value) => {
              setInitialValues((prevValues) => {
                return { ...prevValues, brandId: Number(value) };
              });
            }}
          />
          <FormSelectWithSearch
            name="accounting.itemId"
            label={t("itemIdInputLabel")}
            required={true}
            value={initialValues.itemId}
            fetchOptions={fetchItems}
            fetchInitialOption={() => {
              return {
                key: initialValues.itemId ?? 0,
                label: initialValues.itemDescription ?? "",
                value: initialValues.itemId ?? 0,
              };
            }}
            placeHolder={t("placeHolder")}
            onChange={(option) => {
              setInitialValues((prevValues) => {
                return {
                  ...prevValues,
                  itemId: Number(option.value),
                };
              });
              setInitialValues((prevValues) => {
                return { ...prevValues, itemDescription: option?.label };
              });
            }}
          />
          <FormSwitch
            name="accounting.undiscountable"
            label={t("undiscountableInputLabel")}
            rules={[
              {
                required: true,
                message: t("undiscountableInputPlaceholder"),
              },
            ]}
            onChange={(value) => {
              setInitialValues((prevValues) => {
                return { ...prevValues, undiscountable: value };
              });
            }}
            value={initialValues.undiscountable}
          />
        </Col>
      </Row>
    </Modal>
  );
}
