import { Col, Row } from "antd";
import { CreateOrUpdateSaleDto } from "../../../../../models/clientDashboard/Sales/SalesInvoice/CreateOrUpdateSalesDto";
import useAppTranslation from "../../../../../customHooks/useAppTranslation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux";
import { Classifier } from "../../../../../models/clientDashboard/Classifier/Classifier";
import FormInput from "../../../../CustomComponents/FormInput";
import FormSelect from "../../../../CustomComponents/FormSelect";
import FormSelectWithSearch from "../../../../CustomComponents/FormSelectWithSearch";
import { fetchClassifiers } from "../../../../../redux/slices/classifierSlice";

interface Props {
  values: CreateOrUpdateSaleDto;
  setFieldValue: (name: string, value: any) => void;
}
export default function SaleClassifiersComponent({ values, setFieldValue }: Props) {

  const t = useAppTranslation("ClientDashboard.CreateLocalPurchase.LocalPurchaseClassifiersComponent");
  const classifiers = useSelector(
    (state: RootState) => state.classifier.classifiers
  );
  const filteredClassifiers = classifiers.filter(
    (classifier) =>
      classifier.description !== "K1" &&
      classifier.description !== "K2" &&
      classifier.description !== "K3" &&
      classifier.description !== "K4" &&
      classifier.status === true
  );

  function getNestedValue(obj: any, path: any) {
    return path.split('.').reduce((acc: any, part: any) => acc && acc[part], obj);
  }

  const groupSize = Math.ceil(filteredClassifiers.length / 3);
  const groups = [
    filteredClassifiers.slice(0, groupSize),
    filteredClassifiers.slice(groupSize, groupSize * 2),
    filteredClassifiers.slice(groupSize * 2)
  ];

  const getClassifierValueByNameForSelect = (name: string, item: any) => {
    const categoryValue = item[name];
    if (typeof categoryValue === 'number') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue.classifierDetailId || undefined;
    } else {
      return undefined;
    }
  };
  const getClassifierValueByNameForInput = (name: string, item: any) => {
    const categoryValue = item[name];
    if (typeof categoryValue === 'number' || typeof categoryValue === 'string') {
      return categoryValue;
    } else if (categoryValue && typeof categoryValue === 'object') {
      return categoryValue.classifierDetailDescription || undefined;
    } else {
      return undefined;
    }
  };

  const getClassifierDescriptionValue = (name: string, item: any) => {
    const descriptionValue = getNestedValue(item, name);
    switch (name) {
      case "k60":
        return item?.k60Description;
      case "k61":
        return item?.k61Description;
      case "k62":
        return item?.k62Description;
      case "k63":
        return item?.k63Description;
      case "k64":
        return item?.k64Description;
      default:
        return "";
    }
  };

  const renderGroup = (group: Classifier[]) => {
    const inputComponents = group
      .filter(
        (classifier) =>
          classifier.description !== "K1" &&
          classifier.description !== "K2" &&
          classifier.description !== "K3" &&
          classifier.description !== "K4" &&
          classifier.status === true
      )
      .map((classifier) => {
        switch (classifier.classifierTypeDescription.toLowerCase()) {
          case "select":
            const value = getClassifierValueByNameForSelect(classifier?.description?.toLowerCase(), values)
            const label = getClassifierDescriptionValue(classifier?.description?.toLowerCase() ?? "", values);
            return (
              <FormSelectWithSearch
                key={classifier.description}
                name={classifier?.description?.toLowerCase()}
                label={classifier.clientDescription || classifier.description}
                required={classifier.mandatory}
                value={values[classifier?.description?.toLowerCase()]}
                disabled={values.validated}
                fetchOptions={(searchText: string) => fetchClassifiers(searchText, classifier.classifierId ?? "")}
                fetchInitialOption={() => {
                  return {
                    key: value?.toString(),
                    label: label,
                    value: value?.toString(),
                  };
                }}
                placeHolder={t("placeHolder")}
                onChange={(option) => {
                  setFieldValue(classifier?.description?.toLowerCase(), option.value);
                }}
              />
            );
          default:
            return null;
        }
      });

    return inputComponents;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={8}>{renderGroup(groups[0])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[1])}</Col>
      <Col xs={24} sm={8}>{renderGroup(groups[2])}</Col>
    </Row>
  );
}