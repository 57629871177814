export default function SalesIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className="icon-for-sidebar"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.1875 17.4375C6.93886 17.4375 6.7004 17.3387 6.52459 17.1629C6.34877 16.9871 6.25 16.7486 6.25 16.5C6.25 16.2514 6.34877 16.0129 6.52459 15.8371C6.7004 15.6613 6.93886 15.5625 7.1875 15.5625C7.43614 15.5625 7.6746 15.6613 7.85041 15.8371C8.02623 16.0129 8.125 16.2514 8.125 16.5C8.125 16.7486 8.02623 16.9871 7.85041 17.1629C7.6746 17.3387 7.43614 17.4375 7.1875 17.4375ZM13.4375 17.4375C13.1889 17.4375 12.9504 17.3387 12.7746 17.1629C12.5988 16.9871 12.5 16.7486 12.5 16.5C12.5 16.2514 12.5988 16.0129 12.7746 15.8371C12.9504 15.6613 13.1889 15.5625 13.4375 15.5625C13.6861 15.5625 13.9246 15.6613 14.1004 15.8371C14.2762 16.0129 14.375 16.2514 14.375 16.5C14.375 16.7486 14.2762 16.9871 14.1004 17.1629C13.9246 17.3387 13.6861 17.4375 13.4375 17.4375ZM0.625 1.8125C0.45924 1.8125 0.300268 1.74665 0.183058 1.62944C0.065848 1.51223 0 1.35326 0 1.1875C0 1.02174 0.065848 0.862768 0.183058 0.745558C0.300268 0.628348 0.45924 0.5625 0.625 0.5625H3.75C3.89443 0.562587 4.03438 0.612695 4.14604 0.704304C4.2577 0.795913 4.33419 0.923369 4.3625 1.065L5.0125 4.3125H16.875C16.9682 4.31247 17.0603 4.3333 17.1444 4.37347C17.2286 4.41363 17.3027 4.47211 17.3613 4.54462C17.4199 4.61713 17.4615 4.70183 17.4831 4.79252C17.5048 4.88322 17.5058 4.9776 17.4862 5.06875L15.6112 13.8187C15.5812 13.9586 15.5042 14.0838 15.3929 14.1737C15.2817 14.2635 15.143 14.3125 15 14.3125H6.25C6.10557 14.3124 5.96562 14.2623 5.85396 14.1707C5.7423 14.0791 5.66581 13.9516 5.6375 13.81L3.2375 1.8125H0.625ZM6.7625 13.0625H14.495L16.1025 5.5625H5.2625L6.7625 13.0625Z"
        fill="#5E5E5E"
      />
    </svg>
  );
}
