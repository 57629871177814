import dayjs from "dayjs";

export function formatDateAsDDMMYYYY(date) {
  return dayjs(date).format("DD/MM/YYYY");
}


export function formatDateTimeAsDDMMYYYY(date) {
  return dayjs(date).format("DD/MM/YYYY - hh:mm");
}
