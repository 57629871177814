import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useFormData from "../../../customHooks/useFormData";
import { CreateOrUpdateSupplierAgreementDto } from "../../../models/clientDashboard/SupplierAgreement/CreateOrUpdateSupplierAgreementDto";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useNavigate, useParams } from "react-router-dom";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import { toast } from "react-toastify";
import * as Yup from "yup";
import apiService from "../../../extensions/api";
import { DeleteOutlined } from "@ant-design/icons";
import { db } from "../../../indexDB/clientSideDatabase";
import {
  getSupplierAgreementAsync,
  getSupplierAgreementsDataById,
  getSupplierAgreementSuccess,
} from "../../../redux/slices/supplierAgreementSlice";
import { Col, Form, Row, Spin, Switch, Input, AutoComplete, Button } from "antd";
import { Field, Formik } from "formik";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import FormInput from "../../CustomComponents/FormInput";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import FormSelect from "../../CustomComponents/FormSelect";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { v4 as uuidv4 } from "uuid";
import { getEntitiesBySearch, getEntitiesByTypeAsync } from "../../../redux/slices/entitySlice";
import FormSwitch from "../../CustomComponents/FormSwitch";
import {
  getItemDataById,
  getItemsBySearchValue,
} from "../../../redux/slices/itemSlice";
import { debounce } from "lodash";
import { ItemDescriptionIdDto } from "../../../models/clientDashboard/Item/ItemDescriptionIdDto";
import { useTranslation } from "react-i18next";
import { GetItemDetailsDto } from "../../../models/clientDashboard/Item/GetItemDetailsDto";
import { SupplierAgreementDetails } from "../../../models/clientDashboard/SupplierAgreement/SupplierAgreementDetails";
import { handleNextGridColumnClick } from "../../../helperMethods/handleNextGridColumnClick";
import { CellKeyDownEvent, ColDef } from "ag-grid-community";
import userEvent from "@testing-library/user-event";
import SupplierAgreements from ".";
import moment from "moment";
import AgGridTableComponent from "../../CustomComponents/AgGridTableComponent";
import FormSelectWithSearch from "../../CustomComponents/FormSelectWithSearch";
import { SelectOption } from "../../../models/SelectOption";
import { Entity } from "../../../models/clientDashboard/Entity/Entity";
import { MenuOptionEnum } from "../../../enums/MenuOptionEnum";

const { Search } = Input;
const CreateSupplierAgreements = () => {
  const loadScreen = useSelector((state: RootState) => state.loading.isLoading);
  const formikRef = useRef<any>(null);
  const { mode, id } = useParams<{ mode: string; id?: string }>();
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
  const [supplierAgreementsDetails, setSupplierAgreementsDetails] = useState<
    SupplierAgreementDetails[]
  >([]);
  const addInitialOptionsOfSupplier = (options: SelectOption[]) => {
    setSupplierOptions(options);
  };
  const setAdditionalStates = useCallback(
    (data: CreateOrUpdateSupplierAgreementDto | null) => {
      if (data !== null) {
        if (data.details) {
          setSupplierAgreementsDetails(data.details);
        }
      }
    },
    []
  );
  // const updatedDetails = SupplierAgreements.details.map((detail:any) => {
  //   let expirationDateTemp = null;

  //   if (detail.expirationDate !== null) {
  //     const formattedDate = moment(detail.expirationDate).format(
  //       "YYYY-MM-DD"
  //     );
  //     expirationDateTemp = formattedDate;
  //   }

  //   return {
  //     ...detail,
  //     expirationDate: expirationDateTemp,
  //   };
  // });

  const { initialValues, setInitialValues } =
    useFormData<CreateOrUpdateSupplierAgreementDto>(
      mode === "update"
        ? `supplierAgreements/${id}`
        : "supplierAgreements/register",
      {
        generatedId: mode !== "update" ? uuidv4() : "",
        discount: 0,
        bonus: 0,
        fixedBalance: 0,
      } as CreateOrUpdateSupplierAgreementDto,
      setAdditionalStates,
      {
        fetchData: async () => {
          if (id && mode === "update") {
            const response = await dispatch(
              getSupplierAgreementsDataById({
                supplierAgreementId: id,
              })
            );
            const data = response.payload as CreateOrUpdateSupplierAgreementDto;

            if (
              response.type ===
              "SupplierAgreements/getSupplierAgreementsDataById/fulfilled"
            ) {
              const supplierAgreements =
                response.payload as CreateOrUpdateSupplierAgreementDto;

              setInitialValues(supplierAgreements);
              setSupplierAgreementsDetails(supplierAgreements.details);
              setSupplierOptions([
                {
                  key: supplierAgreements.supplierId.toString(),
                  label: supplierAgreements.supplierDescription ?? "",
                  value: supplierAgreements.supplierId,
                },
              ]);
            }
            return data;
          }
          return {
            generatedId: mode !== "update" ? uuidv4() : "",
          } as CreateOrUpdateSupplierAgreementDto;
        },
      }
    );

  const searchInputRef = useRef<any | null>(null);
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const t = useAppTranslation("ClientDashboard.CreateSupplierAgreements");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.isLoading
  );
  const suppliers = useSelector((state: RootState) => state.entity.entities);
  // const [supplierAgreementDetails, setSupplierAgreementDetails] = useState<
  //   SupplierAgreementDetails[]
  // >([]);
  // State for search term
  const [searchTerm, setSearchTerm] = useState("");

  const onFinish = async (values: CreateOrUpdateSupplierAgreementDto) => {
    dispatch(setLoadingState(true));
    values.details = supplierAgreementsDetails;
    const apiEndpoint =
      mode === "update"
        ? `/api/SupplierAgreement/update`
        : "/api/SupplierAgreement/create";

    await apiService
      .post(apiEndpoint, values)
      .then(async (response) => {
        toast.success(t("createdSuccessfully"));
        // formikRef.current.setFieldValue(
        //   "supplierAgreementId",
        //   response.data.Data
        // );
        // const createdId = response.data.Data;
        // await db.updateTab(
        //   "supplierAgreements/register",
        //   `supplierAgreements/update/${createdId}`,
        //   t("tabs.updateSupplierAgreements")
        // );

        // navigate(`/supplierAgreements/update/${createdId}`);
      })

      .catch(() => { })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const fetchSuppliers = async (searchText: string) => {
    const result = await dispatch(
      getEntitiesBySearch({ searchText: searchText, isSupplier: true })
    );

    if (result.payload !== "An error occurred") {
      const payload = result.payload as Entity[];
      const options = payload.map((entity) => ({
        key: entity?.entityId?.toString(),
        label: entity?.description,
        value: entity?.entityId?.toString(),
      }));
      addInitialOptionsOfSupplier(options);
      return options;
    }
    return [];
  };

  const deleteSupplierAgreementsDetail = (itemId: number) => {
    setSupplierAgreementsDetails((prevSupplierAgreementsDetails: SupplierAgreementDetails[]) => {
      const activeSupplierAgreementsDetails = prevSupplierAgreementsDetails.filter(
        (supplierAgreementsDetail) => supplierAgreementsDetail.rowAction !== "D"
      );
      const index = activeSupplierAgreementsDetails.findIndex(
        (supplierAgreementsDetail) => supplierAgreementsDetail.itemId === itemId
      );

      if (index !== -1) {
        const selectedSupplierAgreementsDetail = activeSupplierAgreementsDetails[index];
        if (
          selectedSupplierAgreementsDetail.supplierAgreementDetailId === 0 ||
          !selectedSupplierAgreementsDetail.supplierAgreementDetailId
        ) {
          activeSupplierAgreementsDetails.splice(index, 1);
        } else {
          selectedSupplierAgreementsDetail.rowAction = "D";
        }
      }

      return [...activeSupplierAgreementsDetails];
    });
  };
  const validationSchema = Yup.object({
    supplierId: Yup.number()
      .required(t("supplierIdRequired"))
      .test("supplierId", t("supplierIdRequired"), (value) => value > 0),
    paymentDeadline: Yup.number()
      .required(t("paymentDeadlineRequired"))
      .test("paymentDeadline", t("paymentDeadline"), (value) => value > 0),
    validFrom: Yup.date()
      .required(t("validFromRequired"))
      .test(
        "validFrom",
        t("validFromMustBeTodayOrFutureDate"),
        function (value) {
          if (!value) return false;
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const selectedDate = new Date(value);
          selectedDate.setHours(0, 0, 0, 0);
          return selectedDate >= today;
        }
      ),
    validUntil: Yup.date()
      .required(t("validUntilRequired"))
      .test(
        "validUntil",
        t("validUntilMustBeAfterValidFrom"),
        function (value) {
          const { validFrom } = this.parent;
          if (!value || !validFrom) return false;

          const selectedDate = new Date(value);
          selectedDate.setHours(0, 0, 0, 0);

          const fromDate = new Date(validFrom);
          fromDate.setHours(0, 0, 0, 0);

          return selectedDate > fromDate;
        }
      ),
  });

  const handleSubmitValidationForm = async (
    setTouched: ({ }: any) => void,
    validateForm: (values?: any) => any
  ) => {
    const errors = (await validateForm()) || {};
    setTouched({
      supplierAgreementId: true,
      supplierId: true,
      signatureDate: true,
      validFrom: true,
      validUntil: true,
      paymentDeadline: true,
      comment: true,
      bonus: true,
      fixedBalance: true,
      discount: true,
      contractValidAfterExpiration: true,
      details: true,
      signedBy: true,
      generatedId: true,
    });
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
    return errors;
  };

  useEffect(() => {
    // console.log('outside', id)
    // if (mode === "update" && id) {
    //   dispatch(getSupplierAgreementAsync());
    // }
    dispatch(getEntitiesByTypeAsync(true));
  }, [dispatch, mode, id]);

  const columnDefs: ColDef<SupplierAgreementDetails>[] = [
    { field: "itemId", headerName: t("itemId"), sortable: true, suppressHeaderMenuButton: true },
    {
      field: "itemDescription",
      headerName: t("itemDescription"),
      sortable: true,
      suppressHeaderMenuButton: true
    },
    {
      field: "discount",
      headerName: t("discount"),
      sortable: true,
      editable: true,
      suppressHeaderMenuButton: true,
      valueSetter: (params: any) => {
        const newValue = Number(params.newValue);
        if (
          mode === "update" &&
          params.data.supplierAgreementDetailId !== 0 &&
          params.data.supplierAgreementDetailId !== undefined &&
          params.data.supplierAgreementDetailId !== null
        ) {
          params.data.rowAction = "U";
        }
        if (newValue >= 0) {
          params.data.discount = newValue;
          return true;
        } else {
          return false;
        }
      }
    },
    {
      field: "bonus1",
      headerName: t("bonus1"),
      sortable: true,
      editable: true,
      suppressHeaderMenuButton: true,
      valueSetter: (params: any) => {
        const newValue = Number(params.newValue);
        if (
          mode === "update" &&
          params.data.supplierAgreementDetailId !== 0 &&
          params.data.supplierAgreementDetailId !== undefined &&
          params.data.supplierAgreementDetailId !== null
        ) {
          params.data.rowAction = "U";
        }
        if (newValue >= 0) {
          params.data.bonus1 = newValue;
          return true;
        } else {
          return false;
        }
      }
    },
    {
      field: "bonus2",
      headerName: t("bonus2"),
      sortable: true,
      editable: true,
      suppressHeaderMenuButton: true,
      valueSetter: (params: any) => {
        const newValue = Number(params.newValue);
        if (
          mode === "update" &&
          params.data.supplierAgreementDetailId !== 0 &&
          params.data.supplierAgreementDetailId !== undefined &&
          params.data.supplierAgreementDetailId !== null
        ) {
          params.data.rowAction = "U";
        }
        if (newValue >= 0) {
          params.data.bonus2 = newValue;
          return true;
        } else {
          return false;
        }
      }
    },
    {
      field: "bonus3",
      headerName: t("bonus3"),
      sortable: true,
      editable: true,
      suppressHeaderMenuButton: true,
      valueSetter: (params: any) => {
        const newValue = Number(params.newValue);
        if (
          mode === "update" &&
          params.data.supplierAgreementDetailId !== 0 &&
          params.data.supplierAgreementDetailId !== undefined &&
          params.data.supplierAgreementDetailId !== null
        ) {
          params.data.rowAction = "U";
        }
        if (newValue >= 0) {
          params.data.bonus3 = newValue;
          return true;
        } else {
          return false;
        }
      }
    },
    {
      headerName: t("tableHeaders.options"),
      suppressHeaderMenuButton: true,
      maxWidth: 100,
      cellRenderer: (params: any) => {
        return (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => deleteSupplierAgreementsDetail(params.data.itemId)}
            style={{ color: "#007FFF" }}
          ></Button>
        );
      },
    },
  ];
  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const handleItemSearch = async (value: any, supplierId: number) => {
    const itemsBasedOnValueAction = await dispatch(
      getItemsBySearchValue({ searchValue: value, supplierId: supplierId })
    );
    const items = itemsBasedOnValueAction.payload as ItemDescriptionIdDto[];

    if (!items || items.length === 0) {
      setItemOptions([
        {
          label: t("noItemsFound"),
          value: "no-items",
          disabled: true,
        },
      ]);
    } else {
      setItemOptions([]);
      setItemOptions(
        items?.map((item) => ({
          key: item.itemId,
          label: item.description,
          value: item.itemId.toString(),
        }))
      );
    }
  };

  const debouncedItemSearch = useCallback(debounce(handleItemSearch, 1000), []);

  const handleSearchChange = (value: string, supplierId: number) => {
    // setSearchTerm(value);
    if (!value.trim()) {
      setSearchTerm("");
      return;
    }
    setSearchTerm(value);
    debouncedItemSearch(value, supplierId);
  };

  const onChange = (data: any) => {
    setSearchTerm(data);
  };

  const addSupplierAgreementDetail = (
    supplierAgreementDetails: SupplierAgreementDetails
  ) => {
    setSupplierAgreementsDetails((prevEntryItemsOrdersDetails) => {
      return [...prevEntryItemsOrdersDetails, supplierAgreementDetails];
    });
  };

  const handleOnSelect = async (value: any) => {
    if (!value || isNaN(parseInt(value))) {
      toast.error(t("Please enter a valid search term"));
      return;
    }

    const itemId = parseInt(value);

    try {
      const itemResponse = await dispatch(getItemDataById(itemId));

      if (itemResponse.type === "Items/getItemDataById/rejected") {
        toast.error(t("Item not found"));
        return;
      }

      if (itemResponse.type === "Items/getItemDataById/fulfilled") {
        const item = itemResponse.payload as GetItemDetailsDto;

        if (item) {
          const isAgreementExist = supplierAgreementsDetails.some(
            (agreement: any) => agreement.itemId === item.itemId
          );

          if (isAgreementExist) {
            toast.success(t("Supplier agreement already exists"));
            return;
          }

          const newSupplierAgreementDetail: SupplierAgreementDetails = {
            itemId: item.itemId,
            itemDescription: item.description,
            discount: 0,
            bonus1: 0,
            bonus2: 0,
            bonus3: 0,
            rowAction: "A",
          };

          addSupplierAgreementDetail(newSupplierAgreementDetail);
          setSearchTerm("");
        } else {
          toast.error(t("Supplier agreement not found"));
        }
      } else {
        toast.error(t("Something went wrong"));
      }
    } catch (error) {
      console.error(error);
      toast.error(
        t("An error occurred while fetching supplier agreement details")
      );
    }
  };
  const supplierAgreementDetailGridRef = useRef(null);
  const memoizedRowData = useMemo(() => {
    return supplierAgreementsDetails.filter(
      (detail) => detail.rowAction !== "D"
    );
  }, [supplierAgreementsDetails]);

  return (
    <Spin tip="Loading..." spinning={loadScreen}>
      <Formik
        innerRef={(formik) => (formikRef.current = formik)}
        initialValues={
          initialValues ??
          ({
            discount: 0,
            bonus: 0,
            fixedBalance: 0,
          } as CreateOrUpdateSupplierAgreementDto)
        }
        onSubmit={onFinish}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, submitForm, setTouched, validateForm, setFieldValue }) => (
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            style={{ marginTop: 20 }}
          >
            <FormHeaderOptions
              title={t("formTitle")}
              handleSubmitForm={submitForm}
              handleSubmitValidation={async () => {
                handleSubmitValidationForm(setTouched, validateForm);
              }}
              submitButtonText={
                mode === "update"
                  ? t("updateSupplierAgreementButton")
                  : t("createSupplierAgreementButton")
              }
              submitButtonIsDisabled={isLoading}
              createAccessEnum={MenuOptionEnum.SupplierAgreementsCreate}
            />
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8}>
                <FormSelectWithSearch
                  name="supplierId"
                  label={t("supplierIdLabel")}
                  value={values.supplierId}
                  disabled={values.validated || supplierAgreementsDetails.length > 0}
                  required={true}
                  fetchOptions={fetchSuppliers}
                  fetchInitialOption={() => {
                    return supplierOptions[0]
                  }}
                  placeHolder={t("placeHolder")}
                  onChange={(option) => {
                    setFieldValue("supplierId", option.value);
                    setFieldValue("supplierDescription", option.label);

                  }}
                />
                <FormInput
                  name="validFrom"
                  label={t("validFromLabel")}
                  type="date"
                  value={values.validFrom?.split("T")[0] || ""}
                />

                <FormInput
                  name="validUntil"
                  label={t("validUntilLabel")}
                  type="date"
                  value={values.validUntil?.split("T")[0] || ""}
                />
              </Col>

              <Col xs={24} sm={8}>
                <FormInput
                  name="paymentDeadline"
                  label={t("paymentDeadlineLabel")}
                  type="number"
                  value={values.paymentDeadline}
                />
                <FormInput
                  name="comment"
                  label={t("commentLabel")}
                  value={values.comment}
                />
                <FormInput
                  name="signatureDate"
                  label={t("signatureDateLabel")}
                  type="date"
                  value={values.signatureDate?.split("T")[0] || ""}
                />
              </Col>

              <Col xs={24} sm={8}>
                <FormInput
                  name="fixedBalance"
                  label={t("fixedBalanceLabel")}
                  type="number"
                  value={values.fixedBalance}
                />
                <FormInput
                  name="discount"
                  label={t("discountLabel")}
                  type="number"
                  value={values.discount}
                />
                <FormInput
                  name="bonus"
                  label={t("bonusLabel")}
                  type="number"
                  value={values.bonus}
                />
                <FormSwitch
                  className="switch-for-purchase"
                  name="contractValidAfterExpiration"
                  label={t("contractValidAfterExpirationLabel")}
                  value={values.contractValidAfterExpiration}
                />
                <FormInput
                  name="signedBy"
                  label={""}
                  type="hidden"
                  value={user?.userId}
                />
                <FormInput
                  name="registeredBy"
                  label={""}
                  type="hidden"
                  value={user?.userId}
                />
              </Col>
            </Row>

            <Row>
            <Col className="custom-row" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                {/* <Search
                  placeholder={t("searchPlaceholder")}
                  onSearch={handleSearchChange}
                  style={{ marginBottom: 16 }}
                /> */}
                <AutoComplete
                  options={itemOptions}
                  onSelect={handleOnSelect}
                  onSearch={(value) =>
                    handleSearchChange(value, values.supplierId)
                  }
                  onChange={onChange}
                  className="custom-search"
                  value={searchTerm}
                  style={{ width: 300 }}
                  disabled={
                    values.supplierId === 0 ||
                    values.supplierId === null ||
                    values.supplierId === undefined
                  }
                >
                  <Search
                    placeholder={t("searchPlaceholder")}
                    allowClear
                    ref={searchInputRef}
                    size="large"
                    onSearch={(value) =>
                      handleSearchChange(value, values.supplierId)
                    }
                  />
                </AutoComplete>
              </Col>
            </Row>

            <Row gutter={[12, 8]}>
              <Col xs={24} sm={24}>

                <AgGridTableComponent
                  defaultColDef={defaultColDef}
                  rowData={memoizedRowData}
                  isLoading={isLoading}
                  ref={supplierAgreementDetailGridRef}
                  columnDefs={columnDefs}
                  suppressRowHoverHighlight={true}
                  suppressCellFocus={true}
                  suppressRowClickSelection={true}
                  onCellKeyDown={(event: CellKeyDownEvent) =>
                    handleNextGridColumnClick(event, searchInputRef)
                  }
                />
              </Col>
            </Row>
            <HandleFormDataForTabSaving
              tabPath={
                mode === "update"
                  ? `supplierAgreement/update/${id}`
                  : "supplierAgreement/register"
              }
              additionalStates={{
                details: supplierAgreementsDetails,
              }}
            />
          </Form>
        )}
      </Formik>
    </Spin>
  );
};


export default CreateSupplierAgreements;
