import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupplierAgreementState } from "../../models/clientDashboard/SupplierAgreement/SupplierAgreementState";
import { setLoading, setLoadingState } from "./loadingSlice";
import { SupplierAgreements } from "../../models/clientDashboard/SupplierAgreement/SupplierAgreements";
import apiService from "../../extensions/api";
import { ApiResponse } from "../../models/ApiResponse";
import { CreateOrUpdateSupplierAgreementDto } from "../../models/clientDashboard/SupplierAgreement/CreateOrUpdateSupplierAgreementDto";
import { FilterData } from "../../models/clientDashboard/Filter/FilterData";
import { RootState } from "..";
import { PropertyDto } from "../../models/clientDashboard/Filter/PropertyDto";

const initialState: SupplierAgreementState = {
  supplierAgreement: [],
  error: null,
};

const supplierAgreementSlice = createSlice({
  name: "supplierAgreement",
  initialState,
  reducers: {
    getSupplierAgreementStart(state: SupplierAgreementState) {
      setLoading(true);
      state.error = null;
    },
    getSupplierAgreementSuccess(
      state: SupplierAgreementState,
      action: PayloadAction<SupplierAgreements[]>
    ) {
      setLoading(false);
      state.supplierAgreement = action.payload;
    },
    getSupplierAgreementFailure(
      state: SupplierAgreementState,
      action: PayloadAction<string>
    ) {
      setLoading(false);
      state.error = action.payload;
    },
  },
});

export const getSupplierAgreementAsync = createAsyncThunk(
  "supplierAgreement/getSupplierAgreement",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(getSupplierAgreementStart());
      const response = await apiService.post<ApiResponse<SupplierAgreements[]>>(
        "api/SupplierAgreement/GetDataWithFilter"
      );
      dispatch(getSupplierAgreementSuccess(response.data.Data));
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getSupplierAgreementFailure(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);
export const getSupplierAgreementColumnsAsync = createAsyncThunk(
  "Items/getSupplierAgreementColumnsAsync",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const response = await apiService.get<ApiResponse<PropertyDto[]>>(
        "api/Filter/supplier-agreements"
      );
      return response.data.Data;
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(getSupplierAgreementFailure(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);
export const getSupplierAgreementsDataById = createAsyncThunk(
  "SupplierAgreements/getSupplierAgreementsDataById",
  async (
    data: { supplierAgreementId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.get<ApiResponse<SupplierAgreements>>(
        `/api/SupplierAgreement/getById`,
        {
          params: {
            id: +data.supplierAgreementId,
          },
        }
      );
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const getSupplierAgreementWithFilter = createAsyncThunk(
  "SupplierAgreement/getSupplierAgreementWithFilter",
  async (data: FilterData[], { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoadingState(true));
      const response = await apiService.post<ApiResponse<SupplierAgreements[]>>(
        "/api/SupplierAgreement/GetDataWithFilter",
        data
      );
      dispatch(getSupplierAgreementSuccess(response.data.Data));
      return response.data.Data;
    } catch (error: any) {
      const errorMessage =
        typeof error === "string" ? error : "An error occurred";
      dispatch(setLoadingState(false));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoadingState(false));
    }
  }
);

export const {
  getSupplierAgreementStart,
  getSupplierAgreementSuccess,
  getSupplierAgreementFailure,
} = supplierAgreementSlice.actions;

export default supplierAgreementSlice.reducer;
