import { Button, Popconfirm, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux";
import useAppTranslation from "../../../../customHooks/useAppTranslation";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { GoodsTransfer } from "../../../../models/clientDashboard/InternalMovements/GoodsTransfers/GoodsTransfer";
import { formatDate } from "../../../../helperMethods/DateFormat";
import {
  RightOutlined,
  FilterOutlined,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { db } from "../../../../indexDB/clientSideDatabase";
import { getGoodsTransferFilterColumnsAsync, getGoodsTransferWithFilters } from "../../../../redux/slices/goodsTransferSlice";
import { FilterData } from "../../../../models/clientDashboard/Filter/FilterData";
import useFilters from "../../../../customHooks/useFilter";
import { renderFilterInputs } from "../../../../helperMethods/renderFilterInputs";
import AgGridTableComponent from "../../../CustomComponents/AgGridTableComponent";
import FilterModal from "../../../CustomComponents/FilterModal";
import './index.css';
import ListHeaderComponent from "../../../CustomComponents/ListHeaderComponent";
import { MenuOptionEnum } from "../../../../enums/MenuOptionEnum";

const { Title, Text } = Typography;

export default function GoodsTransfers() {
  const t = useAppTranslation("ClientDashboard.GoodsTransfer");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [menuItemsDisabled, setMenuItemsDisabled] = useState(false);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const goodsTransfers = useSelector((state: RootState) => state.goodsTransfer.goodsTransfer);
  const gridRef = useRef<AgGridReact | null>(null);
  const [colDefs, setColDefs] = useState<ColDef<GoodsTransfer>[]>([
    {
      field: "goodsTransferId",
      headerName: "ID",
      suppressMenu: true,
      cellRenderer: (params: any) => {
        return <Text className="ag-grid-id-column">{params?.data?.goodsTransferId}</Text>;
      },
    },
    // { field: "sendingOrganizationId", headerName: t("tableHeaders.sendingOrganizationId"), suppressMenu: true },
    { field: "sendingOrganization", headerName: t("tableHeaders.sendingOrganization"), suppressMenu: true },
    // { field: "receivingOrganizationId", headerName: t("tableHeaders.receivingOrganizationId"), suppressMenu: true },
    { field: "receivingOrganization", headerName: t("tableHeaders.receivingOrganization"), suppressMenu: true },
    { field: "validated", headerName: t("tableHeaders.validated"), suppressMenu: true },
    { field: "date", headerName: t("tableHeaders.date"), valueFormatter: formatDate, suppressMenu: true, width: 120 },
    { field: "documentNo", headerName: t("tableHeaders.documentNo"), suppressMenu: true },
    // { field: "registeredBy", headerName: t("tableHeaders.registeredBy"), suppressMenu: true },
    { field: "registeredByName", headerName: t("tableHeaders.registeredByName"), suppressMenu: true, width: 230 },
    {
      headerName: t("tableHeaders.details"),
      cellRenderer: (params: any) => (
        <Button
          type="text"
          icon={<RightOutlined />}
          onClick={() => {
            db.addTab(t("tabs.updateGoodsTransfer"), `goodsTransfer/update/${params?.data?.goodsTransferId}`, undefined);
            navigate(`/goodsTransfer/update/${params?.data?.goodsTransferId}`);
          }}
          style={{
            color: "#007FFF",
          }}
        ></Button>
      ),
      width: 90,
      suppressMenu: true
    },
  ]);

  const [isSelected, setIsSelected] = useState<number>(23);

  const handleTabClick = (documentTypeId: number) => {
    setIsSelected(documentTypeId);
  };

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params: any) => {
    // params.columnApi.autoSizeColumns(['sendingOrganizationId']);
    params.columnApi.autoSizeColumns(['sendingOrganization']);
    // params.columnApi.autoSizeColumns(['receivingOrganizationId']);
    params.columnApi.autoSizeColumns(['receivingOrganization']);
    params.columnApi.autoSizeColumns(['documentTypeId']);
    params.columnApi.autoSizeColumns(['documentType']);
    params.columnApi.autoSizeColumns(['documentNo']);
    params.columnApi.autoSizeColumns(['validated']);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const [perPage, setPerPage] = useState(20);

  const getGoodsTransfers = async () => {
    const result = await dispatch(getGoodsTransferWithFilters([]));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  useEffect(() => {
    getGoodsTransfers();
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const handleRowDoubleClick = (event: any) => {
    db.addTab(t("tabs.updateGoodsTransfers"), `goodsTransfer/update/${event?.data?.goodsTransferId}`, undefined);
    navigate(`/goodsTransfer/update/${event?.data?.goodsTransferId}`);
  };

  const handleFilterSubmit = async (filters: FilterData[]) => {
    const filteredFilters = filters.filter(
      (filter) => filter.value !== null && filter.value !== ""
    );
    if (filteredFilters.length === 0) return;

    const result = await dispatch(getGoodsTransferWithFilters(filters));
    const filteredData = result.payload;
    if (gridApi) {
      gridApi.setRowData(filteredData);
    }
  };

  const getFilterColumnsMethod = async () => {
    const result = await dispatch(getGoodsTransferFilterColumnsAsync());
    if (result.type === "GoodsTransfer/getGoodsTransferFilterColumnsAsync/fulfilled") {
      return result;
    } else {
      return null;
    }
  };

  const handleClearFilters = async () => {
    const result = await dispatch(getGoodsTransferWithFilters([]));
    const data = result.payload;
    if (gridApi) {
      gridApi.setRowData(data);
    }
  };

  const { filterState } = useFilters({
    getFilterColumnsMethod,
    clearFiltersMethod: handleClearFilters,
    filterSubmitMethod: handleFilterSubmit,
  });

  const handleCreateNewPrice = async () => {
    navigate("/goodsTransfer/register");
    const existingTab = await db.tabs.get("goodsTransfer/register");
    if (!existingTab) {
      db.addTab(t("tabs.createGoodsTransfer"), "goodsTransfer/register", undefined);
    }
  };

  const exportDataToCsv = (gridRef: React.RefObject<AgGridReact>, fields: string[]) => {
    if (!gridRef.current) {
      console.error('Grid reference is null');
      return;
    }

    const gridApi = gridRef.current.api;
    const rowData: any[] = [];
    gridApi.forEachNode((node: any) => rowData.push(node.data));

    if (rowData.length === 0) {
      console.error('No data available to export');
      return;
    }


    const csvContent: string[] = [];
    const header = fields.join(',');
    csvContent.push(header);


    rowData.forEach((row: any) => {
      const rowContent = fields.map((field) => row[field] !== undefined ? `"${row[field]}"` : '').join(',');
      csvContent.push(rowContent);
    });


    const blob = new Blob([csvContent.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'goods_transfer_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Download attribute is not supported');
    }
  };

  const onBtnExport = useCallback(() => {
    exportDataToCsv(gridRef, [
      "sendingOrganizationId",
      "sendingOrganization",
      "receivingOrganizationId",
      "receivingOrganization",
      "documentTypeId",
      "documentType",
      "date",
      "documentNo",
      "registeredByName",
      "validated",
    ]);
  }, []);

  return (
    <>
      <ListHeaderComponent
        title={t("titles.GoodsTransfer")}
        createRecord={isSelected !== 24 ? handleCreateNewPrice : undefined}
        createTitle={t("create")}
        filterTitle={t("flirt")}
        filterState={filterState}
        exportMethod={onBtnExport}
        exportTitle={t("export")}
        menuItemsDisabled={false}
        handleTabClick={handleTabClick}
        tabs={[{ tabName: t("goodsTransfered"), tabNumber: 23 }, { tabName: t("goodsRecieved"), tabNumber: 24 }]}
        isSelected={isSelected}
        createAccessEnum={MenuOptionEnum.GoodsTransferCreate}
        exportAccessEnum={MenuOptionEnum.GoodsTransferExport}
      />
      <div
        style={{
          display: 'grid',
          gap: '16px',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))'
        }}
      >
        {filterState && filterState?.filterInputs?.map(input => (
          <div key={input.name} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>{input.isClassifier ? input.name : t(`FilterColumns.${input.name}`)}</Text>
            {renderFilterInputs({ input: input, filterState: filterState })}
          </div>
        ))}
      </div>
      {filterState && filterState?.filterInputs?.length > 0 && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem", gap: "1.5rem" }}>
          <Button onClick={filterState.handleClearFilters}>{t("clearFilters")}</Button>
          <Button type="primary" onClick={filterState.handleFilterSubmit}>{t("filter")}</Button>
        </div>
      )}
      <AgGridTableComponent
        isLoading={isLoading}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        rowData={goodsTransfers.filter((goodsTransfer) => goodsTransfer.documentTypeId === isSelected)}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={20}
        onRowDoubleClicked={handleRowDoubleClick}
        gridRef={gridRef}
      />
      <FilterModal filterState={filterState} translationPrefix="ClientDashboard.GoodsTransfer" />
    </>
  );
}
