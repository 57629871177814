import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux";
import useAppTranslation from "../../../customHooks/useAppTranslation";
import { useEffect, useRef, useState } from "react";
import { getAdminUserDataById } from "../../../redux/slices/userSlice";
import useFormData from "../../../customHooks/useFormData";
import * as Yup from "yup";
import { t } from "i18next";
import { setLoadingState } from "../../../redux/slices/loadingSlice";
import apiService from "../../../extensions/api";
import { toast } from "react-toastify";
import { db } from "../../../indexDB/clientSideDatabase";
import { Formik } from "formik";
import { MenuAdminOptionEnum } from "../../../enums/MenuAdminOptionEnum";
import FormHeaderOptions from "../../CustomComponents/FormHeaderOptions";
import { Col, Form, Row, Spin, Tabs } from "antd";
import HandleFormDataForTabSaving from "../../../helperMethods/handleFormDataForTabSaving";
import AdminRegisterUserDto from "../../../models/dashboard/Users/AdminRegisterUserDto";
import FormInput from "../../CustomComponents/FormInput";
import FormSelect from "../../CustomComponents/FormSelect";
import FormSwitch from "../../CustomComponents/FormSwitch";
import { adminRoles } from "../../../lookups/AdminRoles";
import { ApiResponse } from "../../../models/ApiResponse";
import { GetCompaniesBasicInfo } from "../../../models/dashboard/Company/GetCompaniesBasicInfo";
import { getCompaniesBasicInfoAsync } from "../../../redux/slices/dashboardCompanySlice";

export default function CreateAdminUsers() {
    const navigate = useNavigate();
    const { mode, id } = useParams<{
        mode: string;
        id?: string;
    }>();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const dispatch = useDispatch<AppDispatch>();
    const t = useAppTranslation("AdminDashboard.CreateAdminUser");
    const adminRole = useSelector(
        (rootState: RootState) => rootState.user.adminUsers
    );
    const loggedInUser = useSelector((state: RootState) => state.user.loggedInUser)

    const clients = useSelector((state: RootState) =>
        state.dashboardCompany.companiesBasicInfo?.map(
            (item: GetCompaniesBasicInfo) => {
                return {
                    label: `${item.vatNumber} - ${item.companyName}`,
                    value: item.companyID,
                };
            }
        )
    );
    useEffect(() => {
        dispatch(getCompaniesBasicInfoAsync());
    }, [dispatch]);

    const formikRef = useRef<any>(null);

    const getData = async () => {
        try {
            if (mode === "update" && id) {
                const response = await dispatch(getAdminUserDataById(id));
                if (response.type === "AdminUsers/getAdminUserDataById/fulfilled") {
                    const user = response.payload as AdminRegisterUserDto;
                    setInitialValues(user);
                    formikRef?.current?.setValues(user);
                    return user;
                }
            }
            return { status: true } as AdminRegisterUserDto;
        } catch (error) {
            console.error("Error fetching admin user data:", error);
            toast.error(t("fetchError"));
            return { status: true } as AdminRegisterUserDto;
        }
    };
    const { initialValues, setInitialValues } =
        useFormData<AdminRegisterUserDto>(
            mode === "update" ? `adminUsers/update/${id}` : "adminUsers/register",
            {
                status: true,
            } as AdminRegisterUserDto,
            undefined,
            {
                fetchData: getData,
            }
        );


    const validationSchema = Yup.object({
        firstName: Yup.string().required(t("requiredFirstName")),
        lastName: Yup.string().required(t("requiredLastName")),
        username: Yup.string().required(t("requiredUsername")),
        email: Yup.string()
            .required(t("requiredEmail"))
            .matches(
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                t("invalidEmail")
            ),
        password: Yup.string()
            .required(t("requiredPassword"))
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                t("invalidPassword")
            ),
        adminRoleId: Yup.number().required(t("requiredAdminRole")),
        companies: Yup.array()
            .of(Yup.number().required(t("requiredCompanyName")))
            .min(1, t("requiredCompanyName"))
            .required(t("requiredCompanyName")),
    });

    const onFinish = async (values: AdminRegisterUserDto) => {
        dispatch(setLoadingState(true));

        const isValidAdminRole =
            (loggedInUser?.roleId === "1" && [1, 2, 3].includes(values.adminRoleId)) ||
            (loggedInUser?.roleId === "2" && [2, 3].includes(values.adminRoleId)) ||
            (loggedInUser?.roleId === "3" && false);

        if (!isValidAdminRole) {
            toast.error(t("invalidRole"));
            dispatch(setLoadingState(false));
            return;
        }


        if (mode === "update" && id) {
            const result = await apiService
                .put(`/api/AdminUser/update`, values)
                .then(async (response) => {
                    if (response.status === 200) {
                        toast.success(t("updatedSuccessfully"));
                        getData();
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        } else {
            await apiService.post<ApiResponse>("/api/AdminUser/register", values)
                .then(async (response) => {
                    if (response.data.StatusCode === 200) {
                        toast.success(t("createdSuccessfully"));
                        formikRef.current.setFieldValue("usersID", response.data.Data);

                        navigate(`/dashboard/adminUsers/update/${response.data.Data}`);
                    } else
                        toast.error("something went wrong");
                })
                .catch(() => { })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        }
        dispatch(setLoadingState(false));
    };

    const [tab, setTab] = useState<any>(null);


    const handleSubmitValidationForm = async (
        setTouched: ({ }: any) => void,
        validateForm: (values?: any) => any
    ) => {
        const errors = await validateForm();
        setTouched({
            usersID: true,
            firstName: true,
            lastName: true,
            username: true,
            email: true,
            password: true,
            adminRoleId: true,
            companyId: true,
            status: true,
            userRoleId: true,
            paymentDeadline: true,
        });
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
        }
        return errors;
    };

    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <Formik
                innerRef={(formik) => (formikRef.current = formik)}
                initialValues={initialValues ?? ({} as AdminRegisterUserDto)}
                validationSchema={validationSchema}
                onSubmit={onFinish}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    handleSubmit,
                    submitForm,
                    validateForm,
                    setTouched,
                    setFieldValue,
                }) => (
                    <Form onFinish={handleSubmit} layout="vertical">
                        <FormHeaderOptions
                            title={
                                mode === "update" ? t("updateFormTitle") : t("createFormTitle")
                            }
                            handleSubmitForm={submitForm}
                            handleSubmitValidation={async () => {
                                handleSubmitValidationForm(setTouched, validateForm);
                            }}
                            submitButtonText={t("createButton")}
                            submitButtonIsDisabled={
                                isLoading
                            }
                            createAccessEnum={MenuAdminOptionEnum.AdminDashboardAccess}

                        />
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <FormInput
                                    name="firstName"
                                    type="text"
                                    label={t("firstNameInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("firstNameInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.firstName}
                                />
                                <FormInput
                                    name="lastName"
                                    type="text"
                                    label={t("lastNameInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("lastNameInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.lastName}
                                />
                                <FormInput
                                    name="username"
                                    type="text"
                                    label={t("usernameInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("usernameInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.username}
                                />
                                <FormSwitch
                                    name="status"
                                    label={t("statusInputLabel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("statusInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.status}
                                />
                            </Col>
                            <Col xs={24} sm={12}>
                                <FormInput
                                    name="email"
                                    type="email"
                                    label={t("emailInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("emailInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.email}
                                />
                                <FormInput
                                    name="password"
                                    type="password"
                                    label={t("passwordInputLabel")}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("passwordInputPlaceholder"),
                                        },
                                    ]}
                                    value={values.password}
                                />
                                {loggedInUser?.roleId === "3" && (
                                    <FormSelect
                                        name="adminRoleId"
                                        label={t("adminRoleIdInputLabel")}
                                        required={true}
                                        value={values.adminRoleId}
                                        options={adminRoles
                                            .filter(role => {
                                                // If loggedInUser roleId is not 1, only show roles with ID 2 and 3
                                                if (loggedInUser?.roleId !== "1") {
                                                    return role.value === 2 || role.value === 3;
                                                }
                                
                                                // If loggedInUser roleId is 1, show all roles
                                                return true;
                                            })
                                            .map((role) => ({
                                                key: role.key,
                                                label: t(`${role.description}`),
                                                value: role.value,
                                            }))}
                                    />
                                )}
                                {values.adminRoleId && values.adminRoleId !== 1 && (
                                    <FormSelect
                                        name="companies"
                                        multiple
                                        showSearch={true}
                                        label={t("companies")}
                                        options={clients}
                                        value={values.companies}
                                        onChange={(value) => setFieldValue('companies', value)}
                                        required={true}
                                        disabled={!values.adminRoleId}
                                    />
                                )}
                            </Col>
                        </Row>


                    </Form>

                )}
            </Formik>
        </Spin>
    );
};